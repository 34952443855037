import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { EmbedFilterLabel, EmbedSlider } from 'components/embed';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import {
  DashboardVariable,
  DashboardVariableMap,
  SliderElementConfig,
  SliderElementValue,
} from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { VariableSelectOptions } from 'utils/extraVariableUtils';
import {
  convertArrayToSliderElementValue,
  convertSliderElementValueToArray,
} from 'utils/sliderUtils';
import { clamp } from 'utils/standard';
import { resolveTooltipVariables } from 'utils/variableUtils';

type Props = {
  config: SliderElementConfig;
  disabled?: boolean;
  onNewValueSelect: (newValue: DashboardVariable, options?: VariableSelectOptions) => void;
  value: DashboardVariable;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  variables: DashboardVariableMap;
};

export default function DashboardSliderElement({
  config,
  datasetNamesToId,
  datasetData,
  disabled,
  onNewValueSelect,
  value,
  variables,
}: Props) {
  const dispatch = useDispatch();

  const tooltipText = useMemo(
    () => resolveTooltipVariables(config, variables, datasetNamesToId, datasetData),
    [config, datasetData, datasetNamesToId, variables],
  );

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  const valueAsArray = useMemo(
    () =>
      value !== undefined
        ? convertSliderElementValueToArray(value as SliderElementValue)
        : undefined,
    [value],
  );

  const defaultValueAsArray = useMemo(
    () => convertSliderElementValueToArray(config.defaultValue),
    [config.defaultValue],
  );

  const min = Number(useStringWithVariablesReplaced(config.min, datasetNamesToId));
  const max = Number(useStringWithVariablesReplaced(config.max, datasetNamesToId));

  const clampValue = useCallback(
    (unclampedValue: number[]) => unclampedValue.map((num) => clamp(num, min, max)),
    [max, min],
  );

  const clampedDefaultValue = useMemo(
    () => clampValue(defaultValueAsArray),
    [clampValue, defaultValueAsArray],
  );
  const clampedValue = useMemo(
    () => (valueAsArray != null ? clampValue(valueAsArray) : undefined),
    [clampValue, valueAsArray],
  );

  return (
    <div
      className={sprinkles({
        marginTop: 'sp2.5',
      })}
      style={{ height: 32 }}>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ flexItems: 'alignCenter', marginRight: 'sp1' })}>
          <EmbedFilterLabel noBottomMargin helpText={tooltipText}>
            {config.showSliderLabel ? label : undefined}
          </EmbedFilterLabel>
        </div>
        <EmbedSlider
          defaultValue={clampedDefaultValue}
          disabled={disabled}
          max={max}
          min={min}
          numThumbs={config.numThumbs}
          onValueChange={(newValue) => {
            onNewValueSelect(convertArrayToSliderElementValue(newValue));
            dispatch(sendAnalyticsEventThunk(ReportedAnalyticActionTypes.SLIDER_CHANGED));
          }}
          showThumbLabels={config.showThumbLabels}
          step={config.stepSize}
          value={clampedValue ?? clampedDefaultValue}
        />
      </div>
    </div>
  );
}
