import { FC } from 'react';

import { GradientPointType } from '@explo/data';

import { Select, sprinkles } from 'components/ds';

import { ExactValueInput } from './ExactValueInput';

type Props = {
  hideLabels?: boolean; // when there are multiple in a row, want to hide redundant labels
  disableColumnCalculation?: boolean;
  exactValue?: string;
  label?: string;
  // Table uses average for mid
  type: 'max' | 'mid' | 'avg' | 'min';
  usesExactValue: boolean;

  updateExactValue: (newValue: string) => void;
  setGradientPointType: (newType: GradientPointType) => void;
};

const exactValueOption = 'Exact value';

export const GoalInputRow: FC<Props> = ({
  hideLabels,
  disableColumnCalculation,
  exactValue,
  label,
  type,
  usesExactValue,
  updateExactValue,
  setGradientPointType,
}) => {
  const colTypeOption = `Column ${type}`;

  return (
    <div className={rootClass}>
      {!disableColumnCalculation ? (
        <Select
          className={sprinkles({ flex: 1 })}
          label={!hideLabels ? (label ?? 'Calculation') : undefined}
          onChange={(value) =>
            setGradientPointType(
              value === exactValueOption ? GradientPointType.NUMBER : GradientPointType.COMPUTED,
            )
          }
          selectedValue={usesExactValue ? exactValueOption : colTypeOption}
          values={[{ value: colTypeOption }, { value: exactValueOption }]}
        />
      ) : null}
      <div className={sprinkles({ flex: 1 })}>
        <ExactValueInput
          disabled={!usesExactValue}
          exactValue={!usesExactValue ? '' : exactValue}
          hideLabels={hideLabels}
          label={disableColumnCalculation ? label : undefined}
          updateExactValue={updateExactValue}
        />
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  marginTop: 'sp1',
  display: 'flex',
  alignItems: 'flex-end',
  gap: 'sp1',
});
