import { FC } from 'react';

import { Tag } from 'components/ds';

export const EmailTag: FC = () => {
  return (
    <Tag intent="active" leftIcon="envelope-reg">
      Email enabled
    </Tag>
  );
};
