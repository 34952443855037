import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { FC, Fragment, useContext, useMemo, useState } from 'react';

import { DatasetRow, DatasetSchema, TIME_COLUMN_TYPES } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { getFlexAlignments } from 'components/ds/DataGrid/utils';
import {
  CategoryChartColumnInfo,
  Total,
  VisualizeCollapsibleListInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getAggColDisplayName } from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { getColBucketName } from 'utils/dataPanelColUtils';
import { sortAggregationsByOrderedColumnNames } from 'utils/general';
import { groupBy } from 'utils/standard';

import { getAxisNumericalValue, isCollapsibleListReadyToDisplay } from '../../charts/utils';

import { DashboardCollapsibleListItem } from './DashboardCollapsibleListItem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 63px)',

    '&.noHeader': {
      height: '100%',
    },
  },
  columnLabels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontWeight: 'bold',
    height: theme.spacing(10),
    boxShadow: `${theme.palette.ds.grey400} 0px -1px 0px inset, 0px 0px 0px inset`,
  },
  aggCol: {
    fontWeight: 400,
  },
  listBody: {
    height: 'calc(100% - 39px)',
    overflowY: 'scroll',
  },
  tableFooter: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    boxShadow: '0 0 0 1px rgb(16 22 26 / 15%)',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    paddingLeft: globalStyleConfig.container.padding.default,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
}));

export const DEFAULT_RENDER_COUNT = 200;

interface CollapsibleListItem {
  category: CategoryChartColumnInfo;
  name: string;
  totals: Total[];
  children: CollapsibleListItem[];
  categoryAggName: string;
}
type Props = {
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  instructions: VisualizeCollapsibleListInstructions;
  previewData: DatasetRow[];
  loading?: boolean;
  schema: DatasetSchema;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
};

export const DashboardCollapsibleList: FC<Props> = ({
  generalOptions,
  instructions,
  previewData,
  loading,
  schema,
  variables,
  datasetNamesToId,
  datasetData,
}) => {
  const context = useContext(GlobalStylesContext);
  const classes = useStyles({
    globalStyleConfig: context.globalStyleConfig,
  });

  const [rowsToRenderCount, setRowsToRenderCount] = useState(DEFAULT_RENDER_COUNT);

  const numRowCols = instructions?.rowColumns?.length || 0;
  const aggCols = schema.slice(numRowCols).map((x) => x.name);
  const groupByList = schema.slice(0, numRowCols);
  const valueCols = schema.slice(numRowCols, numRowCols).map((x) => x.name);
  const columnWidth = 100 / (aggCols.length + valueCols.length + 1);
  const newData = useMemo(() => {
    if (loading || !isCollapsibleListReadyToDisplay(instructions)) return [];

    return manipulateData(previewData, aggCols, valueCols, groupByList, instructions);
  }, [aggCols, groupByList, instructions, loading, previewData, valueCols]);

  const renderedData = useMemo(() => {
    if (loading || !isCollapsibleListReadyToDisplay(instructions)) return [];

    return newData.length < rowsToRenderCount ? newData : newData.slice(0, rowsToRenderCount);
  }, [loading, instructions, newData, rowsToRenderCount]);

  if (loading || !isCollapsibleListReadyToDisplay(instructions)) {
    return (
      <NeedsConfigurationPanel
        instructionsNeedConfiguration={!isCollapsibleListReadyToDisplay(instructions)}
        loading={loading}
      />
    );
  }

  const sortedAggregations = sortAggregationsByOrderedColumnNames(
    instructions?.aggregations || [],
    instructions.orderedColumnNames,
  );

  const createList = (branch: CollapsibleListItem, index: number) => {
    // Don't allow more than 3 levels of nesting
    if (index > 3) return null;

    return (
      <DashboardCollapsibleListItem
        category={branch.category}
        categoryAggName={branch.categoryAggName}
        columnWidth={columnWidth}
        datasetData={datasetData}
        datasetNamesToId={datasetNamesToId}
        groupByDisplayOptions={instructions.groupByDisplayOptions}
        index={index}
        name={branch.name}
        numberDisplayOptions={instructions.numberDisplayOptions}
        rowHeight={instructions.rowHeight}
        showCategories={instructions?.showCategories}
        totals={branch.totals}
        variables={variables}>
        {branch.children.map((branch) => (
          <Fragment key={branch.name}>{createList(branch, index + 1)}</Fragment>
        ))}
      </DashboardCollapsibleListItem>
    );
  };

  return (
    <div
      className={cx(classes.root, embedSprinkles({ backgroundColor: 'containerFill' }), {
        noHeader: generalOptions?.headerConfig?.isHeaderHidden,
      })}>
      <div
        className={cx(
          classes.columnLabels,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        )}>
        {instructions.categoryColumnTitle?.showTitle ? (
          <div
            className={cx(
              aggColumnClass,
              embedSprinkles({ otherText: 'tableColumnHeader' }),
              sprinkles({ paddingLeft: 'sp4', justifyContent: 'flex-start' }),
            )}
            style={{ width: `${columnWidth}%` }}>
            {instructions.categoryColumnTitle.title}
          </div>
        ) : null}
        {sortedAggregations.map((aggCol, i) => {
          // Ideally aggCols and sortedAggregations wouldn't be different arrays
          const justifyContent =
            sortedAggregations.length === aggCols.length
              ? // TODO: Refactor how we get the agg cols so that we can pass the correct type here
                getFlexAlignments(instructions.numberDisplayOptions?.[aggCols[i]], '')
              : 'flex-start';
          return (
            <div
              className={cx(
                aggColumnClass,
                sprinkles({ justifyContent, paddingX: 'sp1' }),
                embedSprinkles({ otherText: 'tableColumnHeader' }),
              )}
              key={aggCol.column.name}
              style={{ width: `${columnWidth}%` }}>
              {getAggColDisplayName(aggCol)}
            </div>
          );
        })}
      </div>
      <div className={classes.listBody}>
        {renderedData.map((item) => (
          <div key={item.name}>{createList(item, 0)}</div>
        ))}
        {renderedData.length < newData.length ? (
          <div
            className={cx(
              sprinkles({ cursor: 'pointer', width: 'fill', paddingY: 'sp2', textAlign: 'center' }),
              embedSprinkles({ body: 'primary' }),
            )}
            onClick={() => setRowsToRenderCount((prevCount) => prevCount + DEFAULT_RENDER_COUNT)}>
            Load more
          </div>
        ) : null}
      </div>
    </div>
  );
};

const manipulateData = (
  rows: DatasetRow[],
  aggCols: string[],
  valueCols: string[],
  groupByList: DatasetSchema,
  instructions: VisualizeCollapsibleListInstructions,
): CollapsibleListItem[] => {
  const categoryAggName = groupByList[0].name;
  const categoryCol = instructions?.rowColumns?.find((x) => {
    if (x.column.type && TIME_COLUMN_TYPES.has(x.column.type)) {
      const bucketName = getColBucketName(x.bucket?.id);
      const datePart = bucketName.toLowerCase().replaceAll(' ', '_');
      return (
        datePart.concat('_', x.column.name || '') === categoryAggName ||
        // in embeddo, this is backwards from how date part column names are normally returned.
        // This is standardized in FIDO though, so we can remove the above condition when we switch over
        (x.column.name ?? '').concat('_', datePart) === categoryAggName
      );
    }
    return x.column.name === categoryAggName;
  });

  if (!categoryCol) return [];
  let groupedRows;
  if (!valueCols) {
    groupedRows = groupBy(rows, categoryAggName);
  } else {
    groupedRows = groupBy(rows, (row) => {
      let groupByString = row[categoryAggName];
      valueCols.forEach((col) => (groupByString += `+${row[col]}`));
      return groupByString;
    });
  }

  return createCollapsibleListItem(
    groupedRows,
    aggCols,
    valueCols,
    groupByList,
    instructions,
    categoryCol,
    categoryAggName,
  );
};

const createCollapsibleListItem = (
  groupedRows: Record<string, DatasetRow[]>,
  aggCols: string[],
  valueCols: string[],
  groupByList: DatasetSchema,
  instructions: VisualizeCollapsibleListInstructions,
  categoryCol: CategoryChartColumnInfo,
  categoryAggName: string,
) => {
  const accum: CollapsibleListItem[] = [];
  const categoryColumnName = categoryCol.column.name || '';

  Object.entries(groupedRows).forEach(([value, rows]) => {
    // if there are multiple rows, the category value for each row within
    // one iteration of this function should be the same -
    // we can pull the value for each column from the first row
    const firstRow = rows[0];
    let suffix = '';
    valueCols.forEach((col) => (suffix += `+${firstRow[col]}`));
    // the group by concatenates all the group by columns and uses that as the key
    // remove the suffix so it will show as the top level category column
    const newValue = value.replace(suffix, '');

    const children =
      groupByList.length < 2
        ? []
        : manipulateData(rows, aggCols, valueCols, groupByList.slice(1), instructions);

    accum.push({
      category: categoryCol,
      name: newValue,
      totals: createTotals(rows, aggCols, valueCols),
      children,
      categoryAggName,
    });
  });

  if (!instructions.isSortingDisabled) {
    const sortColumn = instructions.sortColumns?.[categoryColumnName];
    const sortCategory = sortColumn?.column;
    if (sortCategory) {
      accum.sort((a, b) => sortListItems(a, b, sortCategory, sortColumn?.order ?? 'ASC'));
    }
    const sortedStages = instructions.sortedStages;
    sortedStages?.forEach((stage) => {
      const stageIndex = accum.findIndex((item) => item.name === stage);
      if (stageIndex !== -1) {
        const stageItem = accum.splice(stageIndex, 1);
        accum.unshift(stageItem[0]);
      }
    });
  }
  return accum;
};

const createTotals = (
  rows: DatasetRow[],
  aggColumns: string[],
  categoryColumns: string[],
): Total[] => {
  const valueTotals = categoryColumns.map((col) => {
    return { name: col, category: rows[0][col] } as Total;
  });

  const aggTotals = aggColumns.map((col) => {
    let total = 0;
    rows.forEach((row) => (total += getAxisNumericalValue(row[col])));
    return { name: col, total } as Total;
  });

  return valueTotals.concat(aggTotals);
};

const sortListItems = (
  a: CollapsibleListItem,
  b: CollapsibleListItem,
  category: string,
  order: string,
) => {
  const categoryIndex = a.totals.findIndex((t) => t.name === category);
  if (categoryIndex === -1) return 0;

  const aTotal = a.totals[categoryIndex].total || 0;
  const bTotal = b.totals[categoryIndex].total || 0;
  if (order === 'ASC') {
    return aTotal > bTotal ? 1 : -1;
  } else {
    return aTotal > bTotal ? -1 : 1;
  }
};

const aggColumnClass = sprinkles({ truncateText: 'ellipsis', flexItems: 'center' });
