import { FC } from 'react';

import { FILTER_OPS_BOOLEAN, FilterOperator } from '@explo/data';

import { SortOptionToggle } from 'components/SortDirectionToggles';
import { BooleanToggle, Input, Select, Tabs } from 'components/ds';
import { INPUT_TYPES } from 'constants/types';
import {
  DASHBOARD_ELEMENT_TYPES,
  SELECT_FILTER_TYPE,
  SelectElemConfig,
} from 'types/dashboardTypes';
import { createColSelectOptionsWithIcon } from 'utils/general';
import { getDatasetName } from 'utils/naming';

import { getUpdateConfigFunc } from '../utils';

import { ComputedView } from '@explo-tech/fido-api';
import { SelectItems } from 'components/ds/Select';
import { getEmbeddoSchemaFromFidoSchema } from 'utils/fido/fidoShims';
import * as styles from './ValuesConfig.css';
import { getValueInputButtonClickFn, getValuePlaceholderText } from './valuesConfigUtil';
import { Dataset } from 'actions/datasetActions';

type Props = {
  config: SelectElemConfig;
  datasets: Record<string, Dataset>;
  referencedGlobalDatasets: Record<string, ComputedView>;
  selectType: SELECT_FILTER_TYPE;
  elementFilterOperator?: FilterOperator;

  updateSelectConfig: (config: SelectElemConfig, reRequestRows: boolean) => void;
};

export const SelectValuesConfig: FC<Props> = ({
  config,
  datasets,
  referencedGlobalDatasets,
  selectType,
  elementFilterOperator,
  updateSelectConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  const isToggle = selectType === DASHBOARD_ELEMENT_TYPES.TOGGLE;

  const isBooleanFilterOperator = elementFilterOperator
    ? FILTER_OPS_BOOLEAN.has(elementFilterOperator)
    : false;
  const renderSideMessage = () => {
    if (!isToggle || isBooleanFilterOperator) return null;
    return <div className={styles.sideText}>A maximum of 5 options will be shown</div>;
  };

  const renderManualConfig = () => {
    const valuesArrStr = config.valuesConfig.manualValues || '';
    const displaysArrStr = config.valuesConfig.manualDisplays || '';

    return (
      <>
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={valuesArrStr}
          errorText={parseArrayString(valuesArrStr)}
          handleIconButtonClicked={getValueInputButtonClickFn(
            isBooleanFilterOperator,
            updateConfig,
          )}
          inputIcon={isBooleanFilterOperator ? 'right-left' : undefined}
          label="Values"
          onSubmit={(newValue) =>
            updateConfig((draft) => (draft.valuesConfig.manualValues = newValue))
          }
          placeholder={isToggle ? '["day", "week", "month"]' : '[1, 2, 3]'}
          readOnly={isBooleanFilterOperator}
        />
        {renderSideMessage()}
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={displaysArrStr}
          errorText={parseArrayString(displaysArrStr)}
          label="Displays"
          onSubmit={(newValue) =>
            updateConfig((draft) => (draft.valuesConfig.manualDisplays = newValue))
          }
          placeholder={getValuePlaceholderText(isBooleanFilterOperator, isToggle)}
        />
      </>
    );
  };

  const renderQueryConfig = () => {
    const datasetOptions: SelectItems<string> = Object.values(datasets).reduce<SelectItems<string>>(
      (acc, dataset) => {
        if (dataset.schema)
          acc.push({ value: dataset.id, label: getDatasetName(dataset), icon: 'table' });
        return acc;
      },
      [],
    );
    const globalDatasetOptions: SelectItems<string> = Object.values(
      referencedGlobalDatasets,
    ).reduce<SelectItems<string>>((acc, globalDataset) => {
      if (!globalDataset.columnDefinitions) {
        return acc;
      }

      acc.push({
        value: globalDataset.id ?? '',
        label: globalDataset.name,
        icon: 'globe',
      });
      return acc;
    }, []);
    const allDatasetOptions = [...datasetOptions, ...globalDatasetOptions];

    const selectedDataset = datasets[config.valuesConfig.queryTable?.id ?? ''];
    const selectedGlobalDataset =
      referencedGlobalDatasets[config.valuesConfig.queryGlobalDatasetReference?.id ?? ''];
    const hasSelectedDataset = selectedDataset || selectedGlobalDataset;

    const queryDisplayColName = config.valuesConfig.queryDisplayColumn?.name;

    const colOptions = createColSelectOptionsWithIcon(
      selectedDataset
        ? selectedDataset.schema
        : selectedGlobalDataset
          ? getEmbeddoSchemaFromFidoSchema(selectedGlobalDataset.columnDefinitions)
          : [],
    );

    return (
      <>
        <Select
          className={styles.configInput}
          label={{
            text: 'Dataset',
            infoText:
              'Only the first 5000 values from your dataset will be loaded into your dropdown.',
          }}
          onCancel={() =>
            updateConfig((draft) => {
              draft.valuesConfig.queryValueColumn = undefined;
              draft.valuesConfig.queryDisplayColumn = undefined;
              draft.valuesConfig.queryTable = undefined;
              draft.valuesConfig.queryGlobalDatasetReference = undefined;
            })
          }
          onChange={(datasetId) =>
            updateConfig((draft) => {
              draft.valuesConfig.queryValueColumn = undefined;
              draft.valuesConfig.queryDisplayColumn = undefined;
              if (datasets[datasetId]) {
                draft.valuesConfig.queryTable = { id: datasetId };
                draft.valuesConfig.queryGlobalDatasetReference = undefined;
              } else if (referencedGlobalDatasets[datasetId]) {
                const referencedGlobalDataset = referencedGlobalDatasets[datasetId];
                draft.valuesConfig.queryGlobalDatasetReference = {
                  id: datasetId,
                  versionId: referencedGlobalDataset.versionId ?? '',
                  namespaceId: referencedGlobalDataset.namespaceId ?? '',
                };
                draft.valuesConfig.queryTable = undefined;
              }
            }, true)
          }
          placeholder="Select dataset"
          selectedValue={
            selectedDataset ? selectedDataset.id : (selectedGlobalDataset?.id ?? undefined)
          }
          values={allDatasetOptions}
        />
        <Select
          className={styles.configInput}
          disabled={!hasSelectedDataset}
          label="Values"
          onCancel={() =>
            updateConfig((draft) => (draft.valuesConfig.queryValueColumn = undefined))
          }
          onChange={(colName) =>
            updateConfig((draft) => (draft.valuesConfig.queryValueColumn = { name: colName }), true)
          }
          placeholder="Select column"
          selectedValue={config.valuesConfig.queryValueColumn?.name}
          values={colOptions}
        />
        {renderSideMessage()}
        <Select
          className={styles.configInput}
          disabled={!hasSelectedDataset}
          label="Displays (optional)"
          onCancel={() =>
            updateConfig((draft) => (draft.valuesConfig.queryDisplayColumn = undefined))
          }
          onChange={(colName) =>
            updateConfig((draft) => {
              draft.valuesConfig.queryDisplayColumn = { name: colName };
            })
          }
          placeholder="Select column"
          selectedValue={queryDisplayColName}
          values={colOptions}
        />
        <SortOptionToggle
          allowNoSort
          className={styles.configInput}
          currentSort={config.valuesConfig?.querySortOption}
          label="Sort Dropdown"
          updateSort={(newSort) =>
            updateConfig((draft) => (draft.valuesConfig.querySortOption = newSort))
          }
        />

        {queryDisplayColName ? (
          <BooleanToggle
            className={styles.configInput}
            falseText="Display"
            label="Sort On"
            onValueChange={(value) =>
              updateConfig((draft) => (draft.valuesConfig.querySortByValue = value))
            }
            selectedValue={!!config.valuesConfig.querySortByValue}
            trueText="Values"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className={styles.valuesContainer}>
      {!isBooleanFilterOperator ? (
        <Tabs
          className={styles.toggleTabContainer}
          onTabSelect={(tabName) => {
            const inputType =
              tabName === INPUT_TYPES.MANUAL.name ? INPUT_TYPES.MANUAL.id : INPUT_TYPES.QUERY.id;
            updateConfig((draft) => (draft.valuesConfig.valuesSource = inputType));
          }}
          selectedTabId={INPUT_TYPES[config.valuesConfig.valuesSource].name}
          tabs={[INPUT_TYPES.MANUAL.name, INPUT_TYPES.QUERY.name]}
        />
      ) : null}
      <div className={styles.section}>
        {config.valuesConfig.valuesSource === INPUT_TYPES.MANUAL.id
          ? renderManualConfig()
          : !isBooleanFilterOperator
            ? renderQueryConfig()
            : null}
      </div>
    </div>
  );
};

function parseArrayString(arrayString: string): string | undefined {
  let error;
  try {
    if (arrayString) {
      const displaysArr = JSON.parse(arrayString);
      if (!Array.isArray(displaysArr)) error = 'Must be valid javascript array.';
    }
  } catch {
    error = 'Must be valid javascript array.';
  }
  return error;
}
