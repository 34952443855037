import { makeStyles, Theme } from '@material-ui/core/styles';
import { FC, useContext, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import BaseDataTable from 'components/dataTable/baseDataTable';
import { sprinkles } from 'components/ds';
import { EmbedDataGrid } from 'components/embed/EmbedDataGrid';
import { convertColumnConfigs, getCellBorders } from 'components/embed/EmbedDataGrid/utils';
import { PDF_PAGE_HEIGHT } from 'constants/dashboardConstants';
import {
  ColumnWidths,
  SECTION_OPTIONS,
  TablePDFFormat,
  UserTransformedSchema,
} from 'constants/types';
import { GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardStates } from 'reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getMetricsByColumn, removeUserDisabledColumns } from 'utils/dashboardUtils';
import { times } from 'utils/standard';
import { replaceVariablesInString } from 'utils/variableUtils';

import { PDFHeader } from './PDFHeader';
import { PDFTableFooter } from './PDFTableFooter';

const ROWS_PER_PAGE_NORMAL = 20;
const ROWS_PER_PAGE_PDF = 17;

const useStyles = makeStyles((theme: Theme) => ({
  tableName: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    fontSize: 20,
    fontWeight: 600,
  },
  table: (styleConfig: GlobalStyleConfig) => ({
    border: `1px solid ${styleConfig.container.outline.color}`,
  }),
}));

type Props = {
  datasetNamesToId: Record<string, string>;
  dataPanelTemplate: DataPanelTemplate;
  defaultUserTransformedSchema: UserTransformedSchema;
  reportTitle: string;
  variables: DashboardVariableMap;
};

export const PDFExportTableView: FC<Props> = ({
  dataPanelTemplate,
  defaultUserTransformedSchema,
  datasetNamesToId,
  reportTitle,
  variables,
}) => {
  const context = useContext(GlobalStylesContext);
  const classes = useStyles(context.globalStyleConfig);
  const { colorCategoryTracker, enableNewGrid, dataPanelData, datasetData } = useSelector(
    (state: DashboardStates) => ({
      colorCategoryTracker: state.dashboardStyles.colorCategoryTracker,
      enableNewGrid: state.dashboardLayout.enableNewGrid,
      dataPanelData: state.dashboardData.dataPanelData[dataPanelTemplate.id],
      datasetData: state.dashboardData.datasetData,
    }),
    shallowEqual,
  );

  const visualizeOperation = dataPanelTemplate.visualize_op;
  const instructions = visualizeOperation.instructions.VISUALIZE_TABLE;
  const headerEnabled = isHeaderEnabled(visualizeOperation.generalFormatOptions?.export?.pdfFormat);
  const numRows = headerEnabled ? ROWS_PER_PAGE_PDF : ROWS_PER_PAGE_NORMAL;

  const processString = (s: string) =>
    replaceVariablesInString(s, variables, datasetNamesToId, datasetData);

  const tableName = processString(reportTitle);
  const rows = dataPanelData?.rows ?? [];
  const numPages = Math.max(Math.ceil(rows.length / numRows), 1);

  const columnConfigs = useMemo(() => {
    if (!enableNewGrid) return;

    return convertColumnConfigs(instructions);
  }, [enableNewGrid, instructions]);

  const showCellBorders = enableNewGrid ? getCellBorders(instructions) : undefined;
  const secondaryData = dataPanelData?.secondaryData ?? [];

  return (
    <div className={embedSprinkles({ backgroundColor: 'background' })}>
      {times(numPages, (i) => {
        const startRow = i * numRows;
        const currRows = rows.slice(i * numRows, (i + 1) * numRows);

        return (
          <div
            className={sprinkles({ width: 'fill', overflow: 'hidden', display: 'flex' })}
            key={`pdf-page-${i}`}
            style={{ height: PDF_PAGE_HEIGHT }}>
            <div className={pageClass}>
              {headerEnabled && visualizeOperation.generalFormatOptions?.export?.pdfFormat ? (
                <PDFHeader
                  pdfConfig={visualizeOperation.generalFormatOptions.export.pdfFormat}
                  processString={processString}
                />
              ) : null}
              <div className={classes.tableName}>{tableName}</div>
              {enableNewGrid ? (
                <EmbedDataGrid
                  fixedHeight
                  hideHeaderMenu
                  shouldTruncateText
                  colorTracker={colorCategoryTracker[dataPanelTemplate.id]}
                  columnConfigs={columnConfigs}
                  columnFit={instructions.columnFit}
                  columnWidths={instructions.columnWidths as ColumnWidths}
                  datasetData={datasetData}
                  datasetNamesToId={datasetNamesToId}
                  enableBoldFirstColumn={instructions.isFirstColumnBolded}
                  enableBoldHeader={instructions.isColumnHeadersBolded}
                  ignoreInvalidDates={instructions.ignoreInvalidDates}
                  loading={false}
                  metricsByColumn={getMetricsByColumn(secondaryData)}
                  rows={currRows}
                  schema={removeUserDisabledColumns(defaultUserTransformedSchema)}
                  showCellBorders={showCellBorders}
                  variables={variables}
                />
              ) : (
                <BaseDataTable
                  disableRowHeader
                  fill
                  isDashboardTable
                  isPDFTable
                  isSortable
                  noBorderRadius
                  shouldTruncateText
                  truncateEmptyRowSpace
                  unrestrictedHeight
                  useFriendlyNameForHeader
                  className={classes.table}
                  columnWidths={visualizeOperation.instructions.VISUALIZE_TABLE.columnWidths}
                  datasetData={datasetData}
                  datasetNamesToId={datasetNamesToId}
                  ignoreInvalidDates={instructions.ignoreInvalidDates}
                  loading={false}
                  maxRows={numRows}
                  maxRowsPerCell={instructions.maxRowsPerCell}
                  metricsByColumn={getMetricsByColumn(secondaryData)}
                  rows={currRows}
                  schema={removeUserDisabledColumns(defaultUserTransformedSchema)}
                  schemaDisplayOptions={
                    visualizeOperation.instructions.VISUALIZE_TABLE.schemaDisplayOptions
                  }
                  variables={variables}
                />
              )}
              <PDFTableFooter
                numCurrentRows={currRows.length}
                numPages={numPages}
                pageNumber={i + 1}
                startRow={startRow + 1}
                totalRows={rows.length}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const pageClass = sprinkles({
  breakAfter: 'page',
  flex: 1,
  flexItems: 'column',
  padding: 'sp2.5',
});

const isHeaderEnabled = (config: TablePDFFormat | undefined) => {
  if (!config?.headerEnabled) return false;

  return (
    !isOptionBlank(config.leftOption) ||
    !isOptionBlank(config.centerOption) ||
    !isOptionBlank(config.rightOption)
  );
};

const isOptionBlank = (option: SECTION_OPTIONS | undefined) =>
  !option || option === SECTION_OPTIONS.BLANK;
