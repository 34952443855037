import { IconName } from '@blueprintjs/icons';
import { Theme } from '@material-ui/core/styles';
import Color from 'color';

import { BaseCol, DatasetSchema, SchemaDataType } from '@explo/data';

import { DataSourceConfiguration, SupportedDataSource } from 'actions/dataSourceActions';
import { SCHEMA_DATA_TYPES_BY_ID, SchemaDataTypeIcon } from 'constants/dataConstants';
import { AggedChartColumnInfo } from 'constants/types';
import { cloneDeep, debounce, sortBy, throttle } from 'utils/standard';

export const isUUID = (potentialUUIDString: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    potentialUUIDString,
  );
};

export const isValidHex = (str: string): boolean => {
  // Remove leading "#" if present
  if (str.startsWith('#')) str = str.slice(1);
  const strLen = str.length;
  // Hex has to be 3 or 6 characters long
  if (strLen !== 3 && strLen !== 6) return false;

  // Check if the string is a valid hexadecimal value
  const hexRegex = /^[0-9A-Fa-f]+$/g;
  return hexRegex.test(str);
};

export const getValidHex = (str: string): string | undefined => {
  if (!isValidHex(str)) return;
  return str.startsWith('#') ? str : `#${str}`;
};

// TU/5czd2wxs
export function mixColors(color1: string, color2: string, ratio: number) {
  const color1Object = new Color(color1);
  const color2Object = new Color(color2);

  const r1 = color1Object.red();
  const g1 = color1Object.green();
  const b1 = color1Object.blue();
  const r2 = color2Object.red();
  const g2 = color2Object.green();
  const b2 = color2Object.blue();

  let r = Math.ceil(r1 * ratio + r2 * (1 - ratio));
  let g = Math.ceil(g1 * ratio + g2 * (1 - ratio));
  let b = Math.ceil(b1 * ratio + b2 * (1 - ratio));
  r = Math.min(255, r);
  g = Math.min(255, g);
  b = Math.min(255, b);

  return new Color(`rgb(${r}, ${g}, ${b})`);
}

export function convertAlphaToOpaque(color: string | Color): Color {
  const colorObject = new Color(color);

  const alpha = colorObject.alpha();
  if (alpha === 1) return colorObject.hsl();

  const r = alphaToOpaque(alpha, colorObject.red());
  const g = alphaToOpaque(alpha, colorObject.green());
  const b = alphaToOpaque(alpha, colorObject.blue());

  return new Color(`rgb(${r}, ${g}, ${b})`).hsl();
}

function alphaToOpaque(alpha: number, num: number): number {
  return Math.round(255 - alpha * (255 - num));
}

export function getTextColorForBackground(backgroundColor: string, theme: Theme) {
  return new Color(backgroundColor).isDark() ? theme.palette.ds.white : theme.palette.ds.black;
}

export const createDebouncedFn = (milliseconds: number) =>
  /**
   * Only use at top level
   */
  debounce((fn: () => void) => {
    fn();
  }, milliseconds);

export const createThrottleFn = (milliseconds: number) => {
  return throttle((fn: () => void) => {
    fn();
  }, milliseconds);
};

/** Swap two items in a list */
export function shiftItemsInList<T>(arr: T[], from: number, to: number) {
  const element = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, element);
}

/** Similar to shiftItemsInList but lets you swap objects without knowing their index beforehand */
export function shiftIdsInList<T>(
  arr: T[],
  from: string | number,
  to: string | number,
  getId: (item: T) => string,
) {
  shiftItemsInList(
    arr,
    arr.findIndex((item) => getId(item) === from),
    arr.findIndex((item) => getId(item) === to),
  );
}

export function sortAggregationsByOrderedColumnNames(
  schema: AggedChartColumnInfo[],
  orderedColumnNames?: string[],
) {
  if (!orderedColumnNames || orderedColumnNames.length === 0) {
    return schema;
  }

  return sortBy(schema, (info) => {
    const index = orderedColumnNames.findIndex(
      (colName) => colName === `${info.column.name}_${info.agg.id.toLowerCase()}`,
    );
    return index === -1 ? schema.length : index;
  });
}

export const parseJsonFields = (
  dataSource: SupportedDataSource,
  configuration: DataSourceConfiguration,
) => {
  const parsedConfig = cloneDeep(configuration);

  const { properties, order } = dataSource.configuration_schema;

  let error: string | undefined;
  order.forEach((propertyName) => {
    if (properties[propertyName].type === 'json' && configuration?.[propertyName] !== undefined) {
      try {
        const noNewLines = configuration[propertyName].toString().replace(/\n/g, '\\n');
        parsedConfig[propertyName] = JSON.parse(noNewLines);
      } catch (err) {
        error = err as string | undefined;
      }
    }
  });

  return { parsedConfig, error };
};

export const createColOptionWithIconFromCol = (col: BaseCol, useFriendlyName?: boolean) => ({
  id: col.name,
  name: (useFriendlyName && col.friendly_name) || col.name,
  icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon as IconName | JSX.Element,
});

export const createColOptionsWithIcon = (schema: DatasetSchema, useFriendlyName?: boolean) =>
  schema.map((col) => createColOptionWithIconFromCol(col, useFriendlyName));

export const createColSelectOptionsWithIcon = (schema: DatasetSchema | undefined) =>
  schema?.map((col) => ({
    value: col.name,
    icon: SchemaDataTypeIcon[col.type as SchemaDataType],
  })) ?? [];

export const createListString = (list: string[]): string => {
  const listLen = list.length;
  if (listLen === 0) return '';
  if (listLen === 1) return list[0];
  if (listLen === 2) return `${list[0]} and ${list[1]}`;

  return `${list.slice(0, listLen - 1).join(', ')}, and ${list[listLen - 1]}`;
};
