import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { TwoDimensionalDataPanelUtils } from './twoDimensionalDataPanelUtils';

// Sankey charts require a quotes blank to be able to drilldown. Also use in bar funnel because without it we get bad funnel label formatting.
// Other charts may use this in teh future, but the current decision is to only use for Sankey and Bar Funnel
export const EMPTY_STRING_PLACEHOLDER = "''";

export const replaceEmptyWithPlaceholder = (value: string | number): string => {
  return String(value) === '' ? EMPTY_STRING_PLACEHOLDER : String(value);
};

const replacePlaceholderWithEmpty = (value: string): string => {
  return value === EMPTY_STRING_PLACEHOLDER ? '' : value;
};

export class SankeyChartUtils extends TwoDimensionalDataPanelUtils {
  /**
   * Transforms category and subcategory values for chart menu parameters.
   *
   * Due to Highcharts limitations, empty string values ("") cannot trigger click events
   * in Sankey charts. To work around this:
   * 1. We store empty strings as "''" in the chart data
   * 2. When a click event occurs, we need to transform "''" back to "" for filtering
   * 3. We also need to track if the category is undefined because that would correspond
   * to a drilldown into the "to" only section of a sankey chart. setChartMenu was written
   * with the intention that sub category would always be secondary to category, but that is
   * not the case for sankey charts.
   *
   * @param category -
   * Will be "''" for empty strings.
   * Will be string of undefined for null values.
   * Will be undefined if there is no from filter in the drilldown.
   * @param subCategory -
   * Will be "''" for empty strings.
   * Will be string of undefined for null values.
   * Will be undefined if there is no to filter in the drilldown.
   * @returns Object containing transformed values and ignore flag for filter configuration
   */
  static getChartMenuParams = (
    category: string | undefined,
    subCategory: string | undefined,
  ): Pick<ChartMenuInfo, 'ignoreCategory' | 'category' | 'subCategory'> => {
    const ignoreCategory = category == undefined;
    const chartMenuCategory =
      category == undefined ? String(category) : replacePlaceholderWithEmpty(category);
    const chartMenuSubCategory =
      subCategory != undefined ? replacePlaceholderWithEmpty(subCategory) : subCategory;

    return {
      ignoreCategory,
      category: chartMenuCategory,
      subCategory: chartMenuSubCategory,
    };
  };
}
