import parse from 'url-parse';

import { BOOLEAN, DECIMAL_TYPES, INTEGER_DATA_TYPE, STRING } from '@explo/data';

import { RESERVED_VARIABLES } from 'constants/variables';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { isInteger, isNumber } from 'utils/standard';

import { getUrlParamStringFromDashVars } from './dashboardUtils';

export const isNameValid = (name: string) => name.trim() !== '' && !RESERVED_VARIABLES.has(name);

export const isDefaultValueValid = (type: string, defaultValue: string | undefined) => {
  if (defaultValue === undefined || defaultValue.trim() === '') return true;
  return shouldUseDefaultValue(type, defaultValue);
};

const shouldUseDefaultValue = (type: string, defaultValue: string) => {
  if (defaultValue.trim() === '') return false;

  if (type === BOOLEAN) return defaultValue === 'true' || defaultValue === 'false';
  if (type === INTEGER_DATA_TYPE) return isInteger(parseFloat(defaultValue));
  if (DECIMAL_TYPES.has(type)) return isNumber(parseFloat(defaultValue));
  return type === STRING;
};

export const getDefaultVariablesFromParams = (
  params: Record<string, DashboardParam>,
): DashboardVariableMap => {
  const variables: DashboardVariableMap = {};

  Object.values(params).forEach((param) => {
    const variableValue = getDefaultVariableFromParam(param);
    if (variableValue !== undefined) variables[param.name] = variableValue;
  });

  return variables;
};

export const getDefaultVariableFromParam = ({
  type,
  defaultValue,
}: DashboardParam): DashboardVariable => {
  if (!defaultValue || !shouldUseDefaultValue(type, defaultValue)) return;

  if (DECIMAL_TYPES.has(type)) return parseFloat(defaultValue);
  if (type === INTEGER_DATA_TYPE) return parseInt(defaultValue);
  return defaultValue;
};

export const maybeUpdateUrlParams = (
  shouldUpdateUrlParams: boolean | undefined,
  archetypeProperties: Set<string>,
  newVariables: DashboardVariableMap,
) => {
  if (!shouldUpdateUrlParams) return;

  window?.history?.replaceState?.(
    null,
    '',
    getUrlWithUpdatedParams(window.location.href, archetypeProperties, newVariables),
  );
};

// visible for testing
export const getUrlWithUpdatedParams = (
  url: string,
  archetypeProperties: Set<string>,
  newVariables: DashboardVariableMap,
) => {
  const toUpdate = parse(url);
  const queryParams = getUrlParamStringFromDashVars(newVariables, archetypeProperties);
  toUpdate.set('query', queryParams);

  return toUpdate.href;
};
