import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Icon, sprinkles, Switch, Tabs } from 'components/ds';
import { toggleEditableSectionEditing } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';

import { DisabledLayoutPanel } from '../DisabledLayoutPanel';

import { ChartsConfig } from './ChartsConfig';
import { EditableSectionModals } from './Modals';
import { SectionSettings } from './SectionSettings';
import * as styles from './styles.css';
import { getOrDefault } from 'remotedata';
import { ROUTE_PROVIDERS } from 'constants/routes';

type Props = { dashboardId: number };

enum ConfigTabs {
  CHARTS = 'Charts',
  SETTINGS = 'Settings',
}
const TabNames = Object.values(ConfigTabs);

export const EditableSectionConfig: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const [tabId, setTabId] = useState<string>(ConfigTabs.CHARTS);

  const { config, selectedChartId, isEditingDefaultLayout, dashboardEmbedId } = useSelector(
    (state: ReduxState) => ({
      config: state.dashboardEditConfig.config?.editable_section,
      selectedChartId: state.dashboardInteractions.selectedItem?.id,
      isEditingDefaultLayout: state.dashboardInteractions.isEditingEditableSection,
      dashboardEmbedId: getOrDefault(state.dashboard.currentDashboard, null)?.embed_id ?? '',
    }),
    shallowEqual,
  );

  // If a chart is selected want to set tab to charts tab unless editing default layout
  const previousSelectedChart = usePrevious(selectedChartId);
  useEffect(() => {
    if (!isEditingDefaultLayout && !previousSelectedChart && selectedChartId)
      setTabId(ConfigTabs.CHARTS);
  }, [selectedChartId, isEditingDefaultLayout, previousSelectedChart]);

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        Customer editable section
        <Switch
          onChange={() => dispatch(toggleEditableSectionEditing())}
          switchOn={config?.enabled}
        />
      </div>
      <div className={styles.sectionHeader}>
        <Button
          fillWidth
          className={sprinkles({ flexItems: 'alignCenterBetween' })}
          onClick={() => window.open(ROUTE_PROVIDERS.DASHBOARD_PREVIEW_PAGE(dashboardEmbedId))}
          variant="secondary">
          <span>Preview edit sections as customer</span>
          <Icon name="arrow-up-right-from-square" />
        </Button>
      </div>
      <Tabs onTabSelect={setTabId} selectedTabId={tabId} tabs={TabNames} />
      <div
        className={styles.sectionBody}
        onClick={() => selectedChartId && dispatch(clearSelectedItemThunk())}>
        {config?.enabled ? (
          tabId === ConfigTabs.CHARTS ? (
            <ChartsConfig dashboardId={dashboardId} />
          ) : (
            <SectionSettings settings={config.settings} />
          )
        ) : (
          <DisabledLayoutPanel
            buttonText="Turn on Customer Editable Section"
            onEnable={() => dispatch(toggleEditableSectionEditing())}
            title="Add a customer editable section to the bottom of your dashboard"
          />
        )}
      </div>
      <EditableSectionModals />
    </div>
  );
};
