import { sprinkles } from '@explo/design-system';
import { IconButton, Tooltip } from 'components/ds';
import { FC } from 'react';

interface Props {
  errorMessage: string;
  disabled: boolean;

  onRetry: () => void;
}

export const FetchErrorComponent: FC<Props> = ({ errorMessage, disabled, onRetry }) => {
  return (
    <div className={sprinkles({ color: 'error', flexItems: 'alignCenterBetween' })}>
      <span>{errorMessage}</span>
      <Tooltip text="Retry">
        <IconButton disabled={disabled} name="arrows-rotate" onClick={onRetry} />
      </Tooltip>
    </div>
  );
};
