import { v4 as uuidv4 } from 'uuid';

import { ChartAggregation, AggColInfo, DATE_TYPES, UNSIGNED_INTEGER, FLOAT } from '@explo/data';

import { CustomerReportView } from 'actions/customerReportActions';

export function createBlankView(): CustomerReportView {
  return {
    id: uuidv4(),
    name: 'View 1',
    columnOrder: [],
    hiddenColumns: [],
    filters: [],
    sort: [],
  };
}

export function getNextViewName(views: CustomerReportView[] | undefined) {
  let currentNum = views?.length || 0;
  for (const view of views || []) {
    const viewDigits = view.name.match(/\d+$/)?.[0];
    const viewNum = parseInt(viewDigits || '') || 0;
    if (viewNum > currentNum) currentNum = viewNum;
  }
  return `View ${currentNum + 1}`;
}

export const isCountAggregation = (aggId: ChartAggregation | undefined) =>
  aggId === ChartAggregation.COUNT || aggId === ChartAggregation.COUNT_DISTINCT;

export const getAggregationType = (aggCol: AggColInfo) => {
  const { id } = aggCol.agg;

  if (
    DATE_TYPES.has(aggCol.column.type) &&
    (id === ChartAggregation.MAX || id === ChartAggregation.MIN)
  ) {
    return aggCol.column.type;
  }

  return isCountAggregation(id) ? UNSIGNED_INTEGER : FLOAT;
};
