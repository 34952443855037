import cx from 'classnames';
import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { Folder } from 'types/exploResource';

import * as styles from './styles.css';

type Props = {
  isCard: boolean;
  folder: Folder;
  dotsMenu: JSX.Element | null;
  openFolder: (e: React.MouseEvent) => void;
  additionalEndGroupElement?: JSX.Element | null;
};

export const FolderItem: FC<Props> = ({
  isCard,
  folder,
  dotsMenu,
  openFolder,
  additionalEndGroupElement,
}) => {
  if (!isCard) {
    return (
      <div className={styles.folderListItem} key={folder.id} onClick={(e) => openFolder(e)}>
        <div className={cx(styles.resourceListColumnGroup, sprinkles({ paddingLeft: 'sp1.5' }))}>
          <Icon
            className={sprinkles({ color: 'contentTertiary' })}
            name="folder"
            style={{ width: 26, height: 26 }}
          />
          <div className={styles.folderListName}>{folder.name}</div>
        </div>
        <div className={styles.resourceListEndGroup} onClick={(e) => e.stopPropagation()}>
          {additionalEndGroupElement}
          {dotsMenu}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.resourceCardItem} key={folder.id} onClick={(e) => openFolder(e)}>
      <div className={styles.folderCardInfo}>
        <div onClick={(e) => e.stopPropagation()}>{dotsMenu}</div>
        <Icon
          className={sprinkles({ color: 'contentTertiary' })}
          name="folder"
          style={{ width: 38, height: 38 }}
        />
        <div className={styles.folderCardName}>{folder.name}</div>
      </div>
    </div>
  );
};
