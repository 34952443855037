import { DATE_TYPES, PivotAgg } from '@explo/data';

import { V2TwoDimensionChartInstructions } from 'constants/types';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_WEEK * 4;
const ONE_QUARTER = ONE_MONTH * 3;
const ONE_YEAR = ONE_MONTH * 12;

// Use a custom tick interval + point range for dates based on the bucket id
// Highcharts isn't good at automatically calculating these
export const getTickInterval = (instructions: V2TwoDimensionChartInstructions | undefined) => {
  if (!DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;

  const bucketId = instructions?.categoryColumn?.bucket?.id;

  if (bucketId === PivotAgg.DATE_HOUR) {
    return ONE_HOUR;
  } else if (bucketId === PivotAgg.DATE_DAY) {
    return ONE_DAY;
  } else if (bucketId === PivotAgg.DATE_WEEK) {
    return ONE_WEEK;
  } else if (bucketId === PivotAgg.DATE_MONTH) {
    return ONE_MONTH;
  } else if (bucketId === PivotAgg.DATE_QUARTER) {
    return ONE_QUARTER;
  } else if (bucketId === PivotAgg.DATE_YEAR) {
    return ONE_YEAR;
  } else {
    return;
  }
};
