import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getSelectedCustomer } from 'reducers/customersReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  initializeCustomerSelectorThunk,
  switchCustomerThunk,
} from 'reducers/thunks/customerThunks';
import * as RD from 'remotedata';

import { CustomerSelector } from './CustomerSelector';

type Props = {
  className?: string;
  disabled?: boolean;
  parseUrl?: boolean;
  overwriteSelectedUserGroupToNull?: boolean;
};

export const ViewingAsCustomerSelector: FC<Props> = ({
  className,
  parseUrl,
  disabled,
  overwriteSelectedUserGroupToNull,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { fetchCustomerStatus, selectedCustomer, selectorCustomersStatus } = useSelector(
    (state: ReduxState) => ({
      fetchCustomerStatus: state.customers.fetchCustomerStatus,
      selectorCustomersStatus: state.customers.selectorCustomersStatus,
      selectedCustomer: overwriteSelectedUserGroupToNull
        ? null
        : getSelectedCustomer(state.customers),
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (overwriteSelectedUserGroupToNull || selectedCustomer || !RD.isIdle(fetchCustomerStatus))
      return;
    dispatch(initializeCustomerSelectorThunk(parseUrl));
  }, [
    dispatch,
    fetchCustomerStatus,
    parseUrl,
    selectedCustomer,
    overwriteSelectedUserGroupToNull,
    selectorCustomersStatus,
  ]);

  return (
    <CustomerSelector
      className={className}
      disabled={disabled}
      onSelect={(id: string) => {
        const customerId = parseInt(id);
        if (isNaN(customerId)) return;

        dispatch(switchCustomerThunk(customerId));

        if (!parseUrl) return;
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('customer', id);
        history.replace({ search: queryParams.toString(), hash: location.hash });
      }}
      selectedCustomerId={selectedCustomer?.id}
      selectedCustomerName={selectedCustomer?.name}
    />
  );
};
