import { IconName } from '@blueprintjs/core';
import {
  AGGREGATIONS_TYPES,
  BOOLEAN,
  BooleanDisplayFormat,
  ChartAggregation,
  DATE,
  DATE_PART_INPUT_AGG,
  DATETIME,
  DOUBLE,
  FilterOperationInstructions,
  FLOAT,
  INTEGER_DATA_TYPE,
  OPERATION_TYPES,
  SchemaDataType,
  STRING,
  TIME_DIFF_FORMATS,
  TIMESTAMP,
  UNSIGNED_INTEGER,
} from '@explo/data';

import { IconName as NewIconName } from 'components/ds/Icon/';
import {
  CALENDAR_HEATMAP_CHART_ICON,
  CHOROPLETH_MAP_ICON,
  COLLAPSIBLE_LIST_ICON,
  DECIMAL_ICON,
  DENSITY_MAP_ICON,
  LOCATION_MARKER_MAP_ICON,
  NUMBER_ICON,
  NUMBER_TREND_TEXT_PANEL_ICON,
  REPORT_BUILDER_ICON,
  SANKEY_CHART_ICON,
  SCATTER_PLOT_ICON,
  SPIDER_CHART_ICON,
  TEXT_ICON,
  V2_AREA_100_CHART_ICON,
  V2_AREA_CHART_ICON,
  V2_BAR_FUNNEL_CHART_ICON,
  V2_BOX_PLOT_CHART_ICON,
  V2_COMBO_CHART_ICON,
  V2_DONUT_CHART_ICON,
  V2_FUNNEL_CHART_ICON,
  V2_HEAT_MAP_CHART_ICON,
  V2_HORIZ_100_BAR_CHART_ICON,
  V2_HORIZ_BAR_CHART_ICON,
  V2_HORIZ_GROUPED_BAR_CHART_ICON,
  V2_HORIZ_GROUPED_STACKED_BAR_CHART_ICON,
  V2_KPI_CHART_ICON,
  V2_LINE_CHART_ICON,
  V2_NUMBER_TREND_ICON,
  V2_PIE_CHART_ICON,
  V2_PROGRESS_CHART_ICON,
  V2_VERT_100_BAR_CHART_ICON,
  V2_VERT_BAR_CHART_ICON,
  V2_VERT_GROUPED_BAR_CHART_ICON,
  V2_VERT_GROUPED_STACKED_BAR_CHART_ICON,
} from 'constants/iconConstants';
import { ChartTypeSections } from 'constants/types';

export const SCATTER_X_AXIS_TYPES = [INTEGER_DATA_TYPE, FLOAT, STRING, DOUBLE];
export const SCATTER_Y_AXIS_TYPES = [INTEGER_DATA_TYPE, FLOAT, DOUBLE];

export const VIZ_OP_WITH_CSV_DOWNLOAD = new Set([
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP,
  OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_SPIDER_CHART,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
  OPERATION_TYPES.VISUALIZE_SANKEY_CHART,
  OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP,
  OPERATION_TYPES.VISUALIZE_DENSITY_MAP,
]);

export const SELECTABLE_CHARTS = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_PROGRESS_V2,
]);

export const KPI_VIZ_OPS = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_PROGRESS_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL,
]);

export const VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
]);

export const VIZ_OPS_WITH_CUSTOM_MENU = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_SANKEY_CHART,
  OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
]);
export const MAX_ROWS_TO_PREVIEW = 50;
export const MAX_ROWS_PER_CELL = 100;

export const SchemaDataTypeIcon: Record<SchemaDataType, NewIconName> = {
  [INTEGER_DATA_TYPE]: 'report-builder-number',
  [UNSIGNED_INTEGER]: 'report-builder-number',
  [FLOAT]: 'report-builder-decimal',
  [DOUBLE]: 'report-builder-decimal',
  [STRING]: 'report-builder-string',
  [BOOLEAN]: 'tick',
  [DATETIME]: 'calendar',
  [DATE]: 'calendar',
  [TIMESTAMP]: 'calendar',
};

export const SCHEMA_DATA_TYPES_BY_ID: Record<
  string,
  { name: string; icon: IconName | JSX.Element }
> = {
  [INTEGER_DATA_TYPE]: {
    name: 'Integer',
    icon: NUMBER_ICON,
  },
  [UNSIGNED_INTEGER]: {
    name: 'Unsigned Integer',
    icon: NUMBER_ICON,
  },
  [FLOAT]: {
    name: 'Decimal',
    icon: DECIMAL_ICON,
  },
  [DOUBLE]: {
    name: 'Decimal',
    icon: DECIMAL_ICON,
  },
  [STRING]: {
    name: 'Text',
    icon: TEXT_ICON,
  },
  [BOOLEAN]: {
    name: 'Boolean',
    icon: 'tick',
  },
  [DATETIME]: {
    name: 'Date',
    icon: 'calendar',
  },
  [DATE]: {
    name: 'Date',
    icon: 'calendar',
  },
  [TIMESTAMP]: {
    name: 'Timestamp',
    icon: 'calendar',
  },
};

export type DatePartInputAgg = typeof DATE_PART_INPUT_AGG;

export const FORMULA_AGG_TYPE = AGGREGATIONS_TYPES[ChartAggregation.FORMULA];

export const NON_AGG_TYPE = AGGREGATIONS_TYPES[ChartAggregation.FIRST];

export const AGGREGATIONS_LIST = Object.values(AGGREGATIONS_TYPES).filter(
  (agg) => agg.id !== ChartAggregation.FORMULA && agg.id !== ChartAggregation.FIRST,
);

export const NON_NUMBER_AGGREGATIONS_LIST = [
  AGGREGATIONS_TYPES.COUNT,
  AGGREGATIONS_TYPES.COUNT_DISTINCT,
];

export const DATE_AGGREGATIONS_LIST = [
  AGGREGATIONS_TYPES.COUNT,
  AGGREGATIONS_TYPES.COUNT_DISTINCT,
  AGGREGATIONS_TYPES.MIN,
  AGGREGATIONS_TYPES.MAX,
];

export const COLLAPSIBLE_LIST_AGGREGATION_LIST = [
  AGGREGATIONS_TYPES.COUNT,
  AGGREGATIONS_TYPES.COUNT_DISTINCT,
  AGGREGATIONS_TYPES.SUM,
];

export const OP_TYPE_TO_BP3_ICON: Record<OPERATION_TYPES, IconName | JSX.Element> = {
  VISUALIZE_TABLE: 'th',
  FILTER: 'filter',
  GROUP_BY: 'filter',
  VISUALIZE_VERTICAL_BAR_V2: V2_VERT_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_100_BAR_V2: V2_VERT_100_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_GROUPED_BAR_V2: V2_VERT_GROUPED_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2: V2_VERT_GROUPED_STACKED_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_BAR_V2: V2_HORIZ_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_100_BAR_V2: V2_HORIZ_100_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_GROUPED_BAR_V2: V2_HORIZ_GROUPED_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2: V2_HORIZ_GROUPED_STACKED_BAR_CHART_ICON,
  VISUALIZE_PIE_CHART_V2: V2_PIE_CHART_ICON,
  VISUALIZE_DONUT_CHART_V2: V2_DONUT_CHART_ICON,
  VISUALIZE_LINE_CHART_V2: V2_LINE_CHART_ICON,
  VISUALIZE_AREA_CHART_V2: V2_AREA_CHART_ICON,
  VISUALIZE_AREA_100_CHART_V2: V2_AREA_100_CHART_ICON,
  VISUALIZE_COMBO_CHART_V2: V2_COMBO_CHART_ICON,
  VISUALIZE_HEAT_MAP_V2: V2_HEAT_MAP_CHART_ICON,
  VISUALIZE_CALENDAR_HEATMAP: CALENDAR_HEATMAP_CHART_ICON,
  VISUALIZE_SCATTER_PLOT_V2: SCATTER_PLOT_ICON,
  VISUALIZE_SPIDER_CHART: SPIDER_CHART_ICON,
  VISUALIZE_FUNNEL_V2: V2_FUNNEL_CHART_ICON,
  VISUALIZE_VERTICAL_BAR_FUNNEL_V2: V2_BAR_FUNNEL_CHART_ICON,
  VISUALIZE_NUMBER_V2: V2_KPI_CHART_ICON,
  VISUALIZE_BOX_PLOT_V2: V2_BOX_PLOT_CHART_ICON,
  VISUALIZE_PROGRESS_V2: V2_PROGRESS_CHART_ICON,
  VISUALIZE_NUMBER_TREND_V2: V2_NUMBER_TREND_ICON,
  VISUALIZE_NUMBER_TREND_TEXT_PANEL: NUMBER_TREND_TEXT_PANEL_ICON,
  VISUALIZE_CHOROPLETH_MAP: CHOROPLETH_MAP_ICON,
  VISUALIZE_LOCATION_MARKER_MAP: LOCATION_MARKER_MAP_ICON,
  VISUALIZE_DENSITY_MAP: DENSITY_MAP_ICON,
  VISUALIZE_REPORT_BUILDER: REPORT_BUILDER_ICON,
  VISUALIZE_PIVOT_REPORT_BUILDER: REPORT_BUILDER_ICON,
  VISUALIZE_PIVOT_TABLE: 'th',
  VISUALIZE_COLLAPSIBLE_LIST: COLLAPSIBLE_LIST_ICON,
  VISUALIZE_SANKEY_CHART: SANKEY_CHART_ICON,
  VISUALIZE_PIVOT_TABLE_V2: 'th',
};

export const VIZ_TO_SECTION: Record<string, string> = {
  [OPERATION_TYPES.VISUALIZE_NUMBER_V2]: ChartTypeSections.PERFORMANCE_INDICATOR,
  [OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: ChartTypeSections.PERFORMANCE_INDICATOR,
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: ChartTypeSections.PERFORMANCE_INDICATOR,
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL]: ChartTypeSections.PERFORMANCE_INDICATOR,

  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: ChartTypeSections.BAR_CHART,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]: ChartTypeSections.BAR_CHART,

  [OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: ChartTypeSections.LINE_CHART,
  [OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: ChartTypeSections.LINE_CHART,

  [OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: ChartTypeSections.AREA_CHART,
  [OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: ChartTypeSections.AREA_CHART,

  [OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: ChartTypeSections.PIE_CHART,
  [OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: ChartTypeSections.PIE_CHART,

  [OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: ChartTypeSections.FUNNEL,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: ChartTypeSections.FUNNEL,

  [OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: ChartTypeSections.SPECIAL,
  [OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP]: ChartTypeSections.SPECIAL,
  [OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: ChartTypeSections.SPECIAL,
  [OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: ChartTypeSections.SPECIAL,
  [OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: ChartTypeSections.SPECIAL,
  [OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST]: ChartTypeSections.SPECIAL,
  [OPERATION_TYPES.VISUALIZE_SANKEY_CHART]: ChartTypeSections.SPECIAL,

  [OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP]: ChartTypeSections.MAP,
  [OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP]: ChartTypeSections.MAP,
  [OPERATION_TYPES.VISUALIZE_DENSITY_MAP]: ChartTypeSections.MAP,
};

export const VIZ_TO_NAME: Record<OPERATION_TYPES, string> = {
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Bar Chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: '100% Bar Chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'Grouped Bar Chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: 'Grouped Stacked Bar Chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'Horizontal Bar Chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: '100% Horizontal Bar Chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'Horizontal Grouped Bar Chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]:
    'Horizontal Grouped Stacked Bar Chart',
  [OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Pie Chart',
  [OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Donut Chart',
  [OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Line Chart',
  [OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'Area Chart',
  [OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: '100% Area Chart',
  [OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'Combo Chart',
  [OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'Heat Map',
  [OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP]: 'Calendar Heat Map',
  [OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: 'Scatter Plot',
  [OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: 'Spider Chart',
  [OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'Funnel',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: 'Bar Funnel',
  [OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
  [OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'Box Plot',
  [OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'Progress',
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'KPI Trend',
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL]: 'KPI Trend Text Panel',
  [OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP]: 'Choropleth Map',
  [OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP]: 'Location Marker Map',
  [OPERATION_TYPES.VISUALIZE_DENSITY_MAP]: 'Density Heat Map',
  [OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST]: 'Collapsible List',
  [OPERATION_TYPES.VISUALIZE_TABLE]: 'Table',
  [OPERATION_TYPES.VISUALIZE_REPORT_BUILDER]: 'Report Builder',
  [OPERATION_TYPES.VISUALIZE_PIVOT_TABLE]: 'Pivot Table',
  [OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER]: 'Pivot Report Builder',
  [OPERATION_TYPES.VISUALIZE_SANKEY_CHART]: 'Sankey Chart',
  [OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2]: 'Pivot Table V2',
  [OPERATION_TYPES.FILTER]: '',
  [OPERATION_TYPES.GROUP_BY]: '',
};

export const VIZ_TO_HELP_DOCS: Record<OPERATION_TYPES, string | undefined> = {
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]: 'bar-chart',
  [OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'pie-chart',
  [OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'pie-chart',
  [OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'line-chart',
  [OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'area-chart',
  [OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: 'area-chart',
  [OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'line-chart',
  [OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'heat-map',
  [OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP]: 'calendar-heat-map',
  [OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: 'scatter-plot',
  [OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: 'spider-chart',
  [OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'funnel-chart',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: 'funnel-chart',
  [OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'kpi',
  [OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'box-plot',
  [OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'kpi',
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'kpi-trend',
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL]: 'kpi-trend',
  [OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP]: 'choropleth-map',
  [OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP]: 'location-marker-map',
  [OPERATION_TYPES.VISUALIZE_DENSITY_MAP]: 'density-heat-map',
  [OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST]: 'collapsible-list',
  [OPERATION_TYPES.VISUALIZE_TABLE]: 'data-table',
  [OPERATION_TYPES.VISUALIZE_REPORT_BUILDER]: undefined,
  [OPERATION_TYPES.VISUALIZE_PIVOT_TABLE]: 'pivot-table',
  [OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2]: 'pivot-table-v2',
  [OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER]: undefined,
  [OPERATION_TYPES.VISUALIZE_SANKEY_CHART]: undefined,
  [OPERATION_TYPES.FILTER]: undefined,
  [OPERATION_TYPES.GROUP_BY]: undefined,
};

// If chart types have different types in this map, then when a user switches
// between charts of different types, it reloads the data from the backend.
// The implication is that the way the data needs to be calculated is different
// and so we have to refresh the data with the new chart type/instructions
export const V2_VIZ_INSTRUCTION_TYPE: Record<string, string> = {
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: 'Grouped Stacked Bar Chart',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]: 'Grouped Stacked Bar Chart',
  [OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: 'Scatter Plot',
  [OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'Box & Whisker Plot',
  [OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
  [OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'KPI',
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'Number Trend',
  [OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL]: 'Number Trend',
  [OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_SANKEY_CHART]: 'Two-dimensional',
  [OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP]: 'Geospatial',
  [OPERATION_TYPES.VISUALIZE_DENSITY_MAP]: 'Geospatial',
};

enum SCROLL_DIRECTION {
  VERTICAL = 'Vertical',
  HORIZONTAL = 'Horizontal',
}
export const BAR_CHART_SCROLL_DIRECTION: Record<string, string> = {
  [OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
  [OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
};

export const V2_NUMBER_FORMATS = {
  NUMBER: {
    id: 'NUMBER',
    name: 'Number',
    abbreviation: '#',
  },
  ABBREVIATED: {
    id: 'ABBREVIATED',
    name: 'Abbreviated Number',
    abbreviation: 'abbr.',
  },
  CURRENCY: {
    id: 'CURRENCY',
    name: 'Currency ($)',
    abbreviation: '$',
  },
  PERCENT: {
    id: 'PERCENT',
    name: 'Percent (%)',
    abbreviation: '%',
  },
  TIME: {
    id: 'TIME',
    name: 'Time (00:00)',
    abbreviation: '00:00',
  },
};

export const NUMBER_FORMATS_WITH_DECIMALS = [
  V2_NUMBER_FORMATS.NUMBER.id,
  V2_NUMBER_FORMATS.CURRENCY.id,
  V2_NUMBER_FORMATS.PERCENT.id,
];

export const TIME_DIFF_FORMAT_SELECT_OPTIONS = Object.values(TIME_DIFF_FORMATS).map(
  ({ id, name }) => ({ value: id, label: name }),
);

export const EMPTY_FILTER_CONFIG: FilterOperationInstructions = {
  matchOnAll: true,
  filterClauses: [],
};

export const DEFAULT_BOOLEAN_DISPLAY_OPTIONS: Record<
  'trueIcon' | 'falseIcon',
  BooleanDisplayFormat
> = {
  falseIcon: BooleanDisplayFormat.CROSS,
  trueIcon: BooleanDisplayFormat.TICK,
};

export const BOOLEAN_DISPLAY_FORMATS: BooleanDisplayFormat[] = [
  BooleanDisplayFormat.TICK,
  BooleanDisplayFormat.CROSS,
  BooleanDisplayFormat.BLANK,
];

export const VIRTUALIZATION_THRESHOLD = 50;
