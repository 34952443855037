import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';
import { COMPUTED_VIEW_TYPE } from './constants';
import { ComputedView } from '@explo-tech/fido-api';

/** @return A skeletal computed view that only contains the ids. Do not use this in an actual context that requires complete computed view properties. */
const adaptReferenceToSkeletalComputedView = (
  reference: VersionedComputedViewReference,
): ComputedViewWithIds => {
  return {
    id: reference.id,
    name: '',
    namespaceId: reference.namespaceId,
    query: '',
    path: null,
    description: null,
    columnDefinitions: [],
    parameters: [],
    cacheEvictionPolicy: null,
    '@type': COMPUTED_VIEW_TYPE,
  };
};

export const convertReferencesToComputedViewsMap = (
  datasetReferences: Record<string, VersionedComputedViewReference>,
) => {
  return Object.values(datasetReferences).reduce(
    (datasetReferencesMap, reference) => {
      datasetReferencesMap[reference.id] = adaptReferenceToSkeletalComputedView(reference);
      return datasetReferencesMap;
    },
    {} as Record<string, ComputedView>,
  );
};
