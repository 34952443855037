import { MenuItem } from '@blueprintjs/core';
import { FC } from 'react';

import { sprinkles } from '@explo/design-system';
import { OPERATION_TYPES } from '@explo/data';

import { Dataset } from 'actions/datasetActions';
import { isVizOptionDisabled } from 'utils/aggUtils';

interface Props {
  dataset: Dataset;
  selectedDatasetId: string | undefined;
  dataPanelOperationType: OPERATION_TYPES;
  parentSchemaSourceTypes: Record<number, string>;
  isGlobalDataset: boolean;

  onClick: (datasetId: string) => void;
}

export const DataPanelModalDatasetItem: FC<Props> = ({
  dataset,
  selectedDatasetId,
  dataPanelOperationType,
  parentSchemaSourceTypes,
  isGlobalDataset,
  onClick,
}) => {
  const isDisabled = isVizOptionDisabled(
    dataPanelOperationType,
    parentSchemaSourceTypes[dataset.parent_schema_id],
  );

  return (
    <MenuItem
      className={dataset.id === selectedDatasetId ? selectedItemClass : undefined}
      // TODO we really should have a tooltip saying "box plot is disabled for rockset datasets"
      disabled={isDisabled}
      icon={isGlobalDataset ? 'globe' : 'th'}
      key={`dataset-add-data-panel-${dataset.id}`}
      onClick={() => onClick(dataset.id)}
      text={dataset.table_name || 'Untitled'}
    />
  );
};

const selectedItemClass = sprinkles({ backgroundColor: 'lightBlue' });
