import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { BaseTableInstructions } from 'constants/types';

type Props = {
  instructions: BaseTableInstructions;
  visualizationType: OPERATION_TYPES;
};

export const TableFooterConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader
        name="Footer"
        switchProps={{
          isOn: !instructions.isFooterHidden,
          onChange: () => {
            const newInstructions = produce(instructions, (draft) => {
              draft.isFooterHidden = !draft.isFooterHidden;
            });

            dispatch(updateVisualizeOperation(newInstructions, visualizationType));
          },
        }}
      />
    </>
  );
};
