import { DateTime } from 'luxon';

import { getTimezoneAwareDate } from '@explo/data';

import { TeamMember } from 'actions/userActions';

// Returns a message that describes when and who (if available) created the resource.
export const getCreatorDetailMessage = (
  creationUser: TeamMember | undefined,
  creationTime: string,
) => {
  const baseDate = getTimezoneAwareDate(creationTime)
    // Set the locale to english for this specific instance because we don't want the app builder
    // itself to be translated yet
    .setLocale('en-us');

  const creatorName = creationUser
    ? `${creationUser.first_name} ${creationUser.last_name}`
    : FORMER_TEAMMATE_TEXT;

  return `${creatorName} on ${baseDate.toLocaleString(DateTime.DATE_FULL)}`;
};

const FORMER_TEAMMATE_TEXT = 'Former Teammate';
