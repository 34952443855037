import { ROUTES } from 'constants/routes';

import { NavItemInfo } from './SidebarItem';

export const NUM_DAYS_IN_TRIAL = 7;
export const EXPANDED_STORAGE_KEY = 'saved_is_expanded_state';
export const TOOLTIP_SIDE = 'right';
export const TOOLTIP_SIDE_OFFSET = 12;
export const CHAT_TEXT = 'Chat';
export const DOCS_TEXT = 'Docs';

export const SIDEBAR_EXPANDED_WIDTH = 256;
export const SIDEBAR_COLLAPSED_WIDTH = 64;

export const NAV_ITEMS: Record<string, NavItemInfo> = {
  DATA: {
    id: 'DATA',
    href: ROUTES.DATA_PAGE,
    name: 'Data',
    icon: 'database-reg',
    selectedIcon: 'database',
  },
  CUSTOMERS: {
    id: 'CUSTOMERS',
    href: ROUTES.CUSTOMERS_PAGE,
    name: 'Customers',
    selectedIcon: 'users',
    icon: 'users-reg',
  },
  STYLES: {
    id: 'STYLES',
    href: ROUTES.GLOBAL_CUSTOM_STYLES_PAGE,
    name: 'Styles',
    icon: 'paint-roller-reg',
    selectedIcon: 'paint-roller',
  },

  ANALYTICS: {
    id: 'ANALYTICS',
    href: ROUTES.ANALYTICS,
    name: 'Analytics',
    icon: 'chart-line-reg',
    selectedIcon: 'chart-line',
  },
  DASHBOARDS: {
    id: 'DASHBOARDS',
    href: ROUTES.HOME_APP_PAGE,
    name: 'Dashboard',
    icon: 'sidebar-dashboards',
    selectedIcon: 'sidebar-dashboards-filled',
  },
  REPORT_BUILDER: {
    id: 'REPORT_BUILDER',
    href: ROUTES.REPORT_BUILDER,
    name: 'Report Builder',
    icon: 'sidebar-report',
    selectedIcon: 'sidebar-report-filled',
  },
  SUPERUSER: {
    id: 'SUPERUSER',
    href: ROUTES.SUPERUSER,
    name: 'Superuser',
    icon: 'star',
  },
  DATA_LIBRARY: {
    id: 'DATA_LIBRARY',
    href: ROUTES.DATA_LIBRARY,
    name: 'Data Library',
    icon: 'books',
    selectedIcon: 'books-fill',
  },
};
