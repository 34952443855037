import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Switch, Input, Toggle, ToggleItem } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { SankeyChartFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
};

export const SankeyChartConfigs: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();

  const config = instructions.chartSpecificFormat?.sankeyChart ?? {};
  const selectedFormat = config.numberFormat || V2_NUMBER_FORMATS.NUMBER.id;

  const updateConfig = (updates: Partial<SankeyChartFormat>) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.chartSpecificFormat) draft.chartSpecificFormat = {};
      if (!draft.chartSpecificFormat.sankeyChart) draft.chartSpecificFormat.sankeyChart = {};

      draft.chartSpecificFormat.sankeyChart = {
        ...draft.chartSpecificFormat?.sankeyChart,
        ...updates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_SANKEY_CHART));
  };

  const showPercentageValue = config.showPctOnChart
    ? config.showPctOnTooltips
      ? 'both'
      : 'chart'
    : config.showPctOnTooltips
      ? 'tooltip'
      : undefined;

  return (
    <div className={configRootClass}>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={config.units ?? ''}
        label="Units"
        onSubmit={(newValue) => updateConfig({ units: newValue })}
      />
      <Switch
        className={configInputClass}
        label="Show Percentages"
        onChange={() =>
          updateConfig({
            showPercentages: !config.showPercentages,
            showPctOnTooltips: true,
            showPctOnChart: false,
          })
        }
        switchOn={config.showPercentages}
      />
      {config.showPercentages ? (
        <Toggle
          className={configInputClass}
          onValueChange={(val) =>
            updateConfig({ showPctOnChart: val !== 'tooltip', showPctOnTooltips: val !== 'chart' })
          }
          selectedValue={showPercentageValue}>
          <ToggleItem value="chart">On Chart</ToggleItem>
          <ToggleItem value="tooltip">In Tooltip</ToggleItem>
          <ToggleItem value="both">Both</ToggleItem>
        </Toggle>
      ) : null}
      <Switch
        className={configInputClass}
        label="Show Values"
        onChange={() => updateConfig({ showValues: !config.showValues })}
        switchOn={config.showValues}
      />
      <NumberFormatToggle
        disableAbbr
        disableTime
        className={configInputClass}
        label="Value Format"
        selectedFormat={selectedFormat}
        updateFormat={(numberFormat) => updateConfig({ numberFormat })}
      />
      <Input
        fillWidth
        showInputButton
        className={configInputClass}
        defaultValue={String(config.decimalPlaces ?? 2)}
        label="Decimal Places"
        onSubmit={(newValue) => {
          const intValue = parseInt(newValue);
          updateConfig({ decimalPlaces: intValue >= 0 ? intValue : 2 });
        }}
      />
      <Input
        fillWidth
        showInputButton
        className={configInputClass}
        defaultValue={String(config.multiplier ?? 1)}
        label="Multiplier"
        onSubmit={(newValue) => {
          const newNum = Number(newValue);
          if (isNaN(newNum)) return;

          updateConfig({ multiplier: newNum });
        }}
      />
    </div>
  );
};
