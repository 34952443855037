import GridLayout, {
  Layout,
  ReactGridLayoutProps,
  Responsive as ResponsiveGridLayout,
} from '@explo-tech/react-grid-layout';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CustomerEditableSectionLayout } from 'actions/dashboardActions';
import { DataPanelWrapper } from 'components/DashboardLayout/DataPanelWrapper';
import * as wrapperStyles from 'components/DashboardLayout/wrapperStyles.css';
import { ResizeHandle } from 'components/ResizeHandle';
import { vars } from 'components/ds';
import {
  DASHBOARD_ROW_HEIGHT,
  DRAGGABLE_HANDLE_CLASS,
  PDF_EDITOR_MARGIN_SIZE,
} from 'constants/dashboardConstants';
import { getEditableSectionDataPanel } from 'reducers/thunks/dashboardDataThunks/utils';
import { selectItemOnDashboardThunk } from 'reducers/thunks/dashboardSelectionThunks';
import {
  removeChartFromEditableSectionThunk,
  updateEditableSectionLayoutThunk,
} from 'reducers/thunks/editableSectionThunks';
import { DashboardElement, DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { EditableSectionConfig } from 'types/dashboardVersionConfig';
import { doesDpEndOnRightHalfOfPage } from 'utils/dashboardUtils';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { getEmptySectionHeight, isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import { sortBy } from 'utils/standard';
import { ComputedView } from '@explo-tech/fido-api';
import { Dataset } from 'actions/datasetActions';

type Props = {
  cols: number;
  config: EditableSectionConfig;
  datasets: Record<string, Dataset>;
  elements: DashboardElement[];
  isEditing: boolean;
  isEditingDashboard: boolean;
  isViewOnly: boolean;
  layout: CustomerEditableSectionLayout[];
  margin: number;
  width: number | undefined;
  viewMode: VIEW_MODE;
  variables: DashboardVariableMap | null;
  referencedGlobalDatasets: Record<string, ComputedView>;
};

export const EditableSectionLayout: FC<Props> = ({
  cols,
  config,
  elements,
  datasets,
  layout,
  isEditing,
  isViewOnly,
  margin,
  width,
  viewMode,
  isEditingDashboard,
  variables,
  referencedGlobalDatasets,
}) => {
  const dispatch = useDispatch();

  const sortedLayout = useMemo(() => sortBy(layout, (elem) => -(elem.y * elem.x)), [layout]);
  const datasetNamesToId = useMemo(
    () => getDatasetNamesToId(datasets, referencedGlobalDatasets),
    [datasets, referencedGlobalDatasets],
  );

  const onLayoutChange = useCallback(
    (newLayout: Layout[]) => {
      dispatch(updateEditableSectionLayoutThunk(newLayout));
    },
    [dispatch],
  );

  const layoutCharts = useMemo(
    () =>
      sortedLayout.map((chartLayout) => {
        const chart = Object.values(config.charts).find((c) =>
          isChartInstanceOfTemplate(chartLayout, c),
        );

        const { i } = chartLayout;
        const dpEndsOnRightSide = doesDpEndOnRightHalfOfPage(
          layout,
          i,
          cols,
          chart?.data_panel?.visualize_op.operation_type,
        );

        const backingGlobalDataset =
          referencedGlobalDatasets[chart?.data_panel?.globalDatasetReference?.id ?? ''] ?? null;

        return (
          <DataPanelWrapper
            backingGlobalDataset={backingGlobalDataset}
            dashboardElements={elements}
            dataPanel={chart ? getEditableSectionDataPanel(i, chart.data_panel) : undefined}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets}
            dpEndsOnRightSide={dpEndsOnRightSide}
            hideEditingElements={isEditingDashboard && !isEditing}
            isDragging={false}
            isEditing={isEditingDashboard || isEditing}
            isResizing={false}
            isViewOnly={isViewOnly}
            key={i}
            onDelete={() => dispatch(removeChartFromEditableSectionThunk(i))}
            onSelect={() =>
              dispatch(selectItemOnDashboardThunk(i, { setEditableSection: isEditingDashboard }))
            }
            pageType={PAGE_TYPE.EXPLO_APP}
            referencedGlobalDatasets={referencedGlobalDatasets}
            variables={variables ?? {}}
          />
        );
      }),
    [
      cols,
      config.charts,
      datasetNamesToId,
      datasets,
      dispatch,
      elements,
      isEditing,
      isEditingDashboard,
      isViewOnly,
      layout,
      referencedGlobalDatasets,
      sortedLayout,
      variables,
    ],
  );

  const marginBottom =
    viewMode === VIEW_MODE.PDF
      ? -(PDF_EDITOR_MARGIN_SIZE / 2)
      : isEditing
        ? getEmptySectionHeight(margin)
        : 0;

  let style = { marginBottom };
  if (isEditing) style = { ...style, ...wrapperVars };

  const sharedProps: Partial<ReactGridLayoutProps> = {
    margin: [margin, margin],
    rowHeight: DASHBOARD_ROW_HEIGHT,
    style,
    width: width ?? 0,
    isDraggable: isEditing,
    isResizable: isEditing,
  };

  if (isViewOnly) {
    const [half, xs] = viewMode == VIEW_MODE.EMAIL ? [6, 6] : [Math.ceil(cols / 2), 1];
    return (
      <ResponsiveGridLayout
        {...sharedProps}
        cols={{ lg: cols, md: cols, sm: half, xs, xxs: xs }}
        layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}>
        {layoutCharts}
      </ResponsiveGridLayout>
    );
  }

  return (
    <GridLayout
      {...sharedProps}
      cols={cols}
      draggableHandle={`.${DRAGGABLE_HANDLE_CLASS}`}
      layout={layout}
      onDragStart={(_, item) =>
        dispatch(selectItemOnDashboardThunk(item.i, { setEditableSection: isEditingDashboard }))
      }
      onLayoutChange={onLayoutChange}
      resizeHandle={<ResizeHandle />}>
      {layoutCharts}
    </GridLayout>
  );
};

const wrapperVars = assignInlineVars({
  [wrapperStyles.activeColor]: vars.colors.active,
  [wrapperStyles.activeHoverColor]: vars.colors.activeHovered,
  [wrapperStyles.activeContrastColor]: vars.colors.activeContrast,
  [wrapperStyles.activeSubduedColor]: vars.colors.activeSubdued,
  [wrapperStyles.activeSubduedContrastColor]: vars.colors.activeSubduedContrast,
});
