import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { ROUTES } from 'constants/routes';

import { User } from 'actions/userActions';
import { PageWithNavbar } from 'components/pages/pagewithNavBar';
import { MaintenancePage } from 'pages/MaintenancePage';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageComponent: any;
  permissionFn?: (user: User) => boolean;
  withNavigation?: boolean;
  noMatch?: boolean;
  activeTabId?: string;
  exact?: boolean;
  path?: string;
  shouldRedirectForMaintenance?: boolean;
};

const PrivateRoute = ({
  pageComponent: Component,
  withNavigation,
  noMatch,
  permissionFn,
  activeTabId,
  exact,
  path,
  shouldRedirectForMaintenance,
}: Props) => {
  const currentUser = useSelector((state: ReduxState) => state.currentUser);

  const routeComponent = (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        // if the user isn't logged in, send them to the login page if they're trying to go to a page
        // that requires a session
        if (!currentUser.logged_in) {
          if (
            props.location.pathname === ROUTES.LOGIN ||
            props.location.pathname === ROUTES.CHECK_YOUR_EMAIL ||
            props.location.pathname === ROUTES.VERIFY_EMAIL
          )
            return <Component {...props} />;

          // otherwise redirect them to login
          return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        }
        // otherwise if the user doesn't have a team, send them to the join team page
        else if (!currentUser.has_team) {
          if (props.location.pathname === ROUTES.JOIN_TEAM) return <Component {...props} />;
          return <Redirect to={{ pathname: ROUTES.JOIN_TEAM }} />;
        }
        // otherwise if the user's team is deactivated, send them to the billing page
        else if (currentUser.team?.payment_plan === PLAN_TYPES.DEACTIVATED) {
          if (props.location.pathname === ROUTES.BILLING_PAGE) return <Component {...props} />;
          return <Redirect to={{ pathname: ROUTES.BILLING_PAGE }} />;
        }
        // otherwise, if the user is going to the login page, send them to the home page
        else if (props.location.pathname === ROUTES.LOGIN) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        }
        // otherwise, if they're not going to a valid page, send them to the home page
        else if (noMatch) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        }
        // otherwise, send them to whatever page they were going to
        else {
          // first, check generic permissions on the page to see if they can go there
          if (permissionFn && !permissionFn(currentUser)) {
            return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
          }

          // then, check if the page blocked for maintenance. Making the educated bet here that any page
          // we're likely to take "under maintenance" would fall into this else category
          if (
            shouldRedirectForMaintenance &&
            !!currentUser.team?.feature_flags.is_under_maintenance
          ) {
            return <MaintenancePage />;
          }

          return <Component {...props} />;
        }
      }}
    />
  );

  return withNavigation ? (
    <PageWithNavbar activeTabId={activeTabId}>{routeComponent}</PageWithNavbar>
  ) : (
    routeComponent
  );
};

export default PrivateRoute;
