import cx from 'classnames';
import JSURL from 'jsurl';
import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';

import { embedFetchDashboard } from 'actions/embedActions';
import { ChartLayout } from 'components/ChartLayout/ChartLayout';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import {
  DASHBOARD_CLASS_NAME,
  DASHBOARD_LOADED_CLASS_NAME,
  DATA_PANEL_ERROR_CLASS_NAME,
} from 'constants/exportConstants';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { UserTransformedSchema } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { GlobalStylesProvider } from 'globalStyles';
import { loadFonts } from 'globalStyles/utils';
import { getCurrentTheme, setDashboardTheme } from 'reducers/dashboardStylesReducer';
import { getEmbeddedDashboardWithDrilldowns } from 'reducers/selectors';
import * as RD from 'remotedata';
import { getOrDefault, hasNotReturned } from 'remotedata';
import { pageView } from 'telemetry/exploAnalytics';
import { useSetupAnalytics } from 'telemetry/telemetryUtils';
import { AdditionalTelemetryEmbedType } from 'telemetry/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { getCustomerVariables } from 'utils/customerUtils';
import { loadLocale } from 'utils/loadLocale';
import { getQueryVariables } from 'utils/variableUtils';

const errorMessageClass = sprinkles({
  margin: 'sp5',
  padding: 'sp5',
  borderRadius: 8,
  backgroundColor: 'red3',
  fontSize: 24,
});

type Props = {
  dashboardEmbedId: string; // Empty string if using embedJwt
  dataPanelId?: string; // Optional if encoded in embedJwt
  versionNumber?: number; // Defaults to latest
  customerToken?: string; // Either customerToken or embedJwt must be provided
  embedJwt?: string;
  isScreenshotDownload: boolean;
  variables?: DashboardVariableMap;
};

export const EmbeddedChart: FC<Props> = ({
  dashboardEmbedId,
  versionNumber,
  customerToken,
  dataPanelId: passedDataPanelId,
  embedJwt,
  isScreenshotDownload,
  variables: passedVariables,
}) => {
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables(true));
  const [userTransformedSchema] = useState<UserTransformedSchema>(
    getUserTransformedSchema(passedVariables),
  );
  const [adHocOps] = useState<AdHocOperationInstructions>(getAdHocOps(passedVariables));
  const [reportName] = useState<string>(getReportName(passedVariables));

  const dispatch = useDispatch();

  const { dashboard, dataPanelId, team, customer, globalStyleConfig, fontConfig } = useSelector(
    (state: EmbedReduxState) => ({
      dashboard: getEmbeddedDashboardWithDrilldowns(state),
      dataPanelId: state.embedDashboard.dataPanelId,
      team: state.embedDashboard.team,
      customer: state.embedDashboard.customer,
      fontConfig: state.dashboardStyles.fontConfig,
      globalStyleConfig: getCurrentTheme(state.dashboardStyles),
    }),
    shallowEqual,
  );

  const archetypePropertySet = useMemo(
    () => new Set(team?.archetype_properties?.map((prop) => prop.name)),
    [team?.archetype_properties],
  );

  const { environment, isProduction } = useMemo(() => {
    const queryVariables = getQueryVariables(true);
    const environment = queryVariables['environment'];
    const isProduction = queryVariables['is_production'];
    return { environment, isProduction };
  }, []);

  const onLoad = () => {
    pageView('Shared Chart');

    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          jwt: embedJwt,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
          },
        },
        (data) => {
          loadLocale({
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  };
  useEffect(onLoad, [
    dispatch,
    versionNumber,
    dashboardEmbedId,
    customerToken,
    environment,
    embedJwt,
  ]);

  const analyticsReady = useSetupAnalytics({
    pageViewEvent: ReportedAnalyticActionTypes.SHARED_CHART_VIEWS,
    environment: environment as string | undefined,
    isProduction,
    embedType: AdditionalTelemetryEmbedType.CHART,
  });

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [fontConfig, globalStyleConfig.text, team]);

  const variables = useMemo(
    () => ({
      ...passedVariables,
      ...urlVariables,
      ...(customer && getCustomerVariables(customer, archetypePropertySet)),
    }),
    [archetypePropertySet, customer, urlVariables, passedVariables],
  );

  useEffect(() => {
    const theme = variables['theme'];
    if (!theme || typeof theme !== 'string') return;
    dispatch(setDashboardTheme(theme));
  }, [variables, dispatch]);

  if (RD.isError(dashboard)) {
    return (
      <div
        className={cx(DASHBOARD_LOADED_CLASS_NAME, DATA_PANEL_ERROR_CLASS_NAME, errorMessageClass)}>
        {dashboard.error}
      </div>
    );
  } else if (RD.isLoading(dashboard, true) || !analyticsReady) {
    return <EmbedSpinner fillContainer size="xl" />;
  } else if (!RD.isSuccess(dashboard) || !customer) {
    return (
      <div
        className={cx(DASHBOARD_LOADED_CLASS_NAME, DATA_PANEL_ERROR_CLASS_NAME, errorMessageClass)}>
        There was an error loading the dashboard. Please contact your support team for help.
      </div>
    );
  }

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <div className={cx(DASHBOARD_CLASS_NAME, globalStylesClassName)}>
          <ChartLayout
            adHocOperationInstructions={adHocOps}
            customerToken={customerToken}
            dashboardEmbedId={dashboardEmbedId}
            dataPanelId={dataPanelId || passedDataPanelId || ''}
            embedJwt={embedJwt}
            isScreenshotDownload={isScreenshotDownload}
            reportName={reportName}
            userTransformedSchema={userTransformedSchema}
            variables={variables}
          />
        </div>
      )}
    </GlobalStylesProvider>
  );
};

// These three functions can read from either the passed variables map or the query vars, preferring the query vars.
// Query vars will have quotes wrapping them, so we need to substring out those quotes, while the passed vars do not
function getReportName(passedVariables: DashboardVariableMap | undefined) {
  const rawVars = parse(window.location.href, true).query;

  if (rawVars?.reportName) {
    return rawVars.reportName.substring(1, rawVars.reportName.length - 1);
  } else if (passedVariables?.['userTransformedSchema']) {
    return passedVariables['reportName'] as string;
  } else {
    return '';
  }
}

function getUserTransformedSchema(passedVariables: DashboardVariableMap | undefined) {
  const rawVars = parse(window.location.href, true).query;

  if (rawVars?.userTransformedSchema) {
    return JSURL.parse(
      rawVars.userTransformedSchema.substring(1, rawVars.userTransformedSchema.length),
    ) as UserTransformedSchema;
  } else if (passedVariables?.['userTransformedSchema']) {
    return JSURL.parse(passedVariables['userTransformedSchema'] as string) as UserTransformedSchema;
  } else {
    return [];
  }
}

function getAdHocOps(passedVariables: DashboardVariableMap | undefined) {
  const rawVars = parse(window.location.href, true).query;

  if (rawVars?.adHocOps) {
    return JSURL.parse(
      rawVars.adHocOps.substring(1, rawVars.adHocOps.length),
    ) as AdHocOperationInstructions;
  } else if (passedVariables?.['adHocOps']) {
    return JSURL.parse(passedVariables['adHocOps'] as string) as AdHocOperationInstructions;
  } else {
    return {};
  }
}
