import { DataSourceConfiguration, DataSource, BigQuery, Snowflake } from '@explo-tech/fido-api';

import {
  DataSource as EmbeddoDataSource,
  DataSourceConfiguration as EmbeddoDataSourceConfiguration,
} from 'actions/dataSourceActions';
import {
  FIDO_TYPES,
  DATABASES,
  TUNNEL,
  FIDO_TYPE_KEY,
  DATA_SOURCE_AUTH,
  SSH_AUTH_TYPE,
} from 'pages/ConnectDataSourceFlow/constants';
import {
  PartialAuthConfig,
  PartialDataSourceConfig,
  PartialSSHAuthConfig,
  PartialSSHConfig,
} from 'reducers/fidoDataSourceConfigurationReducer';

import { makeAbsentOrNotBlank } from '@explo/computation-generation';

import {
  BIGQUERY_DATA_SOURCE_CONFIG_KEYS,
  FidoDataSourceConfig,
  FidoSSHTunnelAuthentication,
  JDBC_DATABASE_TYPES,
  JDBC_DATA_SOURCE_CONFIG_KEYS,
  JdbcConfig,
  SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS,
  SNOWFLAKE_DATA_SOURCE_REQUIRED_CONFIG_KEYS,
  SSH_CONFIG_KEYS,
} from './types';
import { dataSourceSupportsSSH } from './utils';

export const FidoTypeToDatabaseMapping: Record<string, DATABASES> = {
  [FIDO_TYPES.POSTGRES]: DATABASES.POSTGRES,
  [FIDO_TYPES.REDSHIFT]: DATABASES.REDSHIFT,
  [FIDO_TYPES.SQLSERVER]: DATABASES.SQLSERVER,
  [FIDO_TYPES.MYSQL]: DATABASES.MYSQL,
  [FIDO_TYPES.BIGQUERY]: DATABASES.BIGQUERY,
  [FIDO_TYPES.SNOWFLAKE]: DATABASES.SNOWFLAKE,
  [FIDO_TYPES.CLICKHOUSE]: DATABASES.CLICKHOUSE,
};

export const parseDataSourceFromFido = (
  fidoDataSource: DataSource,
  embeddoDataSource: EmbeddoDataSource,
): EmbeddoDataSource => ({
  id: embeddoDataSource.id,
  name: fidoDataSource.name,
  parent_schema_id: embeddoDataSource.parent_schema_id,
  provided_id: fidoDataSource.externalId,
  access_groups: embeddoDataSource.access_groups,
  source_type:
    FidoTypeToDatabaseMapping[
      (fidoDataSource.configuration as FidoDataSourceConfig)[FIDO_TYPE_KEY]
    ],
  user_viewable_credentials: getUserViewableCredentialsFromFido(fidoDataSource.configuration),
  fido_id: fidoDataSource.id,
});

export const getUserViewableCredentialsFromFido = (
  configuration: DataSourceConfiguration,
): EmbeddoDataSourceConfiguration => {
  let config = configuration as FidoDataSourceConfig;

  if (JDBC_DATABASE_TYPES.has(config['@type'] as FIDO_TYPES)) {
    config = config as JdbcConfig;
    const port = config.port;

    if (port) {
      let sshConfig: Record<string, string | number | undefined> = {};
      if (config.tunnel[FIDO_TYPE_KEY] === TUNNEL.SSH) {
        sshConfig = {
          [SSH_CONFIG_KEYS.HOST]: config.tunnel.host,
          [SSH_CONFIG_KEYS.PORT]: config.tunnel.port,
          [SSH_CONFIG_KEYS.SSH_AUTH_TYPE]: (
            config.tunnel.authentication as FidoSSHTunnelAuthentication
          )[FIDO_TYPE_KEY],
          [SSH_CONFIG_KEYS.USERNAME]: config.tunnel.authentication.username,
        };
      }
      return {
        [JDBC_DATA_SOURCE_CONFIG_KEYS.DATABASE]: config.database,
        [JDBC_DATA_SOURCE_CONFIG_KEYS.HOST]: config.host,
        [JDBC_DATA_SOURCE_CONFIG_KEYS.PORT]: port,
        [JDBC_DATA_SOURCE_CONFIG_KEYS.USER]: config.authentication.username,
        [JDBC_DATA_SOURCE_CONFIG_KEYS.TUNNEL_TYPE]: config.tunnel[FIDO_TYPE_KEY],
        ...sshConfig,
      };
    } else return {};
  } else if (config['@type'] == 'bigquery') {
    config = config as BigQuery;
    return config.authentication.projectId &&
      makeAbsentOrNotBlank(config.authentication.projectId) != null
      ? { projectId: config.authentication.projectId }
      : {};
  } else if (config['@type'] == 'snowflake') {
    config = config as Snowflake;

    return {
      [SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.USER]: config.user,
      [SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.ACCOUNT]: config.account,
      ...(config.schema &&
        makeAbsentOrNotBlank(config.schema) != null && {
          [SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.SCHEMA]: config.schema,
        }),
      ...(config.database &&
        makeAbsentOrNotBlank(config.database) != null && {
          [SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.DATABASE]: config.database,
        }),
    };
  }

  return {};
};

export const getUpdatableDataSourceConfig = (
  payload: EmbeddoDataSourceConfiguration,
  type?: DATABASES,
): { dataSourceConfig: PartialDataSourceConfig; authConfig: PartialAuthConfig } | undefined => {
  switch (type) {
    case DATABASES.POSTGRES:
    case DATABASES.REDSHIFT:
    case DATABASES.MYSQL:
    case DATABASES.SQLSERVER:
    case DATABASES.AZURE:
    case DATABASES.CLICKHOUSE: {
      const isParseable = Object.values(JDBC_DATA_SOURCE_CONFIG_KEYS).every(
        (key) => key in payload,
      );
      if (!isParseable) return;

      return {
        dataSourceConfig: {
          database: payload[JDBC_DATA_SOURCE_CONFIG_KEYS.DATABASE] as string,
          port: payload[JDBC_DATA_SOURCE_CONFIG_KEYS.PORT] as number,
          host: payload[JDBC_DATA_SOURCE_CONFIG_KEYS.HOST] as string,
        },
        authConfig: {
          username: payload[JDBC_DATA_SOURCE_CONFIG_KEYS.USER] as string,
          '@type': DATA_SOURCE_AUTH.USERNAME_PASSWORD,
        },
      };
    }
    case DATABASES.BIGQUERY: {
      const isParseable = Object.values(BIGQUERY_DATA_SOURCE_CONFIG_KEYS).every(
        (key) => key in payload,
      );
      if (!isParseable) return;

      return {
        dataSourceConfig: {},
        authConfig: {
          projectId: payload[BIGQUERY_DATA_SOURCE_CONFIG_KEYS.PROJECT_ID] as string,
        },
      };
    }
    case DATABASES.SNOWFLAKE: {
      const isParseable = Object.values(SNOWFLAKE_DATA_SOURCE_REQUIRED_CONFIG_KEYS).every(
        (key) => key in payload,
      );
      if (!isParseable) return;

      return {
        dataSourceConfig: {
          user: payload[SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.USER] as string,
          account: payload[SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.ACCOUNT] as string,
          schema: payload[SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.SCHEMA] as string | undefined,
          database: payload[SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS.DATABASE] as string | undefined,
        },
        authConfig: {
          '@type': DATA_SOURCE_AUTH.SNOWFLAKE_PASSWORD,
        },
      };
    }
    default:
      return;
  }
};

export const getUpdatableSSHConfig = (
  payload: EmbeddoDataSourceConfiguration,
  type: DATABASES,
): { sshConfig: PartialSSHConfig; sshAuthConfig: PartialSSHAuthConfig } | undefined => {
  if (
    !dataSourceSupportsSSH(type) ||
    !Object.values(SSH_CONFIG_KEYS).every((key) => key in payload)
  )
    return;

  const authType = payload[SSH_CONFIG_KEYS.SSH_AUTH_TYPE];
  if (authType !== SSH_AUTH_TYPE.VENDOR && authType !== SSH_AUTH_TYPE.TENANT) return;
  return {
    sshConfig: {
      tunnel: { '@type': TUNNEL.PUBLIC_INTERNET },
      host: payload[SSH_CONFIG_KEYS.HOST] as string,
      port: payload[SSH_CONFIG_KEYS.PORT] as number,
    },
    sshAuthConfig: {
      '@type': authType,
      username: payload[SSH_CONFIG_KEYS.USERNAME] as string,
      ...(authType === SSH_AUTH_TYPE.TENANT
        ? { privateKey: payload['privateKey'] as string }
        : undefined),
    },
  };
};
