import { FC } from 'react';

import { AlertModal, APP_PORTAL_ID } from 'components/ds';
import { DELETE_MODAL_CONFIRMATION_TEXT } from 'pages/constants';

interface Props {
  resourceName: string;
  titleText?: string;
  confirmationButtonText?: string;

  onClose: () => void;
  onDelete: () => void;
}

export const DeleteResourceConfirmationModal: FC<Props> = ({
  resourceName,
  titleText,
  confirmationButtonText,
  onClose,
  onDelete,
}) => {
  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        text: confirmationButtonText || DEFAULT_CONFIRMATION_BUTTON_TEXT,
        onClick: onDelete,
      }}
      confirmationText={DELETE_MODAL_CONFIRMATION_TEXT}
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      title={titleText || getDefaultTitleText(resourceName)}
    />
  );
};

const getDefaultTitleText = (resourceName: string) =>
  `Are you sure you want to delete '${resourceName}'? Type ${DELETE_MODAL_CONFIRMATION_TEXT} to confirm.`;

const DEFAULT_CONFIRMATION_BUTTON_TEXT = 'Delete for all users';
