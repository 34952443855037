import { useCallback, useRef } from 'react';

export const useMenuScroll = () => {
  const scrollPositionRef = useRef<number>(0);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleMenuRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      menuRef.current = node;
      node.scrollTop = scrollPositionRef.current;

      const handleScroll = (event: Event) => {
        const target = event.target as HTMLElement;
        scrollPositionRef.current = target.scrollTop;
      };

      node.addEventListener('scroll', handleScroll);
      return () => node.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return { handleMenuRef, menuRef };
};
