export enum ChartAggregation {
  COUNT = 'COUNT',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  AVG = 'AVG',
  SUM = 'SUM',
  MIN = 'MIN',
  MAX = 'MAX',
  '25_PERCENTILE' = '25_PERCENTILE',
  MEDIAN = 'MEDIAN',
  '75_PERCENTILE' = '75_PERCENTILE',

  FORMULA = 'FORMULA',
  FIRST = 'FIRST',
}

export type AggregationType = {
  id: ChartAggregation;
  formula?: string;
};

export enum PivotAgg {
  DATE_HOUR = 'DATE_HOUR',
  DATE_DAY = 'DATE_DAY',
  DATE_WEEK = 'DATE_WEEK',
  DATE_MONTH = 'DATE_MONTH',
  DATE_QUARTER = 'DATE_QUARTER',
  DATE_YEAR = 'DATE_YEAR',
  DATE_SMART = 'DATE_AUTO',
  DATE_PART_WEEK_DAY = 'DATE_PART_WEEK_DAY',
  DATE_PART_MONTH_DAY = 'DATE_PART_MONTH_DAY',
  DATE_PART_MONTH = 'DATE_PART_MONTH',
  DATE_PART_HOUR = 'DATE_PART_HOUR',
}
