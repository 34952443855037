import * as QRCode from 'qrcode';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AuthMethods,
  changePrimaryMethod,
  getUserActiveMethods,
  mfaActivate,
  mfaConfirm,
  mfaDeactivate,
  requestCode,
} from 'actions/mfaActions';
import { Button, Input, Spinner, sprinkles } from 'components/ds';
import * as styles from 'pages/settingsPage/SecuritySection/index.css';
import { MfaMethodRow } from 'pages/settingsPage/SecuritySection/mfaMethodRow';
import { resetMethod } from 'reducers/mfaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getOrDefault, hasNotReturned, isError, isIdle, isLoading, isSuccess } from 'remotedata';
import { titleCase } from 'utils/graphUtils';

export const SettingsSecuritySection: FC = () => {
  const dispatch = useDispatch();

  const mfa = useSelector((state: ReduxState) => state.mfa);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasError, setCanvasError] = useState('');
  const [stateMachine, setStateMachine] = useState(StateMachine.IDLE);
  const [currentMethod, setCurrentMethod] = useState<AuthMethods | undefined>();
  const [code, setCode] = useState('');

  const numActiveMethods = useMemo(
    () => Object.values(mfa.methods).filter(({ isActive }) => isActive).length,
    [mfa.methods],
  );

  const trimmedCode = useMemo(() => code.trim(), [code]);

  const handleReset = useCallback(() => {
    dispatch(resetMethod());
    setStateMachine(StateMachine.IDLE);
    setCurrentMethod(undefined);
    setCode('');
  }, [dispatch]);

  useEffect(() => {
    if (isIdle(mfa.activeMethodResponse)) dispatch(getUserActiveMethods());
  }, [dispatch, mfa.activeMethodResponse]);

  useEffect(() => {
    if (stateMachine === StateMachine.DEACTIVATING && isSuccess(mfa.deactivateResponse)) {
      handleReset();
    }
  }, [handleReset, mfa.deactivateResponse, stateMachine]);

  useEffect(() => {
    if (stateMachine === StateMachine.CONFIRMING && isSuccess(mfa.backupCodes)) {
      setStateMachine(StateMachine.BACKUP_CODES);
    }
  }, [mfa.backupCodes, stateMachine]);

  useEffect(() => {
    if (
      stateMachine === StateMachine.CHANGE_PRIMARY_METHOD &&
      isSuccess(mfa.changePrimaryResponse)
    ) {
      handleReset();
    }
  }, [handleReset, mfa.backupCodes, mfa.changePrimaryResponse, stateMachine]);

  const toggleMethod = useCallback(
    (method: AuthMethods, active: boolean) => {
      setCurrentMethod(method);
      if (active) {
        setStateMachine(StateMachine.CONFIRMING);
        dispatch(mfaActivate({ id: method }));
      } else {
        setStateMachine(StateMachine.DEACTIVATING);
        dispatch(requestCode({ postData: { method } }));
      }
    },
    [dispatch],
  );

  const confirmMethod = useCallback(() => {
    if (!currentMethod || !trimmedCode) return;
    dispatch(mfaConfirm({ id: currentMethod, postData: { code: trimmedCode } }));
  }, [currentMethod, dispatch, trimmedCode]);

  const deactivateMethod = useCallback(() => {
    if (!currentMethod || !trimmedCode) return;
    dispatch(mfaDeactivate({ id: currentMethod, postData: { code: trimmedCode } }));
  }, [currentMethod, dispatch, trimmedCode]);

  const primaryMethod = useMemo(
    () => Object.entries(mfa.methods).find(([, { isPrimary }]) => isPrimary)?.[0],
    [mfa.methods],
  );

  const handleChangePrimaryMethod = useCallback(
    (method: AuthMethods) => {
      setStateMachine(StateMachine.CHANGE_PRIMARY_METHOD);
      setCurrentMethod(method);
      dispatch(requestCode({ postData: { method: primaryMethod || method } }));
    },
    [dispatch, primaryMethod],
  );

  const confirmPrimaryMethod = useCallback(() => {
    if (!currentMethod || !trimmedCode) return;
    dispatch(changePrimaryMethod({ postData: { method: currentMethod, code: trimmedCode } }));
  }, [currentMethod, dispatch, trimmedCode]);

  useEffect(() => {
    const response = mfa.activateResponse;
    if (!isSuccess(response) || !canvasRef.current) return;
    (async function () {
      try {
        await QRCode.toCanvas(canvasRef.current, response.data);
      } catch {
        setCanvasError('Failed to create QR code');
      }
    })();
  }, [mfa.activateResponse]);

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
      <div className={styles.section}>
        <div className={sprinkles({ heading: 'h2' })}>Security</div>
        <div className={styles.paragraph}>
          Settings and recommendations to help you keep your account secure
        </div>
      </div>
      <div className={styles.section}>
        <div className={sprinkles({ heading: 'h2' })}>2-Step Verification</div>
        <div className={styles.paragraph}>
          Prevent hackers from accessing your account with an additional layer of security. Upon
          sign in, you’ll be asked to complete the most secure second step available on your
          account. You can update your second steps and sign-in options any time in your settings.
        </div>
      </div>
      {stateMachine === StateMachine.IDLE ? (
        hasNotReturned(mfa.activeMethodResponse) ? (
          <Spinner size="sm" />
        ) : isError(mfa.activeMethodResponse) ? (
          <div className={styles.paragraph}>
            Unable to load 2-step methods. Please refresh and try again
          </div>
        ) : (
          <div className={styles.section}>
            {Object.entries(AuthMethods).map(([key, method]) => (
              <MfaMethodRow
                id={method}
                key={key}
                method={mfa.methods[method]}
                name={key}
                numActiveMethods={numActiveMethods}
                onChange={toggleMethod}
                onClick={handleChangePrimaryMethod}
              />
            ))}
          </div>
        )
      ) : stateMachine === StateMachine.CONFIRMING ? (
        <div className={styles.section}>
          <div className={sprinkles({ heading: 'h3' })}>
            Set Up {titleCase(currentMethod || '')}
          </div>

          {currentMethod === AuthMethods.APP ? (
            <>
              <div className={styles.paragraph}>
                Scan the QR code below with your authenticator app to enable 2-step verification.
              </div>
              <canvas ref={canvasRef} />
              {canvasError ? <div className={styles.paragraph}>{canvasError}</div> : null}
            </>
          ) : null}

          {hasNotReturned(mfa.activateResponse) || isLoading(mfa.backupCodes) ? (
            <Spinner size="sm" />
          ) : isSuccess(mfa.activateResponse) ? (
            <>
              <div className={styles.paragraph}>
                A token has been sent to you, please enter it below to activate.
              </div>
              <Input label="Token" maxLength={12} onChange={setCode} value={code} />
              <div className={styles.row}>
                <Button disabled={!trimmedCode} onClick={confirmMethod}>
                  Submit Token
                </Button>
                {isError(mfa.activateResponse) ? (
                  <div className={styles.paragraph}>Activation failed, please try again</div>
                ) : null}
                {isError(mfa.backupCodes) ? (
                  <div className={styles.paragraph}>Unable to load backup codes</div>
                ) : null}
                <Button onClick={handleReset} variant="secondary">
                  Cancel
                </Button>
              </div>
            </>
          ) : null}
        </div>
      ) : stateMachine === StateMachine.DEACTIVATING ? (
        <div className={styles.section}>
          <div className={sprinkles({ heading: 'h3' })}>
            Deactivate {titleCase(currentMethod || '')}
          </div>
          {hasNotReturned(mfa.codeResponse) || isLoading(mfa.deactivateResponse) ? (
            <Spinner size="sm" />
          ) : (
            <>
              <div className={styles.paragraph}>
                A token has been sent to you, please enter it below before deactivating.
              </div>
              <Input label="Token" maxLength={12} onChange={setCode} value={code} />
              <div className={styles.row}>
                <Button
                  disabled={!trimmedCode}
                  loading={isLoading(mfa.codeResponse) || isLoading(mfa.deactivateResponse)}
                  onClick={deactivateMethod}>
                  Submit Token
                </Button>
                {isError(mfa.codeResponse) || isError(mfa.deactivateResponse) ? (
                  <div className={styles.paragraph}>Deactivation failed, please try again</div>
                ) : null}
                <Button onClick={handleReset} variant="secondary">
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      ) : stateMachine === StateMachine.CHANGE_PRIMARY_METHOD ? (
        <div className={styles.section}>
          <div className={sprinkles({ heading: 'h3' })}>
            Make {titleCase(currentMethod || '')} Your Primary Two-Step Verification Method
          </div>
          {hasNotReturned(mfa.codeResponse) || isLoading(mfa.changePrimaryResponse) ? (
            <Spinner size="sm" />
          ) : (
            <>
              <div className={styles.paragraph}>
                A token has been sent to {titleCase(primaryMethod || currentMethod || '')}, please
                enter it below.
              </div>
              <Input label="Token" maxLength={12} onChange={setCode} value={code} />
              <div className={styles.row}>
                <Button
                  disabled={!trimmedCode}
                  loading={isLoading(mfa.codeResponse) || isLoading(mfa.changePrimaryResponse)}
                  onClick={confirmPrimaryMethod}>
                  Submit Token
                </Button>
                <Button onClick={handleReset} variant="secondary">
                  Cancel
                </Button>
              </div>
              {isError(mfa.codeResponse) || isError(mfa.changePrimaryResponse) ? (
                <div className={styles.section}>
                  <div className={styles.paragraph}>
                    Unable to change primary method, please try again{' '}
                  </div>
                  <Button onClick={() => currentMethod && handleChangePrimaryMethod(currentMethod)}>
                    Send New Code
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </div>
      ) : stateMachine === StateMachine.BACKUP_CODES ? (
        <div className={styles.section}>
          <div className={sprinkles({ heading: 'h3' })}>Backup Codes</div>
          <div className={styles.paragraph}>
            Please save your backup codes in a safe place - <b>they will not be shown again</b>. You
            can use these codes to access your account if you lose access to your second step.
            <pre>
              {getOrDefault(mfa.backupCodes, []).map((code) => (
                <div key={code}>{code}</div>
              ))}
            </pre>
          </div>
          <Button onClick={handleReset}>Done Saving Codes</Button>
        </div>
      ) : null}
    </div>
  );
};

enum StateMachine {
  IDLE = 'idle',
  CONFIRMING = 'confirming',
  BACKUP_CODES = 'backup_codes',
  DEACTIVATING = 'deactivating',
  CHANGE_PRIMARY_METHOD = 'change_primary_method',
}
