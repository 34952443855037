import { FC } from 'react';

import {
  CustomerReportFilter,
  ColumnConfigWithName,
  NUMBER_TYPES,
  STRING,
  BOOLEAN,
  DATE_TYPES,
} from '@explo/data';

import { FilterableColumn } from 'utils/customerReportUtils';

import { BooleanFilterPopover } from './PopoverTypes/BooleanFilterPopover';
import { DateFilterPopover } from './PopoverTypes/DateFilterPopover';
import { NumberAndStringFilterPopover } from './PopoverTypes/NumberAndStringFilterPopover';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  columnConfig: ColumnConfigWithName;
};

export const FilterTag: FC<Props> = ({ clause, column, columnConfig }) => {
  if (NUMBER_TYPES.has(column.type) || column.type === STRING) {
    return (
      <NumberAndStringFilterPopover clause={clause} column={column} columnConfig={columnConfig} />
    );
  }

  if (column.type === BOOLEAN) {
    return <BooleanFilterPopover clause={clause} column={column} />;
  }

  if (DATE_TYPES.has(column.type)) {
    return <DateFilterPopover clause={clause} column={column} />;
  }

  return null;
};
