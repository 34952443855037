import { AggColInfo, BaseCol, GroupByColOptional } from '../columnTypes';
import { OPERATION_TYPES } from '../constants';
import { ChartAggregation } from './aggregations';
import { FilterClause, FilterOperator } from './filters';
import { SortInfo } from './sorts';

// Matching shape of FilterClause for ease of use in backend but making
// some of these required instead
export type CustomerReportFilter = Pick<FilterClause, 'filterValue'> & {
  filterColumn: BaseCol;
  filterOperation: { id: FilterOperator };
  isPostFilter?: boolean;

  // Value for easy updating filters when they have same column
  id: number;
};

export type ReportBuilderComputationParams = {
  sort?: SortInfo[];
  filters?: CustomerReportFilter[];
  group_bys?: GroupByColOptional[];
  columns?: string[];
  hidden_columns?: string[];
  aggs?: AggColInfo[];
  visualization?: OPERATION_TYPES;
};

// For custom aggregations, use the latest formula in the dataset
// embeddo backend performs this same logic so this should only used by FIDO
export const getMappedAggs = (
  aggs: AggColInfo[],
  customAggs: AggColInfo[] | undefined,
): AggColInfo[] => {
  const mappedAggs: AggColInfo[] = [];
  aggs.forEach((agg) => {
    if (agg.agg.id === ChartAggregation.FORMULA) {
      const customAgg = customAggs?.find((customAgg) => customAgg.column.name === agg.column.name);
      if (customAgg) mappedAggs.push(customAgg);
    } else mappedAggs.push(agg);
  });
  return mappedAggs;
};
