import cx from 'classnames';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';

interface Props {
  dataPanelId: string;
}

export const DataPanelHighlightBorderOverlay: FC<Props> = ({ dataPanelId }) => {
  const dispatch = useDispatch();

  const highlightedDatasetIds = useSelector(
    (state: ReduxState) => state.dashboardInteractions.highlightedDataPanelIds,
  );

  const isLinkHighlighted = highlightedDatasetIds.has(dataPanelId);
  if (!isLinkHighlighted) {
    return null;
  }

  return (
    <div
      className={cx(
        sprinkles({
          positionAbsolute: 'fill',
          flexItems: 'center',
          padding: 'sp1',
          border: 1,
          borderColor: 'blue11',
        }),
        embedSprinkles({ borderRadius: 'container' }),
      )}
      onMouseEnter={() => dispatch(setHighlightedDataPanelIds(new Set([dataPanelId])))}
      onMouseLeave={() => dispatch(setHighlightedDataPanelIds(new Set()))}></div>
  );
};
