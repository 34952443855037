import * as RadixSwitch from '@radix-ui/react-switch';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { Label, sprinkles } from 'components/ds';
import { camelCase } from 'utils/standard';

import * as styles from './index.css';

type LabelProps = {
  text: string;
  infoText?: ReactNode;
  variableInput?: boolean;
};

type BaseProps = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * Flag to disable input from user input
   */
  disabled?: boolean;
  /**
   * optional label shown above the input in the top left
   */
  label?: string | LabelProps;
  /**
   * If true, label will be on right side of switch
   */
  labelOnRight?: boolean;
  /**
   * When true, indicates that the user must check the switch before the owning form can be submitted
   */
  required?: boolean;
};

type ExternalStateProps = {
  name?: never;
  value?: never;
  defaultOn?: never;
  /**
   * Whether the toggle is enabled or not
   */
  switchOn?: boolean;
  /**
   * Function that runs on change events
   */
  onChange: (newValue: boolean) => void;
};

type InternalStateProps = {
  switchOn?: never;
  onChange?: never;
  /**
   * The value given as data when submitted with a name in a form
   */
  value?: string;
  /**
   * The state of the switch when it is initially rendered. Use when you do not need to control its state.
   */
  defaultOn: boolean;
};

// External is when parent uses state to control toggle, Internal is when using a form wrapped around the toggle
type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps;

export const Switch: FC<Props> = ({
  switchOn,
  className,
  'data-testid': testId,
  defaultOn,
  disabled,
  label,
  labelOnRight,
  value = 'on',
  required = false,
  onChange,
}) => {
  const currLabel = typeof label === 'string' ? label : label?.text;
  const name = currLabel ? camelCase(currLabel) : '';

  const renderLabel = () => {
    if (currLabel === undefined || label === undefined) return null;

    if (typeof label === 'string')
      return (
        <Label htmlFor={name} variant="inline">
          {currLabel}
        </Label>
      );

    return (
      <Label
        forVariableInput={label.variableInput}
        htmlFor={name}
        infoText={label.infoText}
        variant="inline">
        {currLabel}
      </Label>
    );
  };

  const toggle = (
    <RadixSwitch.Root
      checked={switchOn}
      className={styles.switchRoot}
      data-testid={testId}
      defaultChecked={defaultOn}
      disabled={disabled}
      name={name}
      onCheckedChange={(newValue) => {
        if (defaultOn || !onChange) return;
        onChange(newValue);
      }}
      required={required}
      value={value}>
      <RadixSwitch.Thumb className={styles.switchThumb} />
    </RadixSwitch.Root>
  );

  return (
    <div
      className={cx(sprinkles({ flexItems: 'alignCenter', gap: 'sp1' }), className, {
        [sprinkles({ justifyContent: 'space-between' })]: !labelOnRight,
      })}
      style={{ minHeight: 18 }}>
      {labelOnRight ? (
        <>
          {toggle}
          {renderLabel()}
        </>
      ) : (
        <>
          {renderLabel()}
          {toggle}
        </>
      )}
    </div>
  );
};
