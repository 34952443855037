import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';
import * as styles from 'shared/ExploResource/styles.css';

import { Branch, ComputedView, Folder, Resource } from '@explo-tech/fido-api';
import { DataLibraryOverflowMenu } from './DataLibraryOverflowMenu';
import { PendingChangesTooltip } from './PendingChangesTooltip';
import { ResponseData } from 'remotedata';

interface Props {
  dataset: ComputedView;
  allItemNames: Set<string>;
  hasPendingChanges: boolean;
  currentBranch: ResponseData<Branch>;

  onClick: () => void;
  onItemDelete: (resource: Resource) => void;
  onItemRename: (resource: Resource, newName: string) => void;
  onItemDuplicate: () => void;
  onItemMove: (newParentFolder: Folder) => void;
}

export const DatasetListItem: FC<Props> = ({
  dataset,
  allItemNames,
  hasPendingChanges,
  currentBranch,
  onClick,
  onItemDelete,
  onItemRename,
  onItemDuplicate,
  onItemMove,
}) => {
  return (
    <div className={styles.resourceListItem} onClick={onClick}>
      <div className={sprinkles({ display: 'flex', flexItems: 'alignCenter' })}>
        <Icon
          className={sprinkles({ color: 'contentSecondary', marginX: 'sp2' })}
          name="table"
          size="lg"
        />
        <div className={styles.resourceCardName}>{dataset.name}</div>
      </div>
      <div
        className={sprinkles({
          display: 'flex',
          flexItems: 'alignCenter',
          height: 'fill',
        })}>
        {hasPendingChanges ? <PendingChangesTooltip /> : null}
        <DataLibraryOverflowMenu
          allItemNames={allItemNames}
          currentBranch={currentBranch}
          item={dataset}
          onItemDelete={() => {
            onItemDelete(dataset);
          }}
          onItemDuplicate={() => {
            onItemDuplicate();
          }}
          onItemMove={onItemMove}
          onItemRename={(newName: string) => {
            onItemRename(dataset, newName);
          }}
        />
      </div>
    </div>
  );
};
