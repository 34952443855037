import cx from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { sendPing } from 'actions/pingActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { Button, ErrorFallback, Icon, Spinner, Tag, sprinkles } from 'components/ds';
import {
  EmbedButton,
  EmbedDataGrid,
  EmbedModal,
  EmbedModalClose,
  EmbedModalFooter,
  EmbedModalHeader,
} from 'components/embed';
import { UseEmbedColumnsParams } from 'components/embed/EmbedDataGrid/useEmbedColumns';
import { PingTypes } from 'constants/pingTypes';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { AIChatErrors } from 'constants/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ModalViews/ReportAIModal.css';
import { ReportChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChart';
import { useReportColumns } from 'pages/ReportBuilder/ReportView/useReportColumns';
import {
  GRID_ROW_HEIGHT,
  OPERATION_ICON_MAP,
  OPERATION_NAME_MAP,
} from 'pages/ReportBuilder/constants';
import { ReduxState } from 'reducers/rootReducer';
import { isError, isIdle, isLoading, isSuccess } from 'remotedata';
import { AIMessage } from 'reportBuilderContent/apiTypes';
import { resetMessages } from 'reportBuilderContent/reducers/reportAiReducer';
import {
  closeReportModal,
  createView,
  getCurrentColorTracker,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { getAiDataId } from 'reportBuilderContent/reducers/reportEditingUtils';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { fetchAiData } from 'reportBuilderContent/thunks';
import {
  fetchChatAiViewJob,
  fetchGenerateAiViewJob,
  fetchGenerateAiViewSuggestionsJob,
} from 'reportBuilderContent/thunks/aiThunks';
import { aiOutputToView } from 'reportBuilderContent/thunks/aiUtils';
import { sendReportBuilderAnalyticsEvent } from 'reportBuilderContent/thunks/analyticsThunks';
import { filterViewParams, isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { getSchemaAndColConfigs } from 'utils/customerReportUtils';
import { getColumnDisplayName } from 'utils/reportBuilderConfigUtils';

import { AIChatMessage } from './AIChatMessage';

type Props = {
  dataInfo?: CustomerReportDataInfo;
  isIframe: boolean;
};

// This is the maximum allowed message count for the chat;
// Needs to be kept in sync with MAX_MESSAGES on the backend
// (backend/async_jobs/jobs/ChatReportBuilderAIJob.py)
const MAX_MESSAGES = 20;

export const ReportAIModal: FC<Props> = ({ dataInfo, isIframe }) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState('');
  const [view, setView] = useState<CustomerReportView | undefined>(undefined);
  const [error, setError] = useState('');
  const prevAIError = useRef<string | undefined | null>(null);
  const [viewName, setViewName] = useState('Untitled Report'); // Store separately from view, so it doesn't trigger data fetching

  const {
    currentView,
    currentConfig,
    selectedReport,
    conversation,
    generatedView,
    messages,
    versionConfig,
    aiData,
    colorCategoryTracker,
    loadedDatasetId,
    chartSuggestions,
    team,
    customerName,
  } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      aiData: state.reportEditing.reportData[getAiDataId()],
      colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
      currentView: state.reportEditing.currentView,
      currentConfig: state.reportEditing.currentConfig,
      selectedReport: state.reportEditing.selectedReport,
      conversation: state.reportAi.conversation,
      messages: state.reportAi.messages,
      generatedView: state.reportAi.generatedView,
      loadedDatasetId: state.reportAi.datasetId,
      chartSuggestions: state.reportAi.chartSuggestions[state.reportAi.datasetId],
      team: state.embeddedReportBuilder.team,
      customerName: state.embeddedReportBuilder.customerName,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (prevAIError.current === null) {
      prevAIError.current = aiData?.error;
    } else if (aiData?.error && aiData.error !== prevAIError.current) {
      dispatch(
        sendReportBuilderAnalyticsEvent(
          selectedReport,
          ReportedAnalyticActionTypes.CUSTOMER_REPORT_AI_DATA_QUERY_FAILED,
          { error: aiData.error },
        ),
      );
      prevAIError.current = aiData.error;
    }
  }, [dispatch, selectedReport, aiData?.error]);

  const teamData = useSelector((state: ReduxState) => state.teamData?.data);
  const isJapanese = teamData?.default_locale_code === 'ja';

  const handleSendMessage = () => {
    dispatch(fetchChatAiViewJob(query));
    setQuery('');
  };

  const handleClose = () => dispatch(closeReportModal());

  const handleCreateView = () => {
    dispatch(createView(view ? { ...view, name: viewName } : undefined));

    dispatch(
      sendReportBuilderAnalyticsEvent(
        selectedReport,
        ReportedAnalyticActionTypes.CUSTOMER_REPORT_AI_VIEW_CREATED,
        {
          dataset_id: currentConfig?.dataInfo?.datasetId,
        },
      ),
    );

    const message = `End User (${customerName ?? 'Unknown'}) from ${
      team?.name ?? 'Unknown team'
    } \`Created a View\` from an AI generated report.`;

    dispatch(sendPing({ postData: { message_type: PingTypes.PING_AI_USAGE, message: message } }));
    handleClose();
  };

  // Reset history when view changes
  const datasetId = dataInfo?.datasetId;
  useEffect(() => {
    dispatch(resetMessages());
  }, [currentView, dispatch]);

  // Load suggestions if not already loaded for this dataset
  const dataset = dataInfo && versionConfig?.datasets[dataInfo.datasetId];
  useEffect(() => {
    if (
      datasetId &&
      dataset?.schema &&
      (!chartSuggestions || isIdle(chartSuggestions)) &&
      datasetId !== loadedDatasetId
    )
      dispatch(fetchGenerateAiViewSuggestionsJob({ schema: dataset.schema, datasetId }));
  }, [datasetId, dataset?.schema, dispatch, chartSuggestions, loadedDatasetId]);

  useEffect(() => {
    if (!dataset || !isSuccess(generatedView)) return;
    try {
      const view = aiOutputToView(
        generatedView.data.chart_title,
        generatedView.data.chart_type,
        generatedView.data.chart_schema,
        dataset,
      );
      setViewName(view?.name || 'Untitled Report');
      setView(view);
      setError('');
    } catch {
      setError('Unable to generate chart');
    }
  }, [generatedView, dataset]);

  useEffect(() => {
    if (view) dispatch(fetchAiData(view));
  }, [dispatch, view]);

  // Scroll to latest message when new messages are sent or chart is generated
  const messagesEndRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, generatedView]);

  const { schema, columnConfigs } = useMemo(() => {
    const columnOrder = view?.columnOrder || [];
    const hiddenColumns = view?.hiddenColumns || [];
    const schemaInfo = view ? filterViewParams(view) : null;
    return getSchemaAndColConfigs(dataset, columnOrder, hiddenColumns, schemaInfo);
  }, [dataset, view]);

  const columnParams: UseEmbedColumnsParams = useMemo(
    () => ({
      rows: aiData?.rows ?? [],
      schema,
      shouldTruncateText: true,
      disableCustomStyles: true,
      colorTracker: colorCategoryTracker,
      containerRef: modalRef,
      columnConfigs,
    }),
    [aiData, schema, colorCategoryTracker, columnConfigs],
  );

  const gridColumns = useReportColumns(columnParams);

  const isQueryTooShort = query.length < MIN_QUERY_LENGTH;
  const isQueryTooLong = query.length > MAX_QUERY_LENGTH;
  const isQueryInvalid = isQueryTooShort || isQueryTooLong;
  const hasGeneratedView = isSuccess(generatedView) && view;

  return (
    <EmbedModal isOpen isIframe={isIframe} onClose={handleClose} size="xlarge">
      <EmbedModalHeader title="AI Visualization Creator" />
      <div className={styles.modalContainer} ref={modalRef}>
        <AIChatMessage message={getIntroMessage(dataset)}>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', flexWrap: 'wrap' })}>
            {isSuccess(chartSuggestions) ? (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {chartSuggestions.data.ideas.map((idea) => (
                  <Button
                    icon="wand"
                    key={idea}
                    onClick={() => {
                      dispatch(resetMessages());
                      dispatch(fetchChatAiViewJob(idea));
                    }}
                    variant="secondary">
                    {idea}
                  </Button>
                ))}
              </ErrorBoundary>
            ) : isLoading(chartSuggestions) ? (
              <Tag intent="active">
                <Icon
                  bounce
                  className={sprinkles({ color: 'active', marginRight: 'sp.5' })}
                  name="sparkles"
                  size="sm"
                />
                Loading suggestions
              </Tag>
            ) : isError(chartSuggestions) ? (
              <Tag intent="warning">Unable to load suggestions</Tag>
            ) : null}
          </div>
        </AIChatMessage>
        {messages.map((message, index) =>
          message.role === 'system' ? null : (
            <AIChatMessage key={index} message={message}>
              {message.metadata?.chartType && isIdle(generatedView) ? (
                <div>
                  <EmbedButton
                    onClick={() => {
                      if (message.metadata?.chartType) {
                        dispatch(
                          fetchGenerateAiViewJob(message.content, message.metadata.chartType),
                        );
                      }
                    }}
                    variant="primary">
                    Create chart
                  </EmbedButton>
                </div>
              ) : null}
            </AIChatMessage>
          ),
        )}
        {isLoading(conversation) ? (
          <Tag intent="active">
            <Icon
              bounce
              className={sprinkles({ color: 'active', marginRight: 'sp.5' })}
              name="sparkles"
              size="md"
            />
            Thinking...
          </Tag>
        ) : isError(conversation) ? (
          conversation.error === AIChatErrors.MAX_MESSAGES_ERROR ? (
            <AIChatMessage
              message={
                {
                  role: 'assistant',
                  content: `You've reached the maximum number of messages allowed in a conversation.  If you want to increase the message limit, please reach out to support.`,
                } as AIMessage
              }
            />
          ) : (
            <div className={styles.loadingCard}>
              <EmbedText body="b2">{conversation.error}</EmbedText>
            </div>
          )
        ) : null}
        {isLoading(generatedView) ? (
          <div className={styles.loadingCard}>
            <Icon bounce className={sprinkles({ color: 'active' })} name="sparkles" size="lg" />
            <EmbedText body="b2" className={styles.loadingText}>
              Generating your report with AI. This may take a minute...
            </EmbedText>
          </div>
        ) : null}
        {error ? (
          <EmbedText body="b2" color="warning">
            {error}
          </EmbedText>
        ) : null}

        {hasGeneratedView ? (
          <AIChatMessage message={{ role: 'assistant', content: '' }}>
            <div className={styles.iconHeading}>
              <Icon className={sprinkles({ color: 'active' })} name="sparkles" />
              <EmbedText heading="h3">Generated Visualization</EmbedText>
            </div>
            <div className={styles.section}>
              <EmbedText body="b3" color="contentSecondary">
                Visualization Name
              </EmbedText>
              <input
                className={styles.nameInput}
                onChange={(e) => setViewName((prev) => e.target.value || prev)}
                placeholder="Enter a name for your view"
                type="text"
                value={viewName}
              />
            </div>
            <div className={styles.section}>
              <EmbedText body="b3" color="contentSecondary">
                Pick a Visualization Type
              </EmbedText>
              <div className={styles.iconHeading}>
                {[
                  OPERATION_TYPES.VISUALIZE_TABLE,
                  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
                  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
                  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
                  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
                  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
                  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
                  OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2,
                ].map((op) => (
                  <div
                    className={cx(
                      styles.visualizationCard,
                      aiData?.isLoading || aiData?.error
                        ? styles.disabledCard
                        : op === view?.visualization
                          ? styles.activeCard
                          : styles.defaultCard,
                    )}
                    key={op}
                    onClick={() => {
                      if (aiData?.isLoading || aiData?.error) return;
                      setView((view) =>
                        view
                          ? {
                              ...view,
                              visualization: op as CustomerReportView['visualization'],
                            }
                          : undefined,
                      );
                    }}>
                    <Icon
                      className={sprinkles({ color: 'contentSecondary' })}
                      name={OPERATION_ICON_MAP[op] || 'table'}
                    />

                    <EmbedText heading="h4">{OPERATION_NAME_MAP[op]}</EmbedText>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.section}>
              <EmbedText body="b3" color="contentSecondary">
                Visualization Preview
              </EmbedText>
              <div className={styles.reportCard}>
                {aiData?.isLoading ? (
                  <>
                    <Spinner size="lg" />
                    <EmbedText body="b2">Loading visualization data...</EmbedText>
                  </>
                ) : aiData?.error ? (
                  <EmbedText body="b2">{aiData.error}</EmbedText>
                ) : aiData ? (
                  isTableVisualization(view?.visualization) ? (
                    <EmbedDataGrid
                      columnConfigs={columnConfigs}
                      columns={gridColumns}
                      rowHeight={GRID_ROW_HEIGHT}
                      rows={aiData.rows ?? []}
                      schema={schema}
                    />
                  ) : (
                    <ReportChart
                      containerRef={modalRef}
                      dataset={dataset}
                      reportData={aiData}
                      view={view}
                    />
                  )
                ) : null}
              </div>

              <EmbedModalClose
                disabled={!hasGeneratedView}
                onClick={handleCreateView}
                variant="primary">
                Create view
              </EmbedModalClose>
            </div>
          </AIChatMessage>
        ) : null}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.reportAiConversationInput}>
        {isError(conversation) && conversation.error === AIChatErrors.MAX_MESSAGES_ERROR ? (
          <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp1' })}>
            <EmbedText body="b1">
              {`You've reached the limit of messages for this conversation.`}
            </EmbedText>

            <div onClick={() => dispatch(resetMessages())}>
              <EmbedText className={sprinkles({ color: 'active', cursor: 'pointer' })} heading="h4">
                Start new conversation
              </EmbedText>
            </div>
          </div>
        ) : (
          <textarea
            className={styles.queryInput}
            disabled={
              isError(conversation) && conversation.error === AIChatErrors.MAX_MESSAGES_ERROR
            }
            draggable={false}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !isJapanese) {
                e.preventDefault();

                if (!isQueryInvalid && !isLoading(conversation)) {
                  handleSendMessage();
                }
              }
            }}
            placeholder="Describe what you want to visualize or ask a question you'd like to answer about your data"
            rows={3}
            value={query}
          />
        )}
      </div>
      <EmbedModalFooter>
        <div className={styles.reportAiModalFooter}>
          {messages.length > 0 ? (
            <EmbedButton
              icon="refresh"
              onClick={() => dispatch(resetMessages())}
              variant="secondary">
              Reset
            </EmbedButton>
          ) : (
            <span />
          )}
          <EmbedButton
            disabled={isQueryInvalid || isLoading(conversation)}
            icon="wand"
            onClick={handleSendMessage}
            tooltipProps={
              isQueryInvalid
                ? {
                    side: 'bottom',
                    align: 'end',
                    text: `Messages cannot ${
                      isQueryTooShort
                        ? `be shorter than ${MIN_QUERY_LENGTH}`
                        : `be longer than ${MAX_QUERY_LENGTH}`
                    } characters`,
                  }
                : undefined
            }
            variant="primary">
            Send message
          </EmbedButton>
        </div>
      </EmbedModalFooter>
    </EmbedModal>
  );
};

const MIN_QUERY_LENGTH = 2;
const MAX_QUERY_LENGTH = 500;

const getIntroMessage = (dataset?: ReportBuilderDataset): AIMessage => ({
  role: 'assistant',
  content:
    `Hello! I'm your data analysis assistant, ready to turn your data into insightful charts. Let me know what you'd like to visualize.` +
    `\n\n**Note: The maximum number of messages allowed in this conversation is ${MAX_MESSAGES}. If you want to increase the message limit, please reach out to support.**` +
    (dataset?.schema
      ? ` \n\nTo help you get started, here are the columns for your current dataset **${
          dataset?.name
        }**:\n\n${dataset?.schema
          .map((col) => `_${getColumnDisplayName(col, dataset)}_`)
          .join(', ')}`
      : ''),
});
