import cx from 'classnames';
import Highcharts, { SeriesLineOptions, TooltipFormatterContextObject } from 'highcharts';
import { DateTime } from 'luxon';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'd3-format';

import {
  DatasetSchema,
  getKpiDateRanges,
  getTimezoneAwareDate,
  OPERATION_TYPES,
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
  TrendGroupToggleOption,
} from '@explo/data';

import { NoDataPanel } from 'components/ChartLayout/NoDataPanel';
import { ChartMenu } from 'components/ChartMenu';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { sprinkles } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { V2KPITrendInstructions, VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { getCategoricalColors, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';

import NumberTrendTextPanel from '../../../shared/charts/numberTrendTextPanel';

import { keyBy } from 'utils/standard';
import { PanelName } from '../DashboardDatasetView/Header/PanelName';
import { HighCharts } from './highCharts';
import * as styles from './numberTrend.css';
import TrendPctChange from './shared/trendPctChange';
import { formatValue, getAxisNumericalValue } from './utils';
import { sharedTitleConfig } from './utils/sharedConfigs';
import {
  areRequiredVariablesSet,
  enumerateDatesByGroup,
  formatDateRange,
  getComparisonDates,
  getEntryStartDateFromGrouping,
  getPctChange,
  getPeriodDates,
  isKpiTrendReadyToDisplay,
  isNumberTrendTextPanelVisualizationType,
} from './utils/trendUtils';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type LineOptions = Omit<SeriesLineOptions, 'data'> & {
  data: [DateTime, number][];
};

type ChartData = LineOptions[];

const PERIOD_INDEX = 0;
const COMPARISON_INDEX = 1;
const DATE_INDEX = 0;

type TotalAggregatedValues = {
  periodRange: number;
  comparisonRange: number;
};

type Props = {
  loading?: boolean;
  newDataLoading?: boolean;
  previewData: Record<string, string | number>[];
  aggValuesLoading?: boolean;
  aggregatedValues?: TotalAggregatedValues;
  instructions?: V2KPITrendInstructions;
  dataPanelTemplateId: string;
  editableDashboard?: boolean;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  infoTooltipText?: string;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  hideIcons?: boolean;
  processString: (s: string, builtInVars?: DashboardVariableMap) => string;
  operationType: OPERATION_TYPES;
};

export const NumberTrend: FC<Props> = ({
  loading,
  newDataLoading,
  previewData,
  aggValuesLoading,
  aggregatedValues,
  instructions,
  dataPanelTemplateId,
  editableDashboard,
  variables,
  schema,
  infoTooltipText,
  globalStyleConfig,
  generalOptions,
  hideIcons,
  processString,
  operationType,
}) => {
  const shouldUseFido = useSelector(
    (state: ReduxState) => !!state.dashboardLayout.requestInfo.useFido,
  );
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>();

  const getAggColumnName = useCallback(() => schema[1].name, [schema]);

  const getComparisonRange = useCallback(
    () => instructions?.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD,
    [instructions?.periodComparisonRange],
  );

  const getPeriodColumnName = useCallback(() => schema[0].name, [schema]);

  const getPeriodRange = useCallback(
    () => instructions?.periodColumn?.periodRange || PeriodRangeTypes.LAST_4_WEEKS,
    [instructions?.periodColumn?.periodRange],
  );

  // At this point TrendGrouping cannot be a DateGroupToggleId
  const getTrendGrouping = useCallback(
    () =>
      (isNumberTrendTextPanelVisualizationType(operationType, instructions)
        ? TrendGroupingOptions.DAILY // Grouping doesn't affect the data when trend lines are hidden. We use DAILY so the subtitle dates are correct.
        : instructions?.trendGrouping || TrendGroupingOptions.WEEKLY) as TrendGroupingOptions,
    [instructions, operationType],
  );

  const getCustomEndDate = useCallback(() => {
    return instructions?.periodColumn?.customEndDate
      ? getTimezoneAwareDate(instructions.periodColumn.customEndDate)
      : DateTime.local();
  }, [instructions?.periodColumn?.customEndDate]);

  const getCustomStartDate = useCallback(() => {
    return instructions?.periodColumn?.customStartDate
      ? getTimezoneAwareDate(instructions.periodColumn.customStartDate)
      : DateTime.local();
  }, [instructions?.periodColumn?.customStartDate]);

  const getPeriodAndComparisonDates = useCallback(() => {
    if (!shouldUseFido) {
      const { periodStartDate, periodEndDate, periodDates } = getPeriodDates(
        getPeriodRange(),
        getTrendGrouping(),
        getCustomStartDate(),
        getCustomEndDate(),
        instructions?.periodColumn?.trendDateOffset ?? 0,
      );

      const comparisonDates = getComparisonDates(
        periodStartDate,
        periodEndDate,
        periodDates.length,
        getComparisonRange(),
        getPeriodRange(),
        getTrendGrouping(),
      );

      return { periodDates, comparisonDates };
    } else {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      const kpiInstructions = instructions!;
      const { currentPeriod, previousPeriod } = getKpiDateRanges(
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        kpiInstructions.periodColumn!,
        kpiInstructions.periodComparisonRange ?? PeriodComparisonRangeTypes.PREVIOUS_PERIOD,
      );

      const periodDates: DateTime[] = [];
      const comparisonDates: DateTime[] = [];
      enumerateDatesByGroup(
        getEntryStartDateFromGrouping(currentPeriod.startDate, getTrendGrouping()),
        getEntryStartDateFromGrouping(currentPeriod.endDate, getTrendGrouping()),
        periodDates,
        getTrendGrouping(),
      );

      if (previousPeriod) {
        enumerateDatesByGroup(
          getEntryStartDateFromGrouping(previousPeriod.startDate, getTrendGrouping()),
          getEntryStartDateFromGrouping(previousPeriod.endDate, getTrendGrouping()),
          comparisonDates,
          getTrendGrouping(),
        );
      }

      return { periodDates, comparisonDates };
    }
  }, [
    getComparisonRange,
    getCustomEndDate,
    getCustomStartDate,
    getPeriodRange,
    getTrendGrouping,
    instructions,
    shouldUseFido,
  ]);

  const processTrendData = useCallback(() => {
    if (!previewData) return undefined;

    const periodColumnName = getPeriodColumnName();
    const aggColumnName = getAggColumnName();

    const { periodDates, comparisonDates } = getPeriodAndComparisonDates();

    previewData.forEach((row) => {
      if (!instructions?.periodColumn?.column.type) return;

      row[periodColumnName] = getTimezoneAwareDate(row[periodColumnName].toString()).toLocaleString(
        DateTime.DATE_SHORT,
      );
    });

    const dataByDate = keyBy(previewData, periodColumnName);
    const series: Record<string, LineOptions> = {
      period: {
        type: 'line',
        name: 'Period',
        data: [],
        color:
          instructions?.displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0],
      },
      comparison: {
        type: 'line',
        name: 'Comparison',
        data: [],
        color: instructions?.displayFormat?.comparisonColor || '#757575',
        lineWidth: 1,
        opacity: 0.6,
        dashStyle: 'Dash',
      },
    };

    periodDates.forEach((date) => {
      let aggValue = 0;
      const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
      if (dataByDate[parsedDate]) {
        const asNumber =
          getAxisNumericalValue(dataByDate[parsedDate][aggColumnName]) *
          (instructions?.valueFormat?.multiplyFactor ?? 1);
        aggValue = !isNaN(asNumber) ? asNumber : 0;
      }
      const entry: [DateTime, number] = [date, aggValue];
      series.period.data.push(entry);
    });

    comparisonDates.forEach((date) => {
      let aggValue = 0;
      const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
      if (dataByDate[parsedDate]) {
        const asNumber =
          getAxisNumericalValue(dataByDate[parsedDate][aggColumnName]) *
          (instructions?.valueFormat?.multiplyFactor ?? 1);
        aggValue = !isNaN(asNumber) ? asNumber : 0;
      }
      const entry: [DateTime, number] = [date, aggValue];
      series.comparison.data.push(entry);
    });

    return [series.period, series.comparison];
  }, [
    getAggColumnName,
    getPeriodAndComparisonDates,
    getPeriodColumnName,
    globalStyleConfig,
    instructions?.displayFormat?.comparisonColor,
    instructions?.displayFormat?.periodColor,
    instructions?.periodColumn?.column.type,
    instructions?.valueFormat?.multiplyFactor,
    previewData,
  ]);

  const data = useMemo(() => {
    if (isKpiTrendReadyToDisplay(instructions) && schema?.length >= 2) {
      return processTrendData();
    }
  }, [processTrendData, instructions, schema?.length]);

  const getDateRange = useCallback(
    (compData: [DateTime, number][]) => {
      if (hoveredIndex !== undefined) {
        if (!compData[hoveredIndex]) return '-';
        return formatDateRange(getTimezoneAwareDate(compData[hoveredIndex][DATE_INDEX].toString()));
      }

      if (compData.length < 1) return '-';
      const startDate = formatDateRange(getTimezoneAwareDate(compData[0][DATE_INDEX].toString()));
      const endDate = formatDateRange(
        getTimezoneAwareDate(compData[compData.length - 1][DATE_INDEX].toString()),
        true,
      );
      return `${startDate} - ${endDate}`;
    },
    [hoveredIndex],
  );

  const getPeriodDateRange = useCallback(
    (data: ChartData) => {
      return getDateRange(data[PERIOD_INDEX].data);
    },
    [getDateRange],
  );

  const getComparisonDateRange = useCallback(
    (data: ChartData) => {
      return getDateRange(data[COMPARISON_INDEX].data);
    },
    [getDateRange],
  );

  const getBuiltInVarsMap = useCallback(() => {
    if (!data) return {};

    const map: Record<string, string | undefined> = {
      current_period: getPeriodDateRange(data),
      comparison_period:
        getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON
          ? getComparisonDateRange(data)
          : undefined,
    };

    return map;
  }, [data, getComparisonDateRange, getComparisonRange, getPeriodDateRange]);

  // if the title is undefined, we then use the aggregation name as the title for the KPI
  const getTitle = useCallback(() => {
    if (generalOptions?.headerConfig?.title !== undefined) return generalOptions.headerConfig.title;

    const aggColumn = instructions?.aggColumn?.column;

    return aggColumn?.friendly_name || aggColumn?.name || '';
  }, [generalOptions?.headerConfig?.title, instructions?.aggColumn?.column]);

  const renderLoadingState = useCallback(
    (requiredVarNotsSet: boolean, instructionsReady: boolean, loading?: boolean) => {
      const showHeader = !generalOptions?.headerConfig?.isHeaderHidden;
      const title = getTitle();
      const configPanel = (className: string) => (
        <NeedsConfigurationPanel
          className={className}
          instructionsNeedConfiguration={!instructionsReady}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
      if (isNumberTrendTextPanelVisualizationType(operationType, instructions)) {
        return (
          <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
            {showHeader && (
              <div
                className={cx(
                  styles.noTrendLineLoadingTitle,
                  embedSprinkles({ otherText: 'kpiTitle' }),
                )}
                style={{
                  fontSize: instructions?.titleFormat?.fontSize,
                }}>
                {processString(title || '')}
              </div>
            )}
            {configPanel(styles.noTrendLineLoadingState)}
          </div>
        );
      }
      return (
        <div className={sprinkles({ parentContainer: 'fill' })}>
          {showHeader && (
            <div className={styles.titleContainer}>
              <div className={cx(styles.chartTitle, embedSprinkles({ otherText: 'kpiTitle' }))}>
                {processString(title || '')}
              </div>
            </div>
          )}
          {configPanel(styles.trendLineLoadingState)}
        </div>
      );
    },
    [
      generalOptions?.headerConfig?.isHeaderHidden,
      getTitle,
      operationType,
      instructions,
      processString,
    ],
  );

  const renderHeaderActions = useCallback(() => {
    const linkFormat = generalOptions?.linkFormat;

    if (hideIcons) return null;

    return (
      <>
        <UrlClickThroughButton linkFormat={linkFormat} />
        <ChartMenu
          dataPanelId={dataPanelTemplateId}
          enableDrilldownModal={generalOptions?.enableRawDataDrilldown}
        />
      </>
    );
  }, [
    dataPanelTemplateId,
    generalOptions?.enableRawDataDrilldown,
    generalOptions?.linkFormat,
    hideIcons,
  ]);

  const renderHeader = useCallback(() => {
    const isHeaderHidden = generalOptions?.headerConfig?.isHeaderHidden;
    const linkFormat = generalOptions?.linkFormat;

    const shouldRenderClickThrough = linkFormat?.link && linkFormat?.url && !hideIcons;

    if (isHeaderHidden && !shouldRenderClickThrough && !generalOptions?.enableRawDataDrilldown)
      return;

    const title = getTitle();

    return (
      <div
        className={cx(
          sprinkles({
            display: 'flex',
            alignItems: 'center',
            justifyContent: !isHeaderHidden ? 'space-between' : 'flex-end',
          }),
        )}
        style={{ height: 32 }}>
        {isHeaderHidden ? null : (
          <div className={styles.titleContainer}>
            <PanelName
              infoTooltipText={infoTooltipText}
              loading={newDataLoading}
              panelName={processString(title)}
            />
          </div>
        )}
        {renderHeaderActions()}
      </div>
    );
  }, [
    generalOptions?.enableRawDataDrilldown,
    generalOptions?.headerConfig?.isHeaderHidden,
    generalOptions?.linkFormat,
    getTitle,
    hideIcons,
    infoTooltipText,
    newDataLoading,
    processString,
    renderHeaderActions,
  ]);

  const renderNoDataBody = useCallback(() => {
    return (
      <div
        className={cx(
          sprinkles({ height: 'fill' }),
          embedSprinkles({ body: 'primaryWithoutColor' }),
        )}>
        {renderHeader()}
        <NoDataPanel noDataState={generalOptions?.noDataState} processString={processString} />
      </div>
    );
  }, [generalOptions?.noDataState, renderHeader, processString]);

  const getNumericChange = useCallback((base: number, comparison: number) => base - comparison, []);

  const showChange = getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;

  const renderNumberTrendTextPanel = useCallback(() => {
    const periodAggregatedValue = aggregatedValues?.periodRange || 0;
    const comparisonAggregatedValue =
      (showChange ? aggregatedValues?.comparisonRange : undefined) || 0;
    const trendChangeVal = instructions?.displayFormat?.showAbsoluteChange
      ? getNumericChange(periodAggregatedValue, comparisonAggregatedValue)
      : getPctChange(periodAggregatedValue, comparisonAggregatedValue);
    const trendChangeValFormat = instructions?.displayFormat?.showAbsoluteChange
      ? (instructions?.valueFormat?.numberFormat ?? V2_NUMBER_FORMATS.NUMBER)
      : V2_NUMBER_FORMATS.PERCENT;

    const subtitle = processString(
      instructions?.textOnlyFormat?.subtitle ?? '',
      getBuiltInVarsMap(),
    );

    const isZeroNoData = generalOptions?.noDataState?.isZeroNoData;

    const noDataCurrentPeriod =
      aggregatedValues === undefined ||
      isNaN(periodAggregatedValue) ||
      (isZeroNoData && periodAggregatedValue === 0);

    const noDataPreviousPeriod =
      aggregatedValues === undefined ||
      isNaN(comparisonAggregatedValue) ||
      (isZeroNoData && comparisonAggregatedValue === 0);

    return (
      <NumberTrendTextPanel
        aggValuesLoading={aggValuesLoading}
        comparisonValue={comparisonAggregatedValue}
        currentPeriodValue={periodAggregatedValue}
        displayFormat={instructions?.displayFormat}
        generalOptions={generalOptions}
        globalStyleConfig={globalStyleConfig}
        headerActions={renderHeaderActions()}
        infoTooltipText={infoTooltipText}
        noData={!!noDataCurrentPeriod}
        noDataPrevPeriod={!!noDataPreviousPeriod}
        processString={processString}
        subtitle={subtitle}
        titleFormat={instructions?.titleFormat}
        trendChangeVal={showChange ? trendChangeVal : undefined}
        trendChangeValFormatId={trendChangeValFormat.id}
        trendChangeValLabel={getComparisonRange()}
        valueFormat={instructions?.valueFormat}
        verticalContentAlignment={instructions?.textOnlyFormat?.verticalContentAlignment}
      />
    );
  }, [
    getComparisonRange,
    aggregatedValues,
    instructions,
    getNumericChange,
    globalStyleConfig,
    infoTooltipText,
    processString,
    getBuiltInVarsMap,
    generalOptions,
    aggValuesLoading,
    renderHeaderActions,
    showChange,
  ]);

  const getFormattedAggValue = useCallback(
    (value?: number) => {
      if (!value || isNaN(value)) return '-';

      const decimalPlaces = instructions?.valueFormat?.decimalPlaces ?? 2;
      const significantDigits = instructions?.valueFormat?.significantDigits ?? 3;
      const formatId = instructions?.valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id;

      return formatValue({
        value: value,
        decimalPlaces,
        significantDigits,
        formatId,
        hasCommas: true,
        timeFormatId: instructions?.valueFormat?.timeFormat?.id,
        customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
        customDurationFormat: instructions?.valueFormat?.customDurationFormat,
      });
    },
    [
      instructions?.valueFormat?.decimalPlaces,
      instructions?.valueFormat?.numberFormat?.id,
      instructions?.valueFormat?.significantDigits,
      instructions?.valueFormat?.timeCustomerFormat,
      instructions?.valueFormat?.timeFormat?.id,
      instructions?.valueFormat?.customDurationFormat,
    ],
  );

  const _spec = useCallback(
    (data: ChartData): Highcharts.Options | undefined => {
      if (schema?.length === 0 || !previewData) return;

      return {
        chart: {
          type: 'line',
          backgroundColor: globalStyleConfig.container.fill,
        },
        series: data,
        title: sharedTitleConfig,
        legend: {
          enabled: false,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
          series: {
            animation: false,
            point: {
              events: {
                mouseOver: function () {
                  setHoveredIndex(this.index);
                },
                mouseOut: function () {
                  setHoveredIndex(undefined);
                },
              },
            },
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        yAxis: {
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: { text: undefined },
        },
        xAxis: {
          crosshair: true,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          lineWidth: 1,
          lineColor: '#EEEEEE',
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            // We can't use an arrow function because "this" references the formatter's context
            return renderTooltip(getFormattedAggValue, data, showChange, instructions, this.points);
          },
          backgroundColor: globalStyleConfig.container.fill,
          borderWidth: 0,
          borderRadius: 4,
        },
      };
    },
    [
      getFormattedAggValue,
      globalStyleConfig.container.fill,
      instructions,
      previewData,
      schema?.length,
      showChange,
    ],
  );

  const getTimeRangeAggregatedValue = useCallback(
    (_data: ChartData, timeRangeIndex: number): number => {
      return timeRangeIndex === PERIOD_INDEX
        ? getAxisNumericalValue(aggregatedValues?.periodRange ?? '') *
            (instructions?.valueFormat?.multiplyFactor ?? 1)
        : getAxisNumericalValue(aggregatedValues?.comparisonRange ?? '') *
            (instructions?.valueFormat?.multiplyFactor ?? 1);
    },
    [
      aggregatedValues?.comparisonRange,
      aggregatedValues?.periodRange,
      instructions?.valueFormat?.multiplyFactor,
    ],
  );

  const renderChartAggregatedValues = useCallback(
    (data: ChartData) => {
      const useComparison = getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
      const periodAggregatedValue = getTimeRangeAggregatedValue(data, PERIOD_INDEX);
      const comparisonAggregatedValue = getTimeRangeAggregatedValue(data, COMPARISON_INDEX);
      const pctChange = useComparison
        ? getPctChange(periodAggregatedValue, comparisonAggregatedValue)
        : undefined;

      return (
        <div className={embedSprinkles({ body: 'primaryWithoutColor' })}>
          {renderHeader()}
          <div
            className={sprinkles({
              flexItems: 'alignCenterBetween',
              gap: 'sp1',
            })}>
            <div
              className={sprinkles({
                display: 'flex',
                alignItems: 'flex-end',
                gap: 'sp.5',
              })}>
              <div
                className={styles.periodAggValue}
                style={{
                  color:
                    instructions?.displayFormat?.periodColor ||
                    globalStyleConfig.visualizations.categoricalPalette.hue1,
                }}>
                <div className={styles.aggValue}>
                  {getFormattedAggValue(periodAggregatedValue)}
                  <span
                    className={sprinkles({
                      marginLeft: instructions?.valueFormat?.unitPadding ? 'sp.5' : 'sp0',
                    })}>
                    {instructions?.valueFormat?.units}
                  </span>
                </div>
              </div>
              {useComparison && (
                <div
                  className={cx(styles.comparisonAggValue, embedSprinkles({ body: 'secondary' }))}
                  style={{ color: instructions?.displayFormat?.comparisonColor }}>
                  from {getFormattedAggValue(comparisonAggregatedValue)}
                  <span
                    className={sprinkles({
                      marginLeft: instructions?.valueFormat?.unitPadding ? 'sp.25' : 'sp0',
                    })}>
                    {instructions?.valueFormat?.units}
                  </span>
                </div>
              )}
            </div>
            {pctChange !== undefined && (
              <TrendPctChange instructions={instructions} pctChange={pctChange} />
            )}
          </div>
        </div>
      );
    },
    [
      getComparisonRange,
      getFormattedAggValue,
      getTimeRangeAggregatedValue,
      globalStyleConfig.visualizations.categoricalPalette.hue1,
      instructions,
      renderHeader,
    ],
  );

  const renderComparisonDateRange = useCallback(
    (data: ChartData) => {
      // If set to "no comparison", compData will be an empty array
      const compData = data[data.length - 1].data;
      if (compData?.length < 2) return null;

      const trendGrouping = getTrendGrouping();
      const dateFormat = getDateFormat(trendGrouping);

      const compStart = compData[0][DATE_INDEX];
      const compStartDate = getTimezoneAwareDate(compStart.toISO()).toLocaleString(dateFormat);
      const compEnd = compData[compData.length - 1][DATE_INDEX];
      const compEndDate = getTimezoneAwareDate(compEnd.toISO()).toLocaleString(dateFormat);

      const comparisonColor = instructions?.displayFormat?.comparisonColor || '#757575';
      return (
        <div className={styles.chartDateRange} style={{ color: comparisonColor }}>
          <span>{compStartDate}</span>
          <span>{compEndDate}</span>
        </div>
      );
    },
    [getTrendGrouping, instructions?.displayFormat?.comparisonColor],
  );

  const renderChartDateRange = useCallback(
    (data: ChartData) => {
      const periodData = data[PERIOD_INDEX].data;
      const trendGrouping = getTrendGrouping();
      const dateFormat = getDateFormat(trendGrouping);
      const start = periodData[0][DATE_INDEX];
      const startDate = start.toLocaleString(dateFormat);
      const end = periodData[periodData.length - 1][DATE_INDEX];
      const endDate = end.toLocaleString(dateFormat);

      const periodColor =
        instructions?.displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0];
      return (
        <div className={cx(styles.chartAxis, embedSprinkles({ body: 'primaryWithoutColor' }))}>
          <div className={styles.chartDateRange} style={{ color: periodColor }}>
            <span>{startDate}</span>
            <span>{endDate}</span>
          </div>
          {renderComparisonDateRange(data)}
        </div>
      );
    },
    [
      getTrendGrouping,
      globalStyleConfig,
      instructions?.displayFormat?.periodColor,
      renderComparisonDateRange,
    ],
  );

  const renderNumberTrend = useCallback(() => {
    if (!data) return <div />;

    return (
      <>
        {renderChartAggregatedValues(data)}
        <HighCharts chartOptions={_spec(data)} className={sprinkles({ display: 'flex' })} />
        {renderChartDateRange(data)}
      </>
    );
  }, [_spec, data, renderChartAggregatedValues, renderChartDateRange]);

  const requiredVarNotsSet = !areRequiredVariablesSet(variables, instructions);

  // If the dashboard is editable, then we want to reload the entire chart when changes
  // happen. Either way, if there are no agg values to show, we should be loading the
  // entire chart since renderNumberTrendTextPanel() will crash if no values are present
  const isDataLoading = isNumberTrendTextPanelVisualizationType(operationType, instructions)
    ? aggValuesLoading && (editableDashboard || !aggregatedValues)
    : loading;
  const instructionsReady = isKpiTrendReadyToDisplay(instructions);

  if (isDataLoading || !instructionsReady || requiredVarNotsSet) {
    return renderLoadingState(requiredVarNotsSet, instructionsReady, isDataLoading);
  }

  if (isNumberTrendTextPanelVisualizationType(operationType, instructions)) {
    return renderNumberTrendTextPanel();
  }

  if (!previewData || previewData.length === 0) {
    return renderNoDataBody();
  }

  return renderNumberTrend();
};

// Highcharts Tooltips do not render JSX so we need to pass it raw HTML
function renderTooltip(
  getFormattedAggValue: (value: number) => string,
  data: LineOptions[],
  showChange: boolean,
  instructions?: V2KPITrendInstructions,
  points?: TooltipFormatterContextObject[],
) {
  const periodPoint = points?.[PERIOD_INDEX];
  if (!periodPoint) return null;

  const { tooltipCell, tooltipLine, periodRow, tooltipTable, periodLine } = styles;

  const comparisonPoint = points?.[COMPARISON_INDEX];
  const pctChange = getPctChange(periodPoint.y, comparisonPoint?.y || 0);
  const isPositive = pctChange > 0;
  const isNoChange = pctChange === 0;
  const changeText = format('.1%')(Math.abs(pctChange));
  const changeSign = isPositive ? '+' : isNoChange ? '' : '-';

  const cellClass = cx(tooltipCell, GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor);
  const dateFormat = getDateFormat(instructions?.trendGrouping);
  const changeClass = getTooltipStyle(isNoChange, isPositive, instructions);
  const periodIndex = periodPoint.x;
  const periodDate = data[0].data[periodIndex][0];
  const periodDateText = periodDate.toLocaleString(dateFormat);
  const periodLineStyle = `border-right: 2px solid ${periodPoint.color}`;
  const periodText = `<tr class="${periodRow}"><td class="${periodLine}"><div class="${tooltipLine}" style="${periodLineStyle}"/></td><td class="${cellClass}">${periodDateText}</td><td class="${cellClass}">${getFormattedAggValue(
    periodPoint.y,
  )}</td>${showChange ? `<td class="${changeClass}">${changeSign}${changeText}</td>` : ''}</tr>`;

  const comparisonText = renderComparisonTooltip(getFormattedAggValue, data, instructions, points);

  const tableClass = cx(tooltipTable, embedSprinkles({ color: 'primaryFont' }));
  return `<table class="${tableClass}">${periodText}${comparisonText}</table>`;
}

function renderComparisonTooltip(
  getFormattedAggValue: (value: number) => string,
  data: LineOptions[],
  instructions?: V2KPITrendInstructions,
  points?: TooltipFormatterContextObject[],
) {
  const comparisonPoint = points?.[COMPARISON_INDEX];
  if (!comparisonPoint) return '';

  const { comparisonRow, tooltipLine, tooltipCell, comparisonLine } = styles;

  const dateFormat = getDateFormat(instructions?.trendGrouping);
  const comparisonIndex = comparisonPoint.x;
  const comparisonDate = data[1].data[comparisonIndex][0];
  const comparisonDateText = comparisonDate.toLocaleString(dateFormat);
  const comparisonLineStyle = `border-right: 2px dashed ${comparisonPoint.color}`;

  const rowClass = cx(comparisonRow, embedSprinkles({ color: 'secondaryFont' }));
  const cellClass = cx(tooltipCell, GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor);
  return `<tr class="${rowClass}"><td class="${comparisonLine}"><div class="${tooltipLine}" style="${comparisonLineStyle}"/></td><td class="${cellClass}">${comparisonDateText}</td><td class="${cellClass}">${getFormattedAggValue(
    comparisonPoint.y,
  )}</td><td class="${tooltipCell}">-</td></tr>`;
}

function getTooltipStyle(
  isNoChange: boolean,
  isPositive: boolean,
  instructions?: V2KPITrendInstructions,
) {
  const changeStyle = isNoChange
    ? cx(styles.tooltipNoChange, embedSprinkles({ color: 'primaryFont' }))
    : instructions?.displayFormat?.trendColorsReversed
      ? isPositive
        ? styles.tooltipNegativeChange
        : styles.tooltipPositiveChange
      : isPositive
        ? styles.tooltipPositiveChange
        : styles.tooltipNegativeChange;

  return `${styles.tooltipChange} ${changeStyle}`;
}

function getDateFormat(trendGrouping?: TrendGroupingOptions | TrendGroupToggleOption) {
  return trendGrouping === TrendGroupingOptions.HOURLY
    ? DateTime.DATETIME_SHORT
    : DateTime.DATE_SHORT;
}
