import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';
import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { CustomerReportGroupBy, OPERATION_TYPES, STRING } from '@explo/data';

import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { AddColumnMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddColumnMenu';
import {
  NoDataAlert,
  RemovedDataAlert,
} from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { GroupByListItem } from 'pages/ReportBuilder/ReportView/DataPanel/GroupByListItem';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { getVisualizationName } from 'pages/ReportBuilder/utils/visualizationUtils';
import {
  addScatterPlotGrouping,
  clearPrevScatterPlotGroupings,
  getCurrentViewRemovedScatterPlotGroupings,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { BucketsByCol, DraggableColumnInfo, getGroupByUniqueId } from 'utils/customerReportUtils';

import { DataPanelList } from './DataPanelList';
import { DataPanelData, SCATTER_PLOT_GROUPING_SECTION_ID } from './constants';

type Props = {
  bucketsByCol: BucketsByCol;
  columnConfigs?: ReportBuilderColConfigs;
  disabled?: boolean;
  groupBys: CustomerReportGroupBy[];
  scatterPlotGrouping?: CustomerReportGroupBy;
  columns: DraggableColumnInfo[];
};

export const ScatterPlotGroupingSection: FC<Props> = ({
  bucketsByCol,
  columnConfigs,
  disabled,
  groupBys,
  scatterPlotGrouping,
  columns,
}) => {
  const dispatch = useDispatch();
  const { active, over, isOver, setNodeRef } = useDroppable(DROPPABLE_ARGS);
  const { removedScatterPlotGrouping, sourceType } = useSelector(
    (state: ReportBuilderReduxState) => ({
      removedScatterPlotGrouping: getCurrentViewRemovedScatterPlotGroupings(state.reportEditing),
      sourceType: state.reportEditing.reportSourceType,
    }),
    shallowEqual,
  );

  const sortableIds = useMemo(
    () =>
      scatterPlotGrouping
        ? [`${SCATTER_PLOT_GROUPING_SECTION_ID}-${getGroupByUniqueId(scatterPlotGrouping)}`]
        : [],
    [scatterPlotGrouping],
  );

  const isOverContainer = isOverSection(
    SCATTER_PLOT_GROUPING_SECTION_ID,
    isOver,
    over,
    sortableIds,
  );
  const isOverSelf = active?.data.current?.section === SCATTER_PLOT_GROUPING_SECTION_ID;

  const visualizationName = getVisualizationName(OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2);

  const xAxisGroupByIsString = groupBys?.[0]?.column.type === STRING;

  const isPopoverOpen = isOverContainer && disabled && !isOverSelf;
  const popoverText = useMemo(() => {
    if (scatterPlotGrouping) return `${visualizationName} can have up to 1 grouping`;
    if (xAxisGroupByIsString) return `Grouping implied by X-Axis Grouping`;
  }, [scatterPlotGrouping, visualizationName, xAxisGroupByIsString]);

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={SCATTER_PLOT_GROUPING_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon="report-scatter-points"
        popoverChildren={isPopoverOpen ? popoverText : undefined}
        title="Grouping">
        <AddColumnMenu
          columnConfigs={columnConfigs}
          columns={columns}
          disabled={disabled}
          onAddColumn={(c) => {
            !disabled && dispatch(addScatterPlotGrouping({ scatterPlotGrouping: { column: c } }));
          }}
          selectedColumns={scatterPlotGrouping ? [scatterPlotGrouping.column] : []}
          tooltipText={popoverText}
        />
      </DataPanelSubHeader>

      {removedScatterPlotGrouping !== undefined ? (
        <RemovedDataAlert
          name="grouping"
          numRemoved={1}
          onDismiss={() => dispatch(clearPrevScatterPlotGroupings())}
          visualizationName={visualizationName}
        />
      ) : null}

      {!scatterPlotGrouping ? <NoDataAlert name="grouping" /> : null}

      {scatterPlotGrouping ? (
        <GroupByListItem
          isScatterPlotGrouping
          bucketsByCol={bucketsByCol}
          columnConfigs={columnConfigs}
          groupBy={scatterPlotGrouping}
          key={getGroupByUniqueId(scatterPlotGrouping)}
          section={SCATTER_PLOT_GROUPING_SECTION_ID}
          sourceType={sourceType}
        />
      ) : null}
    </DataPanelList>
  );
};

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: SCATTER_PLOT_GROUPING_SECTION_ID,
  data: {
    id: SCATTER_PLOT_GROUPING_SECTION_ID,
    section: SCATTER_PLOT_GROUPING_SECTION_ID,
  } as DataPanelData,
};
