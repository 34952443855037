import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dashboard } from 'actions/dashboardActions';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { updateLastSeenAppUpdateId } from 'actions/userActions';
import { ViewType } from 'components/PageHeader';
import { IconButton, Modal, Spinner, sprinkles } from 'components/ds';
import { ROUTES } from 'constants/routes';
import { DisabledDashboardMenu } from 'pages/homeAppPage/DisabledDashboardMenu';
import { createLoadingSelector } from 'reducers/api/selectors';
import { clearDashboardVersions } from 'reducers/dashboardVersionsReducer';
import { getResources } from 'reducers/folderReducer';
import { clearReportBuilderVersions } from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { APP_UPDATE_MAP, AppUpdateType } from 'shared/AppUpdates/AppUpdateMap';
import { BaseAppUpdate } from 'shared/AppUpdates/BaseAppUpdate';
import { Folder, Resource, ResourcePageType } from 'types/exploResource';
import { getPermissionEntity, getResourceText } from 'utils/exploResourceUtils';
import { canUserViewResourceConfigurationMenu } from 'utils/permissionUtils';

import { FolderConfigurationMenu } from './FolderConfigurationMenu';
import { FolderItem } from './FolderItem';
import { MigrateDatasetsModal } from './MigrateDatasetsModal';
import { ResourceConfigurationMenu } from './ResourceConfigurationMenu';
import { ResourceItem } from './ResourceItem';
import * as styles from './styles.css';

type Props = {
  viewType?: ViewType;
  pageType: ResourcePageType;

  dashboardAttributes?: DashboardAttribute[];
  emailCadenceList?: ExploreEmailCadence[];
};

export const ResourceListPage: FC<Props> = ({
  viewType,
  pageType,
  dashboardAttributes,
  emailCadenceList,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const { currentUser, folders, currentFolderId, isLoading, isError, resources } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      folders: state.folder.folders,
      currentFolderId: state.folder.breadcrumbs[state.folder.breadcrumbs.length - 1]?.id,
      isLoading: RD.isLoading(state.folder.resourcesStatus),
      isError: RD.isError(state.folder.resourcesStatus),
      resources: getResources(state, isExploreProduct),
      createResourceLoading: createLoadingSelector(
        [ACTION.CREATE_DASHBOARD, ACTION.CLONE_DASHBOARD],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const [resourceUpdating, setResourceUpdating] = useState<number | undefined>();
  const [folderUpdating, setFolderUpdating] = useState<number | undefined>();
  const [resourceDatasetsBeingMigrated, setDatasetsBeingMigrated] = useState<number | undefined>();

  const resourceWithDatasetsBeingMigrated = useMemo(() => {
    if (!resourceDatasetsBeingMigrated) {
      return undefined;
    }

    return resources.find((resource) => resource.id === resourceDatasetsBeingMigrated);
  }, [resourceDatasetsBeingMigrated, resources]);

  const current_last_seen_id = currentUser.last_seen_app_update_id;

  const updateMap = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(APP_UPDATE_MAP).filter(([_, update]) =>
          isExploreProduct
            ? update.appUpdateType === AppUpdateType.EXPLORE
            : update.appUpdateType === AppUpdateType.REPORT_BUILDER,
        ),
      ),
    [isExploreProduct],
  );

  useEffect(() => {
    // For new users, assign last_seen_id to the maximum app update id so they don't see a ton of updates when they first log in
    if (current_last_seen_id === undefined)
      dispatch(
        updateLastSeenAppUpdateId({
          postData: {
            last_seen_id: Math.max(...Object.keys(APP_UPDATE_MAP).map((id) => Number(id))),
          },
        }),
      );
  }, [dispatch, current_last_seen_id]);

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const userCanConfigureResource = canUserViewResourceConfigurationMenu(
    currentUser,
    resourcePermissionEntity,
  );

  const rootPath = isExploreProduct ? ROUTES.HOME_APP_PAGE : ROUTES.REPORT_BUILDER;

  const appUpdates = useMemo(() => {
    // We don't want to show app updates if the user is new. This line prevents flickering of the update modal.
    if (current_last_seen_id === undefined) return [];
    const appUpdates: ReactNode[] = [];

    // Sort app updates in reverse order
    const sortedUpdates = Object.entries(updateMap).sort((id1, id2) => Number(id2) - Number(id1));
    sortedUpdates.forEach(([id, updateProps]) => {
      // Ignore updates user has already seen or they don't have permission to see
      if (
        Number(id) <= current_last_seen_id ||
        (updateProps?.permissionFn && !updateProps.permissionFn({ user: currentUser }))
      )
        return;
      appUpdates.push(<BaseAppUpdate {...updateProps} />);
    });
    return appUpdates;
  }, [currentUser, updateMap, current_last_seen_id]);

  if (isError) {
    return (
      <div className={styles.errorLoadingResources}>
        Error Loading {getResourceText(pageType, { plural: true, capitalized: true })}
      </div>
    );
  }

  const renderDotsMenu = (
    resource: Resource,
    emailCadence: ExploreEmailCadence | undefined,
    isCard: boolean,
    isDisabled: boolean,
  ) => {
    if (!userCanConfigureResource) return null;

    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (resource.id === resourceUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    if (isDisabled) {
      // Only Dashboard can be disabled
      return (
        <div className={containerStyle} onClick={(e) => e.stopPropagation()}>
          <DisabledDashboardMenu
            dashboard={resource as Dashboard}
            dashboardList={resources as Dashboard[]}
            setLoadingStateForDashboard={(isLoading) =>
              setResourceUpdating(isLoading ? resource.id : undefined)
            }
          />
        </div>
      );
    }

    return (
      <div className={containerStyle} onClick={(e) => e.stopPropagation()}>
        <ResourceConfigurationMenu
          currentFolderId={currentFolderId}
          dashboardAttributes={dashboardAttributes}
          emailCadence={emailCadence}
          onDuplicationModalClose={() =>
            dispatch(isExploreProduct ? clearDashboardVersions() : clearReportBuilderVersions())
          }
          pageType={pageType}
          resource={resource}
          resourcePermissionEntity={resourcePermissionEntity}
          setLoadingStateForResource={(isLoading) =>
            setResourceUpdating(isLoading ? resource.id : undefined)
          }
          trigger={<IconButton className={iconStyle} name="ellipsis-vertical" />}
        />
      </div>
    );
  };

  const renderFolderDotsMenu = (folder: Folder, isCard: boolean) => {
    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (folder.id === folderUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    return (
      <div className={containerStyle} onClick={(e) => e.preventDefault()}>
        <FolderConfigurationMenu
          folder={folder}
          pageType={pageType}
          setLoadingStateForFolder={(isLoading) =>
            setFolderUpdating(isLoading ? folder.id : undefined)
          }
          trigger={<IconButton className={iconStyle} name="ellipsis-vertical" />}
        />
      </div>
    );
  };

  const viewResource = (resource: Resource) => {
    const emailCadence = isExploreProduct
      ? emailCadenceList?.find((cadence) => cadence.dashboard_template_id === resource.id)
      : undefined;

    const isDisabled = 'disabled' in resource ? (resource.disabled ?? false) : false;

    return (
      <ResourceItem
        dotsMenu={renderDotsMenu(resource, emailCadence, viewType === ViewType.Card, isDisabled)}
        hasEmailState={emailCadence !== undefined}
        isCard={viewType === ViewType.Card}
        isLoading={isLoading}
        key={resource.id}
        onClickMigrateDatasetsTag={setDatasetsBeingMigrated}
        onClickUrl={
          isExploreProduct ? `/dashboard/${resource.id}` : `/report-builder/${resource.id}/datasets`
        }
        resource={resource}
        showPreview={pageType !== ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  const renderList = () => {
    return (
      <div className={viewType === ViewType.Card ? sprinkles({ flex: 1 }) : undefined}>
        <div
          className={viewType === ViewType.Card ? styles.cardsGrid : styles.resourceListContainer}>
          {folders.map((folder) => (
            <FolderItem
              dotsMenu={renderFolderDotsMenu(folder, viewType === ViewType.Card)}
              folder={folder}
              isCard={viewType === ViewType.Card}
              key={`${folder.id}-${folder.name}`}
              openFolder={(e) => {
                if (!isLoading) {
                  if (e.metaKey || e.ctrlKey) {
                    window.open(`${rootPath}/${folder.id}`, '_blank');
                  } else {
                    history.push(`${rootPath}/${folder.id}`);
                  }
                }
              }}
            />
          ))}
          {resources.map(viewResource)}
        </div>
      </div>
    );
  };

  return (
    <div className={sprinkles({ flex: 1 })}>
      <Modal
        isOpen={appUpdates.length > 0}
        onClose={() =>
          dispatch(
            updateLastSeenAppUpdateId({
              postData: {
                last_seen_id: Math.max(...Object.keys(updateMap).map((id) => Number(id))),
              },
            }),
          )
        }
        size="small"
        title="✨ New in Explo">
        {appUpdates}
      </Modal>
      {resourceDatasetsBeingMigrated ? (
        <MigrateDatasetsModal
          onClose={() => setDatasetsBeingMigrated(undefined)}
          selectedResourceId={resourceWithDatasetsBeingMigrated?.id ?? 0}
          selectedResourceTitle={resourceWithDatasetsBeingMigrated?.name ?? ''}
        />
      ) : null}
      {renderList()}
    </div>
  );
};
