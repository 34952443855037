import { NumberDisplayOptions, SchemaDisplayOption } from './display';
import { AggregationType, PivotAgg } from './instructions/aggregations';
import { GroupByBucket } from './instructions/groupings';
import { KPIPeriodInfo } from './instructions/kpis';

export type BaseCol = { name: string; type: string; friendly_name?: string };

export type BaseColOptional = Partial<BaseCol>;

export type DatasetSchema = BaseCol[];

export type DatasetRow = Record<string, string | number>;

export enum DatabaseUnsupportedOperations {
  PAGINATION = 'pagination',
}

export type AggCol = {
  column: BaseCol;
  agg: AggregationType;
};

export type AggColOptional = {
  // frustratingly this needs to directly be the partial to allow tsoa to generate
  // the rover yaml properly
  column: Partial<BaseCol>;
  agg: AggregationType;
};

export type GroupByCol = {
  column: BaseCol;
  bucket?: GroupByBucket;
};

export type GroupByColOptional = {
  // frustratingly this needs to directly be the partial to allow tsoa to generate
  // the rover yaml properly
  column: Partial<BaseCol>;
  bucket?: GroupByBucket;
  bucketElemId?: string;
  bucketSize?: number;
};

export type ColorCol = GroupByCol & {
  selected?: boolean;
};

export type KPIPeriodCol = KPIPeriodInfo & {
  column: BaseColOptional;
};

export type AggColInfo = AggCol & {
  // Only used by custom aggregations
  displayFormatting?: NumberDisplayOptions;
  // Lets us reuse components without being annoying
  bucket?: undefined;
};

export type AggColInfoOptional = AggColOptional & {
  // Only used by custom aggregations
  displayFormatting?: NumberDisplayOptions;
};

export interface CustomerReportGroupBy {
  column: BaseCol;
  bucket?: PivotAgg;
}

// Deprecated: Needed for backwards compatibility but should now use types above
// Used for DroppableColumnSection and Utils
export type OldConfigurableColInfo = {
  column: BaseColOptional;
  agg?: AggregationType;
  bucket?: GroupByBucket;
};

// Used by report builder and v2 pivot table
export type ColumnConfigWithName = { name: string; displayFormatting?: SchemaDisplayOption };
export type ColumnConfigsWithName = Record<string, ColumnConfigWithName>;

// This is used for normal explore table
export type TableColumnConfig = { displayFormatting?: SchemaDisplayOption };
export type TableColumnConfigs = Record<string, TableColumnConfig>;

// Union used by component to render the cells
export type ColumnConfigTypes = ColumnConfigWithName | TableColumnConfig;
export type ColumnConfigs = Record<string, ColumnConfigTypes>;

// Report Builder sets the PivotAgg as the bucket and not the id in the bucket
// So creating this union type to use in utils and such
export type GroupByColTypes = GroupByCol | CustomerReportGroupBy;
