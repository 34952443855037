import { DATE } from '../constants';
import {
  DateDisplayOptions,
  DateDisplayFormat,
  NumberDisplayOptions,
  NumberDisplayFormat,
  StringDisplayOptions,
  StringDisplayFormat,
  StringUrlDisplayFormat,
} from '../display';

export const getCurrentDateFormat = (
  dateDisplayOptions: DateDisplayOptions | undefined,
  colType: string,
): DateDisplayFormat => {
  if (dateDisplayOptions?.format) return dateDisplayOptions.format;
  return colType === DATE ? DateDisplayFormat.NORMAL : DateDisplayFormat.DATE_TIMESTAMP;
};

export const getCurrentNumberFormat = (
  numberDisplayOptions: NumberDisplayOptions | undefined,
): NumberDisplayFormat => {
  return numberDisplayOptions?.format ?? NumberDisplayFormat.NORMAL;
};

export const getCurrentStringFormat = (
  stringDisplayOptions: StringDisplayOptions | undefined,
): StringDisplayFormat => {
  return stringDisplayOptions?.format ?? StringDisplayFormat.NORMAL;
};

export const getCurrentUrlFormat = (urlFormat?: StringUrlDisplayFormat): StringUrlDisplayFormat => {
  return urlFormat ?? StringUrlDisplayFormat.LABEL;
};
