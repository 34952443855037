import {
  FilterClause,
  FilterOperator,
  FilterValueType,
  KPI_NUMBER_TREND_OPERATION_TYPES,
  OPERATION_TYPES,
} from '@explo/data';

import { DATE_ELEMENT_SET, FILTER_LINK_ELEMENTS } from 'constants/dashboardConstants';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardElementConfig,
  DashboardVariableMap,
} from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';

import { isDataPanelLinked } from './editableSectionUtils';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { isValidColumnTypeForOperation } from './filterOperations';
import { isVariableFalse, isVariableTrue } from './variableUtils';
import { Dataset } from 'actions/datasetActions';

export const isFilterLinked = (config: DashboardElementConfig): boolean => {
  if (!config.datasetLinks) return false;

  return !!Object.values(config.datasetLinks).find(
    (dsFilter) => dsFilter.column && dsFilter.dataPanels && dsFilter.dataPanels.length > 0,
  );
};

export const removeDataPanelsFromLinks = (
  dpIds: string[],
  elements: Record<string, DashboardElement>,
) => {
  if (dpIds.length === 0) return;
  const idSet = new Set(dpIds);

  Object.values(elements).forEach((element) => {
    if (!element.config.datasetLinks) return;

    Object.values(element.config.datasetLinks).forEach((datasetLink) => {
      if (!datasetLink.dataPanels) return;

      datasetLink.dataPanels = datasetLink.dataPanels.filter((id) => !idSet.has(id));
    });
  });
};

export const removeDatasetFromLinks = (datasetId: string, elements: DashboardElement[]) => {
  elements.forEach((element) => {
    const datasetLinks = element.config.datasetLinks;
    if (datasetLinks && datasetId in datasetLinks) {
      delete datasetLinks[datasetId];
    }
  });
};

export const getDataPanelLinks = (
  elements: DashboardElement[],
  changedElementNamesSet: Set<string>,
) => {
  const datasetToDps: Record<string, Set<string> | undefined> = {};
  elements.forEach((element) => {
    const { config, name } = element;
    if (!isValidLinkFilter(element) || !changedElementNamesSet.has(name)) return;

    Object.keys(config.datasetLinks ?? {}).map((datasetId) => {
      const linkConfig = config.datasetLinks?.[datasetId];
      if (!linkConfig?.dataPanels || linkConfig.dataPanels.length === 0) return;

      const datasetLinks = datasetToDps[datasetId];
      if (datasetLinks) {
        linkConfig.dataPanels.forEach((id) => datasetLinks.add(id));
      } else {
        datasetToDps[datasetId] = new Set(linkConfig.dataPanels);
      }
    });
  });
  return datasetToDps;
};

export const attachLinkFiltersToDp = (
  dp: DataPanel,
  datasets: Record<string, Dataset>,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
) => {
  const linkFilters = createDataPanelLinkFilterClauses(dp, datasets, elements, variables);
  if (linkFilters.length > 0) dp.filter_op.instructions.filterClauses.push(...linkFilters);
};

const createDataPanelLinkFilterClauses = (
  dp: DataPanel,
  datasets: Record<string, Dataset>,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
): FilterClause[] => {
  const datasetId = getDataPanelDatasetId(dp);
  const dataset = datasets[datasetId];
  if (!dataset) return [];

  return createLinkFilterClauses(dp, dataset, elements, variables);
};

const createLinkFilterClauses = (
  dp: DataPanel,
  dataset: Dataset,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
) => {
  const dpId = dp.id;
  return elements.reduce<FilterClause[]>((clauses, { config, name, element_type }) => {
    const variable = variables[name];

    if (
      !(FILTER_LINK_ELEMENTS.has(element_type) && config.operator && config.datasetLinks) ||
      variable === undefined ||
      isInvalidLinkCombo(element_type, dp.visualize_op.operation_type)
    )
      return clauses;

    const datasetLinks = config.datasetLinks[dataset.id];
    if (!datasetLinks?.column || !isDataPanelLinked(datasetLinks.dataPanels, dpId)) return clauses;
    const filterColumn = dataset.schema?.find((col) => col.name === datasetLinks.column);
    if (filterColumn && isValidColumnTypeForOperation(config.operator, filterColumn.type)) {
      if (config.operator === FilterOperator.BOOLEAN_IS) {
        const isTrue = isVariableTrue(variable);
        if (isTrue || isVariableFalse(variable)) {
          // Link Filter Operator BOOLEAN_IS means we need to compute based on the set variable
          // whether to filter as BOOLEAN_IS_TRUE or BOOLEAN_IS_FALSE.
          clauses.push({
            filterColumn,
            filterValue: undefined,
            filterOperation: {
              id: isTrue ? FilterOperator.BOOLEAN_IS_TRUE : FilterOperator.BOOLEAN_IS_FALSE,
            },
          });
        }
      } else {
        clauses.push({
          filterColumn,
          // operations that do date filters on a single date (i.e. date is after X)
          // have values that take the form of { startDate: x }
          filterValue: (element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER
            ? { startDate: variable }
            : variable) as FilterValueType,
          // Is it not undefined as isValidLinkFilter makes sure of that
          filterOperation: { id: config.operator },
        });
      }
    }

    return clauses;
  }, []);
};

const isValidLinkFilter = ({ element_type, config }: DashboardElement) => {
  return FILTER_LINK_ELEMENTS.has(element_type) && config.operator && config.datasetLinks;
};

export type DataPanelLink = {
  column: string;
  operator: FilterOperator;
  elementName: string;
  elementId: string;
  applied: boolean;
};

export const getPossibleLinksForDataPanel = (
  dp: DataPanel,
  datasets: Record<string, Dataset>,
  elements: DashboardElement[],
) => {
  const datasetId = getDataPanelDatasetId(dp);
  const dataset = datasets[datasetId];
  if (!dataset) return [];

  return elements.reduce<DataPanelLink[]>((acc, elem) => {
    const { config, name } = elem;

    if (
      !isInvalidLinkCombo(elem.element_type, dp.visualize_op.operation_type) &&
      isValidLinkFilter(elem) &&
      config.operator
    ) {
      const datasetLinks = config.datasetLinks?.[dataset.id];
      if (!datasetLinks?.column) return acc;
      const filterColumn = dataset.schema?.find((col) => col.name === datasetLinks.column);
      if (filterColumn && isValidColumnTypeForOperation(config.operator, filterColumn.type)) {
        acc.push({
          column: datasetLinks.column,
          operator: config.operator,
          elementName: name,
          elementId: elem.id,
          applied: !!datasetLinks.dataPanels?.includes(dp.id),
        });
      }
    }
    return acc;
  }, []);
};

// Date filters do not make sense for kpi trends
export const isInvalidLinkCombo = (
  elementType: DASHBOARD_ELEMENT_TYPES,
  operationType: OPERATION_TYPES,
) => DATE_ELEMENT_SET.has(elementType) && KPI_NUMBER_TREND_OPERATION_TYPES.has(operationType);

// Undefined means element does not have linking set up
// Null means linking is set up but just not for that dataset
export const getDashboardLinkForDataset = (
  dataset: Dataset,
  element: DashboardElement,
): Set<string> | null | undefined => {
  const { config, element_type } = element;
  if (!FILTER_LINK_ELEMENTS.has(element_type) || !config.operator) return;

  const datasetLink = config.datasetLinks?.[dataset.id];
  if (!datasetLink?.column) return null;

  const col = dataset.schema?.find((col) => col.name === datasetLink.column);
  if (!col || !isValidColumnTypeForOperation(config.operator, col.type)) return null;

  return new Set(datasetLink.dataPanels ?? []);
};
