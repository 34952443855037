import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  EmbedFetchShareData,
  embedFetchShareData,
  embedFetchShareDataWithPassword,
} from 'actions/embedActions';
import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { VIEW_MODE } from 'types/dashboardTypes';

import { SharePasswordRequiredPage } from './sharePasswordRequiredPage';

type MatchParams = {
  shareId: string;
};

export default function SharedDashboardPage() {
  const [shareData, setShareData] = useState<EmbedFetchShareData | null>(null);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [isUsernameProtected, setIsUsernameProtected] = useState(false);

  const dispatch = useDispatch();
  const {
    params: { shareId },
    url,
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData(
        { id: shareId },
        (shareData) => setShareData(shareData),
        (error) => {
          // API returns 405 when we send a GET for a password-protected link
          if (error.status === 405) {
            setIsPasswordProtected(true);
            if (error.detail.indexOf('username and password required') >= 0) {
              setIsUsernameProtected(true);
            }
          }
        },
      ),
    );
  }, [dispatch, shareId]);

  useEffect(() => {
    if (!shareData) return;
    const faviconElement = document.getElementById('site-favicon') as HTMLAnchorElement;
    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;

    if (faviconElement && shareData.favicon_url)
      faviconElement.href = `https://www.google.com/s2/favicons?domain=${shareData.favicon_url}`;
    if (siteTitleElement) {
      siteTitleElement.innerHTML =
        shareData.site_title || 'Explo | Build customer-facing dashboards, lightning fast';
    }
  }, [shareData]);

  const renderContent = () => {
    if (shareData) {
      return (
        <EmbeddedDashboard
          updateUrlParams
          customerToken={shareData.customer_token}
          dashboardEmbedId={shareData.dashboard_embed_id}
          embedType={EmbeddedDashboardType.SHARED} // Keeping the old way to set strict mode through the URL for backwards compatibility
          isStrict={shareData.is_strict_viewing_mode || url.toLocaleUpperCase().endsWith('STRICT')}
          versionNumber={shareData.version_number}
          viewMode={VIEW_MODE.SHARE}
        />
      );
    }

    return (
      <SharePasswordRequiredPage
        fetchShareData={(password, onFailure, username) =>
          dispatch(
            embedFetchShareDataWithPassword(
              { id: shareId, postData: { password, username } },
              (shareData) => setShareData(shareData),
              (e) => {
                if (e.status === 403 || e.status === 405) onFailure();
              },
            ),
          )
        }
        isUsernameProtected={isUsernameProtected}
      />
    );
  };

  // If share data is null and no password is required then page should be loading
  if (shareData === null && !isPasswordProtected) {
    return <EmbedSpinner fillContainer size="xl" />;
  }

  return <div className={sprinkles({ parentContainer: 'fill' })}>{renderContent()}</div>;
}
