import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Icon, IconButton, sprinkles } from 'components/ds';

import { ComputedView, Folder } from '@explo-tech/fido-api';
import { ItemType } from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';
import { listBranchContentThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { COMPUTED_VIEW_TYPE, FOLDER_CONTENT_FETCH_ERROR_MESSAGE } from './constants';
import { getItemNameFromPath } from './dataLibraryUtil';

import { EmbedSpinner } from 'components/embed';
import { ItemPathInfo } from 'reducers/dataLibraryReducer';
import * as RD from 'remotedata';
import { FetchErrorComponent } from './FetchErrorComponent';
import { ResourceControl } from './ResourceControl';
import { getAllModifiedResourceIds } from './selectors';

interface Props {
  // The folder id and folder path are passed in separately from the folder object itself since
  // the id and path can be determined from the parent folder's children object but the full child
  // folder cannot be determined from the parent folder's children object.
  folderId: string;
  folderPath: string;
  folder: RD.ResponseData<Folder>;
  searchQuery: string;
  selectedItemPath: ItemPathInfo;
  branchId: string;
  shouldReadFromHead: boolean;
  expansionState: Map<string, boolean>;

  onComputedViewSelection: (computedView: ComputedView) => void;
  onFolderSelection: (folderId: string, folderPath: string) => void;
  setIsExpanded: (folderPath: string, isExpanded: boolean) => void;
}

const HOME_FOLDER_NAME = 'Home';

export const DirectoryPanelSection: FC<Props> = ({
  folderId,
  folderPath,
  folder,
  searchQuery,
  selectedItemPath,
  branchId,
  shouldReadFromHead,
  expansionState,
  onComputedViewSelection,
  onFolderSelection,
  setIsExpanded,
}) => {
  const dispatch = useDispatch();

  const { folders, pendingResourceCreations, allModifiedResourceIds } = useSelector(
    (state: ReduxState) => {
      return {
        folders: shouldReadFromHead
          ? (state.dataLibrary.updatedFolders.get(branchId) ?? new Map())
          : (state.dataLibrary.folders.get(branchId) ?? new Map()),
        pendingResourceCreations: state.dataLibrary.pendingResourceCreations,
        allModifiedResourceIds: shouldReadFromHead ? getAllModifiedResourceIds(state) : new Set(),
      };
    },
    shallowEqual,
  );

  const isExpanded = expansionState.get(folderPath) ?? false;

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    if (
      !pendingResourceCreations.has(folderId) &&
      (RD.isIdle(folders.get(folderPath)) || !folders.has(folderPath))
    ) {
      dispatch(
        listBranchContentThunk({
          id: branchId,
          path: folderPath ?? '',
          resourceType: ItemType.FOLDER,
        }),
      );
    }
  }, [dispatch, branchId, folderId, folderPath, isExpanded, pendingResourceCreations, folders]);

  const { folderName, isRoot } = useMemo(() => {
    let folderName = getItemNameFromPath(folderPath);
    const isRoot = folderName === '';
    folderName = isRoot ? HOME_FOLDER_NAME : folderName;
    return { folderName, isRoot };
  }, [folderPath]);

  const filteredContents = useMemo(() => {
    if (!RD.isSuccess(folder) || !folder.data.children) {
      return [];
    }

    return folder.data.children.filter((content) => {
      const itemName = getItemNameFromPath(content.path ?? '');
      return itemName.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [folder, searchQuery]);

  const subFolders = useMemo(() => {
    if (!RD.isSuccess(folder) || !folder.data.children) {
      return [];
    }

    return folder.data.children.filter((content) => content['@type'] === 'folder') as Folder[];
  }, [folder]);

  const filteredComputedViews = useMemo(() => {
    return filteredContents.filter(
      (content) => content['@type'] === COMPUTED_VIEW_TYPE,
    ) as ComputedView[];
  }, [filteredContents]);

  const isSelectedFolder =
    selectedItemPath.type === ItemType.FOLDER && folderPath === selectedItemPath.path;
  const areAllComputedViewsFiltered = filteredComputedViews.length === 0 && searchQuery.length > 0;
  const folderNameHasQuery = folderName.toLowerCase().includes(searchQuery.toLowerCase());
  const subFolderNamesHaveQuery = subFolders.some((subFolder) =>
    subFolder.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const folderHasContents = RD.getOrDefault(folder, null)?.children?.length ?? 0 > 0;
  const folderHasError = RD.isError(folder);

  const areFolderSubcontentsBeingFetched = RD.isLoading(folder);

  return (
    <>
      {!areAllComputedViewsFiltered || folderNameHasQuery || subFolderNamesHaveQuery || isRoot ? (
        <div
          className={getFolderControlClass(isSelectedFolder, allModifiedResourceIds.has(folderId))}
          onClick={() => {
            onFolderSelection(folderId, folderPath);
            setIsExpanded(folderPath, true);
          }}>
          <div className={sprinkles({ flexItems: 'alignCenter', minHeight: 32, width: 'fill' })}>
            {!isRoot ? (
              <IconButton
                className={sprinkles({ marginRight: 'sp.5' })}
                name={isExpanded ? 'chevron-down' : 'chevron-right'}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsExpanded(folderPath, !isExpanded);
                }}
              />
            ) : null}
            <div className={sprinkles({ truncateText: 'ellipsis' })}>
              {isRoot ? <Icon className={sprinkles({ marginX: 'sp1' })} name="home" /> : null}
              {folderName}
            </div>
          </div>
          {isSelectedFolder ? (
            <Icon className={sprinkles({ color: 'active', marginRight: 'sp1' })} name="check" />
          ) : null}
        </div>
      ) : null}
      {isExpanded ? (
        areFolderSubcontentsBeingFetched ? (
          <EmbedSpinner className={sprinkles({ marginX: 'sp1' })} size="md" />
        ) : folderHasContents ? (
          <>
            {subFolders.map((subFolder) => {
              return (
                <div key={subFolder.path}>
                  <div className={sprinkles({ marginLeft: 'sp3' })}>
                    <DirectoryPanelSection
                      branchId={branchId}
                      expansionState={expansionState}
                      folder={folders.get(subFolder.path ?? '') ?? RD.Loading()}
                      folderId={subFolder.id ?? ''}
                      folderPath={subFolder.path ?? ''}
                      onComputedViewSelection={onComputedViewSelection}
                      onFolderSelection={onFolderSelection}
                      searchQuery={searchQuery}
                      selectedItemPath={selectedItemPath}
                      setIsExpanded={setIsExpanded}
                      shouldReadFromHead={shouldReadFromHead}
                    />
                  </div>
                </div>
              );
            })}
            {filteredComputedViews.map((computedView) => {
              const isFilteredViewSelected =
                selectedItemPath.type === ItemType.VIEW &&
                selectedItemPath.path === computedView.path;
              return (
                <div
                  className={getComputedViewControlClass(
                    isFilteredViewSelected,
                    allModifiedResourceIds.has(computedView.id ?? ''),
                  )}
                  key={computedView.path}
                  onClick={() => onComputedViewSelection(computedView)}>
                  <ResourceControl
                    resourceDisplay={computedView.name}
                    resourceType={computedView['@type']}
                  />
                  {isFilteredViewSelected ? (
                    <Icon
                      className={sprinkles({ color: 'active', marginRight: 'sp1' })}
                      name="check"
                    />
                  ) : null}
                </div>
              );
            })}
          </>
        ) : (
          <div
            className={sprinkles({
              marginLeft: 'sp4.5',
              marginY: 'sp1',
              color: 'contentTertiary',
              truncateText: 'ellipsis',
            })}>
            {folderHasError ? (
              <FetchErrorComponent
                disabled={RD.isLoading(folder)}
                errorMessage={FOLDER_CONTENT_FETCH_ERROR_MESSAGE}
                onRetry={() => {
                  dispatch(
                    listBranchContentThunk({
                      id: branchId,
                      path: folderPath,
                      resourceType: ItemType.FOLDER,
                    }),
                  );
                }}
              />
            ) : null}
            {searchQuery.length === 0 && !folderHasError ? 'No folders or datasets' : null}
          </div>
        )
      ) : null}
    </>
  );
};

const getFolderControlClass = (isSelected: boolean, isModified: boolean) =>
  sprinkles({
    backgroundColor: isSelected
      ? 'lightActive'
      : { hover: isSelected ? 'lightActiveHovered' : 'elevationLow' },
    borderRadius: 8,
    marginY: 'sp1',
    flexItems: 'alignCenterBetween',
    cursor: 'pointer',
    color: isModified ? 'yellow11' : 'contentPrimary',
  });

const getComputedViewControlClass = (isSelected: boolean, isModified: boolean) =>
  sprinkles({
    backgroundColor: isSelected
      ? 'lightActive'
      : { hover: isSelected ? 'lightActiveHovered' : 'elevationLow' },
    borderRadius: 8,
    flexItems: 'alignCenterBetween',
    paddingLeft: 'sp1',
    paddingY: 'sp1',
    cursor: 'pointer',
    marginLeft: 'sp3',
    color: isModified ? 'yellow11' : 'contentPrimary',
  });
