import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Input, Toggle, ToggleItem } from 'components/ds';
import {
  SpiderChartFormat,
  SpiderChartShape,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  lineWidth: number;
};

export const SpiderChartConfig: FC<Props> = ({ visualizationType, instructions, lineWidth }) => {
  const dispatch = useDispatch();

  const spiderChartFormat = instructions.chartSpecificFormat?.spiderChart;
  const selectedShape = spiderChartFormat?.spiderChartShape || SpiderChartShape.ROUND;

  const updateSpiderChartInstructions = (spiderChartUpdates: SpiderChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.chartSpecificFormat) draft.chartSpecificFormat = {};
      if (!draft.chartSpecificFormat.spiderChart) draft.chartSpecificFormat.spiderChart = {};

      draft.chartSpecificFormat.spiderChart = {
        ...draft.chartSpecificFormat?.spiderChart,
        ...spiderChartUpdates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <Toggle
        className={configInputClass}
        label="Grid Shape"
        onValueChange={(val) =>
          updateSpiderChartInstructions({ spiderChartShape: val as SpiderChartShape })
        }
        selectedValue={selectedShape}>
        <ToggleItem icon="draw-circle" value={SpiderChartShape.ROUND} />
        <ToggleItem icon="draw-polygon" value={SpiderChartShape.POLYGON} />
      </Toggle>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(spiderChartFormat?.lineWidth ?? lineWidth)}
        label="Line Width"
        onSubmit={(newValue) => {
          const newInt = parseInt(newValue);
          updateSpiderChartInstructions({ lineWidth: newInt > 0 ? newInt : undefined });
        }}
      />
    </div>
  );
};
