import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES, PIE_CHART_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Input, Switch } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { V2TwoDimensionChartInstructions, YAxisFormat } from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import {
  DEFAULT_Y_AXIS_FORMAT_INDEX,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const InteractiveTooltipConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const yAxisFormat = instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX];
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  const updateYAxis = (updatedYAxisFormat: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...updatedYAxisFormat };
    dispatch(
      updateVisualizeOperation(updateYAxisFormat(newYAxisFormat, instructions), visualizationType),
    );
  };

  return (
    <div className={configRootClass}>
      <NumberFormatToggle
        disableAbbr
        disableTime
        className={configInputClass}
        label="Value Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => updateYAxis({ numberFormat: { id: numberFormat } })}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(yAxisFormat?.decimalPlaces ?? 2)}
        label="Value Decimal Places"
        onSubmit={(newValue: string) => {
          const intValue = parseInt(newValue);
          updateYAxis({ decimalPlaces: intValue > 0 ? intValue : 0 });
        }}
      />
      {PIE_CHART_TYPES.has(visualizationType) ? (
        <>
          <Switch
            className={configInputClass}
            label="Show percentage values"
            onChange={() => {
              updateYAxis({ showPct: !yAxisFormat?.showPct });
            }}
            switchOn={yAxisFormat?.showPct}
          />
          <Switch
            className={configInputClass}
            label="Show total"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.tooltipFormat) draft.tooltipFormat = {};
                draft.tooltipFormat.showTotal = !draft.tooltipFormat.showTotal;
              });
              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.tooltipFormat?.showTotal}
          />
        </>
      ) : null}
    </div>
  );
};
