import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { ViewState } from 'react-map-gl';

import { OPERATION_TYPES } from '@explo/data';

import { VisualizeOperationInstructions } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { updateOperationConfigThunk } from 'reducers/thunks/dataPanelConfigThunks';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const updateMapViewConfig =
  (
    operationType: OPERATION_TYPES,
    instructions: VisualizeOperationInstructions,
    view: ViewState,
  ): Thunk =>
  (dispatch) => {
    if (operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP) {
      dispatch(
        updateOperationConfigThunk(
          operationType,
          (instructions.VISUALIZE_GEOSPATIAL_CHART ??= {}),
          (draft) => {
            (draft.mapFormat ??= {}).viewState = view;
          },
        ),
      );
    } else if (operationType === OPERATION_TYPES.VISUALIZE_DENSITY_MAP) {
      dispatch(
        updateOperationConfigThunk(
          operationType,
          (instructions.VISUALIZE_GEOSPATIAL_CHART ??= {}),
          (draft) => {
            (draft.densityMapFormat ??= {}).viewState = view;
          },
        ),
      );
    } else {
      dispatch(
        updateOperationConfigThunk(
          operationType,
          (instructions.V2_TWO_DIMENSION_CHART ??= {}),
          (draft) => {
            ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).viewState = view;
          },
        ),
      );
    }
  };
