import { Dispatch } from 'redux';

import { ROUTES } from 'constants/routes';
import { Action } from 'reducers/rootReducer';
import { isExternalFacingDashboard } from 'utils/environmentUtils';
import Cookies from 'js-cookie';

const sessionExpiredMiddleware = () => (next: Dispatch<Action>) => (action: Action) => {
  // TODO: Action should never be undefined, but it sometimes is. Need to investigate why.
  const errorDataStatus = action?.payload?.errorData?.status;
  if ([404, 401].includes(errorDataStatus) && !isExternalFacingDashboard()) {
    document.cookie.split(';').forEach((c) => {
      const cookieName = c.replace(/^ +/, '').split('=')[0];
      Cookies.remove(cookieName, { domain: '.explo.co', path: '/' });
    });
    return (window.location.href = `${ROUTES.LOGIN}#expired`);
  }
  return next(action ?? { type: '', payload: {} });
};

export default sessionExpiredMiddleware;
