import produce from 'immer';
import { FC } from 'react';

import { Label, Select, sprinkles } from 'components/ds';
import { SpinnerSize, SpinnerWidth } from 'components/embed/EmbedSpinner';
import { DEFAULT_COMPONENTS_CONFIG } from 'globalStyles/constants';
import { GlobalStyleConfig, GlobalStyleSpinnerConfig } from 'globalStyles/types';

import ColorPicker from '../CustomStylesColorPicker';

const SpinnerSizes: { label: string; value: SpinnerSize }[] = [
  { label: 'Small', value: 'sm' },
  { label: 'Medium', value: 'md' },
  { label: 'Large', value: 'lg' },
  { label: 'Extra Large', value: 'xl' },
];

const SpinnerWidths: { label: string; value: SpinnerWidth }[] = [
  { label: 'Thick', value: 'thick' },
  { label: 'Regular', value: 'regular' },
  { label: 'Light', value: 'light' },
  { label: 'Thin', value: 'thin' },
];

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export const SpinnerConfig: FC<Props> = ({ styleConfig, updateConfig }) => {
  const config = styleConfig.components?.spinner ?? DEFAULT_COMPONENTS_CONFIG.spinner;

  const handleChange = (prop: Partial<GlobalStyleSpinnerConfig>) => {
    const newConfig = produce(styleConfig, (draft) => {
      if (!draft.components) draft.components = { ...DEFAULT_COMPONENTS_CONFIG };

      draft.components.spinner = { ...draft.components.spinner, ...prop };
    });
    updateConfig(newConfig);
  };

  return (
    <div className={sprinkles({ padding: 'sp2' })}>
      <Select
        className={sprinkles({ marginBottom: 'sp2' })}
        label={{ text: 'Size', infoText: 'Only affects spinners that fill their container' }}
        onChange={(value) => handleChange({ size: value as SpinnerSize })}
        selectedValue={config.size}
        values={SpinnerSizes}
      />
      <Select
        className={sprinkles({ marginBottom: 'sp2' })}
        label="Width"
        onChange={(value) => handleChange({ width: value as SpinnerWidth })}
        selectedValue={config.width}
        values={SpinnerWidths}
      />
      <Label htmlFor="ColorPicker">Color</Label>
      <ColorPicker
        fill
        color={config.color}
        onClose={(newColor) => handleChange({ color: newColor })}
      />
    </div>
  );
};
