import { makeStyles, Theme } from '@material-ui/core/styles';
import { FC } from 'react';

import { BaseCol, DatasetSchema, FilterOperator, FilterValueType } from '@explo/data';

import { FilterValueInput } from 'components/FilterValueInput';
import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import DropdownSelect from 'shared/DropdownSelect';
import { createColOptionsWithIcon, createColOptionWithIconFromCol } from 'utils/general';

import { OperatorDropdown } from './OperatorDropdown';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  columnOrOperatorOption: {
    display: 'flex',
    marginRight: theme.spacing(2),
    '& .bp3-button': {
      border: `1px solid ${theme.palette.ds.white}`,
      fontSize: 12,
    },
    '& .bp3-input-group .bp3-input': {
      border: `1px solid ${theme.palette.ds.white}`,
    },
  },
  operatorAndValueContainer: {
    width: 300,
    display: 'flex',
  },
}));

export type Props = {
  baseSchema: DatasetSchema;
  deleteDisabled?: boolean;
  selectedColumn?: BaseCol;
  selectedOperator?: FilterOperator;
  filterValue?: FilterValueType;
  onColumnSelect: (column: BaseCol) => void;
  onOperatorSelect: (operator: FilterOperator) => void;
  onFilterValueUpdate: (value: FilterValueType) => void;
  onDelete: () => void;
};

export const FilterRow: FC<Props> = ({
  onDelete,
  deleteDisabled,
  baseSchema,
  onColumnSelect,
  onOperatorSelect,
  selectedColumn,
  selectedOperator,
  filterValue,
  onFilterValueUpdate,
}) => {
  const classes = useStyles();

  const longestColumnLength = Math.max(
    6, //length of 'Column'
    ...baseSchema.map((col) => (col.friendly_name || col.name).length),
  );

  return (
    <div className={classes.container}>
      <EmbedButton
        className={sprinkles({ marginRight: 'sp.5' })}
        disabled={deleteDisabled}
        icon="cross"
        onClick={onDelete}
        variant="tertiary"
      />
      <span className={classes.columnOrOperatorOption}>
        <DropdownSelect
          fillWidth
          minimal
          showIcon
          buttonStyle={{
            // Adding 5 because of icon
            width: `${Math.min(longestColumnLength + 5, 25)}ch`,
          }}
          containerClassName={sprinkles({ width: 'fill' })}
          filterable={false}
          noSelectionText="Column"
          onChange={(item) => {
            const column = baseSchema.find((col) => col.name === item.id);
            column && onColumnSelect(column);
          }}
          options={createColOptionsWithIcon(baseSchema, true)}
          selectedItem={
            selectedColumn ? createColOptionWithIconFromCol(selectedColumn, true) : undefined
          }
        />
      </span>
      <span className={classes.operatorAndValueContainer}>
        <OperatorDropdown
          className={classes.columnOrOperatorOption}
          disabled={!selectedColumn}
          onOperatorSelect={onOperatorSelect}
          selectedColumn={selectedColumn}
          selectedOperator={selectedOperator}
        />
        <FilterValueInput
          adHocFilterSettings
          embed
          disabled={!selectedColumn}
          filterOperator={selectedOperator}
          filterValue={filterValue}
          onFilterValueUpdate={onFilterValueUpdate}
          usePortal={false}
        />
      </span>
    </div>
  );
};
