import { Reducer, createSlice } from '@reduxjs/toolkit';

import {
  Dashboard,
  activateDisabledDashboardSuccess,
  createDashboardSuccess,
  deleteDashboardSuccess,
  fetchDashboardError,
  fetchDashboardListSuccess,
  fetchDashboardRequest,
  fetchDashboardSuccess,
  renameDashboardSuccess,
  saveShareLinkTitleRequest,
  updateDashboardCacheConfigSuccess,
  updateDashboardDefaultTimezoneSuccess,
  updateDashboardStatePersistanceSuccess,
  updateDisableFiltersWhileLoadingSuccess,
  updateValuesShareColorsAcrossDashboardSuccess,
} from 'actions/dashboardActions';
import { assignDashboardValueSuccess } from 'actions/dashboardAttributesActions';
import { ErrorResponse } from 'actions/responseTypes';
import {
  listDashboardFolderContents,
  listResourceContentNames,
  moveEntry,
} from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { DASHBOARD_RESPONSE_KEY_RENAME_MAP, DashboardHierarchy } from 'types/dashboardTypes';
import { Breadcrumb, ResourceType } from 'types/exploResource';
import { ValueType, deepMapKeys } from 'utils/objectUtils';

import { cloneResourceThunk } from './thunks/versionManagementThunks';

interface DashboardReducerState {
  currentDashboard: RD.ResponseData<CurrentDashboard>;
  folderDashboardList?: Dashboard[]; // the dashboards within the current folder context
  currentFolderResourceNames?: RD.ResponseData<Set<string>>; // the dashboard names within the current folder context
  dashboardList?: Dashboard[]; // all dashboards
  error?: ErrorResponse;
  dashboardHierarchy: RD.ResponseData<DashboardHierarchy>;
}

interface CurrentDashboard extends Dashboard {
  breadcrumbs: Breadcrumb[];
}

const dashboardReducerInitialState: DashboardReducerState = {
  currentDashboard: RD.Idle(),
  dashboardHierarchy: RD.Idle(),
};

const updateDashboardList = (
  state: DashboardReducerState,
  dashboardId: number | string | undefined | null,
  updateFunc: (dashboard: Dashboard) => void,
): void => {
  if (state.folderDashboardList) {
    const dashboard = state.folderDashboardList.find((elem) => elem.id === dashboardId);

    if (dashboard) updateFunc(dashboard);
  }

  if (state.dashboardList) {
    const dashboard = state.dashboardList.find((elem) => elem.id === dashboardId);

    if (dashboard) updateFunc(dashboard);
  }

  if (RD.isSuccess(state.currentDashboard) && state.currentDashboard.data.id === dashboardId) {
    updateFunc(state.currentDashboard.data);
  }
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardReducerInitialState,
  reducers: {
    clearFolderDashboards: (state) => {
      state.folderDashboardList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDashboardSuccess, (state, { payload }) => {
        if (state.folderDashboardList) {
          state.folderDashboardList.push(payload.new_dashboard_template);
        }

        if (state.dashboardList) {
          state.dashboardList.push(payload.new_dashboard_template);
        }

        state.currentDashboard = RD.Success({
          ...payload.new_dashboard_template,
          // breadcrumbs are loaded imminently when we fetch the dashboard
          breadcrumbs: [],
        });
      })
      .addCase(cloneResourceThunk.fulfilled, (state, { payload, meta }) => {
        const { new_resource } = payload;
        if (!meta.arg.isExplore || !('dashboard_attributes' in new_resource)) return;

        state.currentDashboard = RD.Success({
          ...new_resource,
          // breadcrumbs are loaded imminently when we fetch the dashboard
          breadcrumbs: [],
        });

        if (state.folderDashboardList) {
          state.folderDashboardList.push(new_resource);
        }
        if (state.dashboardList) {
          state.dashboardList.push(new_resource);
        }
      })
      .addCase(deleteDashboardSuccess, (state, { payload }) => {
        if (state.folderDashboardList) {
          const deletedIndex = state.folderDashboardList.findIndex(
            (elem) => elem.id === payload.id,
          );
          if (deletedIndex >= 0) state.folderDashboardList.splice(deletedIndex, 1);
        }

        if (state.dashboardList) {
          const deletedIndex = state.dashboardList.findIndex((elem) => elem.id === payload.id);
          if (deletedIndex >= 0) state.dashboardList.splice(deletedIndex, 1);
        }
      })
      .addCase(activateDisabledDashboardSuccess, (state, { payload }) => {
        if (state.folderDashboardList) {
          const activateIndex = state.folderDashboardList.findIndex(
            (elem) => elem.id === payload.id,
          );
          if (activateIndex >= 0) state.folderDashboardList[activateIndex].disabled = false;

          const deactivateIndex = state.folderDashboardList.findIndex(
            (elem) => elem.id === payload.postData.disable_dashboard_id,
          );
          if (deactivateIndex >= 0) state.folderDashboardList[deactivateIndex].disabled = true;
        }

        if (state.dashboardList) {
          const activateIndex = state.dashboardList.findIndex((elem) => elem.id === payload.id);
          if (activateIndex >= 0) state.dashboardList[activateIndex].disabled = false;

          const deactivateIndex = state.dashboardList.findIndex(
            (elem) => elem.id === payload.postData.disable_dashboard_id,
          );
          if (deactivateIndex >= 0) state.dashboardList[deactivateIndex].disabled = true;
        }
      })
      .addCase(fetchDashboardRequest, (state) => {
        state.currentDashboard = RD.Loading();
      })
      .addCase(fetchDashboardSuccess, (state, { payload }) => {
        // TODO(zifanxiang): Type deepMapKeys differently so we don't have to do this type of
        // casting.
        const remappedDashboard = deepMapKeys(
          payload.dashboard as unknown as Record<string, ValueType>,
          (responseKey: string) => DASHBOARD_RESPONSE_KEY_RENAME_MAP[responseKey] || responseKey,
        ) as unknown as Dashboard;
        state.currentDashboard = RD.Success({
          ...remappedDashboard,
          breadcrumbs: payload.breadcrumbs,
        });
        if (payload.dashboard_hierarchy) {
          state.dashboardHierarchy = RD.Success(
            deepMapKeys(
              payload.dashboard_hierarchy,
              (responseKey: string) =>
                DASHBOARD_RESPONSE_KEY_RENAME_MAP[responseKey] || responseKey,
            ) as DashboardHierarchy,
          );
        }
      })
      .addCase(fetchDashboardError, (state, { payload }) => {
        state.currentDashboard = RD.Error(payload.errorData?.detail || 'Error fetching dashboard');
      })
      .addCase(fetchDashboardListSuccess, (state, { payload }) => {
        state.dashboardList = payload.dashboard_template_list;
      })
      .addCase(listDashboardFolderContents.fulfilled, (state, { payload }) => {
        state.folderDashboardList = payload.resources;
      })
      .addCase(listResourceContentNames.fulfilled, (state, { payload }) => {
        state.currentFolderResourceNames = RD.Success(payload.resource_names);
      })
      .addCase(listResourceContentNames.pending, (state) => {
        state.currentFolderResourceNames = RD.Loading();
      })
      .addCase(listResourceContentNames.rejected, (state) => {
        state.currentFolderResourceNames = RD.Error('Error getting folder resource names');
      })
      .addCase(renameDashboardSuccess, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          dashboard.name = payload.name;
        });
      })
      .addCase(saveShareLinkTitleRequest, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          dashboard.share_link_title = payload.postData.share_link_title;
        });
      })
      .addCase(updateDashboardDefaultTimezoneSuccess, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          dashboard.default_timezone = payload.postData.default_timezone;
        });
      })
      .addCase(updateDisableFiltersWhileLoadingSuccess, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          dashboard.disable_filters_while_loading = payload.postData.disable_filters_while_loading;
        });
      })
      .addCase(updateValuesShareColorsAcrossDashboardSuccess, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          dashboard.should_values_share_colors_across_dashboard =
            payload.postData.should_values_share_colors_across_dashboard;
        });
      })
      .addCase(updateDashboardCacheConfigSuccess, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          if (payload.postData.is_cache_enabled !== undefined)
            dashboard.is_cache_enabled = payload.postData.is_cache_enabled;
          if (payload.postData.cache_cron !== undefined)
            dashboard.cache_cron = payload.postData.cache_cron;
        });
      })
      .addCase(assignDashboardValueSuccess, (state, { payload }) => {
        const { value_id, attribute_id, template_id } = payload.postData;
        updateDashboardList(state, template_id, (template) => {
          const newAttributes = template.dashboard_attributes.filter(
            (elem) => elem.attribute_id !== attribute_id,
          );
          if (value_id !== '') newAttributes.push({ attribute_id, value_id: parseInt(value_id) });
          template.dashboard_attributes = newAttributes;
        });
      })
      .addCase(updateDashboardStatePersistanceSuccess, (state, { payload }) => {
        updateDashboardList(state, payload.id, (dashboard) => {
          dashboard.should_persist_customer_state = payload.postData.should_persist_customer_state;
        });
      })
      .addCase(moveEntry.fulfilled, (state, { payload }) => {
        if (!state.folderDashboardList || payload.entry.type !== ResourceType.DASHBOARD) return;
        state.folderDashboardList = state.folderDashboardList.filter(
          ({ entry_id }) => entry_id !== payload.entry.id,
        );
        if (RD.isSuccess(state.currentDashboard)) {
          state.currentDashboard.data.breadcrumbs = payload.breadcrumbs;
        }
      });
  },
});

export const { clearFolderDashboards } = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer as Reducer<DashboardReducerState>;
