import {
  GradientOptions,
  GradientPointOptions,
  GradientPointType,
  GradientShape,
  GradientType,
  NumberDisplayOptions,
} from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { DEFAULT_GRADIENT } from 'constants/dataPanelEditorConstants';
import { DashboardVariableMap, NumberColumnMetrics } from 'types/dashboardTypes';

import { mixColors } from './general';
import { resolveNumberInputWithVariables } from './variableUtils';

const boundRatioZeroToOne = (ratio: number): number => Math.max(0, Math.min(1, ratio));

const getPercentageRatio = (min: number, max: number, value: number) => {
  if (min >= 0 && max >= 0) {
    // We use || -0.0001 to avoid dividing by zero
    return (value - min) / (max - min || 0.0001);
  }
  return 1 - Math.abs(value - max) / Math.abs(min - max || 0.0001);
};

// This is used to check if we need to show gradients for a table column
export const isGradientEnabled = (gradientType: GradientType | undefined) =>
  !!gradientType && gradientType !== GradientType.NONE;

type GetGradientColorParams = {
  value: number;
  metrics: NumberColumnMetrics | undefined;
  gradient: Partial<GradientShape> | undefined;
  gradientType: GradientType | undefined;
  gradientOptions: GradientOptions | undefined;
  variables: DashboardVariableMap | undefined;
  datasetNamesToId: Record<string, string> | undefined;
  datasetData: DatasetDataObject | undefined;
};

// This is used to get the gradient color for a table column
// Should only be called if isGradientEnabled returns true
export const getGradientColor = ({
  gradient,
  gradientType,
  gradientOptions,
  value,
  metrics,
  variables,
  datasetNamesToId,
  datasetData,
}: GetGradientColorParams) => {
  const minColor = gradient?.hue1 || DEFAULT_GRADIENT.hue1;
  const maxColor = gradient?.hue3 || DEFAULT_GRADIENT.hue3;

  const resolve = (s: string | undefined) =>
    resolveNumberInputWithVariables(s, variables, datasetNamesToId, datasetData);

  const minpoint =
    (gradientOptions?.minpoint?.type === GradientPointType.NUMBER
      ? resolve(gradientOptions?.minpoint?.value)
      : metrics?.min) ?? 0;

  const maxpoint =
    (gradientOptions?.maxpoint?.type === GradientPointType.NUMBER
      ? resolve(gradientOptions?.maxpoint?.value)
      : metrics?.max) ?? 0;

  if (gradientType === GradientType.LINEAR) {
    const ratio = getPercentageRatio(minpoint, maxpoint, value);
    return mixColors(maxColor, minColor, boundRatioZeroToOne(ratio)).rgb().string();
  }

  const midColor = gradient?.hue2 || DEFAULT_GRADIENT.hue2;
  const midpoint =
    (gradientOptions?.midpoint?.type === GradientPointType.NUMBER
      ? resolve(gradientOptions?.midpoint?.value)
      : metrics?.avg) ?? 0;

  // Diverging gradient
  if (value < midpoint) {
    const ratio = getPercentageRatio(minpoint, midpoint, value);
    return mixColors(midColor, minColor, boundRatioZeroToOne(ratio)).rgb().string();
  } else {
    const ratio = getPercentageRatio(midpoint, maxpoint, value);
    return mixColors(maxColor, midColor, boundRatioZeroToOne(ratio)).rgb().string();
  }
};

// If all points are fixed numbers no need to fetch secondary data for gradient
export const isDataRequiredForTableColumnGradient = (options: NumberDisplayOptions) => {
  if (!isGradientEnabled(options.gradientType)) return false;

  const { minpoint, maxpoint, midpoint } = options.gradientOptions ?? {};

  const isDiverging = options.gradientType === GradientType.DIVERGING;

  return (
    minpoint?.type !== GradientPointType.NUMBER ||
    maxpoint?.type !== GradientPointType.NUMBER ||
    // midpoint only matters for diverging gradient
    (isDiverging && midpoint?.type !== GradientPointType.NUMBER)
  );
};

/**
 * Below are heatmap related gradient functions
 */

export const getHeatmapStopPoint = (
  point: GradientPointOptions | undefined,
  min: number,
  max: number,
  defaultFloat: number,
  variables: DashboardVariableMap,
  datasetNamesToId: Record<string, string>,
  datasetData?: DatasetDataObject,
): number => {
  if (max <= min || point?.type !== GradientPointType.NUMBER) return defaultFloat;

  const value = resolveNumberInputWithVariables(
    point.value,
    variables,
    datasetNamesToId,
    datasetData,
  );

  return value === undefined ? defaultFloat : (Number(value) - min) / (max - min);
};

// If this is true we need to do some calculation over data
export const needsToCalculateStopsForHeatmap = (options: GradientOptions) => {
  const { maxpoint, midpoint, minpoint } = options;

  return (
    maxpoint?.type === GradientPointType.NUMBER ||
    midpoint?.type === GradientPointType.NUMBER ||
    minpoint?.type === GradientPointType.NUMBER
  );
};
