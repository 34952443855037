import { FC, useState } from 'react';

import { ParentSchema } from 'actions/dataSourceActions';
import { Button } from 'components/ds';
import { CreateDatasetModal } from './CreateDatasetModal';
import { ButtonVariant } from 'components/ds/Button';

type Props = {
  datasetNames: Set<string>;
  textOverride?: string;
  variantOverride?: ButtonVariant;
  className?: string;

  onSubmit: (name: string, parentSchema: ParentSchema) => void;
};

export const AddDatasetButton: FC<Props> = ({
  datasetNames,
  textOverride,
  variantOverride,
  className,
  onSubmit,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        fillWidth
        className={className}
        icon="plus"
        onClick={() => setModalOpen(true)}
        variant={variantOverride ?? 'primary'}>
        {textOverride ?? 'New Dataset'}
      </Button>
      {modalOpen ? (
        <CreateDatasetModal
          currentDatasetNames={datasetNames}
          onClose={() => setModalOpen(false)}
          onSubmit={onSubmit}
        />
      ) : null}
    </>
  );
};
