import produce from 'immer';
import { gcd, mod } from 'mathjs';

import {
  BaseCol,
  COLOR_SYNC_CHART_TYPES,
  DatasetRow,
  getTimezoneAwareUnix,
  OPERATION_TYPES,
  SchemaDisplayOptions,
  StringDisplayFormat,
  StringDisplayOptions,
} from '@explo/data';

import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import {
  CategoryToColor,
  ChartColumnInfo,
  ColorCategoryTracker,
  ColorFormat,
  ColorPalette,
  ColorPaletteV2,
  ColumnColorTracker,
  VisualizeOperationInstructions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  getAxisNumericalValue,
  getColorColNames,
  getColorPalette,
  shouldProcessColAsDate,
} from 'pages/dashboardPage/charts/utils';
import { murmurhash, orderBy } from 'utils/standard';

import { isSelectedColorDateType } from './colorColUtils';

type SetColorCategoryTableDataParams = {
  displayOptions: SchemaDisplayOptions;
  previewData: DatasetRow[];
  columnColorTracker?: ColumnColorTracker;
  globalStyleConfig: GlobalStyleConfig;
  shouldReplace?: boolean; // If true, replaces existing colors in tracker. Should be used for tables or custom palettes which do not have syncing
};

export function getTableColors(globalStyleConfig: GlobalStyleConfig) {
  const colorFormat: ColorFormat = { selectedPalette: ColorPaletteV2.CATEGORICAL };
  return getColorPalette(globalStyleConfig, colorFormat) || DEFAULT_CATEGORY_COLORS;
}

export const setTableColorCategoryData = ({
  displayOptions,
  columnColorTracker,
  previewData,
  globalStyleConfig,
  shouldReplace,
}: SetColorCategoryTableDataParams): ColumnColorTracker => {
  return produce(columnColorTracker || {}, (draft) => {
    Object.entries(displayOptions).forEach(([columnName, displayOptions]) => {
      const stringDisplayOptions = displayOptions as StringDisplayOptions;
      if (stringDisplayOptions.format !== StringDisplayFormat.CATEGORY) return;

      const colors = getTableColors(globalStyleConfig);

      // For tables, we want to replace the existing colors in the tracker. Tables do not share colors so this is fine
      // This should match the behavior of CategoryFieldColorAssignment where we alternate through DEFAULT_CATEGORY_COLORS
      // unless the category exists in categoryColorAssignments
      const paletteToColor: CategoryToColor = draft[columnName] || {};
      setColorsForColorCategoryTracker({
        previewData,
        columnName,
        tracker: paletteToColor,
        colors,
        shouldReplace,
        colorAssignments: stringDisplayOptions.categoryColorAssignments,
      });
      draft[columnName] = paletteToColor;
    });
  });
};

// exported for testing
export function findNearbyCoPrime(startValue: number, hashTableSize: number): number {
  let coprime = startValue;
  while (gcd(coprime, hashTableSize) !== 1) {
    coprime = (coprime + 1) % hashTableSize;
  }
  return coprime;
}

// Exported for testing
export const getHashedPaletteColor = (
  category: string,
  colorsLength: number,
  hashFunction: (input: string) => number,
  seenColors: Set<string | undefined>,
  colors: string[],
  shouldRehash?: boolean,
) => {
  const hash = hashFunction(category);
  let index = mod(hash, colorsLength);
  // This means there are still some open slots in the color palette, so we should try to fill them
  if (shouldRehash && seenColors.size < colorsLength) {
    // Use this counter to make sure we don't infinitely loop (we shouldn't, but let's be safe)
    let counter = 0;
    // If the slot is occupied, rehash to a new index until we find an empty spot
    const offset = findNearbyCoPrime(colorsLength - (hash % (colorsLength - 1)), colorsLength);
    while (seenColors.has(colors[index]) && counter < colorsLength) {
      index = (index + offset) % colorsLength;
      counter += 1;
    }
  }
  return colors[index];
};

export const setAggColColorsForColorCategoryTracker = (
  paletteToColor: CategoryToColor,
  aggCol: ChartColumnInfo,
  dashboardCategoryColors: Record<string, string>,
) => {
  // Allow user to set column colors by data label name, we will always save it under the agg column name
  const colName = aggCol.name;
  const dataLabelKey = aggCol?.friendly_name ?? colName;
  if (!dataLabelKey || !colName) return;
  const dashboardColor = dashboardCategoryColors?.[dataLabelKey];
  paletteToColor[colName] = dashboardColor;
};

type SetColorsForColorCategoryTrackerParams = {
  previewData: DatasetRow[];
  columnName: string;
  tracker: CategoryToColor;
  // Color palette
  colors: string[];
  // If true, replaces existing colors in tracker.
  // Should be used for tables or custom palettes which do not have syncing
  shouldReplace: boolean | undefined;
  // For tables: Color overrides. Key is the value name, value is the color
  colorAssignments?: Record<string | number, string>;
  // 2D Charts convert date columns to number
  isDateType?: boolean;
  dashboardCategoryColors?: Record<string, string>;
  shouldValuesShareColors?: boolean;
};

export const setColorsForColorCategoryTracker = ({
  previewData,
  columnName,
  tracker,
  colors,
  shouldReplace,
  colorAssignments,
  isDateType,
  dashboardCategoryColors,
  shouldValuesShareColors,
}: SetColorsForColorCategoryTrackerParams) => {
  const categories = new Set<string>();
  const colorsLength = colors.length;

  let currentIndex = shouldReplace ? 0 : Object.keys(tracker).length;
  // Remove duplicate strings
  const data = [...new Set(previewData.map((row) => row[columnName]))];
  const dataLength = data.length;
  // Only rehash if there are fewer or equal categories than colors
  const shouldRehashColorIndex = shouldValuesShareColors && dataLength <= colorsLength;
  // We only need to sort if we'll be rehashing to make sure we rehash in the same order each time
  if (shouldRehashColorIndex) data.sort((val1, val2) => String(val1).localeCompare(String(val2)));
  // Use a number that is coprime with the length of the color palette as a seed to the hash function to avoid collisions
  const hashFunction = (input: string) =>
    murmurhash(input, findNearbyCoPrime(colorsLength + 1, colorsLength));
  for (const inputVal of data) {
    let val = inputVal;
    if (isDateType && typeof val === 'string') {
      val = getTimezoneAwareUnix(val);
    }

    const category = String(val);
    if (shouldReplace ? categories.has(category) : tracker[category]) continue;

    const dashboardColor = dashboardCategoryColors?.[category];
    if (dashboardColor) tracker[category] = dashboardColor;
    else if (colorAssignments?.[category]) {
      tracker[category] = colorAssignments?.[category];
    } else {
      const seenColors = new Set(Object.values(tracker).filter((color) => color !== undefined));
      if (shouldValuesShareColors) {
        // The hashed index is used to generate a consistent color for a category value, based on its characters.
        // This way, colors can be synced between charts and even multiple embeddings of the same dashboard
        tracker[category] = getHashedPaletteColor(
          category,
          colorsLength,
          hashFunction,
          seenColors,
          colors,
          shouldRehashColorIndex,
        );
      } else {
        tracker[category] = colors[currentIndex % colorsLength];
        currentIndex++;
      }
    }

    categories.add(category);
  }
};

type Set2DColorCategoryDataParams = {
  operationInstructions: VisualizeOperationInstructions;
  colorCategoryTracker: ColorCategoryTracker;
  previewData: DatasetRow[];
  globalStyleConfig: GlobalStyleConfig;
  schema: BaseCol[];
  operationType: OPERATION_TYPES;
  dataPanelId: string;
  shouldReplace?: boolean; // If true, replaces existing colors in tracker. Should be used for tables or custom palettes which do not have syncing
  dashboardCategoryColors: Record<string, string>;
  shouldValuesShareColors?: boolean;
};

function set2DColorCategoryData({
  operationInstructions,
  globalStyleConfig,
  schema,
  operationType,
  colorCategoryTracker,
  previewData,
  dataPanelId,
  shouldReplace,
  dashboardCategoryColors,
  shouldValuesShareColors,
}: Set2DColorCategoryDataParams) {
  const v2Instructions = operationInstructions.V2_TWO_DIMENSION_CHART ?? {};
  const colorFormat = v2Instructions.colorFormat ?? {};
  const colors = getColorPalette(globalStyleConfig, colorFormat) ?? [];
  if (colors.length === 0) return;

  const { xAxisColName, colorColName } = getColorColNames(schema, operationType);
  const groupName = getColorTrackerCategoryName(xAxisColName, colorColName);
  const aggCols = v2Instructions?.aggColumns || [];
  const aggColNames = schema.map((col) => col.name).slice(1);

  const isPieChart = operationType === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2;

  const isDateType =
    // Pie chart does color grouping on the category column not color columns
    isPieChart
      ? shouldProcessColAsDate(v2Instructions.categoryColumn)
      : isSelectedColorDateType(v2Instructions);

  const pieAggColName = schema[1]?.name;

  // Order data so that the slices are colored by order of palette
  const data =
    isPieChart && pieAggColName
      ? orderBy(previewData, (row) => getAxisNumericalValue(row[pieAggColName]), 'desc')
      : previewData;

  const isCustomPalette = colorFormat.selectedPalette === ColorPalette.CUSTOM;
  const key = getColorPaletteId(dataPanelId, colorFormat.selectedPalette);

  const setColorsForColorCategory = (paletteToColor: CategoryToColor, columnName: string) => {
    setColorsForColorCategoryTracker({
      previewData: data,
      columnName,
      tracker: paletteToColor,
      colors,
      shouldReplace: isCustomPalette && !!shouldReplace,
      isDateType,
      // If custom palette is used, we want to ignore dashboard colors
      dashboardCategoryColors: isCustomPalette ? undefined : dashboardCategoryColors,
      shouldValuesShareColors,
    });
  };

  colorCategoryTracker[key] = produce(colorCategoryTracker[key] || {}, (draft) => {
    // When user updates custom palette configs, we want to replace the existing tracker with all the new colors
    // For other palettes, we want to keep the existing colors since the tracker is shared between charts and colors won't change
    const paletteToColor: CategoryToColor = draft[groupName] || {};
    setColorsForColorCategory(paletteToColor, colorColName);
    draft[groupName] = paletteToColor;
    // we don't want to overwrite the custom colors with agg col colors
    if (isCustomPalette) return;
    // for each agg column, set the dashboard-wide color in the color category tracker
    aggColNames.forEach((aggColName, index) => {
      const column = aggCols?.[index]?.column;
      if (!column) return;
      const paletteToColor: CategoryToColor = draft[aggColName] || {};
      setAggColColorsForColorCategoryTracker(paletteToColor, column, dashboardCategoryColors);
      draft[aggColName] = paletteToColor;
    });

    // If we have a Sankey chart, we also need to color the x-axis column (which is the source column)
    if (operationType === OPERATION_TYPES.VISUALIZE_SANKEY_CHART) {
      const sourcePaletteToColor: CategoryToColor = draft[xAxisColName] || {};
      setColorsForColorCategory(sourcePaletteToColor, xAxisColName);
      draft[xAxisColName] = sourcePaletteToColor;
    }
  });
}

// if the xAxis and colorCol are the same,
// colorColName will suffix '1' from /query_runner/__init__.py `fetch_columns`
// so use xAxisColName for categoryName
export const getColorTrackerCategoryName = (xAxisColName: string, colorColName: string) =>
  colorColName === xAxisColName + '1' ? xAxisColName : colorColName;

type SetColorCategoryDataParams = {
  operationInstructions: VisualizeOperationInstructions;
  colorCategoryTracker: ColorCategoryTracker;
  previewData: DatasetRow[];
  globalStyleConfig: GlobalStyleConfig;
  dashboardCategoryColors: Record<string, string>;
  schema: BaseCol[];
  operationType: OPERATION_TYPES;
  dataPanelId: string;
  shouldReplace?: boolean; // If true, replaces existing colors in tracker. Should be used for tables or custom palettes which do not have syncing
  shouldValuesShareColors?: boolean;
};

export const setColorCategoryData = ({
  colorCategoryTracker,
  globalStyleConfig,
  operationType,
  operationInstructions,
  previewData,
  schema,
  dataPanelId,
  shouldReplace,
  dashboardCategoryColors,
  shouldValuesShareColors,
}: SetColorCategoryDataParams): void => {
  if (!previewData || previewData.length === 0 || !schema || schema.length === 0) return;

  if (!COLOR_SYNC_CHART_TYPES.has(operationType)) return;

  if (
    operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
    operationInstructions.VISUALIZE_TABLE.schemaDisplayOptions
  ) {
    colorCategoryTracker[dataPanelId] = setTableColorCategoryData({
      displayOptions: operationInstructions.VISUALIZE_TABLE.schemaDisplayOptions,
      globalStyleConfig,
      columnColorTracker: colorCategoryTracker[dataPanelId],
      previewData,
      shouldReplace,
    });
    return;
  }

  set2DColorCategoryData({
    operationInstructions,
    globalStyleConfig,
    schema,
    operationType,
    colorCategoryTracker,
    previewData,
    dataPanelId,
    shouldReplace,
    dashboardCategoryColors,
    shouldValuesShareColors,
  });
};

interface GetColorFromPaletteTrackerParams {
  columnName: string;
  valueName: string;
  colorTracker?: ColumnColorTracker;
}

/**
 * @param columnName
 * @param valueName - Value should be raw and unformatted
 * @param colorTracker
 */
export const getColorFromPaletteTracker = ({
  columnName,
  valueName,
  colorTracker,
}: GetColorFromPaletteTrackerParams) => colorTracker?.[columnName]?.[valueName];

const getColorPaletteId = (dataPanelId?: string, paletteName?: string) => {
  return (
    (paletteName === ColorPalette.CUSTOM ? dataPanelId : paletteName) || ColorPaletteV2.CATEGORICAL
  );
};

interface GetColorPaletteTrackerParams {
  dataPanelId?: string;
  paletteName?: ColorPalette | ColorPaletteV2;
  colorCategoryTracker?: ColorCategoryTracker;
}

export const getColorPaletteTracker = ({
  dataPanelId,
  paletteName,
  colorCategoryTracker,
}: GetColorPaletteTrackerParams) => {
  const key = getColorPaletteId(dataPanelId, paletteName);
  return colorCategoryTracker?.[key];
};
