import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { IconButton, sprinkles, Tooltip } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';

import { clearQueryState, ItemType, setFolderIsExpanded } from 'reducers/dataLibraryReducer';
import * as RD from 'remotedata';
import { DirectoryPanelContent } from './DirectoryPanelContent';
import { ROOT_FOLDER_PATH } from './constants';
import * as styles from './dataLibraryPage.css';
import { navigateToPathThunk } from './navigationUtils';
import { getRootFolderForBranch } from './selectors';

interface Props {
  renderCommitButton: boolean;
  commitButtonEnabled: boolean;
  shouldReadFromHead: boolean;

  onCommitButtonClicked: () => void;
  onViewCommitHistoryButtonClicked: () => void;
}

export const DirectoryPanel: FC<Props> = ({
  renderCommitButton,
  commitButtonEnabled,
  shouldReadFromHead,
  onCommitButtonClicked,
  onViewCommitHistoryButtonClicked,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(true);

  const { currentBranch, rootFolder, selectedItemPath, pendingCommitStatus, folderExpansionState } =
    useSelector((state: ReduxState) => {
      return {
        currentBranch: state.dataLibrary.currentBranch,
        rootFolder: getRootFolderForBranch(state, state.dataLibrary.currentBranch),
        selectedItemPath: state.dataLibrary.currentItemPath,
        pendingCommitStatus: state.dataLibrary.pendingCommitStatus,
        folderExpansionState: state.dataLibrary.folderExpansionState,
      };
    });

  if (!RD.isSuccess(currentBranch) || !RD.isSuccess(rootFolder)) {
    // TODO(zifanxiang): Add in a loading spinner here.
    return <></>;
  }

  return isExpanded ? (
    <div className={styles.directoryPanelContainer}>
      <div className={styles.directoryPanelHeaderContainer}>
        <div
          className={sprinkles({ cursor: 'pointer', margin: 'sp2' })}
          onClick={() =>
            dispatch(
              navigateToPathThunk(
                rootFolder.data.id ?? '',
                ItemType.FOLDER,
                ROOT_FOLDER_PATH,
                history,
              ),
            )
          }>
          Data Library
        </div>
        <Tooltip text="Collapse Data Library side bar">
          <IconButton
            name={'square-chevron-left'}
            onClick={() => {
              setIsExpanded(false);
            }}
          />
        </Tooltip>
      </div>
      <DirectoryPanelContent
        branchId={currentBranch.data.id ?? ''}
        commitButtonEnabled={commitButtonEnabled}
        expansionState={folderExpansionState}
        onCommitButtonClicked={onCommitButtonClicked}
        onComputedViewSelection={(computedView) => {
          dispatch(
            navigateToPathThunk(
              computedView.id ?? '',
              ItemType.VIEW,
              computedView.path ?? '',
              history,
            ),
          );
          dispatch(clearQueryState());
        }}
        onFolderSelection={(folderId, folderPath) => {
          dispatch(navigateToPathThunk(folderId, ItemType.FOLDER, folderPath, history));
        }}
        onViewCommitHistoryButtonClicked={onViewCommitHistoryButtonClicked}
        pendingCommitStatus={pendingCommitStatus}
        renderCommitButton={renderCommitButton}
        rootFolder={rootFolder.data}
        selectedItemPath={selectedItemPath}
        setIsExpanded={(folderPath: string, isExpanded: boolean) => {
          dispatch(setFolderIsExpanded({ folderPath, isExpanded }));
        }}
        shouldReadFromHead={shouldReadFromHead}
      />
    </div>
  ) : (
    <div
      className={sprinkles({
        borderColor: 'gray5',
        borderRight: 1,
        height: 'fill',
        paddingX: 'sp2',
      })}>
      <Tooltip text="Expand Data Library side bar">
        <IconButton
          className={sprinkles({ marginY: 'sp2' })}
          name="square-chevron-right"
          onClick={() => {
            setIsExpanded(true);
          }}
        />
      </Tooltip>
      <Tooltip text="Create commit">
        <IconButton
          className={sprinkles({ marginY: 'sp2' })}
          disabled={!commitButtonEnabled}
          name="cloud-upload"
          onClick={onCommitButtonClicked}
        />
      </Tooltip>
      <Tooltip text="View commit history">
        <IconButton
          className={sprinkles({ marginY: 'sp2' })}
          name="rectangle-history"
          onClick={onViewCommitHistoryButtonClicked}
        />
      </Tooltip>
    </div>
  );
};
