import { Icon as BPIcon } from '@blueprintjs/core';
import cx from 'classnames';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { SortableListItemContext } from 'components/SortableList/SortableList';
import { Icon, IconButton, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { ELEM_TYPE_TO_ICON, DASHBOARD_ELEMENT_TYPE_TO_NAME } from 'constants/dashboardConstants';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';

import * as styles from './index.css';

interface Props {
  actionIcon: IconName;
  elemId: string;
  elemType: DASHBOARD_ELEMENT_TYPES;
  name: string;
  onActionClicked: () => void;
  shouldRenderActionButton: boolean;
}

export const DraggableHeaderElemOption: FC<Props> = ({
  actionIcon,
  elemId,
  elemType,
  name,
  onActionClicked,
  shouldRenderActionButton,
}) => {
  const dispatch = useDispatch();
  const { attributes, listeners } = useContext(SortableListItemContext);

  return (
    <div
      className={cx(
        headerElemStyle,
        styles.headerElemOptionContainer,
        styles.draggableHeaderElemOptionContainer,
      )}
      onMouseEnter={() => dispatch(setHighlightedDataPanelIds(new Set([elemId])))}
      onMouseLeave={() => dispatch(setHighlightedDataPanelIds(new Set()))}>
      <div {...attributes} {...listeners} className={sprinkles({ flexItems: 'center' })}>
        <Icon
          className={sprinkles({ marginRight: 'sp1', color: 'contentSecondary' })}
          name="grip-dots"
        />
        <div className={styles.headerElemIcon}>
          <BPIcon icon={ELEM_TYPE_TO_ICON[elemType]} />
        </div>
        <div className={sprinkles({ paddingRight: 'sp2' })}>
          <div
            className={cx(
              sprinkles({
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }),
              styles.headerElemName,
            )}>
            {name}
          </div>
          <div className={sprinkles({ color: 'contentSecondary', whiteSpace: 'nowrap' })}>
            {DASHBOARD_ELEMENT_TYPE_TO_NAME[elemType]}
          </div>
        </div>
      </div>
      {shouldRenderActionButton ? (
        <IconButton
          name={actionIcon}
          onClick={() => {
            onActionClicked();
            dispatch(setHighlightedDataPanelIds(new Set()));
          }}
          variant="tertiary"
        />
      ) : null}
    </div>
  );
};

const headerElemStyle = sprinkles({
  flexItems: 'alignCenterBetween',
  borderRadius: 8,
  padding: 'sp.5',
  width: 'fill',
});
