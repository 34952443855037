import cx from 'classnames';
import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { Toggle, ToggleItem, sprinkles, Input, Label, Switch } from 'components/ds';
import { ColorSettings, KPIColorFormat, V2KPIChartInstructions } from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { ReduxState } from 'reducers/rootReducer';
import { HEX_COLORS } from '@explo/design-system';

const KPI_COLOR_OPTIONS: { id: ColorSettings; name: string }[] = [
  {
    id: ColorSettings.CONSTANT,
    name: 'Constant',
  },
  {
    id: ColorSettings.CONDITIONAL,
    name: 'Conditional',
  },
];

type Props = {
  instructions: V2KPIChartInstructions;
  visualizationType: OPERATION_TYPES.VISUALIZE_PROGRESS_V2 | OPERATION_TYPES.VISUALIZE_NUMBER_V2;
};

export const KPIColorConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  if (!globalStyleConfig) return null;

  const updateFormat = (colorFormat: KPIColorFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.colorFormat = { ...draft.colorFormat, ...colorFormat };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const colorFormat = instructions.colorFormat;
  const selectedColorType = colorFormat?.colorSettingType;

  const categoricalColors = getCategoricalColors(globalStyleConfig);

  return (
    <div className={configRootClass}>
      <Toggle
        className={configInputClass}
        label="Format Type"
        onValueChange={(value) =>
          updateFormat({
            colorSettingType: value === 'default' ? undefined : (value as ColorSettings),
          })
        }
        selectedValue={selectedColorType ?? 'default'}>
        <ToggleItem value="default">Default</ToggleItem>
        {KPI_COLOR_OPTIONS.map((colorFormat) => (
          <ToggleItem key={colorFormat.id} value={colorFormat.id}>
            {colorFormat.name}
          </ToggleItem>
        ))}
      </Toggle>
      {selectedColorType === ColorSettings.CONSTANT ? (
        <div className={configInputClass}>
          <Label htmlFor="">Value Text Color</Label>
          <ColorPickerButton
            fillWidth
            color={colorFormat?.constantColor || globalStyleConfig.text.primaryColor}
            colorPalette={categoricalColors}
            onColorChange={(hex) => updateFormat({ constantColor: hex })}
          />
        </div>
      ) : null}
      {selectedColorType === ColorSettings.CONDITIONAL ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={String(colorFormat?.conditionalTriggerValue ?? '')}
            label={{ text: 'Conditional Trigger Value', variableInput: true }}
            onSubmit={(newValue) => updateFormat({ conditionalTriggerValue: newValue })}
          />
          <div className={sprinkles({ flexItems: 'alignCenter', width: 'fill' })}>
            <div className={cx(configInputClass, sprinkles({ flex: 1 }))}>
              <Label htmlFor="">Positive Color</Label>
              <ColorPickerButton
                fillWidth
                color={colorFormat?.conditionalPositiveColor || HEX_COLORS.green9}
                colorPalette={categoricalColors}
                onColorChange={(hex) => updateFormat({ conditionalPositiveColor: hex })}
              />
            </div>
            <div className={cx(configInputClass, sprinkles({ flex: 1 }))}>
              <Label htmlFor="">Negative Color</Label>
              <ColorPickerButton
                fillWidth
                color={colorFormat?.conditionalNegativeColor || HEX_COLORS.red9}
                colorPalette={categoricalColors}
                onColorChange={(hex) => updateFormat({ conditionalNegativeColor: hex })}
              />
            </div>
          </div>
        </>
      ) : null}
      {selectedColorType !== undefined ? (
        <Switch
          className={configInputClass}
          label={
            visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
              ? 'Apply Color to Goal & Units'
              : 'Apply Color to Units'
          }
          onChange={() =>
            updateFormat({ applyColorToProgressGoal: !colorFormat?.applyColorToProgressGoal })
          }
          switchOn={colorFormat?.applyColorToProgressGoal}
        />
      ) : null}
    </div>
  );
};
