import { BAR_CHART_TYPES, OPERATION_TYPES } from '@explo/data';

// TODO(zifanxiang/tstenz): Add more operation types as we support them. Starting with just basic
// bar charts.
export const VALID_DRILLDOWN_OPERATION_TYPES: Set<OPERATION_TYPES> = new Set([
  ...BAR_CHART_TYPES,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
]);

// These limit constants must be kept in sync with their corresponding constants in
// dashboard_template.py.
export const MAX_CHILD_DASHBOARDS_PER_PARENT = 3;

export const MAX_DASHBOARD_HIERARCHY_DEPTH = 3;
