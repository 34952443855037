import './global.css';
import { Intent, baseThemeName, vars, CONTAINER_PADDING, HEX_COLORS, tokens } from './vars.css';

export { sprinkles } from './sprinkles.css';
// TODO: Tokens shouldn't be necessary to export
export { baseThemeName, vars, CONTAINER_PADDING, HEX_COLORS, Intent, tokens };

export const APP_PORTAL_ID = 'explo-app';

export type Colors = keyof typeof vars.colors;
export type IntentType = (typeof Intent)[keyof typeof Intent];
