import cx from 'classnames';
import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const UpdateDatasetsButton: FC<Props> = ({ className, onClick }) => {
  return (
    <button
      className={cx(className, buttonClassName)}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.();
      }}>
      <>
        <Icon className={iconClassName} name="arrow-up" />
        <span className={sprinkles({ marginX: 'sp1', color: 'active' })}>Update datasets</span>
      </>
    </button>
  );
};

const buttonClassName = sprinkles({
  borderRadius: 8,
  flexItems: 'alignCenter',
  backgroundColor: 'blue3',
  paddingX: 'sp1',
  paddingY: 'sp.5',
});

const iconClassName = sprinkles({
  backgroundColor: 'active',
  borderRadius: 'round',
  color: 'gray5',
  padding: 'sp.25',
});
