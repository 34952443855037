import { FC, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';
import { v4 as uuidv4 } from 'uuid';

import { editCustomer } from 'actions/customerActions';
import {
  EndUser,
  Customer,
  AccessGroup,
  GroupTag,
  HierarchyLevel,
  Properties,
} from 'actions/teamActions';
import { SideSheet, AlertModal, sprinkles } from 'components/ds';
import { clearPotentialParents } from 'reducers/customersReducer';
import * as RD from 'remotedata';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import { cloneDeep, isEqual } from 'utils/standard';

import { CustomerContent, EditorCustomer } from './customerContent';
import { isEditorGroupValid, formatPropertiesForSubmit, getStringifiedValue } from './utils';

type Props = {
  onClose: () => void;
  selectedEntity: Customer;
  accessGroups: AccessGroup[];
  endUsers: EndUser[];
  groupTags: RD.ResponseData<GroupTag[]>;
  hierarchyLevels: HierarchyLevel[];
};

export const EditCustomerSideSheet: FC<Props> = ({
  onClose,
  selectedEntity,
  accessGroups,
  endUsers,
  groupTags,
  hierarchyLevels,
}) => {
  const dispatch = useDispatch();

  const propertiesInfo = useMemo(() => {
    const properties = selectedEntity.properties;
    const propInfo: Properties = {};
    Object.entries(properties).forEach(([name, val]) => {
      const id = `prop-${uuidv4()}`;

      propInfo[id] = {
        id: id,
        key: name,
        value: getStringifiedValue(val),
      };
    });
    return propInfo;
  }, [selectedEntity]);

  const initialEditorGroup = useMemo(
    () => ({
      name: selectedEntity.name,
      providedId: selectedEntity.provided_id,
      mapping: selectedEntity.parent_schema_datasource_mapping,
      properties: propertiesInfo,
      accessGroupId: selectedEntity.access_group_id || -1,
      isDemoGroup: selectedEntity.is_demo_group || false,
      endUsers: endUsers,
      selectedDashboardId: endUsers.length > 0 ? endUsers[0].permissioned_dashboard_id : undefined,
      selectedGroupTagIds: selectedEntity.group_tags.map((tag) => tag.group_tag_id) ?? [],
      emails: selectedEntity.emails,
      hierarchyLevelId: selectedEntity.hierarchy_level_id,
      parentProvidedId: selectedEntity.parent_provided_id,
    }),
    [selectedEntity, endUsers, propertiesInfo],
  );

  const [editorGroup, setEditorGroup] = useImmer<EditorCustomer>(cloneDeep(initialEditorGroup));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const hasChanges = useMemo(
    () => !isEqual(initialEditorGroup, editorGroup),
    [editorGroup, initialEditorGroup],
  );

  const isValidUpdate = useMemo(() => isEditorGroupValid(editorGroup), [editorGroup]);

  const handleSubmit = () => {
    const {
      name,
      providedId,
      mapping,
      endUsers,
      accessGroupId,
      isDemoGroup,
      properties,
      selectedGroupTagIds,
      selectedDashboardId,
      emails,
      hierarchyLevelId,
      parentProvidedId,
    } = editorGroup;
    if (!isValidUpdate || isSubmitting) return;

    let customerProperties;
    try {
      customerProperties = formatPropertiesForSubmit(properties);
    } catch {
      showErrorToast('Customer properties contain invalid JSON.');
      return;
    }

    setIsSubmitting(true);
    dispatch(
      editCustomer(
        {
          id: selectedEntity?.id,
          postData: {
            name,
            mapping,
            emails,
            provided_id: providedId,
            access_group_id: accessGroupId,
            is_demo_group: isDemoGroup,
            properties: customerProperties,
            end_users: endUsers,
            group_tag_ids: selectedGroupTagIds,
            permissioned_dashboard_id: selectedDashboardId,
            hierarchy_level_id: hierarchyLevelId,
            parent_provided_id: parentProvidedId,
          },
        },
        (response) => showSuccessToast(`Customer ${response.customer.name} successfully updated`),
        (response) => showErrorToast(response.error_msg || `Error updating customer ${name}`),
      ),
    );

    onClose();
  };

  const handleCloseAttempt = () => {
    if (hasChanges) {
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
      onClose();
      dispatch(clearPotentialParents());
    }
  };

  return (
    <>
      <SideSheet
        isOpen
        className={sprinkles({ zIndex: 'sidesheets' })}
        onClickOutside={handleCloseAttempt}
        onCloseClick={handleCloseAttempt}
        primaryButtonProps={{
          text: 'Update',
          onClick: handleSubmit,
          disabled: !hasChanges || !isValidUpdate,
        }}
        secondaryButtonProps={{
          text: 'Discard',
          onClick: () => setEditorGroup(cloneDeep(initialEditorGroup)),
          disabled: !hasChanges,
        }}
        title={`Edit ${selectedEntity?.name}`}>
        <CustomerContent
          accessGroups={accessGroups}
          editorGroup={editorGroup}
          groupTags={groupTags}
          hierarchyLevels={hierarchyLevels}
          onSubmit={handleSubmit}
          selectedGroup={selectedEntity}
          setEditorGroup={setEditorGroup}
        />
      </SideSheet>
      <AlertModal
        actionButtonProps={{
          text: 'Discard Changes',
          onClick: () => {
            setShowConfirmationModal(false);
            onClose();
          },
        }}
        cancelButtonProps={{ text: 'Keep Editing' }}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Do you want to discard your changes?"
      />
    </>
  );
};
