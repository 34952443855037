import { CellProps, TypeSortInfo } from '@trust-kaz/reactdatagrid-enterprise/types';
import cx from 'classnames';
import { FC, MouseEvent, MutableRefObject, useCallback, useMemo, useState } from 'react';

import { BaseCol, SortInfo, SortOrder } from '@explo/data';

import { Tooltip, Icon } from 'components/ds';
import { Sprinkles, sprinkles } from 'components/ds/sprinkles.css';
import * as styles from 'components/embed/EmbedDataGrid/ColumnHeader.css';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import { EmbedButton } from '../EmbedButton';
import { EmbedInfoIcon } from '../EmbedInfoIcon';

type ColumnHeaderProps = CellProps & {
  alignment: Sprinkles['justifyContent'];
  column: BaseCol;
  isBold?: boolean;
  tooltip?: string;
  hideMenu?: boolean;
  shouldTruncateText?: boolean;
  // Used for row-level actions, hides the label of the column
  hideLabel?: boolean;
  // Remove when report builder supports custom styles
  disableCustomStyles?: boolean;

  /** Sort info from React Data Grid */
  sort?: TypeSortInfo;
  onSort?: (sort: SortInfo[]) => void;
  isInitialSortDesc?: boolean;
  onFilter?: (column: BaseCol) => void;

  /** Ref for the parent container to create a boundary for Radix tooltips, dropdowns, and popovers */
  containerRef?: MutableRefObject<HTMLElement | null>;
};

export const ColumnHeader: FC<ColumnHeaderProps> = ({
  alignment,
  sort,
  column,
  disableCustomStyles,
  headerHeight,
  isBold,
  onSort,
  onFilter,
  containerRef,
  hideMenu,
  shouldTruncateText,
  tooltip,
  isInitialSortDesc,
  hideLabel,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Sort will only have at most one item corresponding to the current column
  const singleSort = Array.isArray(sort) ? sort[0] : sort;

  const columnLabel = hideLabel ? '' : column.friendly_name || column.name;

  const menuOptions = useMemo<DropdownOption[]>(() => {
    if (hideMenu) return [];
    const sortOptions: DropdownOption[] = onSort
      ? [
          {
            name: 'Sort in ascending order',
            icon: 'arrow-up-short-wide',
            onClick: () => onSort([{ column, order: SortOrder.ASC }]),
            disabled: singleSort?.dir === 1,
          },
          {
            name: 'Sort in descending order',
            icon: 'arrow-down-wide-short',
            onClick: () => onSort([{ column, order: SortOrder.DESC }]),
            disabled: singleSort?.dir === -1,
          },
        ]
      : [];

    const filterOption: DropdownOption[] = onFilter
      ? [
          {
            borderTop: true,
            name: 'Filter by column',
            icon: 'filter',
            onClick: () => onFilter?.(column),
          },
        ]
      : [];

    return [...sortOptions, ...filterOption];
  }, [column, onFilter, onSort, singleSort?.dir, hideMenu]);

  const handleToggleSort = useCallback(
    (e: MouseEvent) => {
      if (!onSort) return;
      e.stopPropagation();

      let newOrder: SortOrder | undefined;
      if (isInitialSortDesc) {
        // Unsorted --> DESC --> ASC --> Unsorted...
        newOrder =
          singleSort?.dir === 1
            ? undefined
            : singleSort?.dir === -1
              ? SortOrder.ASC
              : SortOrder.DESC;
      } else {
        // Unsorted --> ASC --> DESC --> Unsorted...
        newOrder =
          singleSort?.dir === 1
            ? SortOrder.DESC
            : singleSort?.dir === -1
              ? undefined
              : SortOrder.ASC;
      }

      onSort(newOrder ? [{ column, order: newOrder }] : []);
    },
    [column, onSort, singleSort?.dir, isInitialSortDesc],
  );

  return (
    <div
      className={cx(styles.container, {
        [containerWithMenu]: !hideMenu,
        [sprinkles({ justifyContent: alignment, paddingRight: 'sp1' })]: hideMenu,
        [embedSprinkles({ otherText: 'tableColumnHeader' })]: !disableCustomStyles,
        [sprinkles({ color: 'contentSecondary' })]: disableCustomStyles,
      })}
      onClick={handleToggleSort}
      style={{ display: 'flex', height: shouldTruncateText ? headerHeight : undefined }}>
      <div className={textStyle} style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
        <Tooltip containerRef={containerRef} side="bottom" text={columnLabel}>
          <div
            className={
              shouldTruncateText
                ? sprinkles({ truncateText: 'ellipsis' })
                : sprinkles({ whiteSpace: 'pre-line' })
            }>
            {columnLabel}
          </div>
        </Tooltip>
        {tooltip ? <EmbedInfoIcon noMargin text={tooltip} /> : null}
        {singleSort && (
          <Icon name={singleSort.dir === 1 ? 'arrow-up-short-wide' : 'arrow-down-wide-short'} />
        )}
      </div>
      {menuOptions.length > 0 ? (
        // Wrapper div to prevent dropdown clicks from triggering sorting
        <div
          className={menuOpen ? styles.menuActive : styles.menu}
          onClick={(e) => e.stopPropagation()}>
          <EmbeddedDropdownMenu
            align="start"
            menuOptions={menuOptions}
            onOpenChange={setMenuOpen}
            portalRef={containerRef}>
            <EmbedButton icon="ellipsis-vertical" variant="tertiary" />
          </EmbeddedDropdownMenu>
        </div>
      ) : null}
    </div>
  );
};

const containerWithMenu = sprinkles({
  justifyContent: 'space-between',
  // Less right padding and gap because the ellipsis icon has its own padding
  paddingRight: 'sp.25',
  gap: 'sp.25',
});

const textStyle = sprinkles({
  flexItems: 'alignCenter',
  overflow: 'hidden',
  gap: 'sp1',
});
