import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  saveDefaultLocaleCode,
  saveDefaultCurrencyCode,
  saveUseBrowserLocale,
} from 'actions/teamActions';
import { CalloutLink, Checkbox, Select, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorContactSupportToast } from 'shared/sharedToasts';
import { SUPPORTED_LOCALES, SUPPORTED_CURRENCIES } from 'utils/loadLocale';

const languageOptions = SUPPORTED_LOCALES.map((locale) => {
  return {
    label: locale.name,
    value: locale.localeCode,
  };
});

const currencyOptions = SUPPORTED_CURRENCIES.map((currency) => {
  return {
    label: currency.name,
    value: currency.currencyCode,
  };
});

export const SettingsInternationalizationSection: FC = () => {
  const dispatch = useDispatch();

  const team = useSelector((state: ReduxState) => state.teamData.data);

  const onUserBrowserLocaleClick = () => {
    if (!team) return;
    dispatch(
      saveUseBrowserLocale(
        { postData: { setting: !team.use_browser_locale } },
        undefined,
        (response) => showErrorContactSupportToast(response.error_msg),
      ),
    );
  };

  return (
    <div>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ heading: 'h2' })}>Internationalization</div>
      </div>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
        <div className={sprinkles({ color: 'contentTertiary' })}>
          This affects the formatting of numbers, currencies, and dates on your customer&apos;s
          dashboards
        </div>
        <Select
          label="Default Language"
          onChange={(locale) =>
            dispatch(
              saveDefaultLocaleCode({ postData: { locale_code: locale } }, undefined, (response) =>
                showErrorContactSupportToast(response.error_msg),
              ),
            )
          }
          placeholder="Select a Language"
          selectedValue={team?.default_locale_code}
          values={languageOptions}
        />
        <Checkbox
          isChecked={!!team?.use_browser_locale}
          label="Use Browser Language (when possible)"
          onChange={onUserBrowserLocaleClick}
        />
        <Select
          label="Default Currency"
          onChange={(currency) =>
            dispatch(
              saveDefaultCurrencyCode(
                { postData: { currency_code: currency } },
                undefined,
                (response) => showErrorContactSupportToast(response.error_msg),
              ),
            )
          }
          placeholder="Select a Currency"
          selectedValue={team?.default_currency_code}
          values={currencyOptions}
        />
        <CalloutLink
          text="Learn more about internationalization and how it affects your dashboards"
          url="https://docs.explo.co/dashboard-features/internationalization"
        />
      </div>
    </div>
  );
};
