import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ResizeObserver, { DOMRect } from 'react-resize-observer';

import { Customer, EmbedCustomer } from 'actions/teamActions';
import { EditableSectionHeader } from 'components/EditableSection/EditableSectionHeader';
import { sprinkles } from 'components/ds';
import { EmbedInfoIcon } from 'components/embed';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EditingLayout, setIsEditingEditableSection } from 'reducers/dashboardInteractionsReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { getEditableSectionConfig, getEditableSectionLayout } from 'reducers/selectors';
import {
  EventChart,
  sendEditableSectionUpdatedEventThunk,
} from 'reducers/thunks/customEventThunks';
import { handleEditableSectionClickThunk } from 'reducers/thunks/dashboardSelectionThunks';
import {
  addChartToEditableSectionThunk,
  discardEditableSectionLayoutChangesThunk,
  removeChartFromEditableSectionThunk,
  saveEditableSectionThunk,
} from 'reducers/thunks/editableSectionThunks';
import {
  AddEditableSectionChartPayload,
  INPUT_EVENT,
  RemoveEditableSectionChartPayload,
  ToggleEditableSectionEditingPayload,
} from 'types/customEventTypes';
import { DashboardElement, VIEW_MODE } from 'types/dashboardTypes';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { useCustomEvent } from 'utils/customEvent/useCustomEvent';
import * as utils from 'utils/editableSectionUtils';
import {
  editableSectionChartToEventChartTemplate,
  isChartInstanceOfTemplate,
} from 'utils/editableSectionUtils';
import { getLayoutMargin } from 'utils/layoutUtils';
import { debounce } from 'utils/standard';
import { replaceVariablesInString } from 'utils/variableUtils';

import { EditableSectionLayout } from './EditableSectionLayout';
import * as styles from './index.css';
import { ComputedView } from '@explo-tech/fido-api';
import { Dataset } from 'actions/datasetActions';

type Props = {
  customer: EmbedCustomer | Customer | undefined;
  datasets: Record<string, Dataset>;
  elements: DashboardElement[];
  globalStyleConfig: GlobalStyleConfig;
  isEditingDashboard: boolean;
  viewMode: VIEW_MODE;
  referencedGlobalDatasets: Record<string, ComputedView>;
};

export const EditableSection: FC<Props> = ({
  customer,
  elements,
  datasets,
  globalStyleConfig,
  isEditingDashboard,
  viewMode,
  referencedGlobalDatasets,
}) => {
  const dispatch = useDispatch();

  // Better to use the layout and config from store so only this part rerenders
  const layout = useSelector(getEditableSectionLayout);
  const config = useSelector(getEditableSectionConfig);

  const { editingLayout, isEditing, isEditingDisabled, variables, hideControls } = useSelector(
    (state: DashboardStates) => ({
      editingLayout: state.dashboardInteractions.editingLayout,
      isEditing: state.dashboardInteractions.isEditingEditableSection,
      isEditingDisabled: state.dashboardInteractions.interactionsInfo.disableEditingEditableSection,
      hideControls: state.dashboardInteractions.interactionsInfo.hideEditableSectionEditControls,
      variables: state.dashboardData.variables,
    }),
    shallowEqual,
  );

  const [isMouseInSection, setIsMouseInSection] = useState(false);
  const [width, setWidth] = useState<number>();

  const shouldRender = utils.shouldRenderEditableSection(layout, viewMode);
  const cols = globalStyleConfig.base.numColumns;

  // If view mode or editing mode changes turn off editing mode
  useEffect(() => {
    dispatch(setIsEditingEditableSection(false));
  }, [dispatch, viewMode, isEditingDashboard]);

  const chartsToAdd: EditableSectionChart[] = useMemo(
    () => utils.filterChartsForCustomer(config?.charts, customer),
    [config?.charts, customer],
  );

  const hasConfig = !!config;
  useEffect(() => {
    if (!hasConfig || !shouldRender) return;

    // Existing charts transformed in a user friendly format
    const charts =
      layout?.map((elem): EventChart => {
        const chart = chartsToAdd.find((c) => isChartInstanceOfTemplate(elem, c));
        if (!chart) return { id: elem.i, name: '', chartTemplateId: '', type: '' };

        const eventChartTemplate = editableSectionChartToEventChartTemplate(chart);
        return {
          id: elem.i,
          name: eventChartTemplate.name,
          chartTemplateId: eventChartTemplate.id,
          type: eventChartTemplate.type,
        };
      }) ?? [];

    // Data panels/ chart templates that charts can be added from
    const availableCharts = chartsToAdd.map(editableSectionChartToEventChartTemplate);

    dispatch(sendEditableSectionUpdatedEventThunk(charts, availableCharts));
  }, [chartsToAdd, hasConfig, dispatch, layout, shouldRender]);

  const handleAddChartEvent = useCallback(
    ({ detail: { chartTemplateId, id } }: CustomEvent<AddEditableSectionChartPayload>) => {
      const chart = chartsToAdd.find((c) => c.data_panel.id === chartTemplateId);
      if (chart) dispatch(addChartToEditableSectionThunk(chart, cols, id));
    },
    [chartsToAdd, cols, dispatch],
  );

  const handleRemoveChartEvent = useCallback(
    (event: CustomEvent<RemoveEditableSectionChartPayload>) => {
      const { chartId } = event.detail;
      dispatch(removeChartFromEditableSectionThunk(chartId));
    },
    [dispatch],
  );

  const handleToggleEditing = useCallback(
    (event: CustomEvent<ToggleEditableSectionEditingPayload>) => {
      const { canEdit } = event.detail;
      dispatch(setIsEditingEditableSection(canEdit));
    },
    [dispatch],
  );

  const handleSaveChanges = useCallback(() => {
    dispatch(saveEditableSectionThunk());
  }, [dispatch]);

  const handleDiscardChanges = useCallback(() => {
    dispatch(discardEditableSectionLayoutChangesThunk());
  }, [dispatch]);

  useCustomEvent(INPUT_EVENT.ADD_EDITABLE_SECTION_CHART, handleAddChartEvent);
  useCustomEvent(INPUT_EVENT.REMOVE_EDITABLE_SECTION_CHART, handleRemoveChartEvent);
  useCustomEvent(INPUT_EVENT.TOGGLE_EDITABLE_SECTION, handleToggleEditing);
  useCustomEvent(INPUT_EVENT.SAVE_EDITABLE_SECTION_CHANGES, handleSaveChanges);
  useCustomEvent(INPUT_EVENT.DISCARD_EDITABLE_SECTION_CHANGES, handleDiscardChanges);

  const handleResize = useMemo(() => debounce((rect: DOMRect) => setWidth(rect.width), 300), []);

  // Config should always be defined up to this point but checking for TS
  if (!config || !shouldRender) return null;

  const margin = getLayoutMargin(viewMode, globalStyleConfig);
  const isEditingAllowed = utils.canEditEditableSection(viewMode) && !isEditingDisabled;

  const renderEmptySection = () => {
    return (
      <div style={{ padding: margin }}>
        <div
          className={styles.emptySection}
          style={{ height: utils.getEmptySectionHeight(margin) }}>
          <div className={embedSprinkles({ body: 'secondary' })}>
            {isEditingDashboard
              ? 'Add a chart to set the default layout for your customers'
              : 'Add a chart to customize this section of your dashboard.'}
          </div>
        </div>
      </div>
    );
  };

  // This section is used to highlight when editable section is being edited. Needed to
  // be like this because hover states are funky with react grid layout
  const renderBorderSection = () => {
    const isEditingLayout = editingLayout === EditingLayout.EDITABLE_SECTION;
    // Dividing by two to move the border away from the edge of the dashboard.
    // The two pixels is so that data panel outlines don't touch the border.
    // Came to this solution with Carly.
    const borderMargin = margin / 2 - 2;

    return (
      <div
        className={cx(styles.borderContainer, {
          [styles.selectedBorderContainer]: isEditingLayout,
          [styles.hoverBorderContainer]: isMouseInSection && !isEditingLayout,
        })}
        style={{
          // Needed the opposite for the top margin so this is the reverse of borderMargin
          marginTop: -(margin / 2 + 2),
          marginLeft: borderMargin,
          marginRight: borderMargin,
          marginBottom: borderMargin,
        }}
      />
    );
  };

  const settings = config.settings;

  // Used for border section above
  const onMouseEnter = isEditingDashboard ? () => setIsMouseInSection(true) : undefined;
  const onMouseLeave = isEditingDashboard ? () => setIsMouseInSection(false) : undefined;

  return (
    <div
      className={cx(
        embedSprinkles({ backgroundColor: 'background' }),
        sprinkles({ position: 'relative' }),
      )}
      onClick={(e) => {
        dispatch(handleEditableSectionClickThunk());
        e.stopPropagation();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {/* Used to show selection state of section */}
      {isEditingDashboard ? renderBorderSection() : null}
      <div className={styles.headerContainer} style={{ margin, marginBottom: 0 }}>
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <div className={embedSprinkles({ heading: 'h1' })}>
            {replaceVariablesInString(settings.title, variables)}
          </div>
          {settings.tooltipText ? <EmbedInfoIcon text={settings.tooltipText} /> : null}
        </div>
        {isEditingAllowed && !hideControls ? (
          <EditableSectionHeader
            chartsToAdd={chartsToAdd}
            cols={cols}
            isEditing={isEditing}
            isEditingDashboard={isEditingDashboard}
            layout={layout}
            variables={variables}
          />
        ) : null}
      </div>
      {layout?.length ? (
        <EditableSectionLayout
          cols={cols}
          config={config}
          datasets={datasets}
          elements={elements}
          isEditing={isEditing}
          isEditingDashboard={isEditingDashboard}
          isViewOnly={!isEditingAllowed}
          layout={layout}
          margin={margin}
          referencedGlobalDatasets={referencedGlobalDatasets}
          variables={variables}
          viewMode={viewMode}
          width={width}
        />
      ) : (
        renderEmptySection()
      )}
      <ResizeObserver onResize={handleResize} />
    </div>
  );
};
