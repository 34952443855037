import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { saveShareMetadata, SaveShareMetadataBody } from 'actions/teamActions';
import { Input, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

export const SettingsShareLinkSection: FC = () => {
  const dispatch = useDispatch();

  const { faviconUrl, dashboardLogoUrl, externalEmail, siteTitle, portalLoginText } = useSelector(
    (state: ReduxState) => ({
      faviconUrl: state.teamData.data?.favicon_url,
      dashboardLogoUrl: state.teamData.data?.dashboard_logo_url,
      externalEmail: state.currentUser.team?.configuration.email_from_email,
      siteTitle: state.teamData.data?.portal_site_title,
      portalLoginText: state.teamData.data?.portal_login_text,
    }),
    shallowEqual,
  );
  const [faviconUrlInternal, setFaviconUrlInternal] = useState(faviconUrl || '');
  const [dashboardLogoUrlInternal, setDashboardLogoUrlInternal] = useState(dashboardLogoUrl || '');
  const [siteTitleInternal, setSiteTitleInternal] = useState(siteTitle || '');
  const [externalEmailInternal, setExternalEmailInternal] = useState(externalEmail || '');
  const [portalLoginTextInternal, setPortalLoginTextInternal] = useState(portalLoginText || '');

  useEffect(() => {
    // Fixes race condition where these haven't loaded yet but we set them
    if (faviconUrl) setFaviconUrlInternal(faviconUrl);
    if (dashboardLogoUrl) setDashboardLogoUrlInternal(dashboardLogoUrl);
    if (siteTitle) setSiteTitleInternal(siteTitle);
    if (externalEmail) setExternalEmailInternal(externalEmail);
    if (portalLoginText) setPortalLoginTextInternal(portalLoginText);
  }, [faviconUrl, dashboardLogoUrl, externalEmail, siteTitle, portalLoginText]);

  const validateInput = (value: string | undefined) => validator.isURL(value ?? '') || value === '';

  const saveMetadata = (postData: SaveShareMetadataBody) => {
    dispatch(
      saveShareMetadata({ postData }, undefined, (response) =>
        showErrorContactSupportToast(response.detail),
      ),
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'alignCenter' })}>
      <div className={sprinkles({ width: 'fill' })}>
        <div className={sprinkles({ heading: 'h1', marginBottom: 'sp1' })}>
          Portals & Share Links
        </div>
        <div className={sprinkles({ body: 'b2', marginBottom: 'sp4' })}>
          Customize the settings of your portal and share links.
        </div>

        <div className={sprinkles({ display: 'flex', flexDirection: 'column', gap: 'sp2' })}>
          <Input
            showInputButton
            defaultValue={faviconUrlInternal}
            errorText={validateInput(faviconUrlInternal) ? undefined : 'Invalid URL'}
            label={{
              text: 'Page Favicon URL',
              infoText:
                "This should be your site's domain. We'll automatically grab the favicon from there and show it on your share link pages!",
            }}
            onSubmit={(newValue) => {
              setFaviconUrlInternal(newValue);

              if (validateInput(newValue) && newValue !== faviconUrlInternal) {
                saveMetadata({ favicon_url: newValue });
              }
            }}
            placeholder="www.yourdomain.com"
          />
          <Input
            showInputButton
            defaultValue={dashboardLogoUrlInternal}
            errorText={validateInput(dashboardLogoUrlInternal) ? undefined : 'Invalid URL'}
            label={{
              text: 'Portal Logo',
              infoText:
                "This will show up in the top left of your dashboards accessed from an Customer Portal and on your portal's login screen.",
            }}
            onSubmit={(newValue) => {
              setDashboardLogoUrlInternal(newValue);

              if (validateInput(newValue) && newValue !== dashboardLogoUrlInternal) {
                saveMetadata({ dashboard_logo_url: newValue });
              }
            }}
            placeholder="www.yourdomain.com"
          />
          <Input
            showInputButton
            defaultValue={siteTitleInternal}
            label={{
              text: 'Portal Site Title',
              infoText:
                "This will be the site title of your Customer Portal. Note that this is overridden if you've set a site title for your portal's dashboard",
            }}
            onSubmit={(newValue) => {
              setSiteTitleInternal(newValue);
              if (newValue !== siteTitleInternal) saveMetadata({ portal_site_title: newValue });
            }}
            placeholder="Your Dashboard Portal"
          />
          <Input
            showInputButton
            defaultValue={portalLoginTextInternal}
            label={{
              text: 'Portal Login Text',
              infoText:
                "This will be the login text of your Customer Portal. The default if this section is left blank is 'Contact your administrator for login information.'",
            }}
            onSubmit={(newValue) => {
              setPortalLoginTextInternal(newValue);

              if (newValue !== portalLoginTextInternal) {
                saveMetadata({ portal_login_text: newValue });
              }
            }}
            placeholder="Contact your administrator for login information"
          />
          <Input
            disabled
            showInputButton
            defaultValue={externalEmailInternal}
            errorText={
              validator.isEmail(externalEmailInternal ?? '') || externalEmailInternal === ''
                ? undefined
                : 'Invalid Email'
            }
            label={{
              text: 'External Email',
              infoText:
                'This will be the email that sends portal authentication links to your customers. Reach out to support to set this up.',
            }}
            // intentional no-op because this is just a placeholder to show it _can_ be updated
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            placeholder="your_email@domain.com"
          />
        </div>
      </div>
    </div>
  );
};
