import { FC, useState } from 'react';

import { Input, sprinkles } from 'components/ds';
import { DashboardVariableMap } from 'types/dashboardTypes';

import { CustomerVariablesSection } from './CustomerVariablesSection';
import { QueryParameterVariablesSection } from './QueryParameterVariablesSection';

type Props = {
  variables: DashboardVariableMap;
};

export const GlobalDatasetsVariablesPanel: FC<Props> = ({ variables }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const loweredSearch = searchQuery.toLowerCase();

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <Input
        className={sprinkles({ padding: 'sp2' })}
        leftIcon="search"
        onChange={setSearchQuery}
        placeholder="Search..."
        value={searchQuery}
      />
      <CustomerVariablesSection searchQuery={loweredSearch} />
      <QueryParameterVariablesSection searchQuery={loweredSearch} variables={variables} />
    </div>
  );
};
