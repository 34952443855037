import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Icon, sprinkles } from 'components/ds';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { DashboardSelectionControlDatum } from 'pages/dashboardPage/LayersPanel/types';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';

interface Props {
  itemData: DashboardSelectionControlDatum;
  currentDashboardId: number;
  dashboardIdToSourceDataPanelIdMap: Record<number, string>;
}

const ROOT_HIERARCHY_LEVEL = 0;

// TODO(zifanxiang): Look into seeing how to type this more generally to all the values specified by
// the spacing object in sprinkles.
const ITEM_INDENT_MAP: Record<number, 'sp0' | 'sp1' | 'sp2'> = {
  0: 'sp0',
  1: 'sp1',
  2: 'sp2',
};

/** A control for selecting a particular dashboard within a dashboard hierarchy. */
export const DashboardSelectionControl: FC<Props> = ({
  itemData,
  currentDashboardId,
  dashboardIdToSourceDataPanelIdMap,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const iconName =
    itemData.hierarchyLevel === ROOT_HIERARCHY_LEVEL ? 'folder' : 'arrow-turn-down-right';
  const itemIndent = ITEM_INDENT_MAP[itemData.hierarchyLevel];
  const isSelectedDashboardControl = currentDashboardId === itemData.dashboardId;
  return (
    <button
      className={selectionControlClass}
      onClick={() => {
        if (!isSelectedDashboardControl) {
          history.push(ROUTE_PROVIDERS.DASHBOARD_EDIT_MODE(`${itemData.dashboardId}`));
        }
      }}
      onMouseEnter={() =>
        dispatch(
          setHighlightedDataPanelIds(
            new Set([dashboardIdToSourceDataPanelIdMap[itemData.dashboardId]]),
          ),
        )
      }
      onMouseLeave={() => dispatch(setHighlightedDataPanelIds(new Set()))}>
      <div className={sprinkles({ flexItems: 'alignCenter', marginLeft: itemIndent })}>
        <Icon name={iconName} />
        <div className={sprinkles({ marginX: 'sp1' })}>{itemData.name}</div>
      </div>
      {isSelectedDashboardControl ? (
        <Icon className={sprinkles({ color: 'active' })} name="check" />
      ) : null}
    </button>
  );
};

const selectionControlClass = sprinkles({
  backgroundColor: { hover: 'elevationLow' },
  borderRadius: 4,
  flexItems: 'alignCenterBetween',
  height: 32,
  overflowX: 'hidden',
  paddingX: 'sp1',
  paddingY: 'sp2.5',
  marginY: 'sp1',
  width: 'fill',
});
