import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { BaseCol, CustomerReportGroupBy, OPERATION_TYPES } from '@explo/data';

import { CustomerReportAgg, HeatMapView } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import {
  cloneRowsIfDate,
  customerReportToHeatMapInstructions,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import { HeatMap } from 'pages/dashboardPage/charts/heatMap';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { getCurrentTheme } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';

type Props = {
  groupBys?: CustomerReportGroupBy[];
  columnGroupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  reportData: ReportData;
  schema: BaseCol[];
  columnConfigs?: ReportBuilderColConfigs;
  view: HeatMapView;

  onSelect?: (chartInfo: ChartMenuInfo | null) => void;
};

export const ReportHeatMap: FC<Props> = ({
  aggregations,
  columnGroupBys,
  reportData: { isLoading, rows },
  groupBys,
  schema,
  columnConfigs,
  view,
  onSelect,
}) => {
  const { variables, globalStyleConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () =>
      customerReportToHeatMapInstructions(
        aggregations,
        groupBys,
        columnGroupBys,
        columnConfigs,
        view.heatMap,
      ),
    [aggregations, groupBys, columnGroupBys, columnConfigs, view.heatMap],
  );

  // HeatMap mutates previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const previewData = useMemo(
    () => cloneRowsIfDate([...(groupBys ?? []), ...(columnGroupBys ?? [])], rows),
    [groupBys, columnGroupBys, rows],
  );

  const missingText = useMemo(
    () => [
      ...(!aggregations?.length ? ['Values'] : []),
      ...(!groupBys?.length ? ['X-Axis Grouping'] : []),
      ...(!columnGroupBys?.length ? ['Y-Axis Grouping'] : []),
    ],
    [aggregations?.length, columnGroupBys?.length, groupBys?.length],
  );

  if (missingText.length) {
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2}>
        Missing {missingText.join(' and ')}
      </InvalidData>
    );
  }

  return (
    <HeatMap
      backgroundColor=""
      dataPanelTemplateId=""
      datasetData={{}}
      datasetNamesToId={{}}
      drilldownEntryPoints={{}}
      generalOptions={{ enableRawDataDrilldown: !!onSelect }}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      loading={isLoading}
      previewData={previewData}
      schema={schema}
      setChartMenu={onSelect || noOp}
      variables={variables}
    />
  );
};

const noOp = () => null;
