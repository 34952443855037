import { COMPUTED_VIEW_TYPE, FOLDER_TYPE } from './constants';

const getMessageBase = (userName: string, teamName: string) => {
  return `"${userName}" (on team "${teamName}")`;
};

export const getResourceCreatedMessage = (
  userName: string,
  teamName: string,
  resourceType: typeof COMPUTED_VIEW_TYPE | typeof FOLDER_TYPE,
  resourceName: string,
) => {
  const resourceTypeCanonicalName =
    resourceType === COMPUTED_VIEW_TYPE
      ? DATASET_RESOURCE_CANONICAL_NAME
      : FOLDER_RESOURCE_CANONICAL_NAME;
  return `${getMessageBase(userName, teamName)} \`created\` a ${resourceTypeCanonicalName} named "${resourceName}"`;
};

export const getResourceDeletedMessage = (
  userName: string,
  teamName: string,
  resourceType: typeof COMPUTED_VIEW_TYPE | typeof FOLDER_TYPE,
  resourceName: string,
) => {
  const resourceTypeCanonicalName =
    resourceType === COMPUTED_VIEW_TYPE
      ? DATASET_RESOURCE_CANONICAL_NAME
      : FOLDER_RESOURCE_CANONICAL_NAME;
  return `${getMessageBase(userName, teamName)} \`deleted\` a ${resourceTypeCanonicalName} named "${resourceName}"`;
};

export const getCommitCreatedMessage = (userName: string, teamName: string) => {
  return `${getMessageBase(userName, teamName)} \`committed\``;
};

export const getResourceRenamedMessage = (
  userName: string,
  teamName: string,
  resourceType: typeof COMPUTED_VIEW_TYPE | typeof FOLDER_TYPE,
  oldResourceName: string,
  newResourceName: string,
) => {
  const resourceTypeCanonicalName =
    resourceType === COMPUTED_VIEW_TYPE
      ? DATASET_RESOURCE_CANONICAL_NAME
      : FOLDER_RESOURCE_CANONICAL_NAME;
  return `${getMessageBase(userName, teamName)} \`renamed\` a ${resourceTypeCanonicalName} named "${oldResourceName}" to "${newResourceName}"`;
};

export const getGlobalDatasetImportedMessage = (
  userName: string,
  teamName: string,
  datasetName: string,
) => {
  return `${getMessageBase(userName, teamName)} \`imported\` a dataset named "${datasetName}"`;
};

const DATASET_RESOURCE_CANONICAL_NAME = 'dataset';

const FOLDER_RESOURCE_CANONICAL_NAME = 'folder';
