import * as RadixLabel from '@radix-ui/react-label';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { Icon, InfoIcon, sprinkles, Tooltip } from 'components/ds';

import * as styles from './index.css';

export type Props = {
  children: string;
  className?: string;
  htmlFor: string;
  infoText?: ReactNode;

  // This is true when the input this label is attached to accepts variable entry
  forVariableInput?: boolean;
  variant?: 'stacked' | 'inline';
};

export const Label: FC<Props> = ({
  className,
  htmlFor,
  infoText,
  children,
  forVariableInput,
  variant = 'stacked',
}) => {
  return (
    <RadixLabel.Root className={cx(styles.labelBase({ variant }), className)} htmlFor={htmlFor}>
      {children}
      {infoText ? <InfoIcon text={infoText} /> : null}
      {forVariableInput ? (
        <Tooltip side="right" text="This field can take in variables">
          <Icon className={sprinkles({ color: 'gray8' })} name="code" size="sm" />
        </Tooltip>
      ) : null}
    </RadixLabel.Root>
  );
};
