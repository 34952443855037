import { BigQuery, Snowflake, Tunnel } from '@explo-tech/fido-api';
import { createSelector } from '@reduxjs/toolkit';

import {
  DATABASES,
  DATA_SOURCE_AUTH,
  FIDO_TYPES,
  SSH_AUTH_TYPE,
  SUPPORTED_FIDO_DATA_SOURCES,
  TUNNEL,
} from 'pages/ConnectDataSourceFlow/constants';
import {
  PartialDataSourceConfig,
  PartialAuthConfig,
  PartialSSHConfig,
  PartialSSHAuthConfig,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';

import { JdbcConfig, FidoDataSourceConfig } from './types';

export const dataSourceSupportsSSH = (type: DATABASES) => {
  switch (type) {
    case DATABASES.AZURE:
    case DATABASES.POSTGRES:
    case DATABASES.SQLSERVER:
    case DATABASES.MYSQL:
    case DATABASES.REDSHIFT:
    case DATABASES.CLICKHOUSE:
      return true;
    case DATABASES.BIGQUERY:
    case DATABASES.SNOWFLAKE:
      return false;
    default:
      return false;
  }
};

const JdbcDatabaseToFidoTypeMapping: Record<
  Extract<
    DATABASES,
    | DATABASES.POSTGRES
    | DATABASES.REDSHIFT
    | DATABASES.SQLSERVER
    | DATABASES.AZURE
    | DATABASES.MYSQL
    | DATABASES.CLICKHOUSE
  >,
  // TODO is there a more typescripty way to do this off of the @type values in fido?
  'postgres' | 'redshift' | 'mss' | 'mysql' | 'clickhouse'
> = {
  [DATABASES.POSTGRES]: FIDO_TYPES.POSTGRES,
  [DATABASES.REDSHIFT]: FIDO_TYPES.REDSHIFT,
  [DATABASES.SQLSERVER]: FIDO_TYPES.SQLSERVER,
  [DATABASES.AZURE]: FIDO_TYPES.SQLSERVER,
  [DATABASES.MYSQL]: FIDO_TYPES.MYSQL,
  [DATABASES.CLICKHOUSE]: FIDO_TYPES.CLICKHOUSE,
};

/**
 * Used in the case we are connecting a data source and are past selecting a type
 * or we are updating a data source. Note isUpdatingDataSource in this reducer is only set
 * to true is the data source we are updating has a fido_id.
 */
export const shouldUseFidoForConnectingOrUpdating = createSelector(
  (state: ReduxState) => state.currentUser.team?.feature_flags.use_fido,
  (state: ReduxState) => state.fidoDataSourceConfig.dataSourceConfig.type,
  (state: ReduxState) => state.fidoDataSourceConfig.isUpdating,
  (useFido, type, isUpdatingDataSource) =>
    useFido && type && SUPPORTED_FIDO_DATA_SOURCES.includes(type) && (isUpdatingDataSource ?? true),
);

export const buildDatabaseConfigForFido = (
  {
    config: dataSourceConfig,
    type: dataSourceType,
    auth: authConfig,
  }: { config: PartialDataSourceConfig; type?: DATABASES; auth: PartialAuthConfig },
  requireEncryptedFields: boolean,
):
  | Omit<BigQuery, 'tunnel'>
  | Omit<JdbcConfig, 'tunnel'>
  | Omit<Snowflake, 'tunnel'>
  | undefined => {
  switch (dataSourceType) {
    case DATABASES.POSTGRES:
    case DATABASES.REDSHIFT:
    case DATABASES.MYSQL:
    case DATABASES.SQLSERVER:
    case DATABASES.AZURE:
    case DATABASES.CLICKHOUSE: {
      const { database, host, port } = dataSourceConfig;

      if (!database || !host || !port) {
        return undefined;
      }

      const { username, password } = authConfig;
      if (!username || (requireEncryptedFields && !password)) {
        return undefined;
      }

      return {
        '@type': JdbcDatabaseToFidoTypeMapping[dataSourceType],
        host,
        port,
        database,
        authentication: {
          // TODO support iam auth
          '@type': DATA_SOURCE_AUTH.USERNAME_PASSWORD,
          username,
          ...(password != null ? { password } : undefined),
        },
      };
    }
    case DATABASES.BIGQUERY: {
      const { jsonKeyFile, projectId } = authConfig;

      if (requireEncryptedFields && !jsonKeyFile) {
        return undefined;
      }

      return {
        '@type': 'bigquery',
        authentication: {
          ...(projectId != null ? { projectId } : undefined),
          ...(jsonKeyFile != null ? { jsonKeyFile } : undefined),
        },
      };
    }
    case DATABASES.SNOWFLAKE: {
      const { user, account, schema, database } = dataSourceConfig;

      if (!user || !account) {
        return undefined;
      }

      const { password } = authConfig;
      if (requireEncryptedFields && !password) {
        return undefined;
      }

      return {
        '@type': 'snowflake',
        user,
        account,
        schema: schema ?? null,
        database: database ?? null,
        authentication: {
          '@type': DATA_SOURCE_AUTH.USERNAME_PASSWORD,
          ...(password != null ? { password } : undefined),
        },
      };
    }
    default:
      return;
  }
};

export const buildSSHTunnelConfigForFido = (
  sshConfig: {
    config: PartialSSHConfig;
    auth: PartialSSHAuthConfig;
    useSsh: boolean;
  },
  requireEncryptedFields: boolean,
): Tunnel | undefined => {
  if (!sshConfig.useSsh) return { '@type': TUNNEL.PUBLIC_INTERNET };

  const { host, port } = sshConfig.config;
  const { username, privateKey } = sshConfig.auth;

  const requirePrivateKey =
    sshConfig.auth['@type'] === SSH_AUTH_TYPE.TENANT && requireEncryptedFields;

  if (!host || !port || !username || (requirePrivateKey && !privateKey)) return;

  return {
    '@type': TUNNEL.SSH,
    tunnel: { '@type': TUNNEL.PUBLIC_INTERNET },
    host,
    port,
    authentication: requirePrivateKey
      ? { username, privateKey, '@type': sshConfig.auth['@type'] }
      : { username, '@type': sshConfig.auth['@type'] },
  };
};

export const buildFinalDataSourceConfigForFido = (
  dataSourceConfig: { config: PartialDataSourceConfig; type?: DATABASES; auth: PartialAuthConfig },
  sshConfig: {
    config: PartialSSHConfig;
    auth: PartialSSHAuthConfig;
    useSsh: boolean;
  },
  requireEncryptedFields: boolean,
): FidoDataSourceConfig | undefined => {
  if (!dataSourceConfig.type) return;

  const databaseConfig = buildDatabaseConfigForFido(dataSourceConfig, requireEncryptedFields);
  const tunnelConfig = buildSSHTunnelConfigForFido(sshConfig, requireEncryptedFields);

  if (!databaseConfig || !tunnelConfig) return;

  return {
    ...databaseConfig,
    tunnel: tunnelConfig,
  };
};
