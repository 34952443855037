import { BOOLEAN, DATE, FLOAT, INTEGER_DATA_TYPE, STRING, TIMESTAMP } from '@explo/data';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';

export const TABLE_COLUMN_CLICKED_VARIABLE_SUFFIX = 'column_clicked';

export const dataTypeOptions = [STRING, INTEGER_DATA_TYPE, FLOAT, BOOLEAN, TIMESTAMP, DATE].map(
  (dataType) => ({
    value: dataType,
    label: SCHEMA_DATA_TYPES_BY_ID[dataType].name,
  }),
);
