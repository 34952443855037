import tinycolor from 'tinycolor2';

import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles/constants';
import {
  GlobalStyleActionColorConfig,
  GlobalStyleComponentTextConfig,
  GlobalStyleModalConfig,
  GlobalStyleTextConfig,
} from 'globalStyles/types';
import { getFontFamilyName, getWhiteOrBlack } from 'globalStyles/utils';
import { mixColors } from 'utils/general';
import { HEX_COLORS } from '@explo/design-system';

export const getComponentTextConfig = (
  defaultText: { fontSize: string; fontFamily: string; fontWeight: string; color: string },
  text?: GlobalStyleComponentTextConfig,
) => {
  if (!text) return defaultText;

  return {
    fontFamily: defaultText.fontFamily,
    fontSize: text.size ? `${text.size}px` : defaultText.fontSize,
    fontWeight: text.weight || defaultText.fontWeight,
    color: text.color || defaultText.color,
  };
};

export const getFontStyles = (
  text: GlobalStyleTextConfig,
  type: keyof GlobalStyleTextConfig['overrides'],
  secondary?: boolean,
) => {
  const font = text.overrides[type]?.font || text[secondary ? 'secondaryFont' : 'primaryFont'];
  const fontSize = text.overrides[type]?.size || text.textSize + TEXT_SIZE_OFFSET_MAP[type];
  const fontWeight =
    text.overrides[type]?.weight || text[secondary ? 'secondaryWeight' : 'primaryWeight'];

  return {
    fontFamily: font ? getFontFamilyName(font) : 'inherit',
    fontSize: `${fontSize}px`,
    fontWeight: fontWeight ? `${fontWeight}` : '',
    color: text.overrides[type]?.color || text[secondary ? 'secondaryColor' : 'primaryColor'],
  };
};

// Using Design System rules for interaction states
export const handleInteractionStep = (color: string, step: 1 | 2) => {
  // H is 0-360 and SV values are 0-1 from tinyColor
  // We use 0-360 for hue and 0-100 for saturation and value
  const hsv = tinycolor(color).toHsv();
  const hue = hsv.h;
  const brightness = hsv.v * 100;

  let brightnessStep = 10;
  if (brightness > 20) {
    if (hue >= 30 && hue <= 200) {
      brightnessStep = -5;
    } else if (hue <= 30 || hue >= 200) {
      brightnessStep = -10;
    }
  }

  const newBrightness = Math.max(0, brightness + brightnessStep * step);
  if (newBrightness <= 0) hsv.h = 0;
  else hsv.v = newBrightness / 100;

  return tinycolor(hsv).toHexString();
};

export const getGlobalStyleButtonColors = (
  action: GlobalStyleActionColorConfig,
  text: GlobalStyleTextConfig,
) => {
  const primaryButtonColor = action.buttonColor ?? action.default;
  const primaryButtonTextColor = action.buttonTextColor || getWhiteOrBlack(primaryButtonColor);

  const secondaryButtonColor =
    action.secondaryButtonColor ?? mixColors(primaryButtonColor, 'white', 0.85).rgb().string();
  const secondaryButtonTextColor =
    action.secondaryButtonTextColor || getWhiteOrBlack(secondaryButtonColor);

  const tertiaryButtonTextColor = action.tertiaryButtonTextColor || text.secondaryColor;

  return {
    primaryButtonColor,
    primaryButtonTextColor,
    secondaryButtonColor,
    secondaryButtonTextColor,
    tertiaryButtonTextColor,
  };
};

export const getGlobalStyleModalInfo = (modalConfig: GlobalStyleModalConfig | undefined) => {
  // Need the hex value instead of the CSS variable from vars becuase it's displayed in ColorPicker
  const overlayBackgroundColor = modalConfig?.overlayBackgroundColor ?? HEX_COLORS.gray12;
  const overlayOpacity = modalConfig?.overlayOpacity ?? '0.75';

  return { overlayBackgroundColor, overlayOpacity };
};
