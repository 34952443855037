import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { BOOLEAN, STRING, TIMESTAMP } from '@explo/data';

import { InfoCard } from 'components/InfoCard';
import { Input, Intent, Modal, Select, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { updateDashboardParamThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { isDefaultValueValid, isNameValid } from 'utils/paramUtils';
import { isEqual } from 'utils/standard';
import { dataTypeOptions } from './constants';

type Props = {
  param: DashboardParam | null;

  createNewParam: () => DashboardParam;
  onClose: () => void;
};

export const ManageCustomVariableModal: FC<Props> = ({ param, createNewParam, onClose }) => {
  const dispatch = useDispatch();

  const archetypeProperties = useSelector((state: ReduxState) => getArchetypeProperties(state));

  const [newParam, setNewParam] = useImmer<DashboardParam>(param ?? createNewParam());

  const title = param ? `Edit ${param.name} variable` : 'Create a new embed variable';
  const isNewNameValid = isNameValid(newParam.name) && !archetypeProperties?.has(newParam.name);
  const isNewDefaultValueValid = isDefaultValueValid(newParam.type, newParam.defaultValue);

  const renderDefaultValueSelector = () => {
    const type = newParam.type;
    if (type === TIMESTAMP) {
      return <Input disabled label="Default value" onChange={() => null} value="" />;
    }
    if (type === BOOLEAN) {
      return (
        <Select
          label="Default value"
          onChange={(value) => setNewParam((draft) => void (draft.defaultValue = value))}
          selectedValue={newParam.defaultValue}
          values={[{ value: 'true' }, { value: 'false' }]}
        />
      );
    }
    return (
      <Input
        defaultValue={newParam.defaultValue}
        intent={isNewDefaultValueValid ? undefined : Intent.ERROR}
        label="Default value"
        onSubmit={(value) => setNewParam((draft) => void (draft.defaultValue = value))}
        type={type === STRING ? 'text' : 'number'}
      />
    );
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      primaryButtonProps={{
        disabled: !isNewNameValid || !isNewDefaultValueValid,
        text: param ? 'Update' : 'Create',
        onClick: () => {
          if (!isEqual(param, newParam)) dispatch(updateDashboardParamThunk(param, newParam));
          onClose();
        },
      }}
      size="small"
      tertiaryButtonProps={{ text: 'Cancel', onClick: onClose }}
      title={title}>
      <div className={sprinkles({ paddingX: 'sp2', flexItems: 'column', gap: 'sp2' })}>
        <InfoCard
          noTopMargin
          className={sprinkles({ width: 'fill' })}
          text="Default value here will be the default for both when editing and embedding this dashboard"
        />
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
          <Select
            label="Data Type"
            onChange={(newType) =>
              setNewParam((draft) => {
                draft.type = newType;
                draft.defaultValue = undefined;
              })
            }
            selectedValue={newParam.type}
            values={dataTypeOptions}
          />
          <Input
            autoFocus={!param}
            defaultValue={newParam.name}
            intent={isNewNameValid ? undefined : Intent.ERROR}
            label="Variable name"
            onSubmit={(value) =>
              setNewParam((draft) => void (draft.name = cleanVariableName(value)))
            }
            placeholder="variable_name"
          />
          {renderDefaultValueSelector()}
        </div>
      </div>
    </Modal>
  );
};

const cleanVariableName = (name: string) => {
  name = name.trim();
  name = name.replace(/\s/g, '_');

  if (name.length > 0 && !isNaN(+name[0])) name = '_' + name;

  return name;
};
