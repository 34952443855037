import produce from 'immer';
import { DateTime } from 'luxon';
import { FC, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Timezones } from '@explo/data';

import { DatePresetForm } from 'components/ChartConfigs/DateConfig/DatePresetForm';
import { getDefaultOperations } from 'components/ChartConfigs/DateConfig/DefaultOperations';
import { Button, IconButton, Input, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { DateOperation, DateRangePresetConfig } from 'types/dashboardTypes';
import { getCustomDateRange } from 'utils/dateRangeUtils';

export const DatePresetEditor: FC<{
  value?: DateRangePresetConfig;
  onUpdate?: (value: DateRangePresetConfig) => void;
  onAdd?: (value: DateRangePresetConfig) => void;
  onDelete?: () => void;
  onCancel?: () => void;
}> = ({ value, onAdd, onUpdate, onDelete, onCancel }) => {
  const [name, setName] = useState(value?.name || '');
  const [startOperations, setStartOperations] = useState(
    value?.startDate.operations || getDefaultOperations(),
  );
  const [endOperations, setEndOperations] = useState(
    value?.endDate.operations || getDefaultOperations(),
  );
  const [error, setError] = useState('');

  const timezone = useSelector(
    (state: ReduxState) =>
      ('dashboardLayout' in state && state.dashboardLayout.requestInfo.timezone) || Timezones.UTC,
  );

  const currentRange = useMemo(
    () => getCustomDateRange(startOperations, endOperations, timezone),
    [startOperations, endOperations, timezone],
  );

  const validate = useCallback(() => {
    let error = '';
    if (!name.trim()) error = 'Name is required';
    else {
      if (currentRange.endDate <= currentRange.startDate)
        error = 'End date must be after start date';
    }

    setError(error);
    return !error;
  }, [name, currentRange]);

  const updateStartOperation = useCallback((index: number, operation: DateOperation) => {
    setError('');
    setStartOperations((prev) =>
      produce(prev, (draft) => {
        draft[index] = operation;
      }),
    );
  }, []);

  const updateEndOperation = useCallback((index: number, operation: DateOperation) => {
    setError('');
    setEndOperations((prev) =>
      produce(prev, (draft) => {
        draft[index] = operation;
      }),
    );
  }, []);

  const handleAdd = useCallback(() => {
    if (!validate()) return;
    onAdd?.({
      name,
      startDate: { operations: startOperations },
      endDate: { operations: endOperations },
    });
    setName('');
    setStartOperations([]);
    setEndOperations([]);
  }, [validate, onAdd, name, startOperations, endOperations]);

  const handleUpdate = useCallback(
    () =>
      validate() &&
      onUpdate?.({
        name,
        startDate: { operations: startOperations },
        endDate: { operations: endOperations },
      }),
    [validate, onUpdate, name, startOperations, endOperations],
  );

  return (
    <div className={containerClassName}>
      <div className={sectionClassName}>
        <span className={headingClassName}>{onAdd ? 'Create New' : 'Edit'} Preset</span>
      </div>
      <div className={sectionClassName}>
        <span className={subtitleClassName}>Name</span>
        <Input onChange={setName} placeholder="Name" type="text" value={name} />
      </div>
      <div className={sectionClassName}>
        <span className={subtitleClassName}>Start Date</span>
        <DatePresetForm operations={startOperations} updateOperation={updateStartOperation} />
      </div>
      <div className={sectionClassName}>
        <span className={subtitleClassName}>End Date</span>
        <DatePresetForm operations={endOperations} updateOperation={updateEndOperation} />
      </div>
      <div className={sectionClassName}>
        <span className={subtitleClassName}>Preview</span>
        {currentRange.startDate.toLocaleString(DateTime.DATETIME_SHORT)} -{' '}
        {currentRange.endDate.toLocaleString(DateTime.DATETIME_SHORT)}
      </div>
      <div className={sectionClassName}>
        <div className={actionRowClassName}>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', flex: 1 })}>
            {onAdd ? (
              <Button icon="plus" onClick={handleAdd} variant="primary">
                Add
              </Button>
            ) : onUpdate ? (
              <Button onClick={handleUpdate} variant="primary">
                Save
              </Button>
            ) : null}
            {onDelete ? <IconButton name="trash" onClick={onDelete} variant="destructive" /> : null}
          </div>
          {onCancel ? (
            <Button onClick={onCancel} variant="secondary">
              Cancel
            </Button>
          ) : null}
        </div>
        {error ? <span className={sprinkles({ body: 'b2', color: 'error' })}>{error}</span> : null}
      </div>
    </div>
  );
};

const subtitleClassName = sprinkles({ heading: 'subtitle' });

const headingClassName = sprinkles({ heading: 'h3' });

const actionRowClassName = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
});

const sectionClassName = sprinkles({
  flexItems: 'column',
  gap: 'sp1',
});

const containerClassName = sprinkles({
  flexItems: 'column',
  gap: 'sp2',
  flexWrap: 'wrap',
  padding: 'sp2',
  border: 1,
  borderColor: 'outline',
  borderRadius: 4,
});
