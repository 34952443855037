import { DatasetSchema } from '@explo/data';

import { UserTransformedSchema } from 'constants/types';
import { CustomerTableState } from 'types/customerDashboardStateTypes';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import { keyBy, isEqual } from 'utils/standard';

import { getSortedUserTransformedSchema } from './tableSchemaUtils';

export const getTableState = (
  endUserTransformedSchema: UserTransformedSchema,
  originalSchema: DatasetSchema,
  vizOp: VisualizeOperation,
): CustomerTableState | null => {
  const originalTransformed = getSortedUserTransformedSchema(originalSchema, vizOp);
  const originalDict = keyBy(originalTransformed, 'name');

  const visibleSchema: string[] = [];
  const originalVisibleSchema: string[] = [];
  const updatedColNames: Record<string, string> = {};

  endUserTransformedSchema.forEach((col) => {
    const originalCol = originalDict[col.name];
    if (!originalCol) return;
    if (col.isVisible) visibleSchema.push(col.name);

    if (col.friendly_name && originalCol.friendly_name !== col.friendly_name) {
      updatedColNames[col.name] = col.friendly_name;
    }
  });

  originalTransformed.forEach((col) => {
    if (col.isVisible) originalVisibleSchema.push(col.name);
  });

  const areVisibleSchemasEqual = isEqual(visibleSchema, originalVisibleSchema);

  // If everything is the same then delete table state
  if (areVisibleSchemasEqual && Object.keys(updatedColNames).length === 0) return null;

  return { visibleSchema: areVisibleSchemasEqual ? [] : visibleSchema, updatedColNames };
};
