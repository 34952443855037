// these should stay in sync with share_link_base.py
export enum SHARE_TYPES {
  SHARE = 'share',
  EPHEMERAL = 'ephemeral',
}

export enum SHARE_RESOURCE_TYPES {
  DASHBOARD = 'dashboard',
  REPORT = 'report',
  CHART = 'chart',
}

export enum SHARE_VIEW_TYPES {
  EMAIL = 'email',
  PDF = 'pdf',
  IMAGE = 'image',
  SHARE = 'share',
}
