import { createSlice, Reducer } from '@reduxjs/toolkit';

import { getDashboardDatasets } from 'actions/dashboardActions';
import { Dataset } from 'actions/datasetActions';
import * as RD from 'remotedata';

interface DatasetMigrationState {
  datasets: RD.ResponseData<Record<string, Dataset>>;
}

const initialState: DatasetMigrationState = {
  datasets: RD.Idle(),
};

const datasetMigrationSlice = createSlice({
  name: 'datasetMigration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardDatasets.pending, (state) => {
      state.datasets = RD.Idle();
    });
    builder.addCase(getDashboardDatasets.rejected, (state) => {
      state.datasets = RD.Error('Failed to fetch datasets for dashboard');
    });
    builder.addCase(getDashboardDatasets.fulfilled, (state, action) => {
      state.datasets = RD.Success(action.payload.datasets);
    });
  },
});

export const datasetMigrationReducer: Reducer<DatasetMigrationState> =
  datasetMigrationSlice.reducer;
