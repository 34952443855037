import {
  TIMESTAMP,
  DATE,
  DATETIME,
  BOOLEAN,
  INTEGER_DATA_TYPE,
  UNSIGNED_INTEGER,
  FLOAT,
  STRING,
} from '@explo/data';

import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';

export const getValidDataTypesFromDashboardElement = (
  elementType: DASHBOARD_ELEMENT_TYPES,
): string[] | undefined => {
  switch (elementType) {
    case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
    case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
    case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
      return [TIMESTAMP, DATE, DATETIME];
    case DASHBOARD_ELEMENT_TYPES.SWITCH:
      return [BOOLEAN];
    case DASHBOARD_ELEMENT_TYPES.SLIDER:
      return [INTEGER_DATA_TYPE, UNSIGNED_INTEGER, FLOAT];
    case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
    case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
      return [STRING];
    default:
      return undefined;
  }
};
