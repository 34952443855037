import cx from 'classnames';
import { FC, useState } from 'react';

import { BaseCol, DatasetSchema } from '@explo/data';

import { APP_PORTAL_ID, IconButton, Input, Menu, MenuActionItem, sprinkles } from 'components/ds';
import { CategoryChartColumnInfo } from 'constants/types';
import { showErrorToast } from 'shared/sharedToasts';

import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import * as styles from './styles.css';

type Props = {
  column: CategoryChartColumnInfo;
  draggingClass?: string;
  onColAdded: (newCol: BaseCol, oldColName?: string) => void;
  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  schema: DatasetSchema;
};

export const DroppedBucketColumn: FC<Props> = ({
  column,
  draggingClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  schema,
}) => {
  const [isExpanded, setIsExpanded] = useState(column.bucketSize !== undefined);
  const [isEditingBucket, setIsEditingBucket] = useState(column.bucketSize === undefined);

  const bucketOptionsPopover = (
    <div className={sprinkles({ backgroundColor: isExpanded ? 'blue3' : 'lightBlue' })}>
      <Menu
        align="end"
        portalContainerId={APP_PORTAL_ID}
        trigger={
          <div className={sprinkles({ flexItems: 'alignCenter', cursor: 'pointer' })}>
            <div className={styles.colName}>
              {isExpanded ? 'Bucket values by...' : 'All values'}
            </div>
            <IconButton
              className={isExpanded ? styles.transparentIconBtn : styles.iconBtnWithDivider}
              name="caret-down"
            />
          </div>
        }>
        <MenuActionItem
          onSelect={() => {
            setIsExpanded(false);
            onColOptionChanged({ id: '', name: '' });
            setIsEditingBucket(false);
          }}
          text="All values"
        />
        <MenuActionItem
          onSelect={() => {
            setIsExpanded(true);
            setIsEditingBucket(true);
          }}
          text="Bucket values by..."
        />
      </Menu>
    </div>
  );

  return (
    <div
      className={cx(
        sprinkles({
          backgroundColor: 'lightBlue',
          flexItems: 'alignCenter',
          borderRadius: 3,
          width: 'fill',
          padding: isExpanded ? 'sp.5' : undefined,
        }),
        draggingClass,
      )}>
      <div className={sprinkles({ flex: 1 })}>
        <div
          className={sprinkles({ parentContainer: 'fill', display: 'flex', overflow: 'hidden' })}>
          <ColumnOptionsPopover
            className={cx(
              isExpanded
                ? sprinkles({ backgroundColor: 'blue3', width: 'fill' })
                : styles.notExpandedBucket,
              sprinkles({ flexItems: 'alignCenter', borderRadius: 3 }),
            )}
            displayName={column.column.name}
            onColChange={(newCol) => onColAdded(newCol, column.column.name)}
            rightDivider={!isExpanded}
            schema={schema}
            transparentIcon={isExpanded}
          />

          {isExpanded ? null : bucketOptionsPopover}
        </div>
        {isExpanded ? (
          <div className={sprinkles({ display: 'flex', marginTop: 'sp.5', gap: 'sp.5' })}>
            {bucketOptionsPopover}
            {!isEditingBucket ? (
              <div
                className={sprinkles({
                  flexItems: 'alignCenter',
                  gap: 'sp.5',
                  backgroundColor: 'blue3',
                  width: 'half',
                })}>
                <div className={cx(styles.colName, sprinkles({ width: 'fill' }))}>
                  {column.bucketSize?.toString()}
                </div>
                <IconButton
                  className={styles.transparentIconBtn}
                  name="pencil"
                  onClick={() => setIsEditingBucket(true)}
                />
              </div>
            ) : (
              <Input
                defaultValue={column.bucketSize?.toString() || ''}
                onSubmit={(value) => {
                  const valAsNumber = Number(value);

                  if (value !== '' && (isNaN(valAsNumber) || valAsNumber <= 0))
                    showErrorToast('Bucket size must be a number greater than 0');
                  else {
                    onColOptionChanged({ id: value, name: value });
                    if (value !== '') {
                      setIsEditingBucket(false);
                    }
                  }
                }}
              />
            )}
          </div>
        ) : null}
      </div>
      <IconButton className={styles.iconBtn} name="cross" onClick={onRemoveCol} />
    </div>
  );
};
