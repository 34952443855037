import { PivotAgg } from './instructions/aggregations';

export enum DateDisplayFormat {
  NORMAL = 'MM/DD/YYYY',
  NUMERIC_SHORT = 'MM-DD-YY',
  NUMERIC_SHORT_TIMESTAMP = 'MM-dd-yy (HH:mm:ss)',
  NUMERIC_LONG = 'DD-MM-YYYY',
  DATE_TIMESTAMP = 'DD/MM/YYYY (hh:mm:ss)',
  VERBAL_SHORT = 'Mon Day, Year',
  VERBAL_LONG = 'Month Day, Year',
  RELATIVE_TO_NOW = 'Relative to Now',
  CUSTOM = 'Custom',
}

export enum TEXT_ELEM_HORIZ_ALIGNMENTS {
  LEFT = 'LEFT_ALIGN',
  CENTER = 'CENTER_ALIGN',
  RIGHT = 'RIGHT_ALIGN',
}

export enum STRING_FORMATS {
  DEFAULT = 'Default',
  UPPERCASE = 'Uppercase',
  LOWERCASE = 'Lowercase',
  TITLE_CASE = 'Title Case',
  CAMEL_CASE = 'Camel Case',
  SENTENCE_CASE = 'Sentence Case',
}

export type DateDisplayOptions = {
  format?: DateDisplayFormat;
  customFormat?: string;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;

  // Used for report builder grouping
  datePartAgg?: PivotAgg;
};

export type StringFormat = { format?: STRING_FORMATS; replaceUnderscores?: boolean };

export enum StringDisplayFormat {
  NORMAL = 'Normal',
  CATEGORY = 'Category',
  LINK = 'Link',
  IMAGE = 'Image',
}

export enum StringUrlDisplayFormat {
  LABEL = 'Label',
  COLUMN = 'Column',
}

export enum ImageShapeFormat {
  CIRCLE = 'Circle',
  RECTANGLE = 'Rectangle',
}

export type StringDisplayOptions = {
  format?: StringDisplayFormat;
  urlFormat?: StringUrlDisplayFormat;
  urlColumnName?: string;
  urlLinkColor?: string;
  openInSameTab?: boolean;
  label?: string;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  categoryColorAssignments?: Record<string | number, string>;
  addedCategories?: { name: string; color: string }[];
  imageShape?: ImageShapeFormat;
};

export enum NumberDisplayFormat {
  NORMAL = 'Normal',
  CURRENCY = 'Currency ($)',
  PERCENT = 'Percent (%)',
  TIME = 'Time (00:00)',
}

export enum NumberDisplayDisplayType {
  VALUE = 'Value',
  PROGRESS_BAR = 'Progress Bar',
}

export type NumberDisplayTypeOptions = {
  progressBarGoal?: string;
  useColumnMaxForProgressBarGoal?: boolean;
  useOtherColumnAsMax?: boolean;
  goalColumnName?: string;
  valueTextWidth?: number;
};

export enum GradientType {
  NONE = 'None',
  LINEAR = 'Linear',
  DIVERGING = 'Diverging',
}

export type GradientShape = {
  hue1: string;
  hue2: string;
  hue3: string;
};

export enum GradientPointType {
  COMPUTED = 'Computed',
  NUMBER = 'Number',
}

export type GradientPointOptions = {
  type?: GradientPointType;
  value?: string;
};

export type GradientOptions = {
  minpoint?: GradientPointOptions;
  midpoint?: GradientPointOptions;
  maxpoint?: GradientPointOptions;
};

export type NumberDisplayOptions = {
  decimalPlaces?: number;
  hasCommas?: boolean;
  format?: NumberDisplayFormat;
  goal?: string;
  timeFormat?: { id: string };
  timeCustomFormat?: string;
  customDurationFormat?: string;
  useColumnMaxForGoal?: boolean;
  displayType?: NumberDisplayDisplayType;
  displayTypeOptions?: NumberDisplayTypeOptions;
  gradientType?: GradientType;
  gradient?: Partial<GradientShape>;
  gradientOptions?: GradientOptions;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  multiplier?: number;
  disableHoverTooltip?: boolean;
  zeroCharacter?: string;
  displayNegativeValuesWithParentheses?: boolean;
};

export enum BooleanDisplayFormat {
  TICK = 'tick',
  CROSS = 'cross',
  BLANK = 'blank',
}

export type BooleanColorCellOptions = {
  trueBackgroundColor?: string;
  falseBackgroundColor?: string;
  trueTextColor?: string;
  falseTextColor?: string;
};

export type BooleanDisplayOptions = {
  trueIcon?: BooleanDisplayFormat;
  falseIcon?: BooleanDisplayFormat;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  colorCell?: BooleanColorCellOptions;
};

export type SchemaDisplayOption =
  | DateDisplayOptions
  | StringDisplayOptions
  | NumberDisplayOptions
  | BooleanDisplayOptions;

export type SchemaDisplayOptions = Record<string, SchemaDisplayOption>;
