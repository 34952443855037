import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SharedPieChartConfigs } from 'components/ChartConfigs/PieChartConfigs';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const PieChartConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const updateInstructions = (newInstructions: V2TwoDimensionChartInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <SharedPieChartConfigs
        configInputClass={configInputClass}
        instructions={instructions}
        updateInstructions={updateInstructions}
      />
    </div>
  );
};
