import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { FC, useMemo } from 'react';

import { OPERATION_TYPES } from '@explo/data';

import { RangeInput } from 'components/ChartConfigs/RangeInput';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { PlotLineSelect } from 'components/PlotLineSelect';
import { BooleanToggle, Input, Label, Select, sprinkles } from 'components/ds';
import { GoalLineFormat, YAxisFormat } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

const colorPickerButtonSize = 32;
const wideColorPickerButtonWidth = 286;

const useStyles = makeStyles(() => ({
  lineWidthInput: {
    width: 55,
  },
  colorPickerWrapper: {
    '& .bp3-popover-wrapper': {
      height: colorPickerButtonSize,
    },
  },
  wideColorPickerWrapper: {
    '& .bp3-popover-wrapper': {
      width: wideColorPickerButtonWidth,
      height: colorPickerButtonSize,
    },
  },
  wideColorPickerBtn: {
    width: wideColorPickerButtonWidth,
    height: colorPickerButtonSize,
  },
}));

type Props = {
  config: GoalLineFormat;
  updateConfig: (newConfig: GoalLineFormat) => void;
  visualizationType: OPERATION_TYPES;
  yAxisFormats: YAxisFormat[];
};

export const GoalLineConfig: FC<Props> = ({
  config,
  updateConfig,
  visualizationType,
  yAxisFormats,
}) => {
  const classes = useStyles();

  const yAxisOptions = useMemo(() => {
    const axisName =
      visualizationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2
        ? 'X-Axis'
        : 'Y-Axis';

    return yAxisFormats.map((format, index) => {
      return {
        label: `${axisName} #${index + 1}`,
        value: format.id ?? index.toString(),
      };
    });
  }, [yAxisFormats, visualizationType]);

  return (
    <div className={configRootClass}>
      <div
        className={cx(
          classes.colorPickerWrapper,
          sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1' }),
          configInputClass,
        )}>
        <Input
          showInputButton
          className={sprinkles({ flex: 1 })}
          defaultValue={config.label}
          label="Label"
          onSubmit={(newValue) => updateConfig({ ...config, label: newValue })}
        />
        <ColorPickerButton
          color={config.labelColor ?? '#FF0000'}
          colorPalette={[]}
          onColorChange={(newColor) => updateConfig({ ...config, labelColor: newColor })}
          size={colorPickerButtonSize}
        />
      </div>

      {yAxisOptions.length > 1 ? (
        <Select
          className={configInputClass}
          label="Axis"
          onChange={(newAxis) => updateConfig({ ...config, yAxisId: newAxis })}
          selectedValue={config.yAxisId}
          values={yAxisOptions}
        />
      ) : null}

      <BooleanToggle
        className={configInputClass}
        falseText="Goal Line"
        label="Goal Type"
        onValueChange={(val) => updateConfig({ ...config, isGoalBand: val })}
        selectedValue={!!config.isGoalBand}
        trueText="Goal range"
      />
      {visualizationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 ? (
        <BooleanToggle
          className={configInputClass}
          falseText="Horizontal"
          label="Orientation"
          onValueChange={(val) => updateConfig({ ...config, isXAxisGoal: val })}
          selectedValue={!!config.isXAxisGoal}
          trueText="Vertical"
        />
      ) : null}

      {config.isGoalBand ? (
        <RangeInput
          usesVariables
          className={configInputClass}
          endVal={config.goalValueMax}
          onNewRange={(newStart, newEnd) => {
            const newConfig = { ...config, goalValue: newStart, goalValueMax: newEnd };
            updateConfig(newConfig);
          }}
          startVal={String(config.goalValue ?? 100)}
        />
      ) : (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(config.goalValue ?? 100)}
          label={{ text: 'Goal Value', variableInput: true }}
          onSubmit={(newValue) => {
            updateConfig({ ...config, goalValue: newValue });
          }}
        />
      )}

      {!config.isGoalBand ? (
        <div
          className={cx(
            sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1' }),
            classes.colorPickerWrapper,
            configInputClass,
          )}>
          <PlotLineSelect
            currentType={config.lineType}
            onChange={(lineType) => updateConfig({ ...config, lineType })}
          />
          <Input
            className={classes.lineWidthInput}
            defaultValue={`${config.lineWidth || 1}px`}
            disabled={config.isGoalBand}
            label="Width"
            onSubmit={(newWidthString) => {
              const newWidth = Number(newWidthString.replace('px', ''));
              if (isNaN(newWidth) || newWidth <= 0) return;
              updateConfig({ ...config, lineWidth: newWidth });
            }}
          />
          <ColorPickerButton
            color={config.lineColor ?? '#FF0000'}
            colorPalette={[]}
            onColorChange={(newColor) => updateConfig({ ...config, lineColor: newColor })}
            size={colorPickerButtonSize}
          />
        </div>
      ) : (
        <div className={cx(configInputClass, classes.wideColorPickerWrapper)}>
          <Label htmlFor="">Color</Label>
          <ColorPickerButton
            btnClassName={classes.wideColorPickerBtn}
            color={config.lineColor ?? '#FF0000'}
            colorPalette={[]}
            onColorChange={(newColor) => updateConfig({ ...config, lineColor: newColor })}
          />
        </div>
      )}
    </div>
  );
};
