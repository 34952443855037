import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';
import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES, CustomerReportGroupBy } from '@explo/data';

import { CustomerReportVisualization } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { IconName } from 'components/ds/Icon';
import { SCATTER_X_AXIS_TYPES, SCATTER_Y_AXIS_TYPES } from 'constants/dataConstants';
import { AddColumnMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddColumnMenu';
import {
  AtLeastOneAlert,
  NoDataAlert,
  RemovedDataAlert,
} from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { GroupByListItem } from 'pages/ReportBuilder/ReportView/DataPanel/GroupByListItem';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { getVisualizationName } from 'pages/ReportBuilder/utils/visualizationUtils';
import {
  clearPrevGroupBys,
  getCurrentViewRemovedGroupBys,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DraggableColumnInfo, getGroupByUniqueId, BucketsByCol } from 'utils/customerReportUtils';

import { DataPanelList } from './DataPanelList';
import { ROWS_SECTION_ID, DataPanelData } from './constants';

type Props = {
  bucketsByCol: BucketsByCol;
  columnConfigs?: ReportBuilderColConfigs;
  groupBys: CustomerReportGroupBy[];
  scatterPlotGrouping?: CustomerReportGroupBy;
  disabled?: boolean;
  operationType: CustomerReportVisualization;
  max?: number;
  columns: DraggableColumnInfo[];
  onAddColumn: (column: DraggableColumnInfo) => void;
};

export const GroupBySection: FC<Props> = ({
  bucketsByCol,
  columnConfigs,
  groupBys,
  scatterPlotGrouping,
  disabled,
  operationType,
  max,
  columns,
  onAddColumn,
}) => {
  const dispatch = useDispatch();
  const { removedGroupBys, sourceType } = useSelector(
    (state: ReportBuilderReduxState) => ({
      removedGroupBys: getCurrentViewRemovedGroupBys(state.reportEditing),
      sourceType: state.reportEditing.reportSourceType,
    }),
    shallowEqual,
  );

  const { active, over, isOver, setNodeRef } = useDroppable(DROPPABLE_ARGS);

  const selectedColumns = useMemo(() => groupBys.map((groupBy) => groupBy.column), [groupBys]);

  const scatterAllowedTypes = useMemo(
    () => (scatterPlotGrouping ? SCATTER_Y_AXIS_TYPES : SCATTER_X_AXIS_TYPES),
    [scatterPlotGrouping],
  );

  const isScatterPlot = operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2;

  const allowedColumns = useMemo(
    () => (isScatterPlot ? columns.filter((c) => scatterAllowedTypes.includes(c.type)) : columns),
    [columns, isScatterPlot, scatterAllowedTypes],
  );

  const sortableIds = useMemo(
    () => groupBys.map((item) => `${ROWS_SECTION_ID}-${getGroupByUniqueId(item)}`),
    [groupBys],
  );

  const isOverContainer = isOverSection(ROWS_SECTION_ID, isOver, over, sortableIds);
  const isOverSelf = active?.data.current?.section === ROWS_SECTION_ID;
  const isAllowedColDragging = isScatterPlot
    ? scatterAllowedTypes.includes(active?.data.current?.column?.type)
    : true;

  const visualizationName = getVisualizationName(operationType);
  const numGroupBys = groupBys.length;
  const numRemovedGroupBys = removedGroupBys ? removedGroupBys.length - numGroupBys : 0;
  const hasMax = max != null && groupBys.length >= max;
  const errorCases = disabled || hasMax || !isAllowedColDragging;
  const isPopoverOpen = isOverContainer && errorCases && !isOverSelf;

  const { title, icon } = getGroupBysTitleAndIcon(operationType);

  const popoverText = useMemo(() => {
    if (hasMax) return `${visualizationName} can have up to ${max} grouping`;
    if (!isAllowedColDragging)
      return `Only ${scatterAllowedTypes.join(', ').toLowerCase()} fields allowed.`;
  }, [hasMax, isAllowedColDragging, max, scatterAllowedTypes, visualizationName]);

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={ROWS_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon={icon}
        popoverChildren={isPopoverOpen ? popoverText : undefined}
        title={title}>
        <AddColumnMenu
          columnConfigs={columnConfigs}
          columns={allowedColumns}
          disabled={disabled}
          onAddColumn={(c) => !isPopoverOpen && onAddColumn(c)}
          selectedColumns={selectedColumns}
          tooltipText={hasMax ? popoverText : undefined}
        />
      </DataPanelSubHeader>

      {numRemovedGroupBys > 0 ? (
        <RemovedDataAlert
          name="grouping"
          numRemoved={numRemovedGroupBys}
          onDismiss={() => dispatch(clearPrevGroupBys())}
          visualizationName={visualizationName}
        />
      ) : null}

      {operationType !== OPERATION_TYPES.VISUALIZE_TABLE && groupBys.length === 0 ? (
        <AtLeastOneAlert name="grouping" visualizationName={visualizationName} />
      ) : null}

      {groupBys.length === 0 ? <NoDataAlert name="grouping" /> : null}

      {groupBys.map((groupBy) => (
        <GroupByListItem
          bucketsByCol={bucketsByCol}
          columnConfigs={columnConfigs}
          groupBy={groupBy}
          key={getGroupByUniqueId(groupBy)}
          section={ROWS_SECTION_ID}
          sourceType={sourceType}
        />
      ))}
    </DataPanelList>
  );
};

function getGroupBysTitleAndIcon(operationType: CustomerReportVisualization): {
  title: string;
  icon: IconName;
} {
  switch (operationType) {
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
      return { title: 'X-Axis Grouping', icon: 'right-long' };
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      return { title: 'Stages', icon: 'down-long' };
    default:
      return { title: 'Group by', icon: 'table-rows' };
  }
}

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: ROWS_SECTION_ID,
  data: { id: ROWS_SECTION_ID, section: ROWS_SECTION_ID } as DataPanelData,
};
