import { Parameter } from '@explo-tech/fido-api';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { SchemaDataType } from '@explo/data';

import { Spinner, sprinkles } from 'components/ds';
import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { ManageQueryParameterModal } from './ManageQueryParameterModal';

import { SchemaDataTypeIcon } from 'constants/dataConstants';
import { getCurrentComputedView } from 'pages/dataLibraryPage/selectors';
import { updateVariables } from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';
import { parseQueryThunk } from 'reducers/thunks/fidoThunks/viewThunks';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { getEmbeddoTypeFromFidoTypeForVariableMapping } from 'utils/fido/fidoShims';
import { sortBy } from 'utils/standard';
import { ValueElement } from './ValueElement';

type Props = {
  searchQuery: string;
  variables: DashboardVariableMap;
};

export const QueryParameterVariablesSection: FC<Props> = ({ searchQuery, variables }) => {
  const dispatch = useDispatch();

  const [editingParam, setEditingParam] = useState<Parameter | undefined>();

  const { detectedParams, computedView } = useSelector(
    (state: ReduxState) => ({
      detectedParams: state.dataLibrary.detectedQueryParameters,
      computedView: getCurrentComputedView(state),
    }),
    shallowEqual,
  );

  // Parse the saved query to detect parameters on load
  useEffect(() => {
    if (RD.isSuccess(computedView) && RD.isIdle(detectedParams)) {
      dispatch(
        parseQueryThunk({
          datasetId: computedView.data.id ?? '',
          request: { query: computedView.data?.query ?? '' },
        }),
      );
    }
  });

  const viewWithIds = useMemo(
    () =>
      RD.isSuccess(computedView) && computedView.data
        ? {
            ...computedView.data,
            id: computedView.data.id ?? '',
            namespaceId: computedView.data.namespaceId ? computedView.data.namespaceId : '',
          }
        : undefined,
    [computedView],
  );

  const orderedSavedParams = useMemo(() => {
    return sortBy(
      Object.values(viewWithIds?.parameters ?? []).filter((param) =>
        param.name.toLowerCase().includes(searchQuery),
      ),
      (param) => param.name.toLowerCase(),
    );
  }, [searchQuery, viewWithIds]);

  const updateVariable = useCallback(
    (varName: string, newValue: DashboardVariable) =>
      dispatch(updateVariables({ [varName]: newValue })),
    [dispatch],
  );

  const renderPanelListItem = (param: Parameter) => {
    const paramHasError = param.type == undefined;
    const schemaDataType = getEmbeddoTypeFromFidoTypeForVariableMapping(param.type);
    return (
      <div
        className={sprinkles({ cursor: paramHasError ? 'pointer' : 'default' })}
        onClick={() => {
          if (paramHasError) {
            setEditingParam(param);
          }
        }}>
        <PanelListItem
          hasError={paramHasError}
          key={param.name}
          leftIcon={
            !param.type
              ? 'circle-exclamation'
              : SchemaDataTypeIcon[schemaDataType as SchemaDataType]
          }
          leftIconTooltipText={!param.type ? 'This parameter requires a type' : undefined}
          name={param.name}
          onEdit={() => setEditingParam(param)}
          rightElement={
            <ValueElement
              name={param.name}
              type={schemaDataType ?? 'STRING'}
              updateVariable={updateVariable}
              value={variables[param.name]}
            />
          }
        />
      </div>
    );
  };

  return (
    <ConfigSection
      defaultOpen
      icon="brackets"
      infoText="Variables displayed below have been detected in your query. 
      Values set below will not persist on refresh or when linked to a dashboard, they are just for testing purposes."
      title="Query parameters"
      variant="header2">
      <div>
        {orderedSavedParams.map((param) => renderPanelListItem(param))}
        {RD.isLoading(detectedParams) ? (
          <div
            className={sprinkles({ flexItems: 'center', marginY: 'sp4', parentContainer: 'fill' })}>
            <Spinner />
          </div>
        ) : null}
      </div>
      {editingParam && viewWithIds ? (
        <ManageQueryParameterModal
          onClose={() => setEditingParam(undefined)}
          param={editingParam}
          selectedView={viewWithIds}
        />
      ) : null}
    </ConfigSection>
  );
};
