import { FC, useMemo } from 'react';

import { TIME_DIFF_FORMATS } from '@explo/data';

import { Input, Select } from 'components/ds';
import { TIME_DIFF_FORMAT_SELECT_OPTIONS } from 'constants/dataConstants';
import { KPIValueFormat } from 'constants/types';
import {
  DEFAULT_DURATION_FORMAT,
  DEFAULT_TIME_FORMAT,
  DURATION_INFO_TEXT_COMPONENT,
} from 'pages/dashboardPage/charts/constants/format';
import { getLuxonDurationFormatErrorMessage } from 'pages/dashboardPage/charts/utils';
import { MapTooltipFormat } from 'types/maps';

type Props = {
  configInputClass: string;
  valueFormat: KPIValueFormat | MapTooltipFormat;
  updateFormat: (generalFormat: KPIValueFormat | MapTooltipFormat) => void;
};

export const TimeFormatConfig: FC<Props> = ({ configInputClass, valueFormat, updateFormat }) => {
  const selectedTimeFormat = valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;
  const customDurationFormat = valueFormat?.customDurationFormat;

  const durationFormatErrorMessage = useMemo(
    () => getLuxonDurationFormatErrorMessage(customDurationFormat),
    [customDurationFormat],
  );

  return (
    <>
      <Select
        className={configInputClass}
        label="Time Format"
        onChange={(timeFormatId) => updateFormat({ timeFormat: { id: timeFormatId } })}
        placeholder="Standard"
        selectedValue={selectedTimeFormat.id}
        values={TIME_DIFF_FORMAT_SELECT_OPTIONS}
      />
      {valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={valueFormat?.timeCustomerFormat}
          label="Custom Time Format"
          onSubmit={(newValue) => updateFormat({ timeCustomerFormat: newValue })}
          placeholder={DEFAULT_TIME_FORMAT}
        />
      ) : null}
      {valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM_DURATION.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={customDurationFormat}
          errorText={durationFormatErrorMessage}
          label={{
            text: 'Custom Duration Format',
            infoText: DURATION_INFO_TEXT_COMPONENT,
          }}
          onSubmit={(newValue) => updateFormat({ customDurationFormat: newValue })}
          placeholder={DEFAULT_DURATION_FORMAT}
        />
      ) : null}
    </>
  );
};
