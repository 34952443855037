import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '@radix-ui/react-use-previous';

import { updateDataPanelDataset } from 'reducers/dashboardEditConfigReducer';
import { setEditDataPanelState } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  getEditableDatasets,
  getGlobalDatasetReferences,
  getReferencedGlobalDatasets,
} from 'reducers/selectors';

import { DataPanelModal } from './dataPanelModal';
import { Checkbox } from 'components/ds';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';

export const EditDataPanelModal: FC<{}> = () => {
  const dispatch = useDispatch();

  const [shouldPreserveCurrentDataPanelConfig, setShouldPreserveCurrentDataPanelConfig] =
    useState(true);

  const {
    editDataPanelState,
    datasets,
    globalDatasetReferences,
    referencedGlobalDatasets,
    editingDataPanelId,
  } = useSelector(
    (state: ReduxState) => ({
      editDataPanelState: state.dashboardInteractions.editDataPanelState,
      datasets: getEditableDatasets(state),
      globalDatasetReferences: getGlobalDatasetReferences(state),
      referencedGlobalDatasets: getReferencedGlobalDatasets(state),
      editingDataPanelId: state.dashboardEditConfig.editingDataPanelId,
    }),
    shallowEqual,
  );

  const previousEditDataPanelState = usePrevious(editDataPanelState);

  useEffect(() => {
    if (previousEditDataPanelState && !editDataPanelState) {
      setShouldPreserveCurrentDataPanelConfig(true);
    }
  }, [previousEditDataPanelState, editDataPanelState, setShouldPreserveCurrentDataPanelConfig]);

  if (!editDataPanelState) return null;

  return (
    <DataPanelModal
      additionalContent={
        <Checkbox
          isChecked={shouldPreserveCurrentDataPanelConfig}
          label="Preserve current data panel configuration"
          onChange={() =>
            setShouldPreserveCurrentDataPanelConfig(!shouldPreserveCurrentDataPanelConfig)
          }
        />
      }
      datasets={datasets}
      headerText="Select a table. This will clear your data panel's current configuration."
      initialDatasetId={editDataPanelState.selectedDatasetId}
      onClose={() => {
        setShouldPreserveCurrentDataPanelConfig(false);
        dispatch(setEditDataPanelState(null));
      }}
      onConfirm={(selectedDatasetId) => {
        const selectedLocalDataset = datasets?.[selectedDatasetId ?? ''];
        const selectedGlobalDatasetReference = globalDatasetReferences?.[selectedDatasetId ?? ''];
        if ((!selectedLocalDataset && !selectedGlobalDatasetReference) || !editingDataPanelId) {
          return;
        }

        dispatch(
          updateDataPanelDataset({
            localDatasetId: selectedLocalDataset?.id,
            globalDatasetReference: selectedGlobalDatasetReference,
            shouldPreserveDataPanelConfiguration: shouldPreserveCurrentDataPanelConfig,
          }),
        );
        // Refetch the data for the data panel if we're preserving the current configuration since
        // the data panel's configuration should be fully ready for a fetch. If clearing the current
        // configuration, do not make a fetch since the data panel's configuration still requires
        // additional setup.
        if (shouldPreserveCurrentDataPanelConfig) {
          dispatch(
            fetchDashboardDataThunk({
              dataPanelsToLoad: [editingDataPanelId],
            }),
          );
        }
      }}
      opType={editDataPanelState.opType}
      primaryButtonText="Edit"
      referencedGlobalDatasets={referencedGlobalDatasets}
      title="Edit underlying dataset"
    />
  );
};
