import { TypeSortInfo } from '@trust-kaz/reactdatagrid-enterprise/types';

import { SortInfo, SortOrder } from '@explo/data';

export const getDataGridSort = (viewSort?: SortInfo[]): TypeSortInfo | null => {
  // Convert Explo's sort to reactdatagrid's sort: TypeSortInfo --> TypeSort
  return (
    viewSort?.map((sort) => ({
      name: sort.column.name,
      id: sort.column.name,
      dir: sort.order === SortOrder.ASC ? 1 : -1,
    })) ?? null
  );
};
