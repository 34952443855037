import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ColumnConfigs, DatasetRow } from '@explo/data';

import { sprinkles } from 'components/ds';
import { EmbedDataGrid } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { getCurrentColorTracker } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { FilterableColumn } from 'utils/customerReportUtils';

import { GRID_ROW_HEIGHT } from '../../constants';

type Props = {
  selectableColumns: FilterableColumn[];
  columnConfigs?: ColumnConfigs;
  rows: DatasetRow[];
  loading: boolean;
};

export const PreviewData: FC<Props> = ({ selectableColumns, columnConfigs, rows, loading }) => {
  const colorCategoryTracker = useSelector((state: ReportBuilderReduxState) =>
    getCurrentColorTracker(state.reportEditing),
  );

  const schema = useMemo(
    () =>
      selectableColumns.map((col) => ({
        name: col.name,
        type: col.type,
        friendly_name: col.display,
      })),
    [selectableColumns],
  );

  if (!columnConfigs || !schema.length)
    return (
      <EmbedText
        body="b1"
        className={sprinkles({
          flex: 1,
          padding: 'sp3',
          flexItems: 'column',
          backgroundColor: 'elevationMid',
        })}
        color="contentTertiary">
        0 columns selected
      </EmbedText>
    );

  return (
    <EmbedDataGrid
      disableCustomStyles
      shouldTruncateText
      colorTracker={colorCategoryTracker}
      columnConfigs={columnConfigs}
      loading={loading}
      rowHeight={GRID_ROW_HEIGHT}
      rows={rows}
      schema={schema}
    />
  );
};
