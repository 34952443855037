import {
  OPERATION_TYPES,
  CustomerReportGroupBy,
  SortInfo,
  CustomerReportFilter,
  DATE,
  FILTER_OPS_DATE_RANGE_PICKER,
  DEFAULT_DATE_RANGES_SET,
  DEFAULT_DATE_RANGES,
  FILTER_OPS_DATE_PICKER,
  RELATIVE_DATE_OPTIONS_SET,
  RELATIVE_DATE_OPTIONS,
  StringDisplayOptions,
  StringDisplayFormat,
  ChartAggregation,
  BaseCol,
  SortOrder,
  NUMBER_TYPES,
  getAggColName,
} from '@explo/data';

import {
  CustomerReportAgg,
  CustomerReportTotals,
  CustomerReportView,
} from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { User } from 'actions/userActions';
import { KPI_VIZ_OPS } from 'constants/dataConstants';
import { CategoryChartColumnInfo } from 'constants/types';
import { COLUMN_GROUP_BY_VISUALIZATION_TYPES } from 'pages/ReportBuilder/constants';
import { FidoReducerState } from 'reducers/fidoReducer';
import { FetchCustomerData } from 'reducers/thunks/customerThunks';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { getDefaultRangeValues, getDefaultRelativeValue } from 'utils/dateUtils';
import { shouldUseFidoForRequest } from 'utils/fido/fidoRequestUtils';

import { CustomerReportDataBody } from '../apiTypes';

export const isTableVisualization = (visualization?: OPERATION_TYPES) =>
  !visualization || visualization === OPERATION_TYPES.VISUALIZE_TABLE;

/**
 * Tables with column group bys must always be pivoted
 * Otherwise, if usePivot is not set, pivot if there are group bys
 * Otherwise, pivot if usePivot is set to true and there are group bys
 */
export const isPivotView = (view: CustomerReportView) =>
  isTableVisualization(view.visualization) &&
  (!!view.columnGroupBys?.length || (view.usePivot !== false && !!view.groupBys?.length));

// Different types of visualization support different aggs and group bys
export const filterViewParams = (
  view: Pick<
    CustomerReportView,
    'aggregations' | 'visualization' | 'groupBys' | 'columnGroupBys'
  > & { scatterPlotGrouping?: CustomerReportGroupBy },
): FilteredViewParams => {
  let groupBys: CustomerReportGroupBy[] = [];
  const isTable = isTableVisualization(view.visualization);
  if (!view.visualization || isTable) {
    groupBys = view.groupBys || [];
  } else if (view.visualization === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2) {
    groupBys = view.groupBys?.slice(0, 2) || [];
  } else if (!KPI_VIZ_OPS.has(view.visualization)) {
    groupBys = view.groupBys?.slice(0, 1) || [];
  }

  const aggs = view.aggregations || [];
  const canHaveBreakdown =
    (isTable ||
      (view.visualization && COLUMN_GROUP_BY_VISUALIZATION_TYPES.has(view.visualization))) &&
    groupBys.length > 0 &&
    (view.visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 ? true : aggs.length > 0);
  const columnGroupBys = (canHaveBreakdown && view.columnGroupBys) || [];

  return {
    columnGroupBys,
    aggs,
    groupBys,
    scatterPlotGrouping:
      view.visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2
        ? view.scatterPlotGrouping
        : undefined,
  };
};

type FilteredViewParams = {
  aggs: CustomerReportAgg[];
  groupBys: CustomerReportGroupBy[];
  columnGroupBys: CustomerReportGroupBy[];
  scatterPlotGrouping: CustomerReportGroupBy | undefined;
};

export const TABLE_ROW_LIMIT = 50;
export const EXPORT_ROW_LIMIT = 500;
export const CHART_ROW_LIMIT = 500;
/**
 * PIVOT_ROW_LIMIT found by binary searching for a round-ish number that allows for almost no lag when the
 * pivot table is 1 row or 1 column at the limit since this is where rendering performance would impact the app.
 */
export const PIVOT_ROW_LIMIT = 1750;
export const RB_PIVOT_ROW_LIMIT = 5000;

export type ViewRequestParams = {
  sort: SortInfo[];
  filters: CustomerReportFilter[];
  group_bys: CategoryChartColumnInfo[];
  columns: string[];
  hidden_columns: string[];
  aggs: CustomerReportAgg[];
  visualization?: OPERATION_TYPES;
  limit?: number;
  timezone?: string;
};

// For relative filters, we need to inject the true start time at when we fetch the data
export const mapCustomerReportRelativeFilters = (
  filters: CustomerReportFilter[],
  timezone: string,
) => {
  return filters
    .filter((clause) => !isFilterClauseIncomplete(clause))
    .map((clause) => {
      const isDateFilter = clause.filterColumn.type === DATE;
      if (FILTER_OPS_DATE_RANGE_PICKER.has(clause.filterOperation.id)) {
        if (
          typeof clause.filterValue === 'string' &&
          DEFAULT_DATE_RANGES_SET.has(clause.filterValue as DEFAULT_DATE_RANGES)
        ) {
          const dateRangeId = clause.filterValue as DEFAULT_DATE_RANGES;
          const { startDate, endDate } = getDefaultRangeValues(
            dateRangeId,
            true,
            undefined,
            timezone,
          );

          return {
            ...clause,
            filterValue: {
              startDate: isDateFilter ? startDate.toISODate() : startDate.toISO(),
              endDate: isDateFilter ? endDate.toISODate() : endDate.toISO(),
            },
          };
        }
      } else if (FILTER_OPS_DATE_PICKER.has(clause.filterOperation.id)) {
        if (
          typeof clause.filterValue === 'string' &&
          RELATIVE_DATE_OPTIONS_SET.has(clause.filterValue as RELATIVE_DATE_OPTIONS)
        ) {
          const dateRangeId = clause.filterValue as RELATIVE_DATE_OPTIONS;
          const startDate = getDefaultRelativeValue(dateRangeId, timezone);
          return {
            ...clause,
            filterValue: { startDate: isDateFilter ? startDate.toISODate() : startDate.toISO() },
          };
        }
      }

      return clause;
    });
};

export const getViewRequestParams = (
  view: CustomerReportView,
  dataset: ReportBuilderDataset,
  timezone: string,
  isExport?: boolean,
): ViewRequestParams => {
  const { visualization, filters, hiddenColumns, columnOrder } = view;
  const { groupBys, columnGroupBys, aggs } = filterViewParams(view);

  const isTable = isTableVisualization(visualization);
  const sort = isTable ? view.sort : undefined;
  const limit = isExport
    ? EXPORT_ROW_LIMIT
    : isPivotView(view)
      ? RB_PIVOT_ROW_LIMIT
      : isTable
        ? TABLE_ROW_LIMIT
        : CHART_ROW_LIMIT;

  // need to make a cope of aggs to be able to push to since aggs comes from redux state
  const updatedAggs = [...aggs];
  groupBys.forEach((groupBy) => {
    const displayFormatting = dataset.columnConfigs[groupBy.column.name]?.displayFormatting;
    const stringDisplayOptions = displayFormatting as StringDisplayOptions;
    if (
      stringDisplayOptions?.format !== StringDisplayFormat.LINK ||
      !stringDisplayOptions?.urlColumnName
    ) {
      return;
    }

    // For grouped linked columns, use the first linked value as the display name
    const urlCol = dataset.schema?.find((col) => col.name === stringDisplayOptions.urlColumnName);
    if (urlCol) updatedAggs.push({ column: urlCol, agg: { id: ChartAggregation.MAX } });
  });

  const groupBysForFetch =
    visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 && view.scatterPlotGrouping
      ? [...groupBys, ...columnGroupBys, view.scatterPlotGrouping]
      : [...groupBys, ...columnGroupBys];

  return {
    filters: mapCustomerReportRelativeFilters(filters, timezone),
    group_bys: prepareGroupBysForFetch(groupBysForFetch),
    aggs: updatedAggs,
    sort: sort ?? [],
    columns: columnOrder,
    hidden_columns: hiddenColumns,
    limit,
    visualization: view.visualization,
  };
};
const prepareGroupBysForFetch = (groupBys: CustomerReportGroupBy[]): CategoryChartColumnInfo[] =>
  groupBys.map(({ column, bucket }) => {
    const colInfo: CategoryChartColumnInfo = { column };
    if (bucket) colInfo.bucket = { id: bucket };
    return colInfo;
  });

export const DISTINCT_COLUMN_LIMIT = 1000;
export function getDistinctColumnBody(
  column: BaseCol,
  limitOverride: number | undefined,
): CustomerReportDataBody {
  return {
    hidden_columns: [],
    group_bys: [{ column }],
    sort: [{ column, order: SortOrder.ASC }],
    filters: [],
    aggs: [],
    columns: [column.name],
    page: 1,
    limit: limitOverride ?? DISTINCT_COLUMN_LIMIT,
  };
}

export function getAggBody(columns: BaseCol[]): CustomerReportDataBody {
  return {
    hidden_columns: [],
    group_bys: [],
    sort: [],
    filters: [],
    aggs: columns.flatMap((column) =>
      NUMBER_TYPES.has(column.type)
        ? [
            { agg: { id: ChartAggregation.MIN }, column },
            { agg: { id: ChartAggregation.MAX }, column },
          ]
        : [],
    ),
    columns: columns.map((column) => column.name),
    page: 1,
    limit: 1,
  };
}

const AGGREGATION_VALUES = Object.values(ChartAggregation);

export function getTotalBody(
  columns: BaseCol[],
  filters: CustomerReportFilter[],
  aggregations: CustomerReportAgg[] | undefined,
  totals: CustomerReportTotals,
  timezone: string,
): CustomerReportDataBody {
  return {
    hidden_columns: [],
    group_bys: [],
    sort: [],
    filters: mapCustomerReportRelativeFilters(
      filters.filter((clause) => !isFilterClauseIncomplete(clause)),
      timezone,
    ),
    aggs: columns
      .map((column) => {
        const agg = aggregations?.find((agg) => agg.column.name === column.name);
        if (!agg) return { agg: { id: totals[column.name] }, column };
        const aggColName = getAggColName(agg);
        return { agg: { id: totals[aggColName] }, column };
      })
      .filter((agg) => AGGREGATION_VALUES.includes(agg.agg.id)),
    columns: columns.map((column) => column.name), // Some dashboards got polluted with '-' as the empty value, filter it out
    page: 1,
    limit: 1,
  };
}

export const getDrilldownView = (
  viewConfig: CustomerReportView,
  drilldownFilters: CustomerReportFilter[],
  drilldownSort?: SortInfo[],
): CustomerReportView => ({
  ...viewConfig,
  name: `${viewConfig.name} Drilldown`,
  visualization: OPERATION_TYPES.VISUALIZE_TABLE,
  sort: drilldownSort,
  aggregations: [],
  groupBys: [],
  columnGroupBys: [],
  // Remove post filters since those require group bys
  filters: [...viewConfig.filters, ...drilldownFilters].filter(
    (clause) => !clause.isPostFilter && !isFilterClauseIncomplete(clause),
  ),
});

export const useFidoForReportBuilderRequest = (
  customerId: number,
  customers: Record<string, FetchCustomerData>,
  currentUser: User,
  fido: FidoReducerState,
  dataset: Pick<ReportBuilderDataset, 'fido_id' | 'parent_schema_id'>,
) => {
  return shouldUseFidoForRequest(
    {
      type: 'app',
      useFido: currentUser?.team?.feature_flags.use_fido,
      parentSchemaDataSourceMapping:
        customers[customerId]?.customer?.computed_parent_schema_datasource_mapping,
    },
    fido,
    dataset,
    true,
  );
};
