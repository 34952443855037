import { FC, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import { addCustomer } from 'actions/customerActions';
import { AccessGroup, GroupTag, HierarchyLevel } from 'actions/teamActions';
import { Modal, APP_PORTAL_ID } from 'components/ds';
import * as RD from 'remotedata';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';

import { CustomerContent, EditorCustomer } from './customerContent';
import * as styles from './styles.css';
import { isEditorGroupValid, formatPropertiesForSubmit } from './utils';

type Props = {
  closeModal: () => void;
  accessGroups: AccessGroup[];
  groupTags: RD.ResponseData<GroupTag[]>;
  hierarchyLevels: HierarchyLevel[];
};

export const CustomersModal: FC<Props> = ({
  closeModal,
  accessGroups,
  groupTags,
  hierarchyLevels,
}: Props) => {
  const dispatch = useDispatch();

  const initialHierarchyLevelId = useMemo(
    () => hierarchyLevels[hierarchyLevels.length - 1]?.id ?? -1,
    [hierarchyLevels],
  );

  const initialEditorGroup = {
    name: '',
    providedId: '',
    mapping: {},
    properties: {},
    accessGroupId: accessGroups.length === 1 ? accessGroups[0].id : -1,
    isDemoGroup: false,
    endUsers: [],
    selectedDashboardId: undefined,
    selectedGroupTagIds: [],
    emails: [],
    hierarchyLevelId: initialHierarchyLevelId,
    parentProvidedId: undefined,
  };

  const [editorGroup, setEditorGroup] = useImmer<EditorCustomer>(initialEditorGroup);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValidInput = useMemo(() => isEditorGroupValid(editorGroup), [editorGroup]);

  const handleSubmit = () => {
    const {
      name,
      providedId,
      mapping,
      endUsers,
      accessGroupId,
      isDemoGroup,
      properties,
      selectedGroupTagIds,
      selectedDashboardId,
      emails,
      hierarchyLevelId,
      parentProvidedId,
    } = editorGroup;
    if (!isValidInput || isSubmitting) return;

    let customerProperties;
    try {
      customerProperties = formatPropertiesForSubmit(properties);
    } catch {
      showErrorToast('Invalid Properties JSON');
      return;
    }
    setIsSubmitting(true);
    dispatch(
      addCustomer(
        {
          postData: {
            name,
            emails,
            mapping,
            provided_id: providedId,
            properties: customerProperties,
            access_group_id: accessGroupId,
            is_demo_group: isDemoGroup,
            group_tag_ids: selectedGroupTagIds,
            end_users: endUsers,
            permissioned_dashboard_id: selectedDashboardId,
            hierarchy_level_id: hierarchyLevelId,
            parent_provided_id: parentProvidedId,
          },
        },
        (response) => showSuccessToast(`Created customer ${response.customer.name} successfully! `),
        (error) => showErrorToast(`Error creating customer ${name}: ${error.error_msg}`),
      ),
    );
    closeModal();
  };

  return (
    // if the caller is rendering CustomersModal, then the modal is open
    <Modal
      isOpen
      contentClassName={styles.modal}
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{
        text: 'Confirm',
        disabled: !isValidInput,
        onClick: handleSubmit,
      }}
      size="medium"
      title="Add Customer">
      <CustomerContent
        accessGroups={accessGroups}
        editorGroup={editorGroup}
        groupTags={groupTags}
        hierarchyLevels={hierarchyLevels}
        onSubmit={handleSubmit}
        setEditorGroup={setEditorGroup}
      />
    </Modal>
  );
};
