import cx from 'classnames';
import produce from 'immer';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  ChartAggregation,
  DatasetRow,
  DatasetSchema,
  GROUPED_STACKED_OPERATION_TYPES,
  OPERATION_TYPES,
  SortAxis,
} from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SortOptionToggle } from 'components/SortDirectionToggles';
import { Button, Select, sprinkles } from 'components/ds';
import {
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { getGroupOptions, resolveAggColDropped } from 'utils/dataPanelColUtils';
import { keyBy } from 'utils/standard';

import { SortableCategoriesConfig } from './SortableCategoriesConfig';

type Instructions =
  | V2TwoDimensionChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions;

type Props = {
  chartSchema: DatasetSchema;
  instructions: Instructions;
  dataPanelData: DatasetRow[];
  datasetSchema?: DatasetSchema;
  isHorizontal?: boolean;
  visualizationType: OPERATION_TYPES;
};

export const SortConfigs: FC<Props> = ({
  chartSchema,
  instructions,
  dataPanelData,
  datasetSchema,
  isHorizontal,
  visualizationType,
}) => {
  const dispatch = useDispatch();

  const { xAxisFormat } = instructions;
  const datasetSchemaByName = useMemo(() => keyBy(datasetSchema, 'name'), [datasetSchema]);
  const selectedSort = xAxisFormat?.sortOption;

  const selectedSortAxis = xAxisFormat?.sortAxis || SortAxis.NONE;
  const selectedSortColumn = xAxisFormat?.sortColumns?.[0];
  const aggGroupOptions: { value: string; label: string }[] = useMemo(() => {
    const sortGroupOptions = selectedSortColumn ? getGroupOptions(selectedSortColumn) : [];
    return (sortGroupOptions[0]?.options ?? []).map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
  }, [selectedSortColumn]);

  const sortableCategories = useMemo(() => {
    const xAxisColIndex = GROUPED_STACKED_OPERATION_TYPES.has(visualizationType) ? 1 : 0;

    if (!datasetSchema || !chartSchema[xAxisColIndex]) return new Set<string>();
    const xAxisColName = chartSchema[xAxisColIndex].name;

    const categories = new Set<string>();
    dataPanelData.forEach((row) => categories.add(String(row[xAxisColName])));

    return categories;
  }, [dataPanelData, datasetSchema, visualizationType, chartSchema]);

  const updateXAxisFormat = (xAxisUpdates: XAxisFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.xAxisFormat = {
        ...draft.xAxisFormat,
        ...xAxisUpdates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const sortAxisOptions = useMemo(
    () =>
      Object.values(SortAxis).map((sortAxis) => {
        let text = 'Default';
        if (sortAxis === SortAxis.AGG_AXIS) text = isHorizontal ? 'X-Axis' : 'Y-Axis';
        else if (sortAxis === SortAxis.CAT_AXIS) text = isHorizontal ? 'Y-Axis' : 'X-Axis';
        else if (sortAxis === SortAxis.COLUMN) text = 'Column';
        else if (sortAxis === SortAxis.MANUAL) text = 'Manual';

        return { value: sortAxis, label: text };
      }),
    [isHorizontal],
  );

  return (
    <div className={configRootClass}>
      <Select
        className={cx(sprinkles({ width: 'fill' }), configInputClass)}
        label="Sort Type"
        onChange={(value) => updateXAxisFormat({ sortAxis: value as SortAxis })}
        placeholder="Select an Option"
        selectedValue={selectedSortAxis}
        values={sortAxisOptions}
      />

      {selectedSortAxis === SortAxis.COLUMN ? (
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            paddingX: 'sp1.5',
            width: 'fill',
            marginBottom: 'sp1',
            gap: 'sp1',
          })}>
          <Select
            className={sprinkles({ flex: 1 })}
            label="Sort Column"
            onChange={(value) => {
              const col = datasetSchemaByName[value];
              if (!col) return;
              updateXAxisFormat({ sortColumns: resolveAggColDropped(col) });
            }}
            placeholder="Select a column"
            selectedValue={selectedSortColumn?.column.name}
            values={(datasetSchema ?? []).map((col) => ({
              value: col.name,
            }))}
          />
          <Select
            className={sprinkles({ flex: 1 })}
            label="Aggregation"
            onChange={(value) => {
              if (!selectedSortColumn || selectedSortColumn.agg.id === value) return;
              updateXAxisFormat({
                sortColumns: [{ ...selectedSortColumn, agg: { id: value as ChartAggregation } }],
              });
            }}
            placeholder="Select an Aggregation"
            selectedValue={selectedSortColumn?.agg.id}
            values={aggGroupOptions}
          />
        </div>
      ) : null}
      {selectedSortAxis === SortAxis.MANUAL ? (
        <div
          className={sprinkles({
            width: 'fill',
            marginBottom: 'sp1',
          })}>
          <div className={sprinkles({ paddingX: 'sp1.5', width: 'fill' })}>
            <Button
              fillWidth
              icon="plus"
              onClick={() =>
                updateXAxisFormat({
                  sortManualCategoriesOrder: [
                    {
                      category: 'New Stage',
                      displayName: 'New Stage',
                    },
                    ...(instructions.xAxisFormat?.sortManualCategoriesOrder || []),
                  ],
                })
              }
              variant="secondary">
              Add Stage
            </Button>
          </div>
          <SortableCategoriesConfig
            orderConfig={instructions.xAxisFormat?.sortManualCategoriesOrder}
            updateOrder={(newOrder) => updateXAxisFormat({ sortManualCategoriesOrder: newOrder })}
            visibleCategories={sortableCategories}
          />
        </div>
      ) : null}
      {selectedSortAxis !== SortAxis.NONE && selectedSortAxis !== SortAxis.MANUAL ? (
        <SortOptionToggle
          className={configInputClass}
          currentSort={selectedSort}
          label="Sort Direction"
          updateSort={(newSort) => updateXAxisFormat({ sortOption: newSort })}
        />
      ) : null}
    </div>
  );
};
