import { batch } from 'react-redux';
import Redux from 'redux';

import {
  fetchDataPanelError,
  fetchDataPanelRequest,
  fetchDataPanelRowCountError,
  fetchDataPanelRowCountRequest,
  fetchDataPanelRowCountSuccess,
  fetchDataPanelSuccess,
  fetchSecondaryDataError,
  fetchSecondaryDataRequest,
  fetchSecondaryDataSuccess,
} from 'actions/dataPanelTemplateAction';
import {
  fetchAllDataSourceTablesActions,
  fetchDatasetPreviewError,
  fetchDatasetPreviewRequest,
  fetchDatasetPreviewSuccess,
  fetchDatasetRowCountError,
  fetchDatasetRowCountSuccess,
  syncSourceTablesRequest,
  syncSourceTablesSuccess,
} from 'actions/dataSourceActions';
import {
  fetchDashboardDatasetPreviewError,
  fetchDashboardDatasetPreviewRequest,
  fetchDashboardDatasetPreviewSuccess,
  fetchEditorDatasetPreviewError,
  fetchEditorDatasetPreviewRequest,
  fetchEditorDatasetPreviewSuccess,
  fetchEditorDatasetRowCountError,
  fetchEditorDatasetRowCountSuccess,
} from 'actions/datasetActions';
import {
  embedFetchDashboardDatasetPreviewError,
  embedFetchDashboardDatasetPreviewRequest,
  embedFetchDashboardDatasetPreviewSuccess,
  embedFetchDataPanelError,
  embedFetchDataPanelRequest,
  embedFetchDataPanelRowCountError,
  embedFetchDataPanelRowCountRequest,
  embedFetchDataPanelRowCountSuccess,
  embedFetchDataPanelSuccess,
  embedFetchSecondaryDataError,
  embedFetchSecondaryDataRequest,
  embedFetchSecondaryDataSuccess,
} from 'actions/embedActions';
import {
  downloadDashboardError,
  downloadDashboardRequest,
  downloadDashboardSuccess,
  downloadDataPanelScreenshotError,
  downloadDataPanelScreenshotRequest,
  downloadDataPanelScreenshotSuccess,
  downloadDataPanelSpreadsheetError,
  downloadDataPanelSpreadsheetRequest,
  downloadDataPanelSpreadsheetSuccess,
} from 'actions/exportActions';
import { BulkJobEnqueueResponse, JobDefinition, JobQueueResult } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import {
  fetchDashboardDatasetError,
  fetchDashboardDatasetRequest,
  fetchDashboardDatasetRowCountError,
  fetchDashboardDatasetRowCountRequest,
  fetchDashboardDatasetRowCountSuccess,
  fetchDashboardDatasetSuccess,
  fetchDashboardReportDataError,
  fetchDashboardReportDataRequest,
  fetchDashboardReportDataSuccess,
  fetchDashboardReportRowCountError,
  fetchDashboardReportRowCountRequest,
  fetchDashboardReportRowCountSuccess,
} from 'reportBuilderContent/actions/dashboardJobActions';
import {
  fetchEmbeddedReportDataError,
  fetchEmbeddedReportDataRequest,
  fetchEmbeddedReportDataSuccess,
  fetchEmbeddedRowCountError,
  fetchEmbeddedRowCountRequest,
  fetchEmbeddedRowCountSuccess,
} from 'reportBuilderContent/actions/embeddedJobActions';
import {
  fetchChatAiViewError,
  fetchChatAiViewRequest,
  fetchChatAiViewSuccess,
  fetchGenerateAiViewError,
  fetchGenerateAiViewRequest,
  fetchGenerateAiViewSuccess,
  fetchGenerateAiViewSuggestionError,
  fetchGenerateAiViewSuggestionRequest,
  fetchGenerateAiViewSuggestionSuccess,
} from 'reportBuilderContent/thunks/aiThunks';
import {
  exportCustomerReportError,
  exportCustomerReportSuccess,
  sendDraftExportError,
  sendDraftExportRequest,
  sendDraftExportSuccess,
  sendTestExportError,
  sendTestExportRequest,
  sendTestExportSuccess,
} from 'reportBuilderContent/thunks/exportThunks';

/**
 * Returns the action for the given job type. For dashboard actions, this will either be the embed or non-embed
 * version of the action, depending on whether the context is embedded. For other actions, this is a no-op that
 * returns the job type
 */
export const getDispatchActions = (jobType: ACTION, isEmbed = false) => {
  switch (jobType) {
    case ACTION.FETCH_DATA_PANEL_TEMPLATE:
      return !isEmbed
        ? {
            requestFn: fetchDataPanelRequest,
            successFn: fetchDataPanelSuccess,
            errorFn: fetchDataPanelError,
          }
        : {
            requestFn: embedFetchDataPanelRequest,
            successFn: embedFetchDataPanelSuccess,
            errorFn: embedFetchDataPanelError,
          };
    case ACTION.FETCH_SECONDARY_DATA:
      return !isEmbed
        ? {
            requestFn: fetchSecondaryDataRequest,
            successFn: fetchSecondaryDataSuccess,
            errorFn: fetchSecondaryDataError,
          }
        : {
            requestFn: embedFetchSecondaryDataRequest,
            successFn: embedFetchSecondaryDataSuccess,
            errorFn: embedFetchSecondaryDataError,
          };
    case ACTION.FETCH_DATA_PANEL_ROW_COUNT:
      return !isEmbed
        ? {
            requestFn: fetchDataPanelRowCountRequest,
            successFn: fetchDataPanelRowCountSuccess,
            errorFn: fetchDataPanelRowCountError,
          }
        : {
            requestFn: embedFetchDataPanelRowCountRequest,
            successFn: embedFetchDataPanelRowCountSuccess,
            errorFn: embedFetchDataPanelRowCountError,
          };
    case ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT:
      return {
        requestFn: downloadDataPanelScreenshotRequest,
        successFn: downloadDataPanelScreenshotSuccess,
        errorFn: downloadDataPanelScreenshotError,
      };
    case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW:
      return !isEmbed
        ? {
            requestFn: fetchDashboardDatasetPreviewRequest,
            successFn: fetchDashboardDatasetPreviewSuccess,
            errorFn: fetchDashboardDatasetPreviewError,
          }
        : {
            requestFn: embedFetchDashboardDatasetPreviewRequest,
            successFn: embedFetchDashboardDatasetPreviewSuccess,
            errorFn: embedFetchDashboardDatasetPreviewError,
          };
    case ACTION.DOWNLOAD_DASHBOARD:
      return {
        requestFn: downloadDashboardRequest,
        successFn: downloadDashboardSuccess,
        errorFn: downloadDashboardError,
      };
    case ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET:
      return {
        requestFn: downloadDataPanelSpreadsheetRequest,
        successFn: downloadDataPanelSpreadsheetSuccess,
        errorFn: downloadDataPanelSpreadsheetError,
      };

    case ACTION.FETCH_EDITOR_DATASET_PREVIEW:
      return {
        requestFn: fetchEditorDatasetPreviewRequest,
        successFn: fetchEditorDatasetPreviewSuccess,
        errorFn: fetchEditorDatasetPreviewError,
      };
    case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT:
      return {
        requestFn: null,
        successFn: fetchEditorDatasetRowCountSuccess,
        errorFn: fetchEditorDatasetRowCountError,
      };
    case ACTION.FETCH_DATASET_PREVIEW:
      return {
        requestFn: fetchDatasetPreviewRequest,
        successFn: fetchDatasetPreviewSuccess,
        errorFn: fetchDatasetPreviewError,
      };
    case ACTION.FETCH_DATASET_ROW_COUNT:
      return {
        requestFn: null,
        successFn: fetchDatasetRowCountSuccess,
        errorFn: fetchDatasetRowCountError,
      };
    case ACTION.SYNC_SOURCE_TABLES:
      return {
        requestFn: syncSourceTablesRequest,
        successFn: syncSourceTablesSuccess,
        errorFn: null,
      };
    case ACTION.FETCH_ALL_DATA_SOURCE_TABLES:
      return {
        requestFn: fetchAllDataSourceTablesActions.requestAction,
        successFn: fetchAllDataSourceTablesActions.successAction,
        errorFn: fetchAllDataSourceTablesActions.errorAction,
      };
    case ACTION.EXPORT_CUSTOMER_REPORT:
      return {
        requestFn: null,
        successFn: exportCustomerReportSuccess,
        errorFn: exportCustomerReportError,
      };
    case ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB:
      return {
        requestFn: fetchDashboardDatasetRequest,
        successFn: fetchDashboardDatasetSuccess,
        errorFn: fetchDashboardDatasetError,
      };
    case ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB:
      return {
        requestFn: fetchDashboardDatasetRowCountRequest,
        successFn: fetchDashboardDatasetRowCountSuccess,
        errorFn: fetchDashboardDatasetRowCountError,
      };
    case ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB:
      return {
        requestFn: fetchEmbeddedReportDataRequest,
        successFn: fetchEmbeddedReportDataSuccess,
        errorFn: fetchEmbeddedReportDataError,
      };
    case ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB:
      return {
        requestFn: fetchEmbeddedRowCountRequest,
        successFn: fetchEmbeddedRowCountSuccess,
        errorFn: fetchEmbeddedRowCountError,
      };
    case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB:
      return {
        requestFn: fetchDashboardReportDataRequest,
        successFn: fetchDashboardReportDataSuccess,
        errorFn: fetchDashboardReportDataError,
      };
    case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB:
      return {
        requestFn: fetchDashboardReportRowCountRequest,
        successFn: fetchDashboardReportRowCountSuccess,
        errorFn: fetchDashboardReportRowCountError,
      };
    case ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB:
      return {
        requestFn: fetchChatAiViewRequest,
        successFn: fetchChatAiViewSuccess,
        errorFn: fetchChatAiViewError,
      };
    case ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB:
      return {
        requestFn: fetchGenerateAiViewRequest,
        successFn: fetchGenerateAiViewSuccess,
        errorFn: fetchGenerateAiViewError,
      };
    case ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB:
      return {
        requestFn: fetchGenerateAiViewSuggestionRequest,
        successFn: fetchGenerateAiViewSuggestionSuccess,
        errorFn: fetchGenerateAiViewSuggestionError,
      };
    case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS:
      return {
        requestFn: sendDraftExportRequest,
        successFn: sendDraftExportSuccess,
        errorFn: sendDraftExportError,
      };
    case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL:
      return {
        requestFn: sendTestExportRequest,
        successFn: sendTestExportSuccess,
        errorFn: sendTestExportError,
      };
    default:
      return {
        requestFn: null,
        successFn: null,
        errorFn: null,
      };
  }
};

/**
 * Takes in a response from the bulk enqueue endpoint and returns a dictionary of
 * the backend's job id mapped to the job object
 */
export const handleBulkEnqueueResponse = (
  { enqueued_job_map: enqueuedJobMap }: BulkJobEnqueueResponse,
  jobMap: Record<string, JobDefinition>,
  dispatch: Redux.Dispatch,
  isEmbed: boolean,
) => {
  const newAwaitedJobs: Record<string, Jobs> = {};
  Object.keys(enqueuedJobMap).forEach((jobId) => {
    const { job_intention_key: jobIntentionKey, error } = enqueuedJobMap[jobId];

    const { onSuccess, onError, job_type: jobType, job_args: jobArgs } = jobMap[jobId];

    if (error) {
      handleEnqueueError({ jobType, jobArgs, onError }, error, isEmbed, dispatch);
    } else if (jobIntentionKey) {
      switch (jobType) {
        case ACTION.FETCH_DATA_PANEL_TEMPLATE:
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.id,
            jobId: jobArgs.jobId,
          };
          break;
        case ACTION.FETCH_DATA_PANEL_ROW_COUNT: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.id,
          };
          break;
        }
        case ACTION.FETCH_SECONDARY_DATA: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.id,
            isBoxPlot: jobArgs.is_box_plot,
          };
          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.id,
            fileFormat: jobArgs.file_format,
          };
          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.data_panel_template_id,
          };
          break;
        }
        case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
            onSuccess,
          };
          break;
        }

        case ACTION.FETCH_EDITOR_DATASET_PREVIEW: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
            onSuccess,
          };
          break;
        }
        case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
          };
          break;
        }
        case ACTION.FETCH_DATASET_PREVIEW: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
          };
          break;
        }
        case ACTION.FETCH_DATASET_ROW_COUNT: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
          };
          break;
        }
        case ACTION.SEND_TEST_EMAIL: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess: onSuccess as () => void,
            onError,
          };
          break;
        }
        case ACTION.SYNC_SOURCE_TABLES: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess: onSuccess as () => void,
          };
          break;
        }
        case ACTION.TEST_DATA_SOURCE_CONNECTION: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess,
            onError,
          };
          break;
        }
        case ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess,
            onError,
          };
          break;
        }
        case ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB: {
          newAwaitedJobs[jobIntentionKey] = { jobType, jobArgs, onSuccess };
          break;
        }
        case ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB:
        case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB:
        case ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB:
        case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB:
        case ACTION.FETCH_CUSTOMER_DATASET_PREVIEW:
        case ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB:
        case ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB:
        case ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB:
        case ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB: {
          newAwaitedJobs[jobIntentionKey] = { jobType, jobArgs };
          break;
        }
        case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL:
        case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS:
        case ACTION.DOWNLOAD_DASHBOARD:
        case ACTION.EXPORT_CUSTOMER_REPORT:
        case ACTION.FETCH_ALL_DATA_SOURCE_TABLES: {
          newAwaitedJobs[jobIntentionKey] = { jobType };
          break;
        }
      }
    }
  });

  return newAwaitedJobs;
};

/**
 * Dispatches the error action, if exists, for the given job type and fires off that job's onError, if exists
 */
export const handleEnqueueError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  job: { jobType: ACTION; jobArgs: any; onError?: ((errorMessage: string) => void) | (() => void) },
  errorMessage: string,
  isEmbed: boolean,
  dispatch: Redux.Dispatch,
) => {
  const { errorFn } = getDispatchActions(job.jobType, isEmbed);

  let errorArgs = undefined;

  switch (job.jobType) {
    case ACTION.FETCH_DATA_PANEL_TEMPLATE:
      errorArgs = {
        error_msg: errorMessage,

        // postData has more values normally, but the reducer only uses id and jobId
        // and the compiler is finicky
        // @ts-ignore
        postData: { id: job.jobArgs.id, jobId: job.jobArgs.jobId },
      };
      break;
    case ACTION.FETCH_SECONDARY_DATA:
    case ACTION.FETCH_DATA_PANEL_ROW_COUNT:
    case ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET:
      errorArgs = {
        error_msg: errorMessage,

        // postData has more values normally, but the reducer only uses id
        // and the compiler is finicky
        // @ts-ignore
        postData: { id: job.jobArgs.id },
      };
      break;
    case ACTION.FETCH_EDITOR_DATASET_PREVIEW:
    case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW:
    case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT:
    case ACTION.FETCH_DATASET_ROW_COUNT:
    case ACTION.FETCH_DATASET_PREVIEW:
      errorArgs = {
        error_msg: errorMessage,
        // postData has more values normally, but the reducer only uses dataset_id
        // and the compiler is finicky
        // @ts-ignore
        postData: { dataset_id: job.jobArgs.dataset_id },
      };
      break;
    case ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT:
      errorArgs = {
        error_msg: errorMessage,
        // postData has more values normally, but the reducer only uses data_panel_template_id
        // and the compiler is finicky
        // @ts-ignore
        postData: { data_panel_template_id: job.jobArgs.data_panel_template_id },
      };
      break;
    case ACTION.FETCH_ALL_DATA_SOURCE_TABLES:
    case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS:
    case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL:
    case ACTION.DOWNLOAD_DASHBOARD:
      errorArgs = {};
      break;
    case ACTION.EXPORT_CUSTOMER_REPORT:
    case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB:
    case ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB:
    case ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB:
    case ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB:
    case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB:
    case ACTION.FETCH_CUSTOMER_DATASET_PREVIEW:
    case ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB:
    case ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB:
    case ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB:
    case ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB: {
      // @ts-ignore
      errorArgs = {
        job,
        error: errorMessage,
      };
      break;
    }
    default:
      break;
  }

  // @ts-ignore
  if (errorFn && errorArgs !== undefined) dispatch(errorFn(errorArgs));
  job.onError?.(errorMessage);
};

/**
 * Takes in a response from the get job queue results endpoint and fires off any
 * success and error redux actions and job success and error functions as necessary.
 * Returns a list of the finished job ids.
 */
export const handleJobQueueResults = (
  finishedJobs: Record<string, JobQueueResult>,
  awaitedJobs: Record<string, Jobs>,
  dispatch: Redux.Dispatch,
  isEmbed: boolean,
) => {
  const finishedJobIds = Object.keys(finishedJobs);

  batch(() => {
    finishedJobIds.forEach((jobId) => {
      const { success, error } = finishedJobs[jobId];
      const job = awaitedJobs[jobId];

      const { successFn, errorFn } = getDispatchActions(job.jobType, isEmbed);

      switch (job.jobType) {
        case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW: {
          if (success) {
            if (successFn) {
              dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
            }
            job.onSuccess?.(success);
          }
          if (error) {
            if (errorFn) {
              // @ts-ignore
              dispatch(errorFn({ postData: { dataset_id: job.datasetId } }));
            }
            job.onError?.();
          }
          break;
        }
        case ACTION.FETCH_DATA_PANEL_TEMPLATE: {
          if (success && successFn) {
            dispatch(
              successFn({ ...success, postData: { id: job.dataPanelId, jobId: job.jobId } }),
            );
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                // @ts-ignore
                error_msg: error,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { id: job.dataPanelId, jobId: job.jobId },
                // @ts-ignore
                query_information: finishedJobs[jobId]?.query_information,
              }),
            );

          break;
        }
        case ACTION.FETCH_DATA_PANEL_ROW_COUNT: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { id: job.dataPanelId } }));
          }
          if (error && errorFn) {
            // @ts-ignore
            dispatch(errorFn({ postData: { id: job.dataPanelId } }));
          }
          break;
        }
        case ACTION.FETCH_SECONDARY_DATA: {
          if (success && successFn) {
            dispatch(
              successFn({
                ...success,
                postData: { id: job.dataPanelId, is_box_plot: job.isBoxPlot },
              }),
            );
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                // @ts-ignore
                error_msg: error,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { id: job.dataPanelId },
              }),
            );

          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET: {
          if (success && successFn) {
            dispatch(
              successFn({
                ...success,
                postData: { id: job.dataPanelId, file_format: job.fileFormat },
              }),
            );
          }

          if (error && errorFn) {
            dispatch(
              errorFn({
                // @ts-ignore
                error_msg: error,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { id: job.dataPanelId },
              }),
            );
          }

          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT: {
          if (success && successFn)
            dispatch(
              successFn({ ...success, postData: { data_panel_template_id: job.dataPanelId } }),
            );

          if (error && errorFn) {
            dispatch(
              errorFn({
                // @ts-ignore
                error_msg: error,
                // @ts-ignore
                postData: { data_panel_template_id: job.dataPanelId },
              }),
            );
          }

          break;
        }
        case ACTION.FETCH_EDITOR_DATASET_PREVIEW: {
          if (success) {
            if (successFn) {
              dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
            }
            job.onSuccess?.(success);
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                // @ts-ignore
                error_msg: error,
                // @ts-ignore
                query_information: finishedJobs[jobId]?.query_information,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { dataset_id: job.datasetId },
              }),
            );
          break;
        }
        case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
          }
          break;
        }
        case ACTION.FETCH_DATASET_PREVIEW: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                // @ts-ignore
                error_msg: error,
                // these are numbers, but have to be typed as strings
                // @ts-ignore
                postData: { dataset_id: job.datasetId },
              }),
            );
          break;
        }
        case ACTION.FETCH_DATASET_ROW_COUNT: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
          }
          break;
        }
        case ACTION.SYNC_SOURCE_TABLES: {
          if (success && successFn) {
            dispatch(successFn(success));
            job.onSuccess?.();
          }
          break;
        }
        case ACTION.TEST_DATA_SOURCE_CONNECTION:
        case ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION:
        case ACTION.SEND_TEST_EMAIL: {
          if (success) job.onSuccess?.(success);
          if (error) job.onError?.(error);
          break;
        }
        case ACTION.FETCH_ALL_DATA_SOURCE_TABLES: {
          if (success && successFn) dispatch(successFn(success));
          if (error) {
            // @ts-ignore
            if (errorFn) dispatch(errorFn({ error_msg: error }));
          }
          break;
        }
        case ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB: {
          if (success && successFn) dispatch(successFn({ ...success, ...job }));
          // @ts-ignore
          if (error && errorFn) dispatch(errorFn({ job, error }));
          job.onSuccess?.(success);
          break;
        }
        case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB:
        case ACTION.FETCH_CUSTOMER_REPORT_DATA_JOB:
        case ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB:
        case ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB:
        case ACTION.FETCH_CUSTOMER_DATASET_PREVIEW:
        case ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT_JOB:
        case ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB:
        case ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB:
        case ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB: {
          if (success && successFn) dispatch(successFn({ ...success, ...job }));
          // @ts-ignore
          if (error && errorFn) dispatch(errorFn({ job, error }));
          break;
        }
        default:
          if (success && successFn) dispatch(successFn(success));
          // @ts-ignore
          if (error && errorFn) dispatch(errorFn());
      }
    });
  });

  return finishedJobIds;
};
