import produce from 'immer';
import { useEffect, useState, useRef, FC } from 'react';

import {
  DatasetRow,
  DatasetSchema,
  FilterOperationInstructions,
  OPERATION_TYPES,
} from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { ExportSpreadsheetType } from 'actions/exportActions';
import { UserTransformedSchema, VisualizeTableInstructions } from 'constants/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  AdHocOperationInstructions,
  DataPanelData,
  VisualizeOperation,
} from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { removeUserDisabledColumns } from 'utils/dashboardUtils';
import {
  getReportBuilderUserTransformedSchema,
  getTableChangedSchema,
  sortSchemaByOrderedColumnNames,
} from 'utils/tableSchemaUtils';

import { ExportStep } from './Steps/ExportStep';
import { NameReportStep } from './Steps/NameReportStep';
import { PreviewStep } from './Steps/PreviewStep';
import SelectContentStep from './Steps/SelectContentStep';
import { StartStep } from './Steps/StartStep';
import { ReportBuilderStep, REPORT_BUILDER_STEP_ORDER } from './constants';

type ReportConfiguration = {
  name?: string;
  userTransformedSchema: UserTransformedSchema;
};

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  dataPanel: DataPanel;
  dataPanelData: DataPanelData | undefined;
  isEmbed: boolean;
  loading?: boolean;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  onDownloadPanelSpreadsheet: (
    fileFormat: ExportSpreadsheetType,
    schema?: UserTransformedSchema,
  ) => void;
  onDownloadPanelPdf: (
    adHocOperationInstructions: AdHocOperationInstructions,
    schema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  secondaryData: DatasetRow[];
  schema: DatasetSchema;
  updateDataTableOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  visualizeOperation: VisualizeOperation;
  variables: DashboardVariableMap;
};

export const ReportBuilder: FC<Props> = ({
  adHocOperationInstructions,
  dataPanel,
  isEmbed,
  loading,
  datasetData,
  datasetNamesToId,
  onAdHocFilterInfoUpdate,
  onAdHocSortOrPageUpdate,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  secondaryData,
  schema,
  updateDataTableOperation: updateVisualizeOperation,
  visualizeOperation,
  variables,
  dataPanelData,
}) => {
  const [currentStep, setCurrentStep] = useState<ReportBuilderStep>(REPORT_BUILDER_STEP_ORDER[0]);
  const [reportConfiguration, setReportConfiguration] = useState<ReportConfiguration>({
    userTransformedSchema: getReportBuilderUserTransformedSchema(
      schema,
      visualizeOperation.instructions.VISUALIZE_TABLE,
    ),
  });

  useEffect(
    function checkIfColumnsRemovedFromSchema() {
      const maybeChangedSchema = getTableChangedSchema(
        schema,
        visualizeOperation.instructions.VISUALIZE_TABLE,
      );
      const currentUserTransformedSchema = reportConfiguration.userTransformedSchema;
      let newUserSchema: UserTransformedSchema = [];

      const isColumnAdded = maybeChangedSchema.length > currentUserTransformedSchema.length;
      const isColumnRemoved = maybeChangedSchema.length < currentUserTransformedSchema.length;

      if (isColumnAdded) {
        newUserSchema = produce(currentUserTransformedSchema, (draft) => {
          maybeChangedSchema.forEach((maybeAddedColumn, index) => {
            const isNewlyAddedColumn = !draft.some((col) => col.name === maybeAddedColumn.name);

            if (isNewlyAddedColumn) {
              draft.splice(index, 0, { ...maybeAddedColumn, isVisible: false });
            }
          });
        });
      } else if (isColumnRemoved) {
        newUserSchema = produce(currentUserTransformedSchema, (draft) => {
          draft.forEach((oldCol, index) => {
            const isColumnDeleted = !maybeChangedSchema.some(
              (newCol) => newCol.name === oldCol.name,
            );

            if (isColumnDeleted) {
              draft.splice(index, 1);
            }
          });
        });
      } else {
        return;
      }

      setReportConfiguration({ ...reportConfiguration, userTransformedSchema: newUserSchema });
    },
    [reportConfiguration, schema, visualizeOperation],
  );

  const prevOrderedColumnNames = useRef<string[]>();
  // Check if schema has been resorted in format tab for default sorting in config
  useEffect(() => {
    const orderedColumnNames = visualizeOperation.instructions.VISUALIZE_TABLE.orderedColumnNames;
    if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER &&
      currentStep === ReportBuilderStep.NAME_REPORT &&
      prevOrderedColumnNames.current !== orderedColumnNames
    ) {
      setReportConfiguration({
        ...reportConfiguration,
        userTransformedSchema: sortSchemaByOrderedColumnNames(
          reportConfiguration.userTransformedSchema,
          orderedColumnNames,
        ),
      });
      prevOrderedColumnNames.current = orderedColumnNames;
    }
  }, [
    visualizeOperation.instructions.VISUALIZE_TABLE.orderedColumnNames,
    visualizeOperation.operation_type,
    currentStep,
    prevOrderedColumnNames,
    reportConfiguration,
  ]);

  const pivotInstructions = visualizeOperation.instructions.VISUALIZE_PIVOT_TABLE;

  if (
    visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER &&
    (!pivotInstructions?.colColumn ||
      !pivotInstructions.rowColumn ||
      !pivotInstructions.aggregation)
  ) {
    return <NeedsConfigurationPanel fullHeight instructionsNeedConfiguration />;
  } else if (loading && !reportConfiguration.userTransformedSchema.length) {
    return <NeedsConfigurationPanel fullHeight loading />;
  }

  switch (currentStep) {
    case ReportBuilderStep.START:
      return <StartStep onNext={() => setCurrentStep(ReportBuilderStep.NAME_REPORT)} />;
    case ReportBuilderStep.NAME_REPORT:
      return (
        <NameReportStep
          name={reportConfiguration.name}
          onNext={() => setCurrentStep(ReportBuilderStep.SELECT_CONTENT)}
          updateReportName={(newName) => {
            const newConfiguration = produce(reportConfiguration, (draft) => {
              draft.name = newName;
            });

            setReportConfiguration(newConfiguration);
          }}
        />
      );
    case ReportBuilderStep.SELECT_CONTENT:
      return (
        <SelectContentStep
          isPivot={
            visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER
          }
          onBack={() => setCurrentStep(ReportBuilderStep.NAME_REPORT)}
          onNext={() => setCurrentStep(ReportBuilderStep.PREVIEW)}
          setUserTransformedSchema={(newSchema) => {
            const newConfiguration = produce(reportConfiguration, (draft) => {
              draft.userTransformedSchema = newSchema;
            });

            setReportConfiguration(newConfiguration);
          }}
          userTransformedSchema={reportConfiguration.userTransformedSchema}
        />
      );
    case ReportBuilderStep.PREVIEW:
      return (
        <PreviewStep
          adHocOperationInstructions={adHocOperationInstructions}
          dataPanel={dataPanel}
          dataPanelData={dataPanelData}
          datasetData={datasetData}
          datasetNamesToId={datasetNamesToId}
          isEmbed={isEmbed}
          loading={loading}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          onAdHocSortOrPageUpdate={onAdHocSortOrPageUpdate}
          onBack={() => setCurrentStep(ReportBuilderStep.SELECT_CONTENT)}
          onNext={() => setCurrentStep(ReportBuilderStep.EXPORT)}
          reportName={reportConfiguration.name}
          schema={removeUserDisabledColumns(reportConfiguration.userTransformedSchema)}
          secondaryData={secondaryData}
          updateDataTableOperation={updateVisualizeOperation}
          variables={variables}
        />
      );
    case ReportBuilderStep.EXPORT:
      return (
        <ExportStep
          dataPanelId={dataPanel.id}
          onBack={() => setCurrentStep(ReportBuilderStep.PREVIEW)}
          onDownloadPanelPdf={() =>
            onDownloadPanelPdf(
              adHocOperationInstructions,
              reportConfiguration.userTransformedSchema,
              reportConfiguration.name,
            )
          }
          onDownloadPanelSpreadsheet={(fileFormat) =>
            onDownloadPanelSpreadsheet(fileFormat, reportConfiguration.userTransformedSchema)
          }
          onNext={() => {
            setCurrentStep(ReportBuilderStep.START);

            setReportConfiguration({
              userTransformedSchema: getReportBuilderUserTransformedSchema(
                schema,
                visualizeOperation.instructions.VISUALIZE_TABLE,
              ),
            });
          }}
        />
      );
  }
};
