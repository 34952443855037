import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CustomerReportFilter,
  DATE,
  DATE_RELATIVE_OPTION,
  DATETIME,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
  DEFAULT_DATE_RANGES_SET,
  DEFAULT_DATE_TYPES,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_RELATIVE_PICKER,
  FilterOperator,
  FilterValueDateType,
  FilterValueRelativeDateType,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_OPTIONS_SET,
  Timezones,
} from '@explo/data';

import { Input, sprinkles } from 'components/ds';
import {
  DATE_RELATIVE_OPTIONS,
  DATE_RELATIVE_OPTIONS_BY_ID,
} from 'constants/dataPanelEditorConstants';
import { FilterPopover } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/FilterPopover';
import { DashboardDateRangePickerElement } from 'pages/dashboardPage/dashboardElement/DashboardDateRangePickerElement';
import { DashboardDatepickerElement } from 'pages/dashboardPage/dashboardElement/dashboardDatepickerElement';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { FILTER_OPERATOR_TYPES_BY_ID, FILTER_OPERATORS } from 'types/filterOperations';
import { FilterableColumn, getFilterDefaultOperation } from 'utils/customerReportUtils';
import { newOperatorShouldClearSelectedVariable } from 'utils/dashboardUtils';

import { FilterDropdown } from './FilterDropdown';
import * as styles from './styles.css';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
};

export const DateFilterPopover: FC<Props> = ({ column, clause }) => {
  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );

  const isRelativeDateFilter = RELATIVE_DATE_OPTIONS_SET.has(
    clause?.filterValue as RELATIVE_DATE_OPTIONS,
  );
  const isRelativeDateRangeFilter = DEFAULT_DATE_RANGES_SET.has(
    clause?.filterValue as DEFAULT_DATE_RANGES,
  );

  const [dateType, setDateType] = useState(
    isRelativeDateFilter || isRelativeDateRangeFilter
      ? DEFAULT_DATE_TYPES.RELATIVE
      : DEFAULT_DATE_TYPES.EXACT,
  );

  const [relativeDate, setRelativeDate] = useState<RELATIVE_DATE_OPTIONS | undefined>(
    isRelativeDateFilter ? (clause?.filterValue as RELATIVE_DATE_OPTIONS) : undefined,
  );
  const [relativeDateRange, setRelativeDateRange] = useState<DEFAULT_DATE_RANGES | undefined>(
    isRelativeDateRangeFilter ? (clause?.filterValue as DEFAULT_DATE_RANGES) : undefined,
  );

  const [value, setValue] = useState(
    clause?.filterValue as FilterValueRelativeDateType | undefined,
  );
  const [dateValue, setDateValue] = useState(
    clause?.filterValue as FilterValueDateType | undefined,
  );

  const timezone = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.timezone || Timezones.UTC,
  );

  const oldOperator = usePrevious(currOperator);
  useEffect(() => {
    if (newOperatorShouldClearSelectedVariable(currOperator, oldOperator)) {
      setDateValue(undefined);
      setValue(undefined);
    }
  }, [currOperator, oldOperator]);

  const operator = FILTER_OPERATOR_TYPES_BY_ID[currOperator];
  const renderContent = () => {
    if (FILTER_OPS_NO_VALUE.has(operator.id)) {
      return null;
    }

    if (FILTER_OPS_DATE_RANGE_PICKER.has(operator.id)) {
      return (
        <>
          {/* TODO: Can't use DashboardDateRangePickerElement's dropdown since it uses MUI theming which is not supported by RB web component */}
          <FilterDropdown
            items={defaultDateRangeOptions}
            onSelect={(id) => {
              if (id === CUSTOM_DATE_RANGE) {
                setDateType(DEFAULT_DATE_TYPES.EXACT);
                setRelativeDate(undefined);
              } else {
                const dateRangeId = id as DEFAULT_DATE_RANGES;
                setDateType(DEFAULT_DATE_TYPES.RELATIVE);
                setRelativeDateRange(dateRangeId);
              }
            }}
            placeholder={CUSTOM_DATE_RANGE}
            selectedItem={
              dateType === DEFAULT_DATE_TYPES.RELATIVE
                ? relativeDateRange && {
                    id: relativeDateRange,
                    name:
                      DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[relativeDateRange] ??
                      relativeDateRange,
                  }
                : { id: CUSTOM_DATE_RANGE, name: CUSTOM_DATE_RANGE }
            }
          />

          {dateType === DEFAULT_DATE_TYPES.EXACT ? (
            <DashboardDateRangePickerElement
              noDropdown
              config={{ endDateEndOfDay: true, label: 'Date Range', includeRangeDropdown: true }}
              datasetNamesToId={{}}
              onNewValueSelect={(value) =>
                setDateValue(
                  value
                    ? { startDate: value.startDate.toISO(), endDate: value.endDate.toISO() }
                    : undefined,
                )
              }
              timezone={timezone}
              value={dateValue}
            />
          ) : null}
        </>
      );
    }

    if (FILTER_OPS_DATE_PICKER.has(operator.id)) {
      // It doesn't make sense to pick a time for this specific case
      const hideTimeSelect =
        column.type === DATE &&
        [FilterOperator.DATE_IS, FilterOperator.DATE_IS_NOT].includes(operator.id);

      return (
        <>
          <FilterDropdown
            items={relativeDateOptions}
            onSelect={(id) => {
              if (id === CUSTOM_DATE) {
                setDateType(DEFAULT_DATE_TYPES.EXACT);
                setRelativeDate(undefined);
              } else {
                const dateRangeId = id as RELATIVE_DATE_OPTIONS;
                setDateType(DEFAULT_DATE_TYPES.RELATIVE);
                setRelativeDate(dateRangeId);
              }
            }}
            placeholder={CUSTOM_DATE}
            selectedItem={
              dateType === DEFAULT_DATE_TYPES.RELATIVE
                ? relativeDate && { id: relativeDate, name: relativeDate }
                : { id: CUSTOM_DATE, name: CUSTOM_DATE }
            }
          />
          {dateType === DEFAULT_DATE_TYPES.EXACT ? (
            <DashboardDatepickerElement
              config={{ label: 'Date', hideTimeSelect }}
              datasetNamesToId={{}}
              onNewValueSelect={(value) => {
                setDateValue(value ? { startDate: value.toISO() } : undefined);
              }}
              timezone={timezone}
              value={dateValue?.startDate}
            />
          ) : null}
        </>
      );
    }

    if (FILTER_OPS_RELATIVE_PICKER.has(operator.id)) {
      const relativeValue = (value as FilterValueRelativeDateType | undefined) ?? {};
      const selectedRelative = relativeValue.relativeTimeType
        ? DATE_RELATIVE_OPTIONS_BY_ID[relativeValue.relativeTimeType.id]
        : undefined;

      return (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <div className={sprinkles({ flex: 1 })}>
            <Input
              onChange={(value) => {
                if (value === '') return setValue({ ...relativeValue, number: undefined });

                const newVal = Number(value);
                if (isNaN(newVal)) return;
                setValue({ ...relativeValue, number: newVal });
              }}
              placeholder="Number"
              value={relativeValue.number === undefined ? '' : `${relativeValue.number}`}
            />
          </div>
          <div className={sprinkles({ flex: 1 })}>
            <FilterDropdown
              items={DATE_RELATIVE_OPTIONS}
              onSelect={(relativeType) =>
                setValue({
                  ...relativeValue,
                  relativeTimeType: { id: relativeType as DATE_RELATIVE_OPTION },
                })
              }
              placeholder="Time Unit"
              selectedItem={selectedRelative}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const filterValue = useMemo(() => {
    const isFilterOpsDatePicker = FILTER_OPS_DATE_PICKER.has(operator.id);
    if (isFilterOpsDatePicker || FILTER_OPS_DATE_RANGE_PICKER.has(operator.id)) {
      if (dateType === DEFAULT_DATE_TYPES.RELATIVE) {
        return isFilterOpsDatePicker ? relativeDate : relativeDateRange;
      }
      return dateValue;
    }
    return value;
  }, [dateType, dateValue, operator.id, relativeDate, relativeDateRange, value]);

  return (
    <FilterPopover
      clause={clause}
      column={column}
      operator={currOperator}
      operatorOptions={filterOperations}
      setOperator={setOperator}
      value={filterValue}>
      <div className={styles.dateContent}>{renderContent()}</div>
    </FilterPopover>
  );
};

const filterOperations = FILTER_OPERATORS.filter(
  (op) =>
    (op.supported_column_types.has(DATE) || op.supported_column_types.has(DATETIME)) &&
    (FILTER_OPS_RELATIVE_PICKER.has(op.id) ||
      FILTER_OPS_DATE_RANGE_PICKER.has(op.id) ||
      FILTER_OPS_DATE_PICKER.has(op.id) ||
      FILTER_OPS_NO_VALUE.has(op.id)),
);

const CUSTOM_DATE = 'Custom Date';
const CUSTOM_DATE_RANGE = 'Custom Date Range';

const relativeDateOptions = [
  { id: CUSTOM_DATE, name: CUSTOM_DATE },
  ...Object.values(RELATIVE_DATE_OPTIONS).map((id) => ({
    id,
    name: id,
  })),
];

const defaultDateRangeOptions = [
  { id: CUSTOM_DATE_RANGE, name: CUSTOM_DATE_RANGE },
  ...Object.values(DEFAULT_DATE_RANGES).map((id) => ({
    id,
    name: DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[id] ?? id,
  })),
];
