import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { AllStates } from 'reducers/rootReducer';
import {
  Metadata,
  sendAnalyticsEvent,
  shouldSendAnalytics,
  isAnalyticsEventSelected,
  sendErrorEvent,
} from 'telemetry/telemetryUtils';

/** Error and Analytics Telemetry */

type Thunk = ThunkAction<void, AllStates, unknown, AnyAction>;

export const sendAnalyticsEventThunk =
  (eventName: ReportedAnalyticActionTypes, metadata?: Metadata): Thunk =>
  async (_, getState) => {
    const {
      analyticsMetadata,
      analyticsToken,
      visitorId,
      embedType,
      analyticsProperties,
      environment,
      shouldSendAnalyticsJSEvents,
      reportActions,
    } = getState().analytics;

    if (
      !analyticsToken ||
      !analyticsMetadata ||
      !visitorId ||
      !embedType ||
      !shouldSendAnalytics(embedType)
    )
      return;

    sendAnalyticsEvent(
      visitorId,
      embedType,
      analyticsToken,
      eventName,
      analyticsMetadata,
      metadata,
      analyticsProperties,
      environment,
      shouldSendAnalyticsJSEvents && isAnalyticsEventSelected(reportActions ?? [], eventName),
    );
  };

export const sendErrorEventThunk =
  (error: unknown, metadata?: Metadata): Thunk =>
  async (_, getState) => {
    const {
      analyticsMetadata,
      visitorId,
      embedType,
      analyticsProperties,
      environment,
      shouldSendErrorJSEvents,
    } = getState().analytics;

    if (!analyticsMetadata || !visitorId || !embedType || !shouldSendAnalytics(embedType)) return;

    if (shouldSendErrorJSEvents)
      sendErrorEvent(
        error,
        visitorId,
        embedType,
        analyticsMetadata,
        metadata,
        analyticsProperties,
        environment,
      );
  };
