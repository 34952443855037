import { ComputedView } from '@explo-tech/fido-api';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { IconName } from 'components/ds/Icon';
import { CardItem } from 'components/PanelComponents/CardItem';
import { SettingHeader } from 'components/SettingHeader';
import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { BlockDatasetDeletionModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/BlockDatasetDeletionModal';
import { getUsageText } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { deleteVersionedComputedViewReference } from 'reducers/dashboardEditConfigReducer';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';
import { getDashboardEditConfigWithDrilldowns } from 'reducers/selectors';
import * as RD from 'remotedata';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';
import { DASHBOARD_LAYOUT_CONFIG, DashboardElement, SelectElemConfig } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { GlobalDatasetVersionComparisonModal } from './GlobalDatasetVersionComparisonModal';

interface Props {
  globalDatasets: RD.ResponseData<Record<string, ComputedView>>;
  latestGlobalDatasets: RD.ResponseData<Record<string, ComputedView>>;
  query: string;

  onGlobalDatasetSelected: (globalDataset: ComputedView) => void;
}

type VersionComparisonModalInfo = {
  currentGlobalDataset: ComputedView;
  newestGlobalDataset: ComputedView;
};

export const GlobalDatasetsSection: FC<Props> = ({
  globalDatasets,
  latestGlobalDatasets,
  query,
  onGlobalDatasetSelected,
}) => {
  const dispatch = useDispatch();

  const [globalDatasetToDelete, setGlobalDatasetToDelete] = useState<ComputedView | null>(null);
  const [versionComparisonModalInfo, setVersionComparisonModalInfo] = useState<
    VersionComparisonModalInfo | undefined
  >();

  const { enableDataLibrary, dataPanels, editableSectionCharts, elements } = useSelector(
    (state: ReduxState) => {
      return {
        enableDataLibrary: state.currentUser.team?.feature_flags.enable_data_library,
        dataPanels: getDashboardEditConfigWithDrilldowns(state)?.data_panels ?? {},
        editableSectionCharts:
          getDashboardEditConfigWithDrilldowns(state)?.editable_section?.charts ?? {},
        elements: getDashboardEditConfigWithDrilldowns(state)?.elements ?? {},
      };
    },
    shallowEqual,
  );

  const globalDatasetDataPanelUsageMap: Map<string, Set<DataPanelTemplate>> = useMemo(
    () => createDatasetDataPanelUsageMap(dataPanels),
    [dataPanels],
  );

  const globalDatasetEditableSectionChartUsageMap: Map<string, Set<DataPanelTemplate>> = useMemo(
    () =>
      createDatasetDataPanelUsageMap(
        Object.entries(editableSectionCharts).reduce(
          (dataPanelMap, [chartId, editableSectionChart]) => {
            dataPanelMap[chartId] = editableSectionChart.data_panel;
            return dataPanelMap;
          },
          {} as Record<string, DataPanelTemplate>,
        ),
      ),
    [editableSectionCharts],
  );

  const deletionDatasetDependentDataPanelNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(globalDatasetDataPanelUsageMap.get(globalDatasetToDelete.id ?? '') ?? []).map(
          (dataPanel) => dataPanel.provided_id,
        )
      : [];
  }, [globalDatasetToDelete, globalDatasetDataPanelUsageMap]);

  const deletionEditableSectionDependentDataPanelNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(
          globalDatasetEditableSectionChartUsageMap.get(globalDatasetToDelete.id ?? '') ?? [],
        ).map((dataPanel) => dataPanel.provided_id)
      : [];
  }, [globalDatasetToDelete, globalDatasetEditableSectionChartUsageMap]);

  const globalDatasetToElementUsageMap = useMemo(() => {
    const datasetToElementUsageMap: Map<string, Set<DashboardElement>> = new Map();
    Object.values(elements).forEach((element) => {
      if (!SELECT_ELEMENT_SET.has(element.element_type)) {
        return;
      }

      const selectElementConfig = element.config as SelectElemConfig;
      const valuesConfig = selectElementConfig.valuesConfig;
      if (!valuesConfig.queryGlobalDatasetReference) {
        return;
      }

      const globalDatasetId = valuesConfig.queryGlobalDatasetReference.id;
      if (!datasetToElementUsageMap.has(globalDatasetId)) {
        datasetToElementUsageMap.set(globalDatasetId, new Set());
      }
      datasetToElementUsageMap.get(globalDatasetId)?.add(element);
    });

    return datasetToElementUsageMap;
  }, [elements]);

  const deletionDependentElementNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(globalDatasetToElementUsageMap.get(globalDatasetToDelete.id ?? '') ?? [])
          .filter((element) => element.elemLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY)
          .map((element) => element.name)
      : [];
  }, [globalDatasetToDelete, globalDatasetToElementUsageMap]);

  const deletionDependentStickyHeaderElementNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(globalDatasetToElementUsageMap.get(globalDatasetToDelete.id ?? '') ?? [])
          .filter((element) => element.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER)
          .map((element) => element.name)
      : [];
  }, [globalDatasetToDelete, globalDatasetToElementUsageMap]);

  const filteredGlobalDatasets = useMemo(() => {
    if (!RD.isSuccess(globalDatasets)) {
      return [];
    }

    const globalDatasetsData = globalDatasets.data;
    return Object.values(globalDatasetsData).filter((globalDataset) =>
      globalDataset.name.toLowerCase().includes(query.toLowerCase()),
    );
  }, [globalDatasets, query]);

  if (!enableDataLibrary) {
    return null;
  }

  return (
    <>
      <SettingHeader name="Global datasets" />
      {filteredGlobalDatasets.map((globalDataset) => {
        const datasetName = globalDataset.name;
        const alertProps = RD.isSuccess(latestGlobalDatasets)
          ? getAlertPropsForGlobalDataset(
              globalDataset,
              latestGlobalDatasets.data[globalDataset.id ?? ''],
              (currentGlobalDataset, newestGlobalDataset) => {
                setVersionComparisonModalInfo({
                  currentGlobalDataset,
                  newestGlobalDataset,
                });
              },
            )
          : undefined;

        return (
          <CardItem
            alert={alertProps}
            key={`global-dataset-navbar-item-${globalDataset.id}`}
            leftIcon="globe"
            name={datasetName}
            onClick={() => {
              onGlobalDatasetSelected(globalDataset);
            }}
            onDelete={() => {
              setGlobalDatasetToDelete(globalDataset);
            }}
            onMouseEntered={() => {
              const dataPanelsDependentOnGlobalDataset =
                globalDatasetDataPanelUsageMap.get(globalDataset.id ?? '') ?? new Set();
              const dependentDataPanelIds: Set<string> = new Set();
              dataPanelsDependentOnGlobalDataset.forEach((dataPanel) => {
                dependentDataPanelIds.add(dataPanel.id);
              });
              dispatch(setHighlightedDataPanelIds(dependentDataPanelIds));
            }}
            onMouseLeave={() => {
              dispatch(setHighlightedDataPanelIds(new Set()));
            }}
            secondaryText={getGlobalDatasetsUsageText(
              globalDatasetDataPanelUsageMap,
              globalDatasetEditableSectionChartUsageMap,
              globalDatasetToElementUsageMap,
              globalDataset.id ?? '',
            )}
          />
        );
      })}
      {globalDatasetToDelete ? (
        deletionDatasetDependentDataPanelNames.length === 0 ? (
          <DeleteResourceConfirmationModal
            confirmationButtonText="Delete reference"
            onClose={() => setGlobalDatasetToDelete(null)}
            onDelete={() => {
              dispatch(deleteVersionedComputedViewReference(globalDatasetToDelete.id ?? ''));
            }}
            resourceName={globalDatasetToDelete.name}
            titleText={`Type DELETE to remove the reference to global dataset ${globalDatasetToDelete.name}`}
          />
        ) : (
          <BlockDatasetDeletionModal
            dataPanelProvidedIds={deletionDatasetDependentDataPanelNames}
            editableSectionDataPanelProvidedIds={deletionEditableSectionDependentDataPanelNames}
            elementNames={deletionDependentElementNames}
            onClose={() => setGlobalDatasetToDelete(null)}
            stickyHeaderElementNames={deletionDependentStickyHeaderElementNames}
          />
        )
      ) : null}
      {versionComparisonModalInfo ? (
        <GlobalDatasetVersionComparisonModal
          currentGlobalDataset={versionComparisonModalInfo.currentGlobalDataset}
          newestGlobalDataset={versionComparisonModalInfo.newestGlobalDataset}
          onClose={() => setVersionComparisonModalInfo(undefined)}
        />
      ) : null}
    </>
  );
};

const getGlobalDatasetsUsageText = (
  datasetDataPanelUsage: Map<string, Set<DataPanelTemplate>>,
  datasetEditableSectionChartUsage: Map<string, Set<DataPanelTemplate>>,
  datasetElementUsage: Map<string, Set<DashboardElement>>,
  datasetId: string,
) => {
  const dataPanelsInUse = datasetDataPanelUsage.get(datasetId);
  const editableSectionChartsInUse = datasetEditableSectionChartUsage.get(datasetId);
  const totalNumChartsInUse =
    (dataPanelsInUse?.size ?? 0) + (editableSectionChartsInUse?.size ?? 0);
  const elementsInUse = datasetElementUsage.get(datasetId);

  return getUsageText(elementsInUse?.size ?? 0, totalNumChartsInUse);
};

const getAlertPropsForGlobalDataset = (
  globalDataset: ComputedView,
  newestGlobalDataset: ComputedView | undefined,
  onVersionMismatchButtonClicked: (
    currentGlobalDataset: ComputedView,
    newestGlobalDataset: ComputedView,
  ) => void,
) => {
  const isGlobalDatasetDeleted = !newestGlobalDataset;
  if (isGlobalDatasetDeleted) {
    return {
      tooltipText: 'Global dataset deleted from data library. Referencing previous version.',
      icon: 'circle-exclamation' as IconName,
    };
  }

  const newestGlobalDatasetVersionId = newestGlobalDataset.versionId;
  const hasVersionMismatch = globalDataset.versionId !== newestGlobalDatasetVersionId;
  return hasVersionMismatch
    ? {
        tooltipText: 'Newer version available, click to update',
        icon: 'circle-up' as IconName,
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          onVersionMismatchButtonClicked(globalDataset, newestGlobalDataset);
        },
      }
    : undefined;
};

const createDatasetDataPanelUsageMap = (
  dataPanels: Record<string, DataPanelTemplate>,
): Map<string, Set<DataPanelTemplate>> => {
  const datasetToDataPanelUsageMap = new Map();
  Object.values(dataPanels).forEach((dataPanel) => {
    if (!dataPanel.globalDatasetReference) {
      return;
    }

    const globalDatasetId = dataPanel.globalDatasetReference.id;
    if (!datasetToDataPanelUsageMap.has(globalDatasetId)) {
      datasetToDataPanelUsageMap.set(globalDatasetId, new Set());
    }
    datasetToDataPanelUsageMap.get(globalDatasetId)?.add(dataPanel);
  });

  return datasetToDataPanelUsageMap;
};
