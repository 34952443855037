import produce from 'immer';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { Switch, Input, sprinkles } from 'components/ds';
import { MAX_ROWS_TO_PREVIEW, MAX_ROWS_PER_CELL } from 'constants/dataConstants';
import { BaseTableInstructions, VisualizeTableInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { ReduxState } from 'reducers/rootReducer';
import { TABLE_ROW_HEIGHT, ROW_LEVEL_ACTIONS_ROW_HEIGHT } from 'styles/useBaseDataTableStyles';

type Props = {
  instructions: BaseTableInstructions | VisualizeTableInstructions;
  visualizationType: OPERATION_TYPES;

  isRowLevelActionsEnabled?: boolean;
};

export const TableStylingConfig: FC<Props> = ({
  instructions,
  visualizationType,
  isRowLevelActionsEnabled,
}) => {
  const dispatch = useDispatch();

  const { enableNewGrid, useFido } = useSelector(
    (state: ReduxState) => ({
      enableNewGrid: !!state.currentUser?.team?.feature_flags.enable_new_grid,
      useFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const minRowHeight = isRowLevelActionsEnabled ? ROW_LEVEL_ACTIONS_ROW_HEIGHT : TABLE_ROW_HEIGHT;

  const maxRowsPerCellInput = (
    <Input
      className={configInputClass}
      defaultValue={String(instructions.maxRowsPerCell || MAX_ROWS_PER_CELL)}
      label={{
        text: 'Max Rows Per Cell',
        infoText:
          'This determines the maximum number of rows displayed in each cell before the text is truncated.',
      }}
      onSubmit={(newMaxRowsPerCellString: string) => {
        const newMaxRowsPerCell = parseInt(newMaxRowsPerCellString);

        if (newMaxRowsPerCell < 0 || newMaxRowsPerCell > 500 || isNaN(newMaxRowsPerCell)) return;

        const newInstructions = produce(instructions, (draft) => {
          draft.maxRowsPerCell = newMaxRowsPerCell;
        });

        dispatch(updateVisualizeOperation(newInstructions, visualizationType));
      }}
    />
  );

  return (
    <>
      <SettingHeader name="Styling" />
      <div className={configRootClass}>
        <div className={subHeaderClass}>Columns</div>
        <div className={subSectionClass}>
          <Switch
            className={configInputClass}
            label="Column lines"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isColumnLinesEnabled = !draft.isColumnLinesEnabled;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isColumnLinesEnabled}
          />
          <Switch
            className={configInputClass}
            label="Bold column headers"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isColumnHeadersBolded = !draft.isColumnHeadersBolded;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isColumnHeadersBolded}
          />
          <Switch
            className={configInputClass}
            label="Bold first column"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isFirstColumnBolded = !draft.isFirstColumnBolded;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isFirstColumnBolded}
          />
          <Switch
            className={configInputClass}
            label="Freeze first column"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isFirstColumnFrozen = !draft.isFirstColumnFrozen;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isFirstColumnFrozen}
          />
          {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE && useFido ? (
            <Switch
              className={configInputClass}
              label="Show column totals"
              onChange={() => {
                const newInstructions = produce(instructions, (draft) => {
                  if (visualizationType === OPERATION_TYPES.VISUALIZE_TABLE)
                    (draft as VisualizeTableInstructions).showColumnTotals = !(
                      draft as VisualizeTableInstructions
                    ).showColumnTotals;
                });

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
              switchOn={(instructions as VisualizeTableInstructions).showColumnTotals}
            />
          ) : null}
        </div>
        <div className={subHeaderClass}>Rows</div>
        <div className={subSectionClass}>
          <Switch
            className={configInputClass}
            label="Row lines"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isRowLinesDisabled = !draft.isRowLinesDisabled;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={!instructions.isRowLinesDisabled}
          />

          <Input
            className={configInputClass}
            defaultValue={Math.max(instructions.rowHeight || minRowHeight, minRowHeight).toString()}
            label={{
              text: 'Row Height',
              infoText: `The minimum row height is ${minRowHeight}. If cell text is wrapped, this will act as the minimum row height. We recommend choosing a value that will result in consistent heights for the wrapped text.`,
            }}
            onSubmit={(newHeightString: string) => {
              const newHeight = parseInt(newHeightString);

              if (newHeight < minRowHeight || isNaN(newHeight)) return;

              const newInstructions = produce(instructions, (draft) => {
                draft.rowHeight = newHeight;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            placeholder="Enter a row height"
          />
          {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ||
          visualizationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER ? (
            <Input
              className={configInputClass}
              defaultValue={String(instructions.rowsPerPage || MAX_ROWS_TO_PREVIEW)}
              label={{
                text: 'Rows Per Page',
                infoText:
                  'This controls how many rows are loaded each time a user pages through the table.',
              }}
              onSubmit={(newRowsPerPageString: string) => {
                const newRowsPerPage = parseInt(newRowsPerPageString);

                if (newRowsPerPage < 0 || newRowsPerPage > 500 || isNaN(newRowsPerPage)) return;

                const newInstructions = produce(instructions, (draft) => {
                  draft.rowsPerPage = newRowsPerPage;
                });

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
              placeholder="Enter a number of rows"
            />
          ) : null}

          <Switch
            className={configInputClass}
            label={{
              text: 'Group rows visually by first column',
              infoText: 'This will disable sorting and column editing for your table.',
            }}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.shouldVisuallyGroupByFirstColumn = !draft.shouldVisuallyGroupByFirstColumn;

                if (draft.shouldVisuallyGroupByFirstColumn) {
                  draft.isColumnSortingDisabled = true;
                  if (visualizationType === OPERATION_TYPES.VISUALIZE_TABLE)
                    (draft as VisualizeTableInstructions).isSchemaCustomizationEnabled = false;
                }
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.shouldVisuallyGroupByFirstColumn}
          />
        </div>
        {!enableNewGrid ? (
          <>
            <div className={subHeaderClass}>Cells</div>
            <Switch
              className={configInputClass}
              label={{
                text: 'Wrap text',
                infoText:
                  'We recommend being able to provide a uniform row height if this feature is used. Some features may not work as intended while this is turned on.',
              }}
              onChange={() => {
                const newInstructions = produce(instructions, (draft) => {
                  draft.shouldTruncateText = !draft.shouldTruncateText;
                });

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
              switchOn={!instructions.shouldTruncateText}
            />
            {!instructions.shouldTruncateText ? maxRowsPerCellInput : null}
          </>
        ) : null}
      </div>
    </>
  );
};

const subSectionClass = sprinkles({
  marginBottom: 'sp2.5',
});

const subHeaderClass = sprinkles({
  color: 'gray12',
  heading: 'subtitle',
  marginBottom: 'sp1.5',
  paddingX: 'sp1.5',
  paddingY: 'sp0',
});
