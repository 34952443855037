import cx from 'classnames';
import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { Input } from 'components/ds';
import {
  VisualizeCollapsibleListInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { uniqueId } from 'utils/standard';

type Props = {
  dptProvidedId: string;
  instructions: VisualizeCollapsibleListInstructions;
  visualizationType: OPERATION_TYPES;
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export const CollapsibleListConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();

  const aggCols = instructions.aggregations;

  const updateInstructions = (newInstructions: VisualizeCollapsibleListInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <>
      <SettingHeader
        name="Show Categories"
        switchProps={{
          isOn: !!instructions.showCategories,
          onChange: () => {
            const newInstructions = produce(instructions, (draft) => {
              draft.showCategories = !draft.showCategories;
            });
            updateInstructions(newInstructions);
          },
        }}
      />
      {instructions.showCategories && instructions.rowColumns?.length ? (
        <div className={configRootClass}>
          {instructions.rowColumns.map((col) => (
            <Input
              className={configInputClass}
              defaultValue={col.column.friendly_name || getColDisplayText(col)}
              key={uniqueId(col.column.name)}
              label={getColDisplayText(col)}
              onSubmit={(newValue) => {
                const newInstructions = produce(instructions, (draft) => {
                  const changedCol = (draft.rowColumns || []).find(
                    (newCol) => newCol.column.name === col.column.name,
                  );
                  if (changedCol) changedCol.column.friendly_name = newValue;
                });
                updateInstructions(newInstructions);
              }}
            />
          ))}
        </div>
      ) : null}

      <SettingHeader
        name="Category Column Title"
        switchProps={{
          isOn: !!instructions.categoryColumnTitle?.showTitle,
          onChange: () => {
            const newInstructions = produce(instructions, (draft) => {
              draft.categoryColumnTitle = {
                ...draft.categoryColumnTitle,
                showTitle: !draft.categoryColumnTitle?.showTitle,
              };
            });
            updateInstructions(newInstructions);
          },
        }}
      />
      {instructions?.categoryColumnTitle?.showTitle ? (
        <Input
          showInputButton
          className={cx(configRootClass, configInputClass)}
          defaultValue={instructions.categoryColumnTitle.title}
          label="Column Title"
          onSubmit={(newValue) => {
            const newInstructions = produce(instructions, (draft) => {
              draft.categoryColumnTitle = { ...draft.categoryColumnTitle, title: newValue };
            });
            updateInstructions(newInstructions);
          }}
        />
      ) : null}
      <SettingHeader name="Column Names" />
      <div className={configRootClass}>
        {aggCols?.map((col) => (
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={col.column.friendly_name || getColDisplayText(col)}
            key={uniqueId(`bar-line-labels-${col.column.name}`)}
            label={getColDisplayText(col)}
            onSubmit={(newValue) => {
              const newInstructions = produce(instructions, (draft) => {
                const changedCol = (draft.aggregations || []).find(
                  (newCol) =>
                    newCol.column.name === col.column.name && newCol.agg.id === col.agg.id,
                );
                if (changedCol)
                  changedCol.column.friendly_name = newValue !== '' ? newValue : undefined;
              });
              updateInstructions(newInstructions);
            }}
          />
        ))}
      </div>
    </>
  );
};
