import { WritableDraft } from 'immer/dist/internal';
import { DateTime } from 'luxon';

import {
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
  NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT,
} from '@explo/data';

import {
  DateElemConfig,
  DateOperation,
  DateRangeId,
  DateRangePickerElemConfig,
} from 'types/dashboardTypes';
import { sortBy } from 'utils/standard';

const dateRangeList = Object.values(DEFAULT_DATE_RANGES);
const numDefaultRanges = dateRangeList.length;

type DateRangeWithVisibility = {
  id: DateRangeId;
  name: string;
  isVisible: boolean;
};

export const getSortedDateRangesWithVisibility = (
  config: DateRangePickerElemConfig,
): DateRangeWithVisibility[] => {
  const hiddenSet = new Set(config.hiddenDefaultRanges ?? []);
  const newRangesToShow = new Set(config.newRangesToShow ?? []);
  const ranges = dateRangeList.map((id) => ({
    id,
    name: DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[id] ?? id,
    isVisible: isVisible(id, hiddenSet, newRangesToShow),
  }));
  const presets = Object.entries(config.presetRanges ?? {}).map(([key, value]) => ({
    id: key,
    name: value.name,
    isVisible: true,
  }));
  const combined = [...ranges, ...presets];
  const order = config.defaultRangesOrder;
  if (order?.length) {
    return sortBy(combined, (dateRange) => {
      const index = order.indexOf(dateRange.id);
      return index === -1 ? numDefaultRanges : index;
    });
  }

  return combined;
};

export const getValidDateRanges = (
  config: DateRangePickerElemConfig,
): { id: string; name: string }[] => {
  const sortedRanges = getSortedDateRangesWithVisibility(config);
  return sortedRanges.filter((range) => range.isVisible);
};

// There are two ways for a range to be hidden.
// 1. It is in the hiddenDefaultRanges list
// 2. It is one of the new ranges that are added and have not been enabled by user
export const toggleRangeVisibility = (
  range: DateRangeId,
  isVisible: boolean,
  draft: WritableDraft<DateElemConfig>,
) => {
  if (!draft.hiddenDefaultRanges) draft.hiddenDefaultRanges = [];

  if (isVisible) {
    draft.hiddenDefaultRanges.push(range);
    return;
  }

  // If its one of the new date ranges we have to make sure to include them in the new list
  if (
    NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT.has(range as DEFAULT_DATE_RANGES) &&
    !draft.newRangesToShow?.includes(range)
  ) {
    if (!draft.newRangesToShow) draft.newRangesToShow = [];
    draft.newRangesToShow.push(range);
  } else {
    draft.hiddenDefaultRanges = draft.hiddenDefaultRanges.filter((r) => r !== range);
  }
};

const isVisible = (
  dateRange: DateRangeId,
  hiddenSet: Set<DateRangeId>,
  newRangesToShow: Set<DateRangeId>,
): boolean => {
  if (hiddenSet.has(dateRange)) return false;

  // New ranges that are added have to be explicitly enabled by user to be shown
  return (
    !NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT.has(dateRange as DEFAULT_DATE_RANGES) ||
    newRangesToShow.has(dateRange)
  );
};

function applyOperations(config: DateOperation[], timezone: string) {
  let date = DateTime.local().setZone(timezone);
  config.forEach((operation) => {
    switch (operation.id) {
      case 'now':
        break;
      case 'plus':
        date = date.plus({ [operation.unit]: operation.quantity });
        break;
      case 'minus':
        date = date.minus({ [operation.unit]: operation.quantity });
        break;
      case 'startOf':
        date = date.startOf(operation.unit);
        break;
      case 'endOf':
        date = date.endOf(operation.unit);
        break;
    }
  });
  return date;
}

export const getCustomDateRange = (
  startDateOperations: DateOperation[],
  endDateOperations: DateOperation[],
  timezone: string,
): { startDate: DateTime; endDate: DateTime } => {
  const startDate = applyOperations(startDateOperations, timezone);
  const endDate = applyOperations(endDateOperations, timezone);

  return { startDate, endDate };
};
