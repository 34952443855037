import { FC, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { NumberDisplayOptions, getAggColName, ChartAggregation, FLOAT } from '@explo/data';

import { CustomerReportAgg } from 'actions/customerReportActions';
import { ReportBuilderColConfig } from 'actions/reportBuilderConfigActions';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { sprinkles, Input, TextArea, IconButton, Button, Icon } from 'components/ds';
import * as styles from 'pages/ReportBuilderEditor/DatasetEditor/DatasetCustomAggItem.css';
import {
  updateReportBuilderCustomAggregation,
  updateReportBuilderColConfig,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { isLoading } from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';

type Props = {
  datasetId: string;
  agg: CustomerReportAgg;
  onDelete: (aggId: string) => void;
  startOpen: boolean;
  columnConfig?: ReportBuilderColConfig;
};

export const DatasetCustomAggItem: FC<Props> = ({
  datasetId,
  agg,
  onDelete,
  startOpen,
  columnConfig,
}) => {
  const aggId = agg.column.name;
  const formula = agg.agg.formula;
  const name = columnConfig?.name;
  const description = columnConfig?.description;
  const displayFormatting = columnConfig?.displayFormatting as NumberDisplayOptions | undefined;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(startOpen ?? false);

  const { datasetData, config } = useSelector(
    (state: ReduxState) => ({
      datasetData: datasetId ? state.reportBuilderEdit.datasetData[datasetId] : undefined,
      config: state.reportBuilderEdit.config,
    }),
    shallowEqual,
  );

  const handleChangeConfig = useCallback(
    (config: Partial<ReportBuilderColConfig>) => {
      dispatch(
        updateReportBuilderColConfig({
          datasetId,
          colName: getAggColName(agg),
          config: { isVisible: true, ...config },
        }),
      );
    },
    [agg, datasetId, dispatch],
  );

  const handleChangeAgg = useCallback(
    (formula: string) => {
      if (!aggId) return;
      const agg = { id: ChartAggregation.FORMULA, formula };
      dispatch(updateReportBuilderCustomAggregation({ datasetId, aggId, agg: { agg } }));
    },
    [aggId, datasetId, dispatch],
  );

  const handleSave = useCallback(() => {
    if (!aggId) return;
    const agg = { id: ChartAggregation.FORMULA, formula: formula };
    dispatch(
      fetchAppDataset({
        datasetId,
        viewParams: { limit: 1, aggs: [{ agg, column: { name: aggId, type: FLOAT } }] },
      }),
    );
  }, [aggId, datasetId, dispatch, formula]);

  const canPreview = formula?.trim().length && name?.trim().length;
  const loading = isLoading(config) || datasetData?.loading;

  if (!aggId) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size="md" />
        <div className={styles.headerName}>{name || 'Untitled'}</div>
        {description ? <div className={styles.headerDescription}>{description}</div> : null}
      </div>
      {isOpen ? (
        <div className={styles.editContainer}>
          <Input
            defaultValue={name}
            label="Name"
            onSubmit={(name) => handleChangeConfig({ name })}
            placeholder="Untitled"
          />
          <TextArea
            defaultValue={formula}
            label="SQL Query"
            onSubmit={handleChangeAgg}
            rows={2}
            style={{ resize: 'vertical', fontFamily: 'Source Code Pro' }}
          />
          <TextArea
            defaultValue={description}
            label="Description"
            onSubmit={(description) => handleChangeConfig({ description })}
            rows={2}
            style={{ resize: 'vertical' }}
          />
          <NumberFormatConfig
            column={{ name: aggId, type: FLOAT }}
            displayOptions={displayFormatting}
            operationType="REPORT_BUILDER"
            updateNumberOptions={(newFields) =>
              handleChangeConfig({ displayFormatting: { ...displayFormatting, ...newFields } })
            }
          />
          <div className={sprinkles({ flexItems: 'alignCenterBetween', gap: 'sp1' })}>
            <IconButton
              name="trash"
              onClick={() => onDelete(aggId)}
              tooltipProps={{ text: 'Delete this Custom Aggregation' }}
              variant="destructive"
            />
            <Button
              disabled={!canPreview || loading}
              loading={loading}
              onClick={handleSave}
              tooltipProps={{
                text: !canPreview ? 'Name and SQL Query required' : 'Preview sample data',
              }}>
              Preview
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
