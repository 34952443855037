import { Icon as BPIcon } from '@blueprintjs/core';
import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { DownloadPanelFunc, DownloadSpreadsheetFunc, ExportType } from 'actions/exportActions';
import { DownloadStatus } from 'components/ExportOptions/DownloadStatus';
import { ExportOptionItem } from 'components/ExportOptions/ExportOptionItem';
import { WebShareOption } from 'components/ExportOptions/WebShareOption';
import { Icon, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { EmbedButton, EmbedRadioGroup } from 'components/embed';
import { ExportConfig } from 'constants/types';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { inputContainerBackgroundClass } from 'globalStyles/sharedStyles.css';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { DownloadInfo, clearDownloads } from 'reducers/dashboardLayoutReducer';
import { AllStates } from 'reducers/rootReducer';
import {
  selectEnableEmailExports,
  selectEnableScreenshotExports,
  selectShouldUseJobQueue,
} from 'reducers/selectors';
import { fetchDrilldownDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { isLoading } from 'remotedata';
import { DashboardVariableMap, ExportElemConfig } from 'types/dashboardTypes';
import { WindowState } from 'utils/exportUtils';

import * as styles from './exportStyles.css';

export enum SHARE_METHODS {
  WEB = 'Share to Web',
  DOWNLOAD = 'Download',
  EMAIL = 'Send to email',
}

type ShareMethodType = {
  icon: IconName;
  shareMethod: SHARE_METHODS;
  shouldRender: boolean;
};

export const SHARE_METHOD_TO_I18N_Text: Record<
  SHARE_METHODS,
  'export_share_to_web' | 'export_share_to_download' | 'export_share_to_email'
> = {
  [SHARE_METHODS.WEB]: 'export_share_to_web',
  [SHARE_METHODS.DOWNLOAD]: 'export_share_to_download',
  [SHARE_METHODS.EMAIL]: 'export_share_to_email',
};

type ExportInfo = {
  currentView: SHARE_METHODS | undefined;
  type: ExportType | undefined;
};

export type WebShareProps = {
  config: ExportElemConfig;
  exportVars: DashboardVariableMap;
  isSharedView?: boolean;
};

type Props = {
  windowState: WindowState;
  downloadInfo: DownloadInfo | undefined;
  dataPanelId?: string;
  downloadDashboardImage?: (email?: string) => void;
  downloadDashboardPdf?: (email?: string) => void;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelImage?: DownloadPanelFunc;
  onDownloadPanelPdf?: DownloadPanelFunc;
  webShareProps?: WebShareProps;
  enableDrilldownModal?: boolean;
  exportConfig?: ExportConfig;
};

export const ExportOptions: FC<Props> = ({
  dataPanelId,
  downloadDashboardImage,
  downloadDashboardPdf,
  onDownloadPanelImage,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  downloadInfo,
  webShareProps,
  windowState,
  enableDrilldownModal,
  exportConfig,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ExportOptions');

  const { shouldUseJobQueue, enableScreenshotExports, enableEmailExports } = useSelector(
    (state: AllStates) => ({
      shouldUseJobQueue: selectShouldUseJobQueue(state),
      enableScreenshotExports: selectEnableScreenshotExports(state),
      enableEmailExports: selectEnableEmailExports(state),
    }),
    shallowEqual,
  );

  const [inputEmail, setInputEmail] = useState('');
  const [exportInfo, setExportInfo] = useState<ExportInfo>({
    currentView:
      downloadInfo?.type === 'email'
        ? SHARE_METHODS.EMAIL
        : downloadInfo?.type === 'url'
          ? SHARE_METHODS.DOWNLOAD
          : undefined,
    type: downloadInfo?.exportType,
  });

  const isDownloadLoading = isLoading(downloadInfo?.status);
  const isShareComponent = webShareProps !== undefined;
  const isExportEnabled = isShareComponent || !!exportConfig;

  const areBothShareExportsDisabled =
    webShareProps?.config.disableImageDownload && webShareProps?.config.disablePdfDownload;

  const canEmail = isShareComponent
    ? !areBothShareExportsDisabled
    : !!exportConfig?.enableEmailExport;

  const canDownload = isShareComponent
    ? !areBothShareExportsDisabled
    : !exportConfig?.disableDownloadExport;

  const availableShareMethods = useMemo<ShareMethodType[]>(() => {
    // Don't want to render parent option if there are no sub options, share component only has screenshots options
    const ifShareComponentHasOptions = !isShareComponent || enableScreenshotExports;
    const shareMethods: ShareMethodType[] = [
      {
        icon: 'globe',
        shareMethod: SHARE_METHODS.WEB,
        shouldRender: isShareComponent && !webShareProps?.config?.disableShareLink,
      },
      {
        icon: 'arrow-down-to-bracket',
        shareMethod: SHARE_METHODS.DOWNLOAD,
        shouldRender: isExportEnabled && canDownload && ifShareComponentHasOptions,
      },
      {
        icon: 'envelope',
        shareMethod: SHARE_METHODS.EMAIL,
        shouldRender:
          shouldUseJobQueue &&
          isExportEnabled &&
          canEmail &&
          enableEmailExports &&
          ifShareComponentHasOptions,
      },
    ];
    return shareMethods.filter((method) => method.shouldRender);
  }, [
    canDownload,
    canEmail,
    isExportEnabled,
    isShareComponent,
    shouldUseJobQueue,
    webShareProps?.config?.disableShareLink,
    enableScreenshotExports,
    enableEmailExports,
  ]);

  const getExportFunc = (): DownloadPanelFunc | undefined => {
    switch (exportInfo.type) {
      case ExportType.PDF:
        return isShareComponent ? downloadDashboardPdf : onDownloadPanelPdf;
      case ExportType.Image:
        return isShareComponent ? downloadDashboardImage : onDownloadPanelImage;
      case ExportType.CSV:
        return (email) => onDownloadPanelSpreadsheet?.(ExportType.CSV, email);
      case ExportType.XLSX:
        return (email) => onDownloadPanelSpreadsheet?.(ExportType.XLSX, email);
    }
  };

  const renderOptions = () => {
    const exportOptions: ExportType[] = [];
    if (isShareComponent) {
      if (enableScreenshotExports) {
        if (!webShareProps.config?.disablePdfDownload) exportOptions.push(ExportType.PDF);
        if (!webShareProps.config?.disableImageDownload) exportOptions.push(ExportType.Image);
      }
    } else if (exportConfig) {
      const canDownloadPdf = onDownloadPanelPdf || downloadDashboardPdf;
      if (!exportConfig.disablePdfDownload && canDownloadPdf && enableScreenshotExports) {
        exportOptions.push(ExportType.PDF);
      }
      if (!exportConfig.disableCsvExport) exportOptions.push(ExportType.CSV);
      if (!exportConfig.disableXlsxExport) exportOptions.push(ExportType.XLSX);

      if (exportInfo.currentView === SHARE_METHODS.DOWNLOAD) {
        if (!exportConfig.disableImageExport && onDownloadPanelImage && enableScreenshotExports)
          exportOptions.push(ExportType.Image);
      }

      if (exportInfo.currentView === SHARE_METHODS.EMAIL) {
        if (
          !exportConfig.disableImageExport &&
          onDownloadPanelImage &&
          enableScreenshotExports &&
          enableEmailExports
        )
          exportOptions.push(ExportType.Image);
      }
    }

    if (!exportOptions.length) return null;

    const defaultType = exportOptions.length > 0 ? exportOptions[0] : undefined;

    if (exportInfo.type === undefined) {
      setExportInfo({ currentView: exportInfo.currentView, type: defaultType });
    }

    return (
      <EmbedRadioGroup
        defaultValue={defaultType}
        disabled={isDownloadLoading}
        onValueChange={(newType) => setExportInfo({ ...exportInfo, type: newType as ExportType })}
        renderValue={(value) => value.toLocaleUpperCase()}
        value={exportInfo?.type}
        values={exportOptions}
      />
    );
  };

  const downloadHeader = () => (
    <div className={cx(styles.title, embedSprinkles({ body: 'primary' }))}>
      {isDownloadLoading ? null : (
        <Icon
          className={styles.backButton}
          data-testid="back-button"
          name="arrow-left"
          onClick={() => {
            dispatch(clearDownloads(dataPanelId));
            setExportInfo({ currentView: undefined, type: undefined });
          }}
        />
      )}
      {exportInfo.currentView ? t(SHARE_METHOD_TO_I18N_Text[exportInfo.currentView]) : undefined}
    </div>
  );

  const renderShareBody = () => {
    if (exportInfo.currentView === SHARE_METHODS.WEB && isShareComponent) {
      return (
        <WebShareOption
          onBackButtonClick={() => setExportInfo({ currentView: undefined, type: undefined })}
          webShareProps={webShareProps}
        />
      );
    } else if (
      exportInfo.currentView === SHARE_METHODS.DOWNLOAD ||
      exportInfo.currentView === SHARE_METHODS.EMAIL
    ) {
      const shouldEmail = exportInfo.currentView === SHARE_METHODS.EMAIL;

      const downloadFunc = getExportFunc();
      return (
        <div className={styles.root}>
          {downloadHeader()}
          {renderOptions()}
          {shouldEmail && (
            <InputGroup
              fill
              className={cx(
                sprinkles({ marginRight: 'sp.5' }),
                inputContainerBackgroundClass,
                GLOBAL_STYLE_CLASSNAMES.text.body.input,
              )}
              data-testid="email-input"
              leftElement={<BPIcon icon="envelope" />}
              onInputChange={setInputEmail}
              placeholder={t('export_enter_email')}
              value={inputEmail}
            />
          )}
          <EmbedButton
            fillWidth
            data-testid="export-button"
            disabled={
              isDownloadLoading || !downloadFunc || (shouldEmail && !validator.isEmail(inputEmail))
            }
            onClick={() => downloadFunc?.(shouldEmail ? inputEmail : undefined)}
            variant="primary">
            {shouldEmail ? t('export_send_email') : t('export_download')}
          </EmbedButton>
          <DownloadStatus downloadInfo={downloadInfo} windowState={windowState} />
        </div>
      );
    } else {
      return (
        <div
          className={cx(
            styles.menuContent,
            embedSprinkles({ backgroundColor: 'containerFill', color: 'primaryFont' }),
          )}>
          {enableDrilldownModal && dataPanelId ? (
            <ExportOptionItem
              icon="table"
              onClick={() => dispatch(fetchDrilldownDataThunk({ dataPanelId }))}
              option={t('export_expose_underlying_data')}
            />
          ) : null}
          {availableShareMethods.map(({ icon, shareMethod }) => (
            <ExportOptionItem
              rightArrow
              icon={icon}
              key={shareMethod}
              onClick={() => setExportInfo({ currentView: shareMethod, type: undefined })}
              option={t(SHARE_METHOD_TO_I18N_Text[shareMethod])}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className={cx(
        embedSprinkles({ backgroundColor: 'containerFill', borderRadius: 'container' }),
        sprinkles({ overflow: 'hidden' }), //So that white background border radius doesn't cover outline
      )}>
      {renderShareBody()}
    </div>
  );
};
