import { FC, useState } from 'react';

import { SearchInput } from 'components/SearchInput/SearchInput';
import { Button, sprinkles } from 'components/ds';

import { ComputedView, Folder } from '@explo-tech/fido-api';
import {
  clearAllDataLibraryContents,
  clearPendingChanges,
  ItemPathInfo,
} from 'reducers/dataLibraryReducer';
import { DirectoryPanelSection } from './DirectoryPanelSection';

import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { CommitStatusIcon } from './CommitStatusIcon';
import { ROOT_FOLDER_PATH } from './constants';

interface Props {
  rootFolder: Folder;
  selectedItemPath: ItemPathInfo;
  branchId: string;
  renderCommitButton: boolean;
  commitButtonEnabled: boolean;
  pendingCommitStatus: RD.ResponseData<string>;
  shouldReadFromHead: boolean;
  // A map of paths to whether or not the folder is expanded. No entry means the folder is
  // collapsed.
  expansionState: Map<string, boolean>;

  onComputedViewSelection: (computedView: ComputedView) => void;
  onFolderSelection: (folderId: string, folderPath: string) => void;
  onCommitButtonClicked: () => void;
  onViewCommitHistoryButtonClicked?: () => void;
  setIsExpanded: (folderPath: string, isExpanded: boolean) => void;
}

export const DirectoryPanelContent: FC<Props> = ({
  rootFolder,
  selectedItemPath,
  branchId,
  renderCommitButton,
  commitButtonEnabled,
  pendingCommitStatus,
  shouldReadFromHead,
  expansionState,
  onComputedViewSelection,
  onFolderSelection,
  onCommitButtonClicked,
  onViewCommitHistoryButtonClicked,
  setIsExpanded,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { enableDebugActions } = useSelector((state: ReduxState) => {
    return {
      enableDebugActions: state.currentUser.team?.feature_flags.enable_data_library_debug_actions,
    };
  });

  return (
    <div className={sprinkles({ marginX: 'sp2' })}>
      {renderCommitButton ? (
        <Button
          fillWidth
          className={sprinkles({ marginY: 'sp2' })}
          disabled={!commitButtonEnabled}
          onClick={onCommitButtonClicked}>
          <div>
            <CommitStatusIcon pendingCommitStatus={pendingCommitStatus} />
            <span>Create Commit</span>
          </div>
        </Button>
      ) : null}
      {onViewCommitHistoryButtonClicked ? (
        <Button
          fillWidth
          className={sprinkles({ marginY: 'sp2' })}
          onClick={onViewCommitHistoryButtonClicked}
          variant="secondary">
          View Commit History
        </Button>
      ) : null}
      {enableDebugActions ? (
        <DebugActionsSection clearPendingChangesEnabled={commitButtonEnabled} />
      ) : null}
      <SearchInput
        className={sprinkles({ marginY: 'sp2' })}
        onInputChanged={setSearchQuery}
        searchQuery={searchQuery}
      />
      <DirectoryPanelSection
        branchId={branchId}
        expansionState={expansionState}
        folder={RD.Success(rootFolder)}
        folderId={rootFolder.id ?? ''}
        folderPath={ROOT_FOLDER_PATH}
        onComputedViewSelection={onComputedViewSelection}
        onFolderSelection={onFolderSelection}
        searchQuery={searchQuery}
        selectedItemPath={selectedItemPath}
        setIsExpanded={setIsExpanded}
        shouldReadFromHead={shouldReadFromHead}
      />
    </div>
  );
};

interface DebugActionsSectionProps {
  clearPendingChangesEnabled: boolean;
}

const DebugActionsSection: FC<DebugActionsSectionProps> = ({ clearPendingChangesEnabled }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Button
        fillWidth
        className={sprinkles({ marginY: 'sp2' })}
        disabled={!clearPendingChangesEnabled}
        onClick={() => {
          dispatch(clearPendingChanges());
        }}>
        <div>Clear Pending Changes</div>
      </Button>
      <Button
        fillWidth
        onClick={() => {
          dispatch(clearAllDataLibraryContents());
        }}
        variant="destructive">
        Clear all library contents
      </Button>
    </>
  );
};
