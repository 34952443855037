import { RumInitConfiguration } from '@datadog/browser-rum';

import { getEnvironment, isEnvironmentSecure } from 'utils/environmentUtils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_RUM: any;
  }
}

const SAMPLE_RATE = 100;

const shouldInitializeDataDog = !!window.DD_RUM;

const shouldReportErrors =
  ['staging', 'production'].includes(getEnvironment()) &&
  process.env.REACT_APP_ENVIRONMENT !== 'production-medme' &&
  !isEnvironmentSecure();

const initDataDog = () => {
  if (!shouldInitializeDataDog || !shouldReportErrors) return;

  const sharedConfig = {
    site: 'datadoghq.com',
    proxyUrl: `${process.env.REACT_APP_API_URL}telemetry/events/`,
    // This needs to match EMBEDDO_ENVIRONMENT in Heroku
    // REACT_APP_ENVIRONMENT should match in every case except for preview envs
    // REACT_APP_ENV_TAG is generated for preview envs only
    env: process.env.REACT_APP_ENV_TAG ?? process.env.REACT_APP_ENVIRONMENT,
    version: process.env.PKG_VERSION,
    sampleRate: SAMPLE_RATE,
    allowedTracingOrigins: [process.env.REACT_APP_API_URL ?? ''],
    tracingSampleRate: SAMPLE_RATE,
  };

  const appConfig: RumInitConfiguration = {
    ...sharedConfig,
    applicationId: '465ae197-1316-43f4-b293-a8ba17e7e945',
    clientToken: 'pub4332059a181bb617916141bd0751432b',
    service: 'embeddo',
    proxyUrl: `${process.env.REACT_APP_API_URL}telemetry/events/`,
    sessionReplaySampleRate: SAMPLE_RATE,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  };

  window.DD_RUM.init(appConfig);
  window.DD_RUM.startSessionReplayRecording();
};

type RumUser = {
  email?: string;
  endUserId?: number;
  endUserName?: string;
  teamId?: number;
  teamName?: string;
};

const setUser = (user: RumUser) => {
  if (!shouldReportErrors) return;

  if (window.DD_RUM) window.DD_RUM.setUser(user);
};

const clearUser = () => {
  if (!shouldReportErrors) return;

  if (window.DD_RUM) window.DD_RUM.clearUser();
};

const sendDatadogError = (error: unknown, errorInfo?: object) => {
  if (!shouldReportErrors) return;

  if (window.DD_RUM) {
    window.DD_RUM.addError(error, { ...errorInfo, manual: true });
  } else {
    // TODO: Implement manual error logging
  }
};

export { initDataDog, sendDatadogError, setUser, clearUser };
