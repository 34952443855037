import { DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { Dataset } from 'actions/datasetActions';
import {
  SchemaChange,
  UserTransformedSchema,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { CustomerTableState } from 'types/customerDashboardStateTypes';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import { keyBy, cloneDeep, sortBy } from 'utils/standard';

import { getSortedUserTransformedDrilldownColumns } from './drilldownDatasetUtils';
import { titleCase } from './graphUtils';

export const getSortedUserTransformedSchema = (
  schema: DatasetSchema | undefined,
  vizOp: VisualizeOperation,
  // Dataset should only be passed in for drilldown modal
  dataset?: Dataset | undefined,
  tableState?: CustomerTableState | undefined,
): UserTransformedSchema => {
  if (dataset?.drilldownColumnConfigs) {
    return getSortedUserTransformedDrilldownColumns(schema ?? [], dataset);
  }

  const userTransformedSchema = getUserTransformedSchema(schema, vizOp, tableState);

  if (vizOp.operation_type !== OPERATION_TYPES.VISUALIZE_TABLE) return userTransformedSchema;

  const schemaToOrderOn = tableState?.visibleSchema.length
    ? tableState.visibleSchema
    : vizOp.instructions.VISUALIZE_TABLE.orderedColumnNames;

  return sortSchemaByOrderedColumnNames(userTransformedSchema, schemaToOrderOn);
};

const getUserTransformedSchema = (
  schema: DatasetSchema | undefined,
  vizOp: VisualizeOperation,
  tableState: CustomerTableState | undefined,
): UserTransformedSchema => {
  if (!schema?.length) return [];

  const changedSchema = getChangedSchema(schema, vizOp);
  const changeSchemaList = vizOp.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');

  const tableStateSchema = tableState?.visibleSchema.length
    ? new Set(tableState.visibleSchema)
    : undefined;

  const userTransformedSchema = changedSchema.map((column) => ({
    ...column,
    friendly_name: tableState?.updatedColNames[column.name] || column.friendly_name,
    isVisible: tableStateSchema
      ? tableStateSchema.has(column.name)
      : !changeSchemaDictionary[column.name]?.hideCol,
  }));

  return userTransformedSchema;
};

const pivotOperations = new Set([
  OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

export const getChangedSchema = (
  schema: DatasetSchema,
  { instructions, operation_type }: VisualizeOperation,
) => {
  const tableInstructions =
    (pivotOperations.has(operation_type) ? instructions.VISUALIZE_PIVOT_TABLE : undefined) ??
    instructions.VISUALIZE_TABLE;

  return getTableChangedSchema(schema, tableInstructions);
};

export const getTableChangedSchema = (
  schema: DatasetSchema,
  tableInstructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
) => {
  if (!schema.length) return [];
  //Produce (can't change schema from state)
  const clonedSchema = cloneDeep(schema);

  const changeSchemaList = tableInstructions.changeSchemaList ?? [];
  const changedSchema: DatasetSchema = [];
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  clonedSchema.forEach((columnInfo) => {
    const col = changeSchemaDictionary[columnInfo.name];
    const keepCol = col?.keepCol ?? true;
    if (col) {
      if (keepCol) {
        if (col.newColName !== null && col.newColName !== '') {
          columnInfo.friendly_name = col.newColName;
        } else {
          columnInfo.friendly_name = titleCase(columnInfo.name);
        }
        changedSchema.push(columnInfo);
      }
    } else {
      columnInfo.friendly_name = titleCase(columnInfo.name);
      changedSchema.push(columnInfo);
    }
  });
  return changedSchema;
};

export const getReportBuilderUserTransformedSchema = (
  schema: DatasetSchema,
  tableInstructions: VisualizeTableInstructions,
): UserTransformedSchema => {
  return getTableChangedSchema(schema, tableInstructions).map((col) => ({
    ...col,
    isVisible: false,
  }));
};

export function sortSchemaByOrderedColumnNames<T extends DatasetSchema | UserTransformedSchema>(
  schema: T,
  orderedColumnNames: string[] | undefined,
): T {
  if (!orderedColumnNames?.length) return schema;

  // Lodash sort by is messing up type so have to put as T
  return sortBy(schema, (info) => {
    const index = orderedColumnNames.findIndex((name) => name === info.name);
    return index === -1 ? schema.length : index;
  }) as T;
}

// Separate function because looking for key col
export function sortSchemaChangeByOrderedColumnNames(
  schema: SchemaChange[],
  orderedColumnNames: string[] | undefined,
) {
  if (!orderedColumnNames?.length) return schema;

  return sortBy(schema, (info) => {
    const index = orderedColumnNames.findIndex((name) => name === info.col);
    return index === -1 ? schema.length : index;
  });
}
