import { FC, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';

import { ManageDataTablesPage } from './manageDataTablesPage';
import { ManageFidoDataTablesPage } from './manageFidoDataTablesPage';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';

export const ManageDataTablesPageWrapper: FC = () => {
  const {
    params: { dataSourceId },
  } = useRouteMatch<{ dataSourceId: string }>();

  const { shouldUseFido, dataSources } = useSelector(
    (state: ReduxState) => ({
      shouldUseFido: !!state.currentUser.team?.feature_flags.use_fido,
      dataSources: state.dataSource.dataSources,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (RD.isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  if (!RD.isSuccess(dataSources)) return <ExploLoadingSpinner />;

  const isDataSourceOnFido = dataSources.data.find(
    (d) => d.id.toString() === dataSourceId,
  )?.fido_id;

  return shouldUseFido && !!isDataSourceOnFido ? (
    <ManageFidoDataTablesPage />
  ) : (
    <ManageDataTablesPage />
  );
};
