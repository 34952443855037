import cx from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { Timezones } from '@explo/data';

import { EXPORT_TYPE_NAME_MAP, ExportType } from 'actions/exportActions';
import { sprinkles, Tag } from 'components/ds';
import {
  EmbedButton,
  EmbedInput,
  EmbedModalContent,
  EmbedModalFooter,
  EmbedRadioGroup,
} from 'components/embed';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmailFrequency } from 'constants/emailFrequency';
import { Settings } from 'luxon';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ExportScheduler.css';
import {
  cadenceOptions,
  getEmailCadence,
  timeOptions,
  weekdayOptions,
  weekOfMonthOptions,
} from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/utils';
import { ImageExportLimitationWarning } from 'pages/ReportBuilder/ReportView/ExportButton/ImageExportLimitationWarning';
import {
  allExportOptions,
  DEFAULT_EXPORT_TYPE,
  imageExportOptions,
  tabularExportOptions,
} from 'pages/ReportBuilder/ReportView/ExportButton/types';
import { getEnableScreenshotExportReportBuilder } from 'reducers/selectors';
import { isError, isLoading, isSuccess } from 'remotedata';
import { DRAFT_EMAIL_ID, EmailBody, EmailCadence } from 'reportBuilderContent/exportTypes';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { sendTestDraftExportThunk } from 'reportBuilderContent/thunks/exportThunks';
import { getReportBuilderTimezone } from 'utils/customerReportUtils';
import { getTimezoneOptions } from 'utils/emailUtils';

type Props = {
  createExportCadence: (email: EmailBody, onSuccess: () => void, onFailure: () => void) => void;
  editingEmail: EmailCadence | undefined;
  goToManage: () => void;
  resourceName: string;
  updateExportCadence: (
    emailId: string,
    email: EmailBody,
    onSuccess: () => void,
    onFailure: () => void,
  ) => void;
};

export function ExportScheduler({
  createExportCadence,
  editingEmail,
  goToManage,
  resourceName,
  updateExportCadence,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { scheduledExportState, enableScreenshotExport, exportEmailId, reportBuilderTimezone } =
    useSelector(
      (state: ReportBuilderReduxState) => ({
        scheduledExportState: state.reportEditing.scheduledExport,
        enableScreenshotExport: getEnableScreenshotExportReportBuilder(state),
        exportEmailId: state.reportEditing.exportEmailId,
        reportBuilderTimezone: getReportBuilderTimezone(state.embeddedReportBuilder),
      }),
      shallowEqual,
    );

  const [recipients, setRecipients] = useState<string[]>(editingEmail?.recipients ?? []);
  const [recipientError, setRecipientError] = useState(false);
  const [currRecipient, setCurrRecipient] = useState('');
  const [subject, setSubject] = useState(
    editingEmail?.subject ?? `View the latest from ${resourceName}`,
  );
  const [message, setMessage] = useState(editingEmail?.message ?? '');
  const [exportType, setExportType] = useState(editingEmail?.export_type ?? DEFAULT_EXPORT_TYPE);
  const [cadence, setCadence] = useState(EmailFrequency.DAILY);
  const [dayOfWeek, setDayOfWeek] = useState(editingEmail?.day_of_week ?? 0);
  const [weekOfMonth, setWeekOfMonth] = useState(editingEmail?.week_of_month ?? 0);
  const [time, setTime] = useState(720);
  const [saveLoading, setSaveLoading] = useState(false);

  // Timezone input defaults to 1. Existing email timezone, 2. This ReportBuilder's timezone (defaults to UTC), 3. UTC
  const [timezone, setTimezone] = useState(
    editingEmail?.timezone ||
      (reportBuilderTimezone === Timezones.UTC ? UTC_OPTION : reportBuilderTimezone) ||
      UTC_OPTION,
  );

  const timezoneOptions = useMemo(
    () => getTimezoneOptions().map((opt) => ({ value: opt.id, name: opt.name })),
    [],
  );

  useEffect(() => {
    if (editingEmail) {
      setTime(editingEmail.hour * 60 + editingEmail.minute);
      setCadence(getEmailCadence(editingEmail));
    } else {
      const date = new Date();
      const hours = date.getHours();
      const day = date.getDay();
      setTime(hours * 60 + (date.getMinutes() >= 30 ? 30 : 0));
      setDayOfWeek(day === 0 ? 6 : day - 1);
    }
  }, [editingEmail]);

  const noSubject = subject.trim() === '';
  const isMissingInfo = useCallback(() => {
    const noRecipients = recipients.length === 0;
    setRecipientError(noRecipients);
    return noRecipients || noSubject;
  }, [noSubject, recipients.length]);

  useEffect(() => {
    if (recipients.length > 0) setRecipientError(false);
  }, [recipients]);

  const updateOrCreateEmail = useCallback(() => {
    if (isMissingInfo()) return;

    const hour = time / 60;
    const minute = Number.isInteger(hour) ? 0 : 30;

    const emailBody: EmailBody = {
      recipients,
      minute,
      hour: Math.floor(hour),
      week_of_month: cadence === EmailFrequency.MONTHLY ? weekOfMonth : null,
      day_of_week: cadence === EmailFrequency.DAILY ? null : dayOfWeek,
      timezone,
      subject,
      message,
      export_type: exportType,
      locale: Settings.defaultLocale,
    };

    setSaveLoading(true);
    const onFailure = () => setSaveLoading(false);
    const onSuccess = () => goToManage();
    editingEmail
      ? updateExportCadence(editingEmail.id, emailBody, onSuccess, onFailure)
      : createExportCadence(emailBody, onSuccess, onFailure);
  }, [
    cadence,
    createExportCadence,
    dayOfWeek,
    editingEmail,
    exportType,
    goToManage,
    isMissingInfo,
    message,
    recipients,
    subject,
    time,
    timezone,
    updateExportCadence,
    weekOfMonth,
  ]);

  const sendTestEmail = useCallback(() => {
    if (isMissingInfo()) return;
    // TODO SHIBA-6506 - implement through rover
    dispatch(
      sendTestDraftExportThunk({
        email: {
          recipients,
          subject,
          message,
          export_type: exportType,
          timezone,
        },
      }),
    );
  }, [dispatch, exportType, isMissingInfo, message, recipients, subject, timezone]);

  const renderNonDailyCadence = useCallback(() => {
    if (cadence === EmailFrequency.DAILY) return null;
    const isWeekly = cadence === EmailFrequency.WEEKLY;
    return (
      <>
        <div className={styles.cadenceText}>{isWeekly ? 'on' : 'on the'}</div>
        {!isWeekly ? (
          <EmbeddedDropdownMenu
            menuOptions={weekOfMonthOptions}
            onClick={(week) => setWeekOfMonth(parseInt(week))}>
            <EmbeddedDropdownButton
              className={cx(styles.flexOne, styles.marginRightDropdown)}
              selectedName={weekOfMonthOptions[weekOfMonth].name}
            />
          </EmbeddedDropdownMenu>
        ) : null}
        <EmbeddedDropdownMenu
          menuOptions={weekdayOptions}
          onClick={(day) => setDayOfWeek(parseInt(day))}>
          <EmbeddedDropdownButton
            className={styles.flexOne}
            selectedName={weekdayOptions[dayOfWeek].name}
          />
        </EmbeddedDropdownMenu>
      </>
    );
  }, [cadence, dayOfWeek, weekOfMonth]);

  const selectedTime = useMemo(
    () => timeOptions.find((opt) => opt.value === time.toString())?.name,
    [time],
  );

  const selectedTimezone = useMemo(
    () => timezoneOptions.find((opt) => opt.value === timezone)?.name,
    [timezone, timezoneOptions],
  );

  const renderCadence = useCallback(() => {
    return (
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        <EmbeddedDropdownMenu
          menuOptions={cadenceOptions}
          onClick={(cadence) => setCadence(cadence as EmailFrequency)}>
          <EmbeddedDropdownButton className={styles.flexOne} selectedName={cadence} />
        </EmbeddedDropdownMenu>
        {renderNonDailyCadence()}
        <div className={styles.cadenceText}>at</div>
        <EmbeddedDropdownMenu menuOptions={timeOptions} onClick={(time) => setTime(parseInt(time))}>
          <EmbeddedDropdownButton
            className={cx(styles.flexOne, styles.marginRightDropdown)}
            selectedName={selectedTime}
          />
        </EmbeddedDropdownMenu>
        <EmbeddedDropdownMenu menuOptions={timezoneOptions} onClick={(tz) => setTimezone(tz)}>
          <EmbeddedDropdownButton className={styles.flexOne} selectedName={selectedTimezone} />
        </EmbeddedDropdownMenu>
      </div>
    );
  }, [cadence, renderNonDailyCadence, selectedTime, selectedTimezone, timezoneOptions]);

  const handleEmailSubmission = useCallback(() => {
    if (!validator.isEmail(currRecipient)) return;
    if (!recipients.includes(currRecipient)) setRecipients([...recipients, currRecipient]);
    setCurrRecipient('');
  }, [currRecipient, recipients]);

  const exportOptions = enableScreenshotExport ? allExportOptions : tabularExportOptions;
  const isSendingTestEmail = exportEmailId === (editingEmail?.id || DRAFT_EMAIL_ID);

  return (
    <>
      <EmbedModalContent className={styles.modalContent} size="xlarge">
        <div className={styles.section}>
          <EmbedText body="b1">
            Export and email all the views in this report. Only saved changes will be exported.
          </EmbedText>
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Select Recipients
          </EmbedText>
          <EmbedInput
            minimalStyle
            onBlur={handleEmailSubmission}
            onChange={setCurrRecipient}
            onEnter={handleEmailSubmission}
            placeholder="Add Recipient Email"
            value={currRecipient}
          />
          {recipientError ? (
            <EmbedText body="b3" color="error">
              You must insert at least one recipient
            </EmbedText>
          ) : null}
          {recipients.length > 0 ? (
            <div className={styles.recipientTags}>
              {recipients.map((recipient) => (
                <Tag
                  intent="active"
                  key={recipient}
                  onClose={() => setRecipients(recipients.filter((email) => email !== recipient))}>
                  {recipient}
                </Tag>
              ))}
            </div>
          ) : null}
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Email Content
          </EmbedText>
          <EmbedText color="contentPrimary" heading="h4">
            Subject
          </EmbedText>
          <EmbedInput minimalStyle onChange={setSubject} placeholder="Subject" value={subject} />
          {noSubject ? (
            <EmbedText body="b3" color="error">
              Subject cannot be empty
            </EmbedText>
          ) : null}
          <EmbedText color="contentPrimary" heading="h4">
            Body
          </EmbedText>
          <textarea
            className={styles.textarea}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Add content to your email..."
            rows={6}
            value={message}
          />
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Export Type
          </EmbedText>
          <EmbedRadioGroup
            defaultValue={DEFAULT_EXPORT_TYPE}
            onValueChange={(value) => setExportType(value as ExportType)}
            renderValue={(value) => EXPORT_TYPE_NAME_MAP[value as ExportType]}
            value={exportType}
            values={exportOptions}
          />
          {imageExportOptions.includes(exportType) ? <ImageExportLimitationWarning /> : null}
        </div>

        <div className={styles.section}>
          <EmbedText color="contentPrimary" heading="h3">
            Scheduling
          </EmbedText>
          {renderCadence()}
        </div>
      </EmbedModalContent>

      <EmbedModalFooter>
        <EmbedButton
          disabled={isLoading(scheduledExportState)}
          loading={isSendingTestEmail && isLoading(scheduledExportState)}
          onClick={sendTestEmail}
          tooltipProps={{ text: 'This email will include your unsaved changes' }}
          variant="secondary">
          Send Test
        </EmbedButton>
        <EmbedButton
          disabled={noSubject}
          loading={saveLoading}
          onClick={updateOrCreateEmail}
          variant="primary">
          {editingEmail ? 'Update' : 'Schedule Email'}
        </EmbedButton>
        {!isSendingTestEmail ? null : isError(scheduledExportState) ? (
          <EmbedText body="b3" color="error">
            Failed to send test email, please try again then contact support if the issue persists.
          </EmbedText>
        ) : isSuccess(scheduledExportState) ? (
          <EmbedText body="b3" color="success">
            Successfully sent test email.
          </EmbedText>
        ) : null}
      </EmbedModalFooter>
    </>
  );
}

const UTC_OPTION = 'Etc/UTC';
