import { Branch, Folder } from '@explo-tech/fido-api';
import { Button, Modal, sprinkles } from 'components/ds';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { ROOT_FOLDER_PATH } from 'pages/dataLibraryPage/constants';
import { DirectoryPanelContent } from 'pages/dataLibraryPage/DirectoryPanelContent';
import { useLoadRootFolderContents } from 'pages/dataLibraryPage/hooks';
import {
  getRootFolderForBranch,
  getUpdatedFoldersForBranch,
} from 'pages/dataLibraryPage/selectors';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ItemPathInfo, ItemType } from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';

import * as RD from 'remotedata';

interface Props {
  currentBranch: RD.ResponseData<Branch>;
  actionButtonText: string;

  onActionButtonClicked: (parentFolder: Folder) => void;
  isButtonDisabledFn: (currentFolderResourceNames: Set<string>) => boolean;
  onClose: () => void;
}

export const DataLibraryFolderActionModal: FC<Props> = ({
  currentBranch,
  actionButtonText,
  isButtonDisabledFn,
  onActionButtonClicked,
  onClose,
}) => {
  const [selectedItemPath, setSelectedItemPath] = useState<ItemPathInfo>({
    path: ROOT_FOLDER_PATH,
    type: ItemType.FOLDER,
  });

  const [folderExpansionState, setFolderExpansionState] = useState<Map<string, boolean>>(
    new Map([[ROOT_FOLDER_PATH, true]]),
  );

  const { rootFolder, folders } = useSelector((state: ReduxState) => {
    return {
      rootFolder: getRootFolderForBranch(state, currentBranch),
      folders: getUpdatedFoldersForBranch(state, currentBranch),
    };
  });

  const [selectedParentFolder, setSelectedParentFolder] = useState<Folder>();

  useEffect(() => {
    const selectedFolderResponse = folders.get(selectedItemPath.path);
    if (RD.isSuccess(selectedFolderResponse)) {
      setSelectedParentFolder(selectedFolderResponse.data);
    }
  }, [folders, selectedItemPath, setSelectedParentFolder]);

  const allChildResourceNames = useMemo(() => {
    const allChildResourceNames = new Set<string>();

    if (!selectedParentFolder) {
      return allChildResourceNames;
    }

    for (const resource of selectedParentFolder.children ?? []) {
      allChildResourceNames.add(resource.name);
    }
    return allChildResourceNames;
  }, [selectedParentFolder]);

  const onCloseWrapperFn = useCallback(() => {
    onClose();
    setSelectedItemPath({ path: ROOT_FOLDER_PATH, type: ItemType.FOLDER });
    setSelectedParentFolder(undefined);
  }, [onClose]);

  useLoadRootFolderContents(currentBranch, rootFolder);

  return (
    <Modal isOpen={true} onClose={onCloseWrapperFn} size="xlarge" title="Select folder">
      {RD.isSuccess(rootFolder) && RD.isSuccess(currentBranch) ? (
        <>
          <DirectoryPanelContent
            shouldReadFromHead
            branchId={currentBranch.data.id ?? ''}
            commitButtonEnabled={false}
            expansionState={folderExpansionState}
            onCommitButtonClicked={() => {
              // Do nothing, the render button is not rendered here
            }}
            onComputedViewSelection={() => {
              // Do nothing this is intentional
            }}
            onFolderSelection={(_folderId: string, folderPath: string) => {
              setSelectedItemPath({ path: folderPath, type: ItemType.FOLDER });
            }}
            pendingCommitStatus={RD.Idle()}
            renderCommitButton={false}
            rootFolder={rootFolder.data}
            selectedItemPath={selectedItemPath}
            setIsExpanded={(folderPath: string, isExpanded: boolean) => {
              setFolderExpansionState(new Map(folderExpansionState).set(folderPath, isExpanded));
            }}
          />
          <div className={buttonContainerClassName}>
            <Button
              disabled={isButtonDisabledFn(allChildResourceNames)}
              onClick={() => {
                if (!selectedParentFolder) {
                  return;
                }

                onActionButtonClicked(selectedParentFolder);
                onCloseWrapperFn();
              }}>
              {actionButtonText}
            </Button>
          </div>
        </>
      ) : (
        <ExploLoadingSpinner />
      )}
    </Modal>
  );
};

const buttonContainerClassName = sprinkles({
  marginTop: 'sp1',
  marginX: 'sp2',
  justifyContent: 'flex-end',
  display: 'flex',
});
