import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';

import { fetchCustomFonts } from 'actions/customFontsActions';
import {
  fetchGoogleFonts,
  saveAdditionalStyle,
  saveGlobalStyles,
} from 'actions/styleConfigActions';
import { Button, Tabs } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { STYLE_TABS } from 'globalStyles/constants';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getFontFamilyName } from 'globalStyles/utils';
import { ReduxState } from 'reducers/rootReducer';
import { setEditingConfig } from 'reducers/styleConfigReducer';
import * as RD from 'remotedata';
import { getOrDefault } from 'remotedata';
import { showSuccessToast } from 'shared/sharedToasts';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { isEqual } from 'utils/standard';

import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { AdditionalStylesSection } from './AdditionalStylesSection';
import BaseConfigSection from './BaseConfigSection';
import { CardsConfigSection } from './CardsConfigSection';
import { ComponentConfigs } from './ComponentConfigs';
import { TextConfigSection } from './TextConfigSection';
import { VisualizationsConfigSection } from './VisualizationsConfigSection';

import * as styles from './CustomizeStyleConfigPane.css';

const TabNames = Object.values(STYLE_TABS);

type Props = {
  currentConfig: GlobalStyleConfig;
  editingThemeName: string | null;
  originalConfig: GlobalStyleConfig | undefined;
  tabId: string;
  setTabId: (tab: STYLE_TABS) => void;
};

export const CustomizeStyleConfigPane: FC<Props> = ({
  currentConfig,
  originalConfig,
  editingThemeName,
  tabId,
  setTabId,
}) => {
  const dispatch = useDispatch();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const { customFonts, googleFonts, fontConfig, additionalStylesEnabled, currentUser } =
    useSelector(
      (state: ReduxState) => ({
        customFonts: state.customFonts.customFonts,
        fontConfig: state.dashboardStyles.fontConfig,
        googleFonts: state.styleConfig.googleFonts,
        additionalStylesEnabled:
          !!state.currentUser?.team?.entitlements.enable_additional_style_configs,
        currentUser: state.currentUser,
      }),
      shallowEqual,
    );

  const configHasChanges = useMemo(
    () => !isEqual(originalConfig, currentConfig),
    [originalConfig, currentConfig],
  );

  useEffect(() => {
    if (RD.isIdle(customFonts)) dispatch(fetchCustomFonts());
  }, [customFonts, dispatch]);

  useEffect(() => {
    if (RD.isIdle(googleFonts)) dispatch(fetchGoogleFonts());
  }, [dispatch, googleFonts]);

  const updateConfig = useCallback(
    (newConfig: GlobalStyleConfig) => dispatch(setEditingConfig(newConfig)),
    [dispatch],
  );

  const fonts = useMemo(() => {
    let customFontOptions: SelectItems<string> = [];
    if (RD.isSuccess(customFonts)) {
      customFontOptions = customFonts.data.map((font) => ({
        value: font,
        label: getFontFamilyName(font),
      }));
    }

    const googleOptions = RD.isSuccess(googleFonts)
      ? googleFonts.data.map((font) => ({ value: font, label: font }))
      : [];

    return customFontOptions.concat(googleOptions);
  }, [googleFonts, customFonts]);

  const userCanEdit = useMemo(
    () =>
      doesUserHavePermission(
        currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOM_STYLES],
        PERMISSIONED_ACTIONS.UPDATE,
      ),
    [currentUser],
  );

  const onSaveSuccess = useCallback(() => {
    showSuccessToast('Styles saved successfully');
    setIsSaveLoading(false);
  }, []);

  const onSaveError = useCallback(() => setIsSaveLoading(false), []);

  const renderContent = () => {
    return (
      <div className={styles.editStylesWrapper}>
        <Tabs
          onTabSelect={(tab) => setTabId(tab as STYLE_TABS)}
          selectedTabId={tabId}
          tabs={TabNames}
        />

        {tabId === STYLE_TABS.OVERVIEW ? (
          <div className={styles.paneContentClass}>
            <BaseConfigSection styleConfig={currentConfig} updateConfig={updateConfig} />
            <CardsConfigSection styleConfig={currentConfig} updateConfig={updateConfig} />
            <TextConfigSection
              fontConfig={getOrDefault(fontConfig, [])}
              fonts={fonts}
              googleFonts={getOrDefault(googleFonts, [])}
              styleConfig={currentConfig}
              updateConfig={updateConfig}
            />
            <VisualizationsConfigSection styleConfig={currentConfig} updateConfig={updateConfig} />
          </div>
        ) : null}

        {tabId === STYLE_TABS.COMPONENTS ? (
          <ComponentConfigs styleConfig={currentConfig} updateConfig={updateConfig} />
        ) : null}
        <div className={styles.buttonSectionClass} style={{ minHeight: 64 }}>
          <Button
            fillWidth
            disabled={!configHasChanges || !originalConfig}
            onClick={() => dispatch(setEditingConfig(null))}
            variant="secondary">
            Reset
          </Button>
          <Button
            fillWidth
            disabled={!configHasChanges}
            loading={isSaveLoading}
            onClick={() => {
              setIsSaveLoading(true);
              editingThemeName
                ? dispatch(
                    saveAdditionalStyle(
                      { postData: { style_config: currentConfig, theme_name: editingThemeName } },
                      onSaveSuccess,
                      onSaveError,
                    ),
                  )
                : dispatch(
                    saveGlobalStyles(
                      { postData: { config_v2: currentConfig } },
                      onSaveSuccess,
                      onSaveError,
                    ),
                  );
            }}
            variant="primary">
            Save
          </Button>
        </div>
        {!userCanEdit ? (
          <div className={styles.editPermissionsObfuscator}>
            User does not have permission to update styles
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.rootClass} style={{ width: styles.EDIT_PANEL_WIDTH }}>
      <Prompt
        message="You have unsaved changes to the styles. If you leave this page your changes will be lost."
        when={configHasChanges}
      />
      <div className={styles.headerClass} style={{ minHeight: 64 }}>
        Styling
      </div>
      {additionalStylesEnabled ? (
        <AdditionalStylesSection
          currentConfig={currentConfig}
          disableDropdown={configHasChanges}
          isNew={!originalConfig}
        />
      ) : null}
      {renderContent()}
    </div>
  );
};
