import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColumnConfigsWithName, OPERATION_TYPES } from '@explo/data';

import { sprinkles, Tag } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/ReportView/DrilldownFooter.css';
import {
  getDrilldownConfig,
  toggleDrilldownPanelOpen,
  updateDrilldownFilters,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { createViewFromDrilldown } from 'reportBuilderContent/thunks';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';
import { getColDisplay } from 'utils/V2ColUtils';
import { getFilterClauseValueText } from 'utils/customerReportUtils';

type Props = {
  visualization?: OPERATION_TYPES;
  viewName: string;
  hasGroupBys: boolean;
  columnConfigs: ColumnConfigsWithName;
};

export const DrilldownFooter = forwardRef<HTMLDivElement, Props>(function DrilldownFooter(
  { visualization, viewName, hasGroupBys, columnConfigs },
  ref,
) {
  const dispatch = useDispatch();

  const drilldownConfig = useSelector((state: ReportBuilderReduxState) =>
    getDrilldownConfig(state.reportEditing),
  );

  const isTable = isTableVisualization(visualization);

  return (
    <div className={styles.toggleDataContainer} ref={ref}>
      <div className={styles.leftContainer}>
        <span
          className={sprinkles({ cursor: 'pointer', truncateText: 'ellipsis' })}
          onClick={() => dispatch(updateDrilldownFilters([]))}>
          <EmbedText heading="h3">{viewName || 'Untitled View'}</EmbedText>
        </span>
        {drilldownConfig?.filters?.map((filter) => (
          <Tag
            className={sprinkles({ truncateText: 'ellipsis' })}
            key={filter.id}
            leftIcon="filter">
            {getColDisplay(filter.filterColumn, columnConfigs)}&nbsp;
            {getFilterClauseValueText(filter)}
          </Tag>
        ))}
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', overflow: 'hidden', flexShrink: 0 })}>
        <EmbedButton
          icon="arrow-up-right"
          onClick={() => dispatch(createViewFromDrilldown())}
          variant="tertiary">
          Open in new view
        </EmbedButton>
        {!isTable || hasGroupBys ? (
          <EmbedButton
            icon="chevron-down"
            onClick={() => dispatch(toggleDrilldownPanelOpen())}
            variant="tertiary">
            Collapse underlying data
          </EmbedButton>
        ) : null}
      </div>
    </div>
  );
});
