import {
  StringUrlDisplayFormat,
  StringDisplayOptions,
  DatasetRow,
  BooleanDisplayFormat,
  BooleanDisplayOptions,
} from '@explo/data';
import { getValidHex } from './general';
import { DEFAULT_BOOLEAN_DISPLAY_OPTIONS } from 'constants/dataConstants';

export const getCurrentBooleanIcons = (
  booleanOptions: BooleanDisplayOptions | undefined,
): { trueIcon: BooleanDisplayFormat; falseIcon: BooleanDisplayFormat } => {
  return {
    trueIcon: booleanOptions?.trueIcon ?? DEFAULT_BOOLEAN_DISPLAY_OPTIONS.trueIcon,
    falseIcon: booleanOptions?.falseIcon ?? DEFAULT_BOOLEAN_DISPLAY_OPTIONS.falseIcon,
  };
};

export const getCurrentUrlFormat = (urlFormat?: StringUrlDisplayFormat): StringUrlDisplayFormat => {
  return urlFormat ?? StringUrlDisplayFormat.LABEL;
};

export const getLinkInfo = (
  { urlFormat, label, urlColumnName, urlLinkColor, openInSameTab }: StringDisplayOptions,
  row?: DatasetRow,
): { urlLabel: string | undefined; linkColor: string | undefined; target: string | undefined } => {
  let urlLabel: string | undefined = '';
  const format = getCurrentUrlFormat(urlFormat);
  if (format === StringUrlDisplayFormat.LABEL) {
    urlLabel = label;
  } else if (format === StringUrlDisplayFormat.COLUMN && urlColumnName) {
    urlLabel = String(row?.[urlColumnName] ?? label);
  }
  return {
    urlLabel,
    target: openInSameTab ? '_top' : '_blank',
    linkColor: urlLinkColor ? getValidHex(urlLinkColor) : undefined,
  };
};
