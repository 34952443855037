import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  updateExportConfigThunk,
  updateGeneralFormatOptions,
} from 'actions/dataPanelConfigActions';
import { ConfigSubSectionWithSwitch } from 'components/PanelComponents/ConfigSubSection';
import { sprinkles, Button, Input } from 'components/ds';
import { VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import { setIsDrillDownPreview } from 'reducers/dashboardInteractionsReducer';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

type Props = {
  options: VisualizeOperationGeneralFormatOptions;
  datasetId: string | number;
  dpProvidedId: string;
  dataPanel: DataPanelTemplate;
};

export const RawDataDrilldownConfig: FC<Props> = ({
  options,
  datasetId,
  dpProvidedId,
  dataPanel,
}) => {
  const dispatch = useDispatch();

  return (
    <ConfigSubSectionWithSwitch
      label="Open a modal with a table of the underlying data"
      onChange={() => {
        const newFormat = produce(options, (draft) => {
          draft.enableRawDataDrilldown = !draft.enableRawDataDrilldown;
        });
        dispatch(updateGeneralFormatOptions(newFormat));
      }}
      switchOn={!!options.enableRawDataDrilldown}>
      {/** Do not render the data formatting button for data panels that are backed by a global
       * dataset. */}
      {!dataPanel.globalDatasetReference ? (
        <>
          <div className={sprinkles({ body: 'section' })}>Adjust the formatting of the data</div>
          <Button
            linkIcon
            onClick={() => {
              dispatch(clearSelectedItemThunk());
              dispatch(setSelectedDatasetId(String(datasetId)));
              dispatch(setIsDrillDownPreview(true));
            }}
            variant="secondary">
            Open the data formatting panel
          </Button>
        </>
      ) : null}
      <Input
        defaultValue={options.export?.rawDataDownloadFileName ?? dpProvidedId}
        label="Underlying Data File Name"
        onSubmit={(newFileName) =>
          dispatch(updateExportConfigThunk({ rawDataDownloadFileName: newFileName }, options))
        }></Input>
    </ConfigSubSectionWithSwitch>
  );
};
