import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { Dataset } from 'actions/datasetActions';
import { Modal } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { getParentSchemaSourceTypes } from 'reducers/selectors';
import { sortBy } from 'utils/standard';

import { ComputedView } from '@explo-tech/fido-api';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';
import { convertViewToDataset } from './dashboardDatasetEditor/utils';
import * as styles from './dataPanelModal.css';
import { DataPanelModalDatasetItem } from './DataPanelModalDatasetItem';

type Props = {
  datasets: Record<string, Dataset>;
  referencedGlobalDatasets: Record<string, ComputedView>;
  initialDatasetId?: string | undefined;
  opType: OPERATION_TYPES;
  onClose: () => void;
  onConfirm: (selectedDatasetId: string | undefined) => void;
  primaryButtonText: string;
  headerText: string;
  title: string;
  additionalContent?: JSX.Element;
};

export const DataPanelModal: FC<Props> = ({
  datasets,
  referencedGlobalDatasets,
  initialDatasetId,
  opType,
  onClose,
  onConfirm,
  primaryButtonText,
  headerText,
  title,
  additionalContent,
}) => {
  const { parentSchemaSourceTypes, parentSchemas, enableDataLibrary } = useSelector(
    (state: ReduxState) => ({
      parentSchemaSourceTypes: getParentSchemaSourceTypes(state),
      parentSchemas: getParentSchemasList(state),
      enableDataLibrary: state.currentUser.team?.feature_flags.enable_data_library,
    }),
    shallowEqual,
  );

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | undefined>(initialDatasetId);

  const orderedValidDatasets = useMemo(
    () =>
      sortBy(Object.values(datasets ?? {}), 'table_name').filter(
        (dataset) => dataset.schema?.length,
      ),
    [datasets],
  );

  return (
    <Modal
      isOpen
      onClose={onClose}
      primaryButtonProps={{
        disabled: !selectedDatasetId,
        onClick: () => onConfirm(selectedDatasetId),
        text: primaryButtonText,
      }}
      secondaryButtonProps={{
        onClick: onClose,
        text: 'Cancel',
      }}
      size="small"
      title={title}>
      <div className={styles.modalBody}>
        <div className={styles.modalSubHeader}>{headerText}</div>
        <div className={styles.modalBodyListClass} style={{ height: '40vh' }}>
          <Menu>
            <MenuDivider
              title={
                enableDataLibrary
                  ? DASHBOARD_DATASETS_WITH_GLOBAL_DATASETS_TITLE
                  : DASHBOARD_DATASETS_TITLE
              }
            />
            {orderedValidDatasets.length === 0 ? (
              <MenuItem
                disabled
                text={
                  enableDataLibrary ? NO_DATASETS_WITH_GLOBAL_DATASETS_MESSAGE : NO_DATASETS_MESSAGE
                }
              />
            ) : (
              orderedValidDatasets.map((dataset) => {
                return (
                  <DataPanelModalDatasetItem
                    dataPanelOperationType={opType}
                    dataset={dataset}
                    isGlobalDataset={false}
                    key={dataset.id}
                    onClick={setSelectedDatasetId}
                    parentSchemaSourceTypes={parentSchemaSourceTypes}
                    selectedDatasetId={selectedDatasetId}
                  />
                );
              })
            )}
            {enableDataLibrary ? <MenuDivider title="Global Datasets" /> : null}
            {enableDataLibrary
              ? (Object.values(referencedGlobalDatasets) as ComputedViewWithIds[])
                  .map((computedView) => convertViewToDataset(computedView, parentSchemas))
                  .map((dataset) => {
                    return (
                      <DataPanelModalDatasetItem
                        dataPanelOperationType={opType}
                        dataset={dataset}
                        isGlobalDataset={true}
                        key={dataset.id}
                        onClick={setSelectedDatasetId}
                        parentSchemaSourceTypes={parentSchemaSourceTypes}
                        selectedDatasetId={selectedDatasetId}
                      />
                    );
                  })
              : null}
          </Menu>
        </div>
        {additionalContent}
      </div>
    </Modal>
  );
};

const DASHBOARD_DATASETS_TITLE = 'Dashboard Datasets';

const DASHBOARD_DATASETS_WITH_GLOBAL_DATASETS_TITLE = 'Local Datasets';

const NO_DATASETS_MESSAGE = 'No datasets. Create one!';

const NO_DATASETS_WITH_GLOBAL_DATASETS_MESSAGE = 'No local datasets. Create one!';
