import { FC, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ACTION } from 'actions/types';
import { CreateResourceModal } from 'components/CreateResourceModal';
import { EmptyPageActionCallout } from 'components/EmptyPageActionCallout';
import { PageHeader, ViewType } from 'components/PageHeader';
import { SearchResources } from 'components/SearchResources';
import { TablePager } from 'components/dataTable/tablePager';
import { FolderBreadcrumbs, sprinkles } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { createLoadingSelector } from 'reducers/api/selectors';
import { getResources, getUniqueNames } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import { createFolder } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { RESOURCES_PER_CARD_PAGE, RESOURCES_PER_LIST_PAGE } from 'shared/ExploResource/constants';
import { useBreadcrumbItems } from 'shared/ExploResource/useBreadcrumbItems';
import { showErrorToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import {
  formatNumFolders,
  formatNumResources,
  getFolderNameErrorMessage,
  getPermissionEntity,
  getResourceText,
} from 'utils/exploResourceUtils';
import { isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';

import { PromotionalPage } from './PromotionalPage';
import * as styles from './styles.css';

type Props = {
  currPage: number;
  setCurrPage: (page: number) => void;
  viewType: ViewType;
  setViewType: (view: ViewType) => void;
  pageType: ResourcePageType;
  children: JSX.Element;
};

export const ResourceListPageWrapper: FC<Props> = ({
  currPage,
  setCurrPage,
  viewType,
  setViewType,
  pageType,
  children,
}): JSX.Element => {
  const dispatch = useDispatch();

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const {
    currentUser,
    createResourceLoading,
    globalStyleConfig,
    folders,
    isLoading,
    currentFolder,
    currentFolderFolderCount,
    currentFolderResourceCount,
    breadcrumbs,
    folderCount,
    resourceCount,
    resources,
    uniqueNames,
  } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
      folders: state.folder.folders,
      isLoading: RD.isLoading(state.folder.resourcesStatus),
      currentFolder: state.folder.breadcrumbs[state.folder.breadcrumbs.length - 1],
      currentFolderFolderCount: state.folder.folderCount,
      currentFolderResourceCount: state.folder.resourceCount,
      breadcrumbs: state.folder.breadcrumbs,
      folderCount: state.folder.folderCount,
      resourceCount: state.folder.resourceCount,
      resources: getResources(state, isExploreProduct),
      uniqueNames: getUniqueNames(state, isExploreProduct),
      createResourceLoading: createLoadingSelector(
        [ACTION.CREATE_DASHBOARD, ACTION.CLONE_DASHBOARD],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const [createResourceModalOpen, setCreateResourceModalOpen] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const userPermissions = currentUser.permissions[resourcePermissionEntity];

  const userCanCreateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const breadcrumbItems = useBreadcrumbItems(breadcrumbs, pageType);

  const globalStyleVars = useMemo(() => {
    if (pageType === ResourcePageType.REPORT_BUILDER) return {};
    return getGlobalStyleVars(globalStyleConfig, { onlyCustomTheme: true });
  }, [globalStyleConfig, pageType]);

  const getPageTitle = () => {
    switch (pageType) {
      case ResourcePageType.REPORT_BUILDER:
        return 'Report Builder';
      case ResourcePageType.EXPLORE:
        return 'Dashboards';
    }
  };

  const renderEmptyState = useCallback(() => {
    if (resources.length + folders.length === 0 && !isLoading && userCanCreateResource) {
      return (
        <div className={sprinkles({ marginTop: 'sp2' })}>
          <EmptyPageActionCallout
            text={`Get started by creating your first ${getResourceText(pageType)}`}
          />
        </div>
      );
    }
  }, [resources, folders, isLoading, userCanCreateResource, pageType]);

  if (!resources) {
    return (
      <div className={styles.errorLoadingResources}>
        Error Loading {getResourceText(pageType, { plural: true, capitalized: true })}
      </div>
    );
  }

  const renderPager = () => {
    if (folders.length + resources.length === 0) return;

    const resourcesPerPage =
      viewType === ViewType.List ? RESOURCES_PER_LIST_PAGE : RESOURCES_PER_CARD_PAGE;
    const maxPageNumber = Math.max(
      Math.ceil((currentFolderFolderCount + currentFolderResourceCount) / resourcesPerPage),
      1,
    );

    return (
      <div className={sprinkles({ display: 'block' })}>
        <div className={styles.pagerFooterContainer}>
          <div className={styles.pagerCount}>
            <div className={sprinkles({ display: 'flex', flexDirection: 'row', gap: 'sp1' })}>
              <div>{formatNumFolders(folderCount)}</div>
              <div>{formatNumResources(isExploreProduct, resourceCount)}</div>
            </div>
          </div>
          <TablePager
            currentPage={currPage}
            maxPageNumber={maxPageNumber}
            onNewPage={(newPage) => {
              const newPageNumber = parseInt(newPage);

              if (
                !newPageNumber ||
                newPageNumber < 1 ||
                newPageNumber > maxPageNumber ||
                currPage === newPageNumber
              ) {
                return;
              }
              setCurrPage(newPageNumber);
            }}
          />
          <div />
        </div>
      </div>
    );
  };

  const renderCreateFolderModal = () => {
    if (!currentUser.team || !currentFolder) return;

    return (
      <TextFieldModal
        buttonName="Create Folder"
        closeModal={() => setCreateFolderModalOpen(false)}
        getErrorMessage={(input, hasUserInputtedValue) =>
          getFolderNameErrorMessage(input, uniqueNames, undefined, hasUserInputtedValue)
        }
        loading={createResourceLoading}
        modalOpen={createFolderModalOpen}
        modalTitle="Create folder"
        onSubmit={(name) => {
          dispatch(
            createFolder({
              config: { parent_id: currentFolder.id, name: name.trim() },
              onSuccess: () => setCreateFolderModalOpen(false),
              onError: () =>
                showErrorToast(
                  `There was an error creating your folder. Please try again or contact support if the error continues.`,
                ),
            }),
          );
        }}
        textFieldPlaceholder="Enter folder name"
      />
    );
  };

  const primaryActionProps = userCanCreateResource
    ? {
        text: `Create ${getResourceText(pageType, { capitalized: true })}`,
        onClick: () => setCreateResourceModalOpen(true),
      }
    : undefined;

  const secondaryActionProps = userCanCreateResource
    ? {
        text: 'Create Folder',
        onClick: () => setCreateFolderModalOpen(true),
      }
    : undefined;

  const breadcrumbHeader = () => {
    if (breadcrumbs.length <= 1 || !currentFolder)
      return <div className={sprinkles({ truncateText: 'ellipsis' })}>{getPageTitle()}</div>;

    return (
      <div className={sprinkles({ minWidth: 0 })}>
        <FolderBreadcrumbs items={breadcrumbItems} />
        <div className={sprinkles({ truncateText: 'ellipsis' })}>{currentFolder.name}</div>
      </div>
    );
  };

  const renderBody = () => (
    <>
      <PageHeader
        currentView={viewType}
        heading={breadcrumbs.length > 1 ? 'h3' : 'h2'}
        pageTitle={breadcrumbHeader()}
        primaryActionProps={primaryActionProps}
        searchBarOverride={
          <SearchResources isExploreProduct={isExploreProduct} resetPage={() => setCurrPage(1)} />
        }
        searchBarPlaceholderText="Search by name"
        searchBarSubmit={() => setCurrPage(1)}
        secondaryActionProps={secondaryActionProps}
        toggleView={
          pageType === ResourcePageType.REPORT_BUILDER
            ? undefined
            : (view: ViewType) => {
                setCurrPage(1);
                setViewType(view);
              }
        }
      />
      {renderEmptyState()}
      <div className={styles.scroll}>
        <div className={styles.content} style={globalStyleVars}>
          {children}
          {createResourceModalOpen ? (
            <CreateResourceModal
              isOpen={createResourceModalOpen}
              pageType={pageType}
              setCreateResourceModalOpen={setCreateResourceModalOpen}
            />
          ) : null}
          {renderCreateFolderModal()}
          {renderPager()}
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.root}>
      {pageType === ResourcePageType.REPORT_BUILDER && !isReportBuilderEnabled(currentUser) ? (
        <PromotionalPage pageTitle={getPageTitle()} />
      ) : (
        renderBody()
      )}
    </div>
  );
};
