import cx from 'classnames';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { sprinkles } from 'components/ds';
import { LatestVersionInfo, Resource } from 'types/exploResource';

import { EmailTag } from './EmailTag';
import { LatestVersionTag } from './LatestVersionTag';
import { UpdateDatasetsButton } from './UpdateDatasetsButton';
import * as styles from './styles.css';

interface Props {
  resource: Resource;
  isDisabled: boolean;
  isLoading: boolean;
  onClickUrl: string;
  latestConfig: LatestVersionInfo;
  dotsMenu: JSX.Element | null;
  lastModifiedMessage: string;
  hasEmailState: boolean;
  enableDataLibrary: boolean;
  enableDataLibraryV2: boolean;
  renderPreviewFn: (latestVersion: LatestVersionInfo | undefined) => JSX.Element;
  onClickMigrateDatasetsTag: (resourceId: number) => void;
}

export const ResourceItemCard: FC<Props> = ({
  resource,
  isDisabled,
  isLoading,
  onClickUrl,
  latestConfig,
  dotsMenu,
  lastModifiedMessage,
  hasEmailState,
  enableDataLibrary,
  enableDataLibraryV2,
  renderPreviewFn,
  onClickMigrateDatasetsTag,
}) => {
  const history = useHistory();

  return (
    <div
      className={cx(styles.resourceCardItem, {
        [styles.disabledResource]: isDisabled,
      })}
      onClick={(e) => {
        if (isDisabled || isLoading) return;
        if (e.metaKey || e.ctrlKey) {
          window.open(onClickUrl, '_blank');
        } else {
          history.push(onClickUrl);
        }
      }}>
      <div className={styles.cardPreviewContainer}>
        <div className={styles.cardPreview}>
          {renderPreviewFn(latestConfig as LatestVersionInfo)}
        </div>
        {dotsMenu}
      </div>

      <div className={styles.resourceCardInfo}>
        <div className={styles.resourceCardName}>{resource.name}</div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          {resource.latest_versions.map((latestVersion) => (
            <LatestVersionTag
              isDraft={latestVersion.is_draft}
              key={latestVersion.version_number}
              versionNumber={latestVersion.version_number}
            />
          ))}
          <div className={styles.resourceCardLastModified}>{lastModifiedMessage}</div>
        </div>
        {hasEmailState ? <EmailTag /> : null}
        {enableDataLibrary && enableDataLibraryV2 ? (
          <UpdateDatasetsButton onClick={() => onClickMigrateDatasetsTag(resource.id)} />
        ) : null}
      </div>
    </div>
  );
};
