import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Input, Toggle, ToggleItem, Label, vars } from 'components/ds';
import {
  KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS,
  KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS,
  KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS,
  KPI_ORDERED_VERT_CONTENT_ALIGNMENTS,
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS,
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS,
} from 'constants/kpiAlignmentConstants';
import { getCategoricalColors } from 'globalStyles';
import { KPIGeneralFormat, V2KPIChartInstructions } from 'constants/types';
import { ColorPickerButton } from 'components/ColorPickerButton';
import {
  TextElemHorizAlignments,
  TITLE_VALUE_ARRANGEMENTS,
  TitleValueArrangements,
  VerticalContentAlignments,
} from 'types/dashboardTypes';
import { ReduxState } from 'reducers/rootReducer';

const DEFAULT_BACKGROUND_COLOR = vars.colors.white;
const DEFAULT_OPACITY = '1';

type Props = {
  configInputClass: string;
  instructions: V2KPIChartInstructions;
  updateGeneralFormat: (generalFormat: KPIGeneralFormat) => void;
};

export const SharedKPIGeneralConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  updateGeneralFormat,
}) => {
  const selectedHorizAlignment =
    instructions.generalFormat?.alignment || KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS.CENTER_ALIGN.id;

  const selectedVertAlignment =
    instructions.generalFormat?.vertical_content_alignment ||
    KPI_VERT_CONTENT_ALIGNMENT_FORMATS.CENTER.id;

  const selectedTitleArrangement =
    instructions.generalFormat?.title_value_arrangement ||
    KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.ABOVE.id;

  const isFixed =
    selectedTitleArrangement === TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT ||
    selectedTitleArrangement === TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER;

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const backgroundColor = instructions.generalFormat?.backgroundColor;
  const opacity = instructions.generalFormat?.opacity;

  return (
    <>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={instructions.generalFormat?.subtitle}
        label={{ text: 'Description', variableInput: true }}
        onSubmit={(newValue) => updateGeneralFormat({ subtitle: newValue })}
      />
      <Toggle
        className={configInputClass}
        label="Title/Value Arrangement"
        onValueChange={(value) =>
          updateGeneralFormat({ title_value_arrangement: value as TitleValueArrangements })
        }
        selectedValue={selectedTitleArrangement}>
        {KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS.map((alignment) => (
          <ToggleItem key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
      <Toggle
        className={configInputClass}
        label="Vertical Alignment"
        labelHelpText={isFixed ? 'Only affects value when title/value is fixed' : undefined}
        onValueChange={(value) =>
          updateGeneralFormat({
            vertical_content_alignment: value as VerticalContentAlignments,
          })
        }
        selectedValue={selectedVertAlignment}>
        {KPI_ORDERED_VERT_CONTENT_ALIGNMENTS.map((alignment) => (
          <ToggleItem key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
      <Toggle
        className={configInputClass}
        label="Horizontal Alignment"
        labelHelpText={isFixed ? 'Disabled when title/value is fixed' : undefined}
        onValueChange={(value) =>
          updateGeneralFormat({ alignment: value as TextElemHorizAlignments })
        }
        selectedValue={selectedHorizAlignment}>
        {KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS.map((alignment) => (
          <ToggleItem disabled={isFixed} key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
      <div className={configInputClass}>
        <Label htmlFor="">Background Color</Label>
        <ColorPickerButton
          fillWidth
          color={backgroundColor ?? DEFAULT_BACKGROUND_COLOR}
          colorPalette={getCategoricalColors(globalStyleConfig)}
          onCancel={
            backgroundColor ? () => updateGeneralFormat({ backgroundColor: undefined }) : undefined
          }
          onColorChange={(value) => updateGeneralFormat({ backgroundColor: value })}
        />
      </div>
      <Input
        className={configInputClass}
        defaultValue={opacity?.toString() ?? DEFAULT_OPACITY}
        label="Opacity"
        onSubmit={(newOpacity) => {
          const opacityNum = parseFloat(newOpacity);
          if (isNaN(opacityNum) || opacityNum > 1 || opacityNum <= 0) return;
          updateGeneralFormat({ opacity: opacityNum });
        }}
      />
    </>
  );
};
