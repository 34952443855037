import {
  BOUNDARY_FORMAT_TO_MATCH,
  BOUNDARY_TYPE_TO_SOURCE_LAYER,
  BOUNDARY_TYPE_TO_TILESET,
  DEFAULT_BOUNDARY_FORMAT,
  DEFAULT_BOUNDARY_TYPE,
} from 'constants/maps';
import { BoundaryFormat, BoundaryType, ChoroplethMapFormat } from 'types/maps';

const VALID_LATITUDE_RANGE = 90;
const VALID_LONGITUDE_RANGE = 180;

const isValidLatLongValue = (value: number, maxValue: number) => {
  return !isNaN(value) && Math.abs(value) <= maxValue;
};

export const validateLatLongValues = (latitudeVal: number, longitudeVal: number) => {
  return (
    isValidLatLongValue(latitudeVal, VALID_LATITUDE_RANGE) &&
    isValidLatLongValue(longitudeVal, VALID_LONGITUDE_RANGE)
  );
};

export const getMapBoxBoundaryFormat = (
  instructions: Pick<ChoroplethMapFormat, 'boundaryFormat' | 'customBoundaryFormat'> | undefined,
) => {
  if (!instructions) return BOUNDARY_FORMAT_TO_MATCH[DEFAULT_BOUNDARY_FORMAT];

  if (instructions.boundaryFormat === BoundaryFormat.CUSTOM)
    return instructions.customBoundaryFormat ?? BOUNDARY_FORMAT_TO_MATCH[DEFAULT_BOUNDARY_FORMAT];

  return BOUNDARY_FORMAT_TO_MATCH[instructions.boundaryFormat ?? DEFAULT_BOUNDARY_FORMAT];
};

export const getMapBoxBoundaryType = (
  instructions:
    | Pick<
        ChoroplethMapFormat,
        'boundaryType' | 'customBoundarySourceLayer' | 'customBoundaryTileset'
      >
    | undefined,
) => {
  if (!instructions) {
    const sourceLayer = BOUNDARY_TYPE_TO_SOURCE_LAYER[DEFAULT_BOUNDARY_TYPE];
    const tileset = BOUNDARY_TYPE_TO_TILESET[DEFAULT_BOUNDARY_TYPE];

    return { sourceLayer, tileset };
  }

  const { boundaryType, customBoundarySourceLayer, customBoundaryTileset } = instructions;

  if (boundaryType === BoundaryType.CUSTOM) {
    const sourceLayer =
      customBoundarySourceLayer ?? BOUNDARY_TYPE_TO_SOURCE_LAYER[DEFAULT_BOUNDARY_TYPE];
    const tileset = customBoundaryTileset ?? BOUNDARY_TYPE_TO_TILESET[DEFAULT_BOUNDARY_TYPE];

    return { sourceLayer, tileset };
  }

  const currBoundaryType = boundaryType ?? DEFAULT_BOUNDARY_TYPE;

  const sourceLayer = BOUNDARY_TYPE_TO_SOURCE_LAYER[currBoundaryType];
  const tileset = BOUNDARY_TYPE_TO_TILESET[currBoundaryType];

  return { sourceLayer, tileset };
};
