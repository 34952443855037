import { FC, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { SettingHeader } from 'components/SettingHeader';
import { Button, Select, Tooltip, sprinkles, Input, Label } from 'components/ds';
import {
  BOUNDARY_FORMAT_TO_MATCH,
  BOUNDARY_TYPE_TO_SOURCE_LAYER,
  BOUNDARY_TYPE_TO_TILESET,
  DEFAULT_BOUNDARY_FORMAT,
  DEFAULT_BOUNDARY_TYPE,
  MAP_STYLE_TO_MAPBOX_URL,
  MAP_STYLES_SELECT_VALUES,
} from 'constants/maps';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { GlobalStylesContext } from 'globalStyles';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { MapTooltipConfig } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/formatSections/MapTooltipConfig';
import { updateOperationConfigThunk } from 'reducers/thunks/dataPanelConfigThunks';
import { showSuccessToast } from 'shared/sharedToasts';
import { BoundaryFormat, BoundaryType } from 'types/maps';
import { saveMapViewState } from 'utils/customEventUtils';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
};

export const ChoroplethMapConfig: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();

  // Load custom styles for default minimum and maximum colors
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const { gradientPalette } = globalStyleConfig.visualizations;

  // For backward compatibility with the previous method of storing map style as the value string
  const configStyle = instructions.chartSpecificFormat?.choroplethMap?.style;
  const selectedMapStyle = useMemo(() => {
    const selectedStyle = Object.entries(MAP_STYLE_TO_MAPBOX_URL).find(
      ([label, style]) => label === configStyle || style === configStyle,
    );
    return selectedStyle ? selectedStyle[0] : undefined;
  }, [configStyle]);

  return (
    <>
      <SettingHeader name="Map Options" />
      <div className={sprinkles({ margin: 'sp1.5', flexItems: 'column', gap: 'sp1.5' })}>
        <Select
          label="Boundary Type"
          onChange={(value) =>
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                instructions,
                (draft) => {
                  ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).boundaryType =
                    value as BoundaryType;
                },
              ),
            )
          }
          selectedValue={instructions.chartSpecificFormat?.choroplethMap?.boundaryType}
          values={BOUNDARY_TYPES}
        />
        {instructions.chartSpecificFormat?.choroplethMap?.boundaryType === BoundaryType.CUSTOM ? (
          <>
            <Input
              showInputButton
              defaultValue={
                instructions.chartSpecificFormat?.choroplethMap?.customBoundarySourceLayer ??
                BOUNDARY_TYPE_TO_SOURCE_LAYER[DEFAULT_BOUNDARY_TYPE]
              }
              label={{
                text: 'Custom Boundary Source Layer',
                infoText:
                  'This is the name of the Mapbox source layer that you want to use from Mapbox Studio.',
              }}
              onSubmit={(value) =>
                dispatch(
                  updateOperationConfigThunk(
                    OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                    instructions,
                    (draft) => {
                      ((draft.chartSpecificFormat ??= {}).choroplethMap ??=
                        {}).customBoundarySourceLayer = value;
                    },
                  ),
                )
              }
            />
            <Input
              showInputButton
              defaultValue={
                instructions.chartSpecificFormat?.choroplethMap?.customBoundaryTileset ??
                BOUNDARY_TYPE_TO_TILESET[DEFAULT_BOUNDARY_TYPE]
              }
              label={{
                text: 'Custom Boundary Tileset',
                infoText:
                  'This is the name of the Mapbox tileset that you want to use from Mapbox Studio.',
              }}
              onSubmit={(value) =>
                dispatch(
                  updateOperationConfigThunk(
                    OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                    instructions,
                    (draft) => {
                      ((draft.chartSpecificFormat ??= {}).choroplethMap ??=
                        {}).customBoundaryTileset = value;
                    },
                  ),
                )
              }
            />
          </>
        ) : null}
        <Select
          label="Boundary Format"
          onChange={(value) =>
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                instructions,
                (draft) => {
                  ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).boundaryFormat =
                    value as BoundaryFormat;
                },
              ),
            )
          }
          selectedValue={instructions.chartSpecificFormat?.choroplethMap?.boundaryFormat}
          values={BOUNDARY_FORMATS}
        />
        {instructions.chartSpecificFormat?.choroplethMap?.boundaryFormat ===
        BoundaryFormat.CUSTOM ? (
          <Input
            showInputButton
            defaultValue={
              instructions.chartSpecificFormat?.choroplethMap?.customBoundaryFormat ??
              BOUNDARY_FORMAT_TO_MATCH[DEFAULT_BOUNDARY_FORMAT]
            }
            label={{
              text: 'Custom Boundary Format',
              infoText:
                'This is the value of the field in the Mapbox tileset that you want to use as the boundary.',
            }}
            onSubmit={(value) =>
              dispatch(
                updateOperationConfigThunk(
                  OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                  instructions,
                  (draft) => {
                    ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).customBoundaryFormat =
                      value;
                  },
                ),
              )
            }
          />
        ) : null}
        <Select
          label="Map Style"
          onChange={(value) =>
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                instructions,
                (draft) => {
                  ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).style = value;
                },
              ),
            )
          }
          selectedValue={selectedMapStyle}
          values={MAP_STYLES_SELECT_VALUES}
        />
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label htmlFor="">Minimum Color</Label>
          <CustomStylesColorPicker
            fill
            color={
              instructions.chartSpecificFormat?.choroplethMap?.minColor ?? gradientPalette.hue1
            }
            onClose={(newColor) => {
              dispatch(
                updateOperationConfigThunk(
                  OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                  instructions,
                  (draft) => {
                    ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).minColor = newColor;
                  },
                ),
              );
            }}
          />
        </div>
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label htmlFor="">Maximum Color</Label>
          <CustomStylesColorPicker
            fill
            color={
              instructions.chartSpecificFormat?.choroplethMap?.maxColor ?? gradientPalette.hue2
            }
            onClose={(newColor) =>
              dispatch(
                updateOperationConfigThunk(
                  OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                  instructions,
                  (draft) => {
                    ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).maxColor = newColor;
                  },
                ),
              )
            }
          />
        </div>
        <Input
          defaultValue={`${instructions.chartSpecificFormat?.choroplethMap?.colorSteps || 8}`}
          label="Color Steps"
          onSubmit={(value) => {
            const intValue = parseInt(value);
            if (isNaN(intValue) || intValue < 1) return;
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
                instructions,
                (draft) => {
                  ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).colorSteps = intValue;
                },
              ),
            );
          }}
        />

        <Tooltip text="This will save the current map view as the starting point.">
          <Button
            fillWidth
            onClick={() => {
              saveMapViewState();
              showSuccessToast('Saved Map View');
            }}>
            Save Initial View
          </Button>
        </Tooltip>
      </div>
      <MapTooltipConfig
        format={instructions.chartSpecificFormat?.choroplethMap?.tooltipFormat || {}}
        onUpdateFormat={(newFormat) =>
          dispatch(
            updateOperationConfigThunk(
              OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP,
              instructions,
              (draft) => {
                ((draft.chartSpecificFormat ??= {}).choroplethMap ??= {}).tooltipFormat = newFormat;
              },
            ),
          )
        }
      />
    </>
  );
};

const BOUNDARY_TYPES = [
  { label: 'Country', value: BoundaryType.COUNTRY },
  { label: 'US State', value: BoundaryType.STATE },
  { label: 'US Zip Code', value: BoundaryType.ZIP },
  { label: 'Custom', value: BoundaryType.CUSTOM },
];

const BOUNDARY_FORMATS = [
  { label: 'Country Name (United States of America)', value: BoundaryFormat.COUNTRY_NAME },
  { label: 'Country - ISO3 (USA)', value: BoundaryFormat.COUNTRY_CODE_3 },
  { label: 'Country - ISO2 (US)', value: BoundaryFormat.COUNTRY_CODE_2 },
  { label: 'State Name (California)', value: BoundaryFormat.STATE_NAME },
  { label: 'State Code (CA)', value: BoundaryFormat.STATE_CODE },
  { label: 'Zip Code (5 digit)', value: BoundaryFormat.ZIP_CODE },
  { label: 'Custom', value: BoundaryFormat.CUSTOM },
];
