import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { EmptyV2PivotInstructions, V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { SortOrderToggle } from 'components/SortDirectionToggles';
import { Input, Switch, sprinkles } from 'components/ds';
import { PIVOT_SUMMARY_COLUMN_HEADER } from 'components/embed/EmbedPivotTable/pivotUtils';
import { ReduxState } from 'reducers/rootReducer';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { canPivotTableSummarize } from '@explo/computation-generation';

type Props = { instructions: V2PivotTableInstructions | undefined };

export const V2PivotTableConfig: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();
  const useFido = useSelector(
    (state: ReduxState) => state.currentUser.team?.feature_flags.use_fido,
  );

  const updateInstructions = (updates: Partial<V2PivotTableInstructions>) => {
    const newInstructions = produce(instructions ?? EmptyV2PivotInstructions, (draft) => {
      return { ...draft, ...updates };
    });
    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2));
  };

  const includeRollup = instructions?.includeRollup && canPivotTableSummarize(instructions);

  return (
    <>
      <SettingHeader name="Sorting" />
      <div className={settingsSectionClass}>
        <SortOrderToggle
          label="Row Sort"
          labelHelpText="Sort the rows in either ascending or descending order"
          order={instructions?.rowSortOrder}
          updateOrder={(newOrder) => updateInstructions({ rowSortOrder: newOrder })}
        />
        <SortOrderToggle
          label="Column Sort"
          labelHelpText="Sort the pivot columns in either ascending or descending order"
          order={instructions?.columnSortOrder}
          updateOrder={(newOrder) => updateInstructions({ columnSortOrder: newOrder })}
        />
      </div>
      {useFido ? (
        <>
          <SettingHeader name="Summary" />
          <div className={settingsSectionClass}>
            <Switch
              disabled={!canPivotTableSummarize(instructions)}
              label={{
                text: 'Show Pivot Summary Column',
                infoText:
                  'Display a "Summary" column that aggregates all the values in each row. Only displayed when "Rows", "Values", and "Columns" are configured.',
              }}
              onChange={() => {
                updateInstructions({ includeRollup: !instructions?.includeRollup });
                dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }));
              }}
              switchOn={includeRollup}
            />
            {includeRollup ? (
              <>
                <Switch
                  label={{ text: 'Include Summary in Export' }}
                  onChange={() => {
                    updateInstructions({
                      includeRollupOnExport: !instructions?.includeRollupOnExport,
                    });
                  }}
                  switchOn={instructions?.includeRollupOnExport}
                />
                <Input
                  defaultValue={instructions.summaryColumnName || PIVOT_SUMMARY_COLUMN_HEADER}
                  label="Column Name"
                  onSubmit={(newValue) => {
                    if (instructions.summaryColumnName === newValue.trim()) return;
                    updateInstructions({ summaryColumnName: newValue.trim() });
                    dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }));
                  }}
                  placeholder={PIVOT_SUMMARY_COLUMN_HEADER}
                />
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};

const settingsSectionClass = sprinkles({ padding: 'sp1.5', gap: 'sp1.5', flexItems: 'column' });
