import { Icon, Popover, Position } from '@blueprintjs/core';
import { FC } from 'react';

import { OPERATION_TYPES } from '@explo/data';

import { sprinkles } from 'components/ds';
import { VIZ_TO_NAME, OP_TYPE_TO_BP3_ICON } from 'constants/dataConstants';

import { ChartSelectPopover } from './ChartSelectPopover';
import * as styles from './styles.css';

type Props = {
  dataPanelId: string;
  selectedChartType: OPERATION_TYPES;
};

export const VisualizationTypeSelector: FC<Props> = ({ dataPanelId, selectedChartType }) => {
  return (
    <div className={sprinkles({ width: 'fill' })}>
      <Popover
        captureDismiss
        fill
        minimal
        content={
          <ChartSelectPopover dataPanelId={dataPanelId} selectedChartType={selectedChartType} />
        }
        position={Position.BOTTOM_LEFT}>
        <div className={styles.visualizationTypeSelectorRoot}>
          <div className={sprinkles({ flexItems: 'alignCenter' })}>
            <div className={sprinkles({ marginRight: 'sp1' })} style={{ width: 30 }}>
              <Icon icon={OP_TYPE_TO_BP3_ICON[selectedChartType]} iconSize={20} />
            </div>
            <div>{VIZ_TO_NAME[selectedChartType]}</div>
          </div>
          <Icon icon="expand-all" />
        </div>
      </Popover>
    </div>
  );
};
