import { createReducer } from '@reduxjs/toolkit';

import {
  saveDefaultCurrencyCodeSuccess,
  saveDefaultLocaleCodeSuccess,
  saveUseBrowserLocaleSuccess,
} from 'actions/teamActions';
import { logInUserSuccess } from 'actions/userActions';
import { loadLocale } from 'utils/loadLocale';

type InternationalizationReducerState = {
  teamCurrencyCode?: string;
  teamLocaleCode?: string;
  useBrowserLocale?: boolean;
};

const internationalizationInitialState: InternationalizationReducerState = {};

export default createReducer(internationalizationInitialState, (builder) =>
  builder
    .addCase(logInUserSuccess, (state, { payload }) => {
      state.teamCurrencyCode = payload.team?.default_currency_code;
      state.teamLocaleCode = payload.team?.default_locale_code;
      state.useBrowserLocale = payload.team?.use_browser_locale;
      loadLocale(state);
    })
    .addCase(saveDefaultCurrencyCodeSuccess, (state, { payload }) => {
      state.teamCurrencyCode = payload.currency_code;
      loadLocale(state);
    })
    .addCase(saveDefaultLocaleCodeSuccess, (state, { payload }) => {
      state.teamLocaleCode = payload.locale_code;
      loadLocale(state);
    })
    .addCase(saveUseBrowserLocaleSuccess, (state, { payload }) => {
      state.useBrowserLocale = payload.setting;
      loadLocale(state);
    })
    .addDefaultCase((state) => state),
);
