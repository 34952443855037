import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';

import {
  dateTimeFromISOString,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_TYPES,
  RELATIVE_DATE_OPTIONS,
} from '@explo/data';

import { DatePickerInput } from 'components/DatePickerInput';
import { Select, Toggle, ToggleItem } from 'components/ds';
import { DASHBOARD_ELEMENT_TYPES, DATE_FILTER_TYPE, DateElemConfig } from 'types/dashboardTypes';
import { getValidDateRanges } from 'utils/dateRangeUtils';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateDefaultValuesConfig: FC<Props> = ({
  config,
  dateFilterType,
  updateDateConfig,
}) => {
  const updateConfig = useMemo(
    () => getUpdateConfigFunc(config, updateDateConfig),
    [config, updateDateConfig],
  );

  const options = useMemo(
    () =>
      getValidDateRanges(config).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [config],
  );

  if (dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
    return (
      <Select
        onCancel={() => updateConfig((draft) => (draft.defaultDateRange = undefined))}
        onChange={(value) =>
          updateConfig((draft) => (draft.defaultDateRange = value as DEFAULT_DATE_RANGES))
        }
        placeholder="Select a range"
        selectedValue={config.defaultDateRange}
        values={options}
      />
    );
  }
  return (
    <>
      <Toggle
        className={styles.configInput}
        onValueChange={(value) =>
          updateConfig((draft) => (draft.defaultType = value as DEFAULT_DATE_TYPES))
        }
        selectedValue={config.defaultType ?? DEFAULT_DATE_TYPES.RELATIVE}>
        <ToggleItem key="exact" value={DEFAULT_DATE_TYPES.EXACT}>
          Exact Date
        </ToggleItem>
        <ToggleItem key="relative" value={DEFAULT_DATE_TYPES.RELATIVE}>
          Relative Date
        </ToggleItem>
      </Toggle>
      {config.defaultType === DEFAULT_DATE_TYPES.EXACT ? (
        <DatePickerInput
          showCancelBtn
          className={styles.configInput}
          onNewValueSelect={(newValue) =>
            updateConfig((draft) => (draft.defaultValue = (newValue as DateTime) || undefined))
          }
          placeholder="Select a date"
          selectedValue={
            config.defaultValue
              ? typeof config.defaultValue === 'string'
                ? dateTimeFromISOString(config.defaultValue)
                : config.defaultValue
              : undefined
          }
          showTimeSelect={!config.hideTimeSelect}
        />
      ) : (
        <Select
          onCancel={() => updateConfig((draft) => (draft.relativeDefaultValue = undefined))}
          onChange={(value) =>
            updateConfig((draft) => (draft.relativeDefaultValue = value as RELATIVE_DATE_OPTIONS))
          }
          placeholder="Select Relative Date"
          selectedValue={config.relativeDefaultValue}
          values={Object.values(RELATIVE_DATE_OPTIONS).map((date) => ({
            value: date,
          }))}
        />
      )}
    </>
  );
};
