import { Aggregation as FidoAggregation } from '@explo-tech/fido-api';

import { AggColInfo } from '../columnTypes';
import { ChartAggregation } from '../instructions/aggregations';

export const stringReplaceAll = (s: string, match: string, newVal: string) => {
  const re = new RegExp(match, 'g');

  return s.replace(re, newVal);
};

export const getAggColName = ({ column, agg }: AggColInfo) =>
  getAggColNameBase(column.name, agg.id);

export const getAggSuffix = (aggId: ChartAggregation | FidoAggregation) =>
  `_${aggId.toLowerCase()}`;

export const getAggColNameBase = (columnName: string, aggId: ChartAggregation) => {
  const aggSuffix = getAggSuffix(aggId);
  return `${columnName}${aggSuffix}`;
};

export const titleCase = (s: string | number) => {
  if (!s) return '';

  s = String(s);

  s = s.replace(/_/g, ' ');

  // WIP - camel case to title case
  // if (s.indexOf(' ') === -1) {
  //   s = s.replace(/([A-Z])/g, ' $1');
  // }
  return capitalizeEachWord(s);
};

const capitalizeEachWord = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
