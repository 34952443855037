import { CURRENCY_MAPPING } from '@explo/data';
import { FormatLocaleDefinition, formatDefaultLocale } from 'd3-format';
import i18n from 'i18n';
import LOCALE_MAPPING from 'localization/localeMapping';
import { Settings } from 'luxon';

export interface SupportedLocale {
  name: string;
  localeCode: string;
}

export interface SupportedCurrency {
  name: string;
  currencyCode: string;
}

export const SUPPORTED_LOCALES: SupportedLocale[] = [
  { name: 'English', localeCode: 'en-us' },
  { name: 'English - United Kingdom', localeCode: 'en-gb' },
  { name: 'Portuguese - Brazil', localeCode: 'pt-br' },
  { name: 'Spanish', localeCode: 'es' },
  { name: 'French', localeCode: 'fr' },
  { name: 'German', localeCode: 'de' },
  { name: 'Italian', localeCode: 'it' },
  { name: 'Japanese', localeCode: 'ja' },
];

export const SUPPORTED_CURRENCIES: SupportedCurrency[] = [
  { name: 'US Dollar', currencyCode: 'usd' },
  { name: 'Euro', currencyCode: 'eur' },
  { name: 'Pound Sterling', currencyCode: 'gbp' },
  { name: 'United Arab Emirates Dirham', currencyCode: 'aed' },
  { name: 'Brazilian Real', currencyCode: 'brl' },
  { name: 'Rupee, Pakistani', currencyCode: 'pkr' },
  { name: 'Indonesia Rupiah', currencyCode: 'idr' },
  { name: 'Rupee, Indian', currencyCode: 'inr' },
  { name: 'Mexican Peso', currencyCode: 'mxn' },
  { name: 'Canadian Dollar', currencyCode: 'cad' },
  { name: 'Japanese Yen', currencyCode: 'jpy' },
  { name: 'Thai Baht', currencyCode: 'thb' },
  { name: 'Philipine Peso', currencyCode: 'php' },
  { name: 'Danish Krone', currencyCode: 'dkk' },
  { name: 'South African Rand', currencyCode: 'zar' },
];

const getNavigatorLanguage = () => {
  let localeCode: string;
  if (navigator.languages && navigator.languages.length) {
    localeCode = navigator.languages[0];
  } else {
    localeCode = navigator.language;
  }

  return getSupportedLanguageOrUndefined(localeCode);
};

const getSupportedLanguageOrUndefined = (localeCode: string) =>
  Object.keys(LOCALE_MAPPING).find((locale) => localeCode.toLowerCase() === locale);

const getSupportedCurrencyOrUndefined = (code: string) => {
  // str isn't the code for sterling but we accidentally set that up so now we have to convert over
  if (code === 'str') code = 'gbp';
  return SUPPORTED_CURRENCIES.find(({ currencyCode }) => currencyCode.toLowerCase() === code);
};

export const loadLocale = (options: {
  passedCurrencyCode?: string;
  passedLocaleCode?: string;
  teamCurrencyCode?: string;
  teamLocaleCode?: string;
  useBrowserLocale?: boolean;
}) => {
  const {
    passedCurrencyCode,
    passedLocaleCode,
    teamCurrencyCode,
    teamLocaleCode,
    useBrowserLocale,
  } = options;
  const defaultCurrencyCode = 'usd';
  const defaultLocaleCode = 'en-us';

  // order of preference is
  // 1) passed locale code from embedded component or via url
  // 2) browser locale, if setting is set by team
  // 3) team's default language
  // 4) en-us (fall-through for safety)
  let localeCode: string | undefined;

  // if one setting gives us a null value, try the next one or default to English if none work
  if (passedLocaleCode) localeCode = getSupportedLanguageOrUndefined(passedLocaleCode);
  if (!localeCode && useBrowserLocale) localeCode = getNavigatorLanguage();
  if (!localeCode && teamLocaleCode) localeCode = teamLocaleCode;
  if (!localeCode) localeCode = defaultLocaleCode;

  // order of preference is
  // 1) passed currency code from embedded component or via url
  // 2) team's default currency
  // 3) en-us (fall-through for safety)
  // this is decoupled from locale code
  let currencyCode: string | undefined;

  if (passedCurrencyCode)
    currencyCode = getSupportedCurrencyOrUndefined(passedCurrencyCode)?.currencyCode;
  if (!currencyCode && teamCurrencyCode) currencyCode = teamCurrencyCode;
  if (!currencyCode) currencyCode = defaultCurrencyCode;

  Settings.defaultLocale = localeCode;
  i18n.changeLanguage(localeCode);

  const numbers = (LOCALE_MAPPING[localeCode] ??
    LOCALE_MAPPING[defaultLocaleCode]) as FormatLocaleDefinition;

  numbers.currency = CURRENCY_MAPPING[currencyCode] ?? LOCALE_MAPPING[defaultCurrencyCode];
  formatDefaultLocale(numbers as FormatLocaleDefinition);

  return {
    currency: currencyCode,
    locale: localeCode,
  };
};
