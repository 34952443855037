import { FC } from 'react';

import {
  OPERATION_TYPES,
  STRING_FORMATS,
  BAR_CHART_TYPES,
  DATE_TYPES,
  NUMBER_TYPES,
  STRING,
} from '@explo/data';

import { RangeInput } from 'components/ChartConfigs/RangeInput';
import { BooleanToggle, Input, Select, Switch } from 'components/ds';
import {
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import { DateFormatDescriptiveText } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/DateFormatDescriptiveText';
import { ValueFormatConfig } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/formatSections/ValueFormatConfig';
import { getValidRange } from 'utils/numberRangeUtils';

type Instructions =
  | V2TwoDimensionChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions;

type Props = {
  instructions: Instructions;
  operationType: OPERATION_TYPES;
  configInputClass: string;
  enableScrolling?: boolean;
  isHorizontal?: boolean;

  updateXAxisFormat: (xAxisFormat: XAxisFormat) => void;
};

const StringFormatOptions = Object.values(STRING_FORMATS).map((formatOption) => ({
  value: formatOption,
}));

const DEFAULT_ROTATION_ANGLE = 45;

export const SharedXAxisConfigs: FC<Props> = ({
  instructions,
  isHorizontal,
  enableScrolling,
  configInputClass,
  operationType,
  updateXAxisFormat,
}) => {
  const { xAxisFormat } = instructions;

  const selectedStringFormat = xAxisFormat?.stringFormat?.format || STRING_FORMATS.DEFAULT;

  const isSpiderChart = operationType === OPERATION_TYPES.VISUALIZE_SPIDER_CHART;
  const isBarFunnel = operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  const areTotalValueConfigurable =
    isSpiderChart ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2;
  const areBarValuesConfigurable =
    BAR_CHART_TYPES.has(operationType) ||
    operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  let allowMaxCategories = false;
  let xAxisColType: string | undefined;
  if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    xAxisColType = (instructions as V2BoxPlotInstructions).groupingColumn?.column.type;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    xAxisColType = (instructions as V2ScatterPlotInstructions).xAxisColumn?.type;
  } else {
    const v2Instructions = instructions as V2TwoDimensionChartInstructions;
    xAxisColType = v2Instructions.categoryColumn?.column.type;
    allowMaxCategories = BAR_CHART_TYPES.has(operationType) || isSpiderChart;
  }

  const isDateColType = DATE_TYPES.has(xAxisColType ?? '');
  const isIntColType = NUMBER_TYPES.has(xAxisColType ?? '');
  const rotationAngle = xAxisFormat?.rotationAngle;

  return (
    <>
      {isBarFunnel ? null : (
        <>
          <Switch
            className={configInputClass}
            label="Axis Title"
            onChange={() => updateXAxisFormat({ showTitle: !xAxisFormat?.showTitle })}
            switchOn={xAxisFormat?.showTitle}
          />
          {xAxisFormat?.showTitle ? (
            <Input
              showInputButton
              className={configInputClass}
              defaultValue={xAxisFormat?.title}
              onSubmit={(newValue) => updateXAxisFormat({ title: newValue })}
            />
          ) : null}
        </>
      )}
      {!isBarFunnel && allowMaxCategories ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={String(xAxisFormat?.maxCategories ?? '')}
            label={{
              text: 'Max Categories',
              infoText:
                'The maximum number of categories to display. Default of 100 for performance reasons.',
            }}
            onSubmit={(newValue) => {
              const intValue = parseInt(newValue);
              updateXAxisFormat({ maxCategories: intValue >= 1 ? intValue : undefined });
            }}
          />
          {xAxisFormat?.maxCategories && !isSpiderChart && !isDateColType ? (
            <>
              <Switch
                className={configInputClass}
                label="Include Other Bar"
                onChange={() => updateXAxisFormat({ showOther: !xAxisFormat?.showOther })}
                switchOn={xAxisFormat?.showOther}
              />
              {xAxisFormat?.showOther ? (
                <Switch
                  className={configInputClass}
                  label={{
                    text: "Apply Sorting to 'Other' Bar",
                    infoText: 'Currently only supported for x-axis and y-axis sorting',
                  }}
                  onChange={() =>
                    updateXAxisFormat({
                      includeOtherInSorting: !xAxisFormat?.includeOtherInSorting,
                    })
                  }
                  switchOn={xAxisFormat?.includeOtherInSorting}
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {isIntColType && !isBarFunnel ? (
        <ValueFormatConfig instructions={xAxisFormat} updateInstructions={updateXAxisFormat} />
      ) : null}
      <Switch
        className={configInputClass}
        label="Use Logarithmic Scale"
        onChange={() => {
          const useLogScale = !xAxisFormat?.useLogScale;
          const { min } = getValidRange(xAxisFormat?.min, xAxisFormat?.max, useLogScale);
          updateXAxisFormat({ useLogScale, min });
        }}
        switchOn={xAxisFormat?.useLogScale}
      />
      <RangeInput
        className={configInputClass}
        endVal={String(xAxisFormat?.max ?? '')}
        onNewRange={(newStart, newEnd) => {
          const min = parseFloat(newStart ?? '');
          const max = parseFloat(newEnd ?? '');
          updateXAxisFormat(getValidRange(min, max, xAxisFormat?.useLogScale));
        }}
        startVal={String(xAxisFormat?.min ?? '')}
      />
      {!isSpiderChart && !isBarFunnel ? (
        <>
          <BooleanToggle
            className={configInputClass}
            falseText={isHorizontal ? 'Left' : 'Bottom'}
            label="Axis Placement"
            onValueChange={(flipAxis) => updateXAxisFormat({ flipAxis })}
            selectedValue={!!xAxisFormat?.flipAxis}
            trueText={isHorizontal ? 'Right' : 'Top'}
          />
          <Switch
            className={configInputClass}
            label="Manual Label Rotation"
            onChange={() => {
              updateXAxisFormat({
                rotationAngle: rotationAngle === undefined ? DEFAULT_ROTATION_ANGLE : undefined,
              });
            }}
            switchOn={!!rotationAngle}
          />
          {rotationAngle !== undefined ? (
            <Input
              className={configInputClass}
              defaultValue={rotationAngle.toString()}
              label="Rotation Angle"
              onSubmit={(newValue) => {
                const val = parseInt(newValue);
                updateXAxisFormat({ rotationAngle: isNaN(val) ? DEFAULT_ROTATION_ANGLE : val });
              }}
            />
          ) : null}
        </>
      ) : null}

      {isDateColType ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={xAxisFormat?.dateFormat}
            label="Date Format"
            onSubmit={(newValue) => updateXAxisFormat({ dateFormat: newValue })}
          />
          <DateFormatDescriptiveText />
        </>
      ) : null}
      {xAxisColType === STRING ? (
        <>
          <Select
            className={configInputClass}
            label="String Format"
            onChange={(value) =>
              updateXAxisFormat({
                stringFormat: { ...xAxisFormat?.stringFormat, format: value as STRING_FORMATS },
              })
            }
            selectedValue={selectedStringFormat}
            values={StringFormatOptions}
          />
          <Switch
            className={configInputClass}
            label="Remove Underscores"
            onChange={() => {
              updateXAxisFormat({
                stringFormat: {
                  ...xAxisFormat?.stringFormat,
                  replaceUnderscores: !xAxisFormat?.stringFormat?.replaceUnderscores,
                },
              });
            }}
            switchOn={xAxisFormat?.stringFormat?.replaceUnderscores}
          />
        </>
      ) : null}
      {isBarFunnel ? null : (
        <Switch
          className={configInputClass}
          label="Axis Line"
          onChange={() => updateXAxisFormat({ hideAxisLine: !xAxisFormat?.hideAxisLine })}
          switchOn={!xAxisFormat?.hideAxisLine}
        />
      )}
      {!xAxisFormat?.hideAxisLine && !isBarFunnel ? (
        <>
          {!isSpiderChart ? (
            <Switch
              className={configInputClass}
              label="Axis Ticks"
              onChange={() => updateXAxisFormat({ hideAxisTicks: !xAxisFormat?.hideAxisTicks })}
              switchOn={!xAxisFormat?.hideAxisTicks}
            />
          ) : null}
          <Switch
            className={configInputClass}
            label="Axis Labels"
            onChange={() => updateXAxisFormat({ hideAxisLabels: !xAxisFormat?.hideAxisLabels })}
            switchOn={!xAxisFormat?.hideAxisLabels}
          />
        </>
      ) : null}
      {!isBarFunnel ? (
        <Switch
          className={configInputClass}
          label="Reverse Axis"
          onChange={() => updateXAxisFormat({ reverseAxis: !xAxisFormat?.reverseAxis })}
          switchOn={xAxisFormat?.reverseAxis}
        />
      ) : null}
      {areTotalValueConfigurable ? (
        <>
          <Switch
            className={configInputClass}
            label="Show Total Values"
            onChange={() => updateXAxisFormat({ hideTotalValues: !xAxisFormat?.hideTotalValues })}
            switchOn={!xAxisFormat?.hideTotalValues}
          />
          {xAxisFormat?.hideTotalValues ? null : (
            <Input
              className={configInputClass}
              defaultValue={String(xAxisFormat?.labelPadding ?? 10)}
              label={{
                text: 'Label Padding',
                infoText:
                  'Adds padding (as a percent) to total values to prevent them from being cut off',
              }}
              onSubmit={(newValue) => {
                const labelPadding = parseInt(newValue);
                if (isNaN(labelPadding)) return;
                updateXAxisFormat({ labelPadding });
              }}
              placeholder="10"
            />
          )}
        </>
      ) : null}
      {areBarValuesConfigurable ? (
        <>
          {isBarFunnel ? null : (
            <Switch
              className={configInputClass}
              label="Show Bar Values"
              onChange={() => updateXAxisFormat({ showBarValues: !xAxisFormat?.showBarValues })}
              switchOn={xAxisFormat?.showBarValues}
            />
          )}
          <Input
            className={configInputClass}
            defaultValue={String(xAxisFormat?.barCornerRadius || 0)}
            label="Bar Corner Radius"
            onSubmit={(newValue) => {
              const intValue = parseInt(newValue);
              updateXAxisFormat({ barCornerRadius: intValue >= 0 ? intValue : undefined });
            }}
          />
        </>
      ) : null}
      {!isBarFunnel && enableScrolling ? (
        <Switch
          className={configInputClass}
          label="Enable Scroll Bar"
          onChange={() => updateXAxisFormat({ enableScroll: !xAxisFormat?.enableScroll })}
          switchOn={xAxisFormat?.enableScroll}
        />
      ) : null}
    </>
  );
};
