import { OPERATION_TYPES } from '@explo/data';

import { Dataset } from 'actions/datasetActions';
import {
  DASHBOARD_ELEMENT_TYPE_TO_NAME,
  DATE_ELEMENT_SET,
  SELECT_ELEMENT_SET,
} from 'constants/dashboardConstants';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';

export const getDefaultElementName = (
  elementType: DASHBOARD_ELEMENT_TYPES,
  config: DashboardVersionConfig,
) => {
  let elementName: string;
  if (SELECT_ELEMENT_SET.has(elementType)) elementName = 'select';
  else if (DATE_ELEMENT_SET.has(elementType)) elementName = 'date';
  else elementName = DASHBOARD_ELEMENT_TYPE_TO_NAME[elementType];

  const lowerCaseType = `${elementName.replaceAll(' ', '_').toLowerCase()}_`;
  let nextNum = 0;
  Object.values(config.elements).forEach((elem) => {
    const num = getNumOfElem(elem.name, lowerCaseType);
    if (num > nextNum) nextNum = num;
  });

  return `${lowerCaseType}${nextNum + 1}`;
};

export const getDefaultPanelProvidedId = (
  vizType: OPERATION_TYPES,
  config: DashboardVersionConfig,
): string => {
  let providedId = '';
  switch (vizType) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
      providedId = 'data_table_';
      break;
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
      providedId = 'report_builder_';
      break;
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      providedId = 'pivot_table_';
      break;
    default:
      providedId = 'chart_';
      break;
  }
  let nextNum = 0;

  const editableSectionDps = Object.values(config.editable_section?.charts ?? {}).map(
    (chart) => chart.data_panel,
  );
  const dataPanels = Object.values(config.data_panels).concat(editableSectionDps);
  dataPanels.forEach((dp) => {
    const num = getNumOfElem(dp.provided_id, providedId);
    if (num > nextNum) nextNum = num;
  });
  return `${providedId}${nextNum + 1}`;
};

const getNumOfElem = (name: string, startValue: string): number => {
  //get the latest number of element in case there are duplicates
  if (name.startsWith(startValue)) {
    return parseInt(name.split('_').slice(-1)[0]) || 0;
  }
  return 0;
};

export function getDatasetName(dataset: Dataset, replaceEmpty = true): string {
  const name = dataset.table_name;

  if (!replaceEmpty) return name;
  return name.trim() === '' ? 'Untitled' : name;
}
