import * as RadixTooltip from '@radix-ui/react-tooltip';
import cx from 'classnames';
import Color from 'color';
import { format } from 'd3-format';
import { FC, useCallback, useContext, useMemo } from 'react';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { baseThemeName, sprinkles } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { GlobalStyleConfig } from 'globalStyles/types';
import { formatValue, FormatValueOptions } from 'pages/dashboardPage/charts/utils';

import * as styles from './index.css';

export type PointData = {
  color: string | undefined;
  name: string;
  value?: number;
  format?: Omit<FormatValueOptions, 'value'>;
  selected?: boolean;
  unit?: string;
  formattedValue?: string;
};

export type Props = {
  globalStyleConfig: GlobalStyleConfig;
  header: string | undefined;
  points: PointData[];
  selectedPoints?: PointData[];
  includePct?: boolean;
  showTotal?: boolean;
  pctDecimalPlaces?: number;
};

export const ChartTooltip: FC<Props> = ({
  header,
  points,
  selectedPoints,
  includePct,
  showTotal,
  pctDecimalPlaces = 2,
  globalStyleConfig,
  children,
}) => {
  const layoutTagId = useContext(DashboardLayoutContext).dashboardLayoutTagId;

  const hasChildren = !!children;
  const globalStyleVars = useMemo(
    () => (hasChildren ? undefined : getGlobalStyleVars(globalStyleConfig)),
    [globalStyleConfig, hasChildren],
  );

  const totalValue = useMemo(
    () => points.reduce((acc, curr) => acc + (curr.value || 0), 0),
    [points],
  );

  const renderPercent = useCallback(
    (value: number) => (
      <td className={cx(styles.pointData, styles.value)}>
        {format(`.${pctDecimalPlaces}f`)((value / totalValue) * 100)}%
      </td>
    ),
    [pctDecimalPlaces, totalValue],
  );

  const getFormattedValue = useCallback(({ value, format, formattedValue }: PointData) => {
    if (formattedValue) return formattedValue;
    if (value === undefined) return '-';

    return formatValue({
      value,
      decimalPlaces: format?.decimalPlaces ?? 2,
      significantDigits: format?.significantDigits ?? 3,
      formatId: format?.formatId || V2_NUMBER_FORMATS.NUMBER.id,
      hasCommas: true,
      timeFormatId: format?.timeFormatId,
      customTimeFormat: format?.customTimeFormat,
      customDurationFormat: format?.customDurationFormat,
      multiplier: format?.multiplier,
      units: format?.units,
    });
  }, []);

  const pointsToShow = selectedPoints ?? points;

  const tooltipBackgroundColor = globalStyleConfig.container.tooltip.backgroundColor;
  const tooltipHighlightBackgroundColor = Color(tooltipBackgroundColor).lighten(0.1).hex();
  const tooltipContent = (
    <span className="explo-embed">
      <div
        className={cx(styles.root, { [baseThemeName]: !hasChildren })}
        style={{ ...globalStyleVars, backgroundColor: tooltipBackgroundColor }}>
        {header ? (
          <div className={styles.header} style={{ backgroundColor: tooltipBackgroundColor }}>
            {header}
          </div>
        ) : null}
        {pointsToShow.length > 0 ? (
          <table className={styles.pointsTable} style={{ backgroundColor: tooltipBackgroundColor }}>
            <tbody className={sprinkles({ padding: 'sp.5' })}>
              {pointsToShow.map((point) => (
                <tr
                  key={point.name}
                  style={{
                    backgroundColor: point.selected ? tooltipHighlightBackgroundColor : '',
                  }}>
                  <td className={cx(styles.pointData, sprinkles({ flexItems: 'alignCenter' }))}>
                    {point.color ? (
                      <div className={styles.pointColor} style={{ backgroundColor: point.color }} />
                    ) : null}
                    <div className={sprinkles({ marginRight: 'sp.5' })}>{point.name}</div>
                  </td>
                  <td className={cx(styles.pointData, styles.value)}>{getFormattedValue(point)}</td>
                  {includePct ? renderPercent(point.value || 0) : null}
                </tr>
              ))}
            </tbody>
            {showTotal ? (
              <tbody className={sprinkles({ padding: 'sp.5' })}>
                <tr className={styles.totalTopBorder}>
                  <td className={cx(styles.pointData, sprinkles({ flexItems: 'alignCenter' }))}>
                    <div className={sprinkles({ marginLeft: 'sp2', marginRight: 'sp.5' })}>
                      Total
                    </div>
                  </td>
                  <td className={cx(styles.pointData, styles.value)}>
                    {getFormattedValue({
                      name: 'Total',
                      format: pointsToShow[0].format,
                      value: totalValue,
                      color: undefined,
                    })}
                  </td>
                  {includePct ? renderPercent(totalValue) : null}
                </tr>
              </tbody>
            ) : null}
          </table>
        ) : null}
      </div>
    </span>
  );
  // If header is undefined and there are no points, there is nothing to draw
  if (!header && pointsToShow.length === 0) return null;

  // If no children, assume tooltip is being rendered statically for highcharts
  if (!children) return tooltipContent;

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal container={document.getElementById(layoutTagId)}>
          <RadixTooltip.Content className={sprinkles({ zIndex: 'tooltips' })} sideOffset={8}>
            {tooltipContent}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
