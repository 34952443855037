import { configureStore } from '@reduxjs/toolkit';

import rootReducer from 'reducers/rootReducer';
import {
  reportBuilderSharedMiddleware,
  reportBuilderDashboardMiddleware,
} from 'reportBuilderContent/middleware/reportBuilderDataMiddleware';
import { telemetryMiddleware } from 'telemetry/telemetryMiddleware';
import { getEnvironment } from 'utils/environmentUtils';

import { dashboardDataMiddleware } from './dashboardDataMiddleware';
import sessionExpiredMiddleware from './sessionExpiredMiddleware';
import { dataLibraryPendingChangesSerializerMiddleware } from './dataLibraryPendingChangesSerializerMiddleware';
import { dataLibraryValidatorMiddleware } from './dataLibraryValidatorMiddleware';
import { batchFidoMiddleware } from './batchFidoMiddleware';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Calendly: any;
  }
}

export default function configureExploStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // TODO: Stop passing DateTimes to actions/state. Turn to string first
      getDefaultMiddleware({
        serializableCheck: { ignoreActions: true, ignoreState: true },
      }).concat([
        sessionExpiredMiddleware,
        dashboardDataMiddleware,
        reportBuilderSharedMiddleware,
        reportBuilderDashboardMiddleware,
        telemetryMiddleware,
        dataLibraryPendingChangesSerializerMiddleware,
        dataLibraryValidatorMiddleware,
        batchFidoMiddleware,
      ]),
    preloadedState: {},
    devTools: getEnvironment() === 'development',
  });
}

export const store = configureExploStore();
