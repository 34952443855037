import { Middleware } from 'redux';
import { Action } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import {
  updateBatchedFidoFulfilledData,
  updateBatchedFidoPendingData,
} from 'reducers/dashboardDataReducer';
import { FidoBatchedActionPayload } from 'reducers/thunks/dashboardDataThunks/types';

const PENDING_BATCH_WINDOW = 100;
const FULFILLED_BATCH_WINDOW = 300;

export const batchFidoMiddleware: Middleware = () => {
  const batchedActions: Record<string, Partial<Action>[]> = {};
  const timeouts: Record<string, NodeJS.Timeout> = {};

  return (next) => (action: Action) => {
    // Only applies to fetchFidoComputationData actions
    const isPendingFidoAction = action.type.includes(
      `${ACTION.FETCH_SUMMARIZED_VIEW_DATA}/pending`,
    );
    const isFulfilledFidoAction = action.type.includes(
      `${ACTION.FETCH_SUMMARIZED_VIEW_DATA}/fulfilled`,
    );

    if (!isPendingFidoAction && !isFulfilledFidoAction) {
      return next(action);
    }
    const batchWindow = action.type.includes('pending')
      ? PENDING_BATCH_WINDOW
      : FULFILLED_BATCH_WINDOW;

    const { type: actionType, ...actionPayload } = action;

    if (batchedActions[actionType]) {
      // Add to existing batched action
      batchedActions[actionType].push(actionPayload);
      return;
    } else {
      // Create new batched action
      batchedActions[actionType] = [actionPayload];
    }

    // Clear any existing timeout
    if (timeouts[actionType]) {
      clearTimeout(timeouts[actionType]);
    }

    // Set timeout to dispatch batched action
    timeouts[actionType] = setTimeout(() => {
      const batchedAction = batchedActions[actionType];
      delete batchedActions[actionType];
      delete timeouts[actionType];
      if (isPendingFidoAction) {
        next(updateBatchedFidoPendingData(batchedAction as FidoBatchedActionPayload));
      } else if (isFulfilledFidoAction) {
        next(updateBatchedFidoFulfilledData(batchedAction as FidoBatchedActionPayload));
      }
    }, batchWindow);
  };
};
