import { sprinkles } from 'components/ds';
import { FC } from 'react';

export const FolderPageZeroStateComponent: FC = () => {
  return (
    <div className={sprinkles({ flexItems: 'center', height: 'fill', width: 'fill' })}>
      <div>
        <div
          className={sprinkles({
            color: 'contentSecondary',
            marginY: 'sp1',
          })}>
          No folders or datasets
        </div>
      </div>
    </div>
  );
};
