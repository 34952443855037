import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { INTEGER_DATA_TYPE, STRING } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import { EmbedFilterLabel, EmbedSwitch } from 'components/embed';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { DashboardVariable, DashboardVariableMap, SwitchElementConfig } from 'types/dashboardTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';

type Props = {
  config: SwitchElementConfig;
  disabled?: boolean;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  value: DashboardVariable;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  variables: DashboardVariableMap;
};

export default function DashboardSwitchElement({
  config,
  datasetNamesToId,
  datasetData,
  disabled,
  onNewValueSelect,
  value,
  variables,
}: Props) {
  const dispatch = useDispatch();

  const valueAsString = String(value);
  const isVarOn =
    valueAsString === config.onStatusLabel ||
    valueAsString === config.onStatusValue ||
    valueAsString === 'true';
  const tooltipText = useMemo(
    () => resolveTooltipVariables(config, variables, datasetNamesToId, datasetData),
    [config, datasetData, datasetNamesToId, variables],
  );

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginTop: 'sp2.5',
      })}
      style={{ height: 32 }}>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ flexItems: 'alignCenter', marginRight: 'sp1' })}>
          <EmbedFilterLabel noBottomMargin helpText={tooltipText}>
            {config.showSwitchLabel ? label : undefined}
          </EmbedFilterLabel>
        </div>
        <EmbedSwitch
          disabled={disabled}
          onChange={() => {
            const newValue = getOppositeSwitchValue(config, isVarOn);
            onNewValueSelect(
              transformSwitchValueByType(newValue, config.switchValueType || STRING),
            );
            dispatch(sendAnalyticsEventThunk(ReportedAnalyticActionTypes.DROPDOWN_SELECTED));
          }}
          switchOn={valueAsString === undefined ? false : isVarOn}
        />
      </div>
      {config.showStatusLabel ? (
        <EmbedFilterLabel noBottomMargin className={sprinkles({ marginLeft: 'sp1' })}>
          {isVarOn ? config.onStatusLabel : config.offStatusLabel}
        </EmbedFilterLabel>
      ) : null}
    </div>
  );
}

const getOppositeSwitchValue = (config: SwitchElementConfig, isVarOn: boolean) => {
  if (isVarOn) {
    return config.offStatusValue || 'false';
  } else {
    return config.onStatusValue || 'true';
  }
};

/**
 * Transforms the switch value from the string that its configured as within the switch to the type
 * that is configured. Currently only supports string and integers and casts the integers to
 * retrieve a value from the string.
 * @param value The string value from the switch
 * @param type The configured type of value to be set as a variable
 */
const transformSwitchValueByType = (
  value: string,
  type: typeof STRING | typeof INTEGER_DATA_TYPE,
) => {
  switch (type) {
    case STRING:
      return value;
    case INTEGER_DATA_TYPE: {
      const parsedValue = parseInt(value);
      return Number.isNaN(parsedValue) ? 0 : parsedValue;
    }
  }
};
