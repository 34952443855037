import {
  ChartAggregation,
  AggregationType,
  NUMBER_TYPES,
  BaseCol,
  PivotAgg,
  OPERATION_TYPES,
} from '@explo/data';

import {
  COLLAPSIBLE_LIST_AGGREGATION_LIST,
  NON_NUMBER_AGGREGATIONS_LIST,
  AGGREGATIONS_LIST,
  NON_AGG_TYPE,
} from 'constants/dataConstants';
import { AggedChartColumnInfo } from 'constants/types';
import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';

const BIG_QUERY_NAMES = ['big_query', DATABASES.BIGQUERY];
const ROCKSET_NAMES = ['rockset', DATABASES.ROCKSET];

export const findMatchingAggColIdx = (
  aggregations: AggedChartColumnInfo[] | undefined,
  colName: string | undefined,
  aggOption: { id: string; formula?: string } | undefined,
): number => {
  if (!aggregations) return -1;

  return aggregations.findIndex(({ column, agg }) => {
    if (column.name !== colName) return false;
    // If no agg needed for column and same name then equal
    if (!aggOption) return true;

    // If its the same agg then return true, but if its a formula check the formula
    return (
      agg.id === aggOption.id &&
      (aggOption.id !== ChartAggregation.FORMULA || aggOption.formula === agg.formula)
    );
  });
};

export const findMatchingAgg = (
  aggregations: AggedChartColumnInfo[] | undefined,
  colName: string | undefined,
  aggType: AggregationType | undefined,
): AggedChartColumnInfo | undefined => {
  const index = findMatchingAggColIdx(aggregations, colName, aggType);
  return aggregations?.[index];
};

export const getDefaultAgg = (type: string): AggregationType => {
  if (NUMBER_TYPES.has(type)) return { id: ChartAggregation.SUM };
  return { id: ChartAggregation.COUNT };
};

export const getNextAggType = (
  newCol: BaseCol,
  currAggSet: Set<ChartAggregation>,
  isCollapsibleListAgg = false,
) => {
  const defaultAgg = getDefaultAgg(newCol.type);
  if (currAggSet.size === 0 || !currAggSet.has(defaultAgg.id)) return defaultAgg;

  const validAggTypes = getAggOptions(newCol.type, isCollapsibleListAgg);

  const validType = validAggTypes.find((aggType) => !currAggSet.has(aggType.id));
  if (validType) return { id: validType.id };
};

export const getAggOptions = (
  colType: string,
  isCollapsibleListAgg?: boolean,
  includeFirst?: boolean,
): { id: ChartAggregation; name: string }[] => {
  const isNumber = NUMBER_TYPES.has(colType);
  if (isCollapsibleListAgg) {
    return isNumber ? COLLAPSIBLE_LIST_AGGREGATION_LIST : NON_NUMBER_AGGREGATIONS_LIST;
  }

  const options = isNumber ? [...AGGREGATIONS_LIST] : [...NON_NUMBER_AGGREGATIONS_LIST];
  if (includeFirst) options.push(NON_AGG_TYPE);
  return options;
};

const percentileAggs = new Set([
  ChartAggregation['25_PERCENTILE'].valueOf(),
  ChartAggregation.MEDIAN.valueOf(),
  ChartAggregation['75_PERCENTILE'].valueOf(),
]);

export const isAggOptionDisabled = ({
  sourceType,
  aggId,
  aggColumns,
}: {
  sourceType: string;
  aggId?: string;
  aggColumns?: AggedChartColumnInfo[];
}): boolean | undefined => {
  return (
    // percentiles aren't implemented in rockset
    (aggId && percentileAggs.has(aggId) && ROCKSET_NAMES.includes(sourceType)) ||
    // quarter grouping is only supported by bigquery
    (PivotAgg.DATE_QUARTER === aggId && BIG_QUERY_NAMES.includes(sourceType)) ||
    // can't have bigquery and a percentile aggregation
    (aggColumns &&
      BIG_QUERY_NAMES.includes(sourceType) &&
      aggColumns.some(
        (column) =>
          column.agg.id === ChartAggregation['75_PERCENTILE'] ||
          column.agg.id === ChartAggregation['25_PERCENTILE'],
      ))
  );
};

export const isVizOptionDisabled = (opType: OPERATION_TYPES, sourceType: string) => {
  // boxplot isn't implemented in rockset (because it uses percentiles)
  return opType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2 && ROCKSET_NAMES.includes(sourceType);
};
