import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { createErrorAction, createRequestAction, createSuccessAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { createJob } from 'components/JobQueue/createJob';
import {
  ChatReportBuilderViewAIJob,
  ChatReportBuilderViewAIJobRequest,
  ChatReportBuilderViewAIJobSuccess,
  GenerateReportBuilderViewAIJob,
  GenerateReportBuilderViewAIJobRequest,
  GenerateReportBuilderViewAIJobSuccess,
  GenerateReportBuilderViewSuggestionsAIJob,
  GenerateReportBuilderViewSuggestionsAIJobParams,
  GenerateReportBuilderViewSuggestionsAIJobRequest,
  GenerateReportBuilderViewSuggestionsAIJobSuccess,
  ReportBuilderJobError,
} from 'components/JobQueue/types';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { openModalType } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { AIReducerMessage, ReportModalType } from 'reportBuilderContent/reducers/types';
import { sendReportBuilderAnalyticsEvent } from 'reportBuilderContent/thunks/analyticsThunks';
import { enqueueReportBuilderJobsThunk } from 'reportBuilderContent/thunks/jobThunks';
import { escape } from 'utils/standard';

export const openAiModalThunk = (): Thunk => (dispatch, getState) => {
  const { selectedReport, currentConfig } = getState().reportEditing;
  dispatch(openModalType({ type: ReportModalType.GENERATE_AI_VIEW }));
  dispatch(
    sendReportBuilderAnalyticsEvent(
      selectedReport,
      ReportedAnalyticActionTypes.CUSTOMER_REPORT_AI_OPENED,
      {
        dataset_id: currentConfig?.dataInfo?.datasetId,
      },
    ),
  );
};

export const fetchGenerateAiViewJob =
  (prompt: string, chartType: string): Thunk =>
  (dispatch, getState) => {
    const { selectedReport, currentConfig } = getState().reportEditing;
    const datasetId = currentConfig?.dataInfo?.datasetId;
    const versionConfig = getState().embeddedReportBuilder.reportBuilderVersion?.config;

    const dataset = datasetId && versionConfig?.datasets[datasetId];
    if (!dataset || !dataset.schema) return;

    const job = createJob({
      job_type: ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB,
      job_args: {
        schema: dataset.schema,
        message: prompt,
        chart_type: chartType,
      },
    });
    dispatch(enqueueReportBuilderJobsThunk(job));

    dispatch(
      sendReportBuilderAnalyticsEvent(
        selectedReport,
        ReportedAnalyticActionTypes.CUSTOMER_REPORT_AI_CHART_CREATED,
        {
          dataset_id: currentConfig?.dataInfo?.datasetId,
          query: '',
        },
      ),
    );
  };

export const fetchGenerateAiViewRequest =
  createRequestAction<GenerateReportBuilderViewAIJobRequest>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB,
  );

export const fetchGenerateAiViewSuccess =
  createSuccessAction<GenerateReportBuilderViewAIJobSuccess>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB,
  );

export const fetchGenerateAiViewError = createErrorAction<
  ReportBuilderJobError<GenerateReportBuilderViewAIJob>
>(ACTION.GENERATE_REPORT_BUILDER_VIEW_AI_JOB);

export const fetchChatAiViewJob =
  (message: string): Thunk =>
  (dispatch, getState) => {
    const { selectedReport, currentConfig } = getState().reportEditing;
    const { messages } = getState().reportAi;
    const datasetId = currentConfig?.dataInfo?.datasetId;
    const versionConfig = getState().embeddedReportBuilder.reportBuilderVersion?.config;

    const dataset = datasetId && versionConfig?.datasets[datasetId];
    if (!dataset || !dataset.schema) return;

    const messages1 = [
      ...messages.map((m) => ({ role: m.role, content: escape(m.content) })),
      { role: 'user', content: escape(message) } as AIReducerMessage,
    ];

    const job = createJob({
      job_type: ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB,
      job_args: { schema: dataset.schema, messages: messages1 },
    });
    dispatch(enqueueReportBuilderJobsThunk(job));

    dispatch(
      sendReportBuilderAnalyticsEvent(
        selectedReport,
        ReportedAnalyticActionTypes.CUSTOMER_REPORT_AI_MESSAGE_SENT,
        {
          dataset_id: currentConfig?.dataInfo?.datasetId,
          query: message,
        },
      ),
    );
  };

export const fetchChatAiViewRequest = createRequestAction<ChatReportBuilderViewAIJobRequest>(
  ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB,
);

export const fetchChatAiViewSuccess = createSuccessAction<ChatReportBuilderViewAIJobSuccess>(
  ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB,
);

export const fetchChatAiViewError = createErrorAction<
  ReportBuilderJobError<ChatReportBuilderViewAIJob>
>(ACTION.CHAT_REPORT_BUILDER_VIEW_AI_JOB);

export const fetchGenerateAiViewSuggestionsJob =
  (params: GenerateReportBuilderViewSuggestionsAIJobParams): Thunk =>
  (dispatch) => {
    const job = createJob({
      job_type: ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB,
      job_args: params,
    });

    dispatch(enqueueReportBuilderJobsThunk(job));
  };

export const fetchGenerateAiViewSuggestionRequest =
  createRequestAction<GenerateReportBuilderViewSuggestionsAIJobRequest>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB,
  );

export const fetchGenerateAiViewSuggestionSuccess =
  createSuccessAction<GenerateReportBuilderViewSuggestionsAIJobSuccess>(
    ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB,
  );

export const fetchGenerateAiViewSuggestionError = createErrorAction<
  ReportBuilderJobError<GenerateReportBuilderViewSuggestionsAIJob>
>(ACTION.GENERATE_REPORT_BUILDER_VIEW_SUGGESTIONS_AI_JOB);

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;
