import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDashboardDatasets } from 'actions/dashboardActions';
import { Dataset } from 'actions/datasetActions';
import { DatasetPreviewPanel } from 'components/Dataset/DatasetPreviewPanel';
import { Modal, sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';

import { DatasetMigrationDatasetList } from './datasetMigration/DatasetMigrationDatasetList';

interface Props {
  selectedResourceId: number;
  selectedResourceTitle: string;
  onClose: () => void;
}

export const MigrateDatasetsModal: FC<Props> = ({
  selectedResourceId,
  selectedResourceTitle,
  onClose,
}) => {
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardDatasets(selectedResourceId));
  }, [dispatch, selectedResourceId]);

  const { datasetsToMigrate } = useSelector((state: ReduxState) => {
    return {
      datasetsToMigrate: state.datasetMigration.datasets,
    };
  });

  const queryTextFromSelectedDataset = getQueryTextFromSelectedDataset(
    datasetsToMigrate,
    selectedDatasetId,
  );

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="large"
      subtitle={selectedResourceTitle}
      title="Migration workflow">
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        {RD.isIdle(datasetsToMigrate) ? (
          <div className={sprinkles({ flexItems: 'center', paddingY: 'sp8', width: 'fill' })}>
            <EmbedSpinner size="lg" />
          </div>
        ) : RD.isSuccess(datasetsToMigrate) ? (
          <DatasetMigrationDatasetList
            datasets={datasetsToMigrate.data}
            onDatasetSelected={setSelectedDatasetId}
          />
        ) : null}
        {selectedDatasetId && queryTextFromSelectedDataset ? (
          <DatasetPreviewPanel queryText={queryTextFromSelectedDataset} />
        ) : (
          <span className={sprinkles({ marginX: 'sp3' })}>
            {selectedDatasetId ? INCOMPLETE_QUERY_ERROR_MESSAGE : SELECT_DATASET_MESSAGE}
          </span>
        )}
      </div>
    </Modal>
  );
};

const SELECT_DATASET_MESSAGE = 'Select a dataset to migrate';

const INCOMPLETE_QUERY_ERROR_MESSAGE = 'No query or draft query found for the selected dataset';

const getQueryTextFromSelectedDataset = (
  datasetsResponse: RD.ResponseData<Record<string, Dataset>>,
  selectedDatasetId: string,
): string => {
  const datasets = RD.getOrDefault(datasetsResponse, {});
  const dataset = datasets[selectedDatasetId];

  if (!dataset) {
    return '';
  }

  return dataset.query ? dataset.query : dataset.queryDraft ? dataset.queryDraft : '';
};
