import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetSchema, NUMBER_TYPES, OPERATION_TYPES } from '@explo/data';

import { CollapsibleGroup } from 'components/CollapsibleGroup';
import { SettingHeader } from 'components/SettingHeader';
import { Switch, sprinkles } from 'components/ds';
import { VisualizeOperationInstructions } from 'constants/types';
import { updateOperationConfigThunk } from 'reducers/thunks/dataPanelConfigThunks';

import { MapTooltipConfig } from './MapTooltipConfig';

type Props = {
  instructions: VisualizeOperationInstructions;
  schema: DatasetSchema;
};

export const LocationMarkerMapTooltipConfig: FC<Props> = ({ instructions, schema }) => {
  const dispatch = useDispatch();

  const latitudeColumnName = instructions.VISUALIZE_GEOSPATIAL_CHART?.latitudeColumn?.name;
  const longitudeColumnName = instructions.VISUALIZE_GEOSPATIAL_CHART?.longitudeColumn?.name;
  const formattableSchema = useMemo(
    () =>
      schema.filter(
        (col) =>
          NUMBER_TYPES.has(col.type) &&
          latitudeColumnName !== col.name &&
          longitudeColumnName !== col.name,
      ),
    [schema, latitudeColumnName, longitudeColumnName],
  );

  return (
    <>
      <SettingHeader
        infoText="Format the tooltip's columns and its header. You can only format numeric columns."
        name="Tooltip"
      />
      <div className={containerStyle}>
        <Switch
          label="Show header"
          onChange={() =>
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP,
                (instructions.VISUALIZE_GEOSPATIAL_CHART ??= {}),
                (draft) => {
                  (draft.mapFormat ??= {}).hideTooltipHeader = !draft.mapFormat.hideTooltipHeader;
                },
              ),
            )
          }
          switchOn={!instructions.VISUALIZE_GEOSPATIAL_CHART?.mapFormat?.hideTooltipHeader}
        />
        {formattableSchema.map((col) => (
          <CollapsibleGroup key={col.name} title={col.name}>
            <MapTooltipConfig
              hideHeader
              format={
                instructions.VISUALIZE_GEOSPATIAL_CHART?.mapFormat?.tooltipFormat?.[col.name] || {}
              }
              onUpdateFormat={(newFormat) => {
                dispatch(
                  updateOperationConfigThunk(
                    OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP,
                    (instructions.VISUALIZE_GEOSPATIAL_CHART ??= {}),
                    (draft) => {
                      ((draft.mapFormat ??= {}).tooltipFormat ??= {})[col.name] = {
                        ...draft.mapFormat.tooltipFormat[col.name],
                        ...newFormat,
                      };
                    },
                  ),
                );
              }}
            />
          </CollapsibleGroup>
        ))}
      </div>
    </>
  );
};

const containerStyle = sprinkles({ flexItems: 'column', padding: 'sp1.5', gap: 'sp1.5' });
