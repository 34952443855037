import { BaseCol } from '@explo/data';

export enum ItemTypes {
  CONFIGURATION_COLUMN = 'CONFIGURATION_COLUMN',
  DATASOURCE = 'DATASOURCE',
  DATA_TABLE_ROW = 'DATA_TABLE_ROW',
  REPORT_BUILDER_ROW = 'REPORT_BUILDER_ROW',
  TIME_PERIOD_DROPDOWN_OPTION = 'TIME_PERIOD_DROPDOWN_OPTION',
}

export type DraggableReportBuilderRow = {
  type: ItemTypes.REPORT_BUILDER_ROW;
  data: { index: number };
};

export type ConfigColumnItem = {
  type: string;
  data: BaseCol;
};
