import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { OPERATION_TYPES } from '@explo/data';

import { AlertModal, Input, Select, Toggle, ToggleItem, sprinkles } from 'components/ds';
import {
  addEditableSectionChart,
  setEditableSectionModal,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getReferencedGlobalDatasets } from 'reducers/selectors';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';
import { sortBy } from 'utils/standard';

export const AddChartModal: FC = () => {
  const dispatch = useDispatch();

  const [chartName, setChartName] = useState('Chart');
  const [datasetId, setDatasetId] = useState<string | undefined>();
  const [selectedGlobalDatasetReference, setSelectedGlobalDatasetReference] = useState<
    VersionedComputedViewReference | undefined
  >();
  const [vizType, setVizType] = useState(OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const { datasets, referencedGlobalDatasets, isNewPivotTableEnabled, enableDataLibrary } =
    useSelector(
      (state: ReduxState) => ({
        datasets: state.dashboardEditConfig.config?.datasets,
        referencedGlobalDatasets: getReferencedGlobalDatasets(state),
        isNewPivotTableEnabled: !!state.currentUser.team?.feature_flags.enable_new_pivot_table,
        enableDataLibrary: state.currentUser.team?.feature_flags.enable_data_library,
      }),
      shallowEqual,
    );

  const orderedDatasetOptions = useMemo(() => {
    const datasetOptions: { label: string; value: string }[] = [];
    Object.values(datasets ?? {}).forEach((ds) => {
      if (!ds.schema?.length) return;
      datasetOptions.push({ label: ds.table_name, value: ds.id });
    });

    return sortBy(datasetOptions, 'label');
  }, [datasets]);

  const orderedGlobalDatasetOptions = useMemo(() => {
    const globalDatasetOptions: { label: string; value: string }[] = [];
    Object.values(referencedGlobalDatasets).forEach((globalDataset) => {
      if (!globalDataset.columnDefinitions.length) {
        return;
      }
      globalDatasetOptions.push({ label: globalDataset.name, value: globalDataset.id ?? '' });
    });

    return sortBy(globalDatasetOptions, 'label');
  }, [referencedGlobalDatasets]);

  const disabled = !chartName.trim() || (!datasetId && !selectedGlobalDatasetReference);

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        disabled,
        text: 'Add',
        onClick: () => {
          if (disabled) return;
          dispatch(
            addEditableSectionChart({
              id: v4(),
              name: chartName,
              datasetId: datasetId ?? '',
              vizType,
              globalDatasetReference: selectedGlobalDatasetReference,
            }),
          );
        },
        variant: 'primary',
      }}
      onClose={() => dispatch(setEditableSectionModal(null))}
      title="Add chart">
      <div className={sprinkles({ paddingX: 'sp2' })} ref={setContainer}>
        <Input defaultValue={chartName} label="Chart Title" onSubmit={setChartName} />
        <Toggle
          className={sprinkles({ marginY: 'sp2' })}
          label="Chart Type"
          onValueChange={(newType) => setVizType(newType as OPERATION_TYPES)}
          selectedValue={vizType}>
          <ToggleItem value={OPERATION_TYPES.VISUALIZE_TABLE}>Table</ToggleItem>
          {isNewPivotTableEnabled ? (
            <ToggleItem value={OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2}>Pivot Table</ToggleItem>
          ) : null}
          <ToggleItem value={OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2}>Other Chart</ToggleItem>
        </Toggle>
        <Select
          collisionBoundary={container}
          label="Dataset"
          onChange={(datasetId) => {
            setDatasetId(datasetId);
            setSelectedGlobalDatasetReference(undefined);
          }}
          placeholder="Select a dataset..."
          selectedValue={datasetId}
          values={orderedDatasetOptions}
        />
        {enableDataLibrary ? (
          <Select
            className={sprinkles({ marginY: 'sp1' })}
            collisionBoundary={container}
            label="Global Dataset"
            onChange={(selectedDatasetId) => {
              setSelectedGlobalDatasetReference({
                id: selectedDatasetId,
                versionId: referencedGlobalDatasets[selectedDatasetId]?.versionId ?? '',
                namespaceId: referencedGlobalDatasets[selectedDatasetId]?.namespaceId ?? '',
              });
              setDatasetId(undefined);
            }}
            placeholder="Select a global dataset..."
            selectedValue={selectedGlobalDatasetReference?.id}
            values={orderedGlobalDatasetOptions}
          />
        ) : null}
      </div>
    </AlertModal>
  );
};
