import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useScript } from 'usehooks-ts';

import { fetchDashboardList } from 'actions/dashboardActions';
import { fetchUserTeam } from 'actions/teamActions';
import { DashboardSelector } from 'components/DashboardSelector';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { sprinkles } from 'components/ds';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { STYLE_TABS } from 'globalStyles/constants';
import { loadFonts } from 'globalStyles/utils';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { ReduxState } from 'reducers/rootReducer';
import { resetStylingConfigReducer } from 'reducers/styleConfigReducer';
import { getEnvironment } from 'utils/environmentUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { cloneDeep } from 'utils/standard';

import { getOrDefault, hasNotReturned } from '../../remotedata';

import { ComponentPreview } from './ComponentConfigs/ComponentPreview';
import { CustomizeStyleConfigPane } from './CustomizeStyleConfigPane';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown;
    }
  }
}

const topBarClass = sprinkles({
  flexItems: 'alignCenter',
  paddingX: 'sp2',
  borderBottom: 1,
  borderColor: 'gray7',
});

const EXAMPLE_CUSTOMER_TOKEN = '8898b421f165453944df5e1bb7a792ee5f6c09ce1251a6afbfd10e608e4a4fe5';
const EXAMPLE_EMBED_ID = 'aovARZVAlV';

export const CustomizeStylesPage: FC = () => {
  const dispatch = useDispatch();

  const {
    editingConfig,
    defaultConfig,
    editingThemeName,
    additionalConfigs,
    dashboards,
    team,
    selectedUserGroup,
    selectedDashboardId,
    currentUser,
    fontConfig,
  } = useSelector((state: ReduxState) => {
    const selectedDashboardId = state.styleConfig.selectedDashboardId;
    return {
      dashboards: state.dashboard.dashboardList,
      editingThemeName: state.styleConfig.editingThemeName,
      editingConfig: state.styleConfig.editingConfig,
      defaultConfig: state.dashboardStyles.globalStyleConfig,
      additionalConfigs: state.dashboardStyles.additionalStyleConfigOptions,
      team: state.teamData.data,
      selectedUserGroup: selectedDashboardId ? getSelectedCustomer(state.customers) : undefined,
      selectedDashboardId,
      currentUser: state.currentUser,
      fontConfig: state.dashboardStyles.fontConfig,
    };
  }, shallowEqual);
  const [tabId, setTabId] = useState(STYLE_TABS.OVERVIEW);

  const originalConfig = useMemo(
    () => (editingThemeName ? additionalConfigs[editingThemeName] : defaultConfig),
    [editingThemeName, additionalConfigs, defaultConfig],
  );

  const currentConfig = editingConfig ?? originalConfig;

  const bundleSourceUrl =
    getEnvironment() == 'development'
      ? 'https://embed.explo.co/'
      : process.env.REACT_APP_EMBED_ASSET_URL;

  useScript(`${bundleSourceUrl}/bundle.js`, {
    removeOnUnmount: true,
  });

  useEffect(() => {
    return () => {
      dispatch(resetStylingConfigReducer());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!team) dispatch(fetchUserTeam());
  }, [dispatch, team]);

  useEffect(() => {
    if (!dashboards) dispatch(fetchDashboardList());
  }, [dispatch, dashboards, currentUser.team?.id]);

  useEffect(() => {
    if (!team || !currentConfig?.text || hasNotReturned(fontConfig)) return;
    loadFonts(currentConfig.text, getOrDefault(fontConfig, []), team.id, true);
  }, [currentConfig?.text, fontConfig, team]);

  const stylesToApply = useMemo(() => {
    if (!currentConfig) return '';
    const clonedStyles = cloneDeep(currentConfig);
    clonedStyles.base.numColumns = 12;
    return JSON.stringify(clonedStyles);
  }, [currentConfig]);

  const selectedDashboard = useMemo(
    () =>
      selectedDashboardId
        ? dashboards?.find((dashboard) => dashboard.id === selectedDashboardId)
        : undefined,
    [selectedDashboardId, dashboards],
  );

  const publishedDashboards = useMemo(
    () =>
      dashboards?.filter((dashboard) =>
        dashboard.latest_versions.some((version) => !version.is_draft),
      ) ?? [],
    [dashboards],
  );

  const userCanEdit = useMemo(
    () =>
      doesUserHavePermission(
        currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOM_STYLES],
        PERMISSIONED_ACTIONS.UPDATE,
      ),
    [currentUser],
  );

  if (!currentConfig) return null;

  const renderDashboardSelectorBar = () => {
    if (
      !doesUserHavePermission(
        currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
        PERMISSIONED_ACTIONS.READ,
      )
    )
      return null;

    if (publishedDashboards.length === 0)
      return (
        <span>To preview a specific dashboard with custom styles, first publish a dashboard.</span>
      );

    return (
      <>
        <div className={sprinkles({ marginRight: 'sp1' })}>Viewing</div>
        <DashboardSelector dashboards={publishedDashboards} />
        <div className={sprinkles({ marginX: 'sp1' })}>as</div>
        <ViewingAsCustomerSelector
          disabled={!selectedDashboard}
          overwriteSelectedUserGroupToNull={!selectedDashboard}
        />
      </>
    );
  };

  const dashUserGroupToken =
    selectedDashboard?.embed_id && selectedUserGroup?.token
      ? `${selectedDashboard.embed_id}:${selectedUserGroup.token}`
      : `${EXAMPLE_EMBED_ID}:${EXAMPLE_CUSTOMER_TOKEN}`;

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        widthConstraints: 'minOnly',
        backgroundColor: 'white',
      })}>
      <CustomizeStyleConfigPane
        currentConfig={currentConfig}
        editingThemeName={editingThemeName}
        originalConfig={originalConfig}
        setTabId={setTabId}
        tabId={tabId}
      />
      <div
        className={sprinkles({ flex: 1, height: 'fill', overflowY: 'scroll' })}
        key={JSON.stringify(currentConfig)}>
        {userCanEdit && tabId === STYLE_TABS.COMPONENTS ? (
          <ComponentPreview styleConfig={currentConfig} />
        ) : (
          <>
            <div className={topBarClass} style={{ height: 64 }}>
              {renderDashboardSelectorBar()}
            </div>
            <explo-dashboard
              updateUrlParams
              custom-styles={stylesToApply}
              dash-customer-token={dashUserGroupToken}
              isProduction={false}
            />
          </>
        )}
      </div>
    </div>
  );
};
