import { isEnvironmentSecure } from 'utils/environmentUtils';

// any changes here need to be reflected in FeatureFlags in team.py
export type FeatureFlags = {
  use_job_queue: boolean;
  use_fido: boolean;
  use_rover: boolean;
  enable_report_builder_image_export?: boolean;
  enable_dashboard_timezones?: boolean;
  show_unfiltered_dataset_flag?: boolean;
  enable_new_grid?: boolean;
  use_archetype_variable_filters?: boolean;
  enable_new_pivot_table?: boolean;
  allow_dashboard_state_persistance?: boolean;
  enable_fill_missing_date_range?: boolean;
  enable_drilldown_dashboards?: boolean;
  can_only_use_fido_data_sources?: boolean;
  enable_fido_version_control?: boolean;
  enable_data_library?: boolean;
  enable_data_library_v2?: boolean;
  enable_data_library_debug_actions?: boolean;

  is_under_maintenance?: boolean;
};

// any changes here need to be reflected in Configuration in team.py
export type Configuration = {
  pdf_max_rows?: number;
  email_from_name?: string;
  email_from_email?: string;
  require_dataset_customer_id?: boolean;
  // Incident needs 100k rows in their dropdowns, this is hopefully temporary until we support some kind of look-ahead search
  dataset_max_rows?: number;
  // Together needs more data points on their charts, this is hopefully temporary until we support more dynamic groupings
  data_panel_max_data_points?: number;
  // Potentially we should make this user-configurable but for now its a superuser thing
  email_width_px?: number;
  rb_distinct_column_limit?: number;
};

// any changes here need to be reflected in Entitlements in team.py
export type Entitlements = {
  product_report_builder_enabled: boolean;
  report_builder_ai_enabled: boolean;
  enable_ai?: boolean;
  enable_dashboard_emails?: boolean;
  enable_editable_section?: boolean;
  enable_additional_style_configs?: boolean;
  enable_customer_portal?: boolean;
  enable_cache?: boolean;
  enable_data_panel_embedding?: boolean;
  enabled_data_sources?: string[];
  enable_screenshot_exports?: boolean;
  enable_email_exports?: boolean;
  enable_error_js_events?: boolean;
};

export const enableSSO = !(
  process.env.REACT_APP_ENVIRONMENT === 'production-medme' || isEnvironmentSecure()
);
