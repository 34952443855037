import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { getTimezoneAwareUnix } from '@explo/data';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from 'pages/ReportBuilder/HomeView/AllReportTab.css';
import { BuiltInReportCarousel } from 'pages/ReportBuilder/HomeView/BuiltInReportCarousel';
import { ReportListItem } from 'pages/ReportBuilder/HomeView/ReportListItem';
import { getIsCoreEditingEnabled } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { orderBy } from 'utils/standard';

interface Props {
  createReport: () => void;
}

export const AllReportTab: FC<Props> = ({ createReport }) => {
  const { reports, isCoreEditingEnabled } = useSelector(
    (state: ReportBuilderReduxState) => ({
      reports: state.embeddedReportBuilder.reports,
      isCoreEditingEnabled: getIsCoreEditingEnabled(state.embeddedReportBuilder),
    }),
    shallowEqual,
  );

  const filteredAndSortedReports = useMemo(() => {
    return orderBy(reports, (report) => getTimezoneAwareUnix(report.modified), 'desc');
  }, [reports]);

  const renderEmptyState = () => (
    <div className={styles.emptyState}>
      <EmbedText body="b1" color="contentPrimary">
        No reports created
      </EmbedText>
      {isCoreEditingEnabled ? (
        <EmbedButton
          className={sprinkles({ marginTop: 'sp1' })}
          onClick={createReport}
          variant="tertiary">
          Create your first report
        </EmbedButton>
      ) : null}
    </div>
  );

  return (
    <div className={styles.reportTabContainer}>
      <BuiltInReportCarousel />
      {filteredAndSortedReports.length === 0 ? (
        renderEmptyState()
      ) : (
        <div className={styles.reportListContainer}>
          {filteredAndSortedReports.map((report) => (
            <ReportListItem key={report.id} report={report} />
          ))}
        </div>
      )}
    </div>
  );
};
