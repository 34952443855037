import { ComputedView } from '@explo-tech/fido-api';
import { FC, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { addPendingResourceUpdate, revertComputedViewQuery } from 'reducers/dataLibraryReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { fetchGlobalDatasetPreviewThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { getCustomerVariables } from 'utils/customerUtils';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';

import { DatasetEditor } from './DatasetEditor';
import { getEmbeddoSchemaIdFromView } from './utils';

import { sprinkles } from '@explo/design-system';
import { DataLibraryTopBar } from 'pages/dataLibraryPage/DataLibraryTopBar';
import { FetchOrigin } from 'reducers/thunks/dashboardDataThunks/types';
import { parseQueryThunk } from 'reducers/thunks/fidoThunks/viewThunks';
import { getDatasetConfigFromView, getEmbeddoSchemaFromFidoSchema } from 'utils/fido/fidoShims';

type Props = {
  selectedView: ComputedViewWithIds;
  selectedDataset: Dataset;
  baseQuery: string;
};

export const GlobalDatasetFidoDataFetcher: FC<Props> = ({
  selectedDataset,
  selectedView,
  baseQuery,
}) => {
  const dispatch = useDispatch();

  const { parentSchemas, datasetData, customer, archetypePropertiesSet, customVariables } =
    useSelector(
      (state: ReduxState) => ({
        parentSchemas: getParentSchemasList(state),
        datasetData: state.dataLibrary.datasetData,
        customer: getSelectedCustomer(state.customers),
        archetypePropertiesSet: getArchetypeProperties(state),
        customVariables: state.dataLibrary.variables,
      }),
      shallowEqual,
    );

  const parentSchemaId = useMemo(
    () => getEmbeddoSchemaIdFromView(parentSchemas, selectedView),
    [parentSchemas, selectedView],
  );
  const data = datasetData[selectedView.id]?.[selectedView.versionId ?? ''];

  const customerVariables = useMemo(
    () => (customer ? getCustomerVariables(customer, archetypePropertiesSet) : {}),
    [customer, archetypePropertiesSet],
  );

  const variables = useMemo(
    () => ({ ...customerVariables, ...customVariables }),
    [customerVariables, customVariables],
  );

  const getUnderlyingData = useCallback(
    (query: string, pageNumber?: number) => {
      dispatch(
        fetchGlobalDatasetPreviewThunk(
          {
            query,
            parentSchemaId: parentSchemaId,
            selectedView,
            variables: variables,
          },
          FetchOrigin.DATA_LIBRARY,
          pageNumber,
        ),
      );
    },
    [parentSchemaId, dispatch, selectedView, variables],
  );

  const onSaveQueryDraft = useCallback(
    (query: string | undefined) => {
      dispatch(
        addPendingResourceUpdate({
          ...selectedView,
          query: query ?? '',
        }),
      );

      dispatch(parseQueryThunk({ datasetId: selectedView.id, request: { query: query ?? '' } }));
    },
    [selectedView, dispatch],
  );

  const onRevertDraft = useCallback(() => {
    if (selectedView.query === baseQuery) {
      return;
    }

    dispatch(revertComputedViewQuery(selectedView));
  }, [selectedView, baseQuery, dispatch]);

  const savedSchema = getEmbeddoSchemaFromFidoSchema(selectedView.columnDefinitions ?? []);

  const isPreviewButtonDisabledFn = useCallback(() => {
    const selectedDatasetParameters = selectedView.parameters ?? [];

    const hasUnsetSavedParmeter = selectedDatasetParameters.some((parameter) => {
      const parameterName = parameter.name;
      if (
        !selectedView?.query?.includes(parameterName) ||
        archetypePropertiesSet.has(parameterName)
      ) {
        return false;
      }

      return !parameter.type;
    });

    return hasUnsetSavedParmeter;
  }, [selectedView, archetypePropertiesSet]);

  return (
    <div
      className={sprinkles({
        flexItems: 'column',
        parentContainer: 'fill',
        backgroundColor: 'white',
      })}>
      <DataLibraryTopBar selectedView={selectedView} />
      <DatasetEditor
        // @ts-ignore
        activeDatasetConfig={getDatasetConfigFromView(selectedView, selectedDataset)}
        activeDatasetData={data ?? null}
        activeDatasetSavedSchema={savedSchema}
        activeDatasetSchema={data?.schema ?? savedSchema}
        activeQuery={selectedDataset.queryDraft ?? selectedDataset.query ?? ''}
        createNewParam={() => ({ id: 'global-dataset-library', name: '', type: 'string' })}
        fetchData={getUnderlyingData}
        isPreviewButtonDisabledFn={isPreviewButtonDisabledFn}
        onRevertDraft={onRevertDraft}
        onSaveDraft={onSaveQueryDraft}
        onSelectSchema={(schemaId) => {
          const selectedSchema = parentSchemas.find((schema) => schema.id === schemaId);
          if (!selectedSchema) {
            return;
          }

          const updatedComputedView: ComputedView = {
            ...selectedView,
            namespaceId: selectedSchema.fido_id,
            columnDefinitions: [],
          };
          dispatch(addPendingResourceUpdate(updatedComputedView));
        }}
        parentSchemas={parentSchemas}
        renderFormattingTab={false}
        selectedDatasetId={selectedDataset.id}
        variables={variables}
      />
    </div>
  );
};
