import { Divider, Icon } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import produce from 'immer';
import { FC, useState } from 'react';

import {
  BaseCol,
  DatasetSchema,
  DATE_TYPES,
  DateDisplayOptions,
  NUMBER_TYPES,
  NumberDisplayOptions,
  OPERATION_TYPES,
  STRING,
} from '@explo/data';

import { DateFormatConfig } from 'components/ColumnFormatConfigs/DateFormatConfig';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { ProgressBarConfiguration } from 'components/ColumnFormatConfigs/NumberFormatConfig/ProgressBarConfiguration';
import { SortableListItemDragHandle } from 'components/SortableList/SortableList';
import { sprinkles } from 'components/ds';
import { VisualizeCollapsibleListInstructions } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  columnConfigRoot: {
    margin: theme.spacing(3),
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  columnConfigRow: ({ isExpanded }: { isExpanded?: boolean }) => ({
    backgroundColor: theme.palette.ds.grey300,
    borderRadius: 4,
    ...{ borderBottomLeftRadius: isExpanded ? 0 : 4, borderBottomRightRadius: isExpanded ? 0 : 4 },
    color: theme.palette.ds.grey900,
    padding: `0px ${theme.spacing(2)}px`,
    paddingLeft: 8,
    height: 32,
    cursor: 'pointer',
    '&.bp3-icon': {
      color: theme.palette.ds.grey900,
    },
  }),
  columnNameText: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  columnConfigMenu: {
    backgroundColor: theme.palette.ds.grey100,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  dragIconColor: { color: theme.palette.ds.grey700 },
}));

type Props = {
  column: BaseCol;
  instructions: VisualizeCollapsibleListInstructions;
  updateInstructions: (instructions: VisualizeCollapsibleListInstructions) => void;
  schema: DatasetSchema;
  isRowColumn?: boolean;
};

export const CollapsibleListItemConfig: FC<Props> = ({
  column,
  instructions,
  updateInstructions,
  schema,
  isRowColumn,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles({ isExpanded });

  const columnDisplayName = column.friendly_name || column.name;
  const columnConfig = isRowColumn
    ? instructions.groupByDisplayOptions?.[column.name]
    : instructions.numberDisplayOptions?.[column.name];

  const addFieldsToGroupByDisplayOptions = (
    newFields: NumberDisplayOptions | DateDisplayOptions,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.groupByDisplayOptions) draft.groupByDisplayOptions = {};
      draft.groupByDisplayOptions[column.name] = {
        ...draft.groupByDisplayOptions[column.name],
        ...newFields,
      };
    });

    updateInstructions(newInstructions);
  };

  const addFieldsToNumberDisplayOptions = (newFields: NumberDisplayOptions) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.numberDisplayOptions) draft.numberDisplayOptions = {};
      draft.numberDisplayOptions[column.name] = {
        ...draft.numberDisplayOptions[column.name],
        ...newFields,
      };
    });

    updateInstructions(newInstructions);
  };

  const updateNumberOptionsFn = isRowColumn
    ? addFieldsToGroupByDisplayOptions
    : addFieldsToNumberDisplayOptions;

  return (
    <div className={classes.columnConfigRoot}>
      <div
        className={cx(classes.columnConfigRow, sprinkles({ flexItems: 'alignCenterBetween' }))}
        onClick={() => setIsExpanded(!isExpanded)}>
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          {!isRowColumn ? <SortableListItemDragHandle className={classes.dragIconColor} /> : null}
          <span className={classes.columnNameText}>{columnDisplayName}</span>
        </div>
        {column.type !== STRING ? (
          <Icon icon={isExpanded ? 'caret-down' : 'caret-right'} iconSize={16} />
        ) : null}
      </div>
      {isExpanded && column.type !== STRING ? (
        <div className={classes.columnConfigMenu}>
          {DATE_TYPES.has(column.type) && (
            <DateFormatConfig
              column={column}
              displayOptions={columnConfig as DateDisplayOptions}
              operationType={OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST}
              updateDateOptions={addFieldsToGroupByDisplayOptions}
            />
          )}
          {NUMBER_TYPES.has(column.type) ? (
            <>
              <NumberFormatConfig
                column={column}
                displayOptions={columnConfig as NumberDisplayOptions}
                operationType={OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST}
                updateNumberOptions={updateNumberOptionsFn}
              />
              {!isRowColumn ? (
                <>
                  <Divider className={classes.divider} />
                  <div className={sprinkles({ paddingTop: 'sp1.5' })}>
                    <ProgressBarConfiguration
                      displayOptions={columnConfig as NumberDisplayOptions}
                      operationType={OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST}
                      originalSchema={schema}
                      updateNumberOptions={updateNumberOptionsFn}
                    />
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
