import { createSlice } from '@reduxjs/toolkit';

import {
  createNewDashboardVersionSuccess,
  deleteCurrentDashboardDraftSuccess,
  fetchDashboardVersionsMetadataError,
  fetchDashboardVersionsMetadataRequest,
  fetchDashboardVersionsMetadataSuccess,
  publishNewDashboardVersionSuccess,
} from 'actions/dashboardV2Actions';
import * as RD from 'remotedata';
import { DashboardVersionMetadata } from 'types/dashboardVersion';

import { saveExploreDraft } from './thunks/resourceSaveThunks';
import { revertResourceToVersionThunk } from './thunks/versionManagementThunks';

interface DashboardVersionsReducerState {
  versionsMetadata: RD.ResponseData<DashboardVersionMetadata[]>;
}

const initialState: DashboardVersionsReducerState = {
  versionsMetadata: RD.Idle(),
};

const dashboardVersionsSlice = createSlice({
  name: 'dashboardVersions',
  initialState,
  reducers: {
    clearDashboardVersions: (state) => {
      state.versionsMetadata = RD.Idle();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardVersionsMetadataRequest, (state) => {
        state.versionsMetadata = RD.Loading();
      })
      .addCase(fetchDashboardVersionsMetadataError, (state) => {
        state.versionsMetadata = RD.Error('Error fetching dashboard versions metadata');
      })
      .addCase(fetchDashboardVersionsMetadataSuccess, (state, { payload }) => {
        state.versionsMetadata = RD.Success(payload.versions_metadata);
      })
      .addCase(createNewDashboardVersionSuccess, (state, { payload }) => {
        const { configuration: _, ...dashboard_version_metadata } = payload.dashboard_version;
        RD.update(state.versionsMetadata, (versionsMetadata) =>
          versionsMetadata.unshift(dashboard_version_metadata),
        );
      })
      .addCase(revertResourceToVersionThunk.fulfilled, (state, { payload, meta }) => {
        const { new_version } = payload;
        if (!meta.arg.isExplore || !('configuration' in new_version)) return;

        RD.update(state.versionsMetadata, (versionsMetadata) => {
          const index = versionsMetadata.findIndex(
            ({ version_number }) => version_number === new_version.version_number,
          );

          // Remove an active draft if present, since we are pushing a new draft based on the revert
          if (index >= 0) {
            versionsMetadata.splice(index, 1);
          }
          versionsMetadata.unshift(new_version);
        });
      })
      .addCase(saveExploreDraft.fulfilled, (state, { payload }) => {
        RD.update(state.versionsMetadata, (versionsMetadata) => {
          versionsMetadata[0].edit_version_number = payload.edit_version_number;
        });
      })
      .addCase(publishNewDashboardVersionSuccess, (state, { payload: { dashboard_version } }) => {
        RD.update(state.versionsMetadata, (versionsMetadata) => {
          const publishedVersion = versionsMetadata.find(({ id }) => id === dashboard_version.id);
          if (!publishedVersion) return;

          publishedVersion.is_draft = dashboard_version.is_draft;
          publishedVersion.published_by = dashboard_version.published_by;
          publishedVersion.change_comments = dashboard_version.change_comments;
          publishedVersion.version_saved_at = dashboard_version.version_saved_at;
        });
      })
      .addCase(deleteCurrentDashboardDraftSuccess, (state) => {
        RD.update(state.versionsMetadata, (versionsMetadata) => versionsMetadata.splice(0, 1));
      });
  },
});

export const { clearDashboardVersions } = dashboardVersionsSlice.actions;

export const dashboardVersionsReducer = dashboardVersionsSlice.reducer;
