import { Timezones } from './constants';

export const CURRENCY_MAPPING: Record<string, [string, string]> = {
  // euro
  eur: ['', '\u00a0€'],
  // us dollar
  usd: ['$', ''],
  // brazilian real
  brl: ['R$', ''],
  // pakistani rupee
  pkr: ['\u20A8\u00a0', ''],
  // pound sterling
  gbp: ['\u00A3', ''],
  // for some reason when we first built this, we set str to be british sterling.
  // That's not british sterling, it's some cryptocurrency, but now we have to
  // support this mapping in case anyone's using it, so hopefully we never support
  // the cryptocurrency Stellar
  str: ['\u00A3', ''],
  // UAE dirham
  // the space before AED is intentional here
  aed: ['', ' AED'],
  // indonesian rupiah
  idr: ['Rp', ''],
  // indian rupee
  inr: ['\u20B9', ''],
  // candian dollar
  cad: ['CA$', ''],
  // mexican peso
  mxn: ['$', ''],
  // japanese yen
  jpy: ['\u00A5', ''],
  // philipine peso
  php: ['\u20B1', ''],
  // thai baht
  thb: ['\u0E3F', ''],
  // danish krone
  dkk: ['', '\u006b\u0072'],
  // south african rand
  zar: ['R', ''],
};

export default CURRENCY_MAPPING;

const DISPLAY_TIMEZONE_STORAGE_KEY = 'explo__display_timezone';

export const setDisplayTimezone = (timezone: string) => {
  localStorage.setItem(DISPLAY_TIMEZONE_STORAGE_KEY, timezone);
};

export const getDisplayTimezone = () =>
  localStorage.getItem(DISPLAY_TIMEZONE_STORAGE_KEY) || Timezones.UTC;
