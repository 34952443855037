import { FC, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Button, IconButton, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { getEditPageDataPanels, getEditPageElements } from 'reducers/dashboardEditConfigReducer';
import {
  PanelTab,
  setIsDrillDownPreview,
  setPanelTab,
} from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getDatasetName } from 'utils/naming';
import { sortBy } from 'utils/standard';
import { DeleteDatasetModal } from './modals/DeleteDatasetModal';
import { EditDatasetModal } from './modals/EditDatasetModal';

import { ModalStatus } from './constants';
import { getDataPanelUsageTextMap } from './utils';

type Props = {
  datasetConfigs: Record<string, Dataset>;
  selectedDatasetId: string | null;
  setSelectedDatasetId: (datasetId: string | null) => void;
};

export const DatasetSelectTopBar: FC<Props> = ({
  datasetConfigs,
  selectedDatasetId,
  setSelectedDatasetId,
}) => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);

  const { elements, dataPanels } = useSelector(
    (state: ReduxState) => ({
      elements: getEditPageElements(state.dashboardEditConfig, false),
      dataPanels: getEditPageDataPanels(state.dashboardEditConfig, false),
    }),
    shallowEqual,
  );

  const usageTextMap = useMemo(
    () => getDataPanelUsageTextMap(datasetConfigs, dataPanels, elements),
    [datasetConfigs, elements, dataPanels],
  );

  const dropdownOptions: SelectItems<string> = useMemo(() => {
    const sortedDatasets = sortBy(Object.values(datasetConfigs), (dataset) =>
      dataset.table_name.toLocaleLowerCase(),
    );
    return sortedDatasets.map((dataset) => {
      const datasetId = dataset.id;
      return {
        label: getDatasetName(dataset),
        value: datasetId,
        icon: 'table',
        secondaryLabel: usageTextMap[datasetId],
      };
    });
  }, [datasetConfigs, usageTextMap]);

  const onReturnFn = useCallback(() => {
    dispatch(setSelectedDatasetId(null));
    dispatch(setIsDrillDownPreview(false));
    // Since we don't always reach the dataset editor from the datasets panel,
    // we force this button to always return to the datasets panel tab
    dispatch(setPanelTab(PanelTab.DATASETS));
  }, [dispatch, setSelectedDatasetId]);

  return (
    <div className={containerStyle}>
      <div className={sprinkles({ flexItems: 'alignCenterBetween', height: 'fill' })}>
        <Button icon="arrow-left" onClick={onReturnFn} variant="tertiary">
          Return to datasets
        </Button>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', height: 'fill' })}>
          <div className={sprinkles({ body: 'b2' })}>Viewing as</div>
          <ViewingAsCustomerSelector />
          <div
            className={sprinkles({ height: 'fill', backgroundColor: 'outline' })}
            style={{ width: 1 }}
          />
          <Button
            icon="pencil"
            onClick={() => setModalStatus(ModalStatus.EDIT_DATASET)}
            variant="tertiary">
            Rename
          </Button>
          <Button
            icon="trash-can-reg"
            onClick={() => setModalStatus(ModalStatus.DELETE_DATASET)}
            variant="tertiary">
            Delete
          </Button>
          <IconButton name="cross" onClick={onReturnFn} />
        </div>
      </div>
      {selectedDatasetId ? (
        <Select
          onChange={setSelectedDatasetId}
          selectedValue={selectedDatasetId}
          values={dropdownOptions}
          variant="secondary"
        />
      ) : null}
      {modalStatus === ModalStatus.DELETE_DATASET ? (
        <DeleteDatasetModal
          datasetConfigs={datasetConfigs}
          deleteDatasetId={selectedDatasetId}
          onClose={() => setModalStatus(ModalStatus.CLOSED)}
        />
      ) : null}
      {modalStatus === ModalStatus.EDIT_DATASET ? (
        <EditDatasetModal
          datasetConfigs={datasetConfigs}
          editDatasetId={selectedDatasetId}
          onClose={() => setModalStatus(ModalStatus.CLOSED)}
        />
      ) : null}
    </div>
  );
};

const containerStyle = sprinkles({
  flexItems: 'column',
  gap: 'sp2',
  padding: 'sp2',
  overflowX: 'auto',
  flexShrink: 0,
});
