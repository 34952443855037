import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { DashboardButtonGroupInput } from 'components/dashboardButtonGroupInput';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { SelectElemConfig, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover':
      {
        zIndex: 0,
      },
  },
}));

type Props = {
  config: SelectElemConfig;
  value?: DashboardVariable;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  disabled?: boolean;
  loading?: boolean;
  variables: DashboardVariableMap;
  onNewValueSelect: OnNewValueSelectFunc;
};

const DashboardToggleElement = ({
  config,
  disabled,
  datasetData,
  datasetNamesToId,
  value,
  onNewValueSelect,
  loading,
  variables,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const options = useMemo(
    () => constructOptions(config.valuesConfig, datasetData),
    [config.valuesConfig, datasetData],
  );

  const selectedOption = useMemo(() => {
    if (options == null) return undefined;

    let selectedOption = options.find((option) => option.value === value);

    // If the selected value is not in the options, it is coming from a default variable.
    // In this case create your own option for this
    if (value && !selectedOption) {
      selectedOption = { id: String(value), name: String(value) };
    }

    return selectedOption;
  }, [options, value]);

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <div className={classes.root}>
      <DashboardButtonGroupInput
        disabled={disabled}
        infoTooltipText={resolveTooltipVariables(config, variables, datasetNamesToId, datasetData)}
        label={label}
        loading={loading}
        onChange={(item) => {
          onNewValueSelect(item?.value, { display: item?.name });
          dispatch(sendAnalyticsEventThunk(ReportedAnalyticActionTypes.DROPDOWN_SELECTED));
        }}
        options={options ?? []}
        preventUnselect={config.disableCancel}
        selectedItem={selectedOption}
      />
    </div>
  );
};

export default DashboardToggleElement;
