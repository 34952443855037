import { APP_PORTAL_ID, Modal, sprinkles } from 'components/ds';
import { FC } from 'react';

interface Props {
  dataPanelProvidedIds: string[];
  editableSectionDataPanelProvidedIds: string[];
  elementNames: string[];
  stickyHeaderElementNames: string[];

  onClose: () => void;
}

export const BlockDatasetDeletionModal: FC<Props> = ({
  dataPanelProvidedIds,
  editableSectionDataPanelProvidedIds,
  elementNames,
  stickyHeaderElementNames,
  onClose,
}) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      secondaryButtonProps={{ text: 'Cancel', onClick: onClose }}
      size="small"
      title="This dataset cannot be deleted">
      <div className={sprinkles({ flexItems: 'column', paddingX: 'sp3', gap: 'sp3', body: 'b2' })}>
        <div>
          This dataset is in use and cannot be deleted until the following elements are deleted.
          This is true even if the <b>Customer Editable Section</b> and the <b>Header</b> are
          disabled.
        </div>
        <ModalSection category="dashboard charts" names={dataPanelProvidedIds} />
        <ModalSection category="dashboard filters" names={elementNames} />
        <ModalSection
          category="customer editable section charts"
          names={editableSectionDataPanelProvidedIds}
        />
        <ModalSection category="header filters" names={stickyHeaderElementNames} />
      </div>
    </Modal>
  );
};

interface ModalSectionProps {
  names: string[];
  category: string;
}

const ModalSection: FC<ModalSectionProps> = ({ names, category }) => {
  return names.length > 0 ? (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
      <div>
        The following <b>{`${category}`}</b> are using this dataset:
      </div>
      <ul>
        {names.map((name) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </div>
  ) : null;
};
