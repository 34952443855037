import cx from 'classnames';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dateTimeFromISOString } from '@explo/data';

import { sendPing } from 'actions/pingActions';
import { InfoCard } from 'components/InfoCard';
import { Button, Intent, Tag, sprinkles } from 'components/ds';
import {
  PLAN_TYPES,
  PLAN_TYPES_TYPE,
  TRIAL_STATUS,
  TRIAL_STATUS_TYPE,
} from 'constants/paymentPlanConstants';
import { PingTypes } from 'constants/pingTypes';
import { ReduxState } from 'reducers/rootReducer';
import { openActivationCalendly } from 'utils/calendlyUtils';
import { numDaysLeftInPlan } from 'utils/paymentPlanUtils';

const orderedPlans = [
  PLAN_TYPES.LAUNCH,
  PLAN_TYPES.GROWTH_V2,
  PLAN_TYPES.PRO,
  PLAN_TYPES.ENTERPRISE,
];

const newPlanText = (
  trialStatus?: TRIAL_STATUS_TYPE,
  newPlan?: PLAN_TYPES_TYPE,
  oldPlan?: PLAN_TYPES_TYPE,
) => {
  if (trialStatus === TRIAL_STATUS.IN_PROGRESS || !oldPlan || !newPlan)
    return 'Contact sales to activate plan';

  const oldIdx = orderedPlans.findIndex((plan) => plan === oldPlan);
  const newIdx = orderedPlans.findIndex((plan) => plan === newPlan);

  return `Contact sales to ${newIdx > oldIdx ? 'upgrade' : 'downgrade'} plan`;
};

type PanelButtonProps = {
  plan: PLAN_TYPES_TYPE;
  selected: boolean;
  title: string;
};

export const SettingsBillingSection: FC = () => {
  const currentUser = useSelector((state: ReduxState) => state.currentUser);
  const {
    payment_plan: paymentPlan,
    trial_status: trialStatus,
    trial_end_date: trialEndDate,
  } = currentUser.team ?? {};

  const [newPlan, setNewPlan] = useState<PLAN_TYPES_TYPE>();
  const dispatch = useDispatch();

  const isNewPlanSelected = newPlan && newPlan !== paymentPlan;
  const currentlySelectedPlan = newPlan || paymentPlan;

  const daysLeftInPlan = numDaysLeftInPlan(trialEndDate);
  const isAccountDeactivated =
    (trialStatus === TRIAL_STATUS.IN_PROGRESS && daysLeftInPlan <= 0) ||
    paymentPlan === PLAN_TYPES.DEACTIVATED;

  const renderTrialInfoCard = () => {
    if (trialStatus !== TRIAL_STATUS.IN_PROGRESS) return null;

    const dateEndedText = trialEndDate
      ? dateTimeFromISOString(trialEndDate).toLocaleString(DateTime.DATE_SHORT)
      : '';
    const trialText =
      daysLeftInPlan > 0
        ? `There are ${daysLeftInPlan} days left on your trial. During the trial, your team will be on the Pro plan. Please activate your plan before your trial is up to ensure continued access to Explo.`
        : `Your free trial has ended${
            dateEndedText ? ' on ' + dateEndedText : ''
          }. Please activate your plan to continue using Explo.`;

    return (
      <InfoCard
        className={sprinkles({ marginBottom: 'sp2', marginTop: 'sp1', fontSize: 14 })}
        text={trialText}
      />
    );
  };

  const newPlanTextValue = newPlanText(trialStatus, newPlan, paymentPlan);

  const renderPlanButton = ({ plan, title, selected }: PanelButtonProps) => {
    return (
      <div
        className={cx(
          planButtonClass,
          isAccountDeactivated ? disabledPlanClass : selected ? selectedPlanClass : validPlanClass,
        )}
        onClick={() => setNewPlan(plan)}>
        <div className={sprinkles({ flexItems: 'column', alignItems: 'center', gap: 'sp1' })}>
          <div className={planTitleClass}>{title}</div>
          {paymentPlan === plan && !isAccountDeactivated ? (
            <Tag intent={Intent.ACTIVE} inverted={currentlySelectedPlan === paymentPlan}>
              {trialStatus === TRIAL_STATUS.IN_PROGRESS ? 'Current Trial' : 'Current Plan'}
            </Tag>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={sprinkles({ heading: 'h2', marginBottom: 'sp1' })}>Billing</div>
      {renderTrialInfoCard()}
      <div className={sprinkles({ color: 'gray11', marginBottom: 'sp3', body: 'b2' })}>
        Contact your sales representative if you have questions about your plan or pricing.
      </div>
      <div
        className={sprinkles({ display: 'grid', gridTemplateColumns: 4, columnGap: 'sp3' })}
        style={{ height: 170 }}>
        {renderPlanButton({
          plan: PLAN_TYPES.LAUNCH,
          title: 'Launch',
          selected: currentlySelectedPlan === PLAN_TYPES.LAUNCH,
        })}
        {renderPlanButton({
          plan: PLAN_TYPES.GROWTH_V2,
          title: 'Growth',
          selected: currentlySelectedPlan === PLAN_TYPES.GROWTH_V2,
        })}
        {renderPlanButton({
          plan: PLAN_TYPES.PRO,
          title: 'Pro',
          selected: currentlySelectedPlan === PLAN_TYPES.PRO,
        })}
        {renderPlanButton({
          plan: PLAN_TYPES.ENTERPRISE,
          title: 'Enterprise',
          selected: currentlySelectedPlan === PLAN_TYPES.ENTERPRISE,
        })}
      </div>
      {isNewPlanSelected ||
      trialStatus === TRIAL_STATUS.IN_PROGRESS ||
      currentlySelectedPlan === PLAN_TYPES.DEACTIVATED ? (
        <Button
          fillWidth
          className={sprinkles({ marginTop: 'sp3' })}
          onClick={() => {
            // Before payment invoicing is built in, we'll send those who want to change pans to our sales team's Calendly.
            // We'll remove this after the infra to take in payment info is built.
            openActivationCalendly(currentUser);

            newPlan &&
              dispatch(
                sendPing({
                  postData: {
                    message: `${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" clicked to book a call with sales to "${newPlanTextValue}", from ${paymentPlan} to ${newPlan}`,
                    message_type: PingTypes.PING_PRICING_PLAN_UPDATE,
                  },
                }),
              );
          }}
          variant="primary">
          {newPlanTextValue}
        </Button>
      ) : null}
    </div>
  );
};

const planTitleClass = sprinkles({ marginBottom: 'sp1', color: 'contentPrimary', heading: 'h3' });

const planButtonClass = sprinkles({
  border: 1,
  flexItems: 'centerColumn',
  borderRadius: 4,
});

const validPlanClass = sprinkles({
  cursor: 'pointer',
  backgroundColor: { default: 'white', hover: 'lightBlue' },
  borderColor: { default: 'outline', hover: 'active' },
});

const selectedPlanClass = sprinkles({ backgroundColor: 'lightBlue', borderColor: 'active' });

const disabledPlanClass = sprinkles({
  cursor: 'not-allowed',
  backgroundColor: 'gray3',
  opacity: 0.5,
  borderColor: 'outline',
});
