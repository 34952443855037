/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageRequestBody } from '../models/ImageRequestBody';
import type { ResponseBody } from '../models/ResponseBody';
import type { ShimmedTabularRequestBody } from '../models/ShimmedTabularRequestBody';
import type { TabularRequestBody } from '../models/TabularRequestBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RoverResourceService {
    /**
     * @param requestBody
     * @returns ResponseBody Ok
     * @throws ApiError
     */
    public static tabularExport(
        requestBody: TabularRequestBody,
    ): CancelablePromise<ResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rover/tabular',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ResponseBody Ok
     * @throws ApiError
     */
    public static screenshotExport(
        requestBody: ImageRequestBody,
    ): CancelablePromise<ResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rover/screenshot',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ResponseBody Ok
     * @throws ApiError
     */
    public static shimmedTabularExport(
        requestBody: ShimmedTabularRequestBody,
    ): CancelablePromise<ResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rover/shimmed-tabular',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
