import { GradientPointOptions, GradientType } from '@explo/data';

import { HeatMapFormat } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { needsToCalculateStopsForHeatmap } from 'utils/gradientUtils';

import { getAxisNumericalValue } from '.';

export const getStops = (
  heatMapFormat: HeatMapFormat | undefined,
  aggColName: string | undefined,
  globalStyleConfig: GlobalStyleConfig,
  previewData: Record<string, string | number>[],
  getFloat: (
    opts: GradientPointOptions | undefined,
    min: number,
    max: number,
    defaultFloat: number,
  ) => number,
): [number, string][] => {
  const gradientColors = heatMapFormat?.gradient;
  const { divergingPalette, gradientPalette } = globalStyleConfig.visualizations;

  const { colorAxisMin, colorAxisMid, colorAxisMax } = getColorAxisFloats(
    aggColName,
    heatMapFormat ?? {},
    previewData,
    getFloat,
  );

  if (heatMapFormat?.gradientType === GradientType.DIVERGING) {
    return [
      [colorAxisMin, gradientColors?.hue1 || divergingPalette.hue1],
      [colorAxisMid, gradientColors?.hue2 || divergingPalette.hue2],
      [colorAxisMax, gradientColors?.hue3 || divergingPalette.hue3],
    ];
  }
  return [
    [colorAxisMin, gradientColors?.hue2 || gradientPalette.hue1],
    [colorAxisMax, gradientColors?.hue3 || gradientPalette.hue2],
  ];
};

const getColorAxisFloats = (
  aggColName: string | undefined,
  { gradientOptions }: HeatMapFormat,
  previewData: Record<string, string | number>[],
  getFloat: (
    opts: GradientPointOptions | undefined,
    min: number,
    max: number,
    defaultFloat: number,
  ) => number,
) => {
  if (
    !previewData.length ||
    !gradientOptions ||
    !needsToCalculateStopsForHeatmap(gradientOptions) ||
    !aggColName
  ) {
    return { colorAxisMin: 0, colorAxisMid: 0.5, colorAxisMax: 1 };
  }

  const { min, max } = getAggColMinMax(aggColName, previewData);

  const getFloatWrapper = (opts: GradientPointOptions | undefined, defaultFloat: number) =>
    getFloat(opts, min, max, defaultFloat);

  const colorAxisMin = getFloatWrapper(gradientOptions.minpoint, 0);
  const colorAxisMid = getFloatWrapper(gradientOptions.midpoint, 0.5);
  const colorAxisMax = getFloatWrapper(gradientOptions.maxpoint, 1);

  return { colorAxisMin, colorAxisMid, colorAxisMax };
};

export const getAggColMinMax = (
  aggColName: string,
  previewData: Record<string, string | number>[],
) => {
  let min = getAxisNumericalValue(previewData[0][aggColName]);
  let max = min;
  previewData.forEach((row) => {
    const aggCol = getAxisNumericalValue(row[aggColName]);
    min = Math.min(aggCol, min);
    max = Math.max(aggCol, max);
  });

  return { min, max };
};
