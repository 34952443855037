import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig, createGetRequestConfig } from 'actions/actionUtils';
import { Dashboard, DashboardVersionHierarchyResponse } from 'actions/dashboardActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { ProductType, ResourceType } from 'types/exploResource';
import { makeThunkRequest } from 'utils/thunkUtils';

import { cloneComputedViews } from './fidoThunks';

export const revertResourceToVersion =
  (
    isExplore: boolean,
    args: {
      id: number;
      version_number: number;
    },
    onSuccess: () => void,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { currentUser } = getState();

    currentUser.team?.feature_flags.use_fido
      ? dispatch(fidoRevertResourceToVersionThunk({ isExplore, args, onSuccess }))
      : dispatch(revertResourceToVersionThunk({ isExplore, args, onSuccess }));
  };

const fidoRevertResourceToVersionThunk = createAsyncThunk<
  {},
  {
    isExplore: boolean;
    args: { id: number; version_number: number };
    onSuccess: () => void;
  },
  { state: ReduxState }
>(ACTION.FIDO_REVERT_RESOURCE, async ({ isExplore, args, onSuccess }, { dispatch }) => {
  return dispatch(
    fetchVersion({
      isExplore,
      args,
      onSuccess: ({ version }) => {
        if (!version) return;

        dispatch(
          cloneComputedViews({
            configuration: 'config' in version ? version.config : version.configuration,
            onSuccess: (configuration) =>
              dispatch(
                revertResourceToVersionThunk({
                  isExplore,
                  args: {
                    ...args,
                    fido_duplicated_configuration: configuration,
                  },
                  onSuccess,
                }),
              ),
          }),
        );
      },
    }),
  );
});

export const revertResourceToVersionThunk = createAsyncThunk<
  {
    new_version: DashboardVersion | ReportBuilderVersion;
    // TODO(zifanxiang/tarastentz): remove the ? once drilldowns are fully enabled
    dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
  },
  {
    isExplore: boolean;
    args: {
      id: number;
      version_number: number;
      fido_duplicated_configuration?: DashboardVersionConfig | ReportBuilderConfig;
    };
    onSuccess: () => void;
  },
  { state: ReduxState }
>(ACTION.REVERT_RESOURCE, async ({ isExplore, args, onSuccess }) =>
  makeThunkRequest(
    createApiRequestConfig(
      `${isExplore ? 'dashboards' : 'report_builder'}/${args.id}/revert_to_version/`,
      'POST',
      {
        version_number: args.version_number,
        fido_duplicated_configuration: args.fido_duplicated_configuration,
      },
    ),
    'Error reverting version',
    { onSuccess },
  ),
);

type FetchCurrentAndPreviousVersionResponse =
  | {
      '@type': ResourceType.REPORT;
      current_version: ReportBuilderVersion;
      previous_version: ReportBuilderVersion;
    }
  | {
      '@type': ResourceType.DASHBOARD;
      current_version: ReportBuilderVersion;
      previous_version: ReportBuilderVersion;
    };

/**
 * Gets current and previous version on a single resource for comparison
 */
export const fetchVersionsForComparisonThunk = createAsyncThunk<
  FetchCurrentAndPreviousVersionResponse,
  {
    productType: ProductType;
    args: {
      id: number;
      version_number: number;
    };
    onSuccess: (data: FetchCurrentAndPreviousVersionResponse) => void;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_VERSIONS_FOR_COMPARISON,
  async ({ productType, args: { id, version_number }, onSuccess }) =>
    makeThunkRequest(
      createGetRequestConfig(
        `${
          productType === ResourceType.DASHBOARD ? 'dashboards' : 'report_builder'
        }/${id}/get_versions_for_comparison/`,
        'GET',
        { version_number: version_number },
      ),
      'Error getting versions for comparison',
      { onSuccess },
    ),
);

export const cloneResource =
  (
    isExplore: boolean,
    args: {
      id: number;
      version_number: number;
    },
    onSuccess: (data: { new_resource: Dashboard | ReportBuilder }) => void,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { currentUser } = getState();

    const handleSuccess = (data: { new_resource: Dashboard | ReportBuilder }) => {
      if (isExplore) {
        trackEvent(EVENTS.CREATED_DASHBOARD, {
          dashboard_template_id: data.new_resource.id,
          dashboard_name: data.new_resource.name,
        });
      }

      onSuccess(data);
    };

    if (!currentUser.team?.feature_flags.use_fido) {
      dispatch(cloneResourceThunk({ isExplore, args, onSuccess: handleSuccess }));
    } else {
      dispatch(fidoCloneResourceThunk({ isExplore, args, onSuccess: handleSuccess }));
    }
  };

const fidoCloneResourceThunk = createAsyncThunk<
  {},
  {
    isExplore: boolean;
    args: {
      id: number;
      version_number: number;
      fido_duplicated_configuration?: DashboardVersionConfig | ReportBuilderConfig;
    };
    onSuccess: (data: { new_resource: Dashboard | ReportBuilder }) => void;
  },
  { state: ReduxState }
>(ACTION.FIDO_CLONE_RESOURCE, async ({ isExplore, args, onSuccess }, { dispatch }) => {
  const cloneCallback = (configuration?: DashboardVersionConfig | ReportBuilderConfig) =>
    dispatch(
      cloneResourceThunk({
        isExplore,
        args: {
          ...args,
          fido_duplicated_configuration: configuration,
        },
        onSuccess,
      }),
    );

  return makeThunkRequest(
    createGetRequestConfig(
      `${isExplore ? 'dashboards' : 'report_builder'}/${args.id}/get_version_by_number/`,
      'GET',
      { version_number: args.version_number },
    ),
    'Error fetching version',
    {
      onSuccess: ({ version }: { version: DashboardVersion | ReportBuilderVersion }) => {
        return dispatch(
          cloneComputedViews({
            configuration: 'config' in version ? version.config : version.configuration,
            onSuccess: cloneCallback,
          }),
        );
      },
      onError: () => cloneCallback(),
    },
  );
});

export const cloneResourceThunk = createAsyncThunk<
  {
    new_resource: Dashboard | ReportBuilder;
  },
  {
    isExplore: boolean;
    args: {
      id: number;
      fido_duplicated_configuration?: DashboardVersionConfig | ReportBuilderConfig;
      version_number: number;
    };
    onSuccess: (data: { new_resource: Dashboard | ReportBuilder }) => void;
  },
  { state: ReduxState }
>(ACTION.CLONE_RESOURCE, async ({ isExplore, args, onSuccess }) =>
  makeThunkRequest(
    createApiRequestConfig(
      `${isExplore ? 'dashboards' : 'report_builder'}/${args.id}/duplicate/`,
      'POST',
      {
        fido_duplicated_configuration: args.fido_duplicated_configuration,
        version_number: args.version_number,
      },
    ),
    'Error duplicating version',
    { onSuccess },
  ),
);

export const fetchVersion = createAsyncThunk<
  {},
  {
    isExplore: boolean;
    args: {
      id: number;
      version_number?: number;
    };
    onSuccess: ({
      version,
    }: {
      version: DashboardVersion | ReportBuilderVersion | undefined;
    }) => void;
  },
  { state: ReduxState }
>(ACTION.FETCH_VERSION, async ({ isExplore, args, onSuccess }) =>
  makeThunkRequest(
    createApiRequestConfig(
      `${isExplore ? 'dashboards' : 'report_builder'}/${args.id}/get_version/${
        args.version_number ? '?version_number=' + args.version_number : ''
      }`,
      'GET',
      {},
    ),
    'Error fetching version',
    { onSuccess },
  ),
);
