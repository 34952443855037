import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { DatasetSchema } from '@explo/data';

import { ParentSchema } from 'actions/dataSourceActions';
import { Dataset, DatasetData } from 'actions/datasetActions';
import { sprinkles, Tabs } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';

import { DatasetFormattingTab } from './DatasetFormattingTab';
import { DatasetMetadataPanel } from './DatasetMetadataPanel';
import { DatasetQueryPanels } from './DatasetQueryPanels';
import { DATASET_VIEWS } from './constants';
import * as sharedStyles from './styles.css';

const DatasetViewTabs = Object.values(DATASET_VIEWS);

type Props = {
  activeQuery: string;
  activeDatasetData: DatasetData | null;
  activeDatasetConfig: Dataset | null;
  activeDatasetSchema: DatasetSchema | null;
  // This is the saved schema which should be used for drilldown configuration
  activeDatasetSavedSchema: DatasetSchema | null;
  fetchData: (query: string, pageNumber?: number) => void;
  onSave?: (query: string) => void;
  onSaveDraft: (query: string | undefined) => void;
  onSelectSchema: (schemaId: number) => void;
  parentSchemas: ParentSchema[];
  selectedDatasetId: string | null;
  variables: DashboardVariableMap;
  createNewParam: () => DashboardParam;
  renderFormattingTab: boolean;
  onRevertDraft?: () => void;
  isPreviewButtonDisabledFn?: () => boolean;
};

export const DatasetEditor: FC<Props> = ({
  activeQuery,
  activeDatasetData,
  activeDatasetConfig,
  activeDatasetSchema,
  activeDatasetSavedSchema,
  fetchData,
  onSave,
  onSaveDraft,
  onSelectSchema,
  selectedDatasetId,
  variables,
  createNewParam,
  renderFormattingTab,
  onRevertDraft,
  isPreviewButtonDisabledFn,
}) => {
  const { isDrilldownPreview } = useSelector(
    (state: ReduxState) => ({
      isDrilldownPreview: state.dashboardInteractions.isDrilldownPreview,
    }),
    shallowEqual,
  );

  const [editorView, setEditorView] = useState(
    isDrilldownPreview ? DATASET_VIEWS.FORMATTING : DATASET_VIEWS.QUERY,
  );
  const [currentQuery, setCurrentQuery] = useState(activeQuery);

  const prevActiveQuery = usePrevious(activeQuery);
  useEffect(() => {
    if (prevActiveQuery !== activeQuery) {
      setCurrentQuery(activeQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuery]);

  const renderMetadataPanel = () => {
    if (!selectedDatasetId || !activeDatasetConfig) return;

    return (
      <DatasetMetadataPanel
        createNewParam={createNewParam}
        error={activeDatasetData?.error}
        onSelectSchema={onSelectSchema}
        query={activeQuery}
        selectedDatasetId={activeDatasetConfig.parent_schema_id}
        variables={variables}
      />
    );
  };

  const renderDatasetEditor = () => (
    <div className={datasetEditorContainerClass}>
      {renderFormattingTab ? (
        <div className={editorMenuContainerClass} style={{ minHeight: 48 }}>
          <Tabs
            className={sharedStyles.datasetEditorTabs}
            onTabSelect={(tabId) => openTab(tabId)}
            selectedTabId={editorView}
            tabs={DatasetViewTabs}
          />
        </div>
      ) : null}
      {editorView === DATASET_VIEWS.QUERY
        ? renderDatasetQueryEditor()
        : renderDatasetFormattingBody()}
    </div>
  );

  const openTab = (tabId: string) => setEditorView(tabId as DATASET_VIEWS);

  const renderDatasetQueryEditor = () => {
    return (
      <div className={queryEditorContainerClass}>
        <DatasetQueryPanels
          activeDatasetConfig={activeDatasetConfig}
          activeDatasetData={activeDatasetData}
          activeDatasetSchema={activeDatasetSchema}
          activeQuery={activeQuery}
          currentQuery={currentQuery}
          fetchData={fetchData}
          isPreviewButtonDisabledFn={isPreviewButtonDisabledFn}
          onRevertDraft={onRevertDraft}
          onSave={onSave}
          onSaveDraft={onSaveDraft}
          selectedDatasetId={selectedDatasetId}
          setCurrentQuery={setCurrentQuery}
        />
        {renderMetadataPanel()}
      </div>
    );
  };

  const renderDatasetFormattingBody = () => {
    if (!selectedDatasetId) return;

    return (
      <DatasetFormattingTab
        activeDatasetConfig={activeDatasetConfig}
        activeDatasetData={activeDatasetData}
        activeDatasetSavedSchema={activeDatasetSavedSchema}
        activeQuery={activeQuery}
        currentQuery={currentQuery}
        fetchData={fetchData}
        openTab={openTab}
      />
    );
  };

  return (
    <div className={sprinkles({ parentContainer: 'fill', overflowY: 'auto' })}>
      {renderDatasetEditor()}
    </div>
  );
};

const editorMenuContainerClass = sprinkles({
  backgroundColor: 'white',
  paddingRight: 'sp1.5',
  overflow: 'hidden',
  borderBottom: 1,
  borderColor: 'outline',
  width: 'fill',
});

const queryEditorContainerClass = sprinkles({
  flexItems: 'alignCenter',
  parentContainer: 'fill',
  padding: 'sp2',
  gap: 'sp2',
  overflowY: 'auto',
});

const datasetEditorContainerClass = sprinkles({
  parentContainer: 'fill',
  flexItems: 'column',
  backgroundColor: 'white',
  flex: 1,
  overflowY: 'auto',
});
