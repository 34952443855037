import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ColumnConfigsWithName, CustomerReportFilter, SortInfo } from '@explo/data';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { toggleDataPanelOpen } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { FilterableColumn, SortableColumn } from 'utils/customerReportUtils';
import { groupBy, orderBy } from 'utils/standard';

import {
  getIsCoreEditingEnabled,
  getIsFilterEditingEnabled,
  getIsSortEditingEnabled,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { AddFilterButton } from './AddFilterButton';
import { FilterTag } from './FilterTag';
import { SortMenu } from './SortMenu';

type Props = {
  columns: FilterableColumn[];
  filters: CustomerReportFilter[] | undefined;
  columnConfigs: ColumnConfigsWithName;
  sort: SortInfo[] | undefined;
  sortableColumns: SortableColumn[];
};

export const ReportBuilderFilters: FC<Props> = ({
  columns,
  filters,
  columnConfigs,
  sort,
  sortableColumns,
}) => {
  const dispatch = useDispatch();

  const { isDataPanelOpen, isCoreEditingEnabled, isFilterEditingEnabled, isSortEditingEnabled } =
    useSelector(
      (state: ReportBuilderReduxState) => ({
        isDataPanelOpen: state.reportEditing.isDataPanelOpen,
        isCoreEditingEnabled: getIsCoreEditingEnabled(state.embeddedReportBuilder),
        isFilterEditingEnabled: getIsFilterEditingEnabled(state.embeddedReportBuilder),
        isSortEditingEnabled: getIsSortEditingEnabled(state.embeddedReportBuilder),
      }),
      shallowEqual,
    );

  const orderedColumns = useMemo(() => orderBy(columns, 'default', 'desc'), [columns]);

  const filterClausesByCol = useMemo(
    () => groupBy(filters, (filter) => filter.filterColumn.name),
    [filters],
  );

  const renderSortMenu = sortableColumns.length > 0 && (isSortEditingEnabled || sort?.length);

  if (!isFilterEditingEnabled && !filters?.length && !renderSortMenu) return null;

  return (
    <div className={filtersContainer}>
      <div className={leftContainer}>
        {isFilterEditingEnabled ? <AddFilterButton columns={orderedColumns} /> : null}
        {orderedColumns.map((col) => {
          const clauses = filterClausesByCol[col.name];
          if (!clauses) {
            return col.default ? (
              <FilterTag
                column={col}
                columnConfig={columnConfigs[col.name]}
                key={`default_${col.name}`}
              />
            ) : null;
          }

          return clauses.map((clause) => (
            <FilterTag
              clause={clause}
              column={col}
              columnConfig={columnConfigs[col.name]}
              key={clause.id}
            />
          ));
        })}
      </div>
      {renderSortMenu ? <SortMenu columns={sortableColumns} sort={sort} /> : null}
      {isCoreEditingEnabled ? (
        <EmbedButton
          icon="sidebar-flip"
          onClick={() => dispatch(toggleDataPanelOpen())}
          tooltipProps={{
            text: `${isDataPanelOpen ? 'Collapse' : 'Expand'} Data Panel`,
            side: 'left',
          }}
          variant="secondary"
        />
      ) : null}
    </div>
  );
};

const filtersContainer = sprinkles({
  flexItems: 'center',
  paddingX: 'sp4',
  paddingY: 'sp2',
  gap: 'sp1',
});

const leftContainer = sprinkles({
  flex: 1,
  flexItems: 'alignCenter',
  flexWrap: 'wrap',
  gap: 'sp1',
});
