import { FC, useState } from 'react';

import { DatasetSchema, DATE_TYPES, NUMBER_TYPES } from '@explo/data';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';

type Props = {
  tableName: string;
  schema: DatasetSchema;
  isInitiallyOpen: boolean;
};

/** Displays the columns in a schema table */
export const SchemaTableViewer: FC<Props> = ({ tableName, schema, isInitiallyOpen }) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const getIconFromColumnType = (elementType: string) =>
    DATE_TYPES.has(elementType)
      ? 'calendar'
      : NUMBER_TYPES.has(elementType)
        ? 'report-builder-number'
        : elementType === 'BOOLEAN'
          ? 'tick'
          : 'report-builder-string';

  return (
    <ConfigSection
      icon="table"
      isOpen={isOpen}
      onOpen={setIsOpen}
      title={tableName}
      variant="header2">
      {schema?.map((col, index) => (
        <PanelListItem
          copiable
          key={index}
          leftIcon={getIconFromColumnType(col.type)}
          name={col.name}
        />
      ))}
    </ConfigSection>
  );
};
