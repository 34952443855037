import { FC, useMemo, useEffect } from 'react';

import { DatasetSchema } from '@explo/data';

import { Dataset, DatasetData } from 'actions/datasetActions';
import { sprinkles, Icon } from 'components/ds';
import { IconName } from 'components/ds/Icon';

import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import { DatasetPreview } from './DatasetPreview';
import { DrilldownDatasetColumns } from './DrilldownDatasetColumns';
import { DrilldownDatasetColumnsFormatConfig } from './DrilldownDatasetColumnsFormatConfig';
import { DATASET_VIEWS } from './constants';
import * as sharedStyles from './styles.css';

type Props = {
  activeQuery: string;
  currentQuery: string;
  activeDatasetData: DatasetData | null;
  activeDatasetConfig: Dataset | null;
  activeDatasetSavedSchema: DatasetSchema | null;
  fetchData: (query: string, pageNumber?: number) => void;
  openTab: (tabId: string) => void;
};

export const DatasetFormattingTab: FC<Props> = ({
  activeDatasetConfig,
  activeDatasetData,
  fetchData,
  activeQuery,
  currentQuery,
  openTab,
  activeDatasetSavedSchema,
}) => {
  useEffect(() => {
    // Check if there is a query and no data has been requested
    if (!activeDatasetConfig?.query || activeDatasetData) return;

    fetchData(activeDatasetConfig.query);
  }, [activeDatasetConfig, activeDatasetData, fetchData]);

  const renderQueryRedirectText = (text: string) => (
    <span
      className={sprinkles({ color: 'active', cursor: 'pointer' })}
      onClick={() => openTab(DATASET_VIEWS.QUERY)}>
      {text}
    </span>
  );

  const drilldownColumnConfigsValues = useMemo(
    () => Object.values(activeDatasetConfig?.drilldownColumnConfigs || []),
    [activeDatasetConfig?.drilldownColumnConfigs],
  );

  const noIncludedColumns = useMemo(
    () =>
      drilldownColumnConfigsValues.length &&
      !drilldownColumnConfigsValues.some((config) => config.isVisible),
    [drilldownColumnConfigsValues],
  );

  const renderStandAloneNonIdealState = (
    description: string,
    icon: IconName,
    redirectText: string,
    title: string,
    errorStyle?: boolean,
  ) => (
    <div className={nonIdealStateContainerClass}>
      <DatasetEditorNonIdealState
        description={description}
        icon={
          <Icon
            className={errorStyle ? sprinkles({ color: 'error' }) : undefined}
            name={icon}
            size="lg"
          />
        }
        primaryContent={renderQueryRedirectText(redirectText)}
        title={title}
      />
    </div>
  );
  const error = activeDatasetData?.error;
  if (error)
    return renderStandAloneNonIdealState(
      error,
      'circle-exclamation',
      'Revise your query',
      'There was a problem with your query',
      true,
    );

  if (!activeDatasetSavedSchema?.length || !activeDatasetConfig || !activeDatasetData)
    return renderStandAloneNonIdealState(
      'Formatting tools will appear after your SQL query is saved.',
      'rectangle-terminal',
      'Write a query',
      'No data to format',
    );

  return (
    <div className={sprinkles({ display: 'flex', parentContainer: 'fill', overflowY: 'hidden' })}>
      <DrilldownDatasetColumns
        datasetConfig={activeDatasetConfig}
        datasetSchema={activeDatasetSavedSchema}
      />
      <div className={sharedStyles.formattedDataTableClass}>
        {noIncludedColumns ? (
          <DatasetEditorNonIdealState
            description="Select some columns to get started."
            title="No visible columns"
          />
        ) : (
          <DatasetPreview
            enableFormat
            datasetConfig={activeDatasetConfig}
            datasetData={activeDatasetData}
            datasetQuery={activeQuery}
            datasetSchema={activeDatasetSavedSchema}
            fetchDataset={fetchData}
            getTablePreview={() => fetchData(currentQuery)}
            openTab={openTab}
          />
        )}
      </div>
      {noIncludedColumns ? null : (
        <DrilldownDatasetColumnsFormatConfig
          datasetConfig={activeDatasetConfig}
          datasetData={activeDatasetData}
          datasetSchema={activeDatasetSavedSchema}
        />
      )}
    </div>
  );
};

const nonIdealStateContainerClass = sprinkles({
  flexItems: 'center',
  parentContainer: 'fill',
  flex: 1,
});
