import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { useContext } from 'react';

import { DatabaseUnsupportedOperations } from '@explo/data';

import { EmbedTablePager } from 'components/dataTable/embedTablePager';
import { getPaginationDisabledText, isTablePagingEnabled } from 'components/dataTable/utils';
import { sprinkles } from 'components/ds';
import { EmbedInfoIcon, EmbedSpinner } from 'components/embed';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import * as RD from 'remotedata';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';

const useStyles = makeStyles((theme: Theme) => ({
  tableFooter: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    paddingLeft: globalStyleConfig.container.padding.default,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
  rowCount: {
    fontSize: 14,
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  onNewPage: (newPage: string) => void;
  loading?: boolean;
  totalRowCount: RD.ResponseData<number> | undefined;
  rowsPerPage?: number;
  isEmbed: boolean;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
};

export default function DataTableFooter({
  adHocOperationInstructions,
  isEmbed,
  loading,
  onNewPage,
  rowsPerPage,
  totalRowCount,
  unsupportedOperations,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  if (loading || totalRowCount === undefined || RD.isLoading(totalRowCount)) {
    return (
      <div
        className={cx(
          classes.tableFooter,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        )}>
        <EmbedSpinner className={sprinkles({ marginRight: 'sp.5' })} size="md" />
      </div>
    );
  }

  const numRows = rowsPerPage || MAX_ROWS_TO_PREVIEW;

  const enablePagination = isTablePagingEnabled(unsupportedOperations);

  return (
    <div
      className={cx(
        classes.tableFooter,
        GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
      )}>
      <div className={cx(classes.rowCount, embedSprinkles({ body: 'secondary' }))}>
        {!RD.isSuccess(totalRowCount) ? (
          'Error getting total results'
        ) : enablePagination ? (
          `${totalRowCount.data.toLocaleString()} Results`
        ) : (
          <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
            {`Showing ${Math.min(
              numRows,
              totalRowCount.data,
            )} of ${totalRowCount.data.toLocaleString()} results`}
            <EmbedInfoIcon text={getPaginationDisabledText(isEmbed)} />
          </div>
        )}
      </div>
      {enablePagination && RD.isSuccess(totalRowCount) ? (
        <EmbedTablePager
          currentPage={adHocOperationInstructions.currentPage || 1}
          maxPageNumber={Math.ceil(totalRowCount.data / numRows)}
          onNewPage={onNewPage}
        />
      ) : null}
    </div>
  );
}
