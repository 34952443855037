import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ComputedView, Folder } from '@explo-tech/fido-api';
import { sprinkles } from 'components/ds';
import { GlobalDatasetFidoDataFetcher } from 'pages/dashboardPage/dashboardDatasetEditor/GlobalDatasetsFidoDataFetcher';
import { convertViewToDataset } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { getCurrentComputedView, getCurrentFolder, getResourceById } from './selectors';

import { useDataLibrarySetup } from './dataFetchUtils';

export const DatasetPage: FC = () => {
  const dispatch = useDispatch();

  const {
    folder,
    dataSources,
    parentSchemaTablesMap,
    parentSchemas,
    useFido,
    currentBranch,
    computedView,
    currentItemPath,
  } = useSelector((state: ReduxState) => {
    return {
      folder: getCurrentFolder(state) as RD.ResponseData<Folder>,
      currentItemPath: state.dataLibrary.currentItemPath,
      currentBranch: state.dataLibrary.currentBranch,
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      parentSchemaTablesMap: state.parentSchemas.schemaTablesMap,
      useFido: state.currentUser.team?.feature_flags.use_fido,
      computedView: getCurrentComputedView(state),
    };
  }, shallowEqual);

  const computedViewId = RD.isSuccess(computedView) ? computedView.data?.id : undefined;
  const { baseComputedView } = useSelector((state: ReduxState) => {
    return {
      baseComputedView: getResourceById(state, true, computedViewId ?? '') as ComputedView | null,
    };
  });

  // Custom hook to load all the data library state
  useDataLibrarySetup(
    dispatch,
    dataSources,
    parentSchemas,
    parentSchemaTablesMap,
    useFido,
    currentBranch,
    folder,
    currentItemPath,
  );

  const viewWithIds = useMemo(
    () =>
      RD.isSuccess(computedView) && computedView.data
        ? {
            ...computedView.data,
            id: computedView.data.id ?? '',
            namespaceId: computedView.data.namespaceId ? computedView.data.namespaceId : '',
          }
        : undefined,
    [computedView],
  );

  const selectedDataset = useMemo(() => {
    return RD.isSuccess(parentSchemas) && viewWithIds
      ? convertViewToDataset(viewWithIds, parentSchemas.data)
      : undefined;
  }, [parentSchemas, viewWithIds]);

  if (!viewWithIds || !selectedDataset) {
    return null;
  }

  // Default the query to empty string since the base computed view is undefined when it is a new
  // computed view.
  const baseQuery = baseComputedView?.query ?? '';
  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        parentContainer: 'fill',
        overflowX: 'hidden',
      })}>
      <GlobalDatasetFidoDataFetcher
        baseQuery={baseQuery}
        selectedDataset={selectedDataset}
        selectedView={viewWithIds}
      />
    </div>
  );
};
