import { Icon } from '@blueprintjs/core';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { FilterOperator } from '@explo/data';

import { Modal, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { ROUTE_PROVIDERS } from 'constants/routes';
import {
  CategoryChartColumnInfo,
  ColorColumnOption,
  DrilldownColumnIndexToTypeMap,
  DrilldownColumnType,
} from 'constants/types';
import { setSelectedDashboardDrilldownInfo } from 'reducers/dashboardInteractionsReducer';
import { setCurrentSourceInfos, setDrilldownFilters } from 'reducers/drilldownsReducer';
import { switchCurrentEmbedDashboard } from 'reducers/embedDashboardReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { getDashboardIdToNameMap, getSelectedDashboardVersion } from 'reducers/selectors';
import { DashboardVariable } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import {
  COLUMN_TYPE_TO_SUPPORTED_FILTER_OPERATOR_IDS_MAP,
  ValuePlurality,
} from 'types/filterOperations';
import { parseVariableSelectIntoCorrectColType } from 'utils/drilldownUtils';
import { transformObjectToSinglePropertyObjectArray } from 'utils/objectUtils';

interface OperatorRowRenderInfo {
  columnName: string;
  variableName: string;
  columnDataType: string;
  drilldownColumnType: DrilldownColumnType;
  selectedValue: DashboardVariable;
}

export const DashboardDrilldownOptionsModal: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedDashboardDrilldownInfo,
    dashboardIdToNameMap,
    drilldownSourceInfos,
    drilldownDatasetFilters,
    isEmbed,
    selectedDashboardVersion,
  } = useSelector((state: DashboardStates) => {
    return {
      selectedDashboardDrilldownInfo: state.dashboardInteractions.selectedDashboardDrilldownInfo,
      selectedDashboardVersion: getSelectedDashboardVersion(state),
      dashboardIdToNameMap: getDashboardIdToNameMap(state),
      drilldownSourceInfos: state.drilldowns.currentSourceInfos,
      drilldownDatasetFilters: state.drilldowns.drilldownDatasetFilters,
      isEmbed:
        'dashboardLayout' in state ? state.dashboardLayout.requestInfo.type === 'embedded' : false,
    };
  });

  const sourceDataPanel: DataPanel | null = useMemo(() => {
    if (!selectedDashboardDrilldownInfo || !selectedDashboardVersion) {
      return null;
    }

    return selectedDashboardVersion.configuration.data_panels[
      selectedDashboardDrilldownInfo.sourceDataPanelId
    ];
  }, [selectedDashboardDrilldownInfo, selectedDashboardVersion]);

  const drilldownEntryPoint = useMemo(() => {
    if (!sourceDataPanel || !selectedDashboardDrilldownInfo) {
      return null;
    }

    return sourceDataPanel.drilldownEntryPoints[
      selectedDashboardDrilldownInfo.drilldownEntryPointId
    ];
  }, [sourceDataPanel, selectedDashboardDrilldownInfo]);

  const entryPointColumns = useMemo(() => {
    if (!drilldownEntryPoint) {
      return [];
    }

    return drilldownEntryPoint.sourceChartColumns;
  }, [drilldownEntryPoint]);

  const operatorRowRenderInfos: (OperatorRowRenderInfo | null)[] = useMemo(() => {
    if (!selectedDashboardDrilldownInfo || !sourceDataPanel) {
      return [];
    }

    return entryPointColumns.flatMap((entryPointColumn, index) => {
      const columnType = entryPointColumn.type;
      if (!columnType) {
        return null;
      }
      const drilldownColumnType = DrilldownColumnIndexToTypeMap[index];
      const selectedValue = getDrilldownVariableValue(
        selectedDashboardDrilldownInfo.selectedPrimaryField,
        selectedDashboardDrilldownInfo.selectedSecondaryField,
        drilldownColumnType,
        createCategoryOrColorColumn(drilldownColumnType, sourceDataPanel),
      );
      if (typeof selectedValue === 'object') {
        const flattenedSelectedValue = transformObjectToSinglePropertyObjectArray(
          selectedValue as Record<string, DashboardVariable>,
        );
        return flattenedSelectedValue.map(({ key, value }) => {
          return {
            columnName: entryPointColumn.name || '',
            variableName: `${key}`,
            columnDataType: columnType,
            drilldownColumnType,
            selectedValue: value as DashboardVariable,
          };
        });
      }
      return {
        columnName: entryPointColumn.name || '',
        variableName: '',
        columnDataType: columnType,
        drilldownColumnType,
        selectedValue,
      };
    });
  }, [selectedDashboardDrilldownInfo, sourceDataPanel, entryPointColumns]);

  const [selectedFilterOperators, setSelectedFilterOperators] = useState<
    (FilterOperator | undefined)[]
  >(new Array(operatorRowRenderInfos.length).fill(undefined));

  const isDrillInButtonDisabled = useMemo(() => {
    const selectedFilterOperatorIds = Object.values(selectedFilterOperators);
    return (
      selectedFilterOperatorIds.some((operatorId) => !operatorId) ||
      selectedFilterOperatorIds.length !== operatorRowRenderInfos.length
    );
  }, [selectedFilterOperators, operatorRowRenderInfos]);

  if (!drilldownEntryPoint || !sourceDataPanel || !selectedDashboardDrilldownInfo) {
    return null;
  }

  return (
    <Modal
      isOpen
      onClose={() => {
        setSelectedFilterOperators([]);
        dispatch(setSelectedDashboardDrilldownInfo(null));
      }}
      primaryButtonProps={{
        disabled: isDrillInButtonDisabled,
        text: 'Drill in',
        onClick: () => {
          const updatedDrilldownSourceInfos = [
            ...drilldownSourceInfos,
            {
              sourceDashboardId: drilldownEntryPoint.sourceDashboardId,
              sourceDataPanelId: sourceDataPanel.id,
              drilldownEntryPointId: selectedDashboardDrilldownInfo.drilldownEntryPointId,
            },
          ];
          const drilldownVariables: Record<string, DashboardVariable> = {};
          drilldownEntryPoint.sourceChartColumns.forEach((_, index) => {
            const columnType = DrilldownColumnIndexToTypeMap[index];
            const columnVariableName = `drilldown_${columnType.toLocaleLowerCase()}`;
            const sourceDashboardName = dashboardIdToNameMap[drilldownEntryPoint.sourceDashboardId];
            const drilldownVariableName = `${sourceDashboardName}.${sourceDataPanel.provided_id}.${columnVariableName}`;
            const drilldownVariableValue = getDrilldownVariableValue(
              selectedDashboardDrilldownInfo.selectedPrimaryField,
              selectedDashboardDrilldownInfo.selectedSecondaryField,
              columnType,
              createCategoryOrColorColumn(columnType, sourceDataPanel),
            );
            drilldownVariables[drilldownVariableName] = drilldownVariableValue;
          });
          const updatedDrilldownDatasetFilters = { ...drilldownDatasetFilters };
          operatorRowRenderInfos.forEach((renderInfo, index) => {
            const operatorId = selectedFilterOperators[index];
            if (!operatorId || !renderInfo) {
              return;
            }
            const currentDrilldownDatasetFilters =
              updatedDrilldownDatasetFilters[sourceDataPanel.table_id];
            const columnName = renderInfo.columnName;
            updatedDrilldownDatasetFilters[sourceDataPanel.table_id] = {
              ...currentDrilldownDatasetFilters,
              [`${columnName}`]: [
                ...(currentDrilldownDatasetFilters?.[`${columnName}`] || []),
                {
                  operatorId,
                  filterValue: renderInfo.selectedValue,
                },
              ],
            };
          });

          if (isEmbed) {
            dispatch(
              switchCurrentEmbedDashboard({
                currentDashboardId: drilldownEntryPoint.destinationDashboardId,
                variables: drilldownVariables,
              }),
            );
            dispatch(setCurrentSourceInfos(updatedDrilldownSourceInfos));
            dispatch(setDrilldownFilters(updatedDrilldownDatasetFilters));
          } else {
            history.push(
              ROUTE_PROVIDERS.DASHBOARD(`${drilldownEntryPoint.destinationDashboardId}`),
              {
                drilldownVariables: drilldownVariables,
                updatedDrilldownSourceInfos: updatedDrilldownSourceInfos,
                updatedDrilldownDatasetFilters: updatedDrilldownDatasetFilters,
              },
            );
          }
        },
      }}
      size="small"
      title={`Drilldown into ${dashboardIdToNameMap[drilldownEntryPoint.destinationDashboardId]}`}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {operatorRowRenderInfos.map((renderInfo, index) => {
          if (!renderInfo) {
            return null;
          }

          const supportedFilterOperators = COLUMN_TYPE_TO_SUPPORTED_FILTER_OPERATOR_IDS_MAP.get(
            renderInfo.columnDataType,
          );

          if (!supportedFilterOperators) {
            return null;
          }
          const drilldownColumnOperatorValues: SelectItems<string> = [];
          supportedFilterOperators.forEach((operator) => {
            // TODO(zifanxiang): Revisit this decision to filter out plural operators. The thinking
            // currently is that even with plural values such as the date ranges that are produced
            // from date bucket categories, we'll expand into two singular values (a start date and
            // an end date).
            if (operator.valuePlurality === ValuePlurality.PLURAL) {
              return;
            }

            drilldownColumnOperatorValues.push({
              label: operator.name,
              value: operator.id,
            });
          });
          const { columnName, variableName, columnDataType, selectedValue } = renderInfo;
          // TODO(zifanxiang): Unify this type of stringify casting for rendering objects.
          const renderedSelectedValue =
            typeof selectedValue === 'object' ? JSON.stringify(selectedValue) : selectedValue;
          const combinedId = `${columnName}.${variableName}`;
          return (
            <div className={sprinkles({ flexItems: 'alignCenter' })} key={combinedId}>
              <span className={sprinkles({ marginTop: 'sp.5' })}>
                <Icon icon={SCHEMA_DATA_TYPES_BY_ID[columnDataType].icon} />
              </span>
              <div
                className={sprinkles({
                  heading: 'h4',
                  marginX: 'sp2',
                })}>
                {columnName}
              </div>
              <Select
                onChange={(operatorId) => {
                  const updatedSelectedFilterOperators = [...selectedFilterOperators];
                  updatedSelectedFilterOperators[index] = operatorId as FilterOperator;
                  setSelectedFilterOperators(updatedSelectedFilterOperators);
                }}
                selectedValue={selectedFilterOperators[index]}
                values={drilldownColumnOperatorValues}
              />
              <div className={sprinkles({ marginX: 'sp2' })}>{renderedSelectedValue}</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

const getDrilldownVariableValue = (
  category: string,
  subCategory: string | undefined,
  drilldownColumnType: DrilldownColumnType,
  targetColumn: CategoryChartColumnInfo | ColorColumnOption,
): DashboardVariable => {
  switch (drilldownColumnType) {
    case DrilldownColumnType.PRIMARY:
      return parseVariableSelectIntoCorrectColType(category, targetColumn);
    case DrilldownColumnType.SECONDARY:
      return parseVariableSelectIntoCorrectColType(subCategory || '', targetColumn);
  }
  throw new Error('Unsupported column type');
};

const createCategoryOrColorColumn = (
  drilldownColumnType: DrilldownColumnType,
  sourceDataPanel: DataPanel,
): CategoryChartColumnInfo | ColorColumnOption => {
  const instructions = sourceDataPanel.visualize_op.instructions.V2_TWO_DIMENSION_CHART;
  switch (drilldownColumnType) {
    case DrilldownColumnType.PRIMARY:
      if (!instructions?.categoryColumn) {
        throw new Error('Primary category column is not defined');
      }
      return instructions?.categoryColumn;
    case DrilldownColumnType.SECONDARY:
      if (!instructions?.colorColumnOptions || !instructions?.colorColumnOptions[0]) {
        throw new Error('Secondary color column is not defined');
      }
      return instructions?.colorColumnOptions[0];
  }
  throw new Error('Unsupported column type');
};
