import { MenuItem, PopoverPosition } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import cx from 'classnames';
import { FC, useMemo } from 'react';

import { BaseCol, DatasetSchema } from '@explo/data';

import { APP_PORTAL_ID, IconButton, sprinkles } from 'components/ds';
import { createColOptionsWithIcon } from 'utils/general';
import { keyBy, sortBy } from 'utils/standard';

import * as styles from './styles.css';

type Props = {
  schema: DatasetSchema;
  onColChange: (newCol: BaseCol) => void;
  displayName: string | undefined;

  className?: string;
  // If popover has a right divider, then this is a single row dropped column. Otherwise, it is a double row dropped column.
  rightDivider?: boolean;
  transparentIcon?: boolean;
};

export const ColumnOptionsPopover: FC<Props> = ({
  rightDivider,
  schema,
  onColChange,
  displayName,
  className,
  transparentIcon,
}) => {
  const schemaByName = useMemo(() => keyBy(schema, 'name'), [schema]);

  const columnsToSelect = useMemo(() => createColOptionsWithIcon(sortBy(schema, 'name')), [schema]);

  const caretButton = (
    <IconButton
      className={
        transparentIcon
          ? styles.transparentIconBtn
          : rightDivider
            ? styles.iconBtnWithDivider
            : styles.iconBtn
      }
      name="caret-down"
    />
  );

  const includeDisplayName = displayName !== undefined;

  return (
    <Select
      fill={includeDisplayName}
      filterable={false}
      itemRenderer={(item) => (
        <MenuItem
          className={styles.menuItemOption}
          icon={item.icon}
          key={`dropped-column-${item.name}`}
          onClick={() => onColChange?.(schemaByName[item.name])}
          text={item.name}
        />
      )}
      items={columnsToSelect}
      onItemSelect={(item) => onColChange?.(schemaByName[item.name])}
      popoverProps={{
        minimal: true,
        popoverClassName: styles.columnOptionsPopover,
        position: includeDisplayName ? PopoverPosition.BOTTOM_RIGHT : 'auto',
        portalContainer: document.getElementById(APP_PORTAL_ID) ?? undefined,
      }}>
      {includeDisplayName ? (
        <div
          className={cx(
            sprinkles({ cursor: 'pointer' }),
            className ?? styles.popoverTargetContainer,
          )}>
          <div className={styles.colName}>{displayName}</div>
          {caretButton}
        </div>
      ) : (
        caretButton
      )}
    </Select>
  );
};
