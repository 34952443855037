import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { EmbedFetchEphemeralShareData, embedFetchEphemeralShareData } from 'actions/embedActions';
import { EmbeddedChart } from 'components/EmbeddedChart';
import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { SHARE_RESOURCE_TYPES, SHARE_VIEW_TYPES } from 'constants/shareLinkConstants';
import { Export } from 'pages/ReportBuilder/Export';
import { VIEW_MODE } from 'types/dashboardTypes';

type MatchParams = {
  shareId: string;
};

export default function EphemeralShareLinkPage() {
  const [shareData, setShareData] = useState<EmbedFetchEphemeralShareData | null>(null);

  const dispatch = useDispatch();
  const {
    params: { shareId },
  } = useRouteMatch<MatchParams>();

  useEffect(
    () =>
      dispatch(
        embedFetchEphemeralShareData({ id: shareId }, (shareData) => setShareData(shareData.data)),
      ),
    [dispatch, shareId],
  );

  const getViewMode = (shareData: EmbedFetchEphemeralShareData) => {
    switch (shareData.view_type) {
      case SHARE_VIEW_TYPES.EMAIL:
        return VIEW_MODE.EMAIL;
      case SHARE_VIEW_TYPES.PDF:
        return VIEW_MODE.PDF;
      case SHARE_VIEW_TYPES.IMAGE:
      case SHARE_VIEW_TYPES.SHARE:
        return VIEW_MODE.SHARE;
    }
  };

  const getEmbedType = (shareData: EmbedFetchEphemeralShareData) => {
    switch (shareData.view_type) {
      case SHARE_VIEW_TYPES.EMAIL:
        return EmbeddedDashboardType.EMAIL;
      case SHARE_VIEW_TYPES.PDF:
        return EmbeddedDashboardType.PDF;
      case SHARE_VIEW_TYPES.IMAGE:
      case SHARE_VIEW_TYPES.SHARE:
        return EmbeddedDashboardType.SHARED;
    }
  };

  const renderContent = () => {
    if (!shareData) {
      return <EmbedSpinner fillContainer size="xl" />;
    }

    switch (shareData.resource_type) {
      case SHARE_RESOURCE_TYPES.DASHBOARD:
        return (
          <EmbeddedDashboard
            customerToken={shareData.customer_token}
            dashboardEmbedId={shareData.resource_embed_id}
            embedType={getEmbedType(shareData)}
            embeddedVariables={shareData.variables}
            updateUrlParams={false}
            versionNumber={shareData.version_number}
            viewMode={getViewMode(shareData)}
          />
        );
      case SHARE_RESOURCE_TYPES.CHART:
        return (
          <EmbeddedChart
            // ephemeral charts are only ever used for screemnshotting
            isScreenshotDownload
            customerToken={shareData.customer_token}
            dashboardEmbedId={shareData.resource_embed_id}
            dataPanelId={shareData.chart_id}
            variables={shareData.variables}
            versionNumber={shareData.version_number}
          />
        );
      case SHARE_RESOURCE_TYPES.REPORT:
        return (
          <Export
            builtInId={shareData.variables['built_in_id']}
            customerReportId={shareData.variables['customer_report_id']}
            customerToken={shareData.customer_token}
            datasetId={shareData.variables['dataset_id']}
            environment={shareData.variables['environment']}
            reportBuilderEmbedId={shareData.resource_embed_id}
            theme={shareData.variables['theme']}
            timezone={shareData.variables['timezone']}
            title={shareData.variables['title']}
            useQueryVariables={false}
            variables={shareData.variables['variables']}
            viewOverrides={shareData.variables['view_overrides']}
          />
        );
    }
  };

  return <div className={sprinkles({ parentContainer: 'fill' })}>{renderContent()}</div>;
}
