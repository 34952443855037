import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { FilterOperationInstructions } from '@explo/data';

import { ReduxState } from 'reducers/rootReducer';
import { SortInfo_DEPRECATED, VisualizeOperation } from 'types/dataPanelTemplate';

// We need to use ReduxState here instead of DashboardStates because we need to
// be able to grab dataPanels, elements, and datasets from different parts of the app
export type DashboardDataThunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export type FidoReducerArgs = {
  dataPanelId: string;
  sortInfo: SortInfo_DEPRECATED[] | undefined;
  filterInfo: FilterOperationInstructions | undefined;
  pageNumber: number | undefined;
  isPrimaryRequest: boolean;
  secondaryDataAgg: string | undefined;
  timezone: string;
  visualizeOp: VisualizeOperation;
  primaryRequestId: string | undefined;
  isColumnTotalsRequest?: boolean;
};

export type FidoBatchedActionPayload = Array<{
  payload: QueryExecutionResponse;
  meta: {
    arg: {
      reducerArgs: FidoReducerArgs;
    };
    requestId: string;
  };
}>;

export enum FetchOrigin {
  DASHBOARD,
  REPORT_BUILDER,
  DATA_LIBRARY,
}
