import {
  ApiError,
  ComputedView,
  DataPage,
  Namespace,
  NamespaceResponse,
  PropertySchema,
  PropertyType,
  PropertyValue,
  QueryExecutionResponse,
  RequestTelemetry,
  TableView,
} from '@explo-tech/fido-api';
import { DateTime } from 'luxon';
import parse from 'parse-duration';
import { v4 as uuid } from 'uuid';

import {
  BaseCol,
  BOOLEAN,
  DatasetRow,
  DatasetSchema,
  DATE,
  DATETIME,
  DOUBLE,
  FLOAT,
  INTEGER_DATA_TYPE,
  STRING,
  TIMESTAMP,
  Timezones,
} from '@explo/data';

import { DataSource as EmbeddoDataSource, ParentSchema } from 'actions/dataSourceActions';
import { Dataset } from 'actions/datasetActions';
import { QueryDebuggingInformation, QueryTiming } from 'actions/responseTypes';
import { VisualizePivotTableInstructions } from 'constants/types';
import { FIDO_TYPE_KEY } from 'pages/ConnectDataSourceFlow/constants';
import { COMPUTED_VIEW_TYPE } from 'pages/dataLibraryPage/constants';
import { createFullPath } from 'pages/dataLibraryPage/dataLibraryUtil';
import { FidoDaos, FidoTableView, NamespaceTableViewMap } from 'reducers/fidoReducer';
import { SchemaTablesMap } from 'reducers/parentSchemaReducer';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';
import { parseDataSourceFromFido } from './dataSources/fidoDataSourceShims';

export const getEmbeddoTypeFromFidoType = (type: PropertyType) => {
  switch (type) {
    case PropertyType.BOOLEAN:
      return BOOLEAN;
    case PropertyType.DATE:
      return DATE;
    case PropertyType.DATETIME:
      return DATETIME;
    case PropertyType.DECIMAL:
      // TODO when we're done migrating FIDO, it'd be nice to rename this constant to DECIMAL
      return FLOAT;
    case PropertyType.DOUBLE:
      return DOUBLE;
    case PropertyType.INTEGER:
      return INTEGER_DATA_TYPE;
    case PropertyType.LONG:
      return INTEGER_DATA_TYPE;
    case PropertyType.STRING:
      return STRING;
    case PropertyType.UNSUPPORTED:
      return STRING;
  }
};

export const getEmbeddoTypeFromFidoTypeForVariableMapping = (type: PropertyType) => {
  const embeddoType = getEmbeddoTypeFromFidoType(type);
  return embeddoType === DATETIME ? TIMESTAMP : embeddoType;
};

export const getFidoTypeFromEmbeddoType = (type: string) => {
  if (type == BOOLEAN) return PropertyType.BOOLEAN;
  if (type == DATE) return PropertyType.DATE;
  if (type == DATETIME) return PropertyType.DATETIME;
  if (type == TIMESTAMP) return PropertyType.DATETIME;
  if (type == FLOAT) return PropertyType.DECIMAL;
  if (type == INTEGER_DATA_TYPE) return PropertyType.INTEGER;
  else return PropertyType.STRING;
};

export const getEmbeddoSchemaFromFidoSchema = (schema: PropertySchema[]) =>
  schema.map((s) => {
    return {
      name: s.id,
      type: getEmbeddoTypeFromFidoType(s.type),
      friendly_name: s.name ?? s.id,
    } as BaseCol;
  });

export const getFidoSchemaFromEmbeddoSchema = (schema: DatasetSchema) =>
  schema.map((s) => ({
    id: s.name,
    name: s.friendly_name,
    type: getFidoTypeFromEmbeddoType(s.type),
  }));

const getEmbeddoDataFromFidoData = (data: DataPage, timezone?: string) => {
  return data.dataRecords.map((record) => {
    const row: Record<string, Array<PropertyValue>> = record.propertyValues;
    const _row: DatasetRow = {};

    Object.keys(row).map((col) => {
      let value = row[col][0].value.toString();

      // the frontend expects datetimes to be timezone-naive date times, so we need to
      // convert the timezone-aware datetimes in fido to be timezone-naive, but with the
      // hour as the correct value for the required timezone
      if (row[col][0]['@type'] === PropertyType.DATETIME && timezone) {
        // convert the datetime to the expected timezone, this just sets the offset
        value = DateTime.fromISO(value, { zone: timezone })
          // then chop off the offset, keeping the now-offset hour value
          .setZone(Timezones.UTC, { keepLocalTime: true })
          // and put this back into an iso string
          .toISO();
      }

      _row[col] = value;
    });

    return _row;
  });
};

/**
 * Returns a string representing the number of seconds the parsed provided ISO8601 duration string
 */
export const parseTiming = (value: string | null | undefined) => {
  if (!value) return undefined;

  // parse-duraton is a shit library and requires duration to be lowercase
  const parsed = parse(value.toLowerCase(), 's');

  if (!parsed) return undefined;

  return String(Math.round(parsed * 1000) / 1000);
};

const getQueryInformationFromFidoData = (query?: string, telemetry?: RequestTelemetry | null) => {
  const queryTiming: QueryTiming = {
    time_to_run: parseTiming(telemetry?.queryTime),
    time_to_process: parseTiming(telemetry?.processingTime),
    total_time: parseTiming(telemetry?.requestTime),
    cache_hit: telemetry?.cacheTelemetry?.cacheHit,
  };

  return {
    _query_timing: queryTiming,
    _query: query ?? '',
  };
};

export const getEmbeddoResponseFromFidoResponse = (
  response: QueryExecutionResponse,
  timezone?: string,
) => {
  const { data, meta, requestTelemetry } = response;
  const { schema, renderedQuery, totalResults } = meta;

  return {
    rows: getEmbeddoDataFromFidoData(data, timezone),
    schema: getEmbeddoSchemaFromFidoSchema(schema.propertySchema),
    totalResults,
    queryInformation: getQueryInformationFromFidoData(renderedQuery ?? '', requestTelemetry),
  };
};

// Transforms "row" which represents a cell, into a format compatible with Pivot Table V1.
// It combines cells with the same row info into one row for Pivot V1.
// Fido returns a schema with general columns configured for row, column, and pivot
// Here we transforms into the specific columns expected by Pivot Table V1.
export const shimFidoPivotTable = (
  rows: DatasetRow[],
  schema: BaseCol[],
  instructions?: VisualizePivotTableInstructions,
) => {
  const rowColumn = instructions?.rowColumn;
  const aggregation = instructions?.aggregation;

  if (
    rows.length === 0 ||
    schema.length !== 3 ||
    !rowColumn?.column.type ||
    !aggregation?.column.type
  ) {
    // don't expect to get here
    return { rows, schema };
  }

  const rowName = schema[0].name;
  const colName = schema[1].name;
  const aggName = schema[2].name;

  let currentRowValue = rows[0][rowName];
  let currentTransformedRow: DatasetRow = {};

  const transformedRows: DatasetRow[] = [];
  const transformedSchema: BaseCol[] = [
    {
      name: rowName,
      friendly_name: rowName,
      type: schema[0].type,
    },
  ];
  const seenColumnNames = new Set();

  for (const cell of rows) {
    const colValue = cell[colName] == null ? 'None' : cell[colName].toString();

    if (!seenColumnNames.has(colValue)) {
      transformedSchema.push({
        name: colValue,
        friendly_name: colValue,
        type: schema[2].type,
      });
      seenColumnNames.add(colValue);
    }

    const rowValue = cell[rowName];
    // Check if a new row group has started
    if (rowValue !== currentRowValue) {
      currentRowValue = rowValue;
      transformedRows.push(currentTransformedRow);
      currentTransformedRow = {};
    }

    currentTransformedRow[rowName] = rowValue;
    currentTransformedRow[colValue] = cell[aggName];
  }
  transformedRows.push(currentTransformedRow);

  return { rows: transformedRows, schema: transformedSchema };
};

export const getEmbeddoDatasetConfigFromFidoComputedView = (views: ComputedViewWithIds[]) => {
  const datasets: Record<string, Dataset> = {};

  views.forEach((view) => {
    const dataset: Dataset = {
      id: view.id,
      query: view.query,
      table_name: view.name,
      // @ts-ignore
      parent_schema_id: view.namespaceId,
      schema: getEmbeddoSchemaFromFidoSchema(view.columnDefinitions),
    };

    datasets[view.id] = dataset;
  });

  return datasets;
};

export const getDatasetConfigFromView = (view: ComputedView, viewData: Dataset) => {
  return {
    id: viewData.id,
    table_name: view.name,
    parent_schema_id: viewData.namespace_id ?? view.namespaceId,
    query: view.query,
    drilldownColumnConfigs: viewData.drilldownColumnConfigs,
    drilldownConfig: viewData.drilldownConfig,
  };
};

// NOTE: Computed view paths include the name of the computed view itself. Which shouldn't be included in this path as we are appending the table_name to the given path
export const convertDatasetToNewComputedView = (
  dataset: Dataset,
  parentFolderPath: string,
): ComputedView => {
  return {
    id: uuid(),
    '@type': COMPUTED_VIEW_TYPE,
    name: dataset.table_name,
    namespaceId: dataset.namespace_id,
    path: createFullPath(parentFolderPath, dataset.table_name),
    columnDefinitions: getFidoSchemaFromEmbeddoSchema(dataset.schema ?? []),
    query: dataset.query ?? '',
    parameters: [],
    description: null,
    cacheEvictionPolicy: null,
  };
};

export const parseSchema = (namespace: Namespace, schema: ParentSchema): ParentSchema => {
  return {
    id: schema.id,
    name: namespace.name,
    fido_id: namespace.id,
  };
};

export const parseFidoTableViews = (views: TableView[]): Record<string, FidoTableView> => {
  const viewsMap: Record<string, FidoTableView> = {};
  views.forEach(
    (table) =>
      (viewsMap[table.id ?? ''] = {
        tableName: table.tableName,
        id: table.id,
        namespaceId: table.namespaceId,
        schema: getEmbeddoSchemaFromFidoSchema(table.columnDefinitions),
      }),
  );
  return viewsMap;
};

export const parseListNamespacesWithMetaResponse = (
  namespaceResponses: NamespaceResponse[],
  fetchedEmbeddoParentSchemas: ParentSchema[],
  fetchedEmbeddoDataSources: EmbeddoDataSource[],
  fetchedEmbeddoSchemaTablesMap: SchemaTablesMap,
): FidoDaos => {
  const parsedNamespaces: ParentSchema[] = [];
  const parsedDataSources: EmbeddoDataSource[] = [];
  const tables: FidoTableView[] = [];
  const schemaTablesMap: NamespaceTableViewMap = {};

  namespaceResponses.forEach((namespaceResponse) => {
    const ns = namespaceResponse.namespace;
    const dataSources = namespaceResponse.meta?.dataSources;
    const views = namespaceResponse.meta?.resources;
    const embeddoParentSchema = fetchedEmbeddoParentSchemas.find((ps) => ps.fido_id === ns.id);

    if (!embeddoParentSchema) {
      console.error('Namespace with fido_id', ns.id, 'not found');
      return;
    }

    const embeddoSchemaTablesMapEntry =
      fetchedEmbeddoSchemaTablesMap[embeddoParentSchema?.id.toString()];

    parsedNamespaces.push(parseSchema(ns, embeddoParentSchema));

    if (dataSources !== undefined && dataSources != null) {
      dataSources.forEach((fidoDataSource) => {
        const embeddoDataSource = fetchedEmbeddoDataSources.find(
          (eds) => eds.fido_id === fidoDataSource.id,
        );

        if (!embeddoDataSource) {
          console.error('Data Source with fido_id', fidoDataSource.id, 'not found');
          return;
        }

        parsedDataSources.push(parseDataSourceFromFido(fidoDataSource, embeddoDataSource));
      });
    }

    const tableViews = (views ?? []).filter(
      (view) => (view as TableView | ComputedView)[FIDO_TYPE_KEY] === 'table-view',
    ) as TableView[];

    if (tableViews.length > 0) {
      schemaTablesMap[ns.id] = parseFidoTableViews(tableViews);
      tables.push(...Object.values(schemaTablesMap[ns.id]));
    } // try to pull from embeddo if we don't have table views saved in FIDO
    else {
      const map: Record<string, FidoTableView> = {};
      Object.entries(embeddoSchemaTablesMapEntry).forEach(([id, entry]) => {
        map[id.toString()] = { ...entry, tableName: entry.table_name, id: entry.id.toString() };
      });
      schemaTablesMap[ns.id] = map;
    }
  });

  return { namespaces: parsedNamespaces, dataSources: parsedDataSources, tables, schemaTablesMap };
};

export const extractQueryInformationFromFidoException = (
  error: ApiError | undefined,
): { error: string; queryInformation: QueryDebuggingInformation | undefined } => ({
  error: error?.message ?? 'There was an error fetching the results',
  queryInformation:
    error && 'renderedQuery' in error
      ? {
          _query: error.renderedQuery as string,
          _query_timing: {},
        }
      : undefined,
});
