import { FC } from 'react';

import { FILTER_OPS_NUMBER_RANGE, FilterOperator } from '@explo/data';

import DropdownSelect from 'shared/DropdownSelect';
import { FILTER_OPERATORS } from 'types/filterOperations';
import { Props as FilterRowProps } from './FilterRow';

type Props = { className?: string; usePortal?: boolean; disabled?: boolean } & Pick<
  FilterRowProps,
  'selectedOperator' | 'selectedColumn' | 'onOperatorSelect'
>;
export const OperatorDropdown: FC<Props> = ({
  className,
  selectedColumn,
  selectedOperator,
  onOperatorSelect,
  usePortal,
  disabled,
}) => {
  // TODO: Add support for number range
  const relevantOperators = FILTER_OPERATORS.filter((op) =>
    selectedColumn
      ? op.supported_column_types.has(selectedColumn.type) && !FILTER_OPS_NUMBER_RANGE.has(op.id)
      : false,
  );

  return (
    <span className={className}>
      <DropdownSelect
        fillWidth
        minimal
        disabled={disabled}
        filterable={false}
        noSelectionText="Operator"
        onChange={(item) => onOperatorSelect(item.id as FilterOperator)}
        options={relevantOperators.map((op) => ({
          name: op.selectionValue,
          id: op.id,
        }))}
        selectedItem={
          selectedOperator ? relevantOperators.find((op) => op.id === selectedOperator) : undefined
        }
        usePortal={usePortal}
      />
    </span>
  );
};
