import Highcharts from 'highcharts';
import { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DatasetSchema, NUMBER_TYPES } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { ChartTooltip } from 'components/embed';
import { V2ScatterPlotInstructions } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DashboardVariableMap } from 'types/dashboardTypes';

import { SeriesOptions } from './constants/types';
import { HighCharts } from './highCharts';
import {
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorPalette,
  getLabelStyle,
  xAxisFormat,
} from './utils';
import { getGoalLines } from './utils/goalLineUtils';
import { getSingleYAxisInstructions, getValueFormat } from './utils/multiYAxisUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

// default jitter in the highcharts demo docs
const SCATTER_JITTER_DEFAULT = 0.24;

type Props = {
  backgroundColor: string;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  globalStyleConfig: GlobalStyleConfig;
  instructions?: V2ScatterPlotInstructions;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  schema: DatasetSchema;
  variables: DashboardVariableMap;
};

type State = {};

class ScatterPlot extends PureComponent<Props, State> {
  transformNumberXAxisData = () => {
    const { instructions, previewData } = this.props;
    if (!instructions) return;

    const series: SeriesOptions[] = [];
    previewData.forEach((entry: Record<string, string | number>) => {
      const { xAxisColumn, yAxisColumn, groupingColumn } = instructions;
      if (!xAxisColumn?.name || !yAxisColumn?.name) return [];

      const dataPoint: [number, number] | PointOptionsObject = [
        +entry[xAxisColumn.name],
        +entry[yAxisColumn.name],
      ];
      if (groupingColumn?.name) {
        const matchingSeriesItem = series.find((item) =>
          groupingColumn?.name ? item.name === entry[groupingColumn.name] : undefined,
        );
        if (!matchingSeriesItem) {
          // If grouping not in series, add new entry + data
          series.push({
            name: `${entry[groupingColumn.name]}`,
            data: [dataPoint],
            type: 'scatter',
          });
        } else {
          // If grouping already in series, just add data
          matchingSeriesItem.data.push(dataPoint);
        }
      } else {
        // If no grouping, combine all data
        if (series.length === 0) {
          series.push({
            name: instructions.scatterPlotFormat?.seriesLabel || 'Series 1',
            data: [dataPoint],
            type: 'scatter',
          });
        } else {
          series[0].data.push(dataPoint);
        }
      }
    });
    return series;
  };

  transformCategoryXAxisData = () => {
    const { instructions, previewData } = this.props;
    if (!instructions) return;
    const series: Record<string, SeriesOptions> = {};

    const categories: { [name: string]: number } = {};
    previewData.forEach((entry: Record<string, string | number>) => {
      const { xAxisColumn } = instructions;
      if (!xAxisColumn?.name) return;
      categories[entry[xAxisColumn.name]] = 0;
    });

    const categoryOrder = Object.keys(categories);

    previewData.forEach((entry: Record<string, string | number>) => {
      const { xAxisColumn, yAxisColumn } = instructions;
      if (!xAxisColumn?.name || !yAxisColumn?.name) return [];
      const category = entry[xAxisColumn.name] as string;
      const value = getAxisNumericalValue(entry[yAxisColumn.name]);

      const categoryIndex = categoryOrder.indexOf(category);

      const dataPoint = [categoryIndex, value] as [number, number];
      if (series[category]) {
        series[category].data.push(dataPoint);
      } else {
        series[category] = {
          type: 'scatter',
          name: String(category),
          data: [dataPoint],
        };
      }
    });
    return Object.values(series);
  };

  transformData = () => {
    const { instructions } = this.props;
    if (!instructions?.xAxisColumn?.name || !instructions?.yAxisColumn?.name) {
      return [];
    }

    if (this.isCategoryPlot()) return this.transformCategoryXAxisData();
    return this.transformNumberXAxisData();
  };

  isCategoryPlot = () => {
    const { instructions } = this.props;
    if (!instructions?.xAxisColumn) return;
    return !NUMBER_TYPES.has(instructions?.xAxisColumn.type || '');
  };

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      variables,
      datasetData,
      datasetNamesToId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;
    const xAxisValueFormat = getValueFormat(instructions?.xAxisFormat);
    const xAxisFormatInstructions = {
      decimalPlaces: instructions?.xAxisFormat?.showDecimals ? xAxisValueFormat.decimalPlaces : 0,
      formatId: xAxisValueFormat.valueFormatId,
    };
    const yAxisValueFormat = getValueFormat(instructions?.yAxisFormat);
    const yAxisFormatInstructions = {
      decimalPlaces: instructions?.yAxisFormat?.showDecimals ? yAxisValueFormat.decimalPlaces : 0,
      formatId: yAxisValueFormat.valueFormatId,
    };
    const transformedData = this.transformData();
    const isCategoryPlotFlag = this.isCategoryPlot();

    const xAxisFormatComputed = isCategoryPlotFlag
      ? {
          categories: transformedData?.map((series) => series.name || ''),
        }
      : ({
          allowDecimals: instructions?.xAxisFormat?.showDecimals,
          min: instructions?.xAxisFormat?.min ?? null,
          max: instructions?.xAxisFormat?.max ?? null,
          startOnTick: instructions?.xAxisFormat?.min === null,
          endOnTick: instructions?.xAxisFormat?.max === null,
          labels: {
            formatter: function () {
              const value = getAxisNumericalValue(this.value);
              return formatValue({
                ...xAxisFormatInstructions,
                value: isNaN(value) ? 0 : value,
                hasCommas: true,
              });
            },
            style: getLabelStyle(globalStyleConfig, 'secondary'),
            enabled: !instructions?.xAxisFormat?.hideAxisLabels,
            rotation: instructions?.xAxisFormat?.rotationAngle,
          },
          type: instructions?.xAxisFormat?.useLogScale ? 'logarithmic' : 'linear',
          visible: !instructions?.xAxisFormat?.hideAxisLine,
          ...getGoalLines(instructions?.goalLines, variables, datasetNamesToId, datasetData, {
            xAxisGoals: true,
          }),
        } as Highcharts.XAxisOptions);

    const yAxis = getSingleYAxisInstructions(
      globalStyleConfig,
      instructions,
      variables,
      datasetNamesToId,
      datasetData,
    );

    return {
      chart: {
        backgroundColor,
        type: 'scatter',
        zoomType: 'xy',
      },
      series: this.transformData(),
      title: sharedTitleConfig,
      plotOptions: {
        scatter: {
          marker: {
            radius: instructions?.scatterPlotFormat?.radius ?? 4,
          },
          jitter: instructions?.scatterPlotFormat?.useJitter
            ? {
                x: SCATTER_JITTER_DEFAULT,
                y: 0,
              }
            : undefined,
        },
      },
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      yAxis,
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        ...xAxisFormatComputed,
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            <ChartTooltip
              globalStyleConfig={globalStyleConfig}
              header={
                this.point.series.name || instructions?.scatterPlotFormat?.seriesLabel || 'Series 1'
              }
              points={
                isCategoryPlotFlag
                  ? [
                      {
                        color: String(this.point.color),
                        name:
                          instructions?.yAxisColumn?.friendly_name ||
                          instructions?.yAxisColumn?.name ||
                          'y',
                        value: this.point.y || 0,
                        format: yAxisFormatInstructions,
                      },
                    ]
                  : [
                      {
                        color: String(this.point.color),
                        name:
                          instructions?.xAxisColumn?.friendly_name ||
                          instructions?.xAxisColumn?.name ||
                          'x',
                        value: this.point.x || 0,
                        format: xAxisFormatInstructions,
                      },
                      {
                        color: String(this.point.color),
                        name:
                          instructions?.yAxisColumn?.friendly_name ||
                          instructions?.yAxisColumn?.name ||
                          'y',
                        value: this.point.y || 0,
                        format: yAxisFormatInstructions,
                      },
                    ]
              }
            />,
          );
        },
      },
    };
  };

  render() {
    const { instructions, loading } = this.props;
    const instructionsReadyToDisplay = !!(
      instructions &&
      instructions.xAxisColumn &&
      instructions.yAxisColumn
    );

    if (loading || !instructionsReadyToDisplay) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
        />
      );
    }

    return <HighCharts chartOptions={this._spec()} />;
  }
}

export { ScatterPlot };
