import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

export const getAllDataPanelsInVersion = (
  dashboardVersionConfig: DashboardVersionConfig,
): Record<string, DataPanelTemplate> => {
  const dataPanels: Record<string, DataPanelTemplate> = { ...dashboardVersionConfig.data_panels };
  Object.values(dashboardVersionConfig.editable_section?.charts ?? {}).forEach((chart) => {
    dataPanels[chart.data_panel.id] = chart.data_panel;
  });

  return dataPanels;
};
