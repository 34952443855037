import { ColumnConfigsWithName, DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { SchemaChange, UserTransformedSchema } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { cloneDeep, keyBy } from 'utils/standard';

import { attachMonthFilterToDataPanel } from './calendarHeatmapUtils';
import { sortAggregationsByOrderedColumnNames } from './general';
import { sortSchemaChangeByOrderedColumnNames } from './tableSchemaUtils';
import { replaceVariablesInString } from './variableUtils';

export const getTransformedDataPanelForCsv = (
  dataPanel: DataPanel,
  dpSchema: DatasetSchema | undefined,
  userTransformedSchema: UserTransformedSchema | undefined,
  variables: DashboardVariableMap,
  datasetData: DatasetDataObject,
  datasetNameToIdMap: Record<string, string>,
  timezone: string,
): DataPanel => {
  const operationType = dataPanel.visualize_op.operation_type;
  const shouldUseTransformedSchemaForTable =
    operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
    (dataPanel.visualize_op.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled ||
      dataPanel.id === '_drilldown_data_panel');
  const shouldUseTransformedSchema =
    userTransformedSchema &&
    (shouldUseTransformedSchemaForTable ||
      operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER);

  const newDp = cloneDeep(dataPanel);

  if (shouldUseTransformedSchema) {
    // We check the truthiness of userTransformedSchema above
    incorporateUserSchemaOverrides(newDp, userTransformedSchema);
  } else {
    incorporateNewColumns(newDp, dpSchema ?? []);
  }
  resolveVariablesInChangeSchemaList(
    newDp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList,
    variables,
    datasetData,
    datasetNameToIdMap,
  );
  resolveVariablesInPivotTableColumnConfigs(
    newDp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE_V2?.columnConfigs ?? {},
    variables,
  );

  // For calendar heatmap exports
  attachMonthFilterToDataPanel(newDp, variables, timezone);

  return newDp;
};

/**
 * Replaces any variables references within the changeSchemaList, specifically within the newColName
 * field.
 *
 * NOTE: Exported only for unit testing.
 */
export const resolveVariablesInChangeSchemaList = (
  changeSchemaList: SchemaChange[],
  variables: DashboardVariableMap,
  datasetData: DatasetDataObject,
  datasetNameToIdMap: Record<string, string>,
): void => {
  changeSchemaList.forEach((changeSchema) => {
    if (!changeSchema.newColName) {
      return;
    }

    changeSchema.newColName = replaceVariablesInString(
      changeSchema.newColName,
      variables,
      datasetNameToIdMap,
      datasetData,
    );
  });
};

const resolveVariablesInPivotTableColumnConfigs = (
  columnConfigs: ColumnConfigsWithName,
  variables: DashboardVariableMap,
): void => {
  Object.values(columnConfigs).forEach((columnConfig) => {
    if (!columnConfig.name) return;

    columnConfig.name = replaceVariablesInString(columnConfig.name, variables);
  });
};

const incorporateUserSchemaOverrides = (
  dp: DataPanel,
  userTransformedSchema: UserTransformedSchema,
): void => {
  const changeSchemaList = dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaByColName = keyBy(changeSchemaList, 'col');

  dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList = userTransformedSchema.map(
    (userTransformedCol) => {
      const originalChangeSchema = changeSchemaByColName[userTransformedCol.name];
      return {
        col: userTransformedCol.name,
        newColName: userTransformedCol?.friendly_name || originalChangeSchema.newColName,
        keepCol: userTransformedCol.isVisible,
      };
    },
  );
};

const incorporateNewColumns = (dp: DataPanel, dpSchema: DatasetSchema): void => {
  const operationType = dp.visualize_op.operation_type;

  if (operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
    const instructions = dp.visualize_op.instructions.VISUALIZE_TABLE;
    const newChangeSchemaList: SchemaChange[] = instructions.changeSchemaList;

    // Add in the columns to changeSchemaList that never changed from default
    dpSchema.forEach(({ name }) => {
      const foundChangeSchemaItem = instructions.changeSchemaList.find(({ col }) => col === name);
      if (!foundChangeSchemaItem) newChangeSchemaList.push({ col: name, keepCol: true });
    });

    dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList =
      sortSchemaChangeByOrderedColumnNames(newChangeSchemaList, instructions.orderedColumnNames);
  }

  // Ensures csv download for collapsible list reflects orderedColumnNames
  if (
    operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST &&
    dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations !== undefined
  ) {
    dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.aggregations =
      sortAggregationsByOrderedColumnNames(
        dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.aggregations,
        dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.orderedColumnNames,
      );
  }
};
