import { DateTime } from 'luxon';

import { PivotAgg } from './instructions/aggregations';
import { formatTime } from './utils/localizationUtils';

export enum DEFAULT_DATE_TYPES {
  RELATIVE = 'relative',
  EXACT = 'exact',
}

export enum DATE_RANGE_TYPES {
  RELATIVE = 'relative',
  EXACT = 'exact',
}

// Must be help in sync with RELATIVE_DATE_OPTIONS in BE
export enum RELATIVE_DATE_OPTIONS {
  CURRENT_DAY = 'Current day',
  YESTERDAY = 'Yesterday',
  SEVEN_DAYS_AGO = '7 days ago',
  THIRTY_DAYS_AGO = '30 days ago',
  ONE_YEAR_AGO = '365 days ago',
  START_OF_WEEK = 'Start of the week (Monday)',
  START_OF_MONTH = 'Start of the month',
  START_OF_YEAR = 'Start of the year',
  END_OF_WEEK = 'End of the week',
  END_OF_MONTH = 'End of the month',
  END_OF_YEAR = 'End of the year',
}

export const RELATIVE_DATE_OPTIONS_SET = new Set(Object.values(RELATIVE_DATE_OPTIONS));

export enum RELATIVE_DATE_RANGES {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  THIS_YEAR = 'This year',
  LAST_WEEK = 'Last week',
  LAST_MONTH = 'Last month',
  WITHIN_A_WEEK = 'Within 7 days',
  WITHIN_A_MONTH = 'Within 30 days',
  WITHIN_A_YEAR = 'Within 365 days',
  WITHIN_THREE_YEARS = 'Within 3 years',
  WITHIN_FIVE_YEARS = 'Within 5 years',
  WITHIN_TEN_YEARS = 'Within 10 years',
  PAST_DATES = 'Past dates only',
  THIS_QUARTER = 'This quarter',
  LAST_QUARTER = 'Last quarter',
  PREVIOUS_YEAR = 'Previous year',
}

// Must be help in sync with DEFAULT_DATE_RANGES in BE
export enum DEFAULT_DATE_RANGES {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  THIS_QUARTER = 'This quarter',
  THIS_YEAR = 'This year',
  FULL_WEEK = 'Full week',
  FULL_MONTH = 'Full month',
  FULL_QUARTER = 'Full quarter',
  FULL_YEAR = 'Full year',
  LAST_WEEK = 'Last week',
  LAST_MONTH = 'Last month',
  LAST_QUARTER = 'Last quarter',
  PREVIOUS_YEAR = 'Previous year',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  LAST_3_MONTHS = 'Last 3 months',
  LAST_6_MONTHS = 'Last 6 months',
  LAST_4_COMPLETE_WEEKS = 'Last 4 complete weeks',
  LAST_12_COMPLETE_WEEKS = 'Last 12 complete weeks',
  LAST_3_COMPLETE_MONTHS = 'Last 3 complete months',
  LAST_6_COMPLETE_MONTHS = 'Last 6 complete months',
  LAST_YEAR = 'Last year',
  YEAR_TO_LAST_COMPLETED_MONTH = 'Year to last completed month',
  LAST_12_COMPLETE_MONTHS = 'Last 12 complete months',
}

export const DEFAULT_DATE_RANGES_SET = new Set(Object.values(DEFAULT_DATE_RANGES));

// These are new date ranges we add above that we do not want to show
// on default to all previously created date ranges
export const NEW_DATE_RANGES_TO_HIDE_ON_DEFAULT = new Set([
  DEFAULT_DATE_RANGES.LAST_4_COMPLETE_WEEKS,
  DEFAULT_DATE_RANGES.FULL_WEEK,
  DEFAULT_DATE_RANGES.FULL_MONTH,
  DEFAULT_DATE_RANGES.FULL_QUARTER,
  DEFAULT_DATE_RANGES.FULL_YEAR,
]);

export const DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES: Record<string, string> = {
  [DEFAULT_DATE_RANGES.LAST_YEAR]: 'Last 12 months',
  [DEFAULT_DATE_RANGES.LAST_QUARTER]: 'Previous Quarter',
  [DEFAULT_DATE_RANGES.LAST_WEEK]: 'Previous Week',
  [DEFAULT_DATE_RANGES.LAST_MONTH]: 'Previous Month',
  [DEFAULT_DATE_RANGES.PREVIOUS_YEAR]: 'Previous Year',
  [DEFAULT_DATE_RANGES.THIS_WEEK]: 'Week to date',
  [DEFAULT_DATE_RANGES.THIS_MONTH]: 'Month to date',
  [DEFAULT_DATE_RANGES.THIS_QUARTER]: 'Quarter to date',
  [DEFAULT_DATE_RANGES.THIS_YEAR]: 'Year to date',
  [DEFAULT_DATE_RANGES.FULL_WEEK]: 'This week',
  [DEFAULT_DATE_RANGES.FULL_MONTH]: 'This month',
  [DEFAULT_DATE_RANGES.FULL_QUARTER]: 'This quarter',
  [DEFAULT_DATE_RANGES.FULL_YEAR]: 'This year',
};

export enum PeriodRangeTypes {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 days',
  LAST_4_WEEKS = 'Last 4 weeks',
  PREVIOUS_MONTH = 'Previous month',
  LAST_3_MONTHS = 'Last 3 months',
  LAST_12_MONTHS = 'Last 12 months',
  MONTH_TO_DATE = 'Month to date',
  YEAR_TO_DATE = 'Year to date',
  CUSTOM_RANGE = 'Custom Range',
  CUSTOM_RANGE_VARIABLES = 'Custom Range Variables',
  DATE_RANGE_INPUT = 'Date Range Input',
  TIME_PERIOD_DROPDOWN = 'Time Period Dropdown',
}

export const PERIOD_RANGE_OPTIONS = {
  [PeriodRangeTypes.TODAY]: {
    id: PeriodRangeTypes.TODAY,
    name: 'Today',
  },
  [PeriodRangeTypes.LAST_7_DAYS]: {
    id: PeriodRangeTypes.LAST_7_DAYS,
    name: 'Last 7 days',
  },
  [PeriodRangeTypes.LAST_4_WEEKS]: {
    id: PeriodRangeTypes.LAST_4_WEEKS,
    name: 'Last 4 weeks',
  },
  [PeriodRangeTypes.PREVIOUS_MONTH]: {
    id: PeriodRangeTypes.PREVIOUS_MONTH,
    name: 'Previous month',
  },
  [PeriodRangeTypes.LAST_3_MONTHS]: {
    id: PeriodRangeTypes.LAST_3_MONTHS,
    name: 'Last 3 months',
  },
  [PeriodRangeTypes.LAST_12_MONTHS]: {
    id: PeriodRangeTypes.LAST_12_MONTHS,
    name: 'Last 12 months',
  },
  [PeriodRangeTypes.MONTH_TO_DATE]: {
    id: PeriodRangeTypes.MONTH_TO_DATE,
    name: 'Month to date',
  },
  [PeriodRangeTypes.YEAR_TO_DATE]: {
    id: PeriodRangeTypes.YEAR_TO_DATE,
    name: 'Year to date',
  },
  [PeriodRangeTypes.CUSTOM_RANGE]: {
    id: PeriodRangeTypes.CUSTOM_RANGE,
    name: 'Custom Range',
  },
  [PeriodRangeTypes.CUSTOM_RANGE_VARIABLES]: {
    id: PeriodRangeTypes.CUSTOM_RANGE_VARIABLES,
    name: 'Custom Range Variables',
  },
  [PeriodRangeTypes.DATE_RANGE_INPUT]: {
    id: PeriodRangeTypes.DATE_RANGE_INPUT,
    name: 'Date Range Input',
  },
  [PeriodRangeTypes.TIME_PERIOD_DROPDOWN]: {
    id: PeriodRangeTypes.TIME_PERIOD_DROPDOWN,
    name: 'Time Period Dropdown',
  },
};

export enum PeriodComparisonRangeTypes {
  PREVIOUS_PERIOD = 'Previous Period',
  PREVIOUS_MONTH = 'Previous Month',
  PREVIOUS_YEAR = 'Previous Year',
  NO_COMPARISON = 'No Comparison',
  // This and below only available for text trend
  PREVIOUS_CUSTOM_RANGE = 'Previous Custom Range',
  PREVIOUS_DATE_RANGE_INPUT = 'Previous Date Range Input',
  PREVIOUS_TIME_PERIOD_DROPDOWN = 'Previous Time Period Dropdown',
}

export const PERIOD_COMPARISON_RANGE_TYPES = {
  [PeriodComparisonRangeTypes.PREVIOUS_PERIOD]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_PERIOD,
    name: 'Previous Period',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_MONTH]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_MONTH,
    name: 'Previous Month',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_YEAR]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_YEAR,
    name: 'Previous Year',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_CUSTOM_RANGE]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_CUSTOM_RANGE,
    name: 'Custom Range',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT,
    name: 'Date Range Input',
  },
  [PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN]: {
    id: PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN,
    name: 'Time Period Dropdown',
  },
  [PeriodComparisonRangeTypes.NO_COMPARISON]: {
    id: PeriodComparisonRangeTypes.NO_COMPARISON,
    name: 'No Comparison',
  },
};

export const TEXT_TREND_PERIOD_COMPARISON_RANGE_TYPES = new Set([
  PeriodComparisonRangeTypes.PREVIOUS_CUSTOM_RANGE,
  PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT,
  PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN,
]);

// Keep in sync with bucket_to_name in BE
export const PIVOT_AGG_TYPES: Record<
  PivotAgg,
  { id: PivotAgg; name: string; getExample: (datetime: DateTime) => string }
> = {
  DATE_HOUR: {
    id: PivotAgg.DATE_HOUR,
    name: 'Hour',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['HH:00 M/D']),
  },
  DATE_DAY: {
    id: PivotAgg.DATE_DAY,
    name: 'Day',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['MMM D, YYYY']),
  },
  DATE_WEEK: {
    id: PivotAgg.DATE_WEEK,
    name: 'Week',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['MMM D, YYYY']),
  },
  DATE_MONTH: {
    id: PivotAgg.DATE_MONTH,
    name: 'Month',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['MMM YYYY']),
  },
  DATE_QUARTER: {
    id: PivotAgg.DATE_QUARTER,
    name: 'Quarter',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['Quarter']),
  },
  DATE_YEAR: {
    id: PivotAgg.DATE_YEAR,
    name: 'Year',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['YYYY']),
  },
  DATE_AUTO: {
    id: PivotAgg.DATE_SMART,
    name: 'Smart Grouping',
    getExample: () => '',
  },
  DATE_PART_WEEK_DAY: {
    id: PivotAgg.DATE_PART_WEEK_DAY,
    name: 'Day of Week',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['ddd']),
  },
  DATE_PART_MONTH_DAY: {
    id: PivotAgg.DATE_PART_MONTH_DAY,
    name: 'Day of Month',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['D']),
  },
  DATE_PART_MONTH: {
    id: PivotAgg.DATE_PART_MONTH,
    name: 'Month of Year',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['MMM']),
  },
  DATE_PART_HOUR: {
    id: PivotAgg.DATE_PART_HOUR,
    name: 'Hour of Day',
    getExample: (dateTime: DateTime) => formatTime(dateTime, TIME_FORMATS['ha']),
  },
};

export enum TrendGroupingOptions {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export const TREND_GROUPING_OPTIONS = {
  [TrendGroupingOptions.HOURLY]: {
    id: TrendGroupingOptions.HOURLY,
    name: 'Hourly',
  },
  [TrendGroupingOptions.DAILY]: {
    id: TrendGroupingOptions.DAILY,
    name: 'Daily',
  },
  [TrendGroupingOptions.WEEKLY]: {
    id: TrendGroupingOptions.WEEKLY,
    name: 'Weekly',
  },
  [TrendGroupingOptions.MONTHLY]: {
    id: TrendGroupingOptions.MONTHLY,
    name: 'Monthly',
  },
  [TrendGroupingOptions.YEARLY]: {
    id: TrendGroupingOptions.YEARLY,
    name: 'Yearly',
  },
};

export const TREND_GROUP_OPTION_TO_PIVOT_AGG = {
  [TrendGroupingOptions.HOURLY]: PIVOT_AGG_TYPES.DATE_HOUR,
  [TrendGroupingOptions.DAILY]: PIVOT_AGG_TYPES.DATE_DAY,
  [TrendGroupingOptions.WEEKLY]: PIVOT_AGG_TYPES.DATE_WEEK,
  [TrendGroupingOptions.MONTHLY]: PIVOT_AGG_TYPES.DATE_MONTH,
  [TrendGroupingOptions.YEARLY]: PIVOT_AGG_TYPES.DATE_YEAR,
};

export const DATE_PIVOT_AGGS = [
  PIVOT_AGG_TYPES.DATE_DAY,
  PIVOT_AGG_TYPES.DATE_WEEK,
  PIVOT_AGG_TYPES.DATE_MONTH,
  PIVOT_AGG_TYPES.DATE_QUARTER,
  PIVOT_AGG_TYPES.DATE_YEAR,
  PIVOT_AGG_TYPES.DATE_AUTO,
];

export const DATE_PIVOT_AGGS_SET: Set<string> = new Set(DATE_PIVOT_AGGS.map((agg) => agg.id));

export const DATETIME_PIVOT_AGGS = [PIVOT_AGG_TYPES.DATE_HOUR, ...DATE_PIVOT_AGGS];

export const DATETIME_PIVOT_AGGS_SET: Set<string> = new Set(
  DATETIME_PIVOT_AGGS.map((agg) => agg.id),
);

export const DATE_PART_AGGS = [
  PIVOT_AGG_TYPES.DATE_PART_WEEK_DAY,
  PIVOT_AGG_TYPES.DATE_PART_MONTH_DAY,
  PIVOT_AGG_TYPES.DATE_PART_MONTH,
];

export const DATETIME_PART_AGGS = [PIVOT_AGG_TYPES.DATE_PART_HOUR, ...DATE_PART_AGGS];

export const DATETIME_PART_PIVOT_AGG_SET: Set<string> = new Set(
  DATETIME_PART_AGGS.map((agg) => agg.id),
);

export interface GroupOption {
  title?: string;
  options: { id: string; name: string; getExample?: (dateTime: DateTime) => string }[];
}

export const DATE_PART_INPUT_AGG = 'DATE_PART_INPUT_AGG';

export enum TIME_FORMATS {
  'h:mm:ss A',
  'MM/DD/YYYY',
  'MMMM D, YYYY',
  'MMM D, YYYY',
  'MMM D, YYYY h:mm A',
  'MMM D',
  'HH:00 M/D',
  'MM/DD/YYYY HH:00 aa',
  'MM-DD-YY',
  'MM/DD/YYYY h:mm aa',
  'MMM YYYY',
  'YYYY',
  'ddd',
  'D',
  'MMM',
  'YYYY-MM-DD',
  'DD-MM-YYYY',
  'MM-dd-yy (HH:mm:ss)',
  'DD/MM/YYYY (HH:mm:ss)',
  'ha',
  'h:mm A',
  'MMMM D, YYYY h:mm A',
  'Relative to Now',
  'Quarter',
}

export interface DateRange {
  startDate: DateTime;
  endDate: DateTime;
}

export type KpiDateRanges = {
  currentPeriod: DateRange;
  previousPeriod?: DateRange;
};
