import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dashboard } from 'actions/dashboardActions';
import { DashboardDebugger } from 'pages/dashboardPage/DashboardDebugger';
import { AddDataPanelModal } from 'pages/dashboardPage/addDataPanelModal';
import { DashboardEditBanner } from 'pages/dashboardPage/dashboardEditBanner';
import { getIsFullPageDashboard } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets, getReferencedGlobalDatasets } from 'reducers/selectors';
import { VersionInfo } from 'types/exploResource';
import { getViewFromDatasetId } from 'utils/fido/fidoRequestUtils';

import { EditDashboardLeftPanel } from './EditDashboardLeftPanel';
import { DashboardEditorEmbeddoDataFetcher } from './dashboardDatasetEditor/DashboardEditorEmbeddoDataFetcher';
import { DashboardEditorFidoDataFetcher } from './dashboardDatasetEditor/DashboardEditorFidoDataFetcher';
import { DeleteElementModal } from './deleteElementModal';
import { EditDashboardPageBody } from './editDashboardBody';
import * as styles from './editDashboardPage.css';
import { EditDataPanelModal } from './editDataPanelModal';
import { ViewGlobalDatasetModal } from './EditDashboardLeftPanel/DatasetsPanel/ViewGlobalDatasetModal';
import { setSelectedGlobalDatasetId } from 'reducers/dashboardEditConfigReducer';

type Props = {
  dashboard: Dashboard;
  dashboardVersionInfo: VersionInfo;
  width?: number;
};

export const EditDashboardPage: FC<Props> = ({ dashboard, dashboardVersionInfo, width }) => {
  const dispatch = useDispatch();

  const {
    editableDashboard,
    isFullPageDashboard,
    rightPaneOpen,
    selectedDatasetId,
    selectedGlobalDatasetId,
    referencedGlobalDatasets,
    shouldUseFido,
    datasets,
    computedViews,
  } = useSelector(
    (state: ReduxState) => ({
      editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
      isFullPageDashboard: getIsFullPageDashboard(state.dashboardInteractions),
      rightPaneOpen: state.dashboardInteractions.paneOpenStates.right,
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
      selectedGlobalDatasetId: state.dashboardEditConfig.selectedGlobalDatasetId,
      referencedGlobalDatasets: getReferencedGlobalDatasets(state),
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      isSideBarOpen: state.dashboardInteractions.isSidebarOpen,
      datasets: getEditableDatasets(state),
      computedViews: state.fido.computedViews,
    }),
    shallowEqual,
  );

  const renderEditor = () => {
    return (
      <div className={styles.datasetEditorBase}>
        {shouldUseFido ? (
          <DashboardEditorFidoDataFetcher
            selectedDataset={selectedDatasetId ? datasets[selectedDatasetId] : undefined}
            selectedView={
              selectedDatasetId
                ? (getViewFromDatasetId(computedViews, datasets, selectedDatasetId) ?? undefined)
                : undefined
            }
          />
        ) : (
          <DashboardEditorEmbeddoDataFetcher />
        )}
      </div>
    );
  };

  const renderDashboardEditPage = () => {
    return (
      <>
        <DashboardEditBanner
          dashboard={dashboard}
          dashboardVersionInfo={dashboardVersionInfo}
          inEditMode={editableDashboard}
        />
        {editableDashboard ? null : (
          <div className={styles.previewBanner}>
            This is a preview of&nbsp;<b>{dashboard.name}</b>. Changes will not be saved.
          </div>
        )}
        <div className={styles.bodyBelowBanner}>
          <EditDashboardLeftPanel
            dashboardId={dashboard.id}
            isFullPageDashboard={isFullPageDashboard}
            shareLinkTitle={dashboard.share_link_title}
          />
          <EditDashboardPageBody
            dashboard={dashboard}
            versionInfo={dashboardVersionInfo}
            width={width}
          />
          {!isFullPageDashboard && rightPaneOpen ? (
            <div className={styles.rightSide}>
              <DashboardDebugger />
            </div>
          ) : null}
        </div>
        <AddDataPanelModal dashboardId={dashboard.id} />
        <EditDataPanelModal />
        <DeleteElementModal />
      </>
    );
  };

  return (
    <div className={styles.root}>
      {selectedDatasetId !== null ? renderEditor() : null}
      {selectedGlobalDatasetId ? (
        <ViewGlobalDatasetModal
          isOpen
          globalDataset={referencedGlobalDatasets[selectedGlobalDatasetId]}
          onClose={() => {
            dispatch(setSelectedGlobalDatasetId(null));
          }}
        />
      ) : null}
      {renderDashboardEditPage()}
    </div>
  );
};
