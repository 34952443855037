import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { Dashboard } from 'actions/dashboardActions';
import { ReduxState } from 'reducers/rootReducer';
import {
  EXPLO_DASHBOARD_STORAGE_KEY,
  selectDashboardToPreviewStyleChanges,
} from 'reducers/styleConfigReducer';

import { Select } from './ds';

type Props = {
  dashboards: Dashboard[];
};

export const DashboardSelector: FC<Props> = ({ dashboards }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const selectedDashboardId = useSelector(
    (state: ReduxState) => state.styleConfig.selectedDashboardId,
  );

  const [hasParsedUrl, setHasParsedUrl] = useState(false);
  const [filteredDashboards, setFilteredDashboards] = useState<Dashboard[]>(dashboards);

  const dashboardIdInStorage = useLocalStorage(EXPLO_DASHBOARD_STORAGE_KEY, null)[0];

  useEffect(() => {
    if (hasParsedUrl) return;
    setHasParsedUrl(true);

    // Get dashboard id from url
    const queryParams = new URLSearchParams(location.search);
    const parsedCustomerId = parseInt(queryParams.get('dashboard') ?? '');

    let dashboardId: number | undefined;
    dashboardId = isNaN(parsedCustomerId) ? undefined : parsedCustomerId;

    // Get dashboard id from local storage
    if (dashboardId === undefined) {
      if (!dashboardIdInStorage) return;
      dashboardId = parseInt(dashboardIdInStorage);
    }

    // Check dashboard still exists
    const dashboard = dashboards.find((dashboard) => dashboard.id === dashboardId);
    if (dashboard) dispatch(selectDashboardToPreviewStyleChanges(dashboard.id));
  }, [dispatch, dashboards, location.search, hasParsedUrl, dashboardIdInStorage]);

  const dashboardsWithLowerCaseNames = useMemo(
    () =>
      dashboards.map((dashboard) => ({
        ...dashboard,
        nameLowerCase: dashboard.name.toLocaleLowerCase(),
      })),
    [dashboards],
  );

  const onFilter = useCallback(
    (searchString: string) => {
      const searchLower = searchString.toLocaleLowerCase();
      const filteredDashboards = dashboardsWithLowerCaseNames.filter((dashboard) =>
        dashboard.nameLowerCase.includes(searchLower),
      );
      setFilteredDashboards(filteredDashboards);
    },
    [dashboardsWithLowerCaseNames],
  );

  const optionsToDisplay = useMemo(
    () =>
      filteredDashboards.map((dashboard) => ({
        value: dashboard.id.toString(),
        label: dashboard.name,
      })),
    [filteredDashboards],
  );

  const selectedLabel = useMemo(() => {
    if (selectedDashboardId !== undefined) {
      return dashboards.find((d) => d.id === selectedDashboardId)?.name;
    }
    return undefined;
  }, [selectedDashboardId, dashboards]);

  return (
    <Select
      contentWidth="auto"
      disabled={!dashboards}
      filterProps={{
        placeholder: 'Filter for dashboard',
        onFilter,
        selectedLabel,
      }}
      onCancel={() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('dashboard');
        queryParams.delete('customer');
        history.replace({ search: queryParams.toString() });

        dispatch(selectDashboardToPreviewStyleChanges(null));
      }}
      onChange={(id) => {
        const dashboardId = parseInt(id);
        if (dashboardId === selectedDashboardId) return;
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('dashboard', dashboardId.toString());
        history.replace({ search: queryParams.toString() });

        dispatch(selectDashboardToPreviewStyleChanges(dashboardId));
      }}
      placeholder="Select dashboard"
      selectedValue={selectedDashboardId?.toString()}
      values={optionsToDisplay}
    />
  );
};
