import { Menu, MenuDivider, MenuItem, PopoverPosition } from '@blueprintjs/core';
import { IItemListRendererProps, IItemRendererProps, Select } from '@blueprintjs/select';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cx from 'classnames';
import { CSSProperties, useContext, useState } from 'react';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { withWidth } from 'components/HOCs/withWidth';
import { Button, Input, sprinkles } from 'components/ds';
import { EmbedFilterLabel } from 'components/embed';
import { SelectedDropdownInputItem } from 'constants/types';
import DropdownButton from 'explo-ds/buttons/dropdownButton';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { inputContainerBackgroundClass } from 'globalStyles/sharedStyles.css';
import { useMenuScroll } from 'hooks/useMenuScroll';
import InputLabel from 'shared/InputLabel';
import { uniqueId } from 'utils/standard';

import * as styles from './DropdownSelect.css';
import * as indexStyles from './index.css';

export type Props = {
  btnMinimal?: boolean;
  buttonStyle?: CSSProperties;
  className?: string;
  containerClassName?: string;
  createItemPlaceholderText?: string;
  createItemText?: string;
  dataTestid?: string;
  disabled?: boolean;
  fillWidth?: boolean;
  filterable?: boolean;
  filterPlaceholderText?: string;
  hideBtnCaret?: boolean;
  hideSelectedText?: boolean;
  ignoreCustomStyles?: boolean;
  label?: string;
  labelHelpText?: string;
  maxWidth?: number;
  minimal?: boolean;
  noSelectionText?: string | React.ReactNode;
  onCancelClick?: () => void;
  onChange: (item: SelectedDropdownInputItem) => void;
  onCreateItem?: (name: string) => void;
  options: SelectedDropdownInputItem[];
  selectedItem?: SelectedDropdownInputItem;
  showCancelBtn?: boolean;
  showIcon?: boolean;
  usePortal?: boolean;
  disableOnNoItems?: boolean;
  openElementToLeft?: boolean;
  isEmbed?: boolean;
};

const DropdownSelect = (props: Props) => {
  const [isCreatingItem, setIsCreatingItem] = useState<boolean>(false);
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const { handleMenuRef } = useMenuScroll();

  const simpleFilterPredicate = (query: string, item: SelectedDropdownInputItem) => {
    return item.name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const filterable = props.filterable === undefined ? true : props.filterable;

  const isDisabled = props.disabled || (props.disableOnNoItems && props.options.length === 0);

  const itemRenderer = (
    item: SelectedDropdownInputItem,
    { handleClick, query, index }: IItemRendererProps,
  ) => {
    let itemText;
    if (query.length > 0) {
      // replace each instance of the query with a bolded version (wrapped in <b />)
      // so that the item shows the query highlighted in it when searching
      const textPartsWithoutQuery = item.name.split(query);
      itemText = [];
      for (let i = 0; i < textPartsWithoutQuery.length - 1; i++) {
        itemText.push(textPartsWithoutQuery[i]);
        itemText.push(<b key={uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(textPartsWithoutQuery[textPartsWithoutQuery.length - 1]);
    } else if (item.isDivider) {
      return <div className={styles.itemDivider} key={`divider_${index}`}></div>;
    } else if (item.isTitle) {
      return (
        <MenuDivider
          className={sprinkles({ paddingLeft: 'sp0' })}
          key={`title-${item.name}`}
          title={item.name}
        />
      );
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        multiline
        active={item.id === props.selectedItem?.id}
        className={cx(styles.dropdownMenuItem, {
          [GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem]: !props.ignoreCustomStyles,
          [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem]:
            !props.ignoreCustomStyles,
        })}
        data-testid={props.dataTestid ? `${props.dataTestid}-item` : undefined}
        icon={props.showIcon && item.icon}
        key={uniqueId(`${item.id}_`)}
        labelElement={item.secondaryText}
        onClick={handleClick}
        text={itemText}
      />
    );
  };

  const itemListRenderer = ({
    filteredItems,
    renderItem,
    itemsParentRef,
  }: IItemListRendererProps<SelectedDropdownInputItem>) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled text="No results." />;
    } else if (filteredItems.length > 100 && filterable) {
      itemsList = filteredItems.slice(0, 100).map(renderItem);
      itemsList.push(<MenuItem disabled key="no_results" text="Filter for more results." />);
    } else {
      itemsList = filteredItems.map(renderItem);
    }

    return (
      <div>
        <Menu ulRef={itemsParentRef}>
          <div className={styles.menuList} ref={handleMenuRef}>
            {itemsList}
          </div>
        </Menu>
        {/* Currently only used in app */}
        {props.onCreateItem && (
          <div className={sprinkles({ backgroundColor: 'white', padding: 'sp1' })}>
            {isCreatingItem ? (
              <Input
                fillWidth
                showInputButton
                data-testid={props.dataTestid ? `${props.dataTestid}-input` : undefined}
                leftIcon="plus"
                onSubmit={(text) => {
                  props.onCreateItem?.(text);
                  setIsCreatingItem(false);
                }}
                placeholder={props.createItemPlaceholderText || 'Enter item name'}
              />
            ) : (
              <Button
                fillWidth
                data-testid={props.dataTestid ? `${props.dataTestid}-create` : undefined}
                icon="plus"
                onClick={() => setIsCreatingItem(true)}
                variant="tertiary">
                {props.createItemText || 'Create Item'}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  };

  const usePortal = !props.fillWidth || !!props.usePortal;
  // we don't have custom disabled states, so we need to override custom styles if
  // the component is disabled for now
  const useCustomStylesIfNotDisabled = !props.ignoreCustomStyles && !isDisabled;

  const sharedClassNames = cx({
    [GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownFilterInputBorder]:
      !props.ignoreCustomStyles,
    [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownFilterInputOutline]:
      !props.ignoreCustomStyles,
    [GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder]: !props.ignoreCustomStyles,
    [GLOBAL_STYLE_CLASSNAMES.text.body.input]: useCustomStylesIfNotDisabled,
    [GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.popoverCornerRadius]:
      !props.ignoreCustomStyles,
  });

  return (
    <div
      className={props.containerClassName}
      style={assignInlineVars({
        [styles.popoverMaxWidth]: props.maxWidth ? `${props.maxWidth}` : 'unset',
      })}>
      {props.isEmbed ? (
        <EmbedFilterLabel helpText={props.labelHelpText}>{props.label}</EmbedFilterLabel>
      ) : props.label ? (
        <InputLabel helpText={props.labelHelpText} text={props.label} />
      ) : null}
      <Select
        className={cx(
          sharedClassNames,
          {
            [styles.fullWidthRoot]: props.fillWidth,
          },
          props.className,
        )}
        disabled={isDisabled}
        filterable={filterable}
        inputProps={{ placeholder: props.filterPlaceholderText }}
        itemListRenderer={itemListRenderer}
        itemPredicate={simpleFilterPredicate}
        itemRenderer={itemRenderer}
        items={props.options}
        matchTargetWidth={!props.usePortal}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={props.onChange}
        popoverProps={{
          boundary: 'window',
          minimal: props.minimal,
          className: GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.popoverCornerRadius,
          popoverClassName: cx(sharedClassNames, styles.popoverContainer, indexStyles.popover, {
            [inputContainerBackgroundClass]: !props.ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: !props.ignoreCustomStyles,
          }),
          portalContainer: usePortal
            ? (document.getElementById(dashboardLayoutTagId) ?? document.body)
            : undefined,
          position: props.openElementToLeft
            ? PopoverPosition.BOTTOM_RIGHT
            : PopoverPosition.BOTTOM_LEFT,
          usePortal,
        }}>
        <DropdownButton
          className={cx({
            [sprinkles({ width: 'fill' })]: props.fillWidth,
            [styles.placeholderText]: !props.selectedItem,
            [GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius]:
              !props.ignoreCustomStyles,
          })}
          data-testid={props.dataTestid ? `${props.dataTestid}-open-dropdown` : undefined}
          disabled={isDisabled}
          hideText={props.hideSelectedText}
          icon={props.showIcon && props.selectedItem ? props.selectedItem.icon : undefined}
          ignoreCustomStyles={props.ignoreCustomStyles}
          minimal={props.btnMinimal}
          onCancelClick={props.onCancelClick}
          rightIcon={props.hideBtnCaret ? undefined : 'caret-down'}
          showCancelBtn={props.selectedItem ? props.showCancelBtn : false}
          style={props.buttonStyle}
          text={props.selectedItem ? props.selectedItem.name : props.noSelectionText}
        />
      </Select>
    </div>
  );
};

export default withWidth(DropdownSelect);
