import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';

import { sprinkles } from 'components/ds';
import { getSelectedDataPanelData } from 'reducers/selectors';
import { formatQuery } from 'utils/queryUtils';

import { QueryRuntime } from '../DashboardDebugger/QueryRuntime';

SyntaxHighlighter.registerLanguage('sql', sql);

export const QueryDebugger: FC = () => {
  const dataPanelData = useSelector(getSelectedDataPanelData);

  if (!dataPanelData) {
    return (
      <>
        <div className={sprinkles({ padding: 'sp1.5' })}>
          Select a data panel on the dashboard to use the debugger
        </div>
      </>
    );
  }

  const formattedQuery = formatQuery(dataPanelData.queryInformation?._query || '');

  return (
    <div className={sprinkles({ flexItems: 'column', height: 'fill' })}>
      <QueryRuntime queryInformation={dataPanelData.queryInformation} />
      <div
        className={sprinkles({ paddingX: 'sp1.5', flex: 1, whiteSpace: 'pre' })}
        style={{ fontFamily: 'Source Code Pro' }}>
        <SyntaxHighlighter showLineNumbers language="sql">
          {formattedQuery}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};
