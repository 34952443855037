import { createAction } from '@reduxjs/toolkit';

import { FilterOperationInstructions } from '@explo/data';

import { FetchDataPanelData, FetchDataPanelRowCountData } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { AdHocOperationInstructions, SortInfo_DEPRECATED } from 'types/dataPanelTemplate';
import { DataPanel, RequestDataset } from 'types/exploResource';

import { defineAPIPostAction } from './actionUtils';

export type FetchDataPanelBody = {
  id: string;
  config: DataPanel;
  variables: DashboardVariableMap;
  page_number?: number;
  sort_info?: SortInfo_DEPRECATED | SortInfo_DEPRECATED[];
  filter_info?: FilterOperationInstructions;
  query_limit?: number;
  timezone?: string;
  should_override_cache?: boolean;
  jobId: string;

  // embedded
  dataset_id?: string | number;
  version_number?: number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

export const {
  requestAction: fetchDataPanelRequest,
  successAction: fetchDataPanelSuccess,
  errorAction: fetchDataPanelError,
} = defineAPIPostAction<FetchDataPanelBody, FetchDataPanelData>(
  ACTION.FETCH_DATA_PANEL_TEMPLATE,
  'data_panel_templates',
  'get_data',
  'POST',
);
export type FetchSecondaryDataBody = {
  variables: DashboardVariableMap;
  config: DataPanel;
  id: string;
  is_box_plot: boolean; // Need it for when receiving data back
  is_secondary_data_query: true;
  timezone?: string;

  // embedded
  dataset_id?: string | number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

// Still need these actions for job queue/reducers
export const {
  requestAction: fetchSecondaryDataRequest,
  successAction: fetchSecondaryDataSuccess,
  errorAction: fetchSecondaryDataError,
} = defineAPIPostAction<FetchSecondaryDataBody, FetchDataPanelData>(
  ACTION.FETCH_SECONDARY_DATA,
  'data_panel_templates',
  'get_data',
  'POST',
);

export type FetchDataPanelRowCountBody = {
  id: string;
  config: DataPanel;
  filter_info?: FilterOperationInstructions;
  variables: DashboardVariableMap;
  timezone?: string;

  // embedded
  dataset_id?: string | number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

export const {
  requestAction: fetchDataPanelRowCountRequest,
  successAction: fetchDataPanelRowCountSuccess,
  errorAction: fetchDataPanelRowCountError,
} = defineAPIPostAction<FetchDataPanelRowCountBody, FetchDataPanelRowCountData>(
  ACTION.FETCH_DATA_PANEL_ROW_COUNT,
  'data_panel_templates',
  'get_row_count',
  'POST',
);

export const updateUnderlyingDataDataPanel = createAction<
  { dataPanel: DataPanel; adHocInstructions: AdHocOperationInstructions } | undefined
>(ACTION.UPDATE_DRILLDOWN_DATA_PANEL);
