import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';

import { BaseCol } from '@explo/data';

import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { Icon, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';
import { EmbedButton } from 'components/embed';
import { Props as EmbedButtonProps } from 'components/embed/EmbedButton';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { DataPanelData } from 'pages/ReportBuilder/ReportView/DataPanel/constants';

import * as styles from './DataPanelListItem.css';
import { LightTooltip } from './LightTooltip';

type Props = {
  id: string;
  section: string;
  children?: ReactNode;
  columnConfigs?: ReportBuilderColConfigs;
  column: BaseCol;
  name: string;
  description?: string;
  icon?: IconName;
  actionButton?: EmbedButtonProps;
  disabled?: boolean;
};

export const DataPanelListItem: FC<Props> = ({
  id,
  section,
  name,
  column,
  description,
  icon,
  actionButton,
  disabled,
  children,
}) => {
  const dataPanelData: DataPanelData = { id, section, column };
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: `${section}-${id}`,
    data: dataPanelData,
    disabled,
  });
  const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition };

  return (
    <div
      className={cx(styles.item, disabled ? styles.itemDisabled : styles.itemGrab)}
      ref={setNodeRef}
      style={style}>
      <div {...attributes} {...listeners} className={styles.dragArea}>
        <Icon
          className={sprinkles({
            color: 'contentTertiary',
            cursor: 'grab',
          })}
          name="vertical-grip"
          size="md"
        />
        <LightTooltip
          className={sprinkles({ zIndex: 2 })}
          delayDuration={DEFAULT_DELAY}
          side="left"
          text={
            <span className={styles.tooltip}>
              <EmbedText body="b2" className={styles.tooltipTitle}>
                {name}
              </EmbedText>
              {description ? (
                <EmbedText body="b2" className={sprinkles({ margin: 'sp0' })}>
                  {description}
                </EmbedText>
              ) : null}
            </span>
          }>
          <div className={styles.textContainer}>
            {icon ? (
              <Icon className={sprinkles({ color: 'contentTertiary' })} name={icon} size="md" />
            ) : null}
            <EmbedText body="b1" className={styles.columnName} color="contentPrimary">
              {name}
            </EmbedText>
            {description ? (
              <EmbedText body="b2" className={styles.columnDescription} color="contentTertiary">
                {description}
              </EmbedText>
            ) : null}
          </div>
        </LightTooltip>
      </div>
      {children}
      {actionButton ? <EmbedButton {...actionButton} /> : null}
    </div>
  );
};
