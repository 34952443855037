import cx from 'classnames';
import { forwardRef, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import * as styles from 'components/EditableText.css';
import { Icon, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { Props as TooltipProps } from 'components/ds/Tooltip';
import { useDoubleClick } from 'utils/hookUtils';

interface Props {
  // Initial value
  value: string;

  // Input or empty value placeholder
  placeholder: string;

  // Editing is triggered when the user double clicks. If they single click, this is called instead
  onSingleClick?: () => void;

  // Called when the user is done editing (blurs or presses enter)
  onRename?: (name: string) => void;

  // Disable the single and double click functionality of the component
  disabled?: boolean;

  // Disable rename
  disableRename?: boolean;

  // Manually control the renaming state from outside the component
  isRenaming?: boolean;

  // Use this to manually update the renaming state if using isRenaming
  onRenamingChange?: (isRenaming: boolean) => void;

  // Optional extra content that will be hidden when the input is active. Clicking won't trigger editing
  children?: ReactNode;

  // Optional tooltip that appears when the text is hovered
  tooltipProps?: TooltipProps;

  // Will be rendered after the value string inside the tooltip trigger
  triggerChildren?: ReactNode;

  className?: string;

  // Rendered to the left of the text
  icon?: IconName;
}

/**
 * Allows the user to edit text inline. It will automatically resize to the width of the text
 * When using inside a flexbox, you may need to set min-width: 0 on all parent flex items
 */
export const EditableText = forwardRef<HTMLDivElement, Props>(function EditableText(
  {
    className,
    value,
    placeholder,
    onSingleClick,
    children,
    onRename,
    isRenaming: isRenamingManual,
    onRenamingChange,
    disabled,
    disableRename,
    tooltipProps,
    triggerChildren,
    icon,
    ...props
  },
  ref,
) {
  const [isRenaming, setIsRenaming] = useState(!!isRenamingManual);
  const [newValue, setNewValue] = useState(value);
  const [inputWidth, setInputWidth] = useState(0);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    if (isRenamingManual != null) setIsRenaming(isRenamingManual);
  }, [isRenamingManual]);

  const handleRename = useCallback(() => {
    setIsRenaming(true);
    onRenamingChange?.(true);
  }, [onRenamingChange]);

  const { triggerClick } = useDoubleClick({
    onSingleClick: () => onSingleClick?.(),
    onDoubleClick: () => !disableRename && handleRename(),
  });

  const handleBlur = useCallback(() => {
    setIsRenaming(false);
    onRenamingChange?.(false);

    const trimmed = newValue.trim();
    if (!trimmed) return;
    onRename?.(trimmed);
  }, [newValue, onRename, onRenamingChange]);

  const handleEscape = useCallback(() => {
    setIsRenaming(false);
    onRenamingChange?.(false);
    setNewValue(value);
  }, [value, onRenamingChange]);

  useEffect(() => {
    const width = childrenRef.current?.offsetWidth;

    // Auto-resize the input to the width of the text
    // Math.ceil to remove flickering when the width is a decimal
    // Subtract 1 because if the text is 12.6px, offsetWidth will be 13px
    if (width) setInputWidth(Math.ceil(width) - 1);
  }, [newValue, value, isRenaming]);

  const renderTrigger = () => (
    // triggerContainer provides a larger clickable area than just the text
    <div className={styles.triggerContainer} onClick={() => !disabled && triggerClick()}>
      {icon ? <Icon name={icon} /> : null}
      <span className={styles.trigger}>
        {/* React doesn't render trailing spaces so replace with Unicode spaces, which won't be removed */}
        {/* This ensures the inputWidth is correctly sized to the text */}
        {newValue.replace(/\s/g, '\u00a0') || placeholder}
        {triggerChildren}
      </span>
    </div>
  );

  return (
    <div {...props} className={cx(styles.editableText, className)} ref={ref}>
      {isRenaming && (
        <input
          autoFocus
          className={styles.input}
          onBlur={handleBlur}
          onChange={(e) => setNewValue(e.currentTarget.value)}
          onKeyUp={(e) =>
            e.key === 'Enter' ? handleBlur() : e.key === 'Escape' ? handleEscape() : null
          }
          placeholder={placeholder}
          style={{ width: inputWidth }}
          value={newValue}
        />
      )}
      <div
        className={cx(styles.childrenContainer, {
          [styles.childrenHidden]: isRenaming,
          [styles.hoverBorder]: !disabled,
        })}
        ref={childrenRef}>
        {tooltipProps ? <Tooltip {...tooltipProps}>{renderTrigger()}</Tooltip> : renderTrigger()}
        {children}
      </div>
    </div>
  );
});
