import { FC, useMemo, useCallback, MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DatasetSchema,
  ColumnConfigs,
  NUMBER_TYPES,
  ChartAggregation,
  AGGREGATIONS_TYPES,
} from '@explo/data';

import {
  TotalAccumulatorKey,
  TotalAccumulator,
  CustomerReportView,
} from 'actions/customerReportActions';
import { Icon, sprinkles, Spinner } from 'components/ds';
import { renderCell } from 'components/ds/DataGrid/columnUtils';
import { getFlexAlignments } from 'components/ds/DataGrid/utils';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  setColumnTotalAgg,
  getTotalData,
  getTotalDataLoading,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

interface Props {
  columnName: string;
  groupColumn?: boolean;
  pivotColumnPath?: string[];
  portalRef: MutableRefObject<HTMLElement | null>;
  totalAccumulator: TotalAccumulator;
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
  view: CustomerReportView;
}

export const ReportChartTotal: FC<Props> = ({
  pivotColumnPath,
  groupColumn,
  columnName,
  portalRef,
  totalAccumulator,
  schema,
  columnConfigs,
  view,
}) => {
  const dispatch = useDispatch();

  const pivotPath: string = pivotColumnPath ? pivotColumnPath.join('') : columnName;
  const aggId = view.totals?.[pivotPath];
  const { aggData, isLoading } = useSelector((state: ReportBuilderReduxState) => {
    return {
      isLoading: getTotalDataLoading(state.reportEditing, view.id),
      aggData:
        groupColumn || pivotColumnPath || !aggId
          ? undefined
          : getTotalData(state.reportEditing, columnName, aggId, view.id),
    };
  });

  const value = useMemo(() => {
    if (!aggId) return undefined;

    // Pivot column
    if (groupColumn) return totalAccumulator.pivotColumn?.[aggId];

    // Aggregated column in a pivot table
    if (pivotColumnPath) return totalAccumulator.columns[pivotPath]?.[aggId];

    // Not a pivot table
    return aggData ?? totalAccumulator.columns[columnName]?.[aggId];
  }, [
    aggId,
    columnName,
    groupColumn,
    aggData,
    pivotColumnPath,
    pivotPath,
    totalAccumulator.columns,
    totalAccumulator.pivotColumn,
  ]);

  const handleClick = useCallback(
    (value) => dispatch(setColumnTotalAgg({ path: pivotPath, agg: value as TotalAccumulatorKey })),
    [dispatch, pivotPath],
  );

  const datasetColumn = useMemo(
    () => schema.find((col) => col.name === columnName),
    [schema, columnName],
  );

  // Number aggregation options are disabled for pivot tables because they are inaccurately calculated on the frontend
  const menuOptions =
    datasetColumn && NUMBER_TYPES.has(datasetColumn.type)
      ? totalAccumulator.pivotColumn
        ? PIVOT_NUMBER_AGGREGATION_OPTIONS
        : NUMBER_AGGREGATION_OPTIONS
      : AGGREGATION_OPTIONS;

  const isCount = aggId === ChartAggregation.COUNT || aggId === ChartAggregation.COUNT_DISTINCT;
  const config = useMemo(() => columnConfigs?.[columnName] || {}, [columnConfigs, columnName]);
  const renderedValue = useMemo(
    () =>
      (datasetColumn && config && !isCount
        ? renderCell({ config, columnInfo: datasetColumn, value })
        : value) ?? '-',
    [config, datasetColumn, isCount, value],
  );

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        gap: 'sp1',
        justifyContent: datasetColumn
          ? getFlexAlignments(config?.displayFormatting, datasetColumn.type)
          : undefined,
        width: 'fill',
      })}>
      <EmbeddedDropdownMenu
        menuOptions={menuOptions}
        onClick={handleClick}
        portalRef={portalRef} // Portal is needed because otherwise it renders inside the data grid and causes weird scrolling issues
      >
        <div className={containerStyle}>
          <EmbedText
            className={sprinkles({ truncateText: 'ellipsis' })}
            color="contentTertiary"
            heading="subtitle">
            {(aggId && AGGREGATIONS_TYPES[aggId]?.name) || 'None'}
          </EmbedText>
          <Icon name="caret-down" size="sm" />
        </div>
      </EmbeddedDropdownMenu>
      {!aggId ? null : isLoading ? (
        <Spinner size="sm" />
      ) : (
        <b className={sprinkles({ truncateText: 'ellipsis' })}>{renderedValue}</b>
      )}
    </div>
  );
};

const aggListToOptions = (aggList: { id: ChartAggregation; name: string }[]) =>
  aggList.reduce(
    (acc, agg) => {
      acc.push({ value: agg.id, name: agg.name });
      return acc;
    },
    [{ value: '', name: 'None' }] as { value: string; name: string }[],
  );

const AGGREGATION_LIST = [AGGREGATIONS_TYPES.COUNT, AGGREGATIONS_TYPES.COUNT_DISTINCT];

// Pivots support a subset of number aggs because it computes values in the frontend, which may not be accurate
const PIVOT_NUMBER_AGGREGATION_LIST = [...AGGREGATION_LIST, AGGREGATIONS_TYPES.SUM];

const NUMBER_AGGREGATION_LIST = [
  ...AGGREGATION_LIST,
  AGGREGATIONS_TYPES.SUM,
  AGGREGATIONS_TYPES.AVG,
  AGGREGATIONS_TYPES.MIN,
  AGGREGATIONS_TYPES.MAX,
];

const AGGREGATION_OPTIONS = aggListToOptions(AGGREGATION_LIST);

const NUMBER_AGGREGATION_OPTIONS = aggListToOptions(NUMBER_AGGREGATION_LIST);

const PIVOT_NUMBER_AGGREGATION_OPTIONS = aggListToOptions(PIVOT_NUMBER_AGGREGATION_LIST);

const containerStyle = sprinkles({
  flexItems: 'alignCenterBetween',
  cursor: 'pointer',
  gap: 'sp.5',
  color: { default: 'contentTertiary', hover: 'contentPrimary' },
  truncateText: 'ellipsis',
});
