import produce, { Draft } from 'immer';

import { SortOrder } from '@explo/data';

import { isNumber } from 'utils/standard';

/**
 * Just like Lodash's sortBy, but handles different types of values
 * TODO: Use this everywhere we sort our customers' DB values
 *
 * @param values - array of values to sort
 * @param iterateeFn - function to get the value to sort by
 * @param sortOrder
 */
export const sortByValues = <T>(
  values: T[],
  iterateeFn: (value: Draft<T>) => unknown,
  sortOrder: SortOrder = SortOrder.ASC,
): T[] =>
  produce(values, (draft) =>
    draft.sort((a, b) => {
      const aValue = iterateeFn(a);
      const bValue = iterateeFn(b);

      if (aValue == null && bValue == null) return 0;
      else if (aValue == null) return 1;
      else if (bValue == null) return -1;

      const isAsc = sortOrder === SortOrder.ASC;
      if (isNumber(aValue) && isNumber(bValue)) return isAsc ? aValue - bValue : bValue - aValue;

      const aString = String(aValue);
      const bString = String(bValue);
      return isAsc ? aString.localeCompare(bString) : bString.localeCompare(aString);
    }),
  );
