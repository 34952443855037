import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';

import { Icon, IconName } from '../Icon';
import { Props as TooltipProps, Tooltip } from '../Tooltip';
import { sprinkles } from '../sprinkles.css';

import { MenuItemProps } from './MenuItem';
import * as styles from './index.css';
import cx from 'classnames';

type MenuActionItemProps = MenuItemProps & {
  textClassName?: string;
  /** Left icon */
  iconName?: IconName;
  /** Optional test ID for selecting component in tests */
  'data-testid'?: string;
  /** Tooltip on menu item */
  tooltipProps?: TooltipProps;
};

export const MenuActionItem = forwardRef<HTMLDivElement, MenuActionItemProps>(
  function MenuActionItem(
    {
      textClassName,
      isDestructive,
      disabled,
      iconName,
      'data-testid': testId,
      onSelect,
      text,
      tooltipProps,
    },
    ref,
  ) {
    const item = (
      <DropdownMenu.Item
        className={isDestructive ? styles.destructiveMenuItem : styles.menuItem}
        data-testid={testId}
        disabled={disabled}
        onSelect={onSelect}
        ref={ref}>
        <div className={styles.menuItemLabelIconStyles}>
          {iconName ? (
            <Icon
              className={sprinkles({ color: isDestructive ? 'errorBold' : 'contentSecondary' })}
              name={iconName}
            />
          ) : null}
          <div
            className={cx(
              textClassName,
              sprinkles({ color: isDestructive ? 'errorBold' : 'contentPrimary' }),
            )}>
            {text}
          </div>
        </div>
      </DropdownMenu.Item>
    );

    if (tooltipProps?.text) {
      return (
        <Tooltip {...tooltipProps} text={tooltipProps.text}>
          {item}
        </Tooltip>
      );
    }

    return item;
  },
);
