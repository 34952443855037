import { ViewState } from 'react-map-gl';

import { ColorFormat } from 'constants/types';

type MapFormat = { viewState?: ViewState; style?: string };

export type ChoroplethMapFormat = {
  boundaryFormat?: BoundaryFormat;
  boundaryType?: BoundaryType;
  colorSteps?: number;
  tooltipFormat?: MapTooltipFormat;
  minColor?: string;
  maxColor?: string;

  customBoundaryFormat?: string;
  customBoundarySourceLayer?: string;
  customBoundaryTileset?: string;
} & MapFormat;

export type LocationMarkerMapFormat = {
  markerColor?: string;
  markerSize?: number;
  markerOutlineColor?: string;
  markerOutlineWidth?: number;
  tooltipFormat?: Record<string, MapTooltipFormat>;
  hideTooltipHeader?: boolean;
} & MapFormat;

export type DensityMapFormat = {
  intensity?: number;
  opacity?: number;
  colorFormat?: ColorFormat;
  radius?: number;
} & MapFormat;

export enum MapStyles {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  SATELLITE = 'SATELLITE',
  STREETS = 'STREETS',
  OUTDOORS = 'OUTDOORS',
}

export enum BoundaryFormat {
  COUNTRY_NAME = 'country name',
  COUNTRY_CODE_3 = 'country code iso3',
  COUNTRY_CODE_2 = 'country code iso2',
  STATE_NAME = 'state name',
  STATE_CODE = 'state code',
  ZIP_CODE = 'zip code',
  CUSTOM = 'custom',
}

export enum BoundaryType {
  COUNTRY = 'country',
  STATE = 'state',
  ZIP = 'zip',
  CUSTOM = 'custom',
}

export type MapTooltipFormat = {
  unit?: string;
  numberFormat?: { id: string };
  multiplyFactor?: number;
  decimalPlaces?: number;
  pctDecimalPlaces?: number;
  timeFormat?: Record<'id', string>;
  timeCustomerFormat?: string;
  customDurationFormat?: string;
  significantDigits?: number;
};
