import {
  Branch,
  ComputedView,
  Folder,
  Parameter,
  PropertySchema,
  Resource,
} from '@explo-tech/fido-api';
import { COMPUTED_VIEW_TYPE, FOLDER_TYPE } from './constants';
import { getItemNameFromPath } from './dataLibraryUtil';

/**
 * @param path The full path to the view. NOTE: this should include the view's name as well for
 * optimal searching (it will in the real API)
 * @returns The constructed computed view
 */
export const createComputedView = (
  id: string,
  versionId: string,
  name: string,
  path: string,
  description: string | null,
  query: string,
  namespaceId: string | null,
  columnDefinitions?: PropertySchema[],
  parameters?: Parameter[],
): ComputedView => {
  return createComputedViewWithUndefinedIds(
    id,
    versionId,
    name,
    path,
    description,
    query,
    namespaceId,
    columnDefinitions,
    parameters,
  );
};

export const createComputedViewWithUndefinedIds = (
  id: string | undefined,
  versionId: string | undefined,
  name: string,
  path: string,
  description: string | null,
  query: string,
  namespaceId: string | null,
  columnDefinitions?: PropertySchema[],
  parameters?: Parameter[],
): ComputedView => {
  return {
    id,
    versionId,
    name,
    path,
    description,
    columnDefinitions: columnDefinitions ?? [],
    query,
    namespaceId,
    cacheable: false,
    cacheEvictionPolicy: null,
    '@type': COMPUTED_VIEW_TYPE,
    parameters: parameters ?? [],
  };
};

export const createFolder = (
  id: string,
  versionId: string,
  path: string,
  children: (ComputedView | Folder | Resource)[],
): Folder => {
  return createFolderWithUndefinedIds(id, versionId, path, children);
};

export const createFolderWithUndefinedIds = (
  id: string | undefined,
  versionId: string | undefined,
  path: string,
  children: (ComputedView | Folder | Resource)[],
): Folder => {
  return {
    '@type': FOLDER_TYPE,
    id,
    versionId,
    name: getItemNameFromPath(path),
    description: null,
    path,
    children,
  };
};

export const createBranch = (id: string, name: string, headCommitId: string): Branch => {
  return {
    id,
    name,
    headId: headCommitId,
  };
};
