import { PivotTableV2DataInstructions } from '@explo/data';

import { V2GeneralInstructions } from 'constants/types';

export type V2PivotTableInstructions = PivotTableV2DataInstructions & {
  generalFormat?: V2GeneralInstructions;
  summaryColumnName?: string;
  // Will wrap row header and values header, but not column header text
  wrapHeaderText?: boolean;
};

export const EmptyV2PivotInstructions: V2PivotTableInstructions = {
  rowGroupBys: [],
  colGroupBys: [],
  aggregations: [],
  columnConfigs: {},
};
