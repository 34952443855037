import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createErrorAction, createRequestAction, createSuccessAction } from 'actions/actionUtils';
import { CustomerReport, CustomerReportConfig } from 'actions/customerReportActions';
import { ExportSpreadsheetType, ExportType } from 'actions/exportActions';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { createJob } from 'components/JobQueue/createJob';
import {
  Jobs,
  ReportBuilderJobError,
  SendDraftExportJobRequest,
  SendDraftExportJobSuccess,
  SendTestCustomerReportEmailCadenceByParamsJob,
  SendTestCustomerReportEmailCadenceJob,
  SendTestExportJobRequest,
} from 'components/JobQueue/types';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import {
  FIDO_SUPPORTED_EXPORT_FORMATS,
  downloadReportBuilderComputationSpreadsheet,
} from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import { isSuccess } from 'remotedata';
import {
  EmailCadence,
  ExportBody,
  ExportCustomerReportBody,
  ExportViewOverrides,
} from 'reportBuilderContent/exportTypes';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportType, SelectedReportType } from 'reportBuilderContent/reducers/types';
import { sendReportBuilderAnalyticsEvent } from 'reportBuilderContent/thunks/analyticsThunks';
import { getReportBuilderTimezone } from 'utils/customerReportUtils';
import { shouldUseFidoForRequest } from 'utils/fido/fidoRequestUtils';
import { get, isEqual } from 'utils/standard';

import {
  sendReportBuilderTestDraftExportThunk,
  sendReportBuilderTestExportThunk,
} from 'reducers/thunks/roverThunks/roverThunks';
import { enqueueReportBuilderJobsThunk } from './jobThunks';
import { mapCustomerReportRelativeFilters } from './utils';

export const exportReport = createAsyncThunk<
  Record<string, Jobs>,
  { selectedReport: SelectedReportType; reportName: string; exportType: ExportType },
  { state: ReportBuilderReduxState }
>(
  ACTION.EXPORT_CUSTOMER_REPORT,
  async ({ selectedReport, reportName, exportType }, { dispatch, getState }) => {
    const { embeddedReportBuilder, reportEditing, fido } = getState();
    const { requestInfo, reportBuilderVersion, variables, team } = embeddedReportBuilder;
    const { currentConfig, currentView } = reportEditing;

    const dataset =
      reportBuilderVersion && currentConfig?.dataInfo
        ? reportBuilderVersion.config.datasets[currentConfig.dataInfo.datasetId]
        : undefined;
    if (!dataset) throw new Error('Export missing dataset');

    dispatch(
      sendReportBuilderAnalyticsEvent(selectedReport, ReportedAnalyticActionTypes.REPORT_EXPORTED),
    );
    const timezone = getReportBuilderTimezone(embeddedReportBuilder);

    if (
      shouldUseFidoForRequest(
        {
          type: 'embedded',
          useFido: team?.feature_flags.use_fido,
          parentSchemaDataSourceMapping: {},
        },
        fido,
        dataset,
        true,
      ) &&
      FIDO_SUPPORTED_EXPORT_FORMATS.has(exportType.toUpperCase())
    ) {
      const view = currentConfig?.views?.find((v) => v.id === currentView);
      if (!view) throw new Error('Export report missing view');

      return dispatch(
        downloadReportBuilderComputationSpreadsheet({
          computationBody: {
            aggs: view.aggregations || [],
            columns: view.columnOrder,
            filters: mapCustomerReportRelativeFilters(view.filters, timezone),
            sort: view.sort || [],
            group_bys: view.groupBys,
            col_group_bys: view.columnGroupBys,
            hidden_columns: view.hiddenColumns,
            column_configs: dataset.columnConfigs,
          },
          dataset,
          fileFormat: exportType as ExportSpreadsheetType,
          fileName: reportName,
          emails: null,
        }),
      );
    }

    let reportIdParam = null;
    if (selectedReport?.type === ReportType.CUSTOMER_REPORT) {
      reportIdParam = {
        customer_report_id: selectedReport.id,
        environment_tag_id: requestInfo.envTagId,
      };
    } else if (selectedReport?.type === ReportType.BUILT_IN) {
      reportIdParam = { built_in_id: selectedReport.id, environment_tag_id: requestInfo.envTagId };
    }
    if (!reportIdParam) throw new Error('Missing report');

    const exportView = currentConfig?.views?.find((v) => v.id === currentView);
    if (!exportView) throw new Error('Export report missing view');

    const reportId = String(selectedReport.id);
    const jobArgs: ExportCustomerReportBody = {
      ...reportIdParam,
      embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
      file_name: reportName,
      folder_name: reportId,
      export_type: exportType,
      variables,
      view_override: {
        ...exportView,
        filters: mapCustomerReportRelativeFilters(exportView.filters, timezone),
      },
      timezone,
    } as const;
    const jobs = createJob({
      job_type: ACTION.EXPORT_CUSTOMER_REPORT,
      job_args: jobArgs,
    });
    dispatch(enqueueReportBuilderJobsThunk(jobs));

    return Promise.resolve({});
  },
);

export const sendTestDraftExportThunk =
  ({ email }: { email: ExportBody }): Thunk =>
  (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing } = getState();
    const { customerToken, embedJwt: jwt, envTagId } = embeddedReportBuilder.requestInfo;
    const { currentConfig, selectedReport } = reportEditing;
    const { reports } = embeddedReportBuilder;
    const builtIns = embeddedReportBuilder.reportBuilderVersion?.config.builtInReports;
    const viewOverrides = getViewDiffs(currentConfig, selectedReport, builtIns, reports);

    const reportBuilderId =
      isSuccess(embeddedReportBuilder.reportBuilder) &&
      embeddedReportBuilder.reportBuilder.data?.id;
    let reportIdParam = null;
    if (selectedReport?.type === ReportType.CUSTOMER_REPORT) {
      reportIdParam = { customer_report_id: selectedReport.id, environment_tag_id: envTagId };
    } else if (selectedReport?.type === ReportType.BUILT_IN) {
      reportIdParam = { built_in_id: selectedReport.id, environment_tag_id: envTagId };
    }

    if (!reportIdParam || !reportBuilderId) return;

    if (
      embeddedReportBuilder.team?.feature_flags.use_fido &&
      embeddedReportBuilder.team?.feature_flags.use_rover &&
      (email.export_type === ExportType.CSV || email.export_type === ExportType.XLSX)
    ) {
      dispatch(sendReportBuilderTestDraftExportThunk({ exportBody: email }));
      return;
    }

    const jobs = createJob({
      job_type: ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS,
      job_args: {
        ...email,
        jwt,
        customer_token: customerToken,
        report_builder_id: reportBuilderId,
        dataset_id: currentConfig?.dataInfo?.datasetId,
        view_overrides: viewOverrides,
        ...reportIdParam,
      },
    });

    dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

export const sendTestExportThunk =
  ({ email }: { email: EmailCadence }): Thunk =>
  (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing } = getState();
    const { currentConfig, selectedReport } = reportEditing;
    const { reports } = embeddedReportBuilder;
    const builtIns = embeddedReportBuilder.reportBuilderVersion?.config.builtInReports;
    const viewOverrides = getViewDiffs(currentConfig, selectedReport, builtIns, reports);

    if (
      embeddedReportBuilder.team?.feature_flags.use_fido &&
      embeddedReportBuilder.team?.feature_flags.use_rover &&
      (email.export_type === ExportType.CSV || email.export_type === ExportType.Image)
    ) {
      dispatch(sendReportBuilderTestExportThunk({ exportBody: email }));
      return;
    }

    const jobs = createJob({
      job_type: ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL,
      job_args: {
        report_builder_email_cadence_id: email.id,
        is_test_email: true,
        dataset_id: currentConfig?.dataInfo?.datasetId,
        view_overrides: viewOverrides,
      },
    });
    return dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

export const getViewDiffs = (
  currentConfig: CustomerReportConfig | null,
  selectedReport: SelectedReportType | null,
  builtIns: Record<string, BuiltInReportConfig> | undefined,
  reports: CustomerReport[] | undefined,
) => {
  let savedReport: BuiltInReportConfig | CustomerReport | undefined = undefined;
  if (
    selectedReport?.type === ReportType.BUILT_IN ||
    selectedReport?.type === ReportType.EDIT_BUILT_IN
  ) {
    savedReport = builtIns?.[selectedReport.id];
  } else {
    savedReport = reports?.find((report) => report.id === selectedReport?.id);
  }

  return (
    currentConfig?.views?.map((view) => {
      const savedView = savedReport?.config?.views?.find((v) => v.id === view.id);
      const diff: ExportViewOverrides = { id: view.id };
      for (const [key, value1] of Object.entries(view)) {
        const value2 = get(savedView, key);
        if (!isEqual(value1, value2)) {
          diff[key as keyof ExportViewOverrides] = value1;
        }
      }
      return diff;
    }) || []
  );
};

export const exportCustomerReportSuccess = createSuccessAction<{ export_url: string }>(
  ACTION.EXPORT_CUSTOMER_REPORT,
);
export const exportCustomerReportError = createErrorAction(ACTION.EXPORT_CUSTOMER_REPORT);

export const sendDraftExportRequest = createRequestAction<SendDraftExportJobRequest>(
  ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS,
);

export const sendDraftExportSuccess = createSuccessAction<SendDraftExportJobSuccess>(
  ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS,
);

export const sendDraftExportError = createErrorAction<
  ReportBuilderJobError<SendTestCustomerReportEmailCadenceByParamsJob>
>(ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS);

export const sendTestExportRequest = createRequestAction<SendTestExportJobRequest>(
  ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL,
);

export const sendTestExportSuccess = createSuccessAction<SendDraftExportJobSuccess>(
  ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL,
);

export const sendTestExportError = createErrorAction<
  ReportBuilderJobError<SendTestCustomerReportEmailCadenceJob>
>(ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL);

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;
