import {
  AggregateProperty,
  AggregationOption,
  And,
  BooleanPropertyValue,
  CalendarInterval,
  CalendarIntervalGrouping,
  ColumnFormat,
  Computation,
  DatePart,
  DatePartGrouping,
  DatePropertyValue,
  DateTimeColumnFormat,
  DateTimePropertyValue,
  DecimalIntervalGrouping,
  DecimalPropertyValue,
  DoublePropertyValue,
  Equal,
  ExportColumnOptions,
  ExportFormat,
  Aggregation as FidoAggregation,
  Filter,
  FormulaProperty,
  GreaterThan,
  GreaterThanOrEqual,
  Grouping,
  In,
  IntegerIntervalGrouping,
  IntegerPropertyValue,
  LessThan,
  LessThanOrEqual,
  Not,
  Null,
  Or,
  Property,
  PropertyType,
  PropertyValue,
  SortDirection,
  SourceProperty,
  StringContains,
  StringPropertyValue,
  UnitOfMeasurement,
  ValueGrouping,
} from '@explo-tech/fido-api';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

import { makeAbsentOrNotBlank } from './fidoUtils';

import {
  AggColInfoOptional,
  BaseCol,
  ColumnConfigs,
  ColumnConfigWithName,
  DatasetSchema,
  AGGREGATIONS_TYPES,
  DATE,
  DATETIME,
  DECIMAL_TYPES,
  DEFAULT_CUSTOM_FORMULA,
  DOUBLE,
  FLOAT,
  INTEGER_DATA_TYPE,
  NUMBER_TYPES,
  OPERATION_TYPES,
  SchemaDataType,
  TIME_COLUMN_TYPES,
  TIME_DIFF_FORMATS,
  UNSIGNED_INTEGER,
  DATE_PART_INPUT_AGG,
  PIVOT_AGG_TYPES,
  TREND_GROUP_OPTION_TO_PIVOT_AGG,
  TrendGroupingOptions,
  DateDisplayFormat,
  DateDisplayOptions,
  NumberDisplayFormat,
  NumberDisplayOptions,
  SchemaDisplayOption,
  StringDisplayFormat,
  ChartAggregation,
  PivotAgg,
  VisualizeOperationDataInstructions,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_EMPTY,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NEGATED,
  FILTER_OPS_NUMBER,
  FILTER_OPS_NUMBER_RANGE,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperationInstructions,
  FilterOperator,
  FilterValueDateType,
  FilterValueNumberRangeType,
  FilterValueRelativeDateType,
  FilterValueType,
  GroupByBucket,
  SortInfo,
  SortOrder,
  CURRENCY_MAPPING,
  getDatesFromDateRelativeOption,
  getCurrentDateFormat,
  getFormatForFidoExports,
  getAggColNameBase,
  stringReplaceAll,
  titleCase,
} from '@explo/data';

const durationSubstistutionRegex = /\b(?!ss\b|mm\b|HH\b|d\b|dd\b)(\w+)\b/g;

export const getEmptyComputation = (): Computation => ({
  properties: [],
  filter: null,
  having: null,
  sorts: [],
  groupings: [],
});

export const processSort = (sortInfo: SortInfo[] | undefined, computation: Computation) => {
  (sortInfo ?? []).forEach((colInfo) => {
    computation.sorts.push({
      propertyId: colInfo.column.name,
      sortDirection: colInfo.order === SortOrder.ASC ? SortDirection.ASC : SortDirection.DESC,
    });
  });
};

export const getFilterValue = (
  value: FilterValueType,
  operator: FilterOperator,
  coerceTo: SchemaDataType,
  timezone: string,
): { value?: PropertyValue; values?: PropertyValue[] } | null => {
  // this block checks the no value filters
  if (operator === FilterOperator.BOOLEAN_IS_FALSE) {
    const propertyValue: BooleanPropertyValue = { value: false, '@type': 'boolean' };
    return { value: propertyValue };
  } else if (operator === FilterOperator.BOOLEAN_IS_TRUE) {
    const propertyValue: BooleanPropertyValue = { value: true, '@type': 'boolean' };
    return { value: propertyValue };
  } else if (operator === FilterOperator.DATE_TODAY) {
    const now = DateTime.local().setZone(timezone);

    return {
      values: [
        getTemporalFilterValue(now.startOf('day'), timezone, coerceTo),
        getTemporalFilterValue(now.endOf('day'), timezone, coerceTo),
      ],
    };
  } else if (FILTER_OPS_EMPTY.has(operator)) {
    return {};
  }

  if (FILTER_OPS_NUMBER.has(operator)) {
    if (value == null) return null;

    return { value: getNumericFilterValue(value as number, coerceTo) };
  } else if (FILTER_OPS_STRING.has(operator)) {
    if (value == null) return null;

    const propertyValue: StringPropertyValue = { value: value as string, '@type': 'string' };
    return { value: propertyValue };
  } else if (FILTER_OPS_MULTISELECT.has(operator)) {
    if (value == null) return null;

    // if this filter is configured at the chart level, its coming in as a string representation of a list
    // and we need to convert it to a list here
    if (typeof value === 'string') value = JSON.parse(value);

    const propertyValues: PropertyValue[] = [...(value as string[] | number[])].map((v) => {
      if ([FilterOperator.NUMBER_IS_IN, FilterOperator.NUMBER_IS_NOT_IN].includes(operator)) {
        return getNumericFilterValue(v as number, coerceTo);
      } else {
        const propertyValue: StringPropertyValue = { value: v as string, '@type': 'string' };
        return propertyValue;
      }
    });
    return { values: propertyValues };
  } else if (FILTER_OPS_NUMBER_RANGE.has(operator)) {
    if (value == null) return null;

    const { min, max } = value as FilterValueNumberRangeType;
    if (!min || !max) return null;
    return { values: [getNumericFilterValue(min, coerceTo), getNumericFilterValue(max, coerceTo)] };
  } else if (FILTER_OPS_DATE_PICKER.has(operator)) {
    if (value == null) return null;

    const date = (value as FilterValueDateType).startDate;
    if (!date) return null;
    if (new Set([FilterOperator.DATE_IS, FilterOperator.DATE_IS_NOT]).has(operator)) {
      return {
        values: [
          getTemporalFilterValue(DateTime.fromISO(date), timezone, coerceTo),
          getTemporalFilterValue(DateTime.fromISO(date).endOf('day'), timezone, coerceTo),
        ],
      };
    }

    return { value: getTemporalFilterValue(DateTime.fromISO(date), timezone, coerceTo) };
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(operator)) {
    if (value == null) return null;

    const { startDate, endDate } = value as FilterValueDateType;
    if (!startDate || !endDate) return null;

    return {
      values: [
        getTemporalFilterValue(DateTime.fromISO(startDate), timezone, coerceTo),
        getTemporalFilterValue(DateTime.fromISO(endDate), timezone, coerceTo),
      ],
    };
  } else if (FILTER_OPS_RELATIVE_PICKER.has(operator)) {
    if (value == null) return null;

    const { number, relativeTimeType } = value as FilterValueRelativeDateType;
    if (!number || !relativeTimeType) return null;

    const { startDate, endDate } = getDatesFromDateRelativeOption(
      relativeTimeType.id,
      number,
      operator === FilterOperator.DATE_PREVIOUS,
    );

    return {
      values: [
        getTemporalFilterValue(startDate, timezone, coerceTo),
        getTemporalFilterValue(endDate, timezone, coerceTo),
      ],
    };
  }

  return null;
};

const getTemporalFilterValue = (
  value: DateTime,
  timezone: string,
  coerceTo: SchemaDataType,
): DateTimePropertyValue | DatePropertyValue => {
  // we don't need to convert dates to a timezone because dates are intrinsically timezone-naive
  if (coerceTo === DATE) {
    return {
      value: value.toISODate(),
      '@type': PropertyType.DATE,
    };
  }

  // date times should be set to the selected hour, but with the offset set to the local time. For example,
  // if the user is in EST selects 12:00, we should be sending to FIDO 12:00 EST so that it can filter on
  // the correct times in UTC
  return {
    value: value.setZone(timezone, { keepLocalTime: true }).toISO(),
    '@type': PropertyType.DATETIME,
  };
};

const getNumericFilterValue = (
  value: number,
  coerceTo: SchemaDataType,
): IntegerPropertyValue | DoublePropertyValue | DecimalPropertyValue => {
  return {
    value: value,
    '@type': coerceTo === FLOAT ? 'decimal' : coerceTo === DOUBLE ? 'double' : 'integer',
  };
};

export const getFilter = (
  columnName: string,
  columnType: string,
  operation: FilterOperator,
  valueSource: FilterValueType | undefined,
  timezone: string,
) => {
  const value = getFilterValue(valueSource, operation, columnType as SchemaDataType, timezone);
  if (value == null) return null;

  switch (operation) {
    case FilterOperator.NUMBER_EQ:
    case FilterOperator.STRING_IS:
    case FilterOperator.NUMBER_NEQ:
    case FilterOperator.STRING_IS_NOT: {
      if (!value.value) return null;

      const filter: Equal = {
        '@type': 'eq',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.DATE_IS:
    case FilterOperator.DATE_IS_NOT:
    case FilterOperator.DATE_TODAY:
    case FilterOperator.DATE_IS_BETWEEN: {
      if (!value.values) return null;
      const startFilter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.values[0],
        propertyId: columnName,
      };
      const endFilter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.values[1],
        propertyId: columnName,
      };
      const and: And = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.BOOLEAN_IS_TRUE:
    case FilterOperator.BOOLEAN_IS_FALSE: {
      // isVarReference shouldn't ever be set for these
      if (!value?.value) return null;
      const filter: Equal = {
        '@type': 'eq',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.STRING_IS_NOT_IN:
    case FilterOperator.STRING_IS_IN:
    case FilterOperator.NUMBER_IS_IN:
    case FilterOperator.NUMBER_IS_NOT_IN: {
      if (!value.values) return null;

      // if any values in the filter are null, we need to handle those separately to not
      // break the request body
      if (value.values.some((v) => v.value == null)) {
        const nonNulls = value.values.filter((v) => v.value != null);

        const nullFilter: Null = {
          '@type': 'null',
          propertyId: columnName,
        };
        const nonNullFilter: In = {
          '@type': 'in',
          values: nonNulls,
          propertyId: columnName,
        };

        const filter: Or = {
          '@type': 'or',
          values: [nullFilter, nonNullFilter],
        };

        return filter;
      }

      const filter: In = {
        '@type': 'in',
        values: value.values,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_LT:
    case FilterOperator.DATE_LT: {
      if (!value.value) return null;
      const filter: LessThan = {
        '@type': 'lt',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_GT:
    case FilterOperator.DATE_GT: {
      if (!value.value) return null;
      const filter: GreaterThan = {
        '@type': 'gt',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_LTE:
    case FilterOperator.DATE_LTE: {
      if (!value.value) return null;
      const filter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_GTE:
    case FilterOperator.DATE_GTE: {
      if (!value.value) return null;
      const filter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.value,
        propertyId: columnName,
      };
      return filter;
    }
    case FilterOperator.NUMBER_IS_BETWEEN:
    case FilterOperator.DATE_PREVIOUS:
    case FilterOperator.DATE_NEXT: {
      if (!value?.values) return null;
      const startFilter: GreaterThanOrEqual = {
        '@type': 'gte',
        value: value.values[0],
        propertyId: columnName,
      };
      const endFilter: LessThanOrEqual = {
        '@type': 'lte',
        value: value.values[1],
        propertyId: columnName,
      };
      const and: And = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.STRING_CONTAINS:
    case FilterOperator.STRING_DOES_NOT_CONTAIN: {
      if (!value.value) return null;
      const contains: StringContains = {
        '@type': 'str-ctns',
        value: value.value as StringPropertyValue,
        propertyId: columnName,
        caseInsensitive: true,
      };
      return contains;
    }
    case FilterOperator.IS_EMPTY:
    case FilterOperator.IS_NOT_EMPTY: {
      const isNull: Null = {
        '@type': 'null',
        propertyId: columnName,
      };
      return isNull;
    }
    // I don't actually think this filter operator works
    case FilterOperator.BOOLEAN_IS:
      return null;
  }
};

export const aggregationMap: Record<
  ChartAggregation,
  { agg: FidoAggregation; aggOption?: AggregationOption }
> = {
  [ChartAggregation.COUNT]: { agg: FidoAggregation.COUNT },
  [ChartAggregation.COUNT_DISTINCT]: { agg: FidoAggregation.COUNT_DISTINCT },
  [ChartAggregation.AVG]: { agg: FidoAggregation.AVG },
  [ChartAggregation.SUM]: { agg: FidoAggregation.SUM },
  [ChartAggregation.MIN]: { agg: FidoAggregation.MIN },
  [ChartAggregation.MAX]: { agg: FidoAggregation.MAX },
  [ChartAggregation['25_PERCENTILE']]: {
    agg: FidoAggregation.PERCENTILE,
    aggOption: { decimalValue: 0.25 },
  },
  [ChartAggregation.MEDIAN]: { agg: FidoAggregation.PERCENTILE, aggOption: { decimalValue: 0.5 } },
  [ChartAggregation['75_PERCENTILE']]: {
    agg: FidoAggregation.PERCENTILE,
    aggOption: { decimalValue: 0.75 },
  },

  // TODOs
  [ChartAggregation.FORMULA]: { agg: FidoAggregation.COUNT },
  [ChartAggregation.FIRST]: { agg: FidoAggregation.COUNT },
};

export const processFilter = (
  filterInfo: FilterOperationInstructions | undefined,
  timezone: string,
) => {
  if (!filterInfo || filterInfo.filterClauses.length === 0) return null;

  const filters: Filter[] = [];

  filterInfo.filterClauses.forEach(({ filterOperation, filterColumn, filterValue }) => {
    if (!filterOperation || !filterColumn) return null;

    const filter = getFilter(
      filterColumn.name,
      filterColumn.type,
      filterOperation.id,
      filterValue,
      timezone,
    );

    if (!filter) return null;

    if (FILTER_OPS_NEGATED.has(filterOperation.id)) {
      const not: Not = {
        '@type': 'not',
        value: filter,
      };

      filters.push(not);
    } else {
      filters.push(filter);
    }
  });

  if (filters.length === 1) {
    return filters[0];
  } else if (filters.length > 1) {
    if (filterInfo.matchOnAll) {
      const andFilter: And = { values: filters, '@type': 'and' };
      return andFilter;
    } else {
      const orFilter: Or = { values: filters, '@type': 'or' };
      return orFilter;
    }
  }
  return null;
};

export const getDateBucketTargetPropertyId = (name: string, pivotAggId: PivotAgg) => {
  return `${PIVOT_AGG_TYPES[pivotAggId].name.toLowerCase().replaceAll(' ', '_')}_${name}`;
};

export const getIntervalGroupingTargetPropertyId = (bucketSize: number, propertyId: string) => {
  const stringifiedBucketSize = bucketSize
    .toString()
    .toLowerCase()
    .replaceAll('.', '_')
    .replaceAll('-', 'N');

  return `${propertyId}_bucket_${stringifiedBucketSize}`;
};

export const getGrouping = (
  {
    column,
    bucket,
    bucketSize,
  }: {
    column: Partial<BaseCol>;
    bucket?: GroupByBucket;
    bucketSize?: number;
  },
  timezone: string,
): Grouping | null => {
  const propertyId = column.name ?? '';

  if (NUMBER_TYPES.has(column.type ?? '') && bucketSize) {
    if (Number.isInteger(bucketSize)) {
      const grouping: IntegerIntervalGrouping = {
        '@type': 'integer-interval',
        interval: bucketSize,
        targetPropertyId: getIntervalGroupingTargetPropertyId(bucketSize, propertyId),
        propertyId,
      };
      return grouping;
    } else {
      const grouping: DecimalIntervalGrouping = {
        '@type': 'decimal-interval',
        interval: bucketSize,
        targetPropertyId: getIntervalGroupingTargetPropertyId(bucketSize, propertyId),
        propertyId,
      };
      return grouping;
    }
  } else if (!TIME_COLUMN_TYPES.has(column.type ?? '')) {
    const grouping: ValueGrouping = {
      '@type': 'value',
      propertyId,
      targetPropertyId: propertyId,
    };
    return grouping;
  }

  if (!bucket || bucket.id === DATE_PART_INPUT_AGG) return null;
  const targetPropertyId = getDateBucketTargetPropertyId(propertyId, bucket.id);

  switch (bucket.id) {
    case PivotAgg.DATE_PART_HOUR: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.HOUR_OF_DAY,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_PART_MONTH: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.MONTH_OF_YEAR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_PART_MONTH_DAY: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.DAY_OF_MONTH,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_PART_WEEK_DAY: {
      const grouping: DatePartGrouping = {
        '@type': 'date-part',
        datePart: DatePart.DAY_OF_WEEK,
        propertyId,
        targetPropertyId,

        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_HOUR: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.HOUR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_DAY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.DAY,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_WEEK: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.WEEK,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_MONTH: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.MONTH,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case PivotAgg.DATE_YEAR: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.YEAR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    default:
      return null;
  }
};

export const getTrendGrouping = (
  {
    column,
    grouping,
  }: {
    column: Partial<BaseCol>;
    grouping: TrendGroupingOptions;
  },
  timezone: string,
): Grouping | null => {
  const propertyId = column.name ?? '';

  const targetPropertyId = getDateBucketTargetPropertyId(
    propertyId,
    TREND_GROUP_OPTION_TO_PIVOT_AGG[grouping].id,
  );
  switch (grouping) {
    case TrendGroupingOptions.HOURLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.HOUR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.DAILY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.DAY,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.WEEKLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.WEEK,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.MONTHLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.MONTH,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    case TrendGroupingOptions.YEARLY: {
      const grouping: CalendarIntervalGrouping = {
        '@type': 'calendar-interval',
        calendarInterval: CalendarInterval.YEAR,
        propertyId,
        targetPropertyId,
        targetTimezone: timezone,
      };
      return grouping;
    }
    default:
      return null;
  }
};

export const getAggregationOrFormula = (col: AggColInfoOptional, index?: number) => {
  const aggId: ChartAggregation = col.agg.id;

  if (aggId === ChartAggregation.FORMULA) {
    // if not set, set the custom formula to this "arbitrary" constant so that the query runs properly.
    // Note: historically, this is what embeddo did
    const formula =
      col.agg.formula && col.agg.formula.trim() !== '' ? col.agg.formula : DEFAULT_CUSTOM_FORMULA;

    // guardrail to not duplicate property names if a customer has multiple custom_formulae.
    // dashboards name custom formulas custom_formula, so we're guaranteed dupes if a customer
    // has multiple
    const columnName =
      // note that column.name should never actually be null
      (col.column.name ?? '') + (index !== undefined ? `_${index.toString()}` : '');

    const property: FormulaProperty = {
      '@type': 'formula',
      targetPropertyId: getAggColNameBase(columnName, aggId),
      formula,
    };

    return property;
  } else {
    const { agg, aggOption } = aggregationMap[aggId];
    const property: AggregateProperty = {
      '@type': 'aggregate',
      propertyId: agg === FidoAggregation.COUNT ? null : (col.column.name ?? ''), // TODO: Should be moved to FIDO
      targetPropertyId: getAggColNameBase(col.column.name ?? '', aggId),
      aggregation: agg,
      aggregationOption: aggOption,
    };

    return property;
  }
};

export const getScatterPlotSourceProperty = (name: string | undefined) => {
  return {
    '@type': 'source',
    propertyId: name ?? '',
    targetPropertyId: null,
  } as SourceProperty;
};

export const getAdHocFilterInfo = (
  filterInfo: FilterOperationInstructions | undefined,
  visualizeOp: {
    operation_type: OPERATION_TYPES;
    instructions: VisualizeOperationDataInstructions;
  },
): FilterOperationInstructions | undefined => {
  if (
    !filterInfo ||
    visualizeOp.operation_type !== OPERATION_TYPES.VISUALIZE_TABLE ||
    !visualizeOp.instructions.VISUALIZE_TABLE.schemaDisplayOptions
  ) {
    return filterInfo;
  }

  // for tables, you can join a column to another column, so we need to sort on the displayed column
  // rather than the underlying value
  const { schemaDisplayOptions, baseSchemaList } = visualizeOp.instructions.VISUALIZE_TABLE;

  const newFilterClauses = filterInfo.filterClauses.map((filter) => {
    const schemaDisplayOption = schemaDisplayOptions[filter.filterColumn?.name ?? ''];
    if (
      schemaDisplayOption &&
      'urlColumnName' in schemaDisplayOption &&
      schemaDisplayOption.format === StringDisplayFormat.LINK &&
      schemaDisplayOption.urlColumnName
    ) {
      const baseSchema: DatasetSchema | undefined = baseSchemaList;
      const joinedColumn = baseSchema?.find(
        (col) => col.name === schemaDisplayOption.urlColumnName,
      );
      if (joinedColumn) {
        return { ...filter, filterColumn: { name: joinedColumn.name, type: joinedColumn.type } };
      }
    }
    return filter;
  });

  const newFilterInfo: FilterOperationInstructions = {
    ...filterInfo,
    filterClauses: newFilterClauses,
  };
  return newFilterInfo;
};

export const deriveColumnConfigs = (
  visualizationType: OPERATION_TYPES,
  visualizationInstructions: VisualizeOperationDataInstructions,
  exportComputation: Computation,
): ColumnConfigs => {
  switch (visualizationType) {
    case OPERATION_TYPES.VISUALIZE_TABLE: {
      const columnConfigs: ColumnConfigs = {};
      Object.values(visualizationInstructions.VISUALIZE_TABLE.changeSchemaList ?? {}).forEach(
        (schemaChange) => {
          const propertyId = schemaChange.col;
          if (schemaChange.newColName) {
            columnConfigs[propertyId] = {
              name: schemaChange.newColName,
            };
          } else {
            columnConfigs[propertyId] = {};
          }

          const schemaDisplayOptions =
            visualizationInstructions.VISUALIZE_TABLE.schemaDisplayOptions ?? {};
          if (propertyId in schemaDisplayOptions) {
            columnConfigs[propertyId].displayFormatting = schemaDisplayOptions[propertyId];
          }
        },
      );

      return columnConfigs;
    }
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2: {
      return visualizationInstructions.VISUALIZE_PIVOT_TABLE_V2
        ? visualizationInstructions.VISUALIZE_PIVOT_TABLE_V2.columnConfigs
        : {};
    }

    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2: // Bar Charts
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2: // Line Charts
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2: // Area Charts
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2: // Pie Charts
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2: {
      const columnConfigs: ColumnConfigs = {};
      Object.values(visualizationInstructions.V2_TWO_DIMENSION_CHART?.aggColumns ?? []).forEach(
        (column, index) => {
          const propertyId = exportComputation.properties[index].targetPropertyId;
          if (column.column.friendly_name && propertyId) {
            columnConfigs[propertyId] = {
              name: column.column.friendly_name,
            };
          }
        },
      );

      return columnConfigs;
    }
    // Funnel Charts
    case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
    default:
      return {};
  }
};

type LocalizationOptions = { timezone: string; locale: string; currencyCode?: string };

export const generateExploreExportColumnOptions = (
  computation: Computation,
  schemaChange: ColumnConfigs,
  datasetSchemaMap: Map<string, BaseCol>,
  exportFormat: ExportFormat,
  localizationOptions: LocalizationOptions,
) => {
  const options: ExportColumnOptions[] = [];

  if (Object.keys(schemaChange ?? []).length === 0) return options;

  const mapToOption = (item: Grouping | Property) => {
    // in practice this should never be null
    const targetPropertyId = item.targetPropertyId ?? '';
    const columnSchemaChange = schemaChange[targetPropertyId];
    // If there is no schema change for this column, then this column has been removed and should
    // not be exported.
    if (!columnSchemaChange) {
      return;
    }

    const displayName = formatColumnDisplayName(
      schemaChange,
      targetPropertyId,
      (displayName: string) => {
        return makeFriendlyName(displayName);
      },
    );
    const propertyType =
      datasetSchemaMap.get(targetPropertyId)?.type ??
      ('@type' in item && item['@type'] === 'formula' ? INTEGER_DATA_TYPE : undefined);
    const exportColumnFormat: ColumnFormat | null = deriveColumnFormat(
      propertyType,
      exportFormat,
      localizationOptions,
      schemaChange[targetPropertyId].displayFormatting,
    );
    options.push({ targetPropertyId, displayName: displayName, columnFormat: exportColumnFormat });
  };

  computation.properties.forEach(mapToOption);
  computation.groupings.forEach(mapToOption);

  // This is the default order that FIDO returns the columns in,
  // TODO (SHIBA-6508): Support maintaining the order of changeSchemaList for data tables
  const orderedColumnNames = [...computation.groupings, ...computation.properties].map(
    (c) => c.targetPropertyId,
  );

  return sortBy(options, (o) => {
    const index = orderedColumnNames.findIndex((colName) => colName === o.targetPropertyId);
    return index === -1 ? orderedColumnNames.length : index;
  });
};

export const generateReportBuilderExportColumnOptions = (
  computation: Computation,
  schemaChange: ColumnConfigs,
  datasetSchemaMap: Map<string, BaseCol>,
  exportFormat: ExportFormat,
  localizationOptions: LocalizationOptions,
) => {
  const options: ExportColumnOptions[] = [];

  if (schemaChange == null || Object.keys(schemaChange).length === 0) return options;

  // groupings should be added first since they are displayed first in the table
  computation.groupings.forEach((grouping) => {
    const grouptStr: string | undefined =
      'calendarInterval' in grouping || 'datePart' in grouping
        ? getEmbeddoBucketFromFidoBucket(grouping)
        : undefined;
    const displayName = formatColumnDisplayName(
      schemaChange,
      grouping.propertyId,
      (displayName: string) => {
        return grouptStr ? `${displayName} (${grouptStr})` : displayName;
      },
    );

    const propertyType = datasetSchemaMap.get(grouping.propertyId)?.type;
    const exportColumnFormat: ColumnFormat | null = deriveReportBuilderColumnFormat(
      schemaChange,
      grouping.propertyId,
      propertyType,
      exportFormat,
      localizationOptions,
    );
    options.push({
      // in practice this should never be null
      targetPropertyId: grouping.targetPropertyId ?? '',
      displayName,
      columnFormat: exportColumnFormat,
    });
  });

  computation.properties.forEach((property) => {
    // in practice this should never be null
    const targetPropertyId = property.targetPropertyId ?? '';

    if ('@type' in property && property['@type'] === 'formula') {
      const exportColumnFormat = deriveReportBuilderColumnFormat(
        schemaChange,
        targetPropertyId,
        INTEGER_DATA_TYPE, // we only support integers in formula properties
        exportFormat,
        localizationOptions,
      );

      const displayName = formatColumnDisplayName(
        schemaChange,
        targetPropertyId,
        (displayName: string) => {
          return makeFriendlyName(displayName);
        },
        'Custom Formula',
      );
      options.push({
        targetPropertyId: property.targetPropertyId ?? '',
        displayName: displayName,
        columnFormat: exportColumnFormat,
      });
    } else if ('@type' in property && property['@type'] === 'aggregate') {
      // in practice this should never be null
      const originalColumnName = property.propertyId ?? '';
      const propertyType = datasetSchemaMap.get(originalColumnName)?.type;
      const exportColumnFormat = deriveReportBuilderColumnFormat(
        schemaChange,
        originalColumnName,
        propertyType,
        exportFormat,
        localizationOptions,
      );

      const agg = getEmbeddoAggFromFidoAgg(property);
      const displayName = formatColumnDisplayName(
        schemaChange,
        originalColumnName,
        (displayName: string) => {
          return `${displayName} (${agg ? AGGREGATIONS_TYPES[agg].name : 'Aggregation'})`;
        },
      );

      options.push({
        targetPropertyId: property.targetPropertyId ?? '',
        displayName: displayName,
        columnFormat: exportColumnFormat,
      });

      // we need this type check otherwise the compiler wouldn't recognize that propertyId always exists
    } else if ('@type' in property && property['@type'] === 'source') {
      const propertyType = datasetSchemaMap.get(property.propertyId)?.type;
      const exportColumnFormat = deriveReportBuilderColumnFormat(
        schemaChange,
        property.propertyId,
        propertyType,
        exportFormat,
        localizationOptions,
      );

      const displayName = formatColumnDisplayName(schemaChange, property.propertyId);
      options.push({
        targetPropertyId: property.targetPropertyId ?? '',
        displayName: displayName,
        columnFormat: exportColumnFormat,
      });
    }
  });

  return options;
};

const deriveReportBuilderColumnFormat = (
  schemaChange: ColumnConfigs,
  propertyId: string,
  columnType: string | undefined,
  exportFormat: ExportFormat,
  localizationOptions: LocalizationOptions,
): ColumnFormat | null => {
  if (!(propertyId in schemaChange)) {
    return null;
  }

  return deriveColumnFormat(
    columnType,
    exportFormat,
    localizationOptions,
    schemaChange[propertyId].displayFormatting,
  );
};

export const deriveColumnFormat = (
  columnType: string | undefined,
  exportFormat: ExportFormat,
  localizationOptions: LocalizationOptions,
  schemaDisplayOption?: SchemaDisplayOption,
): ColumnFormat | null => {
  switch (columnType) {
    case DATETIME:
    case DATE: {
      return deriveDateTimeColumnFormat(
        columnType,
        exportFormat,
        localizationOptions,
        schemaDisplayOption,
      );
    }

    case UNSIGNED_INTEGER:
    case INTEGER_DATA_TYPE:
    case FLOAT:
    case DOUBLE:
      return deriveNumberColumnFormat(
        columnType,
        exportFormat,
        localizationOptions,
        schemaDisplayOption,
      );
    default:
      return null;
  }
};

const deriveDateTimeColumnFormat = (
  columnType: string,
  exportFormat: ExportFormat,
  { timezone, locale }: LocalizationOptions,
  schemaDisplayOption?: SchemaDisplayOption,
): DateTimeColumnFormat | null => {
  const dateTimeDisplayOptions = schemaDisplayOption as DateDisplayOptions;
  const dateTimeColumnFormat = getCurrentDateFormat(dateTimeDisplayOptions, columnType);

  let dateTimeFormatPattern =
    exportFormat !== ExportFormat.XLSX
      ? undefined
      : getFormatForFidoExports(dateTimeColumnFormat, dateTimeDisplayOptions?.customFormat);

  if (dateTimeFormatPattern) {
    switch (dateTimeColumnFormat) {
      case DateDisplayFormat.VERBAL_LONG:
      case DateDisplayFormat.VERBAL_SHORT:
        dateTimeFormatPattern = dateTimeFormatPattern.replace(/M/g, 'm');
        break;
    }
  }

  return {
    pattern: dateTimeFormatPattern,
    locale,
    targetTimezone: timezone,
    '@type': 'datetime',
  };
};

const deriveNumberColumnFormat = (
  columnType: string,
  exportFormat: ExportFormat,
  { currencyCode, locale }: LocalizationOptions,
  schemaDisplayOption?: SchemaDisplayOption,
): ColumnFormat | null => {
  // we only want to support formatting in XLSX for human readibility
  // for other formats (CSV/TSV) raw values are more machine/code readable
  if (exportFormat !== ExportFormat.XLSX) {
    return null;
  }

  const numberDisplayOptions = schemaDisplayOption as NumberDisplayOptions;
  const numberFormat = numberDisplayOptions?.format;
  const multiplier =
    numberDisplayOptions?.multiplier == undefined || numberDisplayOptions?.multiplier == 1
      ? null
      : numberDisplayOptions?.multiplier;

  const formatMask = generateDecimalFormatMask(
    columnType,
    currencyCode,
    numberDisplayOptions?.decimalPlaces,
    numberDisplayOptions?.hasCommas,
    numberDisplayOptions?.displayNegativeValuesWithParentheses,
    numberFormat,
  );

  const zeroReplacement = numberDisplayOptions?.zeroCharacter ?? null;

  switch (numberFormat) {
    case NumberDisplayFormat.TIME: {
      let durationPattern = numberDisplayOptions.timeCustomFormat
        ? numberDisplayOptions.timeCustomFormat
        : numberDisplayOptions.customDurationFormat
          ? numberDisplayOptions.customDurationFormat
          : numberDisplayOptions.timeFormat != undefined &&
              TIME_DIFF_FORMATS.ABBREVIATION.id === numberDisplayOptions.timeFormat.id
            ? null
            : //Standard
              'd days hh:MM:ss';
      durationPattern = null == durationPattern ? null : formatJavaDurationPattern(durationPattern);
      return {
        '@type': 'duration',
        durationPattern: durationPattern,
      };
    }
    case NumberDisplayFormat.CURRENCY: {
      return {
        '@type': 'decimal',
        decimalFormat: formatMask,
        unitOfMeasurement: UnitOfMeasurement.CURRENCY,
        multiplier: multiplier,
        zeroReplacement: zeroReplacement,
        locale,
      };
    }
    case NumberDisplayFormat.PERCENT: {
      const denominator =
        undefined != numberDisplayOptions.goal && +numberDisplayOptions.goal !== 0
          ? +numberDisplayOptions.goal
          : 1;
      const finalMultiplier =
        null == multiplier && undefined == denominator
          ? null
          : null == multiplier
            ? 1 / denominator
            : undefined == denominator
              ? multiplier
              : multiplier / denominator;
      return {
        '@type': 'decimal',
        decimalFormat: formatMask,
        multiplier: finalMultiplier,
        zeroReplacement: zeroReplacement,
        locale,
      };
    }
    default: {
      // NumberDisplayFormat.NORMAL and undefined (default behaviour)
      return {
        '@type': 'decimal',
        decimalFormat: formatMask,
        multiplier: multiplier,
        zeroReplacement: zeroReplacement,
        locale,
      };
    }
  }
};

const formatColumnDisplayName = (
  schemaChange: ColumnConfigs,
  propertyId: string,
  displayNameFormatFunc?: (preferredDisplayName: string) => string,
  defaultDisplayName?: string,
) => {
  const maybeConfiguredName = (schemaChange[propertyId] as ColumnConfigWithName)?.name;
  if (undefined != makeAbsentOrNotBlank(maybeConfiguredName)) {
    return displayNameFormatFunc ? displayNameFormatFunc(maybeConfiguredName) : maybeConfiguredName;
  } else if (undefined !== defaultDisplayName) {
    return defaultDisplayName;
  }
  return makeAbsentOrNotBlank(makeFriendlyName(propertyId));
};

const makeFriendlyName = (name: string) => titleCase(name.replaceAll('_', ' '));

const getEmbeddoAggFromFidoAgg = (aggregation: AggregateProperty) => {
  if (aggregation.aggregation === FidoAggregation.COUNT) return ChartAggregation.COUNT;
  if (aggregation.aggregation === FidoAggregation.COUNT_DISTINCT)
    return ChartAggregation.COUNT_DISTINCT;
  if (aggregation.aggregation === FidoAggregation.SUM) return ChartAggregation.SUM;
  if (aggregation.aggregation === FidoAggregation.MIN) return ChartAggregation.MIN;
  if (aggregation.aggregation === FidoAggregation.MAX) return ChartAggregation.MAX;
  if (aggregation.aggregation === FidoAggregation.PERCENTILE) {
    if (aggregation.aggregationOption?.decimalValue === 0.25)
      return ChartAggregation['25_PERCENTILE'];
    if (aggregation.aggregationOption?.decimalValue === 0.5) return ChartAggregation.MEDIAN;
    if (aggregation.aggregationOption?.decimalValue === 0.75)
      return ChartAggregation['75_PERCENTILE'];
  }
};

const getEmbeddoBucketFromFidoBucket = (grouping: CalendarIntervalGrouping | DatePartGrouping) => {
  if (grouping['@type'] === 'calendar-interval') {
    if (grouping.calendarInterval === CalendarInterval.YEAR) return PivotAgg.DATE_YEAR;
    if (grouping.calendarInterval === CalendarInterval.MONTH) return PivotAgg.DATE_MONTH;
    if (grouping.calendarInterval === CalendarInterval.WEEK) return PivotAgg.DATE_WEEK;
    if (grouping.calendarInterval === CalendarInterval.DAY) return PivotAgg.DATE_DAY;
    if (grouping.calendarInterval === CalendarInterval.HOUR) return PivotAgg.DATE_HOUR;
  } else {
    if (grouping.datePart === DatePart.DAY_OF_MONTH) return PivotAgg.DATE_PART_MONTH_DAY;
    if (grouping.datePart === DatePart.DAY_OF_WEEK) return PivotAgg.DATE_PART_WEEK_DAY;
    if (grouping.datePart === DatePart.HOUR_OF_DAY) return PivotAgg.DATE_PART_HOUR;
    if (grouping.datePart === DatePart.MONTH_OF_YEAR) return PivotAgg.DATE_PART_MONTH;
  }
};

const generateDecimalFormatMask = (
  columnType: string,
  currencyCode?: string,
  decimalPlaces?: number,
  hasCommas?: boolean,
  displayNegativeValuesWithParentheses?: boolean,
  numberFormat?: NumberDisplayFormat,
) => {
  const isPercentColumn = numberFormat === NumberDisplayFormat.PERCENT;
  const isCurrencyColumn = numberFormat === NumberDisplayFormat.CURRENCY;
  const defaultToTwoDecimals = DECIMAL_TYPES.has(columnType) || isCurrencyColumn;
  const actualDecimalPlaces =
    undefined != decimalPlaces ? decimalPlaces : defaultToTwoDecimals ? 2 : 0;
  const hasDisplayNegativeValuesWithParentheses =
    undefined != displayNegativeValuesWithParentheses && displayNegativeValuesWithParentheses;
  const displayCommas = undefined != hasCommas && hasCommas;
  if (
    actualDecimalPlaces === 0 &&
    !hasDisplayNegativeValuesWithParentheses &&
    !displayCommas &&
    !isPercentColumn &&
    !isCurrencyColumn
  )
    return null;
  let formatMask = '0';

  if (actualDecimalPlaces !== 0) formatMask = formatMask + '.' + '0'.repeat(actualDecimalPlaces);
  if (displayCommas) formatMask = '#,##' + formatMask;
  if (isPercentColumn) formatMask += '%';
  else if (numberFormat === NumberDisplayFormat.CURRENCY) {
    const code = currencyCode ?? 'usd';
    const format = CURRENCY_MAPPING[code] ?? ['', ''];
    formatMask = format[0] + formatMask + format[1];
  }
  if (hasDisplayNegativeValuesWithParentheses) formatMask = formatMask + ';(' + formatMask + ')';
  return formatMask;
};

const formatJavaDurationPattern = (durationPattern: string) => {
  // TODO: might need to think more about this and maybe skip escaped characters/blocks
  durationPattern = stringReplaceAll(durationPattern, 'SS', 'ss');
  durationPattern = stringReplaceAll(durationPattern, 'MM', 'mm');
  durationPattern = stringReplaceAll(durationPattern, 'hh', 'HH');
  durationPattern = stringReplaceAll(durationPattern, 'DD', 'dd');

  // detect if pattern is already escaped
  if (!durationPattern.includes("'")) {
    // escape all words besides ss mm HH d dd
    durationPattern = durationPattern.replace(durationSubstistutionRegex, "'$&'");
  }
  return durationPattern;
};

export const createDatasetSchemaMap = (datasetSchema?: DatasetSchema) => {
  const datasetSchemaMap = new Map<string, BaseCol>();
  (datasetSchema ?? []).forEach((schema) => {
    datasetSchemaMap.set(schema.name, schema); // Set the id as the key and data as the value
  });
  return datasetSchemaMap;
};
