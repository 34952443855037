import { FC, useMemo } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { sprinkles } from 'components/ds';
import { formatQuery } from 'utils/queryUtils';

interface Props {
  queryText: string;
}

export const DatasetPreviewPanel: FC<Props> = ({ queryText }) => {
  const formattedQuery = useMemo(() => formatQuery(queryText), [queryText]);

  return (
    <div className={sprinkles({ marginX: 'sp2', maxHeight: 'fill', overflow: 'scroll' })}>
      <SyntaxHighlighter showLineNumbers language="sql">
        {formattedQuery}
      </SyntaxHighlighter>
    </div>
  );
};
