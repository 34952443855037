import { FC, ReactNode, useContext, useMemo, useState } from 'react';
import { Map, MapRef, Marker, Popup } from 'react-map-gl';
import ResizeObserver from 'react-resize-observer';
import { v4 } from 'uuid';

import { DatasetRow, NUMBER_TYPES } from '@explo/data';

import { Icon, sprinkles } from 'components/ds';
import { ChartTooltip, PointData } from 'components/embed/ChartTooltip';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  DEFAULT_MAP_STYLE,
  INITIAL_VIEW_STATE,
  MAP_STYLE_TO_MAPBOX_URL,
  MARKER_COLOR,
  MARKER_OUTLINE_COLOR,
  MARKER_OUTLINE_WIDTH,
  MARKER_SIZE,
} from 'constants/maps';
import { VisualizeGeospatialChartInstructions } from 'constants/types';
import { GlobalStylesContext } from 'globalStyles';

import 'mapbox-gl/dist/mapbox-gl.css';
import * as styles from '../../MapboxChart/index.css';
import { validateLatLongValues } from '../mapUtils';

type Props = {
  data: DatasetRow[];
  instructions: VisualizeGeospatialChartInstructions;
  mapRef: React.RefObject<MapRef>;
  debouncedMapResize: () => void;
};

export const LocationMarkerMap: FC<Props> = ({
  data,
  instructions,
  debouncedMapResize,
  mapRef,
}) => {
  const [tooltipInfo, setTooltipInfo] = useState<{
    lat: number;
    long: number;
    pt: DatasetRow;
  } | null>(null);
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  const mapFormat = instructions.mapFormat;

  const latitudeColumnName = instructions.latitudeColumn?.name;
  const longitudeColumnName = instructions.longitudeColumn?.name;

  const [pins, key] = useMemo(() => {
    const pinList: ReactNode[] = [];
    data.forEach((pt, index) => {
      const latitudeVal = latitudeColumnName ? Number(pt[latitudeColumnName]) : undefined;
      const longitudeVal = longitudeColumnName ? Number(pt[longitudeColumnName]) : undefined;

      if (
        latitudeVal === undefined ||
        longitudeVal === undefined ||
        !validateLatLongValues(latitudeVal, longitudeVal)
      )
        return;

      pinList.push(
        <Marker
          key={`marker-${index}`}
          latitude={latitudeVal}
          longitude={longitudeVal}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setTooltipInfo({ lat: latitudeVal, long: longitudeVal, pt });
          }}>
          <Icon
            name="location-pin"
            style={{
              width: mapFormat?.markerSize ?? MARKER_SIZE,
              height: mapFormat?.markerSize ?? MARKER_SIZE,
              color: mapFormat?.markerColor ?? MARKER_COLOR,
              stroke: mapFormat?.markerOutlineColor ?? MARKER_OUTLINE_COLOR,
              strokeWidth: mapFormat?.markerOutlineWidth ?? MARKER_OUTLINE_WIDTH,
            }}
          />
        </Marker>,
      );
    });

    return [pinList, v4()];
  }, [
    data,
    latitudeColumnName,
    longitudeColumnName,
    mapFormat?.markerSize,
    mapFormat?.markerColor,
    mapFormat?.markerOutlineColor,
    mapFormat?.markerOutlineWidth,
  ]);

  const tooltipData: PointData[] | undefined = useMemo(() => {
    if (!tooltipInfo) return;

    const data: PointData[] = [];
    Object.entries(tooltipInfo.pt).forEach(([colName, colValue]) => {
      if (colName === latitudeColumnName || colName === longitudeColumnName) return;
      const colType = instructions.tooltipColumns?.find((col) => col.name === colName)?.type;
      const isNum = colType && NUMBER_TYPES.has(colType);
      const tooltipFormat = isNum ? instructions.mapFormat?.tooltipFormat?.[colName] : undefined;
      data.push({
        color: undefined,
        name: colName,
        value: isNum ? Number(colValue) : undefined,
        formattedValue: !isNum ? (colValue ?? '-').toString() : undefined,
        format: tooltipFormat
          ? {
              decimalPlaces: tooltipFormat?.decimalPlaces ?? 2,
              significantDigits: tooltipFormat?.significantDigits ?? 3,
              formatId: tooltipFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
              hasCommas: true,
              timeFormatId: tooltipFormat?.timeFormat?.id,
              customTimeFormat: tooltipFormat?.timeCustomerFormat,
              customDurationFormat: tooltipFormat?.customDurationFormat,
              units: tooltipFormat?.unit,
            }
          : undefined,
      });
    });

    return data;
  }, [tooltipInfo, latitudeColumnName, longitudeColumnName, instructions]);

  return (
    <div className={sprinkles({ height: 'fill', width: 'fill' })}>
      <Map
        dragRotate
        attributionControl={false}
        cursor="grab"
        initialViewState={mapFormat?.viewState ?? INITIAL_VIEW_STATE}
        key={key} // Key is refreshed every time the pins update. This forces the map to rerender to prevent bugginess in pin display.
        mapLib={import(/* webpackChunkName: "mapbox-gl" */ 'mapbox-gl')}
        mapStyle={
          mapFormat?.style
            ? (MAP_STYLE_TO_MAPBOX_URL[mapFormat?.style] ?? DEFAULT_MAP_STYLE)
            : DEFAULT_MAP_STYLE
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        ref={mapRef}>
        <>
          {pins}
          {tooltipInfo && tooltipData ? (
            <Popup
              anchor="bottom"
              className={styles.tooltip}
              closeButton={false}
              latitude={tooltipInfo.lat}
              longitude={tooltipInfo.long}
              offset={8}
              onClose={() => setTooltipInfo(null)}
              style={{ maxWidth: 500 }}>
              <ChartTooltip
                globalStyleConfig={globalStyleConfig}
                header={
                  instructions.mapFormat?.hideTooltipHeader
                    ? undefined
                    : `${tooltipInfo.lat}, ${tooltipInfo.long}`
                }
                points={tooltipData}
              />
            </Popup>
          ) : null}
        </>
      </Map>
      <ResizeObserver onResize={debouncedMapResize} />
    </div>
  );
};
