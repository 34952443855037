import { useMemo } from 'react';

import { SortOrder } from '@explo/data';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

interface SortOption<T extends string> {
  name: T;
  sortOrder: SortOrder;
}

interface Props<T extends string> {
  sortOptions: SortOption<T>[];
  sortOption: SortOption<T>;
  onChange: (sortOption: SortOption<T>) => void;
}

export const SortButton = <T extends string>({ sortOptions, onChange, sortOption }: Props<T>) => {
  const menuOptions = useMemo(
    (): DropdownOption[] =>
      sortOptions.map((option) => {
        return {
          name: option.name as string,
          icon: getSortOrderIcon(option.sortOrder),
          onClick: () => onChange(option),
        };
      }),
    [sortOptions, onChange],
  );

  return (
    <div className={sprinkles({ flexItems: 'center' })}>
      <EmbeddedDropdownMenu menuOptions={menuOptions}>
        <EmbedButton icon={getSortOrderIcon(sortOption.sortOrder)} variant="tertiary">
          {sortOption.name as string}
        </EmbedButton>
      </EmbeddedDropdownMenu>
    </div>
  );
};

const getSortOrderIcon = (sortOrder: SortOrder) =>
  sortOrder === SortOrder.ASC ? 'arrow-up' : 'arrow-down';
