import { FC, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  previewReportBuilderVersion,
  ReportBuilder,
  switchCurrentlyEditingReportBuilderVersion,
} from 'actions/reportBuilderActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { VersionControlModal } from 'components/VersionControlModal';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Button, sprinkles } from 'components/ds';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ROUTES } from 'constants/routes';
import { SavingInfo } from 'pages/dashboardPage/SavingInfo';
import { setIsEditing } from 'reducers/reportBuilderEditReducer';
import { AllStates } from 'reducers/rootReducer';
import { cloneComputedViews } from 'reducers/thunks/fidoThunks';
import { setVersionForPreviewThunk } from 'reducers/thunks/reportBuilderEditorThunks';
import { createReportBuilderDraft } from 'reducers/thunks/resourceSaveThunks';
import { fetchVersion } from 'reducers/thunks/versionManagementThunks';
import * as RD from 'remotedata';
import { clearSelectedReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { EditResourceBannerDropdown } from 'shared/ExploResource/EditResourceBannerDropdown';
import { SIDE_PANE_HEADER_HEIGHT } from 'shared/ExploResource/constants';
import { Breadcrumb, ResourcePageType } from 'types/exploResource';
import { canUserModifyResource } from 'utils/permissionUtils';

type Props = {
  reportBuilder: ReportBuilder;
  breadcrumbs: Breadcrumb[];
};

const resourceBannerClass = sprinkles({
  borderBottom: 1,
  borderRight: 1,
  borderColor: 'gray7',
});

export const Header: FC<Props> = ({ reportBuilder, breadcrumbs }) => {
  const dispatch = useDispatch();
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const { versionInfo, permissions, isEditing, mostRecentVersion, shouldUseFido, config } =
    useSelector(
      (state: AllStates) => ({
        versionInfo: 'reportBuilderEdit' in state ? state.reportBuilderEdit.versionInfo : null,
        config: 'reportBuilderEdit' in state ? state.reportBuilderEdit.config : RD.Idle(),
        permissions: 'currentUser' in state ? state.currentUser.permissions : {},
        isEditing: 'reportBuilderEdit' in state ? state.reportBuilderEdit.isEditing : false,
        mostRecentVersion:
          'reportBuilderEdit' in state
            ? RD.getOrDefault(state.reportBuilderEdit.versionsMetadata, [])?.[0]
            : undefined,
        shouldUseFido:
          'currentUser' in state ? state.currentUser.team?.feature_flags.use_fido : false,
      }),
      shallowEqual,
    );

  const userCanEditReportBuilder = canUserModifyResource(
    permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER],
  );

  const onReturnMostCurrentVersionClicked = useCallback(() => {
    if (!reportBuilder.id || !mostRecentVersion) return;

    dispatch(
      previewReportBuilderVersion(
        {
          id: reportBuilder.id,
          queryParams: { version_number: mostRecentVersion.version_number },
        },
        ({ preview_version }) => {
          dispatch(
            switchCurrentlyEditingReportBuilderVersion({ reportBuilderVersion: preview_version }),
          );
          dispatch(setVersionForPreviewThunk());
          dispatch(clearSelectedReport());
        },
      ),
    );
  }, [dispatch, reportBuilder.id, mostRecentVersion]);

  const handleDraftCreation = useCallback(() => {
    if (!shouldUseFido) {
      if (!RD.isSuccess(config)) return;
      dispatch(createReportBuilderDraft({ config: config.data }));
      return;
    }

    dispatch(
      fetchVersion({
        isExplore: false,
        args: { id: reportBuilder.id },
        onSuccess: ({ version }) => {
          if (!version || !('config' in version)) return;

          return dispatch(
            cloneComputedViews({
              configuration: version.config,
              onSuccess: (configuration) =>
                dispatch(
                  createReportBuilderDraft({
                    config: configuration as ReportBuilderConfig,
                  }),
                ),
            }),
          );
        },
      }),
    );
  }, [dispatch, reportBuilder.id, shouldUseFido, config]);

  const handleExistingDraft = useCallback(() => {
    if (!mostRecentVersion?.is_draft || !reportBuilder.id) return;

    dispatch(
      previewReportBuilderVersion(
        {
          id: reportBuilder.id,
          queryParams: { version_number: mostRecentVersion.version_number },
        },
        ({ preview_version }) =>
          dispatch(
            switchCurrentlyEditingReportBuilderVersion({ reportBuilderVersion: preview_version }),
          ),
      ),
    );
  }, [dispatch, reportBuilder.id, mostRecentVersion]);

  const onEditClicked = useCallback(() => {
    if (!reportBuilder.id) return;

    dispatch(setIsEditing(true));
    if (versionInfo?.is_draft) return;

    if (mostRecentVersion?.is_draft) {
      handleExistingDraft();
    } else {
      handleDraftCreation();
    }
  }, [
    dispatch,
    reportBuilder.id,
    versionInfo,
    mostRecentVersion,
    handleExistingDraft,
    handleDraftCreation,
  ]);

  const renderVersionActionButtons = () => {
    // There should always be at least 1 version, so if mostRecentVersion doesn't exist it means we are still waiting for versions to load
    const versionsHaveLoaded = !!mostRecentVersion;

    if (versionInfo?.version_number !== mostRecentVersion?.version_number) {
      return (
        <>
          <Button
            className={sprinkles({ marginRight: 'sp1' })}
            disabled={!versionsHaveLoaded}
            onClick={() => setIsVersionModalOpen(true)}
            variant="secondary">
            Version Control
          </Button>
          <Button
            disabled={!versionsHaveLoaded}
            onClick={onReturnMostCurrentVersionClicked}
            variant="primary">
            Return to Current Draft
          </Button>
        </>
      );
    }

    return (
      <>
        {!isEditing ? (
          <Button
            className={sprinkles({ marginRight: 'sp1' })}
            disabled={!versionsHaveLoaded}
            onClick={() => setIsVersionModalOpen(true)}
            variant="secondary">
            Version Control
          </Button>
        ) : null}
        {userCanEditReportBuilder ? (
          <Button
            disabled={!versionsHaveLoaded}
            onClick={() => (isEditing ? dispatch(setIsEditing(false)) : onEditClicked())}
            variant="primary">
            {isEditing ? 'Done Editing' : 'Edit'}
          </Button>
        ) : null}
      </>
    );
  };

  const renderVersionControls = () => {
    if (!userCanEditReportBuilder) return null;

    return (
      <div className={sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp1' })}>
        {versionInfo ? (
          <>
            <SavingInfo
              isDraft={versionInfo.is_draft}
              resourceType={ResourcePageType.REPORT_BUILDER}
              versionNumber={versionInfo.version_number}
            />
            <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
              {renderVersionActionButtons()}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderVersionControlModal = () => {
    if (
      !isVersionModalOpen ||
      !canUserModifyResource(permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER])
    )
      return null;

    return (
      <VersionControlModal
        closeModal={() => setIsVersionModalOpen(false)}
        pageType={ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  return (
    <div className={rootClass} style={{ height: SIDE_PANE_HEADER_HEIGHT }}>
      <EditResourceBannerDropdown
        breadcrumbs={breadcrumbs}
        className={resourceBannerClass}
        onDeletionUrlProviderFn={() => ROUTES.REPORT_BUILDER}
        pageType={ResourcePageType.REPORT_BUILDER}
        resource={reportBuilder}
      />
      <div className={editContainerClass}>
        <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
          <div className={sprinkles({ marginRight: 'sp1' })}>Viewing as</div>
          <ViewingAsCustomerSelector parseUrl />
        </div>
        {renderVersionControls()}
      </div>
      {renderVersionControlModal()}
    </div>
  );
};

const rootClass = sprinkles({
  width: 'fill',
  display: 'flex',
  backgroundColor: 'white',
  borderBottom: 1,
  borderColor: 'gray7',
});

const editContainerClass = sprinkles({
  flex: 1,
  flexItems: 'alignCenterBetween',
  paddingX: 'sp1.5',
});
