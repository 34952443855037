import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { Button, sprinkles } from 'components/ds';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { DatasetPreviewTable } from 'components/resource/DatasetPreviewTable';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { DatasetItem } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetItem';
import {
  clearDuplicateColumns,
  clearVersionedComputedViewReferences,
  createReportBuilderDataset,
  importGlobalDataset,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { createComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { filterReportBuilderDatasets } from 'utils/adHocUtils';
import { getReportBuilderSchema } from 'utils/reportBuilderConfigUtils';
import { useQuery } from 'utils/routerUtils';
import { sortBy } from 'utils/standard';

import { EditorLeftColumn } from '../EditorLeftColumn';

import { ParentSchema } from 'actions/dataSourceActions';
import { ImportGlobalDatasetModal } from 'components/DataLibrary/ImportGlobalDatasetModal';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { getEmbeddoSchemaFromView } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { convertViewToReportBuilderDataset } from 'pages/dataLibraryPage/dataLibraryUtil';
import { DatasetConfig } from './DatasetConfig';
import * as styles from './DatasetEditor.css';
import { useColorCategoryTracker } from './useColorCategoryTracker';

type Props = {
  config: ReportBuilderConfig;
  reportBuilderId: number;
};

type SelectedDatasetInfo = { id: string; isNew: boolean };

export const DatasetEditor: FC<Props> = ({ config, reportBuilderId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedDatasetInfo, setSelectedDatasetInfo] = useState<SelectedDatasetInfo | null>(null);
  const [isImportGlobalDatasetModalOpen, setIsImportGlobalDatasetModalOpen] = useState(false);

  const {
    datasetData,
    globalStyleConfig,
    shouldUseFido,
    schemas,
    referencedGlobalDatasets,
    enableDataLibraryV2,
    enableDataLibraryDebugActions,
  } = useSelector(
    (state: ReduxState) => ({
      datasetData: selectedDatasetInfo
        ? state.reportBuilderEdit.datasetData[selectedDatasetInfo.id]
        : undefined,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      schemas: state.parentSchemas,
      referencedGlobalDatasets: RD.getOrDefault(
        state.reportBuilderEdit.referencedGlobalDatasets,
        {},
      ),
      enableDataLibraryV2: state.currentUser.team?.feature_flags.enable_data_library_v2,
      enableDataLibraryDebugActions:
        state.currentUser.team?.feature_flags.enable_data_library_debug_actions,
    }),
    shallowEqual,
  );

  const selectedGlobalDataset = referencedGlobalDatasets[selectedDatasetInfo?.id ?? ''];
  const convertedSelectedGlobalDataset = useMemo(() => {
    return selectedGlobalDataset
      ? convertViewToReportBuilderDataset(
          {
            ...selectedGlobalDataset,
            id: selectedGlobalDataset.id ?? '',
            namespaceId: selectedGlobalDataset.namespaceId ?? '',
          },
          RD.getOrDefault(schemas.usedParentSchemas, []),
          config.datasets[selectedGlobalDataset.id ?? ''],
        )
      : undefined;
  }, [schemas.usedParentSchemas, selectedGlobalDataset, config.datasets]);
  const selectedDataset = selectedDatasetInfo
    ? (convertedSelectedGlobalDataset ?? config.datasets[selectedDatasetInfo.id])
    : undefined;
  const isSelectedDatasetGlobal = !!selectedGlobalDataset;

  const colorCategoryTracker = useColorCategoryTracker(
    globalStyleConfig,
    selectedDataset?.columnConfigs,
    datasetData?.rows,
  );

  const globalDatasetIds = useMemo(
    () => new Set(Object.keys(referencedGlobalDatasets)),
    [referencedGlobalDatasets],
  );

  // Filter out the datasets that are created to hold the metadata info (permissions, custom
  // aggregations) for their corresponding global dataset.
  const nonBackingGlobalDatasetDatasets = useMemo(() => {
    return Object.values(config.datasets).filter((dataset) => !globalDatasetIds.has(dataset.id));
  }, [config.datasets, globalDatasetIds]);

  const filteredAndOrderedDatasets = useMemo(
    () =>
      sortBy(
        filterReportBuilderDatasets(searchQuery, Object.values(nonBackingGlobalDatasetDatasets)),
        (dataset) => dataset.name,
      ),
    [searchQuery, nonBackingGlobalDatasetDatasets],
  );

  const currentDatasetNames = useMemo(() => {
    return new Set(Object.values(config.datasets).map((dataset) => dataset.name));
  }, [config.datasets]);

  const switchSelectedDataset = useCallback(
    (datasetId: string, isNew?: boolean) => {
      dispatch(fetchAppDataset({ datasetId, switchedToDataset: true }));
      history.replace(
        ROUTE_PROVIDERS.REPORT_BUILDER_DATASET_EDIT(String(reportBuilderId), datasetId),
      );
      setSelectedDatasetInfo({ id: datasetId, isNew: isNew ?? false });
    },
    [dispatch, history, reportBuilderId],
  );

  useEffect(() => {
    if (selectedDatasetInfo || filteredAndOrderedDatasets.length === 0) return;

    const id = query.get('id');
    const dataset = config.datasets[id ?? ''] ?? filteredAndOrderedDatasets[0];
    switchSelectedDataset(dataset.id);
  }, [
    query,
    config.datasets,
    switchSelectedDataset,
    selectedDatasetInfo,
    filteredAndOrderedDatasets,
  ]);

  useEffect(() => {
    if (!selectedDatasetInfo || !datasetData?.duplicateColumns) return;
    dispatch(clearDuplicateColumns(selectedDatasetInfo.id));
    showDuplicateColumnNameToast(datasetData.duplicateColumns, true);
  }, [dispatch, datasetData?.duplicateColumns, selectedDatasetInfo]);

  const schema = useMemo(
    () => selectedDataset && getReportBuilderSchema(datasetData?.schema, selectedDataset, true),
    [datasetData?.schema, selectedDataset],
  );

  const handleAddDataset = useCallback(
    (name: string, parentSchema: ParentSchema) => {
      const newId = uuidv4();

      if (shouldUseFido) {
        dispatch(
          createComputedView({
            name,
            namespace: RD.getOrDefault(schemas.usedParentSchemas, []).find(
              (s) => s.id === parentSchema.id,
            ),
            resourceType: ResourcePageType.REPORT_BUILDER,
            datasetId: newId,
            onSuccess: () => switchSelectedDataset(newId, true),
          }),
        );
      } else {
        dispatch(createReportBuilderDataset({ id: newId, name, parentSchemaId: parentSchema.id }));
        switchSelectedDataset(newId, true);
      }
    },
    [dispatch, schemas.usedParentSchemas, shouldUseFido, switchSelectedDataset],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      return (
        selectedDataset &&
        dispatch(
          fetchAppDataset({
            datasetId: selectedDataset.id,
            page,
          }),
        )
      );
    },
    [dispatch, selectedDataset],
  );

  const noDatasetSelectedDiv = () => {
    return (
      <div className={styles.emptyContainer} style={{ fontSize: 20 }}>
        Select a Dataset
      </div>
    );
  };

  const filteredGlobalDatasets = useMemo(() => {
    if (!enableDataLibraryV2) {
      return [];
    }

    return Object.values(referencedGlobalDatasets).filter((dataset) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return (
        dataset.name.toLowerCase().includes(lowerCaseSearchQuery) ||
        dataset.description?.toLocaleLowerCase().includes(lowerCaseSearchQuery)
      );
    });
  }, [enableDataLibraryV2, referencedGlobalDatasets, searchQuery]);

  const filteredConvertedGlobalDatasets = useMemo(() => {
    const parentSchemasData = schemas.usedParentSchemas;
    if (!RD.isSuccess(parentSchemasData)) {
      return [];
    }

    return filteredGlobalDatasets.map((globalDataset) => {
      return convertViewToReportBuilderDataset(
        {
          ...globalDataset,
          id: globalDataset.id ?? '',
          namespaceId: globalDataset.namespaceId ?? '',
        },
        parentSchemasData.data,
        config.datasets[globalDataset.id ?? ''],
      );
    });
  }, [filteredGlobalDatasets, schemas.usedParentSchemas, config.datasets]);

  const renderedContent = useMemo(
    () =>
      filteredAndOrderedDatasets.map((dataset) => {
        const isSelected = dataset.id === selectedDatasetInfo?.id;
        return (
          <DatasetItem
            dataset={dataset}
            isSelected={isSelected}
            key={dataset.id}
            onClick={() => {
              if (isSelected) return;
              switchSelectedDataset(dataset.id);
            }}
          />
        );
      }),
    [filteredAndOrderedDatasets, selectedDatasetInfo?.id, switchSelectedDataset],
  );

  const hasDatasetsToShow = useMemo(() => {
    return filteredAndOrderedDatasets.length > 0 || filteredConvertedGlobalDatasets.length > 0;
  }, [filteredAndOrderedDatasets, filteredConvertedGlobalDatasets]);

  return (
    <div className={styles.root}>
      <EditorLeftColumn>
        <div
          className={cx(styles.datasetListContainer, {
            [sprinkles({ height: 'fill' })]: !filteredAndOrderedDatasets.length,
          })}>
          {enableDataLibraryV2 ? (
            <div className={sprinkles({ flexItems: 'alignCenter', margin: 'sp1' })}>
              <AddDatasetButton
                datasetNames={currentDatasetNames}
                onSubmit={handleAddDataset}
                variantOverride="secondary"
              />
              <Button
                fillWidth
                className={sprinkles({ marginLeft: 'sp1' })}
                onClick={() => setIsImportGlobalDatasetModalOpen(true)}>
                Import Global Dataset
              </Button>
            </div>
          ) : (
            <AddDatasetButton
              className={sprinkles({ marginBottom: 'sp1' })}
              datasetNames={currentDatasetNames}
              onSubmit={handleAddDataset}
            />
          )}
          {enableDataLibraryV2 && enableDataLibraryDebugActions ? (
            <div className={sprinkles({ marginX: 'sp1', marginBottom: 'sp1' })}>
              <Button
                fillWidth
                onClick={() => dispatch(clearVersionedComputedViewReferences())}
                variant="destructive">
                Clear Global Datasets
              </Button>
            </div>
          ) : null}
          <SearchInput
            className={sprinkles({ paddingX: 'sp1' })}
            onInputChanged={setSearchQuery}
            searchQuery={searchQuery}
          />
          {renderedContent}
          {hasDatasetsToShow ? null : (
            <EmbedText
              body="b1"
              className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}
              color="contentSecondary">
              No datasets.
            </EmbedText>
          )}
          {enableDataLibraryV2
            ? filteredConvertedGlobalDatasets.map((globalDataset) => {
                return (
                  <DatasetItem
                    dataset={globalDataset}
                    infoIcon="globe"
                    isSelected={globalDataset.id === selectedDatasetInfo?.id}
                    key={globalDataset.id}
                    onClick={() => {
                      if (globalDataset.id === selectedDatasetInfo?.id) return;
                      switchSelectedDataset(globalDataset.id);
                    }}
                  />
                );
              })
            : null}
        </div>
      </EditorLeftColumn>
      <div className={styles.configMenu} style={{ width: 545, minWidth: 545 }}>
        {selectedDataset ? (
          <DatasetConfig
            dataset={selectedDataset}
            isGlobalDataset={isSelectedDatasetGlobal}
            isNew={selectedDatasetInfo?.isNew ?? false}
          />
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      <div className={styles.tableView}>
        {selectedDataset ? (
          <>
            <ColumnHeader title="Preview" />
            <DatasetPreviewTable
              colorTracker={colorCategoryTracker}
              currentQuery={selectedDataset.queryDraft ?? selectedDataset.query}
              dataset={selectedDataset}
              error={datasetData?.error}
              handlePageChange={handlePageChange}
              isLoading={!!datasetData?.loading}
              rowCount={datasetData?.rowCount}
              rows={datasetData?.rows}
              schema={schema}
              unsupportedOperations={datasetData?.unsupportedOperations}
            />
          </>
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      {isImportGlobalDatasetModalOpen ? (
        <ImportGlobalDatasetModal
          allVariableMappings={{}}
          currentDatasetNames={currentDatasetNames}
          onClose={() => setIsImportGlobalDatasetModalOpen(false)}
          onImportGlobalDataset={(computedView) => {
            const parentSchema = getEmbeddoSchemaFromView(
              RD.getOrDefault(schemas.usedParentSchemas, []),
              computedView,
            );

            if (!parentSchema) {
              return;
            }

            dispatch(
              importGlobalDataset({
                computedView,
                parentSchema,
              }),
            );
          }}
        />
      ) : null}
    </div>
  );
};
