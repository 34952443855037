import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { DatasetSchema } from '@explo/data';

import { TableDataset } from 'actions/dataSourceActions';
import { Button, Input, Select, sprinkles } from 'components/ds';
import { SchemaTableViewer } from 'components/resource/SchemaTableViewer';
import { PANEL_WIDTH } from 'constants/datasetMetadataPanelConstants';
import { FidoTableView } from 'reducers/fidoReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getSchemaTablesMap } from 'reducers/selectors';
import { createThrottleFn } from 'utils/general';

type Props = {
  selectedDatasetSchemaId: number | string | undefined;
  isReportBuilder?: boolean;

  onSelectSchema?: (schemaId: number) => void;
};

const DEFAULT_TABLE_DATASETS_TO_RENDER = 25;

const throttleFn = createThrottleFn(300);

type TableDatasetIdentifier = {
  tableName: string;
  id: string;
};

/**
 * Lets the user choose a schema from a dropdown list, and then displays the tables in that schema
 * Also provides buttons for reverting edits, previewing, saving, and formatting the SQL
 */
export const SchemaViewer: FC<Props> = ({
  selectedDatasetSchemaId,
  isReportBuilder,
  onSelectSchema,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { parentSchemas, schemaTablesMap } = useSelector(
    (state: ReduxState) => ({
      schemaTablesMap: getSchemaTablesMap(state),
      parentSchemas: getParentSchemasList(state),
    }),
    shallowEqual,
  );

  const schemaId: number | undefined = useMemo(() => {
    if (typeof selectedDatasetSchemaId === 'string') {
      return parentSchemas.find((s) => s.fido_id === String(selectedDatasetSchemaId))?.id ?? -1;
    }
    return selectedDatasetSchemaId as number | undefined;
  }, [selectedDatasetSchemaId, parentSchemas]);

  const schemaOptions = useMemo(
    () => parentSchemas.map((schema) => ({ value: String(schema.id), label: schema.name })),
    [parentSchemas],
  );

  const tableDatasets: (TableDataset | FidoTableView)[] = useMemo(() => {
    if (!schemaId || !schemaTablesMap || !selectedDatasetSchemaId) return [];

    return Object.values(
      // TODO when we clean up FIDO, we should really clean up when the embeddo ID is used vs the fido id....
      schemaTablesMap[schemaId === selectedDatasetSchemaId ? schemaId : selectedDatasetSchemaId] ??
        {},
    ).sort((a: TableDataset | FidoTableView, b) => {
      if ('table_name' in a) return a.table_name.localeCompare(b.table_name);
      return a.tableName.localeCompare(b.tableName);
    });
  }, [schemaTablesMap, schemaId, selectedDatasetSchemaId]);

  const [numTableDatasetsToRender, setNumTableDatasetsToRender] = useState(
    DEFAULT_TABLE_DATASETS_TO_RENDER,
  );
  const trimmedQuery = searchQuery.trim().toLocaleLowerCase();
  const tableDatasetIdentifierToRenderedSchemas: Map<TableDatasetIdentifier, DatasetSchema> =
    useMemo(() => {
      const tableDatasetIdentifierToRenderedSchemas: Map<TableDatasetIdentifier, DatasetSchema> =
        new Map();
      let index = 0;
      while (
        tableDatasetIdentifierToRenderedSchemas.size < numTableDatasetsToRender &&
        index < tableDatasets.length
      ) {
        const tableDataset = tableDatasets[index];
        const copiedSchema = [...tableDataset.schema];
        const filteredSchema =
          trimmedQuery.length > 0
            ? copiedSchema.filter((col) => col.name.toLowerCase().includes(trimmedQuery))
            : copiedSchema;
        filteredSchema.sort((firstSchema, secondSchema) =>
          firstSchema.name.localeCompare(secondSchema.name),
        );
        if (
          filteredSchema.length > 0 ||
          ('table_name' in tableDataset ? tableDataset.table_name : tableDataset.tableName)
            .toLowerCase()
            .includes(trimmedQuery)
        ) {
          const tableName =
            'tableName' in tableDataset
              ? (tableDataset as unknown as FidoTableView).tableName
              : tableDataset.table_name;
          tableDatasetIdentifierToRenderedSchemas.set(
            { tableName, id: String(tableDataset.id) },
            filteredSchema,
          );
        }
        index++;
      }
      return tableDatasetIdentifierToRenderedSchemas;
    }, [numTableDatasetsToRender, trimmedQuery, tableDatasets]);

  const rootStyle = isReportBuilder
    ? { maxHeight: 480 }
    : { minWidth: PANEL_WIDTH, width: PANEL_WIDTH };
  const className = isReportBuilder
    ? sprinkles({ border: 1, borderRadiusBottom: 8, backgroundColor: 'white' })
    : undefined;

  const renderedTableDatasetViewers: JSX.Element[] = useMemo(() => {
    const renderedTableDatasetViewers: JSX.Element[] = [];
    tableDatasetIdentifierToRenderedSchemas.forEach((schema, datasetIdentifier) =>
      renderedTableDatasetViewers.push(
        <SchemaTableViewer
          isInitiallyOpen={!!trimmedQuery}
          key={datasetIdentifier.id}
          schema={schema}
          tableName={datasetIdentifier.tableName}
        />,
      ),
    );

    return renderedTableDatasetViewers;
  }, [tableDatasetIdentifierToRenderedSchemas, trimmedQuery]);

  return (
    <div className={cx(rootClass, className)} style={rootStyle}>
      <div className={sprinkles({ flexItems: 'column', overflow: 'hidden' })}>
        {onSelectSchema ? (
          <Select
            className={sprinkles({ borderBottom: 1, borderColor: 'outline', padding: 'sp1.5' })}
            onChange={(value) => onSelectSchema(parseInt(value))}
            selectedValue={schemaId?.toString()}
            values={schemaOptions}
          />
        ) : null}
        <Input
          className={sprinkles({ padding: 'sp2' })}
          leftIcon="search"
          onChange={(value) => throttleFn(() => setSearchQuery(value))}
          placeholder="Search..."
          value={searchQuery}
        />
        <div className={sprinkles({ flex: 1, overflowY: 'auto' })}>
          {tableDatasetIdentifierToRenderedSchemas.size === 0 ? (
            <div className={sprinkles({ flexItems: 'centerColumn', padding: 'sp1.5' })}>
              <div className={sprinkles({ marginBottom: 'sp1', textAlign: 'center' })}>
                This schema has not been synced. Click below to sync the tables.
              </div>
              <Button to={`/sync-tables/${schemaId}`} variant="primary">
                Sync Tables
              </Button>
            </div>
          ) : (
            renderedTableDatasetViewers
          )}
          {tableDatasetIdentifierToRenderedSchemas.size < tableDatasets.length ? (
            <div className={sprinkles({ flexItems: 'center', paddingY: 'sp1' })}>
              <Button
                onClick={() => setNumTableDatasetsToRender((current) => current + 10)}
                variant="tertiary">
                Show more...
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const rootClass = sprinkles({
  flexItems: 'column',
  overflow: 'hidden',
  borderColor: 'outline',
});
