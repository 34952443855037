import { DateTime, Settings, StringUnitLength, UnitLength } from 'luxon';

import { TIME_FORMATS } from '../dateRangeTypes';
import { DateDisplayFormat } from '../display';
import { getDisplayTimezone } from '../scratch';

export const MONTH_PART_FORMAT_DICT: Record<string, UnitLength> = {
  L: 'numeric',
  LL: '2-digit',
  LLLLL: 'narrow',
  LLL: 'short',
};

export const DAY_PART_FORMAT_DICT: Record<string, StringUnitLength> = {
  ccccc: 'narrow',
  ccc: 'short',
};

// ReactDatePicker requires a specific format string, so this is a vaguely hackey
// way of creating that format string for the two formats we use for the date picker
const getFormatForDatePicker = (format: TIME_FORMATS) =>
  getLocalizedDateTimeFormatString(
    format === TIME_FORMATS['MM/DD/YYYY h:mm aa']
      ? DateTime.DATETIME_SHORT_WITH_SECONDS
      : DateTime.DATE_SHORT,
  );

export const getFormatForFidoExports = (format: DateDisplayFormat, customFormat?: string) => {
  const timeFormat =
    format === DateDisplayFormat.CUSTOM
      ? undefined != customFormat
        ? customTimeFormatToLuxonDateTimeFormat(customFormat)
        : undefined
      : exploTimeFormatToLuxonDateTimeFormat(dateDisplayFormatToExploTimeFormat(format));

  if (undefined == timeFormat || typeof timeFormat == 'string') {
    return timeFormat;
  }

  let formatString = getLocalizedDateTimeFormatString(timeFormat);

  if (
    format === DateDisplayFormat.NUMERIC_SHORT ||
    format === DateDisplayFormat.NUMERIC_SHORT_TIMESTAMP ||
    format === DateDisplayFormat.NUMERIC_LONG
  ) {
    formatString = formatString.replace(/\//g, '-');
  }
  formatString = formatString.replace(/aa/g, 'a');

  return formatString;
};

const getLocalizedDateTimeFormatString = (timeFormat: Intl.DateTimeFormatOptions) => {
  const formatToParts = Intl.DateTimeFormat(
    Settings.defaultLocale ?? 'en',
    timeFormat,
  ).formatToParts();
  const uses12HourClock = formatToParts.some((part) => part.type === 'dayPeriod');

  let formatString = '';

  for (const part of formatToParts) {
    if (part.type === 'day') {
      const dayPart = timeFormat.day === '2-digit' ? 'dd' : 'd';
      formatString = formatString + dayPart;
    } else if (part.type === 'month') {
      switch (timeFormat.month) {
        case 'long':
          formatString = formatString + 'MMMM';
          break;
        case 'short':
          formatString = formatString + 'MMM';
          break;
        default:
          formatString = formatString + 'MM';
          break;
      }
    } else if (part.type === 'year') {
      const yearPart = timeFormat.year === '2-digit' ? 'yy' : 'yyyy';
      formatString = formatString + yearPart;
    } else if (part.type === 'hour') {
      formatString = formatString + (uses12HourClock ? 'hh' : 'HH');
    } else if (part.type === 'minute') {
      formatString = formatString + 'mm';
    } else if (part.type === 'second') {
      formatString = formatString + 'ss';
    } else if (part.type === 'dayPeriod') {
      formatString = formatString + 'aa';
    } else if (part.type === 'literal') {
      if (part.value.match(/[\p{Letter}\p{Mark}]+/gu) === null) {
        formatString = formatString + part.value;
      } else {
        formatString = formatString + ' ';
      }
    } else if (part.type === 'weekday') {
      const weekdayPartSize =
        timeFormat.weekday === 'long' ? 4 : timeFormat.weekday === 'narrow' ? 5 : 1;
      formatString = formatString + 'E'.repeat(weekdayPartSize);
    }
  }

  return formatString;
};

const dateDisplayFormatToExploTimeFormat = (displayFormat: DateDisplayFormat): TIME_FORMATS => {
  switch (displayFormat) {
    case DateDisplayFormat.NUMERIC_SHORT_TIMESTAMP:
      return TIME_FORMATS['MM-dd-yy (HH:mm:ss)'];
    case DateDisplayFormat.NORMAL:
      return TIME_FORMATS['MM/DD/YYYY'];
    case DateDisplayFormat.NUMERIC_LONG:
      return TIME_FORMATS['DD-MM-YYYY'];
    case DateDisplayFormat.NUMERIC_SHORT:
      return TIME_FORMATS['MM-DD-YY'];
    case DateDisplayFormat.VERBAL_LONG:
      return TIME_FORMATS['MMMM D, YYYY'];
    case DateDisplayFormat.VERBAL_SHORT:
      return TIME_FORMATS['MMM D, YYYY'];
    default:
      return TIME_FORMATS['DD/MM/YYYY (HH:mm:ss)'];
  }
};

const customTimeFormatToLuxonDateTimeFormat = (
  format: string,
): Intl.DateTimeFormatOptions | string => {
  switch (format) {
    case 'f':
      return DateTime.DATETIME_SHORT;
    case 'ff':
      return DateTime.DATETIME_MED;
    case 'fff':
      return {
        ...DateTime.DATETIME_MED,
        month: 'long',
      };
    case 'ffff':
      return {
        ...DateTime.DATETIME_MED,
        month: 'long',
        weekday: 'long',
      };
    case 'F':
      return DateTime.DATETIME_SHORT_WITH_SECONDS;
    case 'FF':
      return DateTime.DATETIME_MED_WITH_SECONDS;
    case 'FFF':
      return {
        ...DateTime.DATETIME_MED_WITH_SECONDS,
        month: 'long',
      };
    case 'FFFF':
      return {
        ...DateTime.DATETIME_MED_WITH_SECONDS,
        month: 'long',
        weekday: 'long',
      };
    default:
      return format;
  }
};

const exploTimeFormatToLuxonDateTimeFormat = (format: TIME_FORMATS): Intl.DateTimeFormatOptions => {
  switch (format) {
    case TIME_FORMATS['MM-dd-yy (HH:mm:ss)']:
      return {
        ...DateTime.DATETIME_SHORT_WITH_SECONDS,
        year: '2-digit',
        hour: '2-digit',
        hour12: false,
        day: '2-digit',
      };
    case TIME_FORMATS['MM/DD/YYYY']:
    case TIME_FORMATS['DD-MM-YYYY']:
      return { ...DateTime.DATE_SHORT, day: '2-digit' };
    case TIME_FORMATS['MM-DD-YY']:
      return { ...DateTime.DATE_SHORT, month: '2-digit', year: '2-digit', day: '2-digit' };
    case TIME_FORMATS['MMMM D, YYYY']:
      return DateTime.DATE_FULL;
    case TIME_FORMATS['MMM D, YYYY']:
      return DateTime.DATE_MED;
    case TIME_FORMATS['DD/MM/YYYY (HH:mm:ss)']:
    default:
      return {
        ...DateTime.DATETIME_SHORT_WITH_SECONDS,
        hour: '2-digit',
        hour12: false,
        day: '2-digit',
      };
  }
};

const formatTime = (dateTime: DateTime, format: TIME_FORMATS | string) => {
  if (typeof format === 'string') {
    // really we could be doing this for all below, but this is the only format that actually uses
    // the timezone so lets just scope this to here
    return dateTime.setZone(getDisplayTimezone(), { keepLocalTime: true }).toFormat(format);
  }

  switch (format) {
    case TIME_FORMATS['Relative to Now']:
      return dateTime.toRelative() ?? 'Unsupported Format Option'; //toRelative returns null when platform doesn't support Intl.RelativeTimeFormat
    case TIME_FORMATS['h:mm:ss A']:
      return dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS);
    case TIME_FORMATS['MMM D, YYYY h:mm A']:
      return dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    case TIME_FORMATS['MMM D']:
      return dateTime.toFormat('LLL d');
    case TIME_FORMATS['HH:00 M/D']:
      return (
        dateTime.toFormat("HH':00 ") + dateTime.toLocaleString({ day: 'numeric', month: 'numeric' })
      );
    case TIME_FORMATS['MM/DD/YYYY HH:00 aa']:
      return dateTime.toLocaleString(DateTime.DATE_SHORT) + ' ' + dateTime.toFormat("HH':00 aa");
    case TIME_FORMATS['MM/DD/YYYY h:mm aa']:
      return dateTime.toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, month: '2-digit' });
    case TIME_FORMATS['MMM YYYY']:
      return dateTime.toLocaleString({ month: 'short', year: 'numeric' });
    case TIME_FORMATS.YYYY:
      return dateTime.toLocaleString({ year: 'numeric' });
    case TIME_FORMATS.ddd:
      return dateTime.toLocaleString({ weekday: 'short' });
    case TIME_FORMATS.D:
      return dateTime.toLocaleString({ day: 'numeric' });
    case TIME_FORMATS.MMM:
      return dateTime.toLocaleString({ month: 'short' });
    case TIME_FORMATS['YYYY-MM-DD']:
      return dateTime.toFormat('yyyy-LL-dd');
    case TIME_FORMATS.ha:
      return dateTime.toLocaleString({ hour: 'numeric' });
    case TIME_FORMATS['h:mm A']:
      return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
    case TIME_FORMATS['MMMM D, YYYY h:mm A']:
      return dateTime.toLocaleString({ ...DateTime.DATETIME_MED_WITH_SECONDS, month: 'long' });
    case TIME_FORMATS['Quarter']:
      return dateTime.toFormat('Qq ') + dateTime.toLocaleString({ year: 'numeric' });
    case TIME_FORMATS['MM/DD/YYYY']:
    case TIME_FORMATS['MMMM D, YYYY']:
    case TIME_FORMATS['MMM D, YYYY']:
    case TIME_FORMATS['DD/MM/YYYY (HH:mm:ss)']:
      return dateTime.toLocaleString(exploTimeFormatToLuxonDateTimeFormat(format));
    case TIME_FORMATS['MM-dd-yy (HH:mm:ss)']:
    case TIME_FORMATS['DD-MM-YYYY']:
    case TIME_FORMATS['MM-DD-YY']:
      return dateTime
        .toLocaleString(exploTimeFormatToLuxonDateTimeFormat(format))
        .replace(/\//g, '-');
    default:
      // should never get here
      return '';
  }
};

export { formatTime, getFormatForDatePicker };
