import { FC, useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { saveDraftDatasetQuery } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Button, Spinner, sprinkles } from 'components/ds';
import { SchemaViewer } from 'components/resource/SchemaViewer';
import { ReduxState } from 'reducers/rootReducer';
import { getSchemaTablesMap } from 'reducers/selectors';
import { formatQuery, getSchemaNameInfo } from 'utils/queryUtils';

import { ConfigSection } from '../ConfigSection';
import { QueryRunButtons } from '../QueryRunButtons';
import * as sharedStyles from '../index.css';

import * as styles from './index.css';

const SqlEditor = lazy(() => import(/* webpackChunkName: "SqlEditor" */ 'components/SqlEditor'));

type Props = {
  dataset: ReportBuilderDataset;
  isEditable: boolean;

  onSelectSchema?: (schemaId: number) => void;
  getPreview: () => void;
  saveQuery: () => void;
};

export const QuerySection: FC<Props> = ({
  dataset,
  isEditable,
  onSelectSchema,
  getPreview,
  saveQuery,
}) => {
  const dispatch = useDispatch();

  const { schemaTablesMap, shouldUseFido } = useSelector(
    (state: ReduxState) => ({
      schemaTablesMap: getSchemaTablesMap(state),
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const [currentQuery, setCurrentQuery] = useState(dataset.queryDraft ?? dataset.query);
  const [currDatasetId, setCurrDatasetId] = useState(dataset.id);

  useEffect(() => {
    // Update currentQuery when dataset is switched
    if (dataset.id === currDatasetId) return;
    setCurrDatasetId(dataset.id);
    setCurrentQuery(dataset.queryDraft ?? dataset.query);
  }, [dataset, currDatasetId]);

  const parentSchemaId = dataset.parent_schema_id;

  const { tableNames, columnNames } = useMemo(
    () => getSchemaNameInfo(schemaTablesMap, parentSchemaId.toString()),
    [schemaTablesMap, parentSchemaId],
  );

  const sectionTitle = useMemo(() => (isEditable ? 'Write your query' : 'Query'), [isEditable]);

  const formatSqlQuery = () => {
    const newQuery = formatQuery(currentQuery);
    setCurrentQuery(newQuery);
    dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
  };

  const onRevertDraft = () => {
    dispatch(saveDraftDatasetQuery({ queryDraft: undefined, dataset_id: dataset.id }));
    setCurrentQuery(dataset.query);
  };

  const hasChanges = currentQuery !== dataset.query || dataset.schema?.length === 0;

  return (
    <ConfigSection defaultIsOpen title={sectionTitle}>
      <div className={sharedStyles.configSection}>
        <div className={styles.editorWrapper}>
          <Suspense fallback={<Spinner fillContainer />}>
            <SqlEditor
              columnNames={columnNames}
              onChange={setCurrentQuery}
              onChangeDraft={(newQuery) => {
                dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
              }}
              query={currentQuery}
              readonly={!isEditable}
              tableNames={tableNames}
            />
          </Suspense>
        </div>
        <div className={sprinkles({ paddingX: 'sp1.5', paddingY: 'sp1' })}>
          {isEditable ? (
            <QueryRunButtons
              disableAI //TODO: remove this after SHIBA-5828 builds out AI dataset support
              onFormat={formatSqlQuery}
              onPreview={currentQuery.trim() ? getPreview : undefined}
              onRevertDraft={onRevertDraft}
              onSave={hasChanges ? saveQuery : getPreview}
              saveText={hasChanges ? 'Save & Run' : 'Run'}
              saveTooltipProps={{
                text: hasChanges ? 'Preview and save query' : 'Preview results without saving',
              }}
            />
          ) : (
            <Button onClick={getPreview}>Preview</Button>
          )}
        </div>

        <SchemaViewer
          isReportBuilder
          onSelectSchema={onSelectSchema}
          selectedDatasetSchemaId={
            shouldUseFido
              ? (dataset.namespace_id ?? dataset.parent_schema_id)
              : dataset.parent_schema_id
          }
        />
      </div>
    </ConfigSection>
  );
};
