// this needs to be kept in sync with models.ReportedAnalyticsAction
export enum ReportedAnalyticActionTypes {
  DASHBOARD_PAGE_VIEWED = 'Dashboard Page Viewed',
  SHARED_DASHBOARD_PAGE_VIEWED = 'Shared Dashboard Page Viewed',
  PORTAL_DASHBOARD_PAGE_VIEWED = 'Portal Dashboard Page Viewed',
  SHARED_CHART_VIEWS = 'Shared Chart Viewed',
  SHARE_BUTTON_CLICKED = 'Share Button Clicked',
  CSV_DOWNLOADED = 'CSV Downloaded',
  DATA_PANEL_PDF_DOWNLOADED = 'Data Panel PDF Downloaded',
  TABLE_SORTED = 'Table Sorted',
  TABLED_PAGED = 'Table Paged',
  TABLE_FILTERED = 'Table Filtered',
  DROPDOWN_SELECTED = 'Dropdown Selected',
  MULTISELECT_SELECTED = 'Multiselect Selected',
  DATEPICKER_SELECTED = 'Datepicker Selected',
  SLIDER_CHANGED = 'Slider Changed',

  PORTAL_LOG_IN_EMAIL_SENT = 'Portal Log In Email Sent',
  PORTAL_LOG_IN_EMAIL_CLICKED = 'Portal Log In Email Clicked',

  ANALYTICS_HUB_VIEWED = 'Analytics Hub Viewed',
  CUSTOMER_REPORT_OPENED = 'Customer Report Opened',
  BUILT_IN_REPORT_OPENED = 'Built In Report Opened',
  CUSTOMER_REPORT_CREATED = 'Customer Report Created',
  CUSTOMER_REPORT_COPIED = 'Customer Report Copied',
  REPORT_CADENCE_CREATED = 'Report Cadence Created',
  REPORT_EXPORTED = 'Report Exported',
  CUSTOMER_REPORT_FILTER_OPENED = 'Report Filter Opened',
  CUSTOMER_REPORT_AI_OPENED = 'Report AI Opened',
  CUSTOMER_REPORT_AI_CHART_CREATED = 'Report AI Chart Created',
  CUSTOMER_REPORT_AI_VIEW_CREATED = 'Report AI View Created',
  CUSTOMER_REPORT_AI_MESSAGE_SENT = 'Report AI Message Sent',
  CUSTOMER_REPORT_AI_DATA_QUERY_FAILED = 'Report AI Data Query Failed',

  CUSTOMER_REPORT_SAVED = 'Report Saved',
  CUSTOMER_REPORT_FAVORITED = 'Report Favorited',
  CUSTOMER_REPORT_UNFAVORITED = 'Report Unfavorited',
  CUSTOMER_REPORT_DOCS_OPENED = 'Docs Opened',
}
