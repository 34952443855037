import { IconName as BlueprintIconName } from '@blueprintjs/icons';

import {
  AggColOptional,
  AggregationType,
  BaseCol,
  BaseColOptional,
  SchemaChange as BaseSchemaChange,
  BoxPlotDataInstructions,
  CollapsibleListDataInstructions,
  DateDisplayFormat,
  GeospatialChartDataInstructions,
  GradientOptions,
  GradientShape,
  GradientType,
  GroupByBucket,
  GroupByColOptional,
  KPIDataInstructions,
  KPIPeriodInfo,
  KPITrendDataInstructions,
  NumberDisplayOptions,
  PivotTableDataInstructions,
  ScatterPlotChartDataInstructions,
  SchemaDisplayOptions,
  SortAxis,
  SortOption,
  SortOrder,
  StringFormat,
  TwoDimensionChartDataInstructions,
  VisualizeTableDataInstructions,
} from '@explo/data';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import {
  TextElemHorizAlignments,
  TitleValueArrangements,
  VerticalContentAlignments,
} from 'types/dashboardTypes';
import { ChoroplethMapFormat, DensityMapFormat, LocationMarkerMapFormat } from 'types/maps';

// TODO: Break up file into separate files in types top-level directory

export type AccessGroupDataSourcesMap = {
  [accessGroupId: number]: {
    availableDataSourceIds: number[];
    defaultDataSourceId: number | undefined;
  };
};

export type AccessGroupDataSourcesForSchemaMap = {
  [schemaId: number]: AccessGroupDataSourcesMap;
};

export type ChartColumnInfo = ComboChartFormatInfo & BaseColOptional;

// Should only be used by combo charts. Too complex to pull out right now
type ComboChartFormatInfo = {
  dataFormat?: COMBO_CHART_DATA_FORMATS;
};

export type AggedChartColumnInfo = AggColOptional & {
  // TODO there's probably a more generic solution here but this is the only way i can get data format on this type
  column: ChartColumnInfo;
  yAxisFormatId?: string;
};

export type CategoryChartColumnInfo = GroupByColOptional & {
  // Used only on FE for displaying Smart Grouping Charts
  smartBucketInfo?: SmartBucketInfo;
};

export interface SmartBucketInfo {
  startTime: number;
  endTime: number;
}

interface TimeSeriesDataFormat {
  hideLatestPeriodData?: boolean;
  zeroMissingDates?: boolean;
  zeroMissingValues?: boolean;
}

export enum ChartTypeSections {
  PERFORMANCE_INDICATOR = 'Performance Indicator',
  BAR_CHART = 'Bar Chart',
  LINE_CHART = 'Line Chart',
  AREA_CHART = 'Area Chart',
  PIE_CHART = 'Pie Chart',
  TABLE = 'Data Table',
  FUNNEL = 'Funnel',
  SPECIAL = 'SPECIAL',
  MAP = 'Map',
}

export type EmailCadenceTime = {
  hour?: number;
  minute?: number;
  // unused
  timezone?: string;
  isPm?: boolean;
};

export type UserTransformedSchema = (BaseCol & { isVisible: boolean })[];

export type SchemaChange = BaseSchemaChange & {
  keepCol: boolean; // If false, hidden and cannot be unhidden
  showTooltip?: boolean;
  tooltipText?: string;
};

// We now use luxon, so displaying updated formats
export const DateDisplayFormatToLuxon: Record<DateDisplayFormat, string> = {
  [DateDisplayFormat.NORMAL]: 'MM/dd/yyyy',
  [DateDisplayFormat.NUMERIC_SHORT]: 'MM-dd-yy',
  [DateDisplayFormat.NUMERIC_SHORT_TIMESTAMP]: 'MM-dd-yy (HH:mm:ss)',
  [DateDisplayFormat.NUMERIC_LONG]: 'dd-MM-yyyy',
  [DateDisplayFormat.DATE_TIMESTAMP]: 'dd/MM/yyyy (HH:mm:ss)',
  [DateDisplayFormat.VERBAL_SHORT]: 'Mon Day, Year',
  [DateDisplayFormat.VERBAL_LONG]: 'Month Day, Year',
  [DateDisplayFormat.RELATIVE_TO_NOW]: 'Relative to Now',
  [DateDisplayFormat.CUSTOM]: 'Custom',
};

export type TableDrilldownConfig = {
  drilldownEnabled?: boolean;
  drilldownColumn?: string;
  allColumns?: boolean;
};

export type RowLevelAction = {
  menuLabel: string;
  eventName: string;
};

export type VisualizeTableInstructions = BaseTableInstructions &
  VisualizeTableDataInstructions & {
    changeSchemaList?: SchemaChange[];
    isSchemaCustomizationEnabled?: boolean;
    drilldownConfig?: TableDrilldownConfig;
    rowLevelActionConfigs?: RowLevelAction[];
  };

export type TablePDFFormat = {
  headerEnabled?: boolean;
  leftOption?: SECTION_OPTIONS;
  leftContent?: string;
  centerOption?: SECTION_OPTIONS;
  centerContent?: string;
  rightOption?: SECTION_OPTIONS;
  rightContent?: string;
};

type CsvFormat = { tsvEnabled?: boolean };

export enum SECTION_OPTIONS {
  BLANK = 'BLANK',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export type VisualizePivotTableInstructions = PivotTableDataInstructions &
  BaseTableInstructions & {
    displaySumRow?: boolean;
    sumRowText?: string;
    sumRowBold?: boolean;
    sumRowBackgroundColor?: string;
    firstColumnTitle?: string;
    dateFormat?: string;
    stringFormat?: StringFormat;
    changeSchemaList?: SchemaChange[];
  };

export enum COLUMN_FITS {
  FILL = 'Fill',
  CELL = 'Fit cell',
  HEADER = 'Fit cell and header',
}

export const ColumnFitOptions = Object.values(COLUMN_FITS).map((fit) => ({ value: fit }));

export enum REPORT_BUILDER_COLUMN_FITS {
  DEFAULT = 'Default', // placeholder of an undefined columnFit for the select component values
  CELL = 'Fit cell',
  HEADER = 'Fit cell and header',
}

export type ReportBuilderColumnFitType =
  | REPORT_BUILDER_COLUMN_FITS.CELL
  | REPORT_BUILDER_COLUMN_FITS.HEADER
  | undefined;

export const ReportBuilderColumnFitOptions = Object.values(REPORT_BUILDER_COLUMN_FITS).map(
  (fit) => ({ value: fit }),
);

export type ColumnWidths = Record<string, number>;
export type LegacyWidths = Array<number | null | undefined>;

export type BaseTableInstructions = {
  columnFit?: COLUMN_FITS;
  columnWidths?: LegacyWidths | ColumnWidths;
  shouldTruncateText?: boolean;
  isFilteringDisabled?: boolean;
  isFooterHidden?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  disablePdfDownload?: boolean;
  rowHeight?: number;
  rowsPerPage?: number;
  isColumnSortingDisabled?: boolean;
  isInitialSortDesc?: boolean;
  defaultSortedColumn?: DefaultSortColumn | DefaultSortColumn[];
  isColumnLinesEnabled?: boolean;
  isRowLinesDisabled?: boolean;
  isColumnHeadersBolded?: boolean;
  isFirstColumnBolded?: boolean;
  isFirstColumnFrozen?: boolean;
  shouldVisuallyGroupByFirstColumn?: boolean;
  generalFormat?: V2GeneralInstructions;
  pdfFormat?: TablePDFFormat;
  csvFormat?: CsvFormat;
  schemaDisplayOptions?: SchemaDisplayOptions;
  orderedColumnNames?: string[];
  ignoreInvalidDates?: boolean;
  maxRowsPerCell?: number;
};

type BaseAxisFormat = {
  decimalPlaces?: number;
  hideAxisLabels?: boolean;
  max?: number;
  min?: number;
  numberFormat?: { id: string };
  reverseAxis?: boolean;
  showDecimals?: boolean;
  showTitle?: boolean;
  title?: string;
  useLogScale?: boolean;
};

export type ManualSortCategory = {
  category: string;
  displayName: string;
};

export type DefaultSortColumn = { column?: string; order?: SortOrder };

export type XAxisFormat = BaseAxisFormat & {
  showBarValues?: boolean;
  barCornerRadius?: number;
  hideTotalValues?: boolean;
  labelPadding?: number;
  sortOption?: SortOption;
  sortAxis?: SortAxis;
  // supporting a single column for now, but will make the data model support multiple
  sortColumns?: AggedChartColumnInfo[];
  sortManualCategoriesOrder?: ManualSortCategory[];
  dateFormat?: string;
  stringFormat?: StringFormat;
  maxCategories?: number;
  showOther?: boolean; // If maxCategories and this is true then show "Other" category
  includeOtherInSorting?: boolean;
  hideAxisTicks?: boolean;
  hideAxisLine?: boolean;
  flipAxis?: boolean;
  enableScroll?: boolean;
  rotationAngle?: number;
};

export type YAxisFormat = BaseAxisFormat & {
  id?: string;
  colorFromAggColumn?: AggedChartColumnInfo;
  oppositeAligned?: boolean;
  hideGridLines?: boolean;
  showPct?: boolean;
};

export enum LegendPosition {
  TOP = 'Top',
  BOTTOM = 'Bottom',
  LEFT = 'Left',
  RIGHT = 'Right',
  AUTO = 'Auto',
}

export type LegendFormat = {
  position?: LegendPosition;
  title?: string;
  showTitle?: boolean;
  hideLegend?: boolean;
  stringFormat?: StringFormat;
};

export type AreaChartFormat = {
  reverseGroupOrder?: boolean;
  useGradientFill?: boolean;
};

export type PieChartFormat = {
  hideChartValues?: boolean;
  showLabelsInsideSlices?: boolean;
  valuesFormat?: { id: string };
  useColorForLabel?: boolean;
  maxCategories?: number;
  pctDecimalPlaces?: number;
  trueLabel?: string;
  falseLabel?: string;
  useStandardPieSize?: boolean;
  showOtherCategory?: boolean;
} & ChartShapeBorderFormat;

export type HeatMapFormat = {
  nullMissingValues?: boolean;
  showCellLabels?: boolean;
  valueFormat?: ValueFormatOptions;
  hideZeros?: boolean;
  gradientType?: GradientType;
  gradient?: Partial<GradientShape>;
  gradientOptions?: GradientOptions;
  enableVerticalScroll?: boolean;
  verticalCellHeight?: number;
  sortedXAxis?: string[];
  sortedYAxis?: string[];
};

export type FunnelChartFormat = {
  hideChartValues?: boolean;
  useStageForLabel?: boolean;
  isVertical?: boolean;
  sortedStages?: Array<string>;
};

export type BarFunnelChartFormat = {
  lostLabel?: string;
  wonLabel?: string;
  entityName?: string;
  isNotCumulative?: boolean;
  minBarWidth?: number;
};

export enum LineElasticity {
  CURVED = 'Curved',
  STRAIGHT = 'Straight',
}

export type LineChartFormat = {
  elasticity?: LineElasticity;
  hideMarkers?: boolean;
  lineWidth?: number;
  lineType?: PlotLineType;
};

export enum ColorPalette {
  DEFAULT = 'Default',
  GREEN = 'Green',
  BLUE = 'Blue',
  CUSTOM = 'Custom Palette',
}

export enum ColorPaletteV2 {
  CATEGORICAL = 'Categorical',
  DIVERGING = 'Diverging',
  GRADIENT = 'Gradient',
}

export type ColorZone = {
  zoneThreshold?: string;
  zoneColor: string;
  zoneId: string;
};

export type ColorFormat = {
  selectedPalette?: ColorPalette | ColorPaletteV2;
  customColors?: string;
  useZones?: boolean;
  colorZones?: ColorZone[];
};

export enum CustomStylesCornerRadiusCategories {
  BUTTON = 'buttons',
  INPUT_FIELDS = 'inputFields',
}

export enum SpiderChartShape {
  ROUND = 'Round',
  POLYGON = 'Polygon',
}

export type SpiderChartFormat = {
  spiderChartShape?: SpiderChartShape;
  lineWidth?: number;
};

export type ChartShapeBorderFormat = {
  borderWidth?: number;
  borderColor?: string;
};

export type SankeyChartFormat = {
  units?: string;
  numberFormat?: string;
  decimalPlaces?: number;
  multiplier?: number;
  showValues?: boolean;
  showPercentages?: boolean;
  showPctOnTooltips?: boolean;
  showPctOnChart?: boolean;
};

export type ChartSpecificFormat = {
  barChart?: ChartShapeBorderFormat;
  pieChart?: PieChartFormat;
  areaChart?: AreaChartFormat;
  heatMap?: HeatMapFormat;
  funnelChart?: FunnelChartFormat;
  barFunnelChart?: BarFunnelChartFormat;
  lineChart?: LineChartFormat;
  timeSeriesDataFormat?: TimeSeriesDataFormat;
  spiderChart?: SpiderChartFormat;
  choroplethMap?: ChoroplethMapFormat;
  sankeyChart?: SankeyChartFormat;
};

export enum PlotLineType {
  SOLID = 'Solid',
  SHORT_DASH = 'ShortDash',
  SHORT_DOT = 'ShortDot',
  DOT = 'Dot',
  DASH = 'Dash',
  LONG_DASH = 'LongDash',
}

export type GoalLineFormat = {
  showGoalLine?: boolean;
  goalValue?: string;
  goalValueMax?: string;
  isGoalBand?: boolean;
  lineType?: PlotLineType;
  lineColor?: string;
  lineWidth?: number;
  label?: string;
  labelColor?: string;
  isXAxisGoal?: boolean;
  // If chart has multiple y axes can assign to one so it doesn't repeat
  yAxisId?: string;
};

type TooltipFormat = {
  showPct?: boolean;
  showSelectedOnly?: boolean;
  showTotal?: boolean;
};

export enum ColorFilterPlacement {
  RIGHT_CORNER = 'rightCorner',
  BELOW_TITLE = 'belowTitle',
}

export interface ColorColumnOption {
  column: BaseCol;
  bucket?: GroupByBucket;
  selected?: boolean;
}

export type VisualizeGeospatialChartInstructions = GeospatialChartDataInstructions & {
  rowLimit?: number;
  mapFormat?: LocationMarkerMapFormat;
  densityMapFormat?: DensityMapFormat;
  generalFormat?: V2GeneralInstructions;
};

export type V2TwoDimensionChartInstructions = TwoDimensionChartDataInstructions & {
  colorFilterPlacement?: ColorFilterPlacement;
  defaultColorGroupingOff?: boolean;
  yAxisFormats?: YAxisFormat[];
  legendFormat?: LegendFormat;
  generalFormat?: V2GeneralInstructions;
  tooltipFormat?: TooltipFormat;
  colorFormat?: ColorFormat;
  chartSpecificFormat?: ChartSpecificFormat;
  drilldown?: DrilldownConfig;
  xAxisFormat?: XAxisFormat;

  aggColumns?: AggedChartColumnInfo[];
} & GoalLineChartConfig;

type DrilldownConfig = {
  categorySelectEnabled?: boolean;
};

export type KPIGeneralFormat = V2GeneralInstructions & {
  subtitle?: string;
  alignment?: TextElemHorizAlignments;
  vertical_content_alignment?: VerticalContentAlignments;
  title_value_arrangement?: TitleValueArrangements;
  titleColor?: string;
  backgroundColor?: string;
  opacity?: number;
};

export type KPIValueFormat = {
  units?: string;
  emptyText?: string;
  numberFormat?: { id: string };
  multiplyFactor?: number;
  decimalPlaces?: number;
  // can be a dashboard variable
  progressGoal?: string | number;
  progressHideGoal?: boolean;
  progressShowPct?: boolean;
  pctDecimalPlaces?: number;
  progressBarColor?: string;
  bold?: boolean;
  italic?: boolean;
  timeFormat?: { id: string };
  timeCustomerFormat?: string;
  customDurationFormat?: string;
  horizAlignment?: TextElemHorizAlignments;
  imageUrl?: string;
  significantDigits?: number;
  unitPadding?: boolean;
};

export enum ColorSettings {
  CONSTANT = 'constant',
  CONDITIONAL = 'conditional',
}

export type KPIColorFormat = {
  colorSettingType?: ColorSettings;
  constantColor?: string;
  conditionalTriggerValue?: string;
  conditionalPositiveColor?: string;
  conditionalNegativeColor?: string;
  applyColorToProgressGoal?: boolean;
};

export type KPITitleFormat = {
  horizAlignment?: TextElemHorizAlignments;
  fontSize?: number;
};

export type V2KPIChartInstructions = KPIDataInstructions & {
  aggColumn?: AggedChartColumnInfo;
  generalFormat?: KPIGeneralFormat;
  valueFormat?: KPIValueFormat;
  colorFormat?: KPIColorFormat;
  actionConfig?: KPIActionConfig;
  titleFormat?: KPITitleFormat;
};

export type KPIActionConfig = {
  selectionEnabled?: boolean;
};

export type LinkFormat = {
  link?: boolean;
  url?: string;
  openInNewTab?: boolean;
};

export type V2GeneralInstructions = {
  showTooltip?: boolean;
  tooltipText?: string;
};

export type BoxPlotFormat = {
  seriesLabelByColumn?: { [columnName: string]: string };
  isVertical?: boolean;
  hideWhisker?: boolean;
  fillColorByColumn?: { [columnName: string]: string };
  medianColorByColumn?: { [columnName: string]: string };
};

export type V2BoxPlotInstructions = BoxPlotDataInstructions & {
  xAxisFormat?: XAxisFormat;
  yAxisFormat?: YAxisFormat;
  legendFormat?: LegendFormat;
  boxPlotFormat?: BoxPlotFormat;
  generalFormat?: V2GeneralInstructions;
  sortedStages?: string[];
} & GoalLineChartConfig;

export type ScatterPlotFormat = {
  radius?: number;
  seriesLabel?: string;
  useJitter?: boolean;
};

export type V2ScatterPlotInstructions = ScatterPlotChartDataInstructions & {
  generalFormat?: V2GeneralInstructions;
  colorFormat?: ColorFormat;
  xAxisFormat?: XAxisFormat;
  yAxisFormat?: YAxisFormat;
  legendFormat?: LegendFormat;
  scatterPlotFormat?: ScatterPlotFormat;
} & GoalLineChartConfig;

export type GoalLineChartConfig = {
  goalLines?: GoalLineFormat[];
};

export type KPIPeriodColumnInfo = {
  column: ChartColumnInfo;
} & KPIPeriodInfo;

export interface KPITrendDisplayFormat {
  comparisonColor?: string;
  showAbsoluteChange?: boolean;
  showTrendChangePeriodLabel?: boolean;
  periodColor?: string;
  trendColorsReversed?: boolean;
  useTrendTag?: boolean;
  trendDecimalPlaces?: number;
  showPreviousValue?: boolean;
}

export type V2KPITrendInstructions = KPITrendDataInstructions & {
  trendGroupingElementId?: string;
  valueFormat?: KPIValueFormat;
  titleFormat?: KPITitleFormat;
  displayFormat?: KPITrendDisplayFormat;
  generalFormat?: V2GeneralInstructions;
  textOnlyFormat?: V2KPITrendTextOnlyInstructions;
  // TODO(zifanxiang): Remove this after the database migration and all clients have been reloaded.
  hideTrendLines?: boolean;
};

export type V2KPITrendTextOnlyInstructions = {
  subtitle?: string;
  verticalContentAlignment?: VerticalContentAlignments;
};

// New instruction keys need to be made optional/nullable
// because all existing data panel templates won't have them present
// when the new instruction is added
export type VisualizeOperationInstructions = {
  VISUALIZE_TABLE: VisualizeTableInstructions;
  V2_TWO_DIMENSION_CHART?: V2TwoDimensionChartInstructions;
  V2_KPI?: V2KPIChartInstructions;
  V2_BOX_PLOT?: V2BoxPlotInstructions;
  V2_KPI_TREND?: V2KPITrendInstructions;
  V2_SCATTER_PLOT?: V2ScatterPlotInstructions;
  VISUALIZE_PIVOT_TABLE?: VisualizePivotTableInstructions;
  VISUALIZE_COLLAPSIBLE_LIST?: VisualizeCollapsibleListInstructions;
  VISUALIZE_PIVOT_TABLE_V2?: V2PivotTableInstructions;
  VISUALIZE_GEOSPATIAL_CHART?: VisualizeGeospatialChartInstructions;
};

// Group By Types

export type PivotOperationInstructions = {
  aggregations: PivotOperationAggregation[];
};

export interface SelectedDropdownInputItem {
  name: string;
  id: string;
  icon?: BlueprintIconName | JSX.Element;
  value?: string | number;
  isDivider?: boolean;
  isTitle?: boolean;
  secondaryText?: string;
}

export type PivotOperationAggregation = {
  type: AggregationType | null;
  aggedOnColumn: BaseCol | null;
  additionalCol?: BaseCol;
  aggLabel?: string;
};

export const PAGE_EVENTS = new Set([
  ReportedAnalyticActionTypes.DASHBOARD_PAGE_VIEWED,
  ReportedAnalyticActionTypes.SHARED_DASHBOARD_PAGE_VIEWED,
  ReportedAnalyticActionTypes.PORTAL_DASHBOARD_PAGE_VIEWED,
  ReportedAnalyticActionTypes.ANALYTICS_HUB_VIEWED,
]);

export interface SupportedLocale {
  name: string;
  localeCode: string;
}

export interface SupportedCurrency {
  name: string;
  currencyCode: string;
}

export interface NumberDefinition {
  decimal: string;
  thousands: string;
  grouping: number[];
  percent?: string;
}

export const DEFAULT_NO_DATA_FONT_SIZE = 36;

export interface NoDataConfig {
  hideChartIcon?: boolean;
  noDataText?: string;
  noDataFontSize?: number;
  isZeroNoData?: boolean;
  hideFilterAndDownloadButtons?: boolean;
}

export interface HeaderConfig {
  isHeaderHidden?: boolean;
  title?: string;
}

export enum CustomMenuOptionType {
  CUSTOM_JS_EVENT = 'Custom JS Event',
}

export interface CustomMenuOptionConfig {
  name: string;
  type: CustomMenuOptionType;
  customJSEventName?: string;
}

export interface CustomMenuConfig {
  enabled?: boolean;
  menuOptions?: CustomMenuOptionConfig[];
}

export interface ExportConfig {
  // This disables all exports
  isDownloadButtonHidden?: boolean;
  downloadFileName?: string;
  rawDataDownloadFileName?: string;
  // This disables downloads to browser
  disableDownloadExport?: boolean;
  // This enables exporting to an email
  enableEmailExport?: boolean;

  disableCsvExport?: boolean;
  disableXlsxExport?: boolean;
  disableImageExport?: boolean;
  csvFormat?: CsvFormat;

  // PDF is only for tables
  disablePdfDownload?: boolean;
  pdfFormat?: TablePDFFormat;
}

export interface VisualizeOperationGeneralFormatOptions {
  noDataState?: NoDataConfig;
  headerConfig?: HeaderConfig;
  linkFormat?: LinkFormat;
  enableRawDataDrilldown?: boolean;
  export?: ExportConfig;
  customMenu?: CustomMenuConfig;
}

export interface DropdownOption {
  id: string;
  value: string | number;
  name: string;
}

type CategoryColumnTitle = {
  showTitle?: boolean;
  title?: string;
};

export type VisualizeCollapsibleListInstructions = CollapsibleListDataInstructions & {
  showCategories?: boolean;
  hideFooter?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  generalFormat?: V2GeneralInstructions;
  numberDisplayOptions?: Record<string, NumberDisplayOptions>;
  groupByDisplayOptions?: SchemaDisplayOptions;
  isSortingDisabled?: boolean;
  sortColumns?: Record<string, DefaultSortColumn>;
  categoryColumnTitle?: CategoryColumnTitle;
  orderedColumnNames?: string[];
  rowHeight?: number;
  sortedStages?: string[];
};

export type ValueFormatOptions = {
  numberFormat?: { id: string };
  showDecimals?: boolean;
  decimalPlaces?: number;
};

export type Total = {
  name: string;
  category?: string;
  total?: number;
};

// For charts, if the palette is "Custom Palette", the key is the Data Panel ID since we don't want to share custom palettes between charts
// Otherwise, the key is the palette name
// For tables, the key is Data Panel ID since tables do NOT share palettes
// For Report Builder, the key is the dataset ID since colors are configured on the dataset
export type ColorCategoryTracker = Partial<Record<string, ColumnColorTracker>>;
export type ColumnColorTracker = Partial<Record<string, CategoryToColor>>; // Key is column name
export type CategoryToColor = Partial<Record<string, string>>; // Key is value name. Value is color

export enum INPUT_TYPE {
  MANUAL = 'MANUAL',
  QUERY = 'QUERY',
}

export const INPUT_TYPES: Record<INPUT_TYPE, { id: INPUT_TYPE; name: string }> = {
  MANUAL: { id: INPUT_TYPE.MANUAL, name: 'Manual' },
  QUERY: { id: INPUT_TYPE.QUERY, name: 'Query' },
};

export enum AIChatErrors {
  MAX_MESSAGES_ERROR = 'MAX_MESSAGES_ERROR',
}

export enum DrilldownColumnType {
  // The primary column for a data panel. E.g. the x axis column in a two dimensional chart.
  PRIMARY = 'PRIMARY',
  // Secondary breakdown column for a data panel. E.g. the group by column for a grouped bar
  // chart.
  SECONDARY = 'SECONDARY',
  // Tertiary breakdown column for a data panel. E.g. the group by column for a stacked grouped
  // bar chart (for the stacked portion)
  TERTIARY = 'TERTIARY',
}

// NOTE: Keep in sync with the column types above.
export const DrilldownColumnIndexToTypeMap: Record<number, DrilldownColumnType> = {
  0: DrilldownColumnType.PRIMARY,
  1: DrilldownColumnType.SECONDARY,
  2: DrilldownColumnType.TERTIARY,
};

export const DrilldownColumnTypeToIndexMap: Record<DrilldownColumnType, number> = {
  [DrilldownColumnType.PRIMARY]: 0,
  [DrilldownColumnType.SECONDARY]: 1,
  [DrilldownColumnType.TERTIARY]: 2,
};
