import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { VIEW_MODE } from 'types/dashboardTypes';

type MatchParams = {
  firstParam: string;
  secondParam?: string;
  thirdParam?: string;
};

type Props = RouteComponentProps<MatchParams>;
class IFrameDashboardPage extends Component<Props> {
  render() {
    const { match } = this.props;

    let customToken: string | undefined = undefined;
    let dashboardEmbedId: string | undefined = undefined;
    let embedJwt: string | undefined = undefined;
    let environment: string | undefined = undefined;

    // the min length for an embed token is 10, but there's no max length so
    // going a little above for safety
    if (match.params.firstParam.length < 16) {
      dashboardEmbedId = match.params.firstParam;
      customToken = match.params.secondParam;
      environment = match.params.thirdParam;
    } else {
      embedJwt = match.params.firstParam;
      environment = match.params.secondParam;
    }

    return (
      <EmbeddedDashboard
        updateUrlParams
        customerToken={customToken}
        dashboardEmbedId={dashboardEmbedId ?? ''}
        embedJwt={embedJwt}
        embedType={EmbeddedDashboardType.IFRAME}
        environment={environment}
        isStrict={match.url.toUpperCase().endsWith('STRICT')}
        viewMode={VIEW_MODE.DEFAULT}
      />
    );
  }
}

export default withRouter(IFrameDashboardPage);
