import { FC, ReactNode, useCallback, useState } from 'react';

import { Icon, IconButton, Menu, MenuActionItem, sprinkles, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';
import { showSuccessToast } from 'shared/sharedToasts';

import * as styles from './styles.css';

const COPY_ICON: IconName = 'clipboard-reg';
const COMPLETE_ICON: IconName = 'check';

type Props = {
  name: string;
  suffix?: string; // This will be italicized and added to the name
  leftIcon: IconName;
  copiable?: boolean;
  rightElement?: ReactNode;
  leftIconTooltipText?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  hasError?: boolean;
};

enum CopyStatus {
  IDLE,
  HIGHLIGHTED,
  COMPLETE,
}

export const PanelListItem: FC<Props> = ({
  leftIcon,
  name,
  copiable,
  rightElement,
  onEdit,
  onDelete,
  suffix,
  hasError,
  leftIconTooltipText,
}) => {
  const [copyStatus, setCopyStatus] = useState(CopyStatus.IDLE);

  const handleClick = useCallback(() => {
    if (!copiable) return;
    navigator.clipboard.writeText(name);
    setCopyStatus(CopyStatus.COMPLETE);
    showSuccessToast(`"${name}" copied to clipboard`);
  }, [copiable, name]);

  const renderMenu = () => {
    if (!onEdit && !onDelete) return;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
          {onEdit ? <MenuActionItem onSelect={onEdit} text="Edit" /> : null}
          {onDelete ? <MenuActionItem isDestructive onSelect={onDelete} text="Delete" /> : null}
        </Menu>
      </div>
    );
  };

  const getLeftIcon = useCallback((): IconName => {
    if (!copiable) return leftIcon;
    switch (copyStatus) {
      case CopyStatus.IDLE:
        return leftIcon;
      case CopyStatus.HIGHLIGHTED:
        return COPY_ICON;
      case CopyStatus.COMPLETE:
        return COMPLETE_ICON;
    }
  }, [copyStatus, leftIcon, copiable]);

  return (
    <div
      className={
        hasError
          ? styles.errorVariableListItem
          : copiable
            ? styles.copiableVariableListItem
            : styles.variableListItem
      }
      onClick={handleClick}
      onMouseEnter={() => (copiable ? setCopyStatus(CopyStatus.HIGHLIGHTED) : null)}
      onMouseLeave={() => (copiable ? setCopyStatus(CopyStatus.IDLE) : null)}>
      {leftIconTooltipText ? (
        <PanelItemDetails
          hasError={hasError}
          leftIcon={getLeftIcon()}
          leftIconTooltipText={leftIconTooltipText}
          name={name}
          suffix={suffix}
        />
      ) : (
        <Tooltip
          delayDuration={DEFAULT_DELAY}
          text={`${copiable ? 'Click to copy ' : ''}${name}${suffix || ''}`}>
          <div>
            <PanelItemDetails
              hasError={hasError}
              leftIcon={getLeftIcon()}
              leftIconTooltipText={leftIconTooltipText}
              name={name}
              suffix={suffix}
            />
          </div>
        </Tooltip>
      )}
      {rightElement ? (
        <div className={styles.variableListItemRightElement}>
          {typeof rightElement === 'string' ? (
            <Tooltip delayDuration={DEFAULT_DELAY} text={rightElement}>
              <span
                className={sprinkles({ color: 'contentSecondary', truncateText: 'ellipsis' })}
                style={{ maxWidth: 200 }}>
                {rightElement}
              </span>
            </Tooltip>
          ) : (
            rightElement
          )}
          {renderMenu()}
        </div>
      ) : null}
    </div>
  );
};

interface PanelItemDetailsProps {
  name: string;
  leftIcon: IconName;
  suffix?: string;
  leftIconTooltipText?: string;
  hasError?: boolean;
}

const PanelItemDetails: FC<PanelItemDetailsProps> = ({
  name,
  leftIcon,
  suffix,
  leftIconTooltipText,
  hasError,
}) => {
  return (
    <div className={styles.variableListItemIconName}>
      {leftIconTooltipText ? (
        <Tooltip text={leftIconTooltipText}>
          <Icon
            className={!hasError ? styles.variableIcon : styles.errorVaribleIcon}
            name={leftIcon}
            size="md"
          />
        </Tooltip>
      ) : (
        <Icon
          className={!hasError ? styles.variableIcon : styles.errorVaribleIcon}
          name={leftIcon}
          size="md"
        />
      )}
      <span className={styles.variableName}>
        {name}
        {suffix ? <i>{suffix}</i> : null}
      </span>
    </div>
  );
};
