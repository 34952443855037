import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { Switch, Input, Label, sprinkles } from 'components/ds';
import { KPIValueFormat, V2KPIChartInstructions } from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  instructions: V2KPIChartInstructions;
  visualizationType: OPERATION_TYPES.VISUALIZE_PROGRESS_V2;
};

export const KPIProgressBarConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  if (!globalStyleConfig) return null;

  const updateFormat = (valueFormat: KPIValueFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.valueFormat = { ...draft.valueFormat, ...valueFormat };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const valueFormat = instructions.valueFormat;

  return (
    <div
      className={sprinkles({
        paddingX: 'sp1.5',
        paddingY: 'sp1',
        flexItems: 'column',
        gap: 'sp1',
      })}>
      <Input
        showInputButton
        defaultValue={String(valueFormat?.progressGoal ?? '')}
        label={{ text: 'Progress Goal', variableInput: true }}
        onSubmit={(newValue) => updateFormat({ progressGoal: newValue })}
      />
      <Switch
        label="Hide Goal"
        onChange={() => updateFormat({ progressHideGoal: !valueFormat?.progressHideGoal })}
        switchOn={valueFormat?.progressHideGoal}
      />

      <Switch
        label="Show Percent"
        onChange={() => updateFormat({ progressShowPct: !valueFormat?.progressShowPct })}
        switchOn={valueFormat?.progressShowPct}
      />

      {valueFormat?.progressShowPct ? (
        <Input
          showInputButton
          defaultValue={String(valueFormat?.pctDecimalPlaces ?? '2')}
          label="Percent Decimal Places"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            updateFormat({ pctDecimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}

      <Label htmlFor="">Progress Bar Color</Label>
      <ColorPickerButton
        fillWidth
        color={valueFormat?.progressBarColor || getCategoricalColors(globalStyleConfig)[0]}
        colorPalette={getCategoricalColors(globalStyleConfig)}
        onColorChange={(hex) => updateFormat({ progressBarColor: hex })}
      />
    </div>
  );
};
