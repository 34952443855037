import { Branch, Commit } from '@explo-tech/fido-api';
import { Team } from 'actions/teamActions';
import { TeamMember } from 'actions/userActions';
import { Modal, sprinkles, Tag } from 'components/ds';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import {
  changeComment,
  versionPanelNameAndTags,
  versionPanelRoot,
} from 'components/VersionControlModal/index.css';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listCommitsThunk } from 'reducers/thunks/fidoThunks/commitThunks';

import { isIdle, isSuccess, ResponseData } from 'remotedata';
import { getCreatorDetailMessage } from 'utils/versionControlUtil';

interface Props {
  isOpen: boolean;
  currentBranch: Branch;
  branchCommits: ResponseData<Commit[]>;
  teamData: Team | undefined;

  onClose: () => void;
}

export const CommitHistoryModal: FC<Props> = ({
  isOpen,
  currentBranch,
  branchCommits,
  teamData,
  onClose,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isIdle(branchCommits) && isOpen) {
      dispatch(listCommitsThunk(currentBranch.id ?? ''));
    }
  }, [dispatch, branchCommits, isOpen, currentBranch.id]);

  // TODO(zifanxiang): Add in the user that published the commit.
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xlarge" title="Commit history">
      {isSuccess(branchCommits) && teamData ? (
        branchCommits.data.length > 0 ? (
          <div>
            {branchCommits.data.map((commit, index) => (
              <CommitHistoryItem
                commit={commit}
                creationUser={teamData.team_members.find((member) => member.id === commit.userId)}
                key={commit.id}
                versionNumber={branchCommits.data.length - index}
              />
            ))}
          </div>
        ) : (
          <div className={sprinkles({ flexItems: 'center', height: 'fill' })}>
            <div>No commits found</div>
          </div>
        )
      ) : (
        <ExploLoadingSpinner />
      )}
    </Modal>
  );
};

interface CommitHistoryItemProps {
  commit: Commit;
  versionNumber: number;
  creationUser: TeamMember | undefined;
}

const CommitHistoryItem: FC<CommitHistoryItemProps> = ({ commit, versionNumber, creationUser }) => {
  return (
    <div className={sprinkles({ marginX: 'sp2' })}>
      <div className={versionPanelRoot}>
        <div>
          <div className={versionPanelNameAndTags}>
            <div>{`Commit ${versionNumber}`}</div>
            {commit.createdAt ? (
              <Tag
                className={sprinkles({
                  marginX: 'sp1',
                })}>
                {getCreatorDetailMessage(creationUser, commit.createdAt)}
              </Tag>
            ) : null}
          </div>
          <div className={changeComment}>{commit.commitMessage}</div>
        </div>
      </div>
    </div>
  );
};
