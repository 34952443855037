import produce from 'immer';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperationThunk } from 'actions/dataPanelConfigActions';
import { sprinkles, Input } from 'components/ds';
import { RowLevelAction, VisualizeTableInstructions } from 'constants/types';

import { CardConfig } from './CardConfig';
import { MultiCardConfig } from './MultiCardConfig';

type Props = {
  schema: DatasetSchema;
  instructions: VisualizeTableInstructions;
};

export const RowLevelActionsConfig: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();

  const [defaultOpenCardIdx, setDefaultOpenCardIdx] = useState<number>();

  const rowActions = instructions.rowLevelActionConfigs ?? [];

  const updateInstructions = (updateFunc: (draft: VisualizeTableInstructions) => void) => {
    const newInstructions = produce(instructions, (draft) => {
      updateFunc(draft);
    });
    dispatch(updateVisualizeOperationThunk(newInstructions, OPERATION_TYPES.VISUALIZE_TABLE));
  };

  const onAdd = () => {
    setDefaultOpenCardIdx(rowActions.length);
    updateInstructions((draft) => {
      draft.rowLevelActionConfigs = draft.rowLevelActionConfigs ?? [];
      draft.rowLevelActionConfigs.push({ eventName: 'action', menuLabel: 'Action' });
    });
  };

  const onDelete = (index: number) =>
    updateInstructions((draft) => {
      draft.rowLevelActionConfigs = draft.rowLevelActionConfigs ?? [];
      draft.rowLevelActionConfigs.splice(index, 1);
    });

  const updateSelectedRowAction = (rowAction: Partial<RowLevelAction>, index: number) =>
    updateInstructions((draft) => {
      draft.rowLevelActionConfigs = draft.rowLevelActionConfigs ?? [];
      draft.rowLevelActionConfigs[index] = {
        ...draft.rowLevelActionConfigs[index],
        ...rowAction,
      };
    });

  return (
    <MultiCardConfig
      adderText="Create new menu item"
      defaultEnabled={rowActions.length > 0}
      onAdd={onAdd}
      onDisable={() => updateInstructions((draft) => (draft.rowLevelActionConfigs = undefined))}
      title="Row Level Actions">
      {rowActions.map((action, index) => {
        const menuLabel = action.menuLabel;
        const eventName = action.eventName;

        const title = (
          <div className={sprinkles({ truncateText: 'ellipsis' })} title={menuLabel ?? ''}>
            {menuLabel}
          </div>
        );

        const body = (
          <div className={sprinkles({ flexItems: 'column' })}>
            <div className={sprinkles({ backgroundColor: 'outline' })} style={{ height: 1 }} />
            <div className={sprinkles({ padding: 'sp2', flexItems: 'column', gap: 'sp2' })}>
              <Input
                className={sprinkles({ color: 'contentSecondary' })}
                defaultValue={menuLabel}
                descriptiveText="This is the label your customers will see."
                label="Menu label"
                onSubmit={(value) => updateSelectedRowAction({ menuLabel: value }, index)}
              />
              <Input
                className={sprinkles({ color: 'contentSecondary' })}
                defaultValue={eventName}
                descriptiveText="This is the event name we'll send to your application."
                label="Javascript event name"
                onSubmit={(value) => updateSelectedRowAction({ eventName: value }, index)}
              />
            </div>
          </div>
        );
        return (
          <CardConfig
            defaultOpen={index === defaultOpenCardIdx}
            key={`${menuLabel}-${index}`}
            onDelete={() => onDelete(index)}
            title={title}>
            {body}
          </CardConfig>
        );
      })}
    </MultiCardConfig>
  );
};
