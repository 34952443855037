import { DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { V2KPIChartInstructions } from 'constants/types';

import KPIAggregation from '../KPIAggregation';

type Props = {
  instructions: V2KPIChartInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function KPIVizConfig({ instructions, chartType, loading, schema }: Props) {
  return (
    <KPIAggregation
      chartType={chartType}
      instructions={instructions}
      loading={loading}
      schema={schema}
    />
  );
}
