import { ComputedView } from '@explo-tech/fido-api';
import { CustomerEditableSectionLayout } from 'actions/dashboardActions';
import { ReduxState } from 'reducers/rootReducer';
import {
  getAppDashboardVersionConfigWithDrilldowns,
  getEditableDatasets,
  getEmbeddedDashboardVersionWithDrilldowns,
} from 'reducers/selectors';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import {
  DashboardVersionConfig,
  EditableSectionChart,
  GlobalDatasetVariableNameMap,
} from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import {
  filterHiddenElements,
  filterHiddenPanels,
  getDataPanelsDependentOnVariable,
} from 'utils/variableUtils';

import { Dataset } from 'actions/datasetActions';
import { convertReferencesToComputedViewsMap } from 'pages/dataLibraryPage/computedViewAdapter';

import * as RD from 'remotedata';

export type DashboardConfig = {
  dataPanels: Record<string, DataPanel>;
  datasets: Record<string, Dataset>;
  elements: DashboardElement[];
  referencedGlobalDatasets: Record<string, ComputedView>;
  variableMappings: Record<string, GlobalDatasetVariableNameMap>;

  // Sometimes they need to be accessed even if not in layout when editing
  editableSectionCharts?: Record<string, EditableSectionChart>;
};

/*
 * This function gets things needed for all the requests like data panels, datasets, dashboard elements
 * Each type of embed has it in different places so this is where the logic of where to get it is at
 * Once we remove architect hopefully we can find a way to make this more efficient
 */
export const getDashboardConfig = (state: ReduxState): DashboardConfig | undefined => {
  const { type } = state.dashboardLayout.requestInfo;
  // TODO - FIDO add config.views for embed

  // Edit dashboard page
  if (type === 'app') {
    const config = getAppDashboardVersionConfigWithDrilldowns(state);
    if (!config) return;

    const editableSectionDps = getEditableSectionDps(
      config,
      state.dashboardEditConfig.editableSectionLayout,
      state.dashboardInteractions.interactionsInfo.isEditing,
    );
    const dataPanels = editableSectionDps
      ? { ...config.data_panels, ...editableSectionDps }
      : config.data_panels;

    return {
      dataPanels,
      elements: Object.values(config.elements),
      datasets: getEditableDatasets(state),
      editableSectionCharts: config.editable_section?.enabled
        ? config.editable_section.charts
        : undefined,
      referencedGlobalDatasets: RD.getOrDefault(
        state.dashboardEditConfig.referencedGlobalDatasets,
        {},
      ),
      variableMappings: config.variable_mappings ?? {},
    };
    // Embedded dashboard
  } else if (type === 'embedded') {
    const embeddedDashboardVersionConfig =
      getEmbeddedDashboardVersionWithDrilldowns(state)?.configuration;
    const globalDatasetReferences =
      embeddedDashboardVersionConfig?.versioned_computed_view_references ?? {};

    return getEmbedDashboardConfig(
      state.embedDashboard?.dashboardVersion?.configuration,
      embeddedDashboardVersionConfig,
      state.embedDashboard.hiddenElements,
      state.embedDashboard.currentEditableSectionLayout,
      convertReferencesToComputedViewsMap(globalDatasetReferences),
    );
  }
};

export const getEmbedDashboardConfig = (
  rootConfig: DashboardVersionConfig | undefined,
  config: DashboardVersionConfig | undefined,
  hiddenElements: string[],
  layout: CustomerEditableSectionLayout[] | undefined,
  referencedGlobalDatasets: Record<string, ComputedView>,
): DashboardConfig | undefined => {
  if (!config || !rootConfig) return;
  const hiddenElementSet = new Set(hiddenElements);
  const editableSectionDps = getEditableSectionDpsEmbed(rootConfig, layout);
  const dataPanels = filterHiddenPanels(config.data_panels, hiddenElementSet);

  return {
    dataPanels: editableSectionDps ? { ...dataPanels, ...editableSectionDps } : dataPanels,
    elements: filterHiddenElements(config.elements, hiddenElementSet),
    datasets: rootConfig.datasets,
    referencedGlobalDatasets,
    variableMappings: config.variable_mappings ?? {},
  };
};

export const getEditableSectionDps = (
  { editable_section: config }: DashboardVersionConfig,
  previewLayout: CustomerEditableSectionLayout[] | null,
  isEditing: boolean,
) => {
  if (!config?.enabled) return;

  const layout = isEditing ? config.default_layout : (previewLayout ?? config.default_layout);
  return getEditableSectionDpsHelper(config.charts, layout);
};

const getEditableSectionDpsEmbed = (
  { editable_section: config }: DashboardVersionConfig,
  layout: CustomerEditableSectionLayout[] | undefined,
) => {
  if (!config?.enabled) return;
  return getEditableSectionDpsHelper(config.charts, layout);
};

export const getEditableSectionDataPanel = (
  chartId: string,
  dataPanel: DataPanelTemplate,
): DataPanelTemplate => ({
  ...dataPanel,
  id: chartId,
});

/**
 * Derives a list of data panels required by the charts in an Editable Section
 * These data panels are used to fetch data and inject variables
 */
const getEditableSectionDpsHelper = (
  charts: Record<string, EditableSectionChart>,
  layout: CustomerEditableSectionLayout[] | undefined,
): Record<string, DataPanelTemplate> | undefined => {
  if (!layout?.length) return;

  const dataPanels: Record<string, DataPanelTemplate> = {};
  Object.values(charts).forEach((chart) => {
    for (const chartLayout of layout) {
      if (isChartInstanceOfTemplate(chartLayout, chart))
        // If multiple charts inherit from the same data panel template,
        // each may load different data if the chart-specific variables are provided
        dataPanels[chartLayout.i] = getEditableSectionDataPanel(chartLayout.i, chart.data_panel);
    }
  });

  return dataPanels;
};

export const getDataPanelsDependentOnVars = (
  config: DashboardConfig,
  varNameSet: Set<string>,
  variables: DashboardVariableMap,
): string[] => {
  return getDataPanelsDependentOnVariable(config, varNameSet, variables).map((dp) => dp.id);
};
