import Color from 'color';
import { format } from 'd3-format';
import {
  LegendOptions,
  XAxisOptions,
  XAxisTitleOptions,
  YAxisOptions,
  YAxisTitleOptions,
} from 'highcharts';
import { DateTime, Duration, Info } from 'luxon';

import {
  BOOLEAN,
  DatasetSchema,
  DATE_PART_INPUT_AGG,
  DATE_TYPES,
  DateDisplayFormat,
  DateDisplayOptions,
  DAY_PART_FORMAT_DICT,
  formatTime,
  getCurrentDateFormat,
  getTimezoneAwareDate,
  GROUPED_STACKED_OPERATION_TYPES,
  MONTH_PART_FORMAT_DICT,
  NUMBER_TYPES,
  NumberDisplayFormat,
  NumberDisplayOptions,
  OPERATION_TYPES,
  PivotAgg,
  STRING,
  STRING_FORMATS,
  StringFormat,
  TIME_DIFF_FORMATS,
  TIME_FORMATS,
} from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { PALETTE_TO_COLORS } from 'constants/colorConstants';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DATE_DISPLAY_FORMAT_TO_DATE_FORMAT } from 'constants/dataPanelEditorConstants';
import {
  CategoryChartColumnInfo,
  ColorColumnOption,
  ColorFormat,
  ColorPalette,
  ColorPaletteV2,
  LegendFormat,
  LegendPosition,
  V2TwoDimensionChartInstructions,
  VisualizeCollapsibleListInstructions,
  XAxisFormat,
  YAxisFormat,
} from 'constants/types';
import {
  getCategoricalColors,
  getDivergingColors,
  getGradientColors,
  TEXT_SIZE_OFFSET_MAP,
} from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getFontFamilyName } from 'globalStyles/utils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { replaceVariablesInString } from 'utils/variableUtils';

import { DEFAULT_DURATION_FORMAT } from '../constants/format';
import { LEGEND_CONFIG_BY_POS } from './../constants';

export const getColorColNames = (
  schema: DatasetSchema,
  operationType: OPERATION_TYPES,
  hasColorColumn = true,
) => {
  let xAxisColName;
  let colorColName;
  let aggColName;
  if (GROUPED_STACKED_OPERATION_TYPES.has(operationType)) {
    xAxisColName = schema[1].name;
    colorColName = schema[2].name;
    aggColName = schema[3].name;
  } else if (hasColorColumn) {
    xAxisColName = schema[0].name;
    // if there is a color but only 2 columns, that means that the xAxis and color column are the same
    colorColName = schema[2] ? schema[1].name : xAxisColName;
    aggColName = schema[2] ? schema[2].name : schema[1].name;
  } else {
    // if there is no color column, it should just be set to the xAxisCol
    xAxisColName = schema[0].name;
    colorColName = xAxisColName;
    aggColName = schema[1].name;
  }
  return {
    xAxisColName,
    colorColName,
    aggColName,
  };
};

export const shouldProcessColAsDate = (col?: CategoryChartColumnInfo | ColorColumnOption) => {
  if (!col || !DATE_TYPES.has(col.column.type || '')) return false;

  const hasDatePartBucket = col.bucket?.id && col.bucket.id.indexOf('DATE_PART') >= 0;

  return !hasDatePartBucket;
};

export const getLabelStyle = (
  globalStyleConfig: GlobalStyleConfig,
  fallback: 'primary' | 'secondary',
  colorOverride?: string,
) => {
  const fontFamily =
    globalStyleConfig.text.overrides.smallBody?.font ||
    (fallback === 'primary'
      ? globalStyleConfig.text.primaryFont
      : globalStyleConfig.text.secondaryFont);

  return {
    fontSize: `${
      globalStyleConfig.text.overrides.smallBody?.size ||
      globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['smallBody']
    }px`,
    color:
      colorOverride ||
      globalStyleConfig.text.overrides.smallBody?.color ||
      (fallback === 'primary'
        ? globalStyleConfig.text.primaryColor
        : globalStyleConfig.text.secondaryColor),
    fontWeight: 'normal',
    ...(fontFamily && { fontFamily: getFontFamilyName(fontFamily) }),
  };
};

export const xAxisFormat = (
  globalStyleConfig: GlobalStyleConfig,
  xAxisFormat?: XAxisFormat,
): XAxisOptions => {
  if (!xAxisFormat) return {};

  let title: XAxisTitleOptions = { text: undefined };
  if (xAxisFormat.showTitle && xAxisFormat.title) {
    title = {
      text: xAxisFormat.title,
      margin: 12,
      style: {
        fontWeight: 'bold',
        color:
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor,
      },
    };
  }
  return {
    title,
    reversed: xAxisFormat.reverseAxis ?? false,
    tickWidth: xAxisFormat.hideAxisTicks ? 0 : 1,
    opposite: xAxisFormat.flipAxis ?? false,
  };
};

export const yAxisFormat = (
  globalStyleConfig: GlobalStyleConfig,
  yAxisFormat?: YAxisFormat,
  colorOverride?: string,
): YAxisOptions => {
  if (!yAxisFormat) return {};

  let title: YAxisTitleOptions = { text: undefined };
  if (yAxisFormat.showTitle && yAxisFormat.title) {
    title = {
      text: yAxisFormat.title,
      margin: 12,
      style: {
        fontWeight: 'bold',
        color:
          colorOverride ||
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor,
      },
    };
  }
  return {
    title,
    reversed: yAxisFormat.reverseAxis ?? false,
    type: yAxisFormat.useLogScale ? 'logarithmic' : 'linear',
  };
};
export const formatLegend = (
  globalStyleConfig: GlobalStyleConfig,
  legendFormat: LegendFormat | undefined,
): LegendOptions => {
  let fontFamily =
    globalStyleConfig?.text.overrides.smallBody?.font || globalStyleConfig?.text.secondaryFont;

  const color = String(
    globalStyleConfig?.text.overrides.smallBody?.color || globalStyleConfig?.text.secondaryColor,
  );
  const colorObject = Color(color);
  const styles: LegendOptions = {
    itemStyle: {
      fontSize: `${
        globalStyleConfig.text.overrides.smallBody?.size ||
        globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['smallBody']
      }px`,
      color,
      ...(fontFamily && { fontFamily: getFontFamilyName(fontFamily) }),
      fontWeight: 'normal',
    },
    itemHoverStyle: {
      color: `${(colorObject.isDark() ? colorObject.lighten(0.1) : colorObject.darken(0.1)).hex()}`,
    },
  };
  if (!legendFormat) return { ...LEGEND_CONFIG_BY_POS[LegendPosition.AUTO], ...styles };

  fontFamily =
    globalStyleConfig?.text.overrides.smallHeading?.font || globalStyleConfig?.text.secondaryFont;

  return {
    ...LEGEND_CONFIG_BY_POS[legendFormat.position || LegendPosition.AUTO],
    // Note: treating legends as strings as no issues formatting other types
    labelFormatter: function () {
      return formatLabel(
        this.name || '', //if undefined returns null label, so defaulting to empty string for legends
        STRING,
        undefined,
        undefined,
        undefined,
        legendFormat?.stringFormat,
      );
    },
    enabled: !legendFormat.hideLegend,
    title: {
      text: legendFormat.showTitle ? legendFormat.title : undefined,
      style: {
        fontWeight: 'bold',
        fontSize: `${
          globalStyleConfig?.text.overrides.smallHeading?.size ||
          globalStyleConfig?.text.textSize + TEXT_SIZE_OFFSET_MAP['smallHeading']
        }px`,
        color: `${
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor
        }`,
        ...(fontFamily && { fontFamily: getFontFamilyName(fontFamily) }),
      },
    },
    ...styles,
  };
};

const titleString = (s: string) => {
  if (s.length === 0) return s;
  else if (s.length === 1) return s.toUpperCase();

  return s[0].toUpperCase().concat(s.slice(1).toLowerCase());
};

export const formatLabel = (
  value: number | string | undefined | null,
  colType?: string,
  bucket?: string,
  numBucketSize?: number,
  dateFormatOverride?: string,
  stringFormatOverride?: StringFormat,
  maxCategories = false,
) => {
  if (colType === BOOLEAN) return String(value);

  if (value == null || value == 'undefined' || value == 'null') return 'null';
  if (maxCategories && value === 'Other') return value;

  if (colType) {
    if (colType === STRING) {
      let stringValue = String(value);
      if (stringFormatOverride?.replaceUnderscores)
        stringValue = stringReplaceAll(stringValue, '_', ' ');
      switch (stringFormatOverride?.format) {
        case STRING_FORMATS.CAMEL_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map((part, i) =>
            i === 0 ? part.toLowerCase() : titleString(part),
          );
          return titledParts.join(' ');
        }
        case STRING_FORMATS.SENTENCE_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map((part, i) =>
            i !== 0 ? part.toLowerCase() : titleString(part),
          );
          return titledParts.join(' ');
        }
        case STRING_FORMATS.LOWERCASE:
          return stringValue.toLowerCase();
        case STRING_FORMATS.UPPERCASE:
          return stringValue.toUpperCase();
        case STRING_FORMATS.TITLE_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map(titleString);
          return titledParts.join(' ');
        }
        default:
          return stringValue;
      }
    }
    const valueAsNumber = getAxisNumericalValue(value);

    switch (bucket) {
      case PivotAgg.DATE_HOUR:
        return formatTime(
          DateTime.fromMillis(valueAsNumber).toLocal(),
          dateFormatOverride || TIME_FORMATS['HH:00 M/D'],
        );
      case PivotAgg.DATE_DAY:
      case PivotAgg.DATE_WEEK:
        return formatTime(
          DateTime.fromMillis(valueAsNumber),
          dateFormatOverride || TIME_FORMATS['MMM D, YYYY'],
        );
      case PivotAgg.DATE_MONTH:
        return formatTime(
          DateTime.fromMillis(valueAsNumber),
          dateFormatOverride || TIME_FORMATS['MMM YYYY'],
        );
      case PivotAgg.DATE_QUARTER:
        return formatTime(
          DateTime.fromMillis(valueAsNumber),
          dateFormatOverride || TIME_FORMATS['Quarter'],
        );
      case PivotAgg.DATE_YEAR:
        return formatTime(
          DateTime.fromMillis(valueAsNumber),
          dateFormatOverride || TIME_FORMATS['YYYY'],
        );
      case PivotAgg.DATE_PART_MONTH: {
        return Info.months(MONTH_PART_FORMAT_DICT[dateFormatOverride ?? ''] ?? 'long')[
          valueAsNumber - 1
        ];
      }
      case PivotAgg.DATE_PART_WEEK_DAY: {
        // BE returns Sun 0-6 while Luxon has Mon 0-6
        const day = valueAsNumber - 1;
        return Info.weekdays(DAY_PART_FORMAT_DICT[dateFormatOverride ?? ''] ?? 'long')[
          day === -1 ? 6 : day
        ];
      }
      case PivotAgg.DATE_PART_HOUR:
        return DateTime.utc()
          .set({ hour: valueAsNumber })
          .toLocal()
          .toLocaleString({ hour: 'numeric' });
    }
    if (NUMBER_TYPES.has(colType) && numBucketSize !== undefined) {
      if (numBucketSize === 1) return String(valueAsNumber);

      if (Number.isInteger(numBucketSize)) {
        // FIDO is less consistent about when it returns ints vs non-ints, so coerce this to an int
        // if the bucket size was non-decimal
        return `${parseInt(String(valueAsNumber))}-${
          parseInt(String(valueAsNumber)) + parseInt(String(numBucketSize)) - 1
        }`;
      } else {
        return `${parseFloat(String(valueAsNumber))}-${
          parseFloat(String(valueAsNumber)) + parseFloat(String(numBucketSize))
        }`;
      }
    }
  }

  return String(value);
};

export const getColorPalette = (
  globalStyleConfig: GlobalStyleConfig,
  colorFormat: ColorFormat | undefined,
): string[] => {
  const palette = colorFormat?.selectedPalette;
  const customColors = colorFormat?.customColors;

  switch (palette) {
    case ColorPalette.CUSTOM:
      return constructCustomPalette(customColors);
    case ColorPaletteV2.DIVERGING:
      return getDivergingColors(globalStyleConfig);
    case ColorPaletteV2.GRADIENT:
      return getGradientColors(globalStyleConfig);
    case ColorPaletteV2.CATEGORICAL:
    case undefined:
      return getCategoricalColors(globalStyleConfig);
  }

  return PALETTE_TO_COLORS[palette];
};

type ChartColorZone = { value?: number; color: string };

export const getColorZones = (
  colorFormat: ColorFormat | undefined,
  variables: DashboardVariableMap,
  datasetNamesToId: Record<string, string>,
  datasetData: DatasetDataObject,
  alwaysUseZones?: boolean,
): ChartColorZone[] | undefined => {
  if (!colorFormat?.colorZones?.length || (!colorFormat.useZones && !alwaysUseZones)) return;
  const numZones = colorFormat.colorZones.length;
  const chartZones: ChartColorZone[] = [];

  colorFormat.colorZones.forEach(({ zoneThreshold, zoneColor }, i) => {
    // Last zone does not need a threshold
    if (numZones === i + 1) return chartZones.push({ color: zoneColor });

    if (!zoneThreshold) return;

    const value = Number(
      replaceVariablesInString(zoneThreshold, variables, datasetNamesToId, datasetData),
    );
    if (!isNaN(value)) chartZones.push({ value, color: zoneColor });
  });
  return chartZones;
};

export const DEFAULT_PALETTE_COLOR = '#cce1fb';

export const constructCustomPalette = (customColors?: string) => {
  if (!customColors) return [DEFAULT_PALETTE_COLOR];

  return customColors.split(',');
};

export type FormatValueOptions = {
  value: number;
  formatId?: string;
  decimalPlaces?: number;
  significantDigits?: number;
  multiplier?: number;
  hasCommas?: boolean;
  timeFormatId?: string;
  customTimeFormat?: string;
  units?: string;
  displayNegativeValuesWithParentheses?: boolean;
  customDurationFormat?: string;
};

export const formatValue = ({
  value,
  decimalPlaces,
  formatId,
  significantDigits,
  multiplier,
  hasCommas,
  timeFormatId,
  customTimeFormat,
  units,
  displayNegativeValuesWithParentheses,
  customDurationFormat,
}: FormatValueOptions) => {
  value *= multiplier ?? 1;
  const separator = hasCommas ? ',' : '';
  const negativeDisplay = displayNegativeValuesWithParentheses ? '(' : '';
  const decimals = `.${decimalPlaces ?? 0}f`;

  let formattedVal;
  if (formatId === V2_NUMBER_FORMATS.CURRENCY.id) {
    formattedVal = format(`${negativeDisplay}$${separator}${decimals}`)(value);
  } else if (formatId === V2_NUMBER_FORMATS.PERCENT.id) {
    formattedVal = `${format(`${negativeDisplay}${separator}${decimals}`)(value * 100)}%`;
  } else if (formatId === V2_NUMBER_FORMATS.TIME.id) {
    formattedVal = formatTimeFromSeconds(
      value,
      timeFormatId,
      customTimeFormat,
      customDurationFormat,
    );
  } else if (formatId === V2_NUMBER_FORMATS.ABBREVIATED.id) {
    formattedVal = format(`.${significantDigits ?? 3}${Math.abs(value) < 1 ? 'r' : 's'}`)(value);
  } else {
    formattedVal = format(`${negativeDisplay}${separator}${decimals}`)(value);
  }
  return units && units.trim() !== '' ? `${formattedVal} ${units}` : formattedVal;
};

export const formatNumberValue = (
  numberDisplayOptions: NumberDisplayOptions,
  value: number,
  goal: number | string | undefined,
  isFloatCol?: boolean,
) => {
  const {
    hasCommas,
    multiplier,
    displayNegativeValuesWithParentheses,
    zeroCharacter,
    decimalPlaces,
  } = numberDisplayOptions;

  if (zeroCharacter !== undefined && value === 0) return zeroCharacter;

  switch (numberDisplayOptions.format) {
    case NumberDisplayFormat.CURRENCY:
      return formatValue({
        value,
        decimalPlaces: decimalPlaces ?? 2,
        formatId: V2_NUMBER_FORMATS.CURRENCY.id,
        hasCommas,
        multiplier,
        displayNegativeValuesWithParentheses,
      });
    case NumberDisplayFormat.PERCENT:
      return goal
        ? formatValue({
            value: value / (goal as number),
            decimalPlaces: decimalPlaces ?? 0,
            formatId: V2_NUMBER_FORMATS.PERCENT.id,
            hasCommas,
            multiplier,
          })
        : formatValue({
            value,
            decimalPlaces: decimalPlaces ?? 0,
            formatId: V2_NUMBER_FORMATS.PERCENT.id,
            hasCommas,
            multiplier,
            displayNegativeValuesWithParentheses,
          });
    case NumberDisplayFormat.TIME:
      return formatValue({
        value,
        formatId: V2_NUMBER_FORMATS.TIME.id,
        hasCommas,
        timeFormatId: numberDisplayOptions.timeFormat?.id,
        customTimeFormat: numberDisplayOptions.timeCustomFormat,
        customDurationFormat: numberDisplayOptions.customDurationFormat,
      });
    default:
      return formatValue({
        value,
        decimalPlaces: decimalPlaces ?? (isFloatCol ? 2 : 0),
        formatId: V2_NUMBER_FORMATS.NUMBER.id,
        hasCommas,
        multiplier,
        displayNegativeValuesWithParentheses,
      });
  }
};

// Validates duration format string and returns an error message if applicable
export const getLuxonDurationFormatErrorMessage = (
  format: string | undefined,
): string | undefined => {
  if (!format) return;
  const validTokens = ['S', 's', 'm', 'h', 'd', 'w', 'M', 'y'];
  // Remove escaped sequences
  const unescapedFormat = format.replace(/'[^']*'/g, '');

  const isValidToken = (token: string) => {
    if (token.length === 0) return true; // Ignore empty strings

    const tokenChar = token[0];
    return validTokens.includes(tokenChar) && /^[SsmhdwMy]+$/.test(token);
  };

  // Split format string by the tokens
  const tokens = unescapedFormat.match(/S+|s+|m+|h+|d+|w+|M+|y+|[^SsmhdwMy]+/g) || [];

  // Validate each token
  for (const token of tokens) {
    const trimmedToken = token.trim();
    if (!isValidToken(trimmedToken)) {
      return `Invalid token found in custom duration format: ${trimmedToken}`;
    }
  }
};

const formatTimeFromSeconds = (
  timeInSeconds: number,
  timeFormatId?: string,
  customTimeFormat?: string,
  customDurationFormat?: string,
) => {
  const days = Math.floor(timeInSeconds / (60 * 60 * 24));
  let remainingSeconds = timeInSeconds % (60 * 60 * 24);
  const hours = Math.floor(remainingSeconds / (60 * 60));
  remainingSeconds = remainingSeconds % (60 * 60);
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = Math.round(remainingSeconds % 60);

  if (timeFormatId === TIME_DIFF_FORMATS.ABBREVIATION.id) {
    let amount = 0;
    let unit = '';
    if (days > 0) {
      amount = days;
      unit = 'day';
    } else if (hours > 0) {
      amount = hours;
      unit = 'hour';
    } else if (minutes > 0) {
      amount = minutes;
      unit = 'minute';
    } else {
      amount = seconds;
      unit = 'second';
    }
    return `${amount} ${unit}${amount !== 1 ? 's' : ''}`;
  } else if (timeFormatId === TIME_DIFF_FORMATS.CUSTOM.id && customTimeFormat) {
    const hh = `${hours < 10 ? '0' : ''}${hours}`;
    const mm = `${minutes < 10 ? '0' : ''}${minutes}`;
    const ss = `${seconds < 10 ? '0' : ''}${seconds}`;

    let formatted = stringReplaceAll(customTimeFormat, 'hh', hh);
    formatted = stringReplaceAll(formatted, 'mm', mm);
    formatted = stringReplaceAll(formatted, 'ss', ss);
    formatted = stringReplaceAll(formatted, 'HH', String(hours));
    formatted = stringReplaceAll(formatted, 'MM', String(minutes));
    formatted = stringReplaceAll(formatted, 'SS', String(seconds));
    formatted = stringReplaceAll(formatted, 'DD', String(days));

    return formatted;
  } else if (timeFormatId === TIME_DIFF_FORMATS.CUSTOM_DURATION.id) {
    return Duration.fromObject({
      seconds: timeInSeconds,
    }).toFormat(customDurationFormat || DEFAULT_DURATION_FORMAT);
  }
  // Standard
  return Duration.fromObject({ seconds: timeInSeconds }).toFormat(
    `d 'day${days !== 1 ? 's' : ''}', hh:mm:ss`,
  );
};

export const stringReplaceAll = (s: string, match: string, newVal: string) => {
  const re = new RegExp(match, 'g');

  return s.replace(re, newVal);
};

export const isTwoDimVizInstructionsReadyToDisplay = (
  instructions: V2TwoDimensionChartInstructions | undefined,
  operation_type: OPERATION_TYPES,
): boolean => {
  if (!instructions) return false;
  const { categoryColumn, groupingColumn, aggColumns, colorColumnOptions } = instructions;

  if (categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG && !categoryColumn.bucketElemId) {
    return false;
  }

  const isGroupedIfNeeded = GROUPED_STACKED_OPERATION_TYPES.has(operation_type)
    ? groupingColumn !== undefined && !!colorColumnOptions?.length
    : true;

  const hasColorColIfNeeded =
    operation_type === OPERATION_TYPES.VISUALIZE_SANKEY_CHART ||
    operation_type === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2
      ? colorColumnOptions?.length === 1
      : true;

  return (
    !!categoryColumn?.column && !!aggColumns?.length && isGroupedIfNeeded && hasColorColIfNeeded
  );
};

export const areRequiredVariablesSetTwoDimViz = (
  variables: DashboardVariableMap,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  if (instructions?.categoryColumn?.bucket?.id !== DATE_PART_INPUT_AGG) return true;

  const groupingElemId = instructions?.categoryColumn?.bucketElemId;
  return !groupingElemId || !!variables[groupingElemId];
};

export const isCollapsibleListReadyToDisplay = (
  instructions?: VisualizeCollapsibleListInstructions,
) => {
  return !!(instructions && instructions.rowColumns && instructions.aggregations);
};

export const formatDateField = (
  data: string,
  colType: string,
  dateFormatOption: DateDisplayOptions | undefined,
  ignoreInvalidDates?: boolean,
  isDashboardTable?: boolean,
): string => {
  const datePart = dateFormatOption?.datePartAgg;
  // This is used by report builder table
  if (datePart) {
    if (datePart === PivotAgg.DATE_PART_MONTH_DAY) return data;
    const value = Number(data);
    if (isNaN(value)) return '';

    if (datePart === PivotAgg.DATE_PART_HOUR) {
      return DateTime.utc().set({ hour: value }).toLocal().toLocaleString({ hour: 'numeric' });
    }
    if (datePart === PivotAgg.DATE_PART_MONTH) return Info.months('long')[value - 1];
    const day = value - 1;
    return Info.weekdays('long')[day === -1 ? 6 : day];
  }

  let dateVal = getTimezoneAwareDate(data);
  const currentDateFormat = getCurrentDateFormat(dateFormatOption, colType);
  const dateFormat =
    currentDateFormat === DateDisplayFormat.CUSTOM
      ? (dateFormatOption?.customFormat ?? '')
      : DATE_DISPLAY_FORMAT_TO_DATE_FORMAT[currentDateFormat];

  if (!dateVal.isValid) {
    return ignoreInvalidDates ? '' : 'Invalid Date';
  }
  // this is a date, not a date time, so leaving it in UTC
  if (!isDashboardTable) dateVal = dateVal.toUTC();
  return formatTime(dateVal, dateFormat);
};

// FIDO returns a string for all values, so we have to cast that to a float when a number is expected
export const getAxisNumericalValue = (rawValue: string | number) => parseFloat(rawValue as string);
