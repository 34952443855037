import { DEFAULT_TOOLTIP_BACKGROUND_COLOR } from 'globalStyles/constants';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { isEmpty, cloneDeep } from 'utils/standard';

export function clearEmptyPanels(configuration: DashboardVersionConfig): void {
  const currentPanels = configuration.data_panels;
  // PD-995: For some reason we're getting empty data panel templates sometimes, mitigate this by
  // checking for emptyness until we can figure out why
  configuration.data_panels = Object.keys(currentPanels).reduce<Record<string, DataPanelTemplate>>(
    (acc, dpId) => {
      if (isEmpty(currentPanels[dpId])) return acc;
      acc[dpId] = currentPanels[dpId];

      return acc;
    },
    {},
  );
}

export function cloneAndSanitizeGlobalStyleConfig(
  globalStyleConfig: GlobalStyleConfig,
): GlobalStyleConfig {
  const sanitizedConfig = cloneDeep(globalStyleConfig);
  if (!sanitizedConfig.container.tooltip) {
    sanitizedConfig.container.tooltip = {
      backgroundColor: DEFAULT_TOOLTIP_BACKGROUND_COLOR,
    };
  }
  return sanitizedConfig;
}
