import { Branch, ComputedView, Folder, Resource } from '@explo-tech/fido-api';
import { cloneDeep } from 'lodash';

import { ResponseData, isSuccess } from 'remotedata';
import {
  FOLDER_TYPE,
  MAIN_BRANCH_NAME,
  RESOURCE_NOT_FOUND_INDEX,
  ROOT_FOLDER_PATH,
} from './constants';
import {
  ReportBuilderDataset,
  ReportBuilderDatasetMetadata,
} from 'actions/reportBuilderConfigActions';
import { ParentSchema } from 'actions/dataSourceActions';
import { getEmbeddoSchemaFromFidoSchema } from 'utils/fido/fidoShims';
import { getEmbeddoSchemaIdFromView } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';

export const getItemNameFromPath = (path: string): string => {
  return path.split('/').pop() ?? '';
};

export const getResourceFromFolder = (
  folder: Folder,
  path: string,
): Folder | ComputedView | undefined => {
  if (folder.path === path) {
    return folder;
  }

  for (const content of folder.children ?? []) {
    if (content.path === path) {
      return content as Folder | ComputedView;
    }

    if (content['@type'] === 'folder') {
      const foundResource = getResourceFromFolder(content as Folder, path);
      if (foundResource) {
        return foundResource;
      }
    }
  }

  return undefined;
};

export const getParentPath = (path: string): string =>
  path.split('/').slice(0, -1).join('/') || '/';

// TODO(tarastentz): We could probably get away with a non-recursive version that just looks within the current folder
export const findViewInFolder = (folder: Folder, viewId: string): ComputedView | undefined => {
  for (const content of folder.children ?? []) {
    if (content['@type'] === 'computed-view' && content.id === viewId) {
      return content as ComputedView;
    }

    if (content['@type'] === 'folder') {
      const foundView = findViewInFolder(content as Folder, viewId);
      if (foundView) return foundView;
    }
  }

  return undefined;
};

export const getUniqueResourceName = (allNames: Set<string>, baseName: string): string => {
  let newName = baseName;
  while (allNames.has(newName)) {
    newName = `${baseName} Copy`;
  }

  return newName;
};

export const copyAndRenameItem = (resource: Resource, newName: string): Resource => {
  const newResource = cloneDeep(resource);
  newResource.name = newName;
  const previousPathWithoutName = newResource.path?.substring(0, newResource.path.lastIndexOf('/'));
  newResource.path = createFullPath(previousPathWithoutName ?? '', newName);
  return newResource;
};

/**
 * @param path The path string that does not include the name
 * @param name The name of the resource
 * @returns The full path to the resource. Checks that the passed in path does not end with a
 * forward slash before appending the forward slash. This is done to handle the root folder.
 */
export const createFullPath = (path: string, name: string): string => {
  return path.endsWith('/') ? `${path}${name}` : `${path}/${name}`;
};

export const isFolderFullyFetched = (
  allFolders: Map<string, ResponseData<Folder>>,
  folderPath: string,
): boolean => {
  const folderResponse = allFolders.get(folderPath);
  if (!folderResponse || !isSuccess(folderResponse)) {
    return false;
  }

  const folder = folderResponse.data;
  const allChildFolders = (folder.children ?? []).filter(
    (child) => child['@type'] === FOLDER_TYPE,
  ) as Folder[];

  if (allChildFolders.length === 0) {
    return true;
  }
  return allChildFolders.every((child) => isFolderFullyFetched(allFolders, child.path ?? ''));
};

export const getAllPathsUpToPath = (path: string): string[] => {
  const paths = [ROOT_FOLDER_PATH];
  const pathComponents = path.split('/').filter((component) => component !== '');
  let currentPath = ROOT_FOLDER_PATH;
  for (const component of pathComponents) {
    currentPath += currentPath.endsWith('/') ? `${component}` : `/${component}`;
    paths.push(currentPath);
  }

  return paths;
};

export interface UpdateResourceResult {
  originalResource: Resource;
  updatedResource: Resource;
}

export const updateResourceIdsInParentFolder = (
  parentFolder: Folder,
  updatedResource: Resource,
): UpdateResourceResult | undefined => {
  const resourceIndex =
    parentFolder.children?.findIndex((child) => child.path === updatedResource.path) ??
    RESOURCE_NOT_FOUND_INDEX;
  if (resourceIndex === RESOURCE_NOT_FOUND_INDEX) {
    return;
  }
  const currentResource = parentFolder.children?.[resourceIndex];
  if (!currentResource) {
    return;
  }
  parentFolder.children ??= [];
  parentFolder.children[resourceIndex] = {
    ...currentResource,
    id: updatedResource.id,
    versionId: updatedResource.versionId,
  };

  return {
    originalResource: currentResource,
    updatedResource: parentFolder.children[resourceIndex],
  };
};

/**
 * @param folders A map of folder paths to folder responses.
 * @return The found resource or undefined if not found.
 */
export const getResourceById = (
  resourceId: string,
  folders: Map<string, ResponseData<Folder>>,
): Resource | undefined => {
  for (const folder of folders.values()) {
    if (!isSuccess(folder)) {
      continue;
    }

    if (folder.data.id === resourceId) {
      return folder.data;
    }

    const resource = folder.data.children?.find((child) => child.id === resourceId);
    if (resource) {
      return resource;
    }
  }
};

export const convertViewToReportBuilderDataset = (
  view: ComputedViewWithIds,
  parentSchemas: ParentSchema[],
  reportBuilderDatasetMetadata: ReportBuilderDatasetMetadata,
): ReportBuilderDataset => {
  return {
    id: view.id ?? '',
    name: view.name,
    description: view.description ?? '',
    query: view.query,
    parent_schema_id: getEmbeddoSchemaIdFromView(parentSchemas, view),
    schema: getEmbeddoSchemaFromFidoSchema(view.columnDefinitions ?? []),
    permissions: reportBuilderDatasetMetadata.permissions,
    customAggregations: reportBuilderDatasetMetadata.customAggregations,
    columnConfigs: reportBuilderDatasetMetadata.columnConfigs,
  };
};

export const isBranchMain = (branch: Branch): boolean => branch.name === MAIN_BRANCH_NAME;

export const getConcatenatedVersionedComputedViewId = (computedView: ComputedView): string =>
  `${computedView.id}-${computedView.versionId}`;

export const concatenateVersionedComputedViewIds = (id: string, versionId: string): string =>
  `${id}-${versionId}`;
