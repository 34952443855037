import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { updateClonedVisualizationInstructions } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { ChartColumnInfo, SchemaChange, VisualizeTableInstructions } from 'constants/types';
import { getExcludedColumns, getHiddenColumns } from 'utils/dashboardUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { getTableChangedSchema } from 'utils/tableSchemaUtils';

import DroppableColumnSection from './droppable/DroppableColumnSection';
import { DroppedColumn } from './droppable/DroppedColumn';

const useStyles = makeStyles((theme: Theme) => ({
  excludedHeader: {
    marginBottom: 1,
  },
  excludedColSection: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
}));

type Props = {
  instructions: VisualizeTableInstructions;
  schema: DatasetSchema;
  chartType: string;
  loading?: boolean;
};

export default function TableConfig({ instructions, chartType, loading, schema }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [excludedCollapsed, setExcludedCollapsed] = useState<boolean>(true);

  const changedSchema = useMemo(
    () => getTableChangedSchema(schema, instructions),
    [schema, instructions],
  );

  const excludedColumns = useMemo(
    () => getExcludedColumns(schema, instructions),
    [schema, instructions],
  );

  const hiddenColumnNames = useMemo(
    () => getHiddenColumns(schema, instructions),
    [schema, instructions],
  );

  const updateInstructions = (
    modifyInstructions: (instructions: VisualizeTableInstructions) => void,
  ) =>
    updateClonedVisualizationInstructions(
      instructions,
      chartType as OPERATION_TYPES,
      dispatch,
      modifyInstructions,
    );

  const updateOrCreateSchemaChange =
    (updateFn: (prev: SchemaChange) => void) => (col: ChartColumnInfo) =>
      updateInstructions((instructions) => {
        if (!col.name) return;

        const schemaChangeIndex = instructions.changeSchemaList.findIndex(
          (change) => change.col === col.name,
        );
        if (schemaChangeIndex !== -1) {
          updateFn(instructions.changeSchemaList[schemaChangeIndex]);
        } else {
          const newCol = {
            col: col.name,
            keepCol: true,
            hideCol: false,
            newColName: col.friendly_name || col.name,
          };
          updateFn(newCol);
          instructions.changeSchemaList.push(newCol);
        }
      });

  const handleAddCol = updateOrCreateSchemaChange((prev) => {
    prev.keepCol = true;
  });

  const handleToggleHide = updateOrCreateSchemaChange((prev) => {
    prev.hideCol = !prev.hideCol;
  });

  const handleRemoveCol = updateOrCreateSchemaChange((prev) => {
    prev.keepCol = false;
  });

  const isVisualizeTableChartType = chartType === OPERATION_TYPES.VISUALIZE_TABLE;

  return (
    <div>
      <SettingHeader name="Columns included" />
      <DroppableColumnSection
        columns={changedSchema.map((column) => ({ column }))}
        disableEdits={loading}
        hiddenColumnNames={isVisualizeTableChartType ? hiddenColumnNames : undefined}
        maxCols={changedSchema.length + excludedColumns.length}
        onColAdded={handleAddCol}
        onColOptionChanged={() => undefined}
        onRemoveCol={handleRemoveCol}
        onToggleHideCol={
          isVisualizeTableChartType && instructions.isSchemaCustomizationEnabled
            ? handleToggleHide
            : undefined
        }
        schema={schema}
      />
      <SettingHeader
        btnProps={{
          icon: excludedCollapsed ? 'chevron-right' : 'chevron-down',
          onClick: () => setExcludedCollapsed(!excludedCollapsed),
        }}
        className={classes.excludedHeader}
        name="Columns excluded"
      />
      {!excludedCollapsed && (
        <div className={classes.excludedColSection}>
          {excludedColumns.map((col) => (
            <DroppedColumn
              columnName={getColDisplayText({ column: col })}
              icon="plus"
              key={`table-excluded-column-${col.name}`}
              onIconClick={() => handleAddCol(col)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
