import { FC, useState } from 'react';

import {
  DatasetSchema,
  NUMBER_TYPES,
  NumberDisplayDisplayType,
  NumberDisplayOptions,
  NumberDisplayTypeOptions,
  OPERATION_TYPES,
} from '@explo/data';

import { Input, Select, sprinkles, Switch } from 'components/ds';

import { ExactValueInput } from './ExactValueInput';
import { DEFAULT_VALUE_TEXT_WIDTH, VALUE_TEXT_WIDTH_TEXT } from './constants';
import { VALUE_TEXT_WIDTH_INFO_TEXT_COMPONENT } from './format';

type Props = {
  updateNumberOptions: (newFields: NumberDisplayOptions) => void;
  displayOptions: NumberDisplayOptions | undefined;
  operationType: OPERATION_TYPES;
  originalSchema: DatasetSchema;
};

const colTypeOption = 'Column max';
const exactValueOption = 'Exact value';
const colOption = 'Column';

export const ProgressBarConfiguration: FC<Props> = ({
  updateNumberOptions,
  displayOptions,
  operationType,
  originalSchema,
}) => {
  const isCollapsibleList = operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST;

  const { displayType, displayTypeOptions, disableHoverTooltip } = displayOptions ?? {};
  const { useOtherColumnAsMax, useColumnMaxForProgressBarGoal } = displayTypeOptions ?? {};

  const numberColumnsOptions = originalSchema
    .filter((col) => NUMBER_TYPES.has(col.type))
    .map((col) => ({ value: col.name }));

  const updateFields = (updates: NumberDisplayTypeOptions) => {
    updateNumberOptions({ displayTypeOptions: { ...displayTypeOptions, ...updates } });
  };

  const isColMax = !useOtherColumnAsMax && useColumnMaxForProgressBarGoal;
  const isExactValue = !useOtherColumnAsMax && !useColumnMaxForProgressBarGoal;
  const isCol = useOtherColumnAsMax;

  const getInitialSelectedItem = () => {
    if (isColMax) {
      return colTypeOption;
    } else if (isExactValue) {
      return exactValueOption;
    } else if (isCol) {
      return colOption;
    }
    return exactValueOption;
  };

  const [selectedItem, setSelectedItem] = useState(getInitialSelectedItem());

  const progressGoalInput = () => (
    <ExactValueInput
      containerClassName={isCollapsibleList ? sprinkles({ marginTop: 'sp1' }) : undefined}
      disabled={useColumnMaxForProgressBarGoal}
      exactValue={displayTypeOptions?.progressBarGoal}
      hideLabels={useColumnMaxForProgressBarGoal}
      label="Goal Value"
      updateExactValue={(newValue) => updateFields({ progressBarGoal: newValue })}
    />
  );

  return (
    <>
      <Switch
        label="Progress bar"
        onChange={() => {
          updateNumberOptions({
            displayType:
              displayType === NumberDisplayDisplayType.PROGRESS_BAR
                ? NumberDisplayDisplayType.VALUE
                : NumberDisplayDisplayType.PROGRESS_BAR,
          });
        }}
        switchOn={displayType === NumberDisplayDisplayType.PROGRESS_BAR}
      />
      {displayType === NumberDisplayDisplayType.PROGRESS_BAR && (
        <>
          <Switch
            className={sprinkles({ paddingTop: 'sp1' })}
            label="Show progress on hover"
            onChange={() => updateNumberOptions({ disableHoverTooltip: !disableHoverTooltip })}
            switchOn={!disableHoverTooltip}
          />
          {isCollapsibleList ? (
            progressGoalInput()
          ) : (
            <div className={dropdownRootClass}>
              <Select
                className={sprinkles({ flex: 1 })}
                label="Calculation"
                onChange={(value) => {
                  setSelectedItem(value);
                  updateFields({
                    useColumnMaxForProgressBarGoal: value === colTypeOption,
                    useOtherColumnAsMax: value === colOption,
                  });
                }}
                selectedValue={selectedItem}
                values={[
                  { value: colTypeOption },
                  { value: exactValueOption },
                  { value: colOption },
                ]}
              />
              <div className={sprinkles({ flex: 1 })} style={{ minWidth: 0 }}>
                {useOtherColumnAsMax ? (
                  <Select
                    className={sprinkles({ marginTop: 'sp1', width: 'fill' })}
                    onChange={(value) => updateFields({ goalColumnName: value })}
                    placeholder="Select goal column"
                    selectedValue={displayTypeOptions?.goalColumnName}
                    values={numberColumnsOptions}
                  />
                ) : (
                  progressGoalInput()
                )}
              </div>
            </div>
          )}
          <div className={sprinkles({ flex: 1 })} style={{ minWidth: 0 }}>
            <Input
              showInputButton
              className={sprinkles({ marginTop: 'sp1', width: 'fill' })}
              defaultValue={
                displayTypeOptions?.valueTextWidth?.toString() ?? DEFAULT_VALUE_TEXT_WIDTH
              }
              disabled={useColumnMaxForProgressBarGoal}
              label={{
                text: VALUE_TEXT_WIDTH_TEXT,
                infoText: VALUE_TEXT_WIDTH_INFO_TEXT_COMPONENT,
              }}
              onSubmit={(newValue: string) => {
                const intValue = parseInt(newValue);
                if (!isNaN(intValue)) updateFields({ valueTextWidth: intValue });
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const dropdownRootClass = sprinkles({
  marginTop: 'sp1',
  display: 'flex',
  alignItems: 'flex-end',
  gap: 'sp1',
});
