import cx from 'classnames';
import { FC } from 'react';

import { IconButton, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';

import * as styles from './styles.css';

type Props = {
  columnName?: string;
  icon: IconName;
  onIconClick: () => void;
  isHidden?: boolean; // If true or false, show eye icon and lets users toggle visibility
  onToggleHide?: () => void;
};

export const DroppedColumn: FC<Props> = ({
  columnName,
  icon,
  isHidden,
  onIconClick,
  onToggleHide,
}) => {
  const isExcludedColumn = icon === 'plus';
  return (
    <div
      className={cx(
        rootClass,
        sprinkles({
          backgroundColor: isExcludedColumn ? 'gray2' : 'lightBlue',
          paddingLeft: isExcludedColumn ? 'sp1' : undefined,
        }),
      )}>
      <div className={isExcludedColumn ? excludedNameClass : styles.colName}>{columnName}</div>
      {onToggleHide ? (
        <IconButton
          className={styles.iconBtn}
          name={isHidden ? 'eye-closed' : 'eye-open'}
          onClick={onToggleHide}
        />
      ) : null}
      <IconButton
        className={isExcludedColumn ? styles.excludedIconBtn : styles.iconBtn}
        name={icon}
        onClick={onIconClick}
      />
    </div>
  );
};

const excludedNameClass = sprinkles({
  width: 'fill',
  truncateText: 'ellipsis',
  color: 'contentPrimary',
  heading: 'h4',
});

const rootClass = sprinkles({
  borderRadius: 3,
  flexItems: 'alignCenterBetween',
  border: 1,
  borderColor: 'lightBlue',
});
