import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { getTimezone, setDisplayTimezone } from '@explo/data';

import { updateUnderlyingDataDataPanel } from 'actions/dataPanelTemplateAction';
import { DataSource, ParentSchema } from 'actions/dataSourceActions';
import { embedFetchDashboardActions } from 'actions/embedActions';
import * as exportActions from 'actions/exportActions';
import { ExportType } from 'actions/exportActions';
import { logInUserSuccess } from 'actions/userActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { Jobs } from 'components/JobQueue/types';
import * as RD from 'remotedata';
import { isIframe } from 'utils/environmentUtils';
import { uniqueId } from 'utils/standard';

import { downloadExploreComputationSpreadsheet } from './thunks/dashboardDataThunks/fetchFidoDataThunks';
import {
  downloadDashboard,
  downloadDataPanelScreenshot,
  downloadDataPanelSpreadsheet,
  enqueueDashboardJobsThunk,
  fetchShareIdThunk,
} from './thunks/dashboardLayoutThunks/';

export type DownloadInfo = { exportType: ExportType } & DownloadType;

type DownloadType =
  | { type: 'email'; status: RD.ResponseData<undefined> }
  | { type: 'url'; status: RD.ResponseData<string> };

type ShareData = {
  id: string;
  shareLinkUrl: string | null;
};

export type ChartMenuInfo = {
  chartId: string;
  chartY: number;
  chartX: number;
  category: string;
  subCategory?: string;
  ignoreCategory?: boolean;
};

type FidoRequestInfo = {
  parentSchemaDataSourceMapping?: Record<string, string>;
  dataSources?: DataSource[];
  schemas?: ParentSchema[];
};

export type EmbeddedRequestInfo = {
  type: 'embedded';
  embedType: EmbeddedDashboardType;
  resourceEmbedId: string;
  customerToken: string | undefined;
  environment: string | undefined;
  jwt?: string;
  jwtExpiration?: number;
};

type AppRequestInfo = {
  type: 'app';
  resourceId: number;
  customerId: number | undefined;
  isDraft?: boolean;
};

type RequestInfoType = EmbeddedRequestInfo | AppRequestInfo;

export type DashboardLayoutRequestInfo = RequestInfoType & {
  timezone: string;
  dashboardCurrencyCode?: string;
  useJobQueue: boolean;
  useFido?: boolean;
  emailWidthPx?: number;
  versionNumber: number;
  enableScreenshotExports?: boolean;
  enableEmailExports?: boolean;
  isScreenshot?: boolean;

  datasetMaxRows?: number;
  dataPanelMaxDataPoints?: number;
  pdfMaxRows?: number;
  customMapBoxToken?: string;
} & FidoRequestInfo;

interface DashboardLayoutReducer {
  layoutId: string;
  requestInfo: DashboardLayoutRequestInfo;
  enableNewGrid: boolean;
  enableFillMissingDates: boolean;

  dashboardExport: DownloadInfo | undefined;
  dpDownloads: Record<string, DownloadInfo | undefined>;
  shareData: RD.ResponseData<ShareData>;

  chartMenu: ChartMenuInfo | null;
  isUnderlyingDataModalOpen: boolean;
  awaitedJobs: Record<string, Jobs>;
}

const initRequestInfo: DashboardLayoutRequestInfo = {
  type: 'app',
  versionNumber: 1,
  timezone: getTimezone(undefined),
  dashboardCurrencyCode: undefined,
  useJobQueue: false,
  useFido: false,
  enableEmailExports: true,
  enableScreenshotExports: true,
  resourceId: 0,
  customerId: undefined,
};

const getInitialState = (): DashboardLayoutReducer => ({
  layoutId: uniqueId('explo-dash'),
  requestInfo: initRequestInfo,
  enableNewGrid: false,
  enableFillMissingDates: false,

  dashboardExport: undefined,
  dpDownloads: {},
  shareData: RD.Idle(),
  chartMenu: null,
  isUnderlyingDataModalOpen: false,
  awaitedJobs: {},
});

const receiveDownloadInfo = (exportType: ExportType, url?: string): DownloadInfo => {
  return url
    ? { type: 'url', exportType, status: RD.Success(url) }
    : { type: 'email', exportType, status: RD.Success(undefined) };
};

const setLoadingState = (exportType: ExportType, email: string | undefined): DownloadInfo => {
  return { type: email ? 'email' : 'url', exportType, status: RD.Loading() };
};

const receiveDpDownloadError = (state: DashboardLayoutReducer, dpId: string, error: string) => {
  const download = state.dpDownloads[dpId];
  if (!download) return;
  state.dpDownloads[dpId] = { ...download, status: RD.Error(error) };
};

const dashboardLayoutSlice = createSlice({
  name: 'dashboardLayout',
  initialState: getInitialState(),
  reducers: {
    clearDashboardLayoutReducer: (state) => {
      // Don't want to reset new grid being enabled
      return {
        ...getInitialState(),
        enableNewGrid: state.enableNewGrid,
        enableFillMissingDates: state.enableFillMissingDates,
      };
    },
    clearShareLink: (state) => {
      state.shareData = RD.Idle();
    },
    clearDownloads: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload === undefined) {
        state.dashboardExport = undefined;
        return;
      }
      if (payload in state.dpDownloads) delete state.dpDownloads[payload];
    },
    setRequestInfo: (state, { payload }: PayloadAction<DashboardLayoutRequestInfo>) => {
      setDisplayTimezone(payload.timezone);
      // Resetting state just in case
      return {
        ...getInitialState(),
        requestInfo: payload,
        enableNewGrid: state.enableNewGrid,
        enableFillMissingDates: state.enableFillMissingDates,
      };
    },
    setJwt: (state, { payload }: PayloadAction<string>) => {
      if (state.requestInfo.type === 'embedded') {
        state.requestInfo.jwt = payload;
      }
    },
    setChartMenu: (state, { payload }: PayloadAction<ChartMenuInfo | null>) => {
      state.chartMenu = payload;
    },
    receiveFinishedJobs: (state, { payload: finishedJobs }: PayloadAction<string[]>) => {
      finishedJobs.forEach((jobId) => {
        if (jobId in state.awaitedJobs) delete state.awaitedJobs[jobId];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUnderlyingDataDataPanel, (state, { payload }) => {
        state.isUnderlyingDataModalOpen = !!payload;
      })
      .addCase(enqueueDashboardJobsThunk.fulfilled, (state, { payload: newJobs }) => {
        state.awaitedJobs = { ...state.awaitedJobs, ...newJobs };
      })
      .addCase(embedFetchDashboardActions.successAction, (state, { payload }) => {
        state.enableNewGrid = payload.team.feature_flags.enable_new_grid ?? false;
        state.enableFillMissingDates =
          payload.team.feature_flags.enable_fill_missing_date_range ?? false;
      })
      .addCase(logInUserSuccess, (state, { payload }) => {
        state.enableNewGrid = payload.team?.feature_flags.enable_new_grid ?? false;
        state.enableFillMissingDates =
          payload.team?.feature_flags.enable_fill_missing_date_range ?? false;
      })
      // Download Dashboard
      .addCase(downloadDashboard.pending, (state, { meta }) => {
        state.dashboardExport = setLoadingState(meta.arg.export_type, meta.arg.email);
      })
      .addCase(downloadDashboard.fulfilled, (state, { payload, meta }) => {
        state.dashboardExport = receiveDownloadInfo(meta.arg.export_type, payload.url);
      })
      .addCase(downloadDashboard.rejected, (state) => {
        if (!state.dashboardExport) return;
        state.dashboardExport.status = RD.Error('Error downloading dashboard');
      })
      .addCase(exportActions.downloadDashboardRequest, (state, { payload }) => {
        state.dashboardExport = setLoadingState(
          payload.postData.export_type,
          payload.postData.email,
        );
      })
      .addCase(exportActions.downloadDashboardSuccess, (state, { payload }) => {
        if (!state.dashboardExport) return;
        state.dashboardExport.status = RD.Success(payload.url);
      })
      .addCase(exportActions.downloadDashboardError, (state) => {
        if (!state.dashboardExport) return;
        state.dashboardExport.status = RD.Error('Error downloading dashboard');
      })
      // Download Data Panel Screenshot
      .addCase(downloadDataPanelScreenshot.pending, (state, { meta }) => {
        state.dpDownloads[meta.arg.data_panel_template_id] = setLoadingState(
          ExportType.PDF,
          meta.arg.email,
        );
      })
      .addCase(downloadDataPanelScreenshot.fulfilled, (state, { payload, meta }) => {
        state.dpDownloads[meta.arg.data_panel_template_id] = receiveDownloadInfo(
          ExportType.PDF,
          payload.url,
        );
      })
      .addCase(downloadDataPanelScreenshot.rejected, (state, { meta }) => {
        receiveDpDownloadError(state, meta.arg.data_panel_template_id, 'Error downloading pdf');
      })
      .addCase(exportActions.downloadDataPanelScreenshotRequest, (state, { payload }) => {
        state.dpDownloads[payload.postData.data_panel_template_id] = setLoadingState(
          ExportType.PDF,
          payload.postData.email,
        );
      })
      .addCase(exportActions.downloadDataPanelScreenshotSuccess, (state, { payload }) => {
        state.dpDownloads[payload.postData.data_panel_template_id] = receiveDownloadInfo(
          ExportType.PDF,
          payload.url,
        );
      })
      .addCase(exportActions.downloadDataPanelScreenshotError, (state, { payload }) => {
        receiveDpDownloadError(
          state,
          payload.postData.data_panel_template_id,
          'Error downloading pdf',
        );
      })
      // Download Data Panel Spreadsheet
      .addCase(downloadDataPanelSpreadsheet.pending, (state, { meta }) => {
        state.dpDownloads[meta.arg.id] = setLoadingState(meta.arg.file_format, meta.arg.email);
      })
      .addCase(downloadDataPanelSpreadsheet.fulfilled, (state, { payload, meta }) => {
        state.dpDownloads[meta.arg.id] = receiveDownloadInfo(meta.arg.file_format, payload.url);
      })
      .addCase(downloadDataPanelSpreadsheet.rejected, (state, { meta }) => {
        receiveDpDownloadError(state, meta.arg.id, 'Error downloading spreadsheet');
      })
      .addCase(exportActions.downloadDataPanelSpreadsheetRequest, (state, { payload }) => {
        state.dpDownloads[payload.postData.id] = setLoadingState(
          payload.postData.file_format,
          payload.postData.email,
        );
      })
      .addCase(exportActions.downloadDataPanelSpreadsheetSuccess, (state, { payload }) => {
        state.dpDownloads[payload.postData.id] = receiveDownloadInfo(
          payload.postData.file_format,
          payload.url,
        );
      })
      .addCase(exportActions.downloadDataPanelSpreadsheetError, (state, { payload }) => {
        receiveDpDownloadError(state, payload.postData.id, 'Error downloading spreadsheet');
      })
      .addCase(downloadExploreComputationSpreadsheet.pending, (state, { meta }) => {
        state.dpDownloads[meta.arg.dataPanel.id] = setLoadingState(meta.arg.fileFormat, undefined);
      })
      .addCase(downloadExploreComputationSpreadsheet.fulfilled, (state, { payload, meta }) => {
        state.dpDownloads[meta.arg.dataPanel.id] = receiveDownloadInfo(
          meta.arg.fileFormat,
          payload['@type'] === 'link' ? payload.url : undefined,
        );
      })
      .addCase(downloadExploreComputationSpreadsheet.rejected, (state, { meta }) => {
        receiveDpDownloadError(state, meta.arg.dataPanel.id, 'Error downloading spreadsheet');
      })
      // Share Data
      .addCase(fetchShareIdThunk.pending, (state) => {
        state.shareData = RD.Loading();
      })
      .addCase(fetchShareIdThunk.fulfilled, (state, { payload }) => {
        state.shareData = RD.Success({
          id: payload.share_id,
          shareLinkUrl: payload.share_link_url,
        });
      })
      .addCase(fetchShareIdThunk.rejected, (state) => {
        state.shareData = RD.Error('Error loading share link');
      });
  },
});

export const {
  setChartMenu,
  setRequestInfo,
  setJwt,
  clearDashboardLayoutReducer,
  clearShareLink,
  clearDownloads,
  receiveFinishedJobs,
} = dashboardLayoutSlice.actions;

export const dashboardLayoutReducer = dashboardLayoutSlice.reducer;

export const getIsIframe = createSelector(
  (state: DashboardLayoutReducer) => state.requestInfo,
  isIframe,
);
