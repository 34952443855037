import { FC, useMemo, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetRow } from '@explo/data';

import { sprinkles } from 'components/ds/sprinkles.css';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { RowLevelAction } from 'constants/types';
import { sendCustomJSEventWithRowDataThunk } from 'reducers/thunks/customEventThunks';

import { EmbedButton } from '../EmbedButton';

import * as styles from './styles.css';

type Props = {
  containerRef?: MutableRefObject<HTMLElement | null>;
  rowLevelActions: RowLevelAction[];
  row?: DatasetRow;
  disableActiveCell?: () => void;
};

export const RowLevelActionsCell: FC<Props> = ({
  containerRef,
  rowLevelActions,
  row,
  disableActiveCell,
}) => {
  const dispatch = useDispatch();

  const menuOptions = useMemo<DropdownOption[]>(() => {
    return rowLevelActions.map(({ menuLabel, eventName }) => ({
      name: menuLabel,
      value: eventName,
    }));
  }, [rowLevelActions]);

  return (
    <div className={cellContainerStyle} onClick={(e) => e.stopPropagation()}>
      <EmbeddedDropdownMenu
        align="start"
        className={styles.menuContainer}
        menuOptions={menuOptions}
        onClick={(eventName) => dispatch(sendCustomJSEventWithRowDataThunk(eventName, row))}
        portalRef={containerRef}
        side="left">
        <EmbedButton icon="ellipsis-vertical" onClick={disableActiveCell} variant="tertiary" />
      </EmbeddedDropdownMenu>
    </div>
  );
};

const cellContainerStyle = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'flex-end',
  paddingX: 'sp2',
});
