import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createDashboard } from 'actions/dashboardActions';
import { createReportBuilder } from 'actions/reportBuilderActions';
import { Spinner, sprinkles } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { getUniqueNames } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import { listResourceContentNames } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { showErrorToast } from 'shared/sharedToasts';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { ResourcePageType, ResourceType } from 'types/exploResource';
import { getResourceNameErrorMessage, getResourceText } from 'utils/exploResourceUtils';

type Props = {
  isOpen: boolean;
  pageType: ResourcePageType;
  setCreateResourceModalOpen: (isOpen: boolean) => void;
};

const showCreationError = (pageType: ResourcePageType) => {
  showErrorToast(
    `There was an error creating your ${getResourceText(
      pageType,
    )}. Please try again or contact support if the error continues.`,
  );
};

export const CreateResourceModal: FC<Props> = ({
  isOpen,
  pageType,
  setCreateResourceModalOpen,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const { currentUserTeamId, currentFolderId, uniqueNames, isLoadingUniqueNames } = useSelector(
    (state: ReduxState) => ({
      currentUserTeamId: state.currentUser.team?.id,
      currentFolderId: state.folder.breadcrumbs[state.folder.breadcrumbs.length - 1]?.id,
      uniqueNames: getUniqueNames(state, isExploreProduct),
      isLoadingUniqueNames: isExploreProduct
        ? RD.isLoading(state.dashboard.currentFolderResourceNames)
        : RD.isLoading(state.reportBuilder.currentFolderResourceNames),
    }),
    shallowEqual,
  );

  useEffect(() => {
    const type = isExploreProduct ? ResourceType.DASHBOARD : ResourceType.REPORT;
    dispatch(listResourceContentNames({ id: currentFolderId, type }));
  }, [currentFolderId, dispatch, isExploreProduct]);

  if (!currentUserTeamId || !currentFolderId) return null;

  return (
    <TextFieldModal
      buttonName={`Create ${getResourceText(pageType, { capitalized: true })}`}
      closeModal={() => setCreateResourceModalOpen(false)}
      getErrorMessage={(input) => getResourceNameErrorMessage(input, uniqueNames)}
      modalOpen={isOpen}
      modalTitle={`Create ${getResourceText(pageType)}`}
      onSubmit={(name) => {
        if (isExploreProduct) {
          dispatch(
            createDashboard(
              { id: currentUserTeamId, postData: { name, parent_id: currentFolderId } },
              (data) => {
                trackEvent(EVENTS.CREATED_DASHBOARD, {
                  dashboard_template_id: data.new_dashboard_template.id,
                  dashboard_name: name,
                });
                setCreateResourceModalOpen(false);
                history.push(
                  ROUTE_PROVIDERS.DASHBOARD_EDIT_MODE(String(data.new_dashboard_template.id)),
                );
              },
              () => showCreationError(pageType),
            ),
          );
        } else {
          dispatch(
            createReportBuilder(
              { postData: { name, parent_id: currentFolderId } },
              (data) => {
                setCreateResourceModalOpen(false);
                history.push(
                  ROUTE_PROVIDERS.REPORT_BUILDER_DATASET_EDIT(String(data.report_builder.id)),
                );
              },
              () => showCreationError(pageType),
            ),
          );
        }
      }}
      textFieldPlaceholder={`Enter ${getResourceText(pageType)} name`}>
      <div>
        {isLoadingUniqueNames ? (
          <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
            <Spinner />
          </div>
        ) : undefined}
      </div>
    </TextFieldModal>
  );
};
