export enum EmbeddedDashboardType {
  EMBEDDED = 'embedded',
  IFRAME = 'iframe',
  SHARED = 'shared',
  PORTAL = 'portal',
  EMAIL = 'email',
  PDF = 'pdf',
  PREVIEW = 'preview',
  SCREENSHOT = 'screenshot',
}
