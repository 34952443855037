import { createAction } from '@reduxjs/toolkit';

import {
  BaseCol,
  DatabaseUnsupportedOperations,
  DatasetRow,
  DatasetSchema,
  SchemaDisplayOption,
} from '@explo/data';

import { defineAPIPostAction } from 'actions/actionUtils';
import { FetchDashboardDatasetPreviewData, QueryDebuggingInformation } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { COLUMN_FITS } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';

export interface DataTable {
  name: string;
  columns: BaseCol[];
}

export interface DrilldownColConfig {
  displayName: string; // defaults to title case of name
  index: number;
  isIncluded: boolean; // True implies will be included in data fetching requests
  isVisible: boolean; //True implies will be seen on load
  displayFormatting?: SchemaDisplayOption;
}

export interface DrilldownConfig {
  columnFit?: COLUMN_FITS;
  ignoreInvalidDates?: boolean;
}

// Config shared between Datasets and ComputedViews
export interface DatasetConfig {
  id: string;
  queryDraft?: string;
  drilldownColumnConfigs?: Record<string, DrilldownColConfig>; // key : col name
  drilldownConfig?: DrilldownConfig;
  // attached in embedded environments
  query_variables?: string[];

  namespace_id?: string;
  fido_id?: string;
}

export interface DatasetData {
  // Set in FE
  loading: boolean;
  // Attached from BE
  rows?: DatasetRow[];
  totalRowCount?: number;
  schema?: DatasetSchema;
  error?: string;
  unsupportedOperations?: DatabaseUnsupportedOperations[];
  queryInformation?: QueryDebuggingInformation;
}

export type DatasetDataObject = Record<string, DatasetData | undefined>;

// Represents a type that holds dataset data for versioned datasets. The first key is the dataset
// id, the second key is the version id.
export type VersionedDatasetDataMap = Record<string, Record<string, DatasetData>>;

export interface Dataset extends DatasetConfig {
  query?: string;
  // not actually name of table, should switch to name
  table_name: string;
  parent_schema_id: number;
  schema?: DatasetSchema;
}

type SaveDatasetQueryArgs = {
  query: string;
  dataset_id: string;
  schema: DatasetSchema;
};

export const saveDatasetQuery = createAction<SaveDatasetQueryArgs>(ACTION.SAVE_DATASET_QUERY);

type SaveDraftDatasetQueryArgs = {
  queryDraft?: string;
  dataset_id: string;
};

export const saveDraftDatasetQuery = createAction<SaveDraftDatasetQueryArgs>(
  ACTION.SAVE_DRAFT_DATASET_QUERY,
);

export const saveDraftComputedViewQuery = createAction<{
  viewId: string;
  queryDraft: string | undefined;
}>(ACTION.SAVE_DRAFT_COMPUTED_VIEW_QUERY);

export const deleteDataset = createAction<{ datasetId: string }>(ACTION.DELETE_DATASET);

type EditDatasetNameArgs = { datasetId: string; name: string };
export const editDatasetName = createAction<EditDatasetNameArgs>(ACTION.EDIT_DATASET_NAME);

export type DrilldownColConfigOptions = {
  displayName?: string;
  index?: number;
  isIncluded?: boolean;
  isVisible?: boolean;
  displayFormatting?: SchemaDisplayOption;
};

type UpdateDatasetDrilldownColConfigArgs = DrilldownColConfigOptions & {
  datasetId: string;
  colName: string;
};

export const updateDatasetDrilldownColConfig = createAction<UpdateDatasetDrilldownColConfigArgs>(
  ACTION.UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIG,
);
type UpdateDatasetDrilldownColConfigsArgs = {
  datasetId: string;
  newConfigs: Record<string, DrilldownColConfig>;
};
export const updateDatasetDrilldownColConfigs = createAction<UpdateDatasetDrilldownColConfigsArgs>(
  ACTION.UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIGS,
);

// Requests For Dashboard Editor Page

export type FetchDashboardDatasetPreviewBody = {
  dataset_id: string;
  query_limit: number;
  variables: DashboardVariableMap;
  timezone?: string;

  // non-embedded
  query?: string;
  parent_schema_id?: number;
  resource_id?: number;
};

export const {
  requestAction: fetchDashboardDatasetPreviewRequest,
  successAction: fetchDashboardDatasetPreviewSuccess,
  errorAction: fetchDashboardDatasetPreviewError,
} = defineAPIPostAction<FetchDashboardDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.FETCH_DASHBOARD_DATASET_PREVIEW,
  'datasets',
  'get_preview',
  'POST',
);

export type FetchEditorDatasetPreviewBody = {
  dataset_id: string;
  query: string;
  parent_schema_id: number;
  resource_id?: number;
  timezone?: string;

  variables: DashboardVariableMap;
  customer_id: number | undefined;
  offset?: number;
};

export const {
  requestAction: fetchEditorDatasetPreviewRequest,
  successAction: fetchEditorDatasetPreviewSuccess,
  errorAction: fetchEditorDatasetPreviewError,
} = defineAPIPostAction<FetchEditorDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.FETCH_EDITOR_DATASET_PREVIEW,
  'datasets',
  'get_preview',
  'POST',
);

export const {
  successAction: fetchEditorDatasetRowCountSuccess,
  errorAction: fetchEditorDatasetRowCountError,
} = defineAPIPostAction<FetchEditorDatasetPreviewBody, { _total_row_count: number }>(
  ACTION.FETCH_EDITOR_DATASET_ROW_COUNT,
  'datasets',
  'get_row_count',
  'POST',
);

export const updateDashboardDatasetSchema = createAction<{
  datasetId: string;
  newParentSchemaId: number;
}>(ACTION.UPDATE_DATASET_SCHEMA);

export const setSelectedDrilldownColumn = createAction<{ name: string; type: string } | undefined>(
  ACTION.SET_SELECTED_DRILLDOWN_COLUMN,
);
