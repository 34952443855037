import { FC } from 'react';

import {
  BaseCol,
  DatasetRow,
  DatasetSchema,
  getCurrentStringFormat,
  getCurrentUrlFormat,
  ImageShapeFormat,
  StringDisplayFormat,
  StringDisplayOptions,
  StringUrlDisplayFormat,
} from '@explo/data';

import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { InfoCard } from 'components/InfoCard';
import { getCellAlignment } from 'components/dataTable/utils';
import { Input, Select, sprinkles, Switch, Toggle, ToggleItem } from 'components/ds';
import { isValidHex } from 'utils/general';

import { GlobalStyleConfig } from 'globalStyles/types';
import { CategoryFieldColorAssignment } from './CategoryFieldColorAssignment';

const inputClass = sprinkles({ marginTop: 'sp1' });

type Props = {
  column: BaseCol;
  dataPanelData: DatasetRow[];
  displayOptions: StringDisplayOptions | undefined;
  originalSchema: DatasetSchema;
  updateStringOptions: (newFields: StringDisplayOptions, updateColors?: boolean) => void; // If updateColors is true, update color category tracker
  globalStyleConfig: GlobalStyleConfig;
};

export const StringFormatConfig: FC<Props> = ({
  updateStringOptions,
  column,
  displayOptions,
  dataPanelData,
  originalSchema,
  globalStyleConfig,
}) => {
  const { label, urlFormat, urlColumnName, imageShape, openInSameTab, urlLinkColor } =
    displayOptions ?? {};

  const currentFormat = getCurrentStringFormat(displayOptions);

  const currentAlignment = getCellAlignment(displayOptions, column.type);
  const currentShape = imageShape ?? ImageShapeFormat.RECTANGLE;

  const selectedUrlFormat = getCurrentUrlFormat(urlFormat);
  const urlColumnOptions = originalSchema.map((col) => ({
    value: col.name,
  }));

  return (
    <>
      <Toggle
        className={inputClass}
        label="Format"
        onValueChange={(format) => {
          if (format === currentFormat) return;
          updateStringOptions(
            { format: format as StringDisplayFormat },
            format === StringDisplayFormat.CATEGORY,
          );
        }}
        selectedValue={currentFormat}>
        {Object.values(StringDisplayFormat).map((format) => (
          <ToggleItem key={format} value={format} />
        ))}
      </Toggle>
      {currentFormat === StringDisplayFormat.LINK ? (
        <>
          <InfoCard text="The linked URL must be prefixed with http:// or https:// to redirect to a new domain. If not, then the link will navigate within the domain that this dashboard is embedded in." />
          <Toggle
            className={inputClass}
            onValueChange={(value) =>
              updateStringOptions({ urlFormat: value as StringUrlDisplayFormat })
            }
            selectedValue={selectedUrlFormat}>
            {Object.values(StringUrlDisplayFormat).map((urlDisplayFormat) => (
              <ToggleItem key={urlDisplayFormat} value={urlDisplayFormat} />
            ))}
          </Toggle>
          {selectedUrlFormat === StringUrlDisplayFormat.LABEL ? (
            <Input
              showInputButton
              className={inputClass}
              defaultValue={label}
              onSubmit={(newLabel) => updateStringOptions({ label: newLabel })}
              placeholder="Enter Label"
            />
          ) : null}
          {selectedUrlFormat === StringUrlDisplayFormat.COLUMN ? (
            <Select
              className={inputClass}
              onChange={(urlColumnName) => updateStringOptions({ urlColumnName })}
              placeholder="Select Column"
              selectedValue={urlColumnName}
              values={urlColumnOptions}
            />
          ) : null}
          <Switch
            className={inputClass}
            label="Open in new tab"
            onChange={() => updateStringOptions({ openInSameTab: !openInSameTab })}
            switchOn={!openInSameTab}
          />
          <Input
            showInputButton
            className={inputClass}
            defaultValue={urlLinkColor ?? ''}
            label={{ text: 'Link Color', infoText: 'Enter a valid hex code' }}
            onSubmit={(linkHex) =>
              updateStringOptions({ urlLinkColor: isValidHex(linkHex) ? linkHex : undefined })
            }
          />
        </>
      ) : null}
      {currentFormat === StringDisplayFormat.CATEGORY ? (
        <CategoryFieldColorAssignment
          column={column}
          dataPanelData={dataPanelData}
          displayOptions={displayOptions}
          globalStyleConfig={globalStyleConfig}
          updateStringOptions={(options) => updateStringOptions(options, true)}
        />
      ) : null}
      {currentFormat === StringDisplayFormat.IMAGE ? (
        <Toggle
          className={inputClass}
          label="Format"
          onValueChange={(value) => updateStringOptions({ imageShape: value as ImageShapeFormat })}
          selectedValue={currentShape}>
          <ToggleItem icon="square" key="square" value={ImageShapeFormat.RECTANGLE} />
          <ToggleItem icon="circle" key="circle" value={ImageShapeFormat.CIRCLE} />
        </Toggle>
      ) : null}
      <HorizontalAlignmentToggle
        className={inputClass}
        label="Alignment"
        selectedAlignment={currentAlignment}
        updateAlignment={(alignment) => updateStringOptions({ alignment })}
      />
    </>
  );
};
