import Highcharts from 'highcharts';
import { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DatasetSchema, DATE_TYPES, getTimezoneAwareUnix, OPERATION_TYPES } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import {
  ColumnColorTracker,
  SpiderChartShape,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { getColorFromPaletteTracker } from 'utils/colorCategorySyncUtils';
import { getColorColumn, isSelectedColorDateType } from 'utils/colorColUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { replaceVariablesInString } from 'utils/variableUtils';

import { SeriesOptions } from './constants/types';
import { HighCharts } from './highCharts';
import {
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getColorPalette,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  shouldProcessColAsDate,
  xAxisFormat,
} from './utils';
import {
  createYAxisBaseTooltip,
  getSingleYAxisInstructions,
  getValueFormat,
} from './utils/multiYAxisUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

type Props = {
  backgroundColor: string;
  colorTracker?: ColumnColorTracker;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  normalize?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
};

const opType = OPERATION_TYPES.VISUALIZE_SPIDER_CHART;

type State = {};

class SpiderChart extends PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  render() {
    const { instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions, opType);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      normalize,
      backgroundColor,
      globalStyleConfig,
      variables,
      datasetData,
      datasetNamesToId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const showMarkers = !instructions?.chartSpecificFormat?.lineChart?.hideMarkers;
    const lineWidth =
      instructions?.chartSpecificFormat?.spiderChart?.lineWidth ||
      globalStyleConfig.container.lineWidth.default;
    const data = this.filterToMaxCategories(this.transformData());

    return {
      chart: {
        type: 'line',
        polar: true,
        backgroundColor,
      },
      series: data,
      title: sharedTitleConfig,
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      plotOptions: {
        series: {
          animation: false,
          states: {
            hover: {
              borderColor: '#000000',
            },
          },
          dataLabels: {
            enabled: !instructions?.xAxisFormat?.hideTotalValues,

            formatter: function () {
              return formatValue({
                value: this.y || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
          lineWidth: lineWidth,
          //@ts-ignore
          borderRadius: instructions?.xAxisFormat?.barCornerRadius,
        },
        spline: {
          marker: {
            enabled: showMarkers,
          },
        },
        line: {
          marker: {
            enabled: showMarkers,
          },
        },
      },
      yAxis: {
        ...getSingleYAxisInstructions(
          globalStyleConfig,
          instructions,
          variables,
          datasetNamesToId,
          datasetData,
        ),
        gridLineInterpolation:
          instructions?.chartSpecificFormat?.spiderChart?.spiderChartShape ===
          SpiderChartShape.POLYGON
            ? 'polygon'
            : 'circle',
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: this.getXAxisType(),
        categories: this.getAxisCategories(),
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.categoryColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
        lineWidth: 0, // override the xAxisFormat for spider chart
      },
      legend: formatLegend(globalStyleConfig, instructions?.legendFormat),
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalStyleConfig,
              instructions,
              includePercent: normalize || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
      },
    };
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getXAxisType = () => {
    const { instructions } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
  };

  getAxisCategories = () => {
    const { instructions, previewData } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;
    const xAxisColName = this.getXAxisColName();

    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema || schema.length === 0)
      return;

    const xAxisColName = this.getXAxisColName();
    const colorColName = getColorColNames(schema, opType).colorColName;

    if (!instructions?.categoryColumn?.column.type) return;

    previewData.forEach((row) => {
      // If it's a number, it has already been converted to milliseconds
      if (categoryColIsDate && typeof row[xAxisColName] !== 'number')
        row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);

      if (colorColIsDate && typeof row[xAxisColName] !== 'number')
        row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
    });
  };

  transformData = (): SeriesOptions[] => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let seriesList;
    if (instructions.colorColumnOptions?.length) {
      seriesList = this.transformColorData(schema);
    } else {
      seriesList = this.transformAggColsData(schema);
    }

    return seriesList;
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] => {
    const { instructions, previewData, selectedColorColName, colorTracker } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema, opType);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const categoryOrder = this.getAxisCategories();

    const series: Record<string, SeriesOptions> = {};

    const selectedColorCol = getColorColumn(instructions, selectedColorColName);

    previewData.forEach((row) => {
      if (isDate && row[xAxisColName] === undefined) return;
      const colorValue = row[colorColName];
      const colorCategory = formatLabel(
        colorValue,
        selectedColorCol?.column.type,
        selectedColorCol?.bucket?.id,
      );

      const value = getAxisNumericalValue(row[aggColName]);

      if (isNaN(value)) return;
      const entry = isDate
        ? [getAxisNumericalValue(row[xAxisColName]), value]
        : {
            name: String(row[xAxisColName]),
            y: value,
            x: categoryOrder?.indexOf(String(row[xAxisColName])),
          };
      if (series[colorCategory]) {
        series[colorCategory].data.push(entry);
      } else {
        series[colorCategory] = {
          type: 'line',
          name: colorCategory,
          rawColorData: colorValue,
          data: [entry],
          color: getColorFromPaletteTracker({
            columnName: colorColName,
            valueName: String(colorValue),
            colorTracker,
          }),
        };
      }
    });

    const seriesData = Object.values(series);

    if (isDate && instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
      seriesData.forEach((series) => series.data.pop());
      return seriesData;
    }

    return seriesData;
  };

  transformAggColsData = (schema: DatasetSchema): SeriesOptions[] => {
    const { previewData, instructions, variables, datasetNamesToId, datasetData } = this.props;
    const xAxisColName = schema[0].name;
    const aggCols = instructions?.aggColumns || [];
    const aggColNames = schema.map((col) => col.name).slice(1);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    const series: Record<string, SeriesOptions> = {};

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      aggColNames.forEach((colName, index) => {
        if (isDate && row[xAxisColName] === undefined) return;
        const aggCol = aggCols[index];

        const rawValue = row[colName];
        // Allow nulls as we don't filter out the categories in the x-axis whose values are null.
        // Highcharts will also correctly handle null values by ignoring categories with null
        // values.
        const value = rawValue == null ? rawValue : getAxisNumericalValue(rawValue);
        const x = row[xAxisColName];

        if (isNaN(value)) return;

        const entry = isDate
          ? [getAxisNumericalValue(row[xAxisColName]), value]
          : { name: String(x), y: value };

        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          const name = aggCol.column.friendly_name
            ? replaceVariablesInString(
                aggCol.column.friendly_name,
                variables,
                datasetNamesToId,
                datasetData,
              )
            : getColDisplayText(aggCol) || colName;
          series[colName] = { type: 'line', name, data: [entry] };
        }
      });
    });
    return Object.values(series);
  };

  filterToMaxCategories = (data: SeriesOptions[]) => {
    const { instructions } = this.props;
    const maxCategories = instructions?.xAxisFormat?.maxCategories;

    // PD-1183: max categories isn't going to be supported for charts broken down by color for now
    if (!maxCategories || instructions?.colorColumnOptions?.length) return data;

    data.forEach((series) => {
      series.data = series.data.slice(0, maxCategories);
    });
    return data;
  };
}

export default SpiderChart;
