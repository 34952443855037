import { Computation, ExportFormat, QueryExportResponse } from '@explo-tech/fido-api';
import {
  ComputationConfig,
  ExportTargetConfiguration,
  ResponseBody,
  RoverResourceService,
} from '@explo/rover-typescript-client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomerReportView, FetchCustomerReportsTeamData } from 'actions/customerReportActions';
import { ExportType } from 'actions/exportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { DEFAULT_NO_REPLY_EMAIL } from 'constants/emailConstants';
import { EmailCadence, ExportBody } from 'reportBuilderContent/exportTypes';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportType } from 'reportBuilderContent/reducers/types';

import {
  createDatasetSchemaMap,
  generateReportBuilderComputations,
  generateReportBuilderExportColumnOptions,
  sanitizeFileNameForExports,
} from '@explo/computation-generation';
import { Settings } from 'luxon';
import { mapCustomerReportRelativeFilters } from 'reportBuilderContent/thunks/utils';
import { getReportBuilderTestEmailContent } from 'utils/emailUtils';
import { getDataSource } from 'utils/fido/fidoRequestUtils';
import { makeRoverThunkRequest } from 'utils/thunkUtils';

export const sendReportBuilderTestDraftExportThunk = createAsyncThunk<
  QueryExportResponse | ResponseBody,
  {
    exportBody: ExportBody;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.SEND_ROVER_REPORT_BUILDER_DRAFT_TEST_EMAIL,
  async ({ exportBody }, { getState, rejectWithValue }) =>
    sendReportBuilderExport({ exportBody, state: getState() }).catch((e) => rejectWithValue(e)),
);

export const sendReportBuilderTestExportThunk = createAsyncThunk<
  QueryExportResponse | ResponseBody,
  {
    exportBody: EmailCadence;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.SEND_ROVER_REPORT_BUILDER_TEST_EMAIL,
  async ({ exportBody }, { getState, rejectWithValue }) =>
    sendReportBuilderExport({ exportBody, state: getState() }).catch((e) => rejectWithValue(e)),
);

export const sendReportBuilderExport = ({
  exportBody,
  state,
}: {
  exportBody: ExportBody;
  state: ReportBuilderReduxState;
}) => {
  if (exportBody.export_type === ExportType.PDF || exportBody.export_type === ExportType.Image) {
    throw new Error('Screenshots not supported by ROVER');
  }

  const { embeddedReportBuilder, reportEditing, fido, analytics } = state;
  const { currentConfig } = reportEditing;
  const { team, teamCurrencyCode } = embeddedReportBuilder;

  const dataset =
    embeddedReportBuilder.reportBuilderVersion && currentConfig?.dataInfo
      ? embeddedReportBuilder.reportBuilderVersion.config.datasets[currentConfig.dataInfo.datasetId]
      : undefined;

  if (!team || !currentConfig?.views || !dataset) throw new Error('No valid report');

  const { namespace_id: namespaceId, fido_id: viewId } = dataset;

  if (!namespaceId || !viewId) throw new Error('Unable to find namespace or view id');

  const dataSourceId = getDataSource({ namespaceId, type: 'embedded' }, fido);

  if (!dataSourceId) throw new Error('Unable to find data source');

  const computationConfigs = currentConfig.views.map((view) =>
    createComputationConfig({
      dataSourceId,
      namespaceId,
      viewId,
      view,
      dataset,
      exportBody,
      currencyCode: teamCurrencyCode,
    }),
  );

  return makeRoverThunkRequest(
    () =>
      RoverResourceService.tabularExport({
        authorization: {
          fidoToken: fido.fidoToken ?? '',
        },
        loggingMetadata: {
          teamId: team.id.toString(),
          team: team.name,
          emailType:
            reportEditing.selectedReport?.type === ReportType.CUSTOMER_REPORT
              ? 'customer_report'
              : 'built_in',
          userId: analytics.visitorId ?? undefined,
          customerId: analytics.analyticsProperties?.['customer_id'] as string | undefined,
        },
        exportMetadata: {
          emailConfiguration: {
            fromEmail: getFromEmail(team),
            toEmails: exportBody.recipients,
            subject: exportBody.subject,
            emailContentArgs: {
              ...getReportBuilderTestEmailContent(exportBody.message),
              '@type': 'report-builder',
            },
          },
          queryContext: embeddedReportBuilder.variables,
        },
        computationConfigs,
      }),
    'Error downloading your data panel',
  );
};

const createComputationConfig = ({
  dataSourceId,
  namespaceId,
  viewId,
  view,
  dataset,
  exportBody,
  currencyCode,
}: {
  dataSourceId: string;
  namespaceId: string;
  viewId: string;
  view: CustomerReportView;
  dataset: ReportBuilderDataset;
  exportBody: ExportBody;
  currencyCode: string | undefined;
}): ComputationConfig => {
  const groupings = (view.groupBys ?? []).concat(view.columnGroupBys ?? []).map((g) => ({
    column: g.column,
    bucket: g.bucket ? { id: g.bucket } : undefined,
  }));

  const computation = generateReportBuilderComputations(
    {
      aggs: view.aggregations || [],
      columns: view.columnOrder,
      filters: mapCustomerReportRelativeFilters(view.filters, exportBody.timezone),
      sort: view.sort || [],
      group_bys: groupings,
      hidden_columns: view.hiddenColumns,
    },
    dataset.customAggregations,
    exportBody.timezone,
  ).dataComputation;

  let exportFormat: ExportFormat;
  switch (exportBody.export_type) {
    case 'csv':
      exportFormat = ExportFormat.CSV;
      break;
    case 'xlsx':
      exportFormat = ExportFormat.XLSX;
      break;
    default:
      throw new Error(`Invalid file format: ${exportBody.export_type}`);
  }

  const exportConfiguration: ExportTargetConfiguration = {
    fileName: sanitizeFileNameForExports(view.name),
    exportFormat,
    // @ts-ignore
    columnDisplayOptions: generateReportBuilderExportColumnOptions(
      computation,
      dataset.columnConfigs,
      createDatasetSchemaMap(dataset.schema),
      exportFormat,
      { timezone: exportBody.timezone, currencyCode: currencyCode, locale: Settings.defaultLocale },
    ),
  };

  return {
    dataSourceId,
    namespaceId,
    viewId,
    ...(computation as Computation),
    ...exportConfiguration,
  };
};

const getFromEmail = (team: FetchCustomerReportsTeamData) => {
  const email = team.configuration?.email_from_email || DEFAULT_NO_REPLY_EMAIL;

  return team.configuration?.email_from_name
    ? `${team.configuration.email_from_name} <${email}>`
    : email;
};
