import { sprinkles } from '@explo/design-system';
import cx from 'classnames';
import { Icon } from 'components/ds';
import { FC } from 'react';
import { COMPUTED_VIEW_TYPE } from './constants';

interface Props {
  resourceDisplay: string;
  resourceType: string;
  className?: string;
  additionalRightSideElement?: JSX.Element;
}

export const ResourceControl: FC<Props> = ({
  resourceDisplay,
  resourceType,
  className,
  additionalRightSideElement,
}) => {
  const icon = resourceType === COMPUTED_VIEW_TYPE ? 'table' : 'folder';
  return (
    <div
      className={cx(
        className,
        sprinkles({
          display: 'flex',
          flexItems: 'alignCenterBetween',
          overflow: 'hidden',
          width: 'fill',
        }),
      )}>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        <Icon className={sprinkles({ marginRight: 'sp1' })} name={icon} />
        <div className={sprinkles({ truncateText: 'ellipsis', maxWidth: 'fill' })}>
          {resourceDisplay}
        </div>
      </div>
      {additionalRightSideElement}
    </div>
  );
};
