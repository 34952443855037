import { Feature, FeatureCollection, Point } from 'geojson';
import { ExpressionSpecification } from 'mapbox-gl';
import { FC, useMemo } from 'react';
import { HeatmapLayer, Layer, Map, MapRef, Source, NavigationControl } from 'react-map-gl';
import ResizeObserver from 'react-resize-observer';
import { v4 } from 'uuid';

import { DatasetRow } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import {
  DEFAULT_MAP_STYLE,
  INITIAL_VIEW_STATE,
  INTENSITY,
  MAP_STYLE_TO_MAPBOX_URL,
  OPACITY,
  RADIUS,
} from 'constants/maps';
import { VisualizeGeospatialChartInstructions } from 'constants/types';
import { getColorZones } from 'pages/dashboardPage/charts/utils';
import { DashboardVariableMap } from 'types/dashboardTypes';

import 'mapbox-gl/dist/mapbox-gl.css';

import { validateLatLongValues } from '../mapUtils';

type Props = {
  data: DatasetRow[];
  instructions: VisualizeGeospatialChartInstructions;
  mapRef: React.RefObject<MapRef>;
  debouncedMapResize: () => void;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  variables: DashboardVariableMap;
  dataPanelId: string;
};

const createFeature = (latitude: number, longitude: number) =>
  ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    } as Point,
  }) as Feature;

export const DensityMap: FC<Props> = ({
  data,
  instructions,
  debouncedMapResize,
  mapRef,
  datasetData,
  datasetNamesToId,
  variables,
}) => {
  const mapFormat = instructions.densityMapFormat;
  const intensity = mapFormat?.intensity ?? INTENSITY;
  const opacity = mapFormat?.opacity ?? OPACITY;
  const radius = mapFormat?.radius ?? RADIUS;

  const latitudeColumnName = instructions.latitudeColumn?.name;
  const longitudeColumnName = instructions.longitudeColumn?.name;
  const weightColumnName = instructions.weightColumn?.name;

  const [features, key] = useMemo(() => {
    const featureList: Feature[] = [];
    data.forEach((pt) => {
      const latitudeVal = latitudeColumnName ? Number(pt[latitudeColumnName]) : undefined;
      const longitudeVal = longitudeColumnName ? Number(pt[longitudeColumnName]) : undefined;
      const weightVal = weightColumnName ? Number(pt[weightColumnName]) : undefined;

      if (
        latitudeVal === undefined ||
        longitudeVal === undefined ||
        !validateLatLongValues(latitudeVal, longitudeVal)
      )
        return;

      const feature = createFeature(latitudeVal, longitudeVal);

      // Add the weight as a property of the feature
      if (weightVal !== undefined) {
        feature.properties = { weight: weightVal };
      }

      featureList.push(feature);
    });
    return [featureList, v4()];
  }, [data, latitudeColumnName, longitudeColumnName, weightColumnName]);

  const colorValues = useMemo(() => {
    const zones = getColorZones(
      mapFormat?.colorFormat,
      variables,
      datasetNamesToId,
      datasetData,
      true,
    );
    // starting color is always transparent so we can see the map where there is 0 density
    const values: Partial<ExpressionSpecification> = [0, 'rgba(0,0,0,0)'];
    // mapbox only accepts threshold values in ascending order
    const sortedColorValues = [...(zones ?? [])]?.sort((colorZone1, colorZone2) => {
      const threshold1 = colorZone1?.value ?? 1.0;
      const threshold2 = colorZone2?.value ?? 1.0;
      return threshold1 - threshold2;
    });

    sortedColorValues?.forEach((colorZone) => {
      values.push(colorZone.value ?? 1.0);
      values.push(colorZone.color);
    });
    return values;
  }, [variables, datasetNamesToId, datasetData, mapFormat?.colorFormat]);

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features,
  };

  const layerStyle = {
    id: 'point',
    type: 'heatmap',
    source: 'geojson',
    paint: {
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, intensity, 15, intensity],
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, radius, 15, radius],
      'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, opacity, 15, opacity],
      // Use the weight property, if available, otherwise default to 1
      'heatmap-weight': ['coalesce', ['get', 'weight'], 1],
      // use default colors if there are no color zones set
      ...(mapFormat?.colorFormat?.colorZones?.length && {
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          ...colorValues,
        ] as ExpressionSpecification,
      }),
    },
  } as HeatmapLayer;

  return (
    <div className={sprinkles({ height: 'fill', width: 'fill' })}>
      <Map
        dragRotate
        attributionControl={false}
        cursor="grab"
        initialViewState={mapFormat?.viewState ?? INITIAL_VIEW_STATE}
        key={key} // Key is refreshed every time the data updates. This forces the map to rerender to prevent bugginess in display.
        mapLib={import(/* webpackChunkName: "mapbox-gl" */ 'mapbox-gl')}
        mapStyle={MAP_STYLE_TO_MAPBOX_URL[mapFormat?.style || ''] ?? DEFAULT_MAP_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        ref={mapRef}>
        <Source data={geojson} id="geojson" type="geojson">
          <Layer {...layerStyle} />
        </Source>
        <NavigationControl showCompass showZoom />
      </Map>
      <ResizeObserver onResize={debouncedMapResize} />
    </div>
  );
};
