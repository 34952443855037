import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES, VISUALIZE_TABLE_OPERATIONS_SET } from '@explo/data';

import { updateExportConfigThunk } from 'actions/dataPanelConfigActions';
import {
  ConfigSubSectionTitle,
  ConfigSubSectionWithSwitch,
  ConfigSubSectionWrapper,
} from 'components/PanelComponents/ConfigSubSection';
import { Checkbox, Input, Toggle, ToggleItem, sprinkles } from 'components/ds';
import { VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { AllStates } from 'reducers/rootReducer';
import {
  selectEnableEmailExports,
  selectEnableScreenshotExports,
  selectShouldUseJobQueue,
} from 'reducers/selectors';

import { TablePDFConfig } from './TablePdfConfig';

type Props = {
  dpProvidedId: string;
  options: VisualizeOperationGeneralFormatOptions;
  visualizationType: OPERATION_TYPES;
};

enum ExportType {
  Download = 'Download',
  Email = 'Email',
  Both = 'Both',
}

export const ExportsConfig: FC<Props> = ({ options, visualizationType, dpProvidedId }) => {
  const dispatch = useDispatch();

  const { shouldUseJobQueue, enableScreenshotExports, enableEmailExports } = useSelector(
    (state: AllStates) => ({
      shouldUseJobQueue: selectShouldUseJobQueue(state),
      enableScreenshotExports: selectEnableScreenshotExports(state),
      enableEmailExports: selectEnableEmailExports(state),
    }),
    shallowEqual,
  );

  const config = options.export ?? {};
  const { csvFormat } = config;

  const isDownloadOn = !config.disableDownloadExport;
  const isEmailOn = !!config.enableEmailExport;

  let currExportType = undefined;
  if (isDownloadOn) {
    currExportType = isEmailOn ? ExportType.Both : ExportType.Download;
  } else if (isEmailOn) currExportType = ExportType.Email;

  const isTableOperation = VISUALIZE_TABLE_OPERATIONS_SET.has(visualizationType);

  const renderCsvExportOptions = () => {
    return (
      <>
        <ConfigSubSectionWithSwitch
          label="CSV"
          onChange={() =>
            dispatch(
              updateExportConfigThunk({ disableCsvExport: !config.disableCsvExport }, options),
            )
          }
          switchOn={!config.disableCsvExport}>
          <Checkbox
            isChecked={!!csvFormat?.tsvEnabled}
            label="Tab delimited file"
            onChange={() =>
              dispatch(
                updateExportConfigThunk(
                  {
                    csvFormat: { ...csvFormat, tsvEnabled: !csvFormat?.tsvEnabled },
                  },
                  options,
                ),
              )
            }
          />
        </ConfigSubSectionWithSwitch>
        <ConfigSubSectionWithSwitch
          label="XLSX"
          onChange={() =>
            dispatch(
              updateExportConfigThunk({ disableXlsxExport: !config.disableXlsxExport }, options),
            )
          }
          switchOn={!config.disableXlsxExport}
        />
      </>
    );
  };

  const renderImageExportOptions = () => {
    return !isTableOperation && enableScreenshotExports ? (
      <ConfigSubSectionWithSwitch
        label="Image"
        onChange={() =>
          dispatch(
            updateExportConfigThunk({ disableImageExport: !config.disableImageExport }, options),
          )
        }
        switchOn={!config.disableImageExport}></ConfigSubSectionWithSwitch>
    ) : null;
  };

  const renderTableExportOptions = () => {
    return isTableOperation && enableScreenshotExports ? (
      <TablePDFConfig options={options} />
    ) : null;
  };

  return (
    <>
      <ConfigSubSectionTitle
        isOn={!config.isDownloadButtonHidden}
        onChange={() =>
          dispatch(
            updateExportConfigThunk(
              { isDownloadButtonHidden: !config.isDownloadButtonHidden },
              options,
            ),
          )
        }
        title="Exports"
      />
      {!config.isDownloadButtonHidden ? (
        <ConfigSubSectionWrapper>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
            {shouldUseJobQueue && enableEmailExports ? (
              <Toggle
                label="Format"
                onValueChange={(newType) =>
                  dispatch(
                    updateExportConfigThunk(
                      {
                        disableDownloadExport: newType === ExportType.Email,
                        enableEmailExport: newType !== ExportType.Download,
                      },
                      options,
                    ),
                  )
                }
                selectedValue={currExportType}>
                <ToggleItem value={ExportType.Download} />
                <ToggleItem value={ExportType.Email} />
                <ToggleItem value={ExportType.Both} />
              </Toggle>
            ) : null}
            <Input
              defaultValue={config.downloadFileName || dpProvidedId}
              label="File name"
              onSubmit={(newVal) =>
                dispatch(updateExportConfigThunk({ downloadFileName: newVal }, options))
              }
            />
          </div>
          {renderTableExportOptions()}
          {renderCsvExportOptions()}
          {renderImageExportOptions()}
        </ConfigSubSectionWrapper>
      ) : null}
    </>
  );
};
