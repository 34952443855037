import { CreateResourceChange, Folder } from '@explo-tech/fido-api';

// TODO(zifanxiang): Add tests.
export const sanitizeFolder = (folder: Folder): Folder => {
  const wellFormedChildren =
    folder.children?.filter((child) => {
      return child.id && child.versionId;
    }) ?? [];

  return {
    ...folder,
    children: wellFormedChildren,
  };
};

/**
 * @return A new array of CreateResourceChange objects with the id and versionId fields removed.
 */
export const stripIdsFromPendingCreations: (
  pendingResourceCreations: CreateResourceChange[],
) => CreateResourceChange[] = (pendingResourceCreations) => {
  return pendingResourceCreations.map((pendingResourceCreation) => {
    return {
      ...pendingResourceCreation,
      resource: {
        ...pendingResourceCreation.resource,
        id: undefined,
        versionId: undefined,
      },
    };
  });
};
