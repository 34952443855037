import { CreateResourceChange, Folder, Resource, UpdateResourceChange } from '@explo-tech/fido-api';
import { FOLDER_TYPE } from 'pages/dataLibraryPage/constants';
import { getParentPath } from 'pages/dataLibraryPage/dataLibraryUtil';
import { DeleteResourceChange } from 'pages/dataLibraryPage/types';

import { ResponseData, isSuccess } from 'remotedata';

export const validatePendingChanges = (
  pendingResourceCreations: Map<string, CreateResourceChange>,
  pendingResourceUpdates: Map<string, UpdateResourceChange>,
  pendingResourceDeletions: Map<string, DeleteResourceChange>,
) => {
  const allCreatedResourceIds = new Set(pendingResourceCreations.keys());
  const allUpdatedResourceIds = new Set(pendingResourceUpdates.keys());
  const allDeletedResourceIds = new Set(pendingResourceDeletions.keys());

  const createUpdateIntersection = getIntersectionSet(allCreatedResourceIds, allUpdatedResourceIds);
  const createDeleteIntersection = getIntersectionSet(allCreatedResourceIds, allDeletedResourceIds);
  const updateDeleteIntersection = getIntersectionSet(allUpdatedResourceIds, allDeletedResourceIds);
  if (
    createUpdateIntersection.size > 0 ||
    createDeleteIntersection.size > 0 ||
    updateDeleteIntersection.size > 0
  ) {
    throw new Error('Cannot have the same resource in different types of pending changes');
  }

  for (const pendingResourceCreation of pendingResourceCreations.values()) {
    const pendingResource = pendingResourceCreation.resource;
    if (pendingResource['@type'] === FOLDER_TYPE) {
      const pendingFolder = pendingResource as Folder;
      if (pendingFolder.children?.length ?? 0 > 0) {
        throw new Error('Pending folders should not be populated with any children');
      }
    }
  }
};

export const validateDataLibraryBeforeCreation = (
  pendingResourceCreations: Map<string, CreateResourceChange>,
  resourceId: string,
) => {
  if (pendingResourceCreations.has(resourceId)) {
    throw new Error('Resource already exists in pending creations');
  }
};

export const validateDataLibraryAfterCreation = (
  folders: Map<string, ResponseData<Folder>>,
  resource: Resource,
) => {
  const parentPath = getParentPath(resource.path ?? '');
  if (!folders.has(parentPath)) {
    throw new Error('Parent folder not found');
  }

  const parentFolderResponse = folders.get(parentPath);
  if (!isSuccess(parentFolderResponse)) {
    throw new Error('Parent folder has not been fetched');
  }

  const parentFolder = parentFolderResponse.data;
  const resourceCount = parentFolder.children?.reduce((totalCount, child) => {
    return child.id === resource.id ? totalCount + 1 : totalCount;
  }, 0) as number;

  if (resourceCount === 0) {
    throw new Error('Resource found in parent folder');
  } else if (resourceCount > 1) {
    throw new Error('Duplicate resource found in parent folder');
  }
};

const getIntersectionSet = <T>(set1: Set<T>, set2: Set<T>) => {
  return new Set([...set1].filter((x) => set2.has(x)));
};
