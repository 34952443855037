import cx from 'classnames';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchGroupTags,
  addGroupTag,
  deleteGroupTag,
  renameGroupTag,
} from 'actions/customerActions';
import { GroupTag } from 'actions/teamActions';
import { AlertModal, Button, IconButton, Input, Spinner, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { showErrorToast } from 'shared/sharedToasts';

export const SettingsGroupTagsSection = () => {
  const dispatch = useDispatch();

  const groupTags = useSelector((state: ReduxState) => state.customers.groupTags);

  const [newGroupTagName, setNewGroupTagName] = useState<string>();
  const [groupTagToDelete, setGroupTagToDelete] = useState<GroupTag>();
  const [internalEditedNames, setInternalEditedNames] = useState<Record<number, string>>({});

  useEffect(() => {
    if (RD.isIdle(groupTags)) dispatch(fetchGroupTags());
  }, [dispatch, groupTags]);

  const currentGroupTags = useMemo(
    () => (RD.isSuccess(groupTags) ? groupTags.data : []),
    [groupTags],
  );

  const handleSubmit = (newName: string) => {
    dispatch(
      addGroupTag(
        { postData: { name: newName.trim() } },
        () => setNewGroupTagName(undefined),
        (error) => showErrorToast(`Error adding group tag with name ${newName}: ${error.detail}`),
      ),
    );
  };

  const getErrorText = (id: number, name: string) => {
    const trimmedName = name.trim();
    return currentGroupTags.find((tag) => tag.id !== id && tag.name === trimmedName)
      ? "Can't have a duplicate name."
      : trimmedName === ''
        ? "Name can't be empty"
        : undefined;
  };

  const createNewGroupTag = () => {
    if (newGroupTagName === undefined) return;
    const errorText = getErrorText(-1, newGroupTagName);
    return (
      <div className={cx(container, sprinkles({ paddingBottom: 'sp1.5' }))}>
        <Input
          autoFocus
          className={sprinkles({ flex: 1 })}
          onChange={setNewGroupTagName}
          onEnter={handleSubmit}
          placeholder="Group Tag Name"
          value={newGroupTagName}
        />
        <div className={sprinkles({ display: 'flex', gap: 'sp.5' })}>
          <IconButton
            name="cross"
            onClick={() => setNewGroupTagName(undefined)}
            variant="tertiary"
          />
          <IconButton
            disabled={!!errorText}
            name="tick"
            onClick={() => handleSubmit(newGroupTagName)}
            tooltipProps={errorText ? { text: errorText } : undefined}
            variant={errorText ? 'tertiary' : 'primary'}
          />
        </div>
      </div>
    );
  };

  const viewGroupTag = ({ name, id }: GroupTag) => {
    const renameText = internalEditedNames?.[id];
    const errorText = renameText !== undefined ? getErrorText(id, renameText) : undefined;

    return (
      <div
        className={cx(container, sprinkles({ paddingBottom: errorText ? 'sp.5' : 'sp1.5' }))}
        key={`group-tag-${name}`}>
        <Input
          fillWidth
          defaultValue={renameText ?? name}
          errorText={errorText}
          onSubmit={(newValue) => {
            setInternalEditedNames({ ...internalEditedNames, [id]: newValue });
            if (newValue === undefined || getErrorText(id, newValue)) return;
            dispatch(
              renameGroupTag(
                { postData: { group_tag_id: id, name: newValue } },
                () => undefined,
                (error) =>
                  showErrorToast(
                    `Error renaming group tag ${name} to ${newValue}: ${error.detail}`,
                  ),
              ),
            );
          }}
        />

        <IconButton
          name="trash"
          onClick={() => setGroupTagToDelete({ name, id })}
          variant="destructive"
        />
      </div>
    );
  };

  const onConfirmDelete = (groupTagId: number) => {
    dispatch(
      deleteGroupTag(
        { postData: { group_tag_id: groupTagId } },
        () => setGroupTagToDelete(undefined),
        (error) => showErrorToast(`Error deleting group tag: ${error.detail}`),
      ),
    );
  };

  const viewDeleteModal = () => {
    if (groupTagToDelete == undefined) return;
    const title = `Are you sure you want to delete ${groupTagToDelete.name}?`;
    const confirmText = `Delete ${groupTagToDelete.name}`;

    return (
      <AlertModal
        isOpen
        actionButtonProps={{
          text: confirmText,
          onClick: () => onConfirmDelete(groupTagToDelete.id),
        }}
        onClose={() => setGroupTagToDelete(undefined)}
        title={title}>
        If this group tag is deleted then the customers and reports will no longer be linked to the
        groups you’ve assigned. These changes will happen immediately.
      </AlertModal>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
        <div className={sprinkles({ heading: 'h2' })}>Group Tags</div>
        <div className={sprinkles({ body: 'b2', color: 'gray11' })}>
          Group Tags are customizable tags that you can assign to your customers to control their
          access to Reports in Report Builder.
        </div>
      </div>
      {RD.isLoading(groupTags) ? (
        <div className={sprinkles({ flexItems: 'centerColumn' })}>
          <Spinner size="xl" />
        </div>
      ) : (
        <>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
            {currentGroupTags.map((groupTag) => viewGroupTag(groupTag))}
            {createNewGroupTag()}
          </div>
          <Button
            disabled={newGroupTagName !== undefined}
            icon="plus"
            onClick={() => setNewGroupTagName('')}
            variant="primary">
            Add new group tag
          </Button>
          {viewDeleteModal()}
        </>
      )}
    </div>
  );
};

const container = sprinkles({
  paddingX: 'sp1.5',
  backgroundColor: 'gray3',
  borderRadius: 4,
  display: 'flex',
  gap: 'sp1',
  paddingTop: 'sp1.5',
});
