import { PeriodRangeTypes } from '../dateRangeTypes';

export const TrendGroupToggleOptionId = 'KPI_TREND_DATE_GROUP_TOGGLE';
export type TrendGroupToggleOption = typeof TrendGroupToggleOptionId;

export type KPIComparisonInfo = {
  customStartDate?: string;
  customEndDate?: string;
  rangeElemId?: string;
  timePeriodElemId?: string;
};

export type KPIPeriodInfo = {
  periodRange: PeriodRangeTypes;
  customStartDate?: string;
  customEndDate?: string;
  customStartDateVariable?: string;
  customEndDateVariable?: string;
  rangeElemId?: string;
  timePeriodElemId?: string;
  trendDateOffset?: number;
  comparisonInfo?: KPIComparisonInfo;
};
