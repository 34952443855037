import * as RD from 'remotedata';

import {
  CustomerReportGroupBy,
  DatasetRow,
  DatabaseUnsupportedOperations,
  FilterOperator,
  FilterValueType,
  BaseCol,
} from '@explo/data';

import { AIMessage } from '../apiTypes';
import { CustomerReportAgg } from 'actions/customerReportActions';

export enum ReportModalType {
  DATA_SELECTION = 'DATA_SELECTION',
  SAVE_AS = 'SAVE_AS',
  SCHEDULE_EXPORT = 'SCHEDULE_EXPORT',
  EXPORT_NOW = 'EXPORT_NOW',
  EDIT_BUILT_IN = 'EDIT_BUILT_IN',
  SAVE_BUILT_IN_AS_REPORT = 'SAVE_BUILT_IN_AS_REPORT',
  CONFIRM_CLOSE_REPORT = 'CONFIRM_CLOSE_REPORT',
  CONFIRM_CLOSE_BUILT_IN = 'CONFIRM_CLOSE_BUILT_IN',
  GENERATE_AI_VIEW = 'GENERATE_AI_VIEW',
}

export type ReportModal =
  | { type: ReportModalType.DATA_SELECTION; datasetId?: string }
  | { type: ReportModalType.SAVE_AS; reportId: number }
  | { type: ReportModalType.SCHEDULE_EXPORT }
  | { type: ReportModalType.EXPORT_NOW }
  | { type: ReportModalType.EDIT_BUILT_IN }
  | { type: ReportModalType.SAVE_BUILT_IN_AS_REPORT; builtInId: string }
  | { type: ReportModalType.CONFIRM_CLOSE_REPORT; reportId: number }
  | { type: ReportModalType.CONFIRM_CLOSE_BUILT_IN; builtInId: string }
  | { type: ReportModalType.GENERATE_AI_VIEW };

export type ReportSchemaInfo = {
  groupBys: CustomerReportGroupBy[];
  aggs: CustomerReportAgg[];
  columnGroupBys: CustomerReportGroupBy[];
  scatterPlotGrouping: CustomerReportGroupBy | undefined;
};

export interface ReportData {
  rows?: DatasetRow[];
  rowCount: RD.ResponseData<number>;
  error?: string;
  isLoading: boolean;
  loadingRequestId?: string;
  rowCountRequestId?: string;
  page: number;
  unsupportedOperations?: DatabaseUnsupportedOperations[];
}

export enum ReportType {
  CUSTOMER_REPORT = 'CUSTOMER_REPORT',
  BUILT_IN = 'BUILT_IN',
  // Used for editing built ins in editor
  EDIT_BUILT_IN = 'EDIT_BUILT_IN',
  // Used for previewing report editing in editor
  PREVIEW_REPORT = 'PREVIEW_REPORT',
}

export type CustomerReportType = { type: ReportType.CUSTOMER_REPORT; id: number };
export type BuiltInType = { type: ReportType.BUILT_IN; id: string };
type EditBuiltInType = { type: ReportType.EDIT_BUILT_IN; id: string };
type PreviewReportType = { type: ReportType.PREVIEW_REPORT; id: 0 }; // Id is hardcoded because it's needed for components but doesn't point to anything

export type SelectedReportType =
  | CustomerReportType
  | BuiltInType
  | EditBuiltInType
  | PreviewReportType;

export type CreateFilterPayload = {
  filterOperator?: FilterOperator;
  value?: FilterValueType;
  column: BaseCol;
  /** If true, this filter should be applied last in the DB query rather than at the start (before grouping) */
  isPostFilter?: boolean;
};
export type ApplyFilterInfoPayload = {
  filterOperator: FilterOperator;
  value: FilterValueType;
  isPostFilter?: boolean;
} & ({ column: BaseCol } | { filterId: number });

export type AIReducerMessage = AIMessage & {
  metadata?: {
    chartType?: string;
  };
};
