import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { AREA_CHART_TYPES, BAR_CHART_TYPES, LINE_CHART_TYPES, OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Switch } from 'components/ds';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const TooltipConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  return (
    <div className={configRootClass}>
      {visualizationType !== OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 &&
      visualizationType !== OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ? (
        <Switch
          className={configInputClass}
          label="Show percentage values"
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              if (!draft.tooltipFormat) draft.tooltipFormat = {};
              draft.tooltipFormat.showPct = !draft.tooltipFormat.showPct;
            });
            dispatch(updateVisualizeOperation(newInstructions, visualizationType));
          }}
          switchOn={instructions.tooltipFormat?.showPct}
        />
      ) : null}
      {BAR_CHART_TYPES.has(visualizationType) ||
      LINE_CHART_TYPES.has(visualizationType) ||
      AREA_CHART_TYPES.has(visualizationType) ? (
        <Switch
          className={configInputClass}
          label="Only show hovered value"
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              if (!draft.tooltipFormat) draft.tooltipFormat = {};
              draft.tooltipFormat.showSelectedOnly = !draft.tooltipFormat.showSelectedOnly;
            });
            dispatch(updateVisualizeOperation(newInstructions, visualizationType));
          }}
          switchOn={instructions.tooltipFormat?.showSelectedOnly}
        />
      ) : null}
      <Switch
        className={configInputClass}
        label="Show total"
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.tooltipFormat) draft.tooltipFormat = {};
            draft.tooltipFormat.showTotal = !draft.tooltipFormat.showTotal;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        switchOn={instructions.tooltipFormat?.showTotal}
      />
    </div>
  );
};
