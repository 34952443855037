import { Resource } from '@explo-tech/fido-api';
import {
  addPendingResourceCreation,
  ALL_PENDING_CHANGES_ACTION_TYPES,
} from 'reducers/dataLibraryReducer';
import {
  validateDataLibraryAfterCreation,
  validateDataLibraryBeforeCreation,
  validatePendingChanges,
} from 'reducers/dataLibraryValidator';
import { ReduxState } from 'reducers/rootReducer';
import { listBranchContentThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { Action, AnyAction, Dispatch, Middleware } from 'redux';
import { isSuccess } from 'remotedata';

export const dataLibraryValidatorMiddleware: Middleware<{}, ReduxState> =
  ({ getState }) =>
  (next: Dispatch<Action>) =>
  (action: AnyAction) => {
    if (!getState().currentUser.team?.feature_flags.enable_data_library_debug_actions) {
      next(action);
      return;
    }

    const resourceToAdd = getResourceFromAddPendingCreationAction(action);
    if (resourceToAdd != null) {
      validateDataLibraryBeforeCreation(
        getState().dataLibrary.pendingResourceCreations,
        resourceToAdd.id ?? '',
      );
    }

    // Validate against loading the same folder twice.
    if (action.type === listBranchContentThunk.fulfilled.type) {
      const listBranchContentThunkAction = action as ReturnType<
        typeof listBranchContentThunk.fulfilled
      >;
      const currentBranch = getState().dataLibrary.currentBranch;
      if (!isSuccess(currentBranch)) {
        throw new Error(
          'Fetching the contents for a branch without the branch data being successfully fetched.',
        );
      }

      const alreadyLoadedFolders =
        getState().dataLibrary.updatedFolders.get(currentBranch.data.id ?? '') ?? new Map();
      const loadedFolderPath = listBranchContentThunkAction.meta.arg.path;
      if (alreadyLoadedFolders.has(loadedFolderPath)) {
        if (isSuccess(alreadyLoadedFolders.get(loadedFolderPath))) {
          throw new Error(`Folder at path ${loadedFolderPath} already loaded`);
        }
      }
    }

    next(action);

    const postApplicationDataLibraryState = getState().dataLibrary;
    if (ALL_PENDING_CHANGES_ACTION_TYPES.has(action.type)) {
      validatePendingChanges(
        postApplicationDataLibraryState.pendingResourceCreations,
        postApplicationDataLibraryState.pendingResourceUpdates,
        postApplicationDataLibraryState.pendingResourceDeletions,
      );
    }

    if (resourceToAdd != null) {
      const currentBranch = postApplicationDataLibraryState.currentBranch;
      if (!isSuccess(currentBranch)) {
        throw new Error(
          'Adding a resource to pending creations without the branch data being successfully fetched.',
        );
      }

      const branchId = currentBranch.data.id ?? '';
      validateDataLibraryAfterCreation(
        postApplicationDataLibraryState.updatedFolders.get(branchId) ?? new Map(),
        resourceToAdd,
      );
    }
  };

const getResourceFromAddPendingCreationAction = (action: Action): Resource | null => {
  if (action.type === addPendingResourceCreation.type) {
    return (action as ReturnType<typeof addPendingResourceCreation>).payload;
  }
  return null;
};
