import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setDisplayTimezone } from '@explo/data';

import {
  CustomerReportView,
  fetchCustomerReport,
  listCustomerReports,
} from 'actions/customerReportActions';
import { Spinner } from 'components/ds';
import {
  DASHBOARD_LOADED_CLASS_NAME,
  DATA_PANEL_ERROR_CLASS_NAME,
} from 'constants/exportConstants';
import { loadFonts } from 'globalStyles/utils';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ExportChart } from 'pages/ReportBuilder/ExportChart';
import { ReportBuilderPoller } from 'pages/ReportBuilder/ReportBuilderPoller';
import { getOrDefault, hasNotReturned, isError, isLoading, isSuccess } from 'remotedata';
import { ExportViewOverrides } from 'reportBuilderContent/exportTypes';
import {
  setTimezone,
  getCurrentTheme,
  setTheme,
  setVariables,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData, ReportType } from 'reportBuilderContent/reducers/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { getQueryVariables } from 'utils/variableUtils';

import * as styles from './Export.css';
import { getReportBuilderTimezone } from 'utils/customerReportUtils';

type Props = {
  customerToken?: string;
  environment?: string;
  theme?: string;
  reportBuilderEmbedId?: string;
  datasetId?: string;
  customerReportId?: string;
  builtInId?: string;
  embedJwt?: string;
  title?: string;
  timezone?: string;
  variables?: DashboardVariableMap;
  viewOverrides?: ExportViewOverrides[]; // Determines which views to render + overrides the view's properties
  useQueryVariables: boolean;
};

export const Export: FC<Props> = ({
  customerToken,
  environment,
  theme,
  reportBuilderEmbedId,
  customerReportId,
  builtInId,
  embedJwt,
  variables,
  datasetId,
  title,
  viewOverrides,
  timezone,
  useQueryVariables,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables(useQueryVariables));

  const {
    reportBuilder,
    versionConfig,
    reportStatus,
    reportData,
    globalStyleConfig,
    team,
    currentConfig,
    fontConfig,
  } = useSelector(
    (state: ReportBuilderReduxState) => ({
      reportBuilder: state.embeddedReportBuilder.reportBuilder,
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      reportData: state.reportEditing.reportData,
      currentConfig: state.reportEditing.currentConfig,
      team: state.embeddedReportBuilder.team,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
      reportStatus: state.reportEditing.reportStatus,
      fontConfig: state.embeddedReportBuilder.fontConfig,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (typeof theme === 'string') dispatch(setTheme(theme));
  }, [dispatch, theme]);

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [globalStyleConfig, fontConfig, team]);

  const dataset = datasetId && versionConfig?.datasets[datasetId];

  useEffect(() => {
    dispatch(
      listCustomerReports({
        customerToken,
        jwt: embedJwt,
        postData: {
          environment: environment ?? null,
          resource_embed_id: reportBuilderEmbedId,
        },
      }),
    );
  }, [dispatch, customerToken, embedJwt, environment, reportBuilderEmbedId]);

  useEffect(() => {
    if (dataset)
      dispatch(
        fetchCustomerReport({
          id: builtInId || customerReportId,
          customerToken,
          jwt: embedJwt,
          postData: {
            environment: environment ?? null,
            resource_embed_id: reportBuilderEmbedId,
            report_type: builtInId ? ReportType.BUILT_IN : ReportType.CUSTOMER_REPORT,
          },
        }),
      );
  }, [
    dispatch,
    customerToken,
    embedJwt,
    environment,
    reportBuilderEmbedId,
    dataset,
    builtInId,
    customerReportId,
  ]);

  useEffect(() => {
    dispatch(setVariables({ ...urlVariables, ...variables }));
  }, [dispatch, variables, urlVariables]);

  useEffect(() => {
    if (timezone) dispatch(setTimezone(timezone));
    setDisplayTimezone(getReportBuilderTimezone({ reportBuilder, timezone: timezone ?? null }));
  }, [dispatch, timezone, reportBuilder]);

  useEffect(() => {
    if (isSuccess(reportBuilder) && title !== undefined) {
      document.title = title;
    }
  }, [reportBuilder, title]);

  const isLoaded = useMemo(() => {
    if (!isSuccess(reportBuilder) || !isSuccess(reportStatus)) return false;
    const reportDataValues = Object.values(reportData).filter((data) => !!data) as ReportData[];
    return (
      (viewOverrides?.length || 0) <= reportDataValues.length &&
      !reportDataValues.some((data) => data.isLoading || isLoading(data.rowCount))
    );
  }, [reportData, reportStatus, reportBuilder, viewOverrides?.length]);

  const views = currentConfig?.views;
  const overridenViews = useMemo(() => {
    if (!views || !viewOverrides?.length) return;
    const viewMap = new Map(views.map((view) => [view.id, view]));
    return viewOverrides.map((viewOverride): CustomerReportView => {
      const view = viewMap.get(viewOverride.id);
      // Technically we don't need these default values because the only time there should be a viewOverride without a view
      // is when the user creates a new view, but hasn't saved it. In that case, viewOverride should be a complete view
      return {
        name: 'Untitled View',
        filters: [],
        sort: [],
        hiddenColumns: [],
        columnOrder: [],
        ...view,
        ...viewOverride,
      };
    });
  }, [viewOverrides, views]);

  const renderError = useCallback(
    (error: string) => (
      <EmbedText className={DATA_PANEL_ERROR_CLASS_NAME} heading="h3">
        {error}
      </EmbedText>
    ),
    [],
  );

  return (
    <div
      className={cx(styles.viewContainer, { [DASHBOARD_LOADED_CLASS_NAME]: isLoaded })}
      ref={containerRef}>
      {!overridenViews?.length ? (
        renderError('No views in report')
      ) : hasNotReturned(reportBuilder) || hasNotReturned(reportStatus) ? (
        <Spinner />
      ) : isError(reportBuilder) ? (
        renderError(reportBuilder.error)
      ) : isError(reportStatus) ? (
        renderError(reportStatus.error)
      ) : isSuccess(reportBuilder) && !dataset ? (
        renderError('Missing dataset')
      ) : isSuccess(reportBuilder) && isSuccess(reportStatus) && dataset ? (
        overridenViews?.map((view) => (
          <ExportChart dataset={dataset} key={view.id} title={title} view={view} />
        ))
      ) : (
        renderError('Unknown state')
      )}
      <ReportBuilderPoller />
    </div>
  );
};
