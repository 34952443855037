import { Props } from './BaseAppUpdate';

/* New updates should be added here with an id greater than all present ids

Here are some examples:
export const APP_UPDATE_MAP: Record<number, Props> = {
  0: {
    docsLink: 'https://docs.explo.co/creating-dashboards/debugger-panel',
    title: 'Updated SQL Editor',
    description: 'New dataset editor for editing dashboards!',
    icon: 'table',
  },
  1: {
    docsLink: 'https://docs.explo.co/creating-report-builders/datasets',
    title: 'Report Builder Only',
    description: 'New updates for report builder only!,
    icon: 'gear',
    permissionFn: (permissionObject) =>
      permissionObject.user ? isReportBuilderEnabled(permissionObject.user) : false,
  },
};
*/

// Updates that are for both Explore and Report Builder can be marked as Explore so they show up on landing page
export enum AppUpdateType {
  EXPLORE = 'EXPLORE',
  REPORT_BUILDER = 'REPORT_BUILDER',
}

export const APP_UPDATE_MAP: Record<number, Props> = {
  0: {
    docsLink: 'https://docs.explo.co/creating-dashboards/dataset-editor',
    icon: 'database',
    title: 'Check out the new dashboard dataset editor',
    description:
      'The dashboard dataset editor has been revamped and moved into the left configuration panel.',
    appUpdateType: AppUpdateType.EXPLORE,
  },
  1: {
    docsLink: 'https://docs.explo.co/general-features/version-control/viewing-versions',
    icon: 'code-compare',
    title: 'Preview Report Builders without editing.',
    description:
      'Report builder now allows you to preview a specific version without going into edit mode.',
    appUpdateType: AppUpdateType.REPORT_BUILDER,
  },
};
