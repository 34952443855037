import { FC } from 'react';

import { Tag } from 'components/ds';

interface Props {
  versionNumber: number;
  isDraft: boolean;
}

export const LatestVersionTag: FC<Props> = ({ versionNumber, isDraft }) => {
  return <Tag>{isDraft ? 'In-progress draft' : `Version ${versionNumber}`}</Tag>;
};
