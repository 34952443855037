import { Icon, sprinkles, Tooltip } from 'components/ds';
import { FC } from 'react';

export const PendingChangesTooltip: FC = () => {
  return (
    <Tooltip text="There are pending changes that need to be committed">
      <Icon className={sprinkles({ color: 'yellow', marginX: 'sp1' })} name="infoCircle" />
    </Tooltip>
  );
};
