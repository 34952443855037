/**
 * TODO: Add type declarations for utils so we don't need ts-ignore
 */
// @ts-ignore
import { Layout, utils } from '@explo-tech/react-grid-layout';

import { OPERATION_TYPES } from '@explo/data';

import {
  DRAGGING_ITEM_CONFIG_BY_TYPE,
  GENERIC_DROPPING_ID,
  PDF_EDITOR_MARGIN_SIZE,
} from 'constants/dashboardConstants';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  ContainerElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { cloneDeep, compact, keyBy, map, sortBy } from 'utils/standard';

import { createDashboardItemId, removeElemFromStickyHeader } from './dashboardUtils';
import {
  resolveSecondaryLayout,
  getLayoutHeightInRows,
  removeElementsFromLayoutById,
} from './layoutResolverUtil';

export const getLayoutMargin = (viewMode: VIEW_MODE, config: GlobalStyleConfig): number =>
  viewMode === VIEW_MODE.PDF ? PDF_EDITOR_MARGIN_SIZE : config.base.spacing.default;

export const addItemToConfigLayouts = (
  config: DashboardVersionConfig,
  payload: { newLayout: Layout[]; containerId?: string },
  elementId: string,
) => {
  const draggedElem = payload.newLayout.find((elem) => elem?.i.startsWith(GENERIC_DROPPING_ID));

  if (!draggedElem) return config;

  draggedElem.i = elementId;
  draggedElem.isDraggable = undefined;

  if (payload.containerId) {
    const containerConfig = config.elements[payload.containerId].config as ContainerElemConfig;
    containerConfig.layout = payload.newLayout;

    config.dashboard_layout = getMainLayoutForNewContainerLayout(
      config.dashboard_layout,
      payload.newLayout,
      payload.containerId,
    );

    if (config.pdf_layout && containerConfig.pdfLayout) {
      const newContainerPdfLayout = resolveSecondaryLayout(
        payload.newLayout,
        containerConfig.pdfLayout,
      );
      containerConfig.pdfLayout = newContainerPdfLayout;

      config.pdf_layout = getMainLayoutForNewContainerLayout(
        config.pdf_layout,
        newContainerPdfLayout,
        payload.containerId,
      );
    }

    if (config.email_layout && containerConfig.emailLayout) {
      const newContainerEmailLayout = resolveSecondaryLayout(
        payload.newLayout,
        containerConfig.emailLayout,
      );
      containerConfig.emailLayout = newContainerEmailLayout;

      config.email_layout = getMainLayoutForNewContainerLayout(
        config.email_layout,
        newContainerEmailLayout,
        payload.containerId,
      );
    }

    if (config.mobile_layout && containerConfig.mobileLayout) {
      const newContainerMobileLayout = resolveSecondaryLayout(
        payload.newLayout,
        containerConfig.mobileLayout,
      );
      containerConfig.mobileLayout = newContainerMobileLayout;

      config.mobile_layout = getMainLayoutForNewContainerLayout(
        config.mobile_layout,
        newContainerMobileLayout,
        payload.containerId,
      );
    }
  } else {
    config.dashboard_layout = payload.newLayout;

    updateSecondaryLayoutsAfterMainLayoutChange(config);
  }
};

export const updateSecondaryLayoutsAfterMainLayoutChange = (config: DashboardVersionConfig) => {
  if (config.pdf_layout) {
    config.pdf_layout = resolveSecondaryLayout(config.dashboard_layout, config.pdf_layout);
  }
  if (config.email_layout) {
    config.email_layout = resolveSecondaryLayout(config.dashboard_layout, config.email_layout);
  }
  if (config.mobile_layout) {
    config.mobile_layout = resolveSecondaryLayout(
      config.dashboard_layout,
      config.mobile_layout,
      true,
    );
  }
};

const getMainLayoutForNewContainerLayout = (
  mainLayout: Layout[],
  newContainerLayout: Layout[],
  containerId: string,
) => {
  const containerLayoutItem = mainLayout.find((item) => item.i === containerId);
  const doesLayoutFitInExistingContainer = validateLayout(
    newContainerLayout,
    containerLayoutItem?.h || 0,
  );

  if (doesLayoutFitInExistingContainer) {
    return mainLayout;
  }

  return mainLayout.map((item) => {
    if (item.i === containerId) {
      const maxRows = getLayoutHeightInRows(newContainerLayout);
      return { ...item, h: maxRows + 1 };
    }
    return item;
  });
};

const validateLayout = (layout: Layout[], containerRows: number) => {
  const maxHeight = Math.max(...layout.map((l) => (l.i !== 'null' ? l.y + l.h : 0)));

  return maxHeight < containerRows;
};

const isDataPanel = (
  element: DashboardElement | DataPanelTemplate,
): element is DataPanelTemplate => {
  return 'filter_op' in element;
};

export const duplicateDashboardElement = (payload: {
  newElementConfig: DashboardElement | DataPanelTemplate;
  config: DashboardVersionConfig;
  dashId: number;
}): string => {
  const { newElementConfig, config, dashId } = payload;
  const elementIsDataPanel = isDataPanel(newElementConfig);

  newElementConfig.id = createDashboardItemId(dashId);

  // calculate the new name, numbering after the first copy
  let newValue = elementIsDataPanel
    ? `${newElementConfig.provided_id}_copy`
    : `${newElementConfig.name}_copy`;

  const itemNames = map(Object.values(config.elements), 'name').concat(
    map(Object.values(config.data_panels), 'provided_id'),
  );

  const dupeNames = itemNames.filter((name) => name.startsWith(newValue));

  if (dupeNames.length > 0) {
    const dupeNums = compact(dupeNames.map((name) => parseInt(name.split('copy').slice(-1)[0])));
    const nextNum = Math.max(...dupeNums, 0) + 1;
    newValue = `${newValue}${nextNum}`;
  }

  if (elementIsDataPanel) {
    (newElementConfig as DataPanelTemplate).provided_id = newValue;
    config.data_panels[newElementConfig.id] = newElementConfig as DataPanelTemplate;
  } else {
    newElementConfig.name = newValue;
    config.elements[newElementConfig.id] = newElementConfig as DashboardElement;
  }

  return newElementConfig.id;
};

export const placeDuplicatedElementInLayout = (payload: {
  newElementLayout: Layout;
  newElementConfig: DashboardElement | DataPanelTemplate;
  layout: Layout[];
  config: DashboardVersionConfig;
  yStart: number;
  dashId: number;
}): string => {
  const { newElementLayout, newElementConfig, layout, config, yStart, dashId } = payload;

  newElementLayout.i = GENERIC_DROPPING_ID;
  newElementLayout.y = yStart;
  layout.push(newElementLayout);

  newElementConfig.id = duplicateDashboardElement({ newElementConfig, config, dashId });

  addItemToConfigLayouts(
    config,
    {
      newLayout: layout,
      containerId: newElementConfig.container_id,
    },
    newElementConfig.id,
  );

  return newElementConfig.id;
};

export function processLayout({
  layout,
  dataPanels,
  dashboardElements,
  viewMode,
}: {
  layout: Layout[];
  dashboardElements: DashboardElement[];
  dataPanels: DataPanel[];
  viewMode: VIEW_MODE;
}) {
  const elementMap = keyBy(dashboardElements, 'id');
  const panelMap = keyBy(dataPanels, 'id');
  //Producer (Can't change layout from state)
  const clonedLayout = cloneDeep(layout).filter((l) => !!elementMap[l.i] || !!panelMap[l.i]);

  clonedLayout.forEach((panel) => {
    const element = elementMap[panel.i];
    if (element) {
      if (element.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
        const config = element.config as ContainerElemConfig;
        const containerLayout = getLayoutFromContainerConfig(config, viewMode);

        const { minHeight, minWidth } = getContainerLayoutMinimumSize(containerLayout);
        panel.minH = Math.max(minHeight, 2);
        panel.minW = Math.max(minWidth, 2);
      } else if (element.element_type === DASHBOARD_ELEMENT_TYPES.TOGGLE) {
        panel.minW = 3;
        panel.maxH = 1;
      } else if (element.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH) {
        panel.maxH = 1;
      } else {
        panel.minW = undefined;
        panel.maxH = undefined;
      }
    } else if (panelMap[panel.i]) {
      panel.minH = getPanelMinHeight(panelMap[panel.i]);
    }
  });
  return clonedLayout;
}

const getPanelMinHeight = (panel: DataPanel) => {
  if (
    panel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
    panel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL ||
    panel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2
  ) {
    return 1;
  } else {
    return 3;
  }
};

export const getLayoutFromContainerConfig = (config: ContainerElemConfig, viewMode: VIEW_MODE) =>
  (viewMode === VIEW_MODE.MOBILE
    ? config.mobileLayout
    : viewMode === VIEW_MODE.PDF
      ? config.pdfLayout
      : viewMode === VIEW_MODE.EMAIL
        ? config.emailLayout
        : config.layout) ?? config.layout;

export function formatContainerElementHeightForMobile(
  layout: Layout[],
  elements: DashboardElement[],
): Layout[] {
  const newLayout = cloneDeep(layout);
  const containerMap = keyBy(
    elements.filter((e) => e.element_type === DASHBOARD_ELEMENT_TYPES.CONTAINER),
    'id',
  );

  newLayout.forEach((layoutElem) => {
    const container = containerMap[layoutElem.i];
    if (!container) return;
    const containerLayout = (container.config as ContainerElemConfig).layout;
    layoutElem.h = getContainerLayoutMinimumSize(compactLayout(containerLayout)).minHeight;
  });

  return newLayout;
}

export function compactLayout(layout: Layout[]) {
  const { compact, correctBounds } = utils;
  return compact(correctBounds(cloneDeep(layout), { cols: 2 }), 'vertical', 2);
}

const getContainerLayoutMinimumSize = (layout: Layout[]) => {
  const minWidth = Math.max(
    ...layout.map((l) => (l.i !== 'null' && l.i !== 'containerPlaceholder' ? l.x + l.w : 0)),
  );
  const minHeight = Math.max(
    ...layout.map((l) => (l.i !== 'null' && l.i !== 'containerPlaceholder' ? l.y + l.h : 0)),
  );
  /**
   * We add 1 to the height because height of a container in the main dashboard is 1 row
   * taller than the grid layout inside the container
   */
  return { minWidth, minHeight: minHeight + 1 };
};

/**
 * React-grid-layout uses the ordering of items to determine display precedence.
 * If a dropdown that is vertically rendered above a data panel is prior in the list,
 * the data panel will take display precedence over the menu of the dropdown.
 * To avoid this, we sort items by reverse y-position.
 */
export function getSortedGridItems(gridItems: (DataPanel | DashboardElement)[], layout: Layout[]) {
  const layoutSortedByYPosition = sortBy(layout, (item) => -item.y);
  const sortedGridItems = layoutSortedByYPosition.map((layoutItem) => {
    if (!layoutItem.i) return undefined;
    return gridItems.find((gridItem) => gridItem.id === layoutItem.i);
  });

  return compact(sortedGridItems);
}

export const moveElementFromContainerToBody = (
  config: DashboardVersionConfig,
  containerId: string,
  element: { id: string; container_id?: string },
) => {
  const containerElemConfig = config.elements[containerId].config as ContainerElemConfig;

  element.container_id = undefined;
  const removeId = new Set([element.id]);

  const oldLayoutElem = containerElemConfig.layout.find((elem) => elem.i === element.id);
  config.dashboard_layout = removeElementsFromLayoutById(config.dashboard_layout, removeId);

  containerElemConfig.emailLayout = containerElemConfig.emailLayout
    ? removeElementsFromLayoutById(containerElemConfig.emailLayout, removeId)
    : undefined;
  containerElemConfig.mobileLayout = containerElemConfig.mobileLayout
    ? removeElementsFromLayoutById(containerElemConfig.mobileLayout, removeId)
    : undefined;
  containerElemConfig.pdfLayout = containerElemConfig.pdfLayout
    ? removeElementsFromLayoutById(containerElemConfig.pdfLayout, removeId)
    : undefined;

  if (!oldLayoutElem) return;

  oldLayoutElem.y = 0;
  oldLayoutElem.i = GENERIC_DROPPING_ID;

  const newDashboardLayout = [...config.dashboard_layout, oldLayoutElem];

  addItemToConfigLayouts(config, { newLayout: newDashboardLayout }, element.id);
};

export const moveElementIntoContainer = (
  config: DashboardVersionConfig,
  containerId: string,
  element: { id: string; container_id?: string },
) => {
  const containerElemConfig = config.elements[containerId].config as ContainerElemConfig;
  const elemPreviosContainerId = element.container_id;
  element.container_id = containerId;

  const removedFromHeader = removeElemFromStickyHeader(
    config.dashboard_page_layout_config,
    element.id,
  );

  const maxContainerHeight = containerElemConfig.layout.length
    ? Math.max(
        ...containerElemConfig.layout.map((l) =>
          l.i !== 'null' && l.i !== 'containerPlaceholder' ? l.y + l.h : 0,
        ),
      )
    : 0;

  let layoutElem: ReactGridLayout.Layout | undefined;
  // if remove from the header, it has to be a dahsboard element and not a data panel
  if (removedFromHeader) {
    const elementConfig = element as DashboardElement;
    elementConfig.elemLocation = DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY;

    layoutElem = {
      ...DRAGGING_ITEM_CONFIG_BY_TYPE[elementConfig.element_type],
      x: 0,
      y: maxContainerHeight,
    };
  } else {
    if (elemPreviosContainerId) {
      layoutElem = (
        config.elements[elemPreviosContainerId].config as ContainerElemConfig
      ).layout.find((elem) => elem.i === element.id);
    } else {
      layoutElem = config.dashboard_layout.find((elem) => elem.i === element.id);
    }
    if (layoutElem) {
      layoutElem.x = 0;
      layoutElem.y = maxContainerHeight;
      layoutElem.i = GENERIC_DROPPING_ID;
    }
  }

  if (!layoutElem) return;

  const removeId = new Set([element.id]);

  config.dashboard_layout = removeElementsFromLayoutById(config.dashboard_layout, removeId);

  config.email_layout = config.email_layout
    ? removeElementsFromLayoutById(config.email_layout, removeId)
    : undefined;
  config.mobile_layout = config.mobile_layout
    ? removeElementsFromLayoutById(config.mobile_layout, removeId)
    : undefined;
  config.pdf_layout = config.pdf_layout
    ? removeElementsFromLayoutById(config.pdf_layout, removeId)
    : undefined;

  const newContainerLayout = [...containerElemConfig.layout, layoutElem];

  addItemToConfigLayouts(
    config,
    { newLayout: newContainerLayout, containerId: containerId },
    element.id,
  );
};

/**
 * Returns the bottom most Y coordinate within the given layouts.
 */
export const getLayoutBottomY = (layouts: Layout[]): number => {
  return Math.max(...layouts.map((layout) => layout.y + layout.h));
};
