import { Icon, sprinkles, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { FC } from 'react';

import * as RD from 'remotedata';

interface Props {
  pendingCommitStatus: RD.ResponseData<string>;
}

export const CommitStatusIcon: FC<Props> = ({ pendingCommitStatus }) => {
  return RD.isIdle(pendingCommitStatus) ? null : (
    <Tooltip text={getTooltipText(pendingCommitStatus)}>
      <Icon
        className={sprinkles({
          color: RD.isSuccess(pendingCommitStatus)
            ? 'success'
            : RD.isError(pendingCommitStatus)
              ? 'error'
              : 'activeContrast',
          marginX: 'sp1',
        })}
        name={getTooltipIcon(pendingCommitStatus)}
      />
    </Tooltip>
  );
};

const getTooltipText = (pendingCommitStatus: RD.ResponseData<string>): string => {
  if (RD.isSuccess(pendingCommitStatus)) {
    return 'Commit successful';
  } else if (RD.isError(pendingCommitStatus)) {
    return 'Commit failed';
  } else if (RD.isLoading(pendingCommitStatus)) {
    return 'Commit in progress';
  }

  return '';
};

const getTooltipIcon = (pendingCommitStatus: RD.ResponseData<string>): IconName => {
  if (RD.isSuccess(pendingCommitStatus)) {
    return 'check';
  } else if (RD.isError(pendingCommitStatus)) {
    return 'infoCircle';
  } else if (RD.isLoading(pendingCommitStatus)) {
    return 'spinner';
  }

  return 'check';
};
