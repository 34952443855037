import { DateTime, DateTimeOptions, Info } from 'luxon';

import { Timezones } from '../constants';

const fixISODateFormatting = (isoDate: string) => {
  // if the date is in the format YYYY-MM-DD followed by a space, replace it with a T
  // this is because the date picker library we use doesn't like the space
  return isoDate.replace(/(\d{4}-\d{2}-\d{2}) /, '$1T');
};

export const dateTimeFromISOString = (isoString: string, options?: DateTimeOptions) => {
  // wrapper function for DateTime.fromISO to handle formatting issues
  const isoFormattedString =
    typeof isoString === 'string' ? fixISODateFormatting(isoString) : isoString;
  return DateTime.fromISO(isoFormattedString, options || {});
};

export const getTimezone = (
  dashboardDefaultTimezone: Timezones | undefined,
  passedTimezone?: string,
) => {
  // Zones are a luxon representation of a timezone that we create from parsing in a user-passed
  // timezone override
  if (passedTimezone) {
    const normalized = Info.normalizeZone(passedTimezone);

    if (normalized.isValid) return normalized.name;
    console.warn(`Passed timezone ${passedTimezone} is not valid, using default...`);
  }

  if (dashboardDefaultTimezone === Timezones.USER_LOCAL_TIME) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return Timezones.UTC;
};

export const getTimezoneAwareUnix = (utcTimestamp: string) =>
  dateTimeFromISOString(utcTimestamp).toUnixInteger() * 1000;

export const getTimezoneAwareDate = (utcTimestamp: string) => {
  return dateTimeFromISOString(utcTimestamp);
};

export const getLuxonDateFromDatePicker = (date: Date) =>
  /**
   * Returns the Date object created by react-datepicker converted into a Luxon DateTime
   */
  DateTime.local(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

export const getDatePickerDateFromISO = (isoDate: string) =>
  /**
   * Returns the date and time passed in in the browser's timezone, but without shifting the hour.
   * This is because react-datepicker treats the date as one in the current timezone, but we
   * treat dates as UTC. Shifting the time to the current timezone here means that when a user
   * selects 4:00 1/1 (UTC) in the date picker, it gets passed to the date picker as 4:00 1/1 (EST)
   */
  dateTimeFromISOString(isoDate)
    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, { keepLocalTime: true })
    .toJSDate();

// Since our dashboard assumes dates are in UTC we convert all relative
// and range dates back to UTC but keeping the local time.
export const zoneToUtc = (date: DateTime): DateTime => {
  return date.setZone(Timezones.UTC, { keepLocalTime: true });
};
