import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ParentSchema } from 'actions/dataSourceActions';
import { DatasetModal } from 'components/DatasetModal';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';

type Props = {
  onClose: () => void;
  onSubmit: (name: string, parentSchema: ParentSchema) => void;
  validateDatasetNameFn?: (newDatasetName?: string) => { error: string | undefined };
  currentDatasetNames: Set<string>;
};

export const CreateDatasetModal: FC<Props> = ({
  currentDatasetNames,
  onClose,
  onSubmit,
  validateDatasetNameFn,
}) => {
  const parentSchemas = useSelector(getParentSchemasList);

  const handleError = useCallback(
    (newDatasetName?: string) => {
      if (!newDatasetName) {
        return { error: undefined };
      }

      if (currentDatasetNames.has(newDatasetName)) {
        return {
          error: 'Name must be unique within folder. Please choose another name for the dataset.',
        };
      } else if (validateDatasetNameFn) {
        return validateDatasetNameFn(newDatasetName);
      }

      return { error: undefined };
    },
    [currentDatasetNames, validateDatasetNameFn],
  );

  return (
    <DatasetModal
      isOpen
      buttonTitle="Create Dataset"
      defaultParentSchema={parentSchemas[0]}
      errorState={handleError}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Create dataset"
    />
  );
};
