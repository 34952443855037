import { Dispatch, useEffect } from 'react';

import { Branch, Folder } from '@explo-tech/fido-api';
import { DataSource, listTeamDataSources, ParentSchema } from 'actions/dataSourceActions';
import { fetchAllSchemaTables, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { ItemPathInfo, ItemType } from 'reducers/dataLibraryReducer';
import { SchemaTablesMap } from 'reducers/parentSchemaReducer';
import { getNamespaces } from 'reducers/thunks/fidoThunks';
import { listBranchContentThunk, listBranchesThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import * as RD from 'remotedata';

export function useDataLibrarySetup(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  dataSources: RD.ResponseData<DataSource[]>,
  parentSchemas: RD.ResponseData<ParentSchema[]>,
  parentSchemaTablesMap: RD.ResponseData<SchemaTablesMap>,
  useFido: boolean | undefined,
  currentBranch: RD.ResponseData<Branch>,
  folder: RD.ResponseData<Folder>,
  currentItemPath: ItemPathInfo,
) {
  // All of these pieces of state need to be loaded to get the correct schema and associated embeddo dataset
  useEffect(() => {
    dispatch(getNamespaces());
  }, [dispatch, useFido, parentSchemas, dataSources, parentSchemaTablesMap]);

  useEffect(() => {
    if (RD.isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  useEffect(() => {
    if (RD.isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (RD.isIdle(parentSchemaTablesMap)) dispatch(fetchAllSchemaTables());
  }, [dispatch, parentSchemaTablesMap]);

  useEffect(() => {
    // Fetch all branches if the current branch has not been loaded.
    if (RD.isIdle(currentBranch)) {
      dispatch(listBranchesThunk());
    }
  }, [dispatch, currentBranch]);

  useEffect(() => {
    // Do not attempt to fetch the current folder's contents if the current branch has not been
    // loaded.
    if (!RD.isSuccess(currentBranch)) {
      return;
    }

    // Make a fetch for the current folder's contents if the current folder has never been loaded.
    if (RD.isIdle(folder)) {
      dispatch(
        listBranchContentThunk({
          id: currentBranch.data.id ?? '',
          path: currentItemPath.path,
          resourceType: ItemType.FOLDER,
        }),
      );
    }
  }, [currentBranch, folder, currentItemPath, dispatch]);
}
