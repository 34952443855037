import { FC, useMemo } from 'react';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';

import { DatasetData } from 'actions/datasetActions';
import { ParentSchema } from 'actions/dataSourceActions';
import { convertViewToDataset } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { GlobalDatasetDataPreviewSection } from './GlobalDatasetDataPreviewSection';
import { GlobalDatasetQuerySection } from './GlobalDatasetQuerySection';
import { sprinkles } from '@explo/design-system';

interface Props {
  globalDataset: ComputedViewWithIds;
  datasetData: DatasetData;
  parentSchemas: ParentSchema[];
  hasUnmappedVariables: boolean;
  className?: string;

  onMapVariablesButtonClicked: () => void;
  onPreviewDataset: (query: string, pageNumber?: number) => void;
}

export const GlobalDatasetPreviewSection: FC<Props> = ({
  globalDataset,
  datasetData,
  parentSchemas,
  hasUnmappedVariables,
  className,
  onMapVariablesButtonClicked,
  onPreviewDataset,
}) => {
  const convertedDataset = useMemo(() => {
    return convertViewToDataset(globalDataset, parentSchemas);
  }, [globalDataset, parentSchemas]);

  return (
    <div className={className}>
      <GlobalDatasetQuerySection query={globalDataset.query} />
      <GlobalDatasetDataPreviewSection
        className={sprinkles({ margin: 'sp3' })}
        dataset={convertedDataset}
        datasetData={datasetData}
        hasUnmappedVariables={hasUnmappedVariables}
        onMapVariablesButtonClicked={onMapVariablesButtonClicked}
        onPreviewDataset={onPreviewDataset}
      />
    </div>
  );
};
