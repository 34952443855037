import { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { Select, APP_PORTAL_ID } from 'components/ds';
import {
  DatabasesToPort,
  DATABASES,
  DATA_SOURCE_AUTH,
  JDBC_DATA_SOURCES,
} from 'pages/ConnectDataSourceFlow/constants';
import {
  setAuthentication,
  setDataSourceConfig,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';

import { CredentialsInput } from './CredentialsInput';
import * as styles from './CredentialsSection.css';

const standardConfigHelpText = {
  hostHelp: 'The name of the server that hosts the database.  This does not include "https://".',
  hostLabel: 'Hostname',
  hostPlaceholder: 'e.g. rds.amazonaws.com',
  databasePlaceholder: 'e.g. acme_production',
};

const configHelpTextMapping = {
  [DATABASES.SQLSERVER]: {
    hostHelp: undefined,
    hostLabel: 'Server',
    hostPlaceholder: '<x>.database.windows.net',
    databasePlaceholder: 'master',
  },
  [DATABASES.POSTGRES]: standardConfigHelpText,
  [DATABASES.MYSQL]: standardConfigHelpText,
  [DATABASES.REDSHIFT]: standardConfigHelpText,
  [DATABASES.CLICKHOUSE]: standardConfigHelpText,
};

const DataSourceAuthOptions = [
  { value: DATA_SOURCE_AUTH.USERNAME_PASSWORD, label: 'Username and Password' },
];

export const FidoJdbcCredentialsSection: FC = () => {
  const dispatch = useDispatch();

  const {
    dataSourceConfig: { type: dataSourceType, config: dataSourceConfig, auth: authConfig },
    isUpdating,
  } = useSelector(
    (state: ReduxState) => ({
      dataSourceConfig: state.fidoDataSourceConfig.dataSourceConfig,
      isUpdating: state.fidoDataSourceConfig.isUpdating,
    }),
    shallowEqual,
  );

  if (!dataSourceType || !JDBC_DATA_SOURCES.includes(dataSourceType)) {
    return null;
  }

  // @ts-ignore
  const configSchemaForType = configHelpTextMapping[dataSourceType];
  if (!configSchemaForType) return null;

  const expectedPort = DatabasesToPort[dataSourceType as keyof typeof DatabasesToPort];

  return (
    <>
      <CredentialsInput
        helpTooltip={configSchemaForType.hostHelp}
        label={configSchemaForType.hostLabel}
        placeholder={configSchemaForType.hostPlaceholder}
        updateConfig={(host) => dispatch(setDataSourceConfig({ host }))}
        value={dataSourceConfig?.host}
      />

      <CredentialsInput
        helpTooltip={`${expectedPort} is the default for ${dataSourceType} databases, but in some instances, it might be different.`}
        label="Port"
        placeholder={`e.g. ${expectedPort}`}
        type="number"
        updateConfig={(port) => dispatch(setDataSourceConfig({ port: Number(port) }))}
        value={dataSourceConfig?.port}
      />

      <CredentialsInput
        label="Database"
        placeholder={configSchemaForType.databasePlaceholder}
        updateConfig={(database) => dispatch(setDataSourceConfig({ database }))}
        value={dataSourceConfig?.database}
      />

      <>
        <Select
          className={styles.credentialInput}
          label="Authentication"
          onChange={(newAuthMethod) =>
            dispatch(setAuthentication({ ['@type']: newAuthMethod as DATA_SOURCE_AUTH }))
          }
          portalContainerId={APP_PORTAL_ID}
          selectedValue={authConfig['@type']}
          values={DataSourceAuthOptions}
        />

        {authConfig['@type'] === DATA_SOURCE_AUTH.USERNAME_PASSWORD ? (
          <>
            <CredentialsInput
              helpTooltip="We recommend making a read-only account for Explo."
              label="Username"
              placeholder="e.g. analytics"
              updateConfig={(username) => dispatch(setAuthentication({ username }))}
              value={authConfig.username}
            />
            <CredentialsInput
              helpTooltip="The password is associated with the username above."
              label="Password"
              optional={isUpdating}
              placeholder={isUpdating ? 'password will be unmodified if left blank' : undefined}
              type="password"
              updateConfig={(password) =>
                dispatch(setAuthentication({ password: password || null }))
              }
              value={authConfig.password}
            />
          </>
        ) : (
          <>
            {/* TODO IAM auth isn't supported in FIDO today */}
            {/* <CredentialsInput
              helpTooltip="We recommend making a read-only account for Explo."
              label="Username"
              placeholder="e.g. analytics"
              updateConfig={(username) => dispatch(setAuthentication({ username }))}
              value={authConfig.username}
            />
            <CredentialsInput
              label="AWS Region"
              placeholder="us-east-1"
              updateConfig={(region) => dispatch(setAuthentication({ region }))}
              value={authConfig.region}
            />
            <CredentialsInput
              label="AWS Access Key ID"
              updateConfig={(accessKey) => dispatch(setAuthentication({ accessKey }))}
              value={authConfig.accessKey}
            />
            <CredentialsInput
              label="AWS Secret Key"
              placeholder="e.g. **********"
              type="password"
              updateConfig={(secretKey) => dispatch(setAuthentication({ secretKey }))}
              value={authConfig.secretKey}
            />
            <CredentialsInput
              optional
              label="SSL Cert"
              placeholder="e.g. -----BEGIN CERTIFICATE-----"
              type="textarea"
              updateConfig={(sslCert) => dispatch(setAuthentication({ sslCert }))}
              value={authConfig.sslCert}
            /> */}
          </>
        )}
      </>
    </>
  );
};
