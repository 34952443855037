import { FC, useState } from 'react';

import {
  ColumnConfigWithName,
  CustomerReportFilter,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_NUMBER_RANGE,
  FilterOperator,
  FilterValueType,
  FLOAT,
  INTEGER_DATA_TYPE,
  STRING,
} from '@explo/data';

import { MultiNumberAndStringFilter } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/MultiNumberAndStringFilter';
import { NumberAndStringFilter } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/NumberAndStringFilter';
import { NumberRangeFilter } from 'pages/ReportBuilder/ReportView/Filters/PopoverTypes/NumberRangeFilter';
import { FILTER_OPERATOR_TYPES_BY_ID, FILTER_OPERATORS } from 'types/filterOperations';
import {
  FilterableColumn,
  getFilterDefaultOperation,
  getOperatorName,
} from 'utils/customerReportUtils';
import { FilterPopover } from './FilterPopover';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  columnConfig: ColumnConfigWithName;
};

export const NumberAndStringFilterPopover: FC<Props> = ({ columnConfig, ...props }) => {
  const { clause, column } = props;
  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );
  const [value, setValue] = useState<FilterValueType>(clause?.filterValue);

  const isStringCol = column.type === STRING;
  const operator = FILTER_OPERATOR_TYPES_BY_ID[currOperator];

  return (
    <FilterPopover
      clause={clause}
      column={column}
      operator={currOperator}
      operatorOptions={isStringCol ? stringOptions : numberOptions}
      setOperator={setOperator}
      value={value}>
      {FILTER_OPS_NO_VALUE.has(operator.id) ? null : FILTER_OPS_MULTISELECT.has(operator.id) ||
        FILTER_OPS_SELECT.has(operator.id) ? (
        <MultiNumberAndStringFilter {...props} columnConfig={columnConfig} onChange={setValue} />
      ) : FILTER_OPS_NUMBER_RANGE.has(operator.id) ? (
        <NumberRangeFilter {...props} onChange={setValue} />
      ) : (
        <NumberAndStringFilter {...props} onChange={setValue} />
      )}
    </FilterPopover>
  );
};

const FILTER_OPS_SELECT = new Set([
  FilterOperator.STRING_IS,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.NUMBER_EQ,
  FilterOperator.NUMBER_NEQ,
]);

const numberOptions = FILTER_OPERATORS.filter(
  (op) =>
    (op.supported_column_types.has(INTEGER_DATA_TYPE) || op.supported_column_types.has(FLOAT)) &&
    !FILTER_OPS_SELECT.has(op.id),
).map((op) => ({ ...op, name: getOperatorName(op) }));

const stringOptions = FILTER_OPERATORS.filter(
  (op) => op.supported_column_types.has(STRING) && !FILTER_OPS_SELECT.has(op.id),
).map((op) => ({ ...op, name: getOperatorName(op) }));
