import cx from 'classnames';
import { FC, useContext, forwardRef, Ref, DetailedHTMLProps, HTMLAttributes } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { IconSize, IconName } from 'components/ds/Icon';
import { base as baseStyles } from 'components/ds/Icon/index.css';
import { LOADING_SPINNER_CLASS_NAME } from 'constants/exportConstants';
import { GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

type HTMLDivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>;
export type SpinnerSize = IconSize;
export type SpinnerWidth = 'thick' | 'regular' | 'light' | 'thin';
export type Props = HTMLDivProps & {
  // Determine whether spinner should fill container or be inline
  fillContainer?: boolean;
  // Size of the spinner relative to font size
  size?: SpinnerSize;
  width?: SpinnerWidth;
};

const SPINNER_NAMES: Record<SpinnerWidth, [IconName, IconName | string]> = {
  thick: ['spinner', ''],
  regular: ['spinnerRegular', 'circleRegular'],
  light: ['spinnerLight', 'circleLight'],
  thin: ['spinnerThin', 'circleThin'],
};

export const EmbedSpinner: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ className, fillContainer, size = 'xl', width, ...props }, ref) => {
    const { globalStyleConfig } = useContext(GlobalStylesContext);
    // Only apply custom size when filling container
    const currentSize = fillContainer ? (globalStyleConfig.components?.spinner.size ?? size) : size;
    const currentWidth = globalStyleConfig.components?.spinner.width ?? width;

    const [iconName, circleName] = SPINNER_NAMES[currentWidth ?? 'regular'];

    return (
      <div
        {...props}
        className={cx(
          {
            [sprinkles({ parentContainer: 'fill', flexItems: 'center' })]: fillContainer,
            [sprinkles({ position: 'relative' })]: !fillContainer,
            [baseStyles({ size: currentSize })]: !fillContainer, // Force IconSize if not fillContainer to deal with absolute positioning
          },
          className,
        )}
        ref={ref as Ref<HTMLDivElement>}>
        <Icon
          spin
          className={cx(
            LOADING_SPINNER_CLASS_NAME,
            sprinkles({ position: 'absolute' }),
            embedSprinkles({ color: 'spinner' }),
          )}
          name={iconName}
          size={currentSize}
        />
        {circleName ? (
          <Icon
            className={cx(
              sprinkles({ position: 'absolute' }),
              embedSprinkles({ color: 'spinner' }),
            )}
            name={circleName as IconName}
            size={currentSize}
            style={{ opacity: 0.4 }}
          />
        ) : null}
      </div>
    );
  },
);

EmbedSpinner.displayName = 'EmbedSpinner';
