import { DateTime, DurationUnits } from 'luxon';

import {
  DATE_PIVOT_AGGS_SET,
  DATE_RELATIVE_OPTION,
  DATETIME_PART_PIVOT_AGG_SET,
  DEFAULT_DATE_RANGES,
  FilterClause,
  FilterOperator,
  FilterValueDateType,
  FilterValueRelativeDateType,
  GroupByBucket,
  PivotAgg,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_RANGES,
  Timezones,
  zoneToUtc,
} from '@explo/data';

import { CategoryChartColumnInfo, SmartBucketInfo } from 'constants/types';
import { DateRangeId, DateRangePresetConfig } from 'types/dashboardTypes';
import { FilterOperation } from 'types/dataPanelTemplate';
import { getCustomDateRange } from 'utils/dateRangeUtils';
import { groupBy } from 'utils/standard';

import { getColBucketName } from './dataPanelColUtils';

type ColNames = { xAxisColName: string; yAxisColNames: string[]; colorColName: string };
type PreviewData = Record<string, string | number>[];

//TODO(SHIBA-5659): Unit test all of these utils

/*
 * Utility to get the offset parameter to shift a date based on filter value's
 * relative time type (days, weeks, months, years)
 */
const getRelativeDateOffset = (filterValue: FilterValueRelativeDateType) => {
  const timeType = filterValue.relativeTimeType?.id;
  const isDays = timeType === DATE_RELATIVE_OPTION.DAYS;
  // We add one if the time type is DAYS so that we include the current day
  const offset = (filterValue.number ?? 0) + (isDays ? 1 : 0);
  return {
    days: isDays ? offset : 0,
    weeks: timeType === DATE_RELATIVE_OPTION.WEEKS ? offset : 0,
    months: timeType === DATE_RELATIVE_OPTION.MONTHS ? offset : 0,
    years: timeType === DATE_RELATIVE_OPTION.YEARS ? offset : 0,
  };
};

/*
 * Given a list of filter operation types and values on the xAxisCol, find the latest start of their overlapping date range
 * Any non-overlapping date range would yield a "No Data" state, so we'd never get here anyway
 */
export const getLatestStartDate = (
  filterClauses: FilterClause[],
  xAxisColName?: string,
  timezone?: string,
) => {
  if (!filterClauses.length) return;

  const currentDate = DateTime.now();
  let currentMax = -Infinity;

  filterClauses.forEach((clause) => {
    if (clause.filterColumn?.name !== xAxisColName) return;

    const date = (clause.filterValue as FilterValueDateType)?.startDate;

    switch (clause.filterOperation?.id) {
      case FilterOperator.DATE_IS:
      case FilterOperator.DATE_IS_BETWEEN:
      case FilterOperator.DATE_GTE: {
        if (date) currentMax = Math.max(DateTime.fromISO(date).toMillis(), currentMax);
        break;
      }
      case FilterOperator.DATE_GT: {
        if (!date) return;
        const datetime = DateTime.fromISO(date).plus({ days: 1 });
        currentMax = Math.max(datetime.toMillis(), currentMax);
        break;
      }
      case FilterOperator.DATE_TODAY:
      case FilterOperator.DATE_NEXT: {
        currentMax = Math.max(currentDate.toMillis(), currentMax);
        break;
      }
      case FilterOperator.DATE_PREVIOUS: {
        const date = currentDate.minus(
          getRelativeDateOffset(clause.filterValue as FilterValueRelativeDateType),
        );
        if (date) currentMax = Math.max(date.toMillis(), currentMax);
        break;
      }
      case FilterOperator.DATE_IS_NOT:
      case FilterOperator.DATE_LT:
      case FilterOperator.DATE_LTE:
        // explicitly do not set a start date for the DATE_IS_NOT, LT, and LTE cases, it will be the start of the valid data
        return;
    }
  });

  if (currentMax === -Infinity) return;

  return DateTime.fromMillis(currentMax, FromMillisOpts).setZone(timezone ?? Timezones.UTC);
};

/*
 * Given a list of filter operation types and values on the xAxisCol, find the earliest end of their overlapping date range
 * Any non-overlapping date range would yield a "No Data" state, so we'd never get here anyway
 */
export const getEarliestEndDate = (
  filterClauses: FilterClause[],
  xAxisColName?: string,
  timezone?: string,
) => {
  if (!filterClauses.length) return;

  const currentDate = DateTime.now();
  let currentMin = Infinity;

  filterClauses.forEach((clause) => {
    if (clause.filterColumn?.name !== xAxisColName) return;

    const date = (clause.filterValue as FilterValueDateType)?.startDate;

    switch (clause.filterOperation?.id) {
      case FilterOperator.DATE_IS:
      case FilterOperator.DATE_LTE: {
        if (date) currentMin = Math.min(DateTime.fromISO(date).toMillis(), currentMin);
        break;
      }
      case FilterOperator.DATE_LT: {
        if (!date) return;
        const datetime = DateTime.fromISO(date).minus({ days: 1 });
        currentMin = Math.min(datetime.toMillis(), currentMin);
        break;
      }
      case FilterOperator.DATE_IS_BETWEEN: {
        const endDate = (clause.filterValue as FilterValueDateType)?.endDate;
        if (endDate) currentMin = Math.min(DateTime.fromISO(endDate).toMillis(), currentMin);
        break;
      }
      case FilterOperator.DATE_TODAY:
      case FilterOperator.DATE_PREVIOUS: {
        currentMin = Math.min(currentDate.toMillis(), currentMin);
        break;
      }
      case FilterOperator.DATE_NEXT: {
        const date = currentDate.plus(
          getRelativeDateOffset(clause.filterValue as FilterValueRelativeDateType),
        );
        if (date) currentMin = Math.min(date.toMillis(), currentMin);
        break;
      }
      case FilterOperator.DATE_IS_NOT:
      case FilterOperator.DATE_GT:
      case FilterOperator.DATE_GTE:
        // explicitly do not set an end date for the DATE_IS_NOT, GT, and GTE cases, it will be the end of the valid data
        return;
    }
  });

  if (currentMin === Infinity) return;

  return DateTime.fromMillis(currentMin, FromMillisOpts).setZone(timezone ?? Timezones.UTC);
};

export const insertZeroesForMissingDateData = (
  previewData: PreviewData,
  column: CategoryChartColumnInfo | undefined,
  colNames: ColNames,
  filterInfo?: FilterOperation,
  timezone?: string,
) => {
  const bucket = column?.bucket;
  if (!bucket) return;

  // Find the smallest intersection of the date filter clauses over the x axis
  const filterClauses = filterInfo?.instructions.filterClauses ?? [];
  const startDate = getLatestStartDate(filterClauses, column.column.name, timezone);
  const endDate = getEarliestEndDate(filterClauses, column.column.name, timezone);
  if (DATE_PIVOT_AGGS_SET.has(bucket.id)) {
    insertUnsortedZeroesForMissingDates(
      previewData,
      bucket,
      colNames,
      column.smartBucketInfo,
      startDate,
      endDate,
    );
  } else if (DATETIME_PART_PIVOT_AGG_SET.has(bucket.id)) {
    // TODO: consider removing, we never hit this code path
    insertUnsortedZeroesForMissingDateParts(previewData, bucket, colNames);
  } else return;

  // Highcharts needs pre-sorted data
  previewData.sort((a, b) => {
    return (a[colNames.xAxisColName] as number) - (b[colNames.xAxisColName] as number);
  });
};

const FromMillisOpts = { zone: Timezones.UTC };

const insertUnsortedZeroesForMissingDates = (
  previewData: PreviewData,
  bucket: GroupByBucket,
  colNames: ColNames,
  smartBucketInfo: SmartBucketInfo | undefined,
  filterStartDate?: DateTime,
  filterEndDate?: DateTime,
) => {
  const { xAxisColName, colorColName } = colNames;
  const aggBucket = getColBucketName(bucket.id).toLowerCase();

  const attachMissingDates = (data: PreviewData, colorValue?: string | number) => {
    const missingDates = getMissingDates(
      data,
      colNames,
      smartBucketInfo,
      aggBucket,
      filterStartDate,
      filterEndDate,
    );

    missingDates.forEach((row) => {
      if (colorValue !== undefined) row[colorColName] = colorValue;
      previewData.push(row);
    });
  };

  if (colorColName !== xAxisColName) {
    // If data is grouped need to group the previewData to attach correct empty values
    // and then add those new values to previewData
    const dataByColorCategory = groupBy(previewData, (row) => row[colorColName]);
    Object.entries(dataByColorCategory).forEach(([colorValue, data]) =>
      attachMissingDates(data, colorValue),
    );
  } else {
    attachMissingDates(previewData);
  }
};

const getMissingDates = (
  data: PreviewData,
  { xAxisColName, yAxisColNames }: ColNames,
  smartBucketInfo: SmartBucketInfo | undefined,
  aggBucket: string,
  filterStartDate?: DateTime,
  filterEndDate?: DateTime,
): PreviewData => {
  const newData: PreviewData = [];
  // this is the gap between points on the x-axis (e.g. if we're bucketing by day, 6/10 and 6/13 will have a gap of 2)
  const checkDateGap = (
    fromDate: DateTime,
    toDate: DateTime,
    outerValues = false,
    goBackwards = false,
  ) => {
    // When adding outer values for smart grouping we need to make sure that we add one
    const xAxisDateGap = getDateGap(fromDate, toDate, aggBucket, outerValues);
    if (xAxisDateGap <= 1) return;

    // Fill in each gap between the current and next date e.g. 1 day out, 2 days out, etc.
    for (let gapPart = 1; gapPart < xAxisDateGap; gapPart++) {
      // Reset the currDate to 12AM UTC before adding time onto it to avoid off-by-ones in certain edge cases
      let missingDate: DateTime = (goBackwards ? toDate : fromDate).toUTC().startOf('day');
      missingDate = goBackwards
        ? missingDate.minus({ [aggBucket]: gapPart })
        : missingDate.plus({ [aggBucket]: gapPart });

      // Push at the end and then sort later instead of insertion because it is less expensive
      const datum = {
        [xAxisColName]: missingDate.toLocal().valueOf(),
      };
      // Support multiple y-axis values
      yAxisColNames.forEach((yAxisColName) => (datum[yAxisColName] = 0));
      newData.push(datum);
    }
  };

  const dataLength = data.length;
  for (let idx = 0; idx < dataLength; idx++) {
    const dateValue = data[idx][xAxisColName];
    const currDate =
      typeof dateValue === 'number'
        ? DateTime.fromMillis(dateValue, FromMillisOpts)
        : DateTime.fromISO(dateValue);
    if (idx === 0) {
      // Add zeroes before the first value if there are any missing dates
      if (filterStartDate) {
        checkDateGap(filterStartDate, currDate, true, true);
      } else if (smartBucketInfo) {
        const earliestDate = DateTime.fromMillis(smartBucketInfo.startTime, FromMillisOpts);
        checkDateGap(earliestDate, currDate, true, true);
      }
    }
    if (idx === dataLength - 1) {
      // Add zeroes after the last value if theres any missing dates
      if (filterEndDate) checkDateGap(currDate, filterEndDate, true);
      else if (smartBucketInfo) {
        const latestDate = DateTime.fromMillis(smartBucketInfo.endTime, FromMillisOpts);
        checkDateGap(currDate, latestDate, true);
      }
      continue;
    }
    const nextValue = data[idx + 1][xAxisColName];
    const nextDate =
      typeof nextValue === 'number'
        ? DateTime.fromMillis(nextValue, FromMillisOpts)
        : DateTime.fromISO(nextValue);
    checkDateGap(currDate, nextDate);
  }
  return newData;
};

const insertUnsortedZeroesForMissingDateParts = (
  previewData: PreviewData,
  bucket: GroupByBucket,
  { xAxisColName, yAxisColNames }: ColNames,
) => {
  const existingDateParts = new Set();
  previewData.forEach((row) => existingDateParts.add(row[xAxisColName]));

  const start = 0;
  const end = DatePartToMaxBoundMapping.get(bucket.id);
  if (!end) return;
  for (let datePart = start; datePart < end; datePart++) {
    if (existingDateParts.has(datePart)) continue;

    const datum = { [xAxisColName]: datePart };
    yAxisColNames.forEach((yAxisColName) => (datum[yAxisColName] = 0));
    previewData.push(datum);
  }
};

const getDateGap = (
  currDate: DateTime,
  nextDate: DateTime,
  aggDuration: string,
  outerValues?: boolean,
) => {
  const diff = nextDate.diff(currDate, aggDuration as DurationUnits);
  switch (aggDuration) {
    case 'hour':
      return outerValues ? Math.floor(diff.hours) + 1 : Math.round(diff.hours);
    case 'day':
      return outerValues ? Math.floor(diff.days) + 1 : Math.round(diff.days);
    case 'week':
      return outerValues ? Math.floor(diff.weeks) + 1 : Math.round(diff.weeks);
    case 'month':
      return outerValues ? Math.floor(diff.months) + 1 : Math.round(diff.months);
    case 'year':
      return outerValues ? Math.floor(diff.years) + 1 : Math.round(diff.years);
  }

  return 0;
};

const DatePartToMaxBoundMapping: Map<string, number> = new Map([
  [PivotAgg.DATE_PART_HOUR, 24],
  [PivotAgg.DATE_PART_MONTH, 12],
  [PivotAgg.DATE_PART_MONTH_DAY, 31],
  [PivotAgg.DATE_PART_WEEK_DAY, 7],
]);

export const getDateMin = (
  range: RELATIVE_DATE_RANGES | undefined,
  timezone: string,
): DateTime | undefined => {
  if (!range || range === RELATIVE_DATE_RANGES.PAST_DATES) return;

  const minDate = DateTime.local().setZone(timezone);

  switch (range) {
    case RELATIVE_DATE_RANGES.TODAY:
      return zoneToUtc(minDate.startOf('day'));
    case RELATIVE_DATE_RANGES.YESTERDAY:
      return zoneToUtc(minDate.minus({ days: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_WEEK:
      return zoneToUtc(minDate.startOf('week').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_MONTH:
      return zoneToUtc(minDate.startOf('month').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_YEAR:
      return zoneToUtc(minDate.startOf('year').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_WEEK:
      return zoneToUtc(minDate.startOf('week').minus({ weeks: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_MONTH:
      return zoneToUtc(minDate.startOf('month').minus({ months: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.PREVIOUS_YEAR:
      return zoneToUtc(minDate.startOf('year').minus({ years: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_WEEK:
      return zoneToUtc(minDate.minus({ days: 6 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_MONTH:
      return zoneToUtc(minDate.minus({ days: 30 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_YEAR:
      return zoneToUtc(minDate.minus({ days: 365 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_THREE_YEARS:
      return zoneToUtc(minDate.minus({ year: 3 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_FIVE_YEARS:
      return zoneToUtc(minDate.minus({ year: 5 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_TEN_YEARS:
      return zoneToUtc(minDate.minus({ year: 10 }).startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_QUARTER:
      return zoneToUtc(minDate.startOf('quarter').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_QUARTER:
      return zoneToUtc(minDate.startOf('quarter').minus({ quarter: 1 }).startOf('day'));
  }
};

export const getDateMax = (
  range: RELATIVE_DATE_RANGES | undefined,
  timezone: string,
): DateTime | undefined => {
  if (!range) return;

  const maxDate = DateTime.local().setZone(timezone);

  switch (range) {
    case RELATIVE_DATE_RANGES.TODAY:
      return zoneToUtc(maxDate.endOf('day'));
    case RELATIVE_DATE_RANGES.YESTERDAY:
      return zoneToUtc(maxDate.minus({ days: 1 }).endOf('day'));
    case RELATIVE_DATE_RANGES.THIS_WEEK:
      return zoneToUtc(maxDate.endOf('week').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_MONTH:
      return zoneToUtc(maxDate.endOf('month').startOf('day'));
    case RELATIVE_DATE_RANGES.THIS_YEAR:
      return zoneToUtc(maxDate.endOf('year').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_WEEK:
      return zoneToUtc(maxDate.endOf('week').minus({ days: 7 }).startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_MONTH:
      return zoneToUtc(maxDate.minus({ months: 1 }).endOf('month').startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_WEEK:
      return zoneToUtc(maxDate.plus({ days: 6 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_MONTH:
      return zoneToUtc(maxDate.plus({ days: 30 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_A_YEAR:
      return zoneToUtc(maxDate.plus({ days: 365 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_THREE_YEARS:
      return zoneToUtc(maxDate.plus({ year: 3 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_FIVE_YEARS:
      return zoneToUtc(maxDate.plus({ year: 5 }).startOf('day'));
    case RELATIVE_DATE_RANGES.WITHIN_TEN_YEARS:
      return zoneToUtc(maxDate.plus({ year: 10 }).startOf('day'));
    case RELATIVE_DATE_RANGES.PAST_DATES:
      return zoneToUtc(maxDate);
    case RELATIVE_DATE_RANGES.THIS_QUARTER:
      return zoneToUtc(maxDate.endOf('quarter').startOf('day'));
    case RELATIVE_DATE_RANGES.LAST_QUARTER:
      return zoneToUtc(maxDate.endOf('quarter').minus({ quarter: 1 }).startOf('day'));
    case RELATIVE_DATE_RANGES.PREVIOUS_YEAR:
      return zoneToUtc(maxDate.endOf('year').minus({ year: 1 }).startOf('day'));
  }
};

export const getDefaultRelativeValue = (
  defaultRelativeOption: RELATIVE_DATE_OPTIONS,
  timezone: string,
): DateTime => {
  const date = DateTime.local().setZone(timezone);

  switch (defaultRelativeOption) {
    case RELATIVE_DATE_OPTIONS.CURRENT_DAY:
      return zoneToUtc(date.startOf('day'));
    case RELATIVE_DATE_OPTIONS.YESTERDAY:
      return zoneToUtc(date.startOf('day').minus({ days: 1 }));
    case RELATIVE_DATE_OPTIONS.SEVEN_DAYS_AGO:
      return zoneToUtc(date.startOf('day').minus({ days: 7 }));
    case RELATIVE_DATE_OPTIONS.THIRTY_DAYS_AGO:
      return zoneToUtc(date.startOf('day').minus({ days: 30 }));
    case RELATIVE_DATE_OPTIONS.ONE_YEAR_AGO:
      return zoneToUtc(date.startOf('day').minus({ year: 1 }));
    case RELATIVE_DATE_OPTIONS.START_OF_WEEK:
      return zoneToUtc(date.startOf('week').startOf('day'));
    case RELATIVE_DATE_OPTIONS.START_OF_MONTH:
      return zoneToUtc(date.startOf('month').startOf('day'));
    case RELATIVE_DATE_OPTIONS.START_OF_YEAR:
      return zoneToUtc(date.startOf('year').startOf('day'));
    case RELATIVE_DATE_OPTIONS.END_OF_MONTH:
      return zoneToUtc(date.endOf('month').startOf('day'));
    case RELATIVE_DATE_OPTIONS.END_OF_WEEK:
      return zoneToUtc(date.endOf('week').startOf('day'));
    case RELATIVE_DATE_OPTIONS.END_OF_YEAR:
      return zoneToUtc(date.endOf('year').startOf('day'));
  }
};

export const getDefaultRangeValues = (
  defaultRangeType: DateRangeId,
  endDateEndOfDay: boolean | undefined,
  presetRanges: Record<string, DateRangePresetConfig> | undefined,
  timezone: string,
) => {
  let startDate: DateTime | undefined;
  let endDate = DateTime.local().setZone(timezone);

  switch (defaultRangeType) {
    case DEFAULT_DATE_RANGES.TODAY:
      startDate = getDateMin(RELATIVE_DATE_RANGES.TODAY, timezone);
      break;
    case DEFAULT_DATE_RANGES.YESTERDAY:
      startDate = getDateMin(RELATIVE_DATE_RANGES.YESTERDAY, timezone);
      endDate = startDate ? startDate.endOf('day') : endDate;
      break;
    case DEFAULT_DATE_RANGES.THIS_WEEK:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_WEEK, timezone);
      break;
    case DEFAULT_DATE_RANGES.THIS_MONTH:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_MONTH, timezone);
      break;
    case DEFAULT_DATE_RANGES.THIS_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_YEAR, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_WEEK:
      startDate = getDateMin(RELATIVE_DATE_RANGES.LAST_WEEK, timezone);
      endDate = startDate ? startDate.endOf('week') : endDate;
      break;
    case DEFAULT_DATE_RANGES.LAST_MONTH:
      startDate = getDateMin(RELATIVE_DATE_RANGES.LAST_MONTH, timezone);
      endDate = startDate ? startDate.endOf('month') : endDate;
      break;
    case DEFAULT_DATE_RANGES.PREVIOUS_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.PREVIOUS_YEAR, timezone);
      endDate = startDate ? startDate.endOf('year') : endDate;
      break;
    case DEFAULT_DATE_RANGES.LAST_7_DAYS:
      startDate = getDateMin(RELATIVE_DATE_RANGES.WITHIN_A_WEEK, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_30_DAYS:
      startDate = zoneToUtc(DateTime.local().setZone(timezone).minus({ days: 29 }).startOf('day'));
      break;
    case DEFAULT_DATE_RANGES.LAST_3_MONTHS:
      startDate = endDate.minus({ month: 3 }).startOf('day');
      break;
    case DEFAULT_DATE_RANGES.LAST_6_MONTHS:
      startDate = endDate.minus({ month: 6 }).startOf('day');
      break;
    case DEFAULT_DATE_RANGES.LAST_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.WITHIN_A_YEAR, timezone);
      break;
    case DEFAULT_DATE_RANGES.THIS_QUARTER:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_QUARTER, timezone);
      break;
    case DEFAULT_DATE_RANGES.LAST_QUARTER: {
      startDate = getDateMin(RELATIVE_DATE_RANGES.LAST_QUARTER, timezone);
      endDate = startDate ? startDate.endOf('quarter') : endDate;
      break;
    }
    case DEFAULT_DATE_RANGES.LAST_12_COMPLETE_MONTHS:
      endDate = endDate.startOf('month').minus({ day: 1 });
      startDate = endDate.minus({ month: 11 }).startOf('month');
      break;
    case DEFAULT_DATE_RANGES.LAST_4_COMPLETE_WEEKS:
      endDate = endDate.startOf('week').minus({ day: 1 });
      startDate = endDate.minus({ week: 3 }).startOf('week');
      break;
    case DEFAULT_DATE_RANGES.LAST_12_COMPLETE_WEEKS:
      endDate = endDate.startOf('week').minus({ day: 1 });
      startDate = endDate.minus({ week: 11 }).startOf('week');
      break;
    case DEFAULT_DATE_RANGES.LAST_3_COMPLETE_MONTHS:
      startDate = endDate.minus({ months: 3 }).startOf('month');
      endDate = endDate.minus({ months: 1 }).endOf('month');
      break;
    case DEFAULT_DATE_RANGES.LAST_6_COMPLETE_MONTHS:
      startDate = endDate.minus({ months: 6 }).startOf('month');
      endDate = endDate.minus({ months: 1 }).endOf('month');
      break;
    case DEFAULT_DATE_RANGES.YEAR_TO_LAST_COMPLETED_MONTH:
      // if january, the range should be last year to end of december
      if (endDate.month === 1) {
        startDate = endDate.minus({ year: 1 }).startOf('year');
        endDate = endDate.minus({ months: 1 }).endOf('month');
      } else {
        startDate = endDate.startOf('year');
        endDate = endDate.minus({ months: 1 }).endOf('month');
      }
      break;
    case DEFAULT_DATE_RANGES.FULL_WEEK:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_WEEK, timezone);
      endDate = startDate ? startDate.endOf('week') : endDate;
      break;
    case DEFAULT_DATE_RANGES.FULL_MONTH:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_MONTH, timezone);
      endDate = startDate ? startDate.endOf('month') : endDate;
      break;
    case DEFAULT_DATE_RANGES.FULL_QUARTER:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_QUARTER, timezone);
      endDate = startDate ? startDate.endOf('quarter') : endDate;
      break;
    case DEFAULT_DATE_RANGES.FULL_YEAR:
      startDate = getDateMin(RELATIVE_DATE_RANGES.THIS_YEAR, timezone);
      endDate = startDate ? startDate.endOf('year') : endDate;
      break;
    default: {
      if (!presetRanges || !(defaultRangeType in presetRanges)) break;
      const presetConfig = presetRanges[defaultRangeType];
      const customDate = getCustomDateRange(
        presetConfig.startDate.operations,
        presetConfig.endDate.operations,
        timezone,
      );
      startDate = customDate.startDate;
      endDate = customDate.endDate;
      break;
    }
  }

  if (endDateEndOfDay) endDate = endDate.endOf('day');

  // this shouldn't ever happen, but just so we don't crash
  if (!startDate) startDate = endDate;

  return { startDate: zoneToUtc(startDate), endDate: zoneToUtc(endDate) };
};

/**
 * Get the current date as an ISO string
 */
export const getCurrentISOString = () => zoneToUtc(DateTime.now()).toISO();

export const isDateBetweenLimits = (
  date: DateTime,
  minDate?: DateTime,
  maxDate?: DateTime,
): boolean => {
  if (minDate && maxDate) {
    if (date < minDate || date > maxDate) return false;
  } else if (minDate) {
    if (date < minDate) return false;
  } else if (maxDate) {
    if (date > maxDate) return false;
  }

  return true;
};
