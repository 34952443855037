import { MenuItem } from '@blueprintjs/core';
import { IItemRendererProps, Select } from '@blueprintjs/select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { useState, useMemo, FC } from 'react';

import { DatasetSchema, BaseCol } from '@explo/data';

import { sprinkles } from 'components/ds';
import { SelectedDropdownInputItem } from 'constants/types';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { createColOptionsWithIcon } from 'utils/general';
import { keyBy, sortBy } from 'utils/standard';

const MENU_ITEM_CLASS_NAME = 'column-menu-item';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(1),
    position: 'relative',

    '& .bp3-popover': {
      width: '100%',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey300}`,
    },

    '& li:hover': {
      color: theme.palette.ds.blue,
      backgroundColor: theme.palette.ds.lightBlue,
      borderRadius: theme.spacing(1),

      '& .bp3-menu-item:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .bp3-input': {
      backgroundColor: 'transparent',
    },
  },
  popoverContainer: {
    maxHeight: 200,
    overflow: 'auto',
  },
  popoverTarget: {
    display: 'block !important',
    '& .bp3-input:focus': {
      boxShadow: 'none !important',
    },
  },
  required: {
    fontWeight: 400,
    fontStyle: 'italic',
    color: theme.palette.ds.grey900,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2.5),

    '&:hover': {
      cursor: 'default',
    },
  },
  noBoxShadow: {
    '& .bp3-input': {
      boxShadow: 'none',
    },

    '& .bp3-input:focus, .bp3-input.bp3-active': {
      boxShadow: 'none',
    },
  },
  selectMenuItem: {
    '&.bp3-active': {
      backgroundColor: `${theme.palette.ds.blue} !important`,
    },
  },
}));

type Props = {
  draggingClass?: string;
  required?: boolean;
  onItemSelect: (column: BaseCol) => void;
  schema: DatasetSchema;
  isValidDrop?: boolean;
};

export const EmptyDroppableSection: FC<Props> = ({
  draggingClass,
  required,
  onItemSelect,
  schema,
  isValidDrop,
}) => {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const selectItems = useMemo(() => createColOptionsWithIcon(sortBy(schema, 'name')), [schema]);

  const schemaByName = useMemo(() => keyBy(schema, 'name'), [schema]);

  const itemRenderer = (
    item: SelectedDropdownInputItem,
    { handleClick, index, modifiers }: IItemRendererProps,
  ) => {
    let itemText;
    if (searchQuery.trim().length > 0) {
      if (!item.name.toString().toLowerCase().includes(searchQuery.toLowerCase())) return null;

      // replace each instance of the query with a bolded version (wrapped in <b />)
      // so that the item shows the query highlighted in it when searching
      const textPartsWithoutQuery = item.name.split(searchQuery);
      itemText = [];
      for (let i = 0; i < textPartsWithoutQuery.length - 1; i++) {
        itemText.push(textPartsWithoutQuery[i]);
        itemText.push(<b key={`query-text-${i}`}>{searchQuery}</b>);
      }
      itemText.push(textPartsWithoutQuery[textPartsWithoutQuery.length - 1]);
    } else if (item.isDivider) {
      return <div key={`divider-${index}`}></div>;
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        active={modifiers.active}
        className={cx(classes.selectMenuItem, MENU_ITEM_CLASS_NAME)}
        icon={item.icon}
        key={`${item.id}-${index}`}
        onClick={handleClick}
        text={itemText}
      />
    );
  };

  const borderColor = isValidDrop === undefined ? 'outline' : isValidDrop ? 'blue9' : 'red';

  return (
    <div
      onBlur={(e) =>
        !e.relatedTarget?.className.includes(MENU_ITEM_CLASS_NAME) && setIsPopoverOpen(false)
      }>
      <Select
        matchTargetWidth
        className={cx(classes.root, sprinkles({ borderColor, border: 'dashed' }), draggingClass)}
        filterable={false}
        itemRenderer={itemRenderer}
        items={selectItems}
        onItemSelect={(item) => {
          onItemSelect(schemaByName[item.name]);
          setIsPopoverOpen(false);
        }}
        popoverProps={{
          minimal: true,
          usePortal: false,
          targetClassName: classes.popoverTarget,
          isOpen: isPopoverOpen,
          popoverClassName: classes.popoverContainer,
        }}>
        <InputGroup
          className={classes.noBoxShadow}
          onInputChange={setSearchQuery}
          onMouseDown={() => setIsPopoverOpen(!isPopoverOpen)}
          placeholder="Search or drag a field here..."
          rightElement={required ? <div className={classes.required}>Required</div> : undefined}
        />
      </Select>
    </div>
  );
};
