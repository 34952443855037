import { PayloadAction } from '@reduxjs/toolkit';

import {
  AggColInfo,
  ChartAggregation,
  CustomerReportFilter,
  CustomerReportGroupBy,
  DatasetRow,
  GradientType,
  OPERATION_TYPES,
  SortAxis,
  SortInfo,
  SortOption,
  SortOrder,
} from '@explo/data';

import { Configuration, Entitlements, FeatureFlags } from 'flags/flags';
import { GlobalFontConfig, GlobalStyleConfig } from 'globalStyles/types';
import { CustomerReportBuilderMetadata, ReportUpdate } from 'reportBuilderContent/apiTypes';
import { ReportType } from 'reportBuilderContent/reducers/types';
import { PaymentPlan } from 'utils/paymentPlanUtils';

import { defineEmbedPostAction } from './actionUtils';
import { EmbedFidoDashboardDataConfig } from './embedActions';
import { EmbedReportBuilder } from './reportBuilderActions';
import { ReportBuilderDataset } from './reportBuilderConfigActions';
import { EmbedReportBuilderVersion } from './reportBuilderVersionActions';
import { Customer, ReportedAnalyticsAction } from './teamActions';
import { ACTION } from './types';

export interface CustomerReportDataInfo {
  datasetId: string;
  columns: string[];
}

export type CustomerReportAgg = AggColInfo;

export type TotalAccumulatorData = {
  items?: Set<number | string>;
  [ChartAggregation.AVG]?: number;
  [ChartAggregation.SUM]?: number;
  [ChartAggregation.MIN]?: number;
  [ChartAggregation.MAX]?: number;
  [ChartAggregation.COUNT]?: number;
  [ChartAggregation.COUNT_DISTINCT]?: number;
};

export type TotalAccumulatorKey = keyof Omit<TotalAccumulatorData, 'items'>;

export type TotalAccumulator = {
  pivotColumn?: TotalAccumulatorData;
  columns: Partial<Record<string, TotalAccumulatorData>>;
};

/**
 * Stores what aggregation to use when totaling values for a column
 * Key is the column path for pivot tables follow this format: 'groupBys-aggregations-colGroupBys'
 * Value is what aggregation to use when totalling
 * i.e. If our table has groupBys=['state','city'], colGroupBys=['municipality','neighborhood'], and aggregations=[{birthrate: 'avg}]
 * Example key for 1st level of grouping: 'ontario-toronto-birthrate-avg-markham' (aggregates data from 2nd level of grouping)
 * Example keys for 2nd level of grouping: 'ontario-toronto-birthrate-avg-markham-midtown' (aggregates data from children)
 */
export type CustomerReportTotals = Record<string, TotalAccumulatorKey>;

interface BaseCustomerReportView {
  id: string;
  name: string;
  columnOrder: string[];
  hiddenColumns: string[];
  filters: CustomerReportFilter[];
  sort?: SortInfo[];
  groupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  columnGroupBys?: CustomerReportGroupBy[];
  totals?: CustomerReportTotals;
  usePivot?: boolean; // Render pivot table
  showTotals?: boolean; // Show totals footer
}

interface TableChartView extends BaseCustomerReportView {
  // Tables are the default visualization so don't need to be explicitly set
  visualization?: OPERATION_TYPES.VISUALIZE_TABLE;
}

interface LineChartView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_LINE_CHART_V2;
}

interface PieChartView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_PIE_CHART_V2;
}

interface NumberView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_NUMBER_V2;
}

export interface BarChartOptions {
  direction?: BarVisualizationDirection;
  grouping?: BarVisualizationGrouping;
}

// Only used by bar chart right now but could be used by other visualizations in the future
export interface SortableChartOptions {
  axis?: SortAxis;
  direction?: SortOption;
}

export interface BarChartView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2;
  barChart?: BarChartOptions;
  sortOptions?: SortableChartOptions;
}

export interface AreaChartOptions {
  isPercent?: boolean;
}

export interface AreaChartView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_AREA_CHART_V2;
  areaChart?: AreaChartOptions;
}

export interface HeatMapOptions {
  showCellLabels?: boolean;
  gradientType?: GradientType;
}

export interface HeatMapView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2;
  heatMap?: HeatMapOptions;
}

export interface BarFunnelChartOptions {
  isCumulative?: boolean;
  sortedStages?: string[];
}

export interface BarFunnelChartView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;
  barFunnelChart?: BarFunnelChartOptions;
}

export interface ScatterPlotOptions {
  isMarkerRadiusLarge?: boolean;
}

export interface ScatterPlotView extends BaseCustomerReportView {
  visualization: OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2;
  scatterPlot?: ScatterPlotOptions;
  scatterPlotGrouping?: CustomerReportGroupBy;
}

export type CustomerReportView =
  | LineChartView
  | TableChartView
  | PieChartView
  | NumberView
  | BarChartView
  | AreaChartView
  | HeatMapView
  | BarFunnelChartView
  | ScatterPlotView;

export type CustomerReportVisualization = CustomerReportView['visualization'];

export interface CustomerReportConfig {
  dataInfo?: CustomerReportDataInfo;
  // Should not be an empty list, either undefined or has at least one view
  views?: CustomerReportView[];
}

export interface CustomerReport {
  id: number;
  name: string;
  config: CustomerReportConfig;
  modified: string;
  is_starred: boolean;
}

type ListCustomerReportsBody = {
  resource_embed_id: string | undefined;
  environment: string | null;
  report_type?: string | null;
};

export type FetchCustomerReportsTeamData = {
  id: number;
  name: string;
  explo_analytics_token: string;
  payment_plan: PaymentPlan;
  feature_flags: Pick<
    FeatureFlags,
    'use_job_queue' | 'use_fido' | 'use_rover' | 'enable_report_builder_image_export'
  >;
  entitlements: Entitlements;
  // TODO jgreissman make this not optional
  configuration?: Configuration;
  support_email?: string;
  default_currency_code?: string;
  default_locale_code?: string;
  use_browser_locale?: boolean;
  should_send_js_analytics_events?: boolean;
  analytics_reporting_actions?: ReportedAnalyticsAction[];
};

type ListCustomerReportsData = {
  style_config: GlobalStyleConfig | null;
  additional_style_configs: Record<string, GlobalStyleConfig> | null;
  font_config: GlobalFontConfig | null;
  customer: Pick<Customer, 'name' | 'is_demo_group' | 'provided_id' | 'id'>;
  report_builder_version: EmbedReportBuilderVersion;
  report_builder: EmbedReportBuilder;
  reports: CustomerReport[];
  metadata: CustomerReportBuilderMetadata;
  team: FetchCustomerReportsTeamData;
  environment_tag_id: number | null;
  fido_config?: EmbedFidoDashboardDataConfig | null;
  email_counts: Record<string, number>;
  expiration?: number;
  report_builder_claims?: string[];
};

export const {
  actionFn: listCustomerReports,
  requestAction: listCustomerReportsRequest,
  successAction: listCustomerReportsSuccess,
  errorAction: listCustomerReportsError,
} = defineEmbedPostAction<ListCustomerReportsBody, ListCustomerReportsData>(
  ACTION.FETCH_CUSTOMER_REPORTS,
  'customer_reports',
  'reports',
);

type FetchCustomerReportBody = {
  resource_embed_id: string | undefined;
  environment: string | null;
  report_type: ReportType.CUSTOMER_REPORT | ReportType.BUILT_IN;
};

type FetchCustomerReportData = {
  style_config: GlobalStyleConfig | null;
  customer: Pick<Customer, 'name' | 'is_demo_group' | 'provided_id' | 'id'>;
  report_builder_version: EmbedReportBuilderVersion;
  report_builder: EmbedReportBuilder;
  report: CustomerReport;
  report_type: ReportType.CUSTOMER_REPORT | ReportType.BUILT_IN;
  metadata: CustomerReportBuilderMetadata;
  team: FetchCustomerReportsTeamData;
  environment_tag_id: number | null;
  fido_config?: EmbedFidoDashboardDataConfig | null;
};

export const {
  actionFn: fetchCustomerReport,
  requestAction: fetchCustomerReportRequest,
  successAction: fetchCustomerReportSuccess,
  errorAction: fetchCustomerReportError,
} = defineEmbedPostAction<FetchCustomerReportBody, FetchCustomerReportData>(
  ACTION.FETCH_CUSTOMER_REPORT,
  'customer_reports',
  'report',
);

export const { actionFn: deleteCustomerReport, successAction: deleteCustomerReportSuccess } =
  defineEmbedPostAction<ReportUpdate, ReportUpdate>(
    ACTION.DELETE_CUSTOMER_REPORT,
    'customer_reports',
    'delete_report',
  );

type UpdateCustomerReportNameBody = ReportUpdate & { name: string };

export const {
  actionFn: updateCustomerReportName,
  successAction: updateCustomerReportNameSuccess,
} = defineEmbedPostAction<UpdateCustomerReportNameBody, UpdateCustomerReportNameBody>(
  ACTION.UPDATE_CUSTOMER_REPORT_NAME,
  'customer_reports',
  'update_report_name',
);

export type UpdateCustomerReportViewName = {
  viewId: string;
  name: string;
};

export type AddGroupByPayload = PayloadAction<{
  groupBy: CustomerReportGroupBy;
  isColumnGroupBy: boolean;
}>;

export type AddScatterPlotGroupingPayload = PayloadAction<{
  scatterPlotGrouping: CustomerReportGroupBy;
}>;

export type UpdateGroupByPayload = PayloadAction<{
  id: string;
  groupBy: CustomerReportGroupBy;
  isColumnGroupBy: boolean;
}>;

export type DeleteGroupByPayload = PayloadAction<{
  id: string;
  isColumnGroupBy: boolean;
}>;

export type UpdateColorCategoryTracker = PayloadAction<{
  rows: DatasetRow[];
  globalStyleConfig: GlobalStyleConfig;
  datasets?: Record<string, ReportBuilderDataset>;
}>;

export type SetViewPage = PayloadAction<number>;

export enum BarVisualizationDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum BarVisualizationGrouping {
  Grouped = 'grouped',
  Stacked = 'stacked',
}

export type UpdateSortCol = {
  colName: string;
  toDelete?: boolean;
  isNew?: boolean;
  newColName?: string;
  newOrder?: SortOrder;
};
