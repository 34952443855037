import { BranchContentResourceService, ResourceResponse } from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeFidoThunkRequest } from 'utils/thunkUtils';

export const getResourceOnBranchThunk = createAsyncThunk<
  ResourceResponse,
  { branchId: string; resourceId: string },
  { state: ReduxState }
>(ACTION.FIDO_GET_RESOURCE_ON_BRANCH, async ({ branchId, resourceId }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchContentResourceService.getResourceOnBranch(branchId, resourceId),
    fido.fidoToken ?? '',
    'Error fetching resource on branch',
  );
});
