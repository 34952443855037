import cx from 'classnames';
import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';

import {
  DATE_RELATIVE_OPTION,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_NUMBER,
  FILTER_OPS_RELATIVE_PICKER,
  FilterOperator,
  FilterValueDateType,
  FilterValueRelativeDateType,
  FilterValueType,
  getDatePickerDateFromISO,
  getFormatForDatePicker,
  getLuxonDateFromDatePicker,
  TIME_FORMATS,
} from '@explo/data';

import { Input } from 'components/ds';
import { EmbedInput } from 'components/embed';

import {
  DATE_RELATIVE_OPTIONS,
  DATE_RELATIVE_OPTIONS_BY_ID,
} from 'constants/dataPanelEditorConstants';
import DropdownSelect from 'shared/DropdownSelect';
import * as styles from './index.css';

type Props = {
  disabled?: boolean;
  embed?: boolean;
  filterOperator?: FilterOperator;
  filterValue: FilterValueType;
  onFilterValueUpdate: (value: FilterValueType) => void;
  usePortal?: boolean;
  adHocFilterSettings?: boolean;
  preventFutureDates?: boolean;
};

export const FilterValueInput: FC<Props> = ({
  disabled,
  embed,
  filterValue,
  filterOperator,
  adHocFilterSettings,
  preventFutureDates,
  onFilterValueUpdate,
  usePortal = true,
}) => {
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState(
    filterValue === undefined || filterValue === null ? '' : String(filterValue),
  );

  const RenderedInput = embed ? EmbedInput : Input;

  const updateStartDate = (startDate: string | null) => {
    let newVal: FilterValueType;
    if (!filterValue) {
      newVal = {
        startDate: startDate ?? undefined,
      };
    } else {
      newVal = {
        ...(filterValue as FilterValueDateType),
        startDate: startDate ?? undefined,
      };
    }

    onFilterValueUpdate(newVal);
  };

  const updateEndDate = (endDate: string | null) => {
    let newVal: FilterValueType;
    if (!filterValue) {
      newVal = {
        endDate: endDate ?? undefined,
      };
    } else {
      newVal = {
        ...(filterValue as FilterValueDateType),
        endDate: endDate ?? undefined,
      };
    }

    onFilterValueUpdate(newVal);
  };

  if (disabled || !filterOperator) {
    return <RenderedInput disabled fillWidth={embed} onSubmit={() => null} placeholder="Value" />;
  }

  if (FILTER_OPS_NO_VALUE.has(filterOperator)) return null;

  const VIEW_DATE_FORMAT = 'MM/DD/YYYY'; // we might want to support granular hours/minutes in the future
  if (FILTER_OPS_DATE_PICKER.has(filterOperator)) {
    let date: Date | null = null;
    const filterValueDate = filterValue as FilterValueDateType;
    if (filterValue) {
      date = filterValueDate.startDate ? getDatePickerDateFromISO(filterValueDate.startDate) : null;
    }

    return (
      <DatePicker
        disabledKeyboardNavigation
        showMonthDropdown
        showYearDropdown
        className={styles.datePicker}
        dateFormat={getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])}
        maxDate={preventFutureDates ? new Date() : undefined}
        onChange={(date) => {
          date = date as Date;

          updateStartDate(date ? getLuxonDateFromDatePicker(date).toISO() : null);
        }}
        placeholderText={VIEW_DATE_FORMAT}
        popperClassName={styles.datePopover}
        popperModifiers={{
          preventOverflow: { enabled: false },
          flip: { boundariesElement: 'scrollParent' },
        }}
        popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
        selected={date || undefined}
        withPortal={false}
      />
    );
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperator)) {
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    const filterValueDate = filterValue as FilterValueDateType;
    if (filterValue) {
      startDate = filterValueDate.startDate
        ? getDatePickerDateFromISO(filterValueDate.startDate)
        : null;
      endDate = filterValueDate.endDate ? getDatePickerDateFromISO(filterValueDate.endDate) : null;
    }

    return (
      <div className={styles.dateRange}>
        <DatePicker
          disabledKeyboardNavigation
          selectsStart
          showMonthDropdown
          showYearDropdown
          className={cx(styles.datePicker, styles.startDateRange)}
          dateFormat={getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])}
          endDate={endDate || null}
          maxDate={preventFutureDates ? new Date() : undefined}
          onChange={(newDate) => {
            newDate = newDate as Date;

            updateStartDate(newDate ? getLuxonDateFromDatePicker(newDate).toISO() : null);
          }}
          placeholderText={VIEW_DATE_FORMAT}
          popperClassName={styles.datePopover}
          popperModifiers={{
            preventOverflow: { enabled: false },
            flip: { boundariesElement: 'scrollParent' },
          }}
          popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
          selected={startDate || null}
          startDate={startDate || null}
          withPortal={false}
          wrapperClassName={styles.startDateRangeWrapper}
        />
        <DatePicker
          disabledKeyboardNavigation
          selectsEnd
          showMonthDropdown
          showYearDropdown
          className={cx(styles.datePicker, styles.endDateRange)}
          dateFormat={getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])}
          endDate={endDate || null}
          maxDate={preventFutureDates ? new Date() : undefined}
          onChange={(newDate) => {
            newDate = newDate as Date;

            updateEndDate(
              newDate ? getLuxonDateFromDatePicker(newDate).endOf('day').toISO() : null,
            );
          }}
          placeholderText={VIEW_DATE_FORMAT}
          popperClassName={styles.datePopover}
          popperModifiers={{
            preventOverflow: { enabled: false },
            flip: { boundariesElement: 'scrollParent' },
          }}
          popperPlacement="top-end"
          selected={endDate || null}
          startDate={startDate || null}
          withPortal={false}
        />
      </div>
    );
  } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOperator)) {
    const filterValueRelativeDate = filterValue as FilterValueRelativeDateType | undefined;
    const { relativeTimeType, number } = filterValueRelativeDate ?? {};
    const selectedRelativeTime = relativeTimeType
      ? DATE_RELATIVE_OPTIONS_BY_ID[relativeTimeType.id]
      : undefined;
    return (
      <div className={styles.relativeContainer}>
        <RenderedInput
          showInputButton
          className={styles.relativeNumInput}
          defaultValue={number !== undefined ? String(number) : ''}
          fillWidth={embed}
          onSubmit={(newValue) => {
            const newValNum = Number.parseInt(newValue);
            onFilterValueUpdate({ ...filterValueRelativeDate, number: newValNum });
          }}
        />
        <div className={styles.relativeTimeDropdown}>
          <DropdownSelect
            fillWidth
            minimal
            noSelectionText="Select time"
            onChange={(item) =>
              onFilterValueUpdate({
                ...filterValueRelativeDate,
                relativeTimeType: { id: item.id as DATE_RELATIVE_OPTION },
              })
            }
            options={DATE_RELATIVE_OPTIONS}
            selectedItem={selectedRelativeTime}
            usePortal={usePortal}
          />
        </div>
      </div>
    );
  }

  return (
    <RenderedInput
      fillWidth
      showInputButton
      defaultValue={inputValue}
      errorText={error}
      onSubmit={(newValue) => {
        setInputValue(newValue);
        if (FILTER_OPS_MULTISELECT.has(filterOperator)) {
          try {
            const valuesArr = JSON.parse(newValue);
            if (!Array.isArray(valuesArr)) {
              return setError('Must be valid javascript array, e.g. ["a", "b"]');
            }
            if (valuesArr.length === 0) {
              return setError('Array must not be empty.');
            }
          } catch {
            return setError('Must be valid javascript array, e.g. ["a", "b"].');
          }
        } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
          const numValue = parseFloat(newValue);
          if (isNaN(numValue) || String(numValue) !== newValue) {
            return setError('Must be a valid number');
          }
        }
        setError('');
        onFilterValueUpdate(newValue);
      }}
    />
  );
};
