import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Switch, Input } from 'components/ds';
import { ScatterPlotFormat, V2ScatterPlotInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  instructions: V2ScatterPlotInstructions;
};

export const ScatterPlotConfig: FC<Props> = ({ instructions }) => {
  const { scatterPlotFormat } = instructions;
  const dispatch = useDispatch();

  const updateInstructions = (newInstructions: V2ScatterPlotInstructions) =>
    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2));

  const updateScatterPlotFormat = (scatterPlotFormat: ScatterPlotFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.scatterPlotFormat = {
        ...draft.scatterPlotFormat,
        ...scatterPlotFormat,
      };
    });

    updateInstructions(newInstructions);
  };

  const { yAxisColumn, xAxisColumn } = instructions;

  return (
    <div className={configRootClass}>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(scatterPlotFormat?.radius ?? 4)}
        label="Marker Radius"
        onSubmit={(newValue) => {
          const newInt = parseInt(newValue);
          updateScatterPlotFormat({ radius: newInt > 0 ? newInt : undefined });
        }}
      />
      {xAxisColumn ? (
        <Input
          className={configInputClass}
          defaultValue={xAxisColumn.friendly_name || xAxisColumn.name}
          label="X-Axis Label"
          onSubmit={(newValue) => {
            if (!newValue.trim()) return;
            const newInstructions = produce(instructions, (draft) => {
              if (!draft.xAxisColumn) return;
              draft.xAxisColumn.friendly_name = newValue;
            });

            updateInstructions(newInstructions);
          }}
        />
      ) : null}
      {yAxisColumn ? (
        <Input
          className={configInputClass}
          defaultValue={yAxisColumn.friendly_name || yAxisColumn.name}
          label="Y-Axis Label"
          onSubmit={(newValue) => {
            if (!newValue.trim()) return;
            const newInstructions = produce(instructions, (draft) => {
              if (!draft.yAxisColumn) return;
              draft.yAxisColumn.friendly_name = newValue;
            });

            updateInstructions(newInstructions);
          }}
        />
      ) : null}
      {!instructions.groupingColumn ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={scatterPlotFormat?.seriesLabel ?? 'Series 1'}
          label="Label"
          onSubmit={(newValue) => updateScatterPlotFormat({ seriesLabel: newValue })}
        />
      ) : undefined}
      <Switch
        className={configInputClass}
        label="Use Jitter to Spread Points"
        onChange={() => updateScatterPlotFormat({ useJitter: !scatterPlotFormat?.useJitter })}
        switchOn={scatterPlotFormat?.useJitter}
      />
    </div>
  );
};
