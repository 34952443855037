import { produce } from 'immer';
import { FC, useCallback } from 'react';
import { Updater } from 'use-immer';

import { CustomerPermissionsSetter } from 'components/CustomerPermissions/Setter';
import { ConfigSection } from 'components/resource/ConfigSection';
import * as sharedStyles from 'components/resource/index.css';
import {
  initCustomerPermissionsForObject,
  CustomerPermissionsForObject,
} from 'types/permissionTypes';

type ValueParams = {
  permissions: CustomerPermissionsForObject;
};

type Props = {
  initialPermissions?: CustomerPermissionsForObject;
  handleNewValueSubmitted: (value: ValueParams) => void;
};

export const PermissionConfig: FC<Props> = ({ initialPermissions, handleNewValueSubmitted }) => {
  const handlePermissions: Updater<CustomerPermissionsForObject> = useCallback(
    (arg) => {
      const permissions =
        typeof arg === 'function' ? produce(initialPermissions || emptyPermissions, arg) : arg;
      handleNewValueSubmitted({ permissions });
    },
    [handleNewValueSubmitted, initialPermissions],
  );

  return (
    <ConfigSection title="Permissions">
      <div className={sharedStyles.configSection}>
        <CustomerPermissionsSetter
          permissions={initialPermissions || emptyPermissions}
          setPermissions={handlePermissions}
        />
      </div>
    </ConfigSection>
  );
};

const emptyPermissions = initCustomerPermissionsForObject();
