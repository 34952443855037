import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BAR_CHART_TYPES, OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Switch, sprinkles } from 'components/ds';
import { ChartSpecificFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const MissingDataConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const enableFillMissingDates = useSelector(
    (state: ReduxState) => state.currentUser.team?.feature_flags.enable_fill_missing_date_range,
  );

  const isBarChart = BAR_CHART_TYPES.has(visualizationType);
  if (isBarChart && !enableFillMissingDates) return null;

  const { chartSpecificFormat } = instructions;
  const { timeSeriesDataFormat } = chartSpecificFormat ?? {};

  const updateChartSpecificFormat = (specificUpdates: ChartSpecificFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        ...specificUpdates,
      };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sprinkles({ paddingY: 'sp1.5', flexItems: 'column', gap: 'sp1.5' })}>
      <Switch
        label="Fill in missing dates with 0s"
        onChange={() => {
          const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
            draft.timeSeriesDataFormat = {
              ...draft.timeSeriesDataFormat,
              zeroMissingDates: !timeSeriesDataFormat?.zeroMissingDates,
            };
          });
          updateChartSpecificFormat(newChartSpecificFormat);
        }}
        switchOn={timeSeriesDataFormat?.zeroMissingDates}
      />
      {
        // TODO(SHIBA-5659): Implement this feature for bar charts
        isBarChart ? null : (
          <Switch
            label="Fill in missing values with 0s"
            onChange={() => {
              const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
                draft.timeSeriesDataFormat = {
                  ...draft.timeSeriesDataFormat,
                  zeroMissingValues: !timeSeriesDataFormat?.zeroMissingValues,
                };
              });
              updateChartSpecificFormat(newChartSpecificFormat);
            }}
            switchOn={timeSeriesDataFormat?.zeroMissingValues}
          />
        )
      }
    </div>
  );
};
