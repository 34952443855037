import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { AlertModal } from 'components/ds';
import {
  getDashboardElementsPartitionedBySection,
  getDataPanelsPartitionedBySection,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { deleteDatasetThunk } from 'reducers/thunks/dashboardDataThunks/modifyDatasetThunks';
import { getDataPanelsUsingDataset, getElementsUsingDataset } from 'utils/datasetUtils';
import { getDatasetName } from 'utils/naming';
import { BlockDatasetDeletionModal } from './BlockDatasetDeletionModal';

type Props = {
  onClose: () => void;
  datasetConfigs: Record<string, Dataset>;
  deleteDatasetId: string | null;
};

export const DeleteDatasetModal: FC<Props> = ({ datasetConfigs, deleteDatasetId, onClose }) => {
  const dispatch = useDispatch();

  const { partitionedDataPanels, partitionedElements } = useSelector(
    (state: ReduxState) => ({
      partitionedDataPanels: getDataPanelsPartitionedBySection(state.dashboardEditConfig),
      partitionedElements: getDashboardElementsPartitionedBySection(state.dashboardEditConfig),
    }),
    shallowEqual,
  );

  const { mainDataPanels, editableSectionDataPanels } = partitionedDataPanels;
  const { mainElements, stickyHeaderElements } = partitionedElements;

  const {
    dataPanelsInUse,
    elementsInUse,
    editableSectionDataPanelsInUse,
    stickyHeaderElementsInUse,
  } = useMemo(() => {
    if (!deleteDatasetId)
      return {
        dataPanelsInUse: [],
        elementsInUse: [],
        editableSectionDataPanelsInUse: [],
        stickyHeaderElementsInUse: [],
      };

    return {
      dataPanelsInUse: getDataPanelsUsingDataset(mainDataPanels, deleteDatasetId),
      elementsInUse: getElementsUsingDataset(mainElements, deleteDatasetId),
      editableSectionDataPanelsInUse: getDataPanelsUsingDataset(
        editableSectionDataPanels,
        deleteDatasetId,
      ),
      stickyHeaderElementsInUse: getElementsUsingDataset(stickyHeaderElements, deleteDatasetId),
    };
  }, [
    deleteDatasetId,
    mainElements,
    mainDataPanels,
    editableSectionDataPanels,
    stickyHeaderElements,
  ]);

  const datasetToDelete = deleteDatasetId && datasetConfigs[deleteDatasetId];

  if (!datasetToDelete) return null;

  if (
    dataPanelsInUse.length ||
    elementsInUse.length ||
    editableSectionDataPanelsInUse.length ||
    stickyHeaderElementsInUse.length
  ) {
    return (
      <BlockDatasetDeletionModal
        dataPanelProvidedIds={dataPanelsInUse}
        editableSectionDataPanelProvidedIds={editableSectionDataPanelsInUse}
        elementNames={elementsInUse}
        onClose={onClose}
        stickyHeaderElementNames={stickyHeaderElementsInUse}
      />
    );
  }

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        text: `Delete ${getDatasetName(datasetToDelete)}`,
        onClick: () => {
          dispatch(deleteDatasetThunk(datasetToDelete.id));
        },
      }}
      onClose={onClose}
      title="Are you sure you want to delete this dataset?"
    />
  );
};
