import produce from 'immer';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES, DatasetSchema, ChartAggregation } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import {
  AggedChartColumnInfo,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
} from 'constants/types';
import { getDefaultAgg } from 'utils/aggUtils';

import { v4 as uuid } from 'uuid';
import DroppableColumnSection from './vizConfigs/droppable/DroppableColumnSection';

type Props = {
  instructions: V2KPITrendInstructions | V2KPIChartInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function KPIAggregation({ instructions, chartType, loading, schema }: Props) {
  const dispatch = useDispatch();

  const aggIsCustomFormula = instructions.aggColumn?.agg.id === ChartAggregation.FORMULA;

  const updateAggCol = (
    updateFunc: (curr: AggedChartColumnInfo | undefined) => AggedChartColumnInfo | undefined,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.aggColumn = updateFunc(draft.aggColumn);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <>
      <SettingHeader
        btnProps={{
          icon: aggIsCustomFormula ? 'table' : 'function',
          tooltipText: aggIsCustomFormula
            ? 'Aggregate fields on the table'
            : 'Custom aggregation formula',
          onClick: () =>
            updateAggCol(() =>
              aggIsCustomFormula
                ? undefined
                : { column: {}, agg: { id: ChartAggregation.FORMULA } },
            ),
        }}
        name="Aggregation"
      />
      <DroppableColumnSection
        required
        columns={instructions.aggColumn ? [instructions.aggColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => updateAggCol(() => ({ column: col, agg: getDefaultAgg(col.type) }))}
        onColOptionChanged={(option) => {
          if (aggIsCustomFormula) {
            updateAggCol(() => ({
              column: { name: `custom_formula_${uuid()}` },
              agg: { id: ChartAggregation.FORMULA, formula: option?.formula },
            }));
          } else {
            updateAggCol((aggCol) => {
              if (aggCol) aggCol.agg = { id: option.id as ChartAggregation };
              return aggCol;
            });
          }
        }}
        onRemoveCol={() => updateAggCol(() => undefined)}
        schema={schema}
      />
    </>
  );
}
