import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import {
  FetchDashboardVersionsForPreviewResponse,
  fetchDashboardVersionsForPreview,
} from 'actions/dashboardV2Actions';
import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Select, sprinkles } from 'components/ds';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEmbeddedDashboardWithDrilldowns } from 'reducers/selectors';
import * as RD from 'remotedata';
import { VIEW_MODE } from 'types/dashboardTypes';
import { orderBy } from 'utils/standard';

import * as styles from './index.css';

type MatchParams = {
  embedId: string;
};

export const DashboardPreviewPage: FC = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch<MatchParams>();

  const [versionsForPreview, setVersionsForPreview] = useState<
    RD.ResponseData<FetchDashboardVersionsForPreviewResponse>
  >(RD.Loading());
  const [dashboardName, setDashboardName] = useState('');
  const [versionNumber, setVersionNumber] = useState<number>();

  const { dashboard, customer } = useSelector(
    (state: ReduxState) => ({
      dashboard: getEmbeddedDashboardWithDrilldowns(state),
      customer: getSelectedCustomer(state.customers),
    }),
    shallowEqual,
  );

  const dashboardId = RD.isSuccess(dashboard) ? dashboard.data.id : null;

  const versionOptions = useMemo(() => {
    if (!RD.isSuccess(versionsForPreview) || !dashboardId) return [];
    const { tags, versions } = versionsForPreview.data;

    return orderBy(versions, 'version_number', 'desc').map((version) => {
      const versionNumber = String(version.version_number);
      let name = versionNumber;
      if (version.is_draft) name += ' (draft)';
      else {
        const tag = tags.find(
          (tag) => tag.dashboard_versions_by_dashboard?.[dashboardId] === version.id,
        );
        if (tag) name += ` (${tag.name})`;
      }
      return { value: versionNumber, label: name };
    });
  }, [versionsForPreview, dashboardId]);

  // Do this so when dashboard is re-fetched on customer change, name doesn't flicker
  useEffect(() => {
    if (dashboardName || !RD.isSuccess(dashboard)) return;
    setDashboardName(dashboard.data.name);
  }, [dashboardName, dashboard]);

  useEffect(() => {
    dispatch(
      fetchDashboardVersionsForPreview(
        { id: params.embedId },
        (data) => setVersionsForPreview(RD.Success(data)),
        () => setVersionsForPreview(RD.Error('Error loading versions')),
      ),
    );
  }, [dispatch, params.embedId]);

  return (
    <div className={sprinkles({ parentContainer: 'fillViewport', flexItems: 'column' })}>
      <div className={styles.previewBanner}>
        <div className={styles.customerSelectorContainer}>
          <ViewingAsCustomerSelector className={sprinkles({ width: 'fill' })} />
        </div>
        <div className={styles.titleContainer}>
          {dashboardName ? (
            <>
              This is a preview of&nbsp;<b>{dashboardName}</b>. Changes will not be saved
            </>
          ) : (
            'This is a preview. Changes will not be saved.'
          )}
        </div>
        <div className={styles.customerSelectorContainer}>
          <span>Version</span>
          <Select
            className={sprinkles({ flex: 1, marginLeft: 'sp1', color: 'contentPrimary' })}
            onChange={(value) => setVersionNumber(parseInt(value))}
            selectedValue={versionNumber ? versionNumber.toString() : versionOptions[0]?.value}
            values={versionOptions}
          />
        </div>
      </div>
      <div className={sprinkles({ flex: 1, overflowY: 'auto' })}>
        {customer ? (
          <EmbeddedDashboard
            updateUrlParams
            customerToken={customer.token}
            dashboardEmbedId={params.embedId}
            embedType={EmbeddedDashboardType.PREVIEW}
            versionNumber={versionNumber}
            viewMode={VIEW_MODE.DEFAULT}
          />
        ) : null}
      </div>
    </div>
  );
};
