import { ChartAggregation } from './instructions/aggregations';

export const INTEGER_DATA_TYPE = 'INTEGER';
export const UNSIGNED_INTEGER = 'unsigned integer';
export const FLOAT = 'FLOAT';
export const STRING = 'STRING';
export const BOOLEAN = 'BOOLEAN';
export const DATETIME = 'DATETIME';
export const DATE = 'DATE';
export const TIMESTAMP = 'TIMESTAMP';
export const MENU = 'MENU'; // used for showing the row-level action menu in a column
export const DOUBLE = 'DOUBLE';

export type SchemaDataType =
  | typeof INTEGER_DATA_TYPE
  | typeof UNSIGNED_INTEGER
  | typeof FLOAT
  | typeof STRING
  | typeof BOOLEAN
  | typeof DATETIME
  | typeof DATE
  | typeof TIMESTAMP
  | typeof DOUBLE;

export const INTEGER_TYPES = new Set([INTEGER_DATA_TYPE, UNSIGNED_INTEGER]);
export const DECIMAL_TYPES = new Set([FLOAT, DOUBLE]);

export const NUMBER_TYPES = new Set([INTEGER_DATA_TYPE, UNSIGNED_INTEGER, FLOAT, DOUBLE]);
export const TIME_COLUMN_TYPES = new Set([DATE, DATETIME, TIMESTAMP]);
export const DATE_TYPES = new Set([TIMESTAMP, DATETIME, DATE]);

// Keep in sync with agg_to_name
export const AGGREGATIONS_TYPES: Record<ChartAggregation, { id: ChartAggregation; name: string }> =
  {
    COUNT: {
      id: ChartAggregation.COUNT,
      name: 'Count',
    },
    COUNT_DISTINCT: {
      id: ChartAggregation.COUNT_DISTINCT,
      name: 'Count Distinct',
    },
    AVG: {
      id: ChartAggregation.AVG,
      name: 'Avg',
    },
    SUM: {
      id: ChartAggregation.SUM,
      name: 'Sum',
    },
    MIN: {
      id: ChartAggregation.MIN,
      name: 'Min',
    },
    MAX: {
      id: ChartAggregation.MAX,
      name: 'Max',
    },
    '25_PERCENTILE': {
      id: ChartAggregation['25_PERCENTILE'],
      name: '25th Percentile',
    },
    MEDIAN: {
      id: ChartAggregation.MEDIAN,
      name: 'Median',
    },
    '75_PERCENTILE': {
      id: ChartAggregation['75_PERCENTILE'],
      name: '75th Percentile',
    },
    FORMULA: {
      id: ChartAggregation.FORMULA,
      name: 'Formula',
    },
    FIRST: {
      id: ChartAggregation.FIRST,
      name: 'First',
    },
  };

export const TIME_DIFF_FORMATS = {
  STANDARD: {
    id: 'STANDARD',
    name: 'Standard',
  },
  ABBREVIATION: {
    id: 'ABBREVATION',
    name: 'Abbreviated',
  },
  CUSTOM: {
    id: 'CUSTOME',
    name: 'Custom time format',
  },
  CUSTOM_DURATION: {
    id: 'CUSTOM_DURATION',
    name: 'Custom total duration',
  },
};

export enum OPERATION_TYPES {
  FILTER = 'FILTER',
  GROUP_BY = 'GROUP_BY',

  // KPIs
  VISUALIZE_NUMBER_V2 = 'VISUALIZE_NUMBER_V2',
  VISUALIZE_PROGRESS_V2 = 'VISUALIZE_PROGRESS_V2',
  VISUALIZE_NUMBER_TREND_V2 = 'VISUALIZE_NUMBER_TREND_V2',
  VISUALIZE_NUMBER_TREND_TEXT_PANEL = 'VISUALIZE_NUMBER_TREND_TEXT_PANEL',

  // Special Charts
  VISUALIZE_HEAT_MAP_V2 = 'VISUALIZE_HEAT_MAP_V2',
  VISUALIZE_SCATTER_PLOT_V2 = 'VISUALIZE_SCATTER_PLOT_V2',
  VISUALIZE_SPIDER_CHART = 'VISUALIZE_SPIDER_CHART',
  VISUALIZE_BOX_PLOT_V2 = 'VISUALIZE_BOX_PLOT_V2',
  VISUALIZE_CHOROPLETH_MAP = 'VISUALIZE_CHOROPLETH_MAP',
  VISUALIZE_SANKEY_CHART = 'VISUALIZE_SANKEY_CHART',
  VISUALIZE_LOCATION_MARKER_MAP = 'VISUALIZE_LOCATION_MARKER_MAP',
  VISUALIZE_CALENDAR_HEATMAP = 'VISUALIZE_CALENDAR_HEATMAP',
  VISUALIZE_DENSITY_MAP = 'VISUALIZE_DENSITY_MAP',

  // Bar Charts
  VISUALIZE_VERTICAL_BAR_V2 = 'VISUALIZE_VERTICAL_BAR_V2',
  VISUALIZE_VERTICAL_100_BAR_V2 = 'VISUALIZE_VERTICAL_100_BAR_V2',
  VISUALIZE_VERTICAL_GROUPED_BAR_V2 = 'VISUALIZE_VERTICAL_GROUPED_BAR_V2',
  VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 = 'VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2',

  VISUALIZE_HORIZONTAL_BAR_V2 = 'VISUALIZE_HORIZONTAL_BAR_V2',
  VISUALIZE_HORIZONTAL_100_BAR_V2 = 'VISUALIZE_HORIZONTAL_100_BAR_V2',
  VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 = 'VISUALIZE_HORIZONTAL_GROUPED_BAR_V2',
  VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2 = 'VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2',

  // Line Charts
  VISUALIZE_LINE_CHART_V2 = 'VISUALIZE_LINE_CHART_V2',
  VISUALIZE_COMBO_CHART_V2 = 'VISUALIZE_COMBO_CHART_V2',

  // Area Charts
  VISUALIZE_AREA_CHART_V2 = 'VISUALIZE_AREA_CHART_V2',
  VISUALIZE_AREA_100_CHART_V2 = 'VISUALIZE_AREA_100_CHART_V2',

  // Pie Charts
  VISUALIZE_PIE_CHART_V2 = 'VISUALIZE_PIE_CHART_V2',
  VISUALIZE_DONUT_CHART_V2 = 'VISUALIZE_DONUT_CHART_V2',

  // Funnel Charts
  VISUALIZE_FUNNEL_V2 = 'VISUALIZE_FUNNEL_V2',
  VISUALIZE_VERTICAL_BAR_FUNNEL_V2 = 'VISUALIZE_VERTICAL_BAR_FUNNEL_V2',

  // Tables
  VISUALIZE_TABLE = 'VISUALIZE_TABLE',
  VISUALIZE_REPORT_BUILDER = 'VISUALIZE_REPORT_BUILDER',
  VISUALIZE_PIVOT_TABLE = 'VISUALIZE_PIVOT_TABLE',
  VISUALIZE_PIVOT_REPORT_BUILDER = 'VISUALIZE_PIVOT_REPORT_BUILDER',
  VISUALIZE_COLLAPSIBLE_LIST = 'VISUALIZE_COLLAPSIBLE_LIST',
  VISUALIZE_PIVOT_TABLE_V2 = 'VISUALIZE_PIVOT_TABLE_V2',
}

// embeddo uses this as the default in its backend if no formula is actually configured
export const DEFAULT_CUSTOM_FORMULA = 'count(*)';

export enum Timezones {
  USER_LOCAL_TIME = 'USER_LOCAL_TIME',
  UTC = 'UTC',
}
export const UNDERLYING_DATA_DATA_PANEL_ID = '_drilldown_data_panel';

const NON_CHART_OPERATIONS = new Set([
  OPERATION_TYPES.FILTER,
  OPERATION_TYPES.GROUP_BY,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

// Tables that display a row count
export const ROW_COUNT_OPERATIONS_SET = new Set([
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST,
]);

// All tables
export const VISUALIZE_TABLE_OPERATIONS_SET = new Set([
  ...ROW_COUNT_OPERATIONS_SET,
  OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2,
]);

// This is the list of all charts not including tables
export const CHART_VISUALIZATION_OPERATIONS = Object.values(OPERATION_TYPES).filter(
  (val) => !NON_CHART_OPERATIONS.has(val) && !VISUALIZE_TABLE_OPERATIONS_SET.has(val),
);

export const CHART_VISUALIZATION_OPERATIONS_SET = new Set(CHART_VISUALIZATION_OPERATIONS);

export const V2_CHART_GOAL_LINE_OPERATIONS = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_SPIDER_CHART,
]);

export const VISUALIZATION_OPERATIONS = Object.values(OPERATION_TYPES).filter(
  (val) => !NON_CHART_OPERATIONS.has(val),
);

export const V2_COLOR_ZONE_OPERATIONS = new Set([
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
]);

export const HEAT_MAP_COLOR_ZONE_OPERATIONS = new Set([
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP,
]);

export const COLOR_CATEGORY_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
]);

export const NO_HEADER_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_PROGRESS_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

export const REPORT_BUILDER_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

export const BAR_CHART_TYPES: Set<OPERATION_TYPES> = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const AREA_CHART_TYPES: Set<OPERATION_TYPES> = new Set([
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
]);

export const LINE_CHART_TYPES: Set<OPERATION_TYPES> = new Set([
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
]);

export const PIE_CHART_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
]);

export const GROUPED_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
]);

export const HORIZONTAL_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const GROUPED_STACKED_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const COLOR_SYNC_CHART_TYPES = new Set([
  // TODO: Support Categorical colors for Pivot tables
  // OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_SPIDER_CHART,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
]);

export const KPI_NUMBER_TREND_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL,
]);
