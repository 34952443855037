import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FC } from 'react';

import { sprinkles } from '@explo/design-system';

export const EmbeddedDropdownMenuLabel: FC = ({ children }) => (
  <DropdownMenu.Label className={labelStyle}>{children}</DropdownMenu.Label>
);

const labelStyle = sprinkles({
  heading: 'subtitle',
  color: 'contentSecondary',
  paddingX: 'sp2',
  paddingTop: 'sp1',
  paddingBottom: 'sp.5',
});
