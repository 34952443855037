import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextFieldModal } from 'components/modals/textFieldModal';
import {
  getEditPageDataPanels,
  updateDataPanelProvidedId,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

type Props = { dataPanel: DataPanelTemplate; onClose: () => void };

export const ChangeDataPanelIdModal: FC<Props> = ({ dataPanel, onClose }) => {
  const dispatch = useDispatch();

  const dataPanels = useSelector((state: ReduxState) =>
    getEditPageDataPanels(state.dashboardEditConfig, true),
  );

  const existingProvidedIds = useMemo(
    () => new Set(dataPanels.map((dp) => dp.provided_id)),
    [dataPanels],
  );

  return (
    <TextFieldModal
      modalOpen
      buttonName="Save"
      closeModal={onClose}
      getErrorMessage={(dataPanelId) => {
        const trimmedDataPanelId = dataPanelId?.trim();
        if (!trimmedDataPanelId) return 'ID cannot be empty';
        if (dataPanelId === dataPanel.provided_id) return;
        if (existingProvidedIds.has(trimmedDataPanelId)) return 'Data Panel IDs must be unique';
      }}
      modalTitle="Data Panel ID"
      onSubmit={(newId) => {
        onClose();
        dispatch(updateDataPanelProvidedId(newId));
        trackEvent(EVENTS.RENAMED_DATA_PANEL, { data_panel_template_id: dataPanel.id });
      }}
      resourceName={dataPanel.provided_id}
      textFieldPlaceholder="Enter an ID"
    />
  );
};
