import { FC, useEffect, useMemo, useRef } from 'react';
import { MapRef, ViewState } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';

import { DatasetRow, DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { VisualizeOperationInstructions } from 'constants/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DashboardStates } from 'reducers/rootReducer';
import { updateMapViewConfig } from 'reducers/thunks/mapThunks';
import { INTERNAL_EVENT } from 'types/customEventTypes';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { isDataPanelConfigReady } from 'utils/dataPanelConfigUtils';
import { debounce } from 'utils/standard';

import 'mapbox-gl/dist/mapbox-gl.css';
import { ChoroplethMap } from '../Maps/ChoroplethMap';
import { DensityMap } from '../Maps/DensityMap/DensityMap';
import { LocationMarkerMap } from '../Maps/LocationMarkerMap';

type Props = {
  data: DatasetRow[] | undefined;
  dpId: string;
  instructions: VisualizeOperationInstructions;
  loading: boolean;
  schema: DatasetSchema;
  operationType:
    | OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP
    | OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP
    | OPERATION_TYPES.VISUALIZE_DENSITY_MAP;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  isScreenshotDownload?: boolean;
  variables: DashboardVariableMap;
};

export const MapboxChart: FC<Props> = ({
  isScreenshotDownload,
  operationType,
  data,
  dpId,
  instructions,
  loading,
  schema,
  datasetData,
  datasetNamesToId,
  variables,
}) => {
  const dispatch = useDispatch();

  const isSelected = useSelector(
    (state: DashboardStates) => state.dashboardInteractions.selectedItem?.id === dpId,
  );

  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    const handleCustomEvent = () => {
      if (!isSelected || !mapRef.current) return;

      const { lng, lat } = mapRef.current.getCenter();
      const view: ViewState = {
        longitude: lng,
        latitude: lat,
        zoom: mapRef.current.getZoom(),
        bearing: mapRef.current.getBearing(),
        padding: mapRef.current.getPadding(),
        pitch: mapRef.current.getPitch(),
      };

      dispatch(updateMapViewConfig(operationType, instructions, view));
    };

    window.addEventListener(INTERNAL_EVENT.SAVE_MAP_VIEW_STATE, handleCustomEvent as EventListener);

    return () => {
      window.removeEventListener(
        INTERNAL_EVENT.SAVE_MAP_VIEW_STATE,
        handleCustomEvent as EventListener,
      );
    };
  }, [dispatch, isSelected, dpId, operationType, instructions]);

  const instructionsReadyToDisplay = isDataPanelConfigReady({
    instructions,
    operation_type: operationType,
  });

  // Force map to resize when container changes size
  const debouncedMapResize = useMemo(() => debounce(() => mapRef.current?.resize(), 300), []);

  if (loading || !instructionsReadyToDisplay) {
    return (
      <NeedsConfigurationPanel
        fullHeight
        instructionsNeedConfiguration={!instructionsReadyToDisplay}
        loading={loading}
      />
    );
  }

  const mapComponent = (() => {
    switch (operationType) {
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
        return (
          <LocationMarkerMap
            data={data ?? []}
            debouncedMapResize={debouncedMapResize}
            instructions={instructions.VISUALIZE_GEOSPATIAL_CHART || {}}
            mapRef={mapRef}
          />
        );
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
        return (
          <ChoroplethMap
            data={data ?? []}
            debouncedMapResize={debouncedMapResize}
            instructions={instructions.V2_TWO_DIMENSION_CHART || {}}
            mapRef={mapRef}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
        return (
          <DensityMap
            data={data ?? []}
            dataPanelId={dpId}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            debouncedMapResize={debouncedMapResize}
            instructions={instructions.VISUALIZE_GEOSPATIAL_CHART || {}}
            mapRef={mapRef}
            variables={variables}
          />
        );
      default:
        return null;
    }
  })();

  if (!mapComponent || !isScreenshotDownload) return mapComponent;

  // For screenshot download, we need to force a height on the map component to avoid the map height from being 0
  return <div style={{ height: '100vh' }}>{mapComponent}</div>;
};
