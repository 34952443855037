import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { OPERATION_TYPES } from '@explo/data';

import {
  CombinedVisualizeInstructions,
  updateVisualizeOperationAction,
} from 'actions/dataPanelConfigActions';
import { ReduxState } from 'reducers/rootReducer';

/**
 * Automatically uses produce so you pretend to mutate the instructions without affecting the original
 *
 * @param operationType
 * @param instructions
 * @param recipe
 */
export const updateOperationConfigThunk =
  <T extends CombinedVisualizeInstructions>(
    operationType: OPERATION_TYPES,
    instructions: T,
    recipe: (draft: T) => T | void,
  ): Thunk =>
  (dispatch) => {
    const newInstructions = produce(instructions, recipe);
    dispatch(
      updateVisualizeOperationAction({ visualizeInstructions: newInstructions, operationType }),
    );
  };

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;
