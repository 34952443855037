import { DatasetRow, SchemaDisplayOption } from '@explo/data';
import { DatasetDataObject } from 'actions/datasetActions';
import { VisualizeOperationInstructions } from 'constants/types';

// This file is entirely deprecated (and will be removed when we remove all the join stuff)

export type SchemaDisplayOptionWithTableJoinConfig = SchemaDisplayOption & TableJoinColumnConfig;

export type TableJoinColumnConfig = {
  joinOn?: boolean;
  joinTable?: { id: string };
  joinColumn?: { name: string };
  joinDisplayColumn?: { name: string; column: { type: string } };
};

export const getColTypeForJoinTable = (
  colConfig: SchemaDisplayOptionWithTableJoinConfig,
  joinMapping: Record<string, Record<string, Record<string | number, string | number>>>,
  datasetData: DatasetDataObject,
  rows: DatasetRow[],
  rowIndex: number,
) => {
  let cellData: string | number;
  let colType: string;

  if (isJoinConfigReady(colConfig) && colConfig.joinDisplayColumn && colConfig.joinTable?.id) {
    const datasetId = colConfig.joinTable.id;
    const joinColName = colConfig.joinDisplayColumn.name;
    if (!joinMapping[datasetId]?.[joinColName]) {
      if (!joinMapping[datasetId]) joinMapping[datasetId] = {};
      if (!joinMapping[datasetId][joinColName]) joinMapping[datasetId][joinColName] = {};

      datasetData[datasetId]?.rows?.forEach((row) => {
        if (!colConfig.joinDisplayColumn || !colConfig.joinColumn) return;
        joinMapping[datasetId][joinColName][row[colConfig.joinColumn.name]] = row[joinColName];
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const joinColumnCellData = rows[rowIndex][colConfig.joinColumn!.name];
    cellData =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      joinMapping[colConfig.joinTable.id][colConfig.joinDisplayColumn!.name][joinColumnCellData] ??
      '';

    colType = colConfig.joinDisplayColumn.column.type;

    return { cellData, colType };
  }
};

export const isJoinConfigReady = (colConfig: SchemaDisplayOptionWithTableJoinConfig) => {
  return colConfig.joinOn && colConfig.joinColumn && colConfig.joinDisplayColumn;
};

export const addDatasetIdsForJoinedTables = (
  instructions: VisualizeOperationInstructions,
  datasetIds: Set<string>,
) => {
  Object.values(instructions.VISUALIZE_TABLE.schemaDisplayOptions || {}).forEach((colConfig) => {
    const config: SchemaDisplayOptionWithTableJoinConfig = colConfig;

    if (!(isJoinConfigReady(colConfig) && config.joinTable?.id)) return;

    datasetIds.add(config.joinTable.id);
  });
};
