import { createReducer } from '@reduxjs/toolkit';

import { connectDataSourceSuccess, editDataSourceSuccess } from 'actions/dataSourceActions';
import {
  assignPendingInviteRoleSuccess,
  assignUserRoleSuccess,
  createAccessGroupSuccess,
  createApiTokenSuccess,
  deleteAccessGroupSuccess,
  deleteApiTokenSuccess,
  fetchAccessGroupsSuccess,
  refreshApiTokenSuccess,
  updateAccessGroupsSuccess,
  updateApiTokenSuccess,
} from 'actions/rolePermissionActions';
import {
  fetchUserTeamRequest,
  fetchUserTeamSuccess,
  cancelTeamInviteSuccess,
  saveDefaultCurrencyCodeSuccess,
  saveAnalyticsConfigSuccess,
  saveShareMetadataSuccess,
  saveUseBrowserLocaleSuccess,
  saveDefaultLocaleCodeSuccess,
  Team,
  uploadSupportEmailSuccess,
  removeTeammateSuccess,
  InvitedUser,
  inviteTeammatesSuccess,
  saveCustomMapBoxTokenSuccess,
} from 'actions/teamActions';
import { updateUserNameSuccess } from 'actions/userActions';

interface TeamDataReducerState {
  loading: boolean;
  data?: Team;
  invitedUsers: InvitedUser[];
}

const teamDataReducerInitialState: TeamDataReducerState = {
  loading: false,
  invitedUsers: [],
};

export default createReducer(teamDataReducerInitialState, (builder) => {
  builder
    .addCase(fetchUserTeamRequest, (state) => {
      state.loading = true;
    })
    .addCase(fetchUserTeamSuccess, (state, { payload }) => {
      state.loading = false;
      state.data = payload.team;
      state.invitedUsers = payload.invited_users;
    })
    .addCase(inviteTeammatesSuccess, (state, { payload }) => {
      payload.invited_users.forEach((invite) => {
        if (!state.invitedUsers.some((user) => user.email === invite.email))
          state.invitedUsers.push(invite);
      });
    })
    .addCase(cancelTeamInviteSuccess, (state, { payload }) => {
      const inviteIdx = state.invitedUsers.findIndex(
        (user) => user.email === payload.postData.email,
      );
      if (inviteIdx >= 0) state.invitedUsers.splice(inviteIdx, 1);
    })
    .addCase(updateUserNameSuccess, (state, { payload }) => {
      const user = state.data?.team_members.find((member) => member.id === payload.postData.id);
      if (user) {
        user.first_name = payload.first_name;
        user.last_name = payload.last_name;
      }
    })
    .addCase(saveAnalyticsConfigSuccess, (state, { payload }) => {
      if (!state.data) return;

      state.data.analytics_reporting_actions = payload.analytics_reporting_actions;
      state.data.analytics_reporting_url = payload.analytics_reporting_url;
      state.data.analytics_reporting_token = payload.analytics_reporting_token;
      state.data.should_send_js_analytics_events = payload.should_send_js_analytics_events;
    })
    .addCase(saveDefaultCurrencyCodeSuccess, (state, { payload }) => {
      if (state.data) {
        state.data.default_currency_code = payload.currency_code;
      }
    })
    .addCase(saveDefaultLocaleCodeSuccess, (state, { payload }) => {
      if (state.data) {
        state.data.default_locale_code = payload.locale_code;
      }
    })
    .addCase(saveUseBrowserLocaleSuccess, (state, { payload }) => {
      if (state.data) state.data.use_browser_locale = payload.setting;
    })
    .addCase(saveCustomMapBoxTokenSuccess, (state, { payload }) => {
      if (state.data) state.data.custom_map_box_token = payload.token;
    })
    .addCase(saveShareMetadataSuccess, (state, { payload }) => {
      if (!state.data) return;

      state.data = {
        ...state.data,
        ...payload.postData,
      };
    })
    .addCase(refreshApiTokenSuccess, (state, { payload }) => {
      if (!state.data) return;

      const accessGroup = state.data.access_groups.find(
        (group) => group.id === payload.new_token.access_group_id,
      );

      if (!accessGroup) return;

      const token = accessGroup.api_tokens.find((token) => token.id === payload.new_token.id);
      if (token) token.value = payload.new_token.value;
    })
    .addCase(createApiTokenSuccess, (state, { payload }) => {
      if (!state.data) return;

      const accessGroup = state.data.access_groups.find(
        (group) => group.id === payload.new_token.access_group_id,
      );
      if (!accessGroup) return;

      accessGroup.api_tokens.push(payload.new_token);
    })
    .addCase(deleteApiTokenSuccess, (state, { payload }) => {
      if (!state.data) return;
      const accessGroup = state.data.access_groups.find((group) =>
        group.api_tokens.some((token) => token.id === payload.token_id),
      );
      if (!accessGroup) return;

      const index = accessGroup.api_tokens.findIndex((token) => token.id === payload.token_id);

      if (index >= 0) accessGroup.api_tokens.splice(index, 1);
    })
    .addCase(updateApiTokenSuccess, (state, { payload }) => {
      if (!state.data) return;

      const accessGroup = state.data.access_groups.find(
        (group) => group.id === payload.new_token.access_group_id,
      );

      if (!accessGroup) return;

      const token = accessGroup.api_tokens.find((token) => token.id === payload.new_token.id);

      if (token) {
        token.access_group_id = payload.new_token.access_group_id;
        token.is_read_only = payload.new_token.is_read_only;
      }
    })
    .addCase(fetchAccessGroupsSuccess, (state, { payload }) => {
      if (state.data) state.data.access_groups = payload.access_groups;
    })
    .addCase(createAccessGroupSuccess, (state, { payload }) => {
      if (!state.data) return;
      state.data.access_groups.push(payload.new_access_group);
    })
    .addCase(deleteAccessGroupSuccess, (state, { payload }) => {
      if (!state.data) return;
      const index = state.data.access_groups.findIndex(
        (group) => group.id === payload.access_group_id,
      );

      if (index >= 0) state.data.access_groups.splice(index, 1);
    })
    .addCase(updateAccessGroupsSuccess, (state, { payload }) => {
      if (!state.data) return;
      payload.access_groups.forEach((new_access_group) => {
        const access_group = state.data?.access_groups.find(
          (group) => group.id === new_access_group.id,
        );

        if (access_group) {
          access_group.name = new_access_group.name;
          access_group.default_data_source_ids = new_access_group.default_data_source_ids;
          access_group.data_source_ids = new_access_group.data_source_ids;
        }
      });
    })
    .addCase(connectDataSourceSuccess, (state, { payload }) => {
      if (!state.data) return;
      payload.data_source.access_groups.forEach((accessGroupId) => {
        const accessGroup = state.data?.access_groups.find((group) => group.id === accessGroupId);
        if (!accessGroup) return;

        accessGroup.data_source_ids.push(payload.data_source.id);
        if (payload.new_schema) accessGroup.default_data_source_ids.push(payload.data_source.id);
      });
    })
    .addCase(editDataSourceSuccess, (state, { payload }) => {
      if (!state.data) return;
      const accessGroupUpdates = payload.data_source.access_groups ?? [];
      const data_source_id = payload.data_source.id;
      state.data.access_groups.forEach((accessGroup) => {
        const accessGroupInUpdates = accessGroupUpdates.includes(accessGroup.id);
        const accessGroupHasDataSource = accessGroup.data_source_ids.includes(data_source_id);
        if (accessGroupInUpdates && !accessGroupHasDataSource)
          accessGroup.data_source_ids.push(data_source_id);
        else if (!accessGroupInUpdates && accessGroupHasDataSource)
          accessGroup.data_source_ids = accessGroup.data_source_ids.filter(
            (ds_id) => ds_id !== data_source_id,
          );
      });
    })
    .addCase(uploadSupportEmailSuccess, (state, { payload }) => {
      if (state.data) state.data.support_email = payload.postData.support_email;
    })
    .addCase(assignUserRoleSuccess, (state, { payload }) => {
      if (state.data) {
        const user = state.data.team_members.find((member) => member.id === payload.user_id);
        if (user) user.role_names = payload.role_names;
      }
    })
    .addCase(assignPendingInviteRoleSuccess, (state, { payload }) => {
      if (state.data) {
        const user = state.invitedUsers.find((user) => user.email === payload.email);
        if (user) user.role_names = payload.role_names;
      }
    })
    .addCase(removeTeammateSuccess, (state, { payload }) => {
      if (state.data) {
        const index = state.data.team_members.findIndex((member) => member.id === payload.user_id);
        if (index >= 0) state.data.team_members.splice(index, 1);
      }
    })
    .addDefaultCase((state) => state);
});
