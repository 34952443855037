import { sprinkles } from '@explo/design-system';
import { DatasetPreviewPanel } from 'components/Dataset/DatasetPreviewPanel';
import { FC } from 'react';

interface Props {
  query: string;
}

export const GlobalDatasetQuerySection: FC<Props> = ({ query }) => {
  return (
    <div className={sprinkles({ marginX: 'sp3' })}>
      <div className={borderClassName}>
        <div className={headerClassName}>
          <div className={sprinkles({ margin: 'sp2' })}>Query</div>
        </div>
        <DatasetPreviewPanel queryText={query} />
      </div>
    </div>
  );
};

const headerClassName = sprinkles({
  heading: 'h3',
  color: 'contentSecondary',
  borderBottom: 1,
  borderColor: 'outline',
});

const borderClassName = sprinkles({
  border: 1,
  borderColor: 'outline',
  borderRadius: 8,
});
