import sqlFormatter from 'sql-formatter';

import { DatasetSchema, OPERATION_TYPES } from '@explo/data';

import { TableDataset } from 'actions/dataSourceActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { DEFAULT_ROWS_PER_PAGE } from 'components/ds/DataGrid/paginator';
import { FidoTableView, NamespaceTableViewMap } from 'reducers/fidoReducer';
import { SchemaTablesMap } from 'reducers/parentSchemaReducer';
import { PIVOT_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';
import { VisualizeOperation } from 'types/dataPanelTemplate';

export const DEFAULT_QUERY_LIMIT = 5000;
export const DEFAULT_TABLE_PDF_QUERY_LIMIT = 700;

export const getDuplicateColumnsFromSchema = (schema: DatasetSchema): string[] | null => {
  const potentialDuplicates: string[] = [];

  // matches any string that ends with a number
  const regex = /([A-z]*)\d+$/;

  schema.forEach((column) => {
    const match = column.name.match(regex);
    if (match && schema.some((c) => c.name === match[1])) {
      potentialDuplicates.push(match[1]);
    }
  });

  return potentialDuplicates.length > 0 ? potentialDuplicates : null;
};

const INVALID_DATE_TIME_PARSE_INFO = (
  <div>
    The query generated by this panel is attempted to do time operations on a date field. Please
    ensure that the field you are operating on has a time part before doing hour, minute, or second
    operations
    <br />
    <a
      href="https://docs.explo.co/troubleshooting/common-errors#error-parsing-date-time"
      rel="noopener noreferrer"
      target="_blank">
      Click here for more info.
    </a>
  </div>
);

const AMBIGUOUS_COLUMN_REFERENCE = (
  <div>
    The query generated by this panel references an ambiguous column name. This is likely because
    the dataset used by this panel contains columns with duplicate names.
    <br />
    <a
      href="https://docs.explo.co/troubleshooting/common-errors#error-parsing-date-time"
      rel="noopener noreferrer"
      target="_blank">
      Click here for more info.
    </a>
  </div>
);

const TABLE_DOES_NOT_EXIST = (
  <div>
    The data source you are viewing is incompatible with its schema. This is likely because you have
    added a data source to a schema, but the data source does not contain all tables defined by the
    default data source of the schema.
    <br />
    <a
      href="https://docs.explo.co/troubleshooting/common-errors#table-does-not-exist"
      rel="noopener noreferrer"
      target="_blank">
      Click here for more info.
    </a>
  </div>
);

const MYSQL_OPERATION_NOT_SUPPORTED = (
  <div>
    This data panel is using an unsupported operation for MySQL. This is likely because you are
    using a MEDIAN filter or because the data panel is a box plot. Please reach out to Explo if you
    need this functionality supported for your MySQL dashboards.
  </div>
);

const BIGQUERY_RESERVED_KEYWORD = (
  <div>
    This data panel is using the &quot;time&quot; as the name of one of its columns. This is a
    reserved keyword in BigQuery and is causing issues loading this dashboard. Please use a
    different column name to view this data panel.
  </div>
);

export const IS_ACCESS_GROUP_ERROR = (error: string) => {
  return (
    error.startsWith('The following data visibility group') &&
    error.endsWith(
      'does not have default data sources selected and will result in errors loading data.',
    )
  );
};
export const ACCESS_GROUP_NO_DEFAULT_DATASOURCES_WARNING_WITH_LINK = (error: string) => (
  <>
    {error} Read more <a href="https://docs.explo.co/managing-permissions/access-groups">here</a>
  </>
);

const errorRegexes = [
  {
    regx: /column reference.*is ambiguous/i,
    description: AMBIGUOUS_COLUMN_REFERENCE,
  },
  {
    regx: /invalid value.*for.*date\/time/i,
    description: INVALID_DATE_TIME_PARSE_INFO,
  },
  {
    regx: /datepart.*not supported/i,
    description: INVALID_DATE_TIME_PARSE_INFO,
  },
  // Postgres
  {
    regx: /relation.*does not exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // MySql
  {
    regx: /table.*doesn't exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // BigQuery
  {
    regx: /table name.*missing dataset/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // Rockset
  {
    regx: /collection.*does not exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // MySQL partition/median operation
  {
    regx: /MySQL.*WITHIN GROUP/i,
    description: MYSQL_OPERATION_NOT_SUPPORTED,
  },
  // BigQuery reserved keyword
  {
    regx: /400 Unrecognized name: time/i,
    description: BIGQUERY_RESERVED_KEYWORD,
  },
];

export const parseErrorMessage = (errorMessage: string | undefined) =>
  errorRegexes.find((er) => errorMessage?.match(er.regx))?.description;

export const getSchemaNameInfo = (
  schemaTablesMap: SchemaTablesMap | NamespaceTableViewMap | null,
  parentSchemaId: string | undefined,
) => {
  const columnNames = new Set<string>();
  const tableNames = new Set<string>();

  if (schemaTablesMap !== null) {
    const datasetTables = parentSchemaId ? (schemaTablesMap[parentSchemaId] ?? {}) : {};

    Object.values(datasetTables).forEach((table: FidoTableView | TableDataset) => {
      const tableName = 'table_name' in table ? table.table_name : table.tableName;
      tableNames.add(tableName);
      table.schema?.forEach((column) => columnNames.add(tableName + '.' + column.name));
    });
  }

  return { tableNames: Array.from(tableNames), columnNames: Array.from(columnNames) };
};

/**
 * Returns the query limit for an operation type given a visualization context and team override configuration.
 *
 * For tables and report builders, use pdfMaxRowsOverride or the default limit for PDFs if we're in a screenshot context.
 * Otherwise, use the configured rows per page or default (DEFAULT_ROWS_PER_PAGE)
 *
 * For pivot tables, ALWAYS use the PIVOT_ROW_LIMIT because we've calculated that number based on performance testing.
 *
 * For maps, use the panels configured row limit if set, otherwise the team's configured max rows or defualt
 *
 * For all other configurations, use the team's configured max rows or default
 */
export const getDataPanelQueryLimit = (
  visualizeOperation: VisualizeOperation,
  embedType: EmbeddedDashboardType | undefined,
  dataPanelMaxDataPointsOverride: number | undefined,
  pdfMaxRowsOverride: number | undefined,
) => {
  switch (visualizeOperation.operation_type) {
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_TABLE: {
      if (embedType === EmbeddedDashboardType.SCREENSHOT) {
        return pdfMaxRowsOverride ?? DEFAULT_TABLE_PDF_QUERY_LIMIT;
      }

      return visualizeOperation.instructions.VISUALIZE_TABLE.rowsPerPage ?? DEFAULT_ROWS_PER_PAGE;
    }
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
      return PIVOT_ROW_LIMIT;
    case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
    case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
      return (
        visualizeOperation.instructions.VISUALIZE_GEOSPATIAL_CHART?.rowLimit ??
        dataPanelMaxDataPointsOverride ??
        DEFAULT_QUERY_LIMIT
      );
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      return dataPanelMaxDataPointsOverride ?? DEFAULT_QUERY_LIMIT;
    default:
      return DEFAULT_QUERY_LIMIT;
  }
};

export const getDatasetQueryLimit = (datasetMaxDataPointsOverride: number | undefined) => {
  return datasetMaxDataPointsOverride ?? DEFAULT_QUERY_LIMIT;
};

const DEFAULT_FORMAT_INDENT = '    ';

export const formatQuery = (query: string): string => {
  return sqlFormatter.format(query, {
    indent: DEFAULT_FORMAT_INDENT,
  });
};
