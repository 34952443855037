import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  AggregationType,
  ChartAggregation,
  DatasetSchema,
  DATE,
  DATETIME,
  DEFAULT_CUSTOM_FORMULA,
  OPERATION_TYPES,
  PivotAgg,
  TIMESTAMP,
} from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { IconName } from 'components/ds/Icon';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { getNewAggCol } from 'utils/dataPanelColUtils';

import DroppableColumnSection from './droppable/DroppableColumnSection';

type Props = {
  instructions: V2TwoDimensionChartInstructions | undefined;
  loading: boolean;
  schema: DatasetSchema;
};

export const CalendarHeatmapConfig: FC<Props> = ({ instructions, loading, schema }) => {
  const dispatch = useDispatch();

  const updateInstructions = (updateFunc: (draft: V2TwoDimensionChartInstructions) => void) => {
    const newInstructions = produce(instructions ?? {}, updateFunc);
    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP));
  };

  const customFormulaBtnProps =
    instructions?.aggColumns?.[0]?.agg.id === ChartAggregation.FORMULA
      ? undefined
      : {
          icon: 'function' as IconName,
          tooltipText: 'Click to add a custom formula aggregation',
          onClick: () =>
            updateInstructions((draft) => {
              draft.aggColumns = [
                {
                  column: { name: `custom_formula_${uuid()}` },
                  agg: { id: ChartAggregation.FORMULA, formula: DEFAULT_CUSTOM_FORMULA },
                },
              ];
            }),
        };

  return (
    <div>
      <SettingHeader name="Date Column" />
      <DroppableColumnSection
        required
        allowedTypes={[DATE, DATETIME, TIMESTAMP]}
        columns={instructions?.categoryColumn ? [instructions.categoryColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) =>
          updateInstructions((draft) => {
            draft.categoryColumn = { column: col, bucket: { id: PivotAgg.DATE_PART_MONTH_DAY } };
          })
        }
        onRemoveCol={() =>
          updateInstructions((draft) => {
            draft.categoryColumn = undefined;
          })
        }
        schema={schema}
      />
      <SettingHeader btnProps={customFormulaBtnProps} name="Aggregation" />
      <DroppableColumnSection
        required
        columns={instructions?.aggColumns ?? []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(newCol) =>
          updateInstructions((draft) => {
            const aggCol = getNewAggCol(newCol);
            if (aggCol) draft.aggColumns = [aggCol];
          })
        }
        onColOptionChanged={(option) =>
          updateInstructions((draft) => {
            if (draft.aggColumns?.length !== 1) return;
            const agg = option as AggregationType;
            draft.aggColumns[0].agg = { id: agg.id, formula: agg.formula };
          })
        }
        onRemoveCol={() =>
          updateInstructions((draft) => {
            draft.aggColumns = [];
          })
        }
        schema={schema}
      />
    </div>
  );
};
