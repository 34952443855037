import cx from 'classnames';
import { FC, useState } from 'react';

import { IconButton, Input, sprinkles } from 'components/ds';
import { FORMULA_AGG_TYPE } from 'constants/dataConstants';

import * as styles from './styles.css';

type Props = {
  disableEdits: boolean;
  draggingClass: string | undefined;
  formula: string | undefined;
  onColOptionChanged: (option: { id: string; name: string; formula: string }) => void;
  onRemoveCol: () => void;
};

export const DroppedFormula: FC<Props> = ({
  disableEdits,
  draggingClass,
  formula,
  onColOptionChanged,
  onRemoveCol,
}) => {
  const [isInEditMode, setIsInEditMode] = useState(!formula);

  if (isInEditMode) {
    return (
      <div className={sprinkles({ marginBottom: 'sp.5', display: 'flex' })} style={{ height: 32 }}>
        <Input
          fillWidth
          showInputButton
          defaultValue={formula}
          disabled={disableEdits}
          onSubmit={(newVal) => {
            setIsInEditMode(false);
            if (newVal === formula) return;
            onColOptionChanged({ ...FORMULA_AGG_TYPE, formula: newVal });
          }}
        />
      </div>
    );
  }

  return (
    <div className={cx(droppedFormulaClass, draggingClass)}>
      <div className={styles.colName}>{formula}</div>
      <IconButton
        className={styles.iconBtnWithDivider}
        name="pencil"
        onClick={() => setIsInEditMode(true)}
      />
      <IconButton className={styles.iconBtn} name="cross" onClick={onRemoveCol} />
    </div>
  );
};

const droppedFormulaClass = sprinkles({
  backgroundColor: 'lightBlue',
  borderRadius: 3,
  flexItems: 'alignCenterBetween',
});
