import cx from 'classnames';
import { FC } from 'react';

import {
  BaseCol,
  DateDisplayFormat,
  DateDisplayOptions,
  getCurrentDateFormat,
  OPERATION_TYPES,
} from '@explo/data';

import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { getCellAlignment } from 'components/dataTable/utils';
import { Input, Select, sprinkles } from 'components/ds';
import { DateDisplayFormatToLuxon } from 'constants/types';
import { DateFormatDescriptiveText } from '../../pages/dashboardPage/DataPanelConfig/FormatConfigTab/DateFormatDescriptiveText';

const inputClass = sprinkles({ marginTop: 'sp1' });

type Props = {
  column: BaseCol;
  displayOptions: DateDisplayOptions | undefined;
  operationType:
    | OPERATION_TYPES.VISUALIZE_TABLE
    | OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST
    | 'REPORT_BUILDER';

  updateDateOptions: (newFields: DateDisplayOptions) => void;
};

const formatOptions = Object.values(DateDisplayFormat).map((format) => ({
  value: format,
  label: DateDisplayFormatToLuxon[format],
}));

export const DateFormatConfig: FC<Props> = ({
  updateDateOptions,
  column,
  displayOptions,
  operationType,
}) => {
  const currentFormat = getCurrentDateFormat(displayOptions, column.type);

  const currentAlignment = getCellAlignment(displayOptions, column.type);
  return (
    <>
      <Select
        className={inputClass}
        label={{
          text: 'Format',
          infoText:
            'Formats will be localized. If you want a date format that is static and not localized, you should use the Custom date format option.',
        }}
        onChange={(value) => {
          if (currentFormat === value) return;
          updateDateOptions({ format: value as DateDisplayFormat });
        }}
        selectedValue={currentFormat}
        values={formatOptions}
      />
      {currentFormat === DateDisplayFormat.CUSTOM ? (
        <>
          <Input
            showInputButton
            className={cx(inputClass, sprinkles({ width: 'fill' }))}
            defaultValue={displayOptions?.customFormat}
            label="Custom format"
            onSubmit={(newFormat) => updateDateOptions({ customFormat: newFormat })}
          />
          <DateFormatDescriptiveText />
        </>
      ) : null}
      {
        // TODO: Implement alignment for collapsible lists
        operationType !== OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST ? (
          <HorizontalAlignmentToggle
            className={inputClass}
            label="Alignment"
            selectedAlignment={currentAlignment}
            updateAlignment={(alignment) => updateDateOptions({ alignment })}
          />
        ) : null
      }
    </>
  );
};
