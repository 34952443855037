import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { createDashboardDataset } from 'actions/dashboardV2Actions';
import { deleteDataset, editDatasetName } from 'actions/datasetActions';
import { setLoadingDatasetId } from 'reducers/dashboardEditConfigReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { trackEvent, EVENTS } from 'telemetry/exploAnalytics';
import { getViewFromDatasetId } from 'utils/fido/fidoRequestUtils';

import { createComputedView, saveComputedView } from '../fidoThunks';

export const createDatasetThunk =
  (
    name: string,
    parentSchemaId: number | string,
    dashboardId: number,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const state = getState();
    const shouldUseFido = state.currentUser.team?.feature_flags.use_fido;
    const parentSchemas = getParentSchemasList(state);

    if (shouldUseFido) {
      dispatch(
        createComputedView({
          name,
          namespace: parentSchemas.find((s) => s.id === parentSchemaId),
        }),
      );
    } else {
      dispatch(
        createDashboardDataset({
          name,
          dashId: dashboardId,
          parentSchemaId: parentSchemaId as number,
        }),
      );
    }
    trackEvent(EVENTS.CREATED_NEW_DATASET, {});
  };

export const deleteDatasetThunk =
  (datasetId: string): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(setLoadingDatasetId(datasetId));

    dispatch(deleteDataset({ datasetId }));
    trackEvent(EVENTS.DELETED_DATASET, { datasetId });
  };

export const editDatasetNameThunk =
  (datasetId: string, name: string): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const state = getState();
    const shouldUseFido = state.currentUser.team?.feature_flags.use_fido;

    dispatch(setLoadingDatasetId(datasetId));

    if (shouldUseFido) {
      const viewToEdit = getViewFromDatasetId(
        state.fido.computedViews,
        state.dashboardEditConfig.config?.datasets,
        datasetId,
      );
      viewToEdit && dispatch(saveComputedView({ ...viewToEdit, name: name }));
    } else {
      dispatch(editDatasetName({ name: name, datasetId: datasetId }));
    }

    trackEvent(EVENTS.EDITED_DATASET_NAME, {
      dataset_id: datasetId,
      dataset_name: name,
    });
  };
