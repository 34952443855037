import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES, TEXT_ELEM_HORIZ_ALIGNMENTS } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { HorizontalAlignmentToggle } from 'components/AlignmentToggles';
import { Input, Toggle, ToggleItem } from 'components/ds';
import {
  KPI_ORDERED_VERT_CONTENT_ALIGNMENTS,
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS,
} from 'constants/kpiAlignmentConstants';
import { VerticalContentAlignments } from 'types/dashboardTypes';
import { V2KPITrendInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export const KPITrendTitleConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const selectedAlignment =
    instructions.titleFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  const selectedVertAlignment =
    instructions.textOnlyFormat?.verticalContentAlignment ||
    KPI_VERT_CONTENT_ALIGNMENT_FORMATS.CENTER.id;

  return (
    <div className={configRootClass}>
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={instructions.textOnlyFormat?.subtitle}
        label={{ text: 'Subtitle', variableInput: true }}
        onSubmit={(newValue) => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.textOnlyFormat) draft.textOnlyFormat = {};
            draft.textOnlyFormat.subtitle = newValue;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
      />
      <HorizontalAlignmentToggle
        className={configInputClass}
        selectedAlignment={selectedAlignment}
        updateAlignment={(alignment) => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.titleFormat) draft.titleFormat = {};
            draft.titleFormat.horizAlignment = alignment;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
      />
      <Toggle
        className={configInputClass}
        label="Vertical Alignment"
        onValueChange={(value) => {
          const newInstructions = produce(instructions, (draft) => {
            if (!draft.textOnlyFormat) draft.textOnlyFormat = {};
            draft.textOnlyFormat.verticalContentAlignment = value as VerticalContentAlignments;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        selectedValue={selectedVertAlignment}>
        {KPI_ORDERED_VERT_CONTENT_ALIGNMENTS.map((alignment) => (
          <ToggleItem key={alignment.id} value={alignment.id}>
            {alignment.icon}
          </ToggleItem>
        ))}
      </Toggle>
    </div>
  );
};
