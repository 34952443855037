import { FC, useCallback, useState } from 'react';

import { Input, Modal, sprinkles } from 'components/ds';

type Props = {
  buttonName: string;
  closeModal: () => void;
  getErrorMessage?: (textFieldVal?: string, hasUserInputtedValue?: boolean) => string | undefined;
  loading?: boolean;
  modalOpen: boolean;
  modalTitle: string;
  onSubmit: (text: string) => void;
  portalContainerId?: string;
  resourceName?: string;
  textFieldPlaceholder: string;
};

export const TextFieldModal: FC<Props> = ({
  buttonName,
  closeModal,
  getErrorMessage,
  portalContainerId,
  loading,
  modalOpen,
  modalTitle,
  onSubmit,
  resourceName,
  textFieldPlaceholder,
}) => {
  const [textFieldVal, setTextFieldVal] = useState(resourceName ?? '');
  const [hasUserInputtedValue, setHasUserInputtedValue] = useState(false);

  const errorMsg = getErrorMessage?.(textFieldVal, hasUserInputtedValue);

  const onButtonClick = () => {
    if (getErrorMessage?.(textFieldVal)) return;
    if (resourceName === textFieldVal) return closeModal();
    onSubmit(textFieldVal);
    setTextFieldVal('');
  };

  const resetState = useCallback(() => {
    setTextFieldVal(resourceName ?? '');
    setHasUserInputtedValue(false);
  }, [resourceName]);

  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => {
        resetState();
        closeModal();
      }}
      portalContainerId={portalContainerId}
      primaryButtonProps={{
        disabled: !!errorMsg || !textFieldVal.trim() || loading,
        loading: loading,
        onClick: onButtonClick,
        text: buttonName,
      }}
      size="small"
      title={modalTitle}>
      <div className={sprinkles({ paddingX: 'sp3' })} style={{ minHeight: 56 }}>
        <Input
          autoFocus
          errorText={errorMsg}
          onChange={(newVal) => {
            setHasUserInputtedValue(true);
            setTextFieldVal(newVal);
          }}
          placeholder={textFieldPlaceholder}
          value={textFieldVal}
        />
      </div>
    </Modal>
  );
};
