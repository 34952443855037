//Should be kept in sync with MESSAGE_TYPE_TO_SLACK_CHANNEL
export enum PingTypes {
  PING_ONLINE = 'PING_ONLINE',
  PING_ONLINE_LAUNCH = 'PING_ONLINE_LAUNCH',
  PING_ONLINE_DEACTIVATED = 'PING_ONLINE_DEACTIVATED',
  PING_TEAM_CREATION = 'PING_TEAM_CREATION',
  PING_PRICING_PLAN_UPDATE = 'PING_PRICING_PLAN_UPDATE',
  PING_DATASOURCE_TESTING = 'PING_DATASOURCE_TESTING',
  PING_USER_WITHOUT_TEAM = 'PING_USER_WITHOUT_TEAM',
  PING_POWERED_BY_EXPLO_CLICKED = 'PING_POWERED_BY_EXPLO_CLICKED',
  PING_AI_USAGE = 'PING_AI_USAGE',
  PING_CUSTOMER_ACTION = 'PING_CUSTOMER_ACTION',
  PING_END_USER_EDITABLE_SECTIONS = 'PING_END_USER_EDITABLE_SECTIONS',
  PING_GLOBAL_DATASETS = 'PING_GLOBAL_DATASETS',
}
