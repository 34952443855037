import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import * as styles from './dataLibraryPage.css';
import { ManageDatasetModalType } from './types';

import { ComputedView } from '@explo-tech/fido-api';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Button, sprinkles } from 'components/ds';
import { DataLibraryBreadcrumbs } from 'pages/dataLibraryPage/DataLibraryBreadcrumbs';
import { ReduxState } from 'reducers/rootReducer';
import {
  getPathToCurrentSubFolderHelper,
  getSubFolderUniqueNames,
  getUpdatedFoldersForBranch,
} from './selectors';

import { RenameModal } from 'components/RenameModal';
import {
  addPendingResourceDeletion,
  addPendingResourceUpdate,
  ItemType,
} from 'reducers/dataLibraryReducer';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';
import { ResourceType } from 'types/exploResource';
import { copyAndRenameItem } from './dataLibraryUtil';
import { navigateToPathThunk } from './navigationUtils';
import { getOrDefault } from 'remotedata';

type Props = {
  selectedView: ComputedView;
};

export const DataLibraryTopBar: FC<Props> = ({ selectedView }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openModalType, setOpenModalType] = useState<ManageDatasetModalType>(
    ManageDatasetModalType.NO_MODAL,
  );

  const { subFolderUniqueNames, currentItemPath, folders } = useSelector(
    (state: ReduxState) => ({
      currentItemPath: state.dataLibrary.currentItemPath,
      subFolderUniqueNames: getSubFolderUniqueNames(state),
      folderPath: state.dataLibrary.currentItemPath,
      folders: getUpdatedFoldersForBranch(state, state.dataLibrary.currentBranch),
    }),
    shallowEqual,
  );

  const itemName = selectedView.name;

  return (
    <>
      <div className={styles.dataLibraryTopBar}>
        {selectedView.path ? (
          <DataLibraryBreadcrumbs folders={folders} path={selectedView.path} />
        ) : null}
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', height: 'fill' })}>
          <div className={sprinkles({ body: 'b2' })}>Viewing as</div>
          <ViewingAsCustomerSelector />
          <div
            className={sprinkles({ height: 'fill', backgroundColor: 'outline' })}
            style={{ width: 1 }}
          />
          <Button
            icon="pencil"
            onClick={() => setOpenModalType(ManageDatasetModalType.RENAME_MODAL)}
            variant="tertiary">
            Rename
          </Button>
          <Button
            icon="trash-can-reg"
            onClick={() => setOpenModalType(ManageDatasetModalType.DELETE_CONFIRMATION_MODAL)}
            variant="tertiary">
            Delete
          </Button>
        </div>
      </div>
      {openModalType === ManageDatasetModalType.DELETE_CONFIRMATION_MODAL ? (
        <DeleteResourceConfirmationModal
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
          onDelete={() => {
            dispatch(addPendingResourceDeletion(selectedView));
            if (!selectedView.path) return;

            const parentPath = getPathToCurrentSubFolderHelper(currentItemPath);
            dispatch(
              navigateToPathThunk(
                getOrDefault(folders.get(parentPath), null)?.id ?? '',
                ItemType.FOLDER,
                parentPath,
                history,
              ),
            );
          }}
          resourceName={itemName}
        />
      ) : null}
      {openModalType === ManageDatasetModalType.RENAME_MODAL ? (
        <RenameModal
          isOpen
          isLoadingUniqueNames={false}
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
          onSubmit={(newName) => {
            const newResource = copyAndRenameItem(selectedView, newName);
            dispatch(addPendingResourceUpdate(newResource));
            dispatch(
              navigateToPathThunk(
                newResource.id ?? '',
                ItemType.VIEW,
                newResource.path ?? '',
                history,
              ),
            );
          }}
          resourceName={itemName}
          resourceType={ResourceType.DATASET}
          uniqueNames={subFolderUniqueNames}
        />
      ) : null}
    </>
  );
};
