import { FC } from 'react';

import { Dataset } from 'actions/datasetActions';
import { CardItem } from 'components/PanelComponents/CardItem';
import { sprinkles } from 'components/ds';
import { getDatasetName } from 'utils/naming';

interface Props {
  datasets: Record<string, Dataset>;
  onDatasetSelected: (datasetId: string) => void;
}

export const DatasetMigrationDatasetList: FC<Props> = ({ datasets, onDatasetSelected }) => {
  return (
    <div className={sprinkles({ width: 'quarter', marginX: 'sp2' })}>
      {Object.entries(datasets).map(([datasetId, dataset]) => {
        return (
          <div className={sprinkles({ marginY: 'sp2' })} key={datasetId}>
            <CardItem
              leftIcon="table"
              name={getDatasetName(dataset)}
              onClick={() => onDatasetSelected(datasetId)}></CardItem>
          </div>
        );
      })}
    </div>
  );
};
