import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { ReportBuilderWrapper } from 'pages/ReportBuilder';

type MatchParams = {
  firstParam: string;
  secondParam?: string;
  thirdParam?: string;
};

export const IframeReportBuilderPage: FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  let customerToken: string | undefined = undefined;
  let reportBuilderId: string | undefined = undefined;
  let embedJwt: string | undefined = undefined;
  let environment: string | undefined = undefined;

  // the min length for an embed token is 10, but there's no max length so
  // going a little above for safety
  if (params.firstParam.length < 16) {
    reportBuilderId = params.firstParam;
    customerToken = params.secondParam;
    environment = params.thirdParam;
  } else {
    embedJwt = params.firstParam;
    environment = params.secondParam;
  }

  return (
    <ReportBuilderWrapper
      updateUrlParams
      customerToken={customerToken}
      embedJwt={embedJwt}
      embedType={EmbeddedDashboardType.IFRAME}
      environment={environment}
      reportBuilderEmbedId={reportBuilderId}
    />
  );
};
