import { assignInlineVars } from '@vanilla-extract/dynamic';
import Color from 'color';

import { vars, tokens } from '@explo/design-system';
import {
  BACKGROUND_COLOR_OFFSET,
  BACKGROUND_COLOR_DEEPER_OFFSET,
  PLACEHOLDER_TEXT_OPACITY,
} from 'globalStyles/constants';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getFontFamilyName, getWhiteOrBlack } from 'globalStyles/utils';
import { convertAlphaToOpaque, mixColors } from 'utils/general';

import {
  getGlobalStyleButtonColors,
  getGlobalStyleModalInfo,
  getComponentTextConfig,
  handleInteractionStep,
  getFontStyles,
} from './utils';

/** Convert globalStyleConfig to Vanilla Extract vars */
export function getGlobalStyleVars(
  { base, text, container, components }: GlobalStyleConfig,
  options?: GlobalStylesConfigOptions,
) {
  const customTheme = vars.customTheme;

  const { onlyCustomTheme, forReportBuilder } = options ?? {};

  const activeColor = base.actionColor.default;
  const activeColorObject = Color(activeColor);
  const activeContrast = getWhiteOrBlack(activeColorObject);

  const activeSubduedObject = convertAlphaToOpaque(activeColorObject.fade(0.85));
  const activeSubdued = activeSubduedObject.string();

  const backgroundColorIsDark = new Color(base.backgroundColor).isDark();
  const offsetBackgroundMix = backgroundColorIsDark ? 'white' : 'black';
  const offsetBackgroundColor = new Color(base.backgroundColor)
    .mix(new Color(offsetBackgroundMix), BACKGROUND_COLOR_OFFSET)
    .hex();

  const offsetFillMix = getWhiteOrBlack(container.fill);
  const offsetFillColor = new Color(container.fill)
    .mix(new Color(offsetFillMix), BACKGROUND_COLOR_OFFSET)
    .hex();

  const deeperOffsetFillColor = new Color(container.fill)
    .mix(new Color(offsetFillMix), BACKGROUND_COLOR_DEEPER_OFFSET)
    .hex();

  const interactionColorString = convertAlphaToOpaque(
    new Color(base.actionColor.interactionStateColor ?? base.actionColor.default).fade(
      backgroundColorIsDark ? 0.1 : 0.9,
    ),
  ).string();

  const placeholderColor = new Color(text.secondaryColor)
    .fade(1 - PLACEHOLDER_TEXT_OPACITY)
    .rgb()
    .string();

  const {
    primaryButtonColor,
    primaryButtonTextColor,
    secondaryButtonColor,
    secondaryButtonTextColor,
    tertiaryButtonTextColor,
  } = getGlobalStyleButtonColors(base.actionColor, text);
  const tertiaryButtonColor = forReportBuilder ? 'white' : container.fill;
  const buttonCornerRadius = container.cornerRadius.buttons ?? container.cornerRadius.default;
  const hoverButtonColor = mixColors(primaryButtonColor, 'white', 0.92).rgb().string();
  const activeButtonColor = mixColors(primaryButtonColor, 'white', 0.84).rgb().string();
  const hoverSecondaryButtonColor = mixColors(secondaryButtonColor, 'white', 0.92).rgb().string();
  const activeSecondaryButtonColor = mixColors(secondaryButtonColor, 'white', 0.84).rgb().string();

  const modalInfo = getGlobalStyleModalInfo(components?.modal);

  const colors = onlyCustomTheme
    ? tokens.colors
    : {
        ...tokens.colors,
        active: activeColor,
        activeContrast,
        activeHovered: convertAlphaToOpaque(activeColorObject.darken(0.2)).string(),
        activePressed: convertAlphaToOpaque(activeColorObject.darken(0.3)).string(),
        activeSubdued,
        activeSubduedContrast: getWhiteOrBlack(activeSubduedObject),
        activeSubduedHovered: convertAlphaToOpaque(activeColorObject.fade(0.8)).string(),
        activeSubduedPressed: convertAlphaToOpaque(activeColorObject.fade(0.75)).string(),
        activeBold: activeColorObject.darken(0.2).hex(),
        activeBoldHovered: activeColorObject.darken(0.3).hex(),
        activeBoldPressed: activeColorObject.darken(0.4).hex(),
      };

  const h2Primary = getFontStyles(text, 'h2');
  const bodyPrimary = getFontStyles(text, 'body');

  return assignInlineVars(vars, {
    ...tokens,
    colors,
    customTheme: {
      colors: {
        action: activeColor,
        actionContrast: activeContrast,
        interaction: interactionColorString,
        background: base.backgroundColor,
        backgroundOffset: offsetBackgroundColor,
      },
      text: {
        primaryFamily: getFontFamilyName(text.primaryFont || tokens.customTheme.text.primaryFamily),
        secondaryFamily: getFontFamilyName(
          text.secondaryFont || tokens.customTheme.text.secondaryFamily,
        ),
        primaryColor: text.primaryColor,
        secondaryColor: text.secondaryColor,
        bodyPrimary,
        bodySecondary: getFontStyles(text, 'body', true),
        h1Primary: getFontStyles(text, 'h1'),
        h2Primary,
        kpiTitle: getFontStyles(text, 'kpiTitle'),
        kpiValue: getFontStyles(text, 'kpiValue'),
        tableColumnHeader: getFontStyles(text, 'tableColumnHeader'),
        smallBodyPrimary: getFontStyles(text, 'smallBody'),
        smallBodySecondary: getFontStyles(text, 'smallBody', true),
        smallHeadingSecondary: getFontStyles(text, 'smallHeading', true),
        filterLabel: getFontStyles(text, 'filterLabel', true),
      },
      container: {
        borderColor: container.outline.color || customTheme.container.borderColor,
        borderRadius: `${container.cornerRadius.default}px`,
        borderStyle: container.outline.enabled ? `${container.outline.weight}px solid` : 'none',
        fill: container.fill,
        fillOffset: offsetFillColor,
        fillOffsetDeeper: deeperOffsetFillColor,
        padding: `${container.padding.default}px`,
      },
      button: {
        color: primaryButtonTextColor,
        backgroundColor: primaryButtonColor,
        hoverBackground: hoverButtonColor,
        activeBackground: activeButtonColor,
        secondaryColor: secondaryButtonTextColor,
        secondaryBackgroundColor: secondaryButtonColor,
        hoverSecondaryBackgroundColor: hoverSecondaryButtonColor,
        activeSecondaryBackgroundColor: activeSecondaryButtonColor,
        tertiaryColor: tertiaryButtonTextColor,
        tertiaryBackgroundColor: 'transparent', // Transparent so it inherits color from its parent
        hoverTertiaryBackgroundColor: handleInteractionStep(tertiaryButtonColor, 1),
        activeTertiaryBackgroundColor: handleInteractionStep(tertiaryButtonColor, 2),
        borderRadius: `${buttonCornerRadius}px`,
      },
      input: {
        border: container.outline.enabled
          ? `${container.outline.weight}px solid ${container.outline.color}`
          : '1px solid transparent',
        borderRadius: `${container.cornerRadius.inputFields}px`,
        hoverBorderColor: interactionColorString,
        focusBorderColor: interactionColorString,
        placeholderColor,
      },
      spinner: {
        color: components?.spinner.color || customTheme.spinner.color,
      },
      panelError: {
        icon: { color: components?.panelError?.icon?.color || customTheme.panelError.icon.color },
        message: getComponentTextConfig(h2Primary, components?.panelError?.message),
        description: getComponentTextConfig(bodyPrimary, components?.panelError?.description),
      },
      modal: {
        overlayBackgroundColor: modalInfo.overlayBackgroundColor,
        overlayOpacity: modalInfo.overlayOpacity,
      },
      singleNumber: {
        padding: `${
          components?.singleNumber?.padding ?? Math.round(container.padding.default / 2)
        }px`,
      },
      card: {
        hoverBackgroundColor: offsetFillColor,
        selectedBackgroundColor: activeSubdued,
        selectedHoverBackgroundColor: handleInteractionStep(activeSubdued, 1),
        selectedBorderColor: activeColor,
      },
    },
  });
}

type GlobalStylesConfigOptions = {
  // For preview page we only need to access custom theme variables
  onlyCustomTheme?: boolean;
  forReportBuilder?: boolean;
};
