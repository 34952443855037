import {
  BranchContentResourceService,
  ListVersionedViewsRequest,
  ListViewsResourceService,
  Parameter,
  ParseQueryRequest,
  ViewResourceService,
  ViewResponse,
} from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTION } from 'actions/types';
import { StatusCode } from 'constants/fetchConstants';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { makeFidoThunkRequest } from 'utils/thunkUtils';

export const getVersionedViewThunk = createAsyncThunk<
  ViewResponse,
  { viewId: string; versionId: string; namespaceId: string },
  { state: ReduxState }
>(
  ACTION.FETCH_VERSIONED_COMPUTED_VIEW,
  async ({ viewId, versionId, namespaceId }, { getState }) => {
    return makeFidoThunkRequest(
      () => ViewResourceService.getVersionedView(viewId, namespaceId, versionId),
      getState().fido.fidoToken ?? '',
      'Error fetching versioned view',
    );
  },
);

export const batchGetVersionedViews = createAsyncThunk<
  { views: Array<ViewResponse> },
  ListVersionedViewsRequest,
  { state: ReduxState }
>(ACTION.BATCH_FETCH_VERSIONED_COMPUTED_VIEWS, async (requestBody, { getState }) => {
  return makeFidoThunkRequest(
    () => ListViewsResourceService.batchGetViews(requestBody),
    getState().fido.fidoToken ?? '',
    'Error fetching versioned views',
  );
});

export interface ViewDeletedResponse {
  viewId: string;
  branchId: string;
}

export const batchGetLatestVersionedViews = createAsyncThunk<
  Array<ViewResponse | ViewDeletedResponse | undefined>,
  { branchId: string; viewId: string }[],
  { state: ReduxState }
>(ACTION.BATCH_FETCH_LATEST_VERSIONED_COMPUTED_VIEWS, async (requests, { getState }) => {
  return Promise.all(
    requests.map((request) => {
      return makeFidoThunkRequest(
        () => BranchContentResourceService.getViewOnBranch(request.branchId, request.viewId),
        getState().fido.fidoToken ?? '',
        'Error fetching latest versioned views',
      ).then(
        (value) => value,
        (error) => {
          if (error.status === StatusCode.NOT_FOUND) {
            return {
              viewId: request.viewId,
              branchId: request.branchId,
            };
          }
        },
      );
    }),
  );
});

export const parseQueryThunk = createAsyncThunk<
  { parameters: Array<Parameter> },
  { datasetId: string; request: ParseQueryRequest },
  { state: ReduxState }
>(ACTION.PARSE_QUERY, async ({ request }, { getState }) => {
  const archetypePropertyNames = getArchetypeProperties(getState());

  return makeFidoThunkRequest(
    () => ListViewsResourceService.parseQuery(request),
    getState().fido.fidoToken ?? '',
    'Error parsing query',
  ).then((response) => {
    const filteredParameters = response.parameters.filter(
      (parameter) => !archetypePropertyNames.has(parameter.name),
    );

    return {
      parameters: filteredParameters,
    };
  });
});
