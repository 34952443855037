import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { RenameModal } from 'components/RenameModal';
import { getUniqueNames } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import { listResourceContentNames } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { Folder, Resource, ResourceType } from 'types/exploResource';

interface Props {
  isOpen: boolean;
  isExploreProduct: boolean;
  isFolder: boolean;
  currentFolderId: number;
  resource: Folder | Resource;
  onSubmit: (newName: string) => void;
  onClose: () => void;
}

export const ResourceRenameModal: FC<Props> = ({
  isOpen,
  isExploreProduct,
  isFolder,
  currentFolderId,
  resource,
  onSubmit,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { uniqueNames, isLoadingUniqueNames } = useSelector(
    (state: ReduxState) => ({
      uniqueNames: getUniqueNames(state, isExploreProduct),
      isLoadingUniqueNames: isExploreProduct
        ? RD.isLoading(state.dashboard.currentFolderResourceNames)
        : RD.isLoading(state.reportBuilder.currentFolderResourceNames),
    }),
    shallowEqual,
  );

  useEffect(() => {
    const type = isExploreProduct ? ResourceType.DASHBOARD : ResourceType.REPORT;
    dispatch(listResourceContentNames({ id: currentFolderId, type }));
  }, [currentFolderId, dispatch, isExploreProduct]);

  const resourceType = isFolder
    ? ResourceType.FOLDER
    : isExploreProduct
      ? ResourceType.DASHBOARD
      : ResourceType.REPORT;

  return (
    <RenameModal
      isLoadingUniqueNames={isLoadingUniqueNames}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      resourceName={resource.name}
      resourceType={resourceType}
      uniqueNames={uniqueNames}
    />
  );
};
