import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperationThunk } from 'actions/dataPanelConfigActions';
import { COLOR_ZONES } from 'components/ChartConfigs/ColorConfigs';
import { ColorZonesConfig } from 'components/ChartConfigs/ColorZonesConfig';
import { Toggle, ToggleItem } from 'components/ds';
import { ColorFormat, HeatMapFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';

import { HeatMapGradientConfiguration } from './HeatMapGradientConfiguration';

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  instructions: V2TwoDimensionChartInstructions;
  visualizationType: OPERATION_TYPES;
  updateHeatMapInstructions: (updates: HeatMapFormat) => void;
};

export const HeatMapColorsConfig: FC<Props> = ({
  globalStyleConfig,
  instructions,
  visualizationType,
  updateHeatMapInstructions,
}) => {
  const dispatch = useDispatch();

  const updateColorFormat = (colorUpdates: ColorFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.colorFormat = { ...draft.colorFormat, ...colorUpdates };
    });
    dispatch(
      updateVisualizeOperationThunk(newInstructions, OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2, false),
    );
  };
  const useZones = instructions.colorFormat?.useZones;

  return (
    <>
      {visualizationType === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP ? null : (
        <Toggle
          onValueChange={() => {
            updateColorFormat({ useZones: !useZones });
            // once zones is set requires reload to go back to using gradient
            if (useZones) dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }));
          }}
          selectedValue={useZones ? COLOR_ZONES : 'Gradient'}>
          <ToggleItem value="Gradient" />
          <ToggleItem value={COLOR_ZONES} />
        </Toggle>
      )}
      <div className={configRootClass}>
        {useZones ? (
          <ColorZonesConfig instructions={instructions} updateColorFormat={updateColorFormat} />
        ) : (
          <HeatMapGradientConfiguration
            globalStyleConfig={globalStyleConfig}
            instructions={instructions}
            updateHeatMapInstructions={updateHeatMapInstructions}
          />
        )}
      </div>
    </>
  );
};
