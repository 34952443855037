import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { Input, Switch, sprinkles } from 'components/ds';
import { HeatMapFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { ValueFormatConfig } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/formatSections/ValueFormatConfig';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

import { HeatMapColorsConfig } from './HeatMapColorsConfig';

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const HeatMapConfig: FC<Props> = ({
  globalStyleConfig,
  visualizationType,
  instructions,
}) => {
  const dispatch = useDispatch();
  const heatMap = instructions.chartSpecificFormat?.heatMap;

  const updateHeatMapInstructions = (updates: HeatMapFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        heatMap: {
          ...draft.chartSpecificFormat?.heatMap,
          ...updates,
        },
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <Switch
        className={configInputClass}
        label="Fill in missing data with Zeros"
        onChange={() =>
          updateHeatMapInstructions({ nullMissingValues: !heatMap?.nullMissingValues })
        }
        switchOn={!heatMap?.nullMissingValues}
      />
      <Switch
        className={configInputClass}
        label="Show Chart Values"
        onChange={() => updateHeatMapInstructions({ showCellLabels: !heatMap?.showCellLabels })}
        switchOn={heatMap?.showCellLabels}
      />
      {heatMap?.showCellLabels ? (
        <>
          <ValueFormatConfig
            instructions={heatMap.valueFormat}
            updateInstructions={(valueFormat) => updateHeatMapInstructions({ valueFormat })}
          />
          <Switch
            className={configInputClass}
            label="Hide Zeros"
            onChange={() => updateHeatMapInstructions({ hideZeros: !heatMap?.hideZeros })}
            switchOn={heatMap?.hideZeros}
          />
        </>
      ) : null}
      <Switch
        className={configInputClass}
        label={{ text: 'Enable Vertical Scroll' }}
        onChange={() =>
          updateHeatMapInstructions({ enableVerticalScroll: !heatMap?.enableVerticalScroll })
        }
        switchOn={heatMap?.enableVerticalScroll}
      />
      {heatMap?.enableVerticalScroll ? (
        <Input
          fillWidth
          showInputButton
          className={configInputClass}
          defaultValue={String(heatMap?.verticalCellHeight ?? '')}
          label={{ text: 'Cell Height', infoText: 'Min height of 16' }}
          onSubmit={(height) => {
            const heightFloat = parseFloat(height ?? '');
            updateHeatMapInstructions({
              verticalCellHeight: isNaN(heightFloat)
                ? undefined
                : heightFloat < 16
                  ? 16
                  : heightFloat,
            });
          }}
        />
      ) : null}

      <SettingHeader name="Colors" />
      <div className={sprinkles({ margin: 'sp1.5' })}>
        <HeatMapColorsConfig
          globalStyleConfig={globalStyleConfig}
          instructions={instructions}
          updateHeatMapInstructions={updateHeatMapInstructions}
          visualizationType={visualizationType}
        />
      </div>
    </div>
  );
};
