import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { FC, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { DatasetSchema, FilterOperationInstructions } from '@explo/data';

import { updateUnderlyingDataDataPanel } from 'actions/dataPanelTemplateAction';
import { DownloadPanelFunc, DownloadSpreadsheetFunc } from 'actions/exportActions';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { ExportConfig, LinkFormat, UserTransformedSchema } from 'constants/types';
import { GlobalStylesContext } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { ReduxState } from 'reducers/rootReducer';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { isChartExportEnabled } from 'utils/exportUtils';

import { TransformationSettingsButton } from '../DataTable/TransformationSettingsButton';

import { DownloadTableButton } from './DownloadTableButton';
import { FilterButton } from './FilterButton';
import { PanelName } from './PanelName';

const useStyles = makeStyles((theme: Theme) => ({
  header: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    borderBottom: `1px solid ${globalStyleConfig.container.outline.color}`,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
    height: HEADER_HEIGHT,
    justifyContent: 'space-between',
  }),
  dataTablePadding: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(5)}px ${globalStyleConfig.container.padding.default}px`,
    paddingBottom: theme.spacing(5),
  }),
}));

type Props = {
  drilldownDatasetName?: string;
  adHocOperationInstructions: AdHocOperationInstructions;
  dpEndsOnRightSide?: boolean;
  error?: boolean;
  infoTooltipText?: string;
  isFilteringDisabled?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  title: string;
  schema: DatasetSchema;
  updateUserTransformedSchema?: (userTransformedSchema: UserTransformedSchema) => void;
  linkFormat?: LinkFormat;
  userTransformedSchema?: UserTransformedSchema;
  isDrilldownModal?: boolean;
  hideFilterAndDownloadButtonsIfNoData?: boolean;

  // Export Button props
  dataPanelId?: string;
  exportCurrentlyDisabled?: boolean;
  exportConfig?: ExportConfig;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadPanelFunc;
  processString: (s: string) => string;
};

export const DataTableHeader: FC<Props> = ({
  drilldownDatasetName,
  adHocOperationInstructions,
  dpEndsOnRightSide,
  error,
  infoTooltipText,
  isFilteringDisabled,
  loading,
  onAdHocFilterInfoUpdate,
  title,
  schema,
  updateUserTransformedSchema,
  linkFormat,
  userTransformedSchema,
  isDrilldownModal,
  dataPanelId,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  processString,
  exportCurrentlyDisabled,
  exportConfig,
  hideFilterAndDownloadButtonsIfNoData = false,
}) => {
  const dispatch = useDispatch();

  const variableReplacedSchema =
    userTransformedSchema &&
    userTransformedSchema.map((data) => ({
      ...data,
      friendly_name: processString(data.friendly_name || ''),
    }));

  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  const dataPanelData = useSelector(
    (state: ReduxState) =>
      dataPanelId != null ? state.dashboardData.dataPanelData[dataPanelId] : undefined,
    shallowEqual,
  );

  const maybeRenderFilterAndDownloadButtons = () => {
    const hasData = !loading && dataPanelData?.rows != null && dataPanelData.rows.length > 0;
    if (!hasData && hideFilterAndDownloadButtonsIfNoData) {
      return null;
    }
    return (
      <>
        {!isFilteringDisabled ? (
          <div className={sprinkles({ marginX: isDrilldownModal ? 'sp1' : undefined })}>
            <FilterButton
              adHocFilterInfo={adHocOperationInstructions?.filterInfo}
              error={!!error}
              isDrilldownModal={!!isDrilldownModal}
              loading={loading}
              onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
              openPopoverToRight={dpEndsOnRightSide === undefined ? false : !dpEndsOnRightSide}
              schema={variableReplacedSchema ?? schema}
            />
          </div>
        ) : null}
        {exportConfig && dataPanelId && isChartExportEnabled(exportConfig, true) ? (
          <DownloadTableButton
            dataPanelId={dataPanelId}
            disabled={exportCurrentlyDisabled}
            exportConfig={exportConfig}
            isDrilldownModal={!!isDrilldownModal}
            onDownloadPanelPdf={onDownloadPanelPdf}
            onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
          />
        ) : null}
      </>
    );
  };

  return (
    <div
      className={cx(
        classes.header,
        classes.dataTablePadding,
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}>
      <PanelName
        drilldownDatasetName={drilldownDatasetName}
        infoTooltipText={infoTooltipText}
        loading={loading}
        panelName={title}
      />
      <div
        className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}
        onClick={(e) => e.stopPropagation()}>
        {variableReplacedSchema && updateUserTransformedSchema ? (
          <TransformationSettingsButton
            isDrilldownModal={!!isDrilldownModal}
            loading={!!loading}
            updateUserTransformedSchema={updateUserTransformedSchema}
            userTransformedSchema={variableReplacedSchema}
          />
        ) : null}
        {maybeRenderFilterAndDownloadButtons()}
        {isDrilldownModal ? (
          <EmbedButton
            icon="cross"
            onClick={() => dispatch(updateUnderlyingDataDataPanel())}
            variant="tertiary"
          />
        ) : (
          <UrlClickThroughButton linkFormat={linkFormat} />
        )}
      </div>
    </div>
  );
};
