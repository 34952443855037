import { DatasetSchema, SchemaDisplayOptions } from '@explo/data';

import { Dataset, DatasetConfig, DrilldownColConfig } from 'actions/datasetActions';
import { ExtendedDrilldownColConfig } from 'pages/dashboardPage/dashboardDatasetEditor/DrilldownDatasetColumns';
import { difference, sortBy } from 'utils/standard';

import { UserTransformedSchema } from 'constants/types';
import { SchemaDisplayOptionWithTableJoinConfig } from 'utils/joinTableUtils';
import { titleCase } from './graphUtils';

export const initConfig = (index: number, displayName: string) => {
  return { index, displayName, isIncluded: true, isVisible: true };
};

export const fetchDatasetSuccessDrilldownConfig = (
  fetchingDataset: Dataset,
  schema: DatasetSchema,
) => {
  if (fetchingDataset.drilldownColumnConfigs === undefined) {
    const drilldownColumnConfigs = schema.reduce(
      (acc, col, index) => {
        acc[col.name] = initConfig(index, titleCase(col.name));
        return acc;
      },
      {} as Record<string, DrilldownColConfig>,
    );

    return drilldownColumnConfigs;
  } else {
    // If new column, add, if missing column delete
    const drilldownColumnConfigs = fetchingDataset.drilldownColumnConfigs;
    const schemaColNames = schema.map((col) => col.name);
    const drilldownCols = Object.keys(drilldownColumnConfigs);
    const schemaColsToAdd = difference(schemaColNames, drilldownCols);
    const drilldownColsToDelete = difference(drilldownCols, schemaColNames);

    // Add columns in schema that are not in drilldown
    let index = drilldownCols.length;
    schemaColsToAdd.forEach((colName) => {
      drilldownColumnConfigs[colName] = initConfig(index, titleCase(colName));
      index += 1;
    });

    // Delete columns in drilldown that are not in schema + resort by index
    drilldownColsToDelete.forEach((colName) => delete drilldownColumnConfigs[colName]);

    // Only need to resort if something was deleted
    if (drilldownColsToDelete.length > 0) {
      const drilldownEntries = Object.entries(drilldownColumnConfigs);

      const sortedDrilldown = sortBy(drilldownEntries, ([_, info]) => info.index);

      sortedDrilldown.forEach(([name, info], index) => {
        drilldownColumnConfigs[name] = { ...info, index };
      });
    }

    return drilldownColumnConfigs;
  }
};

export const extendedDrilldownConfigToNormal = (
  extendedDrilldownConfigs: ExtendedDrilldownColConfig[],
) => {
  return extendedDrilldownConfigs.reduce(
    (acc, { name, isVisible, isIncluded, displayFormatting, displayName, index }) => {
      acc[name] = { isVisible, isIncluded, displayFormatting, displayName, index };
      return acc;
    },
    {} as Record<string, DrilldownColConfig>,
  );
};

export const getDrilldownDisplayOptions = (
  drilldownColumnConfigs: Record<string, DrilldownColConfig>,
) => {
  return Object.entries(drilldownColumnConfigs).reduce((acc, [name, config]) => {
    const formatting = config.displayFormatting as SchemaDisplayOptionWithTableJoinConfig;
    if (config.isIncluded && config.isVisible && formatting) {
      acc[name] = formatting;
    }
    return acc;
  }, {} as SchemaDisplayOptions);
};

export const getSortedDrilldownColumns = (schema: DatasetSchema, dataset: DatasetConfig) => {
  const drilldownConfigs = dataset.drilldownColumnConfigs;

  if (!drilldownConfigs || Object.keys(drilldownConfigs).length === 0) return schema;

  const filteredSchema = schema.reduce((acc, col) => {
    const drilldownColumnConfig = drilldownConfigs[col.name];
    if (drilldownColumnConfig?.isIncluded && drilldownColumnConfig?.isVisible) {
      acc.push({ ...col, friendly_name: drilldownColumnConfig?.displayName || col.name });
    }
    return acc;
  }, [] as DatasetSchema);

  const reorderedSchema = sortBy(filteredSchema, (info) => {
    return drilldownConfigs[info.name].index;
  });

  return reorderedSchema;
};

export const getSortedUserTransformedDrilldownColumns = (
  schema: DatasetSchema,
  dataset: Dataset,
) => {
  const drilldownConfigs = dataset.drilldownColumnConfigs;

  if (!drilldownConfigs || Object.keys(drilldownConfigs).length === 0) {
    return schema.map((info) => ({ ...info, isVisible: true }));
  }

  const filteredSchema = schema.reduce((acc, col) => {
    const drilldownColumnConfig = drilldownConfigs[col.name];
    if (drilldownColumnConfig?.isIncluded) {
      acc.push({
        ...col,
        friendly_name: drilldownColumnConfig?.displayName || col.name,
        isVisible: drilldownColumnConfig.isVisible,
      });
    }
    return acc;
  }, [] as UserTransformedSchema);

  return sortBy(filteredSchema, (info) => {
    return drilldownConfigs[info.name].index;
  });
};
