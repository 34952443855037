import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveAnalyticsConfig, SaveAnalyticsConfigBody } from 'actions/teamActions';
import { Checkbox, Input, sprinkles, Switch } from 'components/ds';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import { isValidHttpUrl } from 'telemetry/telemetryUtils';
import { map } from 'utils/standard';

const actionTypes: string[] = Object.values(ReportedAnalyticActionTypes);

export const SettingsAnalyticsSection: FC = () => {
  const dispatch = useDispatch();

  const team = useSelector((state: ReduxState) => state.teamData.data);

  const analyticsReportingActions = useMemo(
    () => new Set(map(team?.analytics_reporting_actions || [], 'name')),
    [team?.analytics_reporting_actions],
  );

  const onConfigUpdated = (update: Partial<SaveAnalyticsConfigBody>) => {
    if (update?.analytics_reporting_url && !isValidHttpUrl(update.analytics_reporting_url)) {
      showErrorToast('The input URL must be a valid URL.');
      return;
    }
    dispatch(
      saveAnalyticsConfig(
        {
          postData: update,
        },
        () => showSuccessToast('Analytics settings saved successfully'),
      ),
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
      <div className={sprinkles({ heading: 'h2' })}>Analytics</div>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
        <div className={sprinkles({ color: 'contentTertiary' })}>
          Send analytics about your customer&apos;s dashboard usage to your database
        </div>
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
          <Switch
            label={{
              text: 'Send Javascript Events',
              infoText:
                'If enabled, sends an additional javascript event called "analyticsEventFired".', // TODO: Include docs link
            }}
            onChange={(value) => onConfigUpdated({ should_send_js_analytics_events: value })}
            switchOn={team?.should_send_js_analytics_events}
          />
          <Input
            defaultValue={team?.analytics_reporting_url ?? ''}
            label="URL"
            onSubmit={(value) => onConfigUpdated({ analytics_reporting_url: value })}
          />
          <Input
            defaultValue={team?.analytics_reporting_token ?? ''}
            label="Authorization Token"
            onSubmit={(value) => onConfigUpdated({ analytics_reporting_token: value })}
          />
        </div>
      </div>
      <div className={sprinkles({ flexItems: 'column', marginTop: 'sp1', gap: 'sp.5' })}>
        {actionTypes.map((action) => (
          <Checkbox
            isChecked={analyticsReportingActions.has(action)}
            key={action}
            label={action}
            onChange={() => {
              const newState = new Set(analyticsReportingActions);
              if (analyticsReportingActions.has(action)) {
                newState.delete(action);
              } else {
                newState.add(action);
              }
              onConfigUpdated({
                analytics_reporting_actions: Array.from(newState),
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};
