import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { DirectoryPanel } from './DirectoryPanel';
import {
  getAllPendingCreations,
  getAllPendingDeletions,
  getAllPendingUpdates,
  getBaseFoldersForBranch,
  getCurrentResource,
} from './selectors';

import { AlertModal, sprinkles } from 'components/ds';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { ROUTES } from 'constants/routes';
import { useHistory, useParams } from 'react-router';
import {
  ItemType,
  setDirectLoadedResource,
  setFoldersExpansionState,
  setInProgressDirectFetchPaths,
} from 'reducers/dataLibraryReducer';
import { listBranchContentThunk, listBranchesThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { getResourceOnBranchThunk } from 'reducers/thunks/fidoThunks/resourceThunks';
import * as RD from 'remotedata';
import { CommitHistoryModal } from './CommitHistoryModal';
import { COMPUTED_VIEW_TYPE, FOLDER_TYPE, NEWER_COMMIT_ERROR_MESSAGE } from './constants';
import { CreateCommitModal } from './CreateCommitModal';
import { getAllPathsUpToPath } from './dataLibraryUtil';
import { DatasetPage } from './DatasetPage';
import { FetchErrorComponent } from './FetchErrorComponent';
import { FolderPage } from './FolderPage';
import { fetchUserTeam } from 'actions/teamActions';

enum ModalType {
  NONE,
  CREATE_COMMIT,
  VIEW_COMMIT_HISTORY,
}

export const ResourcePage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { resourceId: string } = useParams();

  const [openModalType, setOpenModalType] = useState<ModalType>(ModalType.NONE);

  const {
    currentBranch,
    currentItemPath,
    currentResource,
    pendingResourceCreations,
    pendingResourceDeletions,
    pendingResourceUpdates,
    initialUnappliedPendingCreationsMap,
    pendingCommitError,
    currentUser,
    branchCommits,
    teamData,
    directLoadedResource,
    inProgressDirectFetchPaths,
    baseFolders,
    hasFinishedDirectPathsFetch,
  } = useSelector((state: ReduxState) => {
    return {
      currentBranch: state.dataLibrary.currentBranch,
      currentItemPath: state.dataLibrary.currentItemPath,
      currentResource: getCurrentResource(state),
      pendingResourceCreations: getAllPendingCreations(state),
      pendingResourceDeletions: getAllPendingDeletions(state),
      pendingResourceUpdates: getAllPendingUpdates(state),
      initialUnappliedPendingCreationsMap: state.dataLibrary.initialUnappliedPendingCreations,
      pendingCommitError: state.dataLibrary.pendingCommitError,
      currentUser: state.currentUser,
      branchCommits: state.dataLibrary.branchCommits,
      teamData: state.teamData.data,
      directLoadedResource: state.dataLibrary.directLoadedResource,
      inProgressDirectFetchPaths: state.dataLibrary.inProgressDirectFetchPaths,
      baseFolders: getBaseFoldersForBranch(state, state.dataLibrary.currentBranch),
      hasFinishedDirectPathsFetch: state.dataLibrary.hasFinishedDirectPathsFetch,
    };
  }, shallowEqual);

  useEffect(() => {
    if (!RD.isIdle(currentBranch)) {
      return;
    }

    dispatch(listBranchesThunk());
  }, [currentBranch, dispatch]);

  useEffect(() => {
    if (!teamData) {
      dispatch(fetchUserTeam());
    }
  }, [dispatch, teamData]);

  useEffect(() => {
    if (
      !RD.isSuccess(directLoadedResource) ||
      !RD.isSuccess(currentBranch) ||
      hasFinishedDirectPathsFetch
    ) {
      return;
    }

    const allInitialPendingCreationPaths = new Set(
      Array.from(initialUnappliedPendingCreationsMap.values()).map(
        (pendingCreation) => pendingCreation.resource.path ?? '',
      ),
    );
    const allPathsUpToDirectLoadedResource = getAllPathsUpToPath(
      directLoadedResource.data.path ?? '',
    );
    if (directLoadedResource.data['@type'] === COMPUTED_VIEW_TYPE) {
      // Do not fetch a computed view's contents since that should just be itself and we do not
      // need to fetch those details (they're provided by the parent folder).
      allPathsUpToDirectLoadedResource.splice(-1, 1);
    }
    // Do not attempt to fetch paths that are pending creation since they do not exist on the
    // server.
    const pathsToFetch = allPathsUpToDirectLoadedResource.filter(
      (path) => !allInitialPendingCreationPaths.has(path),
    );
    dispatch(setInProgressDirectFetchPaths(new Set(pathsToFetch)));
    const expandedFoldersState = new Map<string, boolean>();
    allPathsUpToDirectLoadedResource.forEach((path) => expandedFoldersState.set(path, true));
    dispatch(setFoldersExpansionState(expandedFoldersState));
    for (const path of pathsToFetch) {
      dispatch(
        listBranchContentThunk({
          id: currentBranch.data.id ?? '',
          path,
          resourceType: ItemType.FOLDER,
        }),
      );
    }
  }, [
    directLoadedResource,
    currentBranch,
    hasFinishedDirectPathsFetch,
    initialUnappliedPendingCreationsMap,
    dispatch,
  ]);

  if (RD.isError(currentBranch)) {
    return (
      <div className={sprinkles({ flexItems: 'center', parentContainer: 'fill' })}>
        <FetchErrorComponent
          disabled={false}
          errorMessage="Error fetching branch data"
          onRetry={() => dispatch(listBranchesThunk())}
        />
      </div>
    );
  }

  const urlResourceId = params.resourceId;
  if (
    RD.getOrDefault(currentResource, null)?.id !== urlResourceId &&
    RD.isSuccess(currentBranch) &&
    RD.isIdle(directLoadedResource)
  ) {
    // If we're in a cold start state where no portion of the data library is loaded and we're
    // attempting to load a particular resource, fetch the details about the particular resource so
    // that we can get the path and issue requests to get the resources along the path to the
    // requested resource.
    const unappliedPendingCreationToLoad = initialUnappliedPendingCreationsMap.get(urlResourceId);
    if (unappliedPendingCreationToLoad) {
      dispatch(setDirectLoadedResource(unappliedPendingCreationToLoad.resource));
    } else {
      dispatch(
        getResourceOnBranchThunk({
          branchId: currentBranch.data.id ?? '',
          resourceId: urlResourceId,
        }),
      );
    }
    return <ExploLoadingSpinner />;
  }

  if (
    inProgressDirectFetchPaths.size > 0 ||
    !RD.isSuccess(currentBranch) ||
    RD.isLoading(directLoadedResource) ||
    !RD.isSuccess(currentResource)
  ) {
    return <ExploLoadingSpinner />;
  }

  const isFolder = currentItemPath.type === FOLDER_TYPE;

  const hasPendingChanges =
    pendingResourceCreations.length > 0 ||
    pendingResourceDeletions.length > 0 ||
    pendingResourceUpdates.length > 0;
  return (
    <>
      <DirectoryPanel
        renderCommitButton
        shouldReadFromHead
        commitButtonEnabled={hasPendingChanges}
        onCommitButtonClicked={() => setOpenModalType(ModalType.CREATE_COMMIT)}
        onViewCommitHistoryButtonClicked={() => setOpenModalType(ModalType.VIEW_COMMIT_HISTORY)}
      />
      {RD.isSuccess(currentResource) ? isFolder ? <FolderPage /> : <DatasetPage /> : null}
      {RD.isSuccess(currentBranch) ? (
        <CreateCommitModal
          baseFolders={baseFolders}
          currentBranch={currentBranch.data}
          currentItemPath={currentItemPath}
          currentUser={currentUser}
          isOpen={openModalType === ModalType.CREATE_COMMIT}
          onClose={() => setOpenModalType(ModalType.NONE)}
          pendingResourceCreations={pendingResourceCreations}
          pendingResourceDeletions={pendingResourceDeletions}
          pendingResourceUpdates={pendingResourceUpdates}
          teamData={teamData}
        />
      ) : null}
      {RD.isSuccess(currentBranch) ? (
        <CommitHistoryModal
          branchCommits={branchCommits}
          currentBranch={currentBranch.data}
          isOpen={openModalType === ModalType.VIEW_COMMIT_HISTORY}
          onClose={() => setOpenModalType(ModalType.NONE)}
          teamData={teamData}
        />
      ) : null}
      {pendingCommitError && pendingCommitError.message === NEWER_COMMIT_ERROR_MESSAGE ? (
        <AlertModal
          actionButtonProps={{
            variant: 'primary',
            text: 'Refresh',
          }}
          isOpen={true}
          onClose={() => {
            history.push(ROUTES.DATA_LIBRARY);
            window.location.reload();
          }}
          title={
            'There is a more recent commit that has been made. Please refresh your page to get latest changes'
          }
        />
      ) : null}
    </>
  );
};
