import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateVisualizeOperation,
  updateVisualizeOperationThunk,
} from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { CollapsibleGroup } from 'components/CollapsibleGroup';
import { BooleanFormatConfig } from 'components/ColumnFormatConfigs/BooleanFormatConfig';
import { DateFormatConfig } from 'components/ColumnFormatConfigs/DateFormatConfig';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { GradientConfiguration } from 'components/ColumnFormatConfigs/NumberFormatConfig/GradientConfiguration';
import { ProgressBarConfiguration } from 'components/ColumnFormatConfigs/NumberFormatConfig/ProgressBarConfiguration';
import { StringFormatConfig } from 'components/ColumnFormatConfigs/StringFormatConfig';
import { Input, Select, sprinkles, Switch } from 'components/ds';

import { MultiColumnSortingConfig } from '../../MultiColumnSortingConfig';

import {
  BaseCol,
  BOOLEAN,
  BooleanDisplayOptions,
  DatasetRow,
  DatasetSchema,
  DATE_TYPES,
  DateDisplayOptions,
  NUMBER_TYPES,
  NumberDisplayOptions,
  OPERATION_TYPES,
  SchemaChange,
  SchemaDisplayOption,
  STRING,
  STRING_FORMATS,
  StringDisplayOptions,
} from '@explo/data';
import { VisualizePivotTableInstructions, VisualizeTableInstructions } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { ReduxState } from 'reducers/rootReducer';
import { isJoinConfigReady } from 'utils/joinTableUtils';
import { sortSchemaByOrderedColumnNames } from 'utils/tableSchemaUtils';
import { EnrichColumnConfiguration } from './EnrichColumnConfiguration';

type Props = {
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  originalSchema: DatasetSchema;
  schema: DatasetSchema;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: DatasetRow[];
  visualizationType: OPERATION_TYPES;
  globalStyleConfig: GlobalStyleConfig;
};

const stringFormatOptions = Object.values(STRING_FORMATS).map((formatOption) => ({
  value: formatOption,
}));

/**
 * Configs for all of the columns for a data table
 */
export const TableColumnsConfig: FC<Props> = ({
  instructions,
  originalSchema,
  schema,
  dashboardDatasets,
  dataPanelData,
  visualizationType,
  globalStyleConfig,
}) => {
  const dispatch = useDispatch();

  const newGridEnabled = useSelector(
    (state: ReduxState) => !!state.currentUser?.team?.feature_flags.enable_new_grid,
  );

  const orderedColumnNames = instructions.orderedColumnNames;
  const orderedSchema = sortSchemaByOrderedColumnNames(schema, orderedColumnNames);
  const operationType =
    visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ||
    visualizationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
      ? OPERATION_TYPES.VISUALIZE_TABLE
      : OPERATION_TYPES.VISUALIZE_PIVOT_TABLE;

  // this is a table only operation so it doesn't have to include pivot tables
  const updateInstructions = (
    instructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
    updateColors?: boolean,
  ) => {
    dispatch(updateVisualizeOperationThunk(instructions, operationType, updateColors));
  };

  const updateFirstColumnTitle = (title: string) => {
    const newInstructions = produce(instructions as VisualizePivotTableInstructions, (draft) => {
      draft.firstColumnTitle = title;
    });

    updateInstructions(newInstructions);
  };

  const renderPivotColumnsConfig = () => {
    const pivotInstructions = instructions as VisualizePivotTableInstructions;
    const firstColumnTitle = pivotInstructions?.firstColumnTitle;
    const originalFirstColumnTitle = pivotInstructions?.rowColumn?.column.name;

    const selectedStringFormat = pivotInstructions?.stringFormat?.format || STRING_FORMATS.DEFAULT;

    const updateStringFormat = (stringFormatUpdates?: {
      format?: STRING_FORMATS;
      replaceUnderscores?: boolean;
    }) => {
      const newInstructions = produce(pivotInstructions, (draft) => {
        draft.stringFormat = {
          ...pivotInstructions?.stringFormat,
          ...stringFormatUpdates,
        };
      });

      dispatch(updateVisualizeOperation(newInstructions, visualizationType));
    };
    return (
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
        <div className={sprinkles({ body: 'section', color: 'contentPrimary' })}>Column Names</div>
        <Select
          label="String Format"
          onChange={(value) => updateStringFormat({ format: value as STRING_FORMATS })}
          placeholder="Select a format"
          selectedValue={selectedStringFormat}
          values={stringFormatOptions}
        />
        <Switch
          label="Remove Underscores"
          onChange={() =>
            updateStringFormat({
              replaceUnderscores: !pivotInstructions?.stringFormat?.replaceUnderscores,
            })
          }
          switchOn={pivotInstructions?.stringFormat?.replaceUnderscores}
        />
        <Input
          showInputButton
          defaultValue={firstColumnTitle ?? originalFirstColumnTitle}
          label="First Column Name"
          onSubmit={updateFirstColumnTitle}
        />
      </div>
    );
  };

  return (
    <div className={sprinkles({ padding: 'sp1.5' })}>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
        {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ? (
          <Switch
            label={{
              text: 'Ignore invalid dates',
              infoText: "Replace 'Invalid Date' with empty value",
            }}
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.ignoreInvalidDates = !draft.ignoreInvalidDates;
              });

              updateInstructions(newInstructions);
            }}
            switchOn={instructions.ignoreInvalidDates}
          />
        ) : null}
        <Switch
          disabled={instructions.shouldVisuallyGroupByFirstColumn}
          label="Sorting"
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.isColumnSortingDisabled = !draft.isColumnSortingDisabled;
              if (draft.isColumnSortingDisabled) {
                draft.defaultSortedColumn = undefined;
              }
            });

            updateInstructions(newInstructions);
          }}
          switchOn={!instructions.isColumnSortingDisabled}
        />
        {!instructions.isColumnSortingDisabled ? (
          <>
            {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ? (
              <Switch
                disabled={instructions.shouldVisuallyGroupByFirstColumn}
                label="Sort descending first when clicked"
                onChange={() => {
                  const newInstructions = produce(instructions, (draft) => {
                    draft.isInitialSortDesc = !draft.isInitialSortDesc;
                  });

                  updateInstructions(newInstructions);
                }}
                switchOn={!!instructions.isInitialSortDesc}
              />
            ) : null}
            <MultiColumnSortingConfig
              instructions={instructions}
              operationType={operationType}
              schema={schema}
            />
          </>
        ) : null}
      </div>

      {orderedSchema.map((col) => (
        <TableColumnConfig
          column={col}
          dashboardDatasets={dashboardDatasets}
          dataPanelData={dataPanelData}
          globalStyleConfig={globalStyleConfig}
          instructions={instructions}
          isPivotTable={visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE}
          key={col.name}
          newGridEnabled={newGridEnabled}
          originalSchema={originalSchema}
          updateInstructions={updateInstructions}
        />
      ))}
      {visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
        ? renderPivotColumnsConfig()
        : null}
    </div>
  );
};

type TableColumnConfigProps = {
  column: BaseCol;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  isPivotTable: boolean;
  updateInstructions: (
    instructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
    updateColors?: boolean, // If true, update color category tracker
  ) => void;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: DatasetRow[];
  originalSchema: DatasetSchema;
  globalStyleConfig: GlobalStyleConfig;
  newGridEnabled: boolean;
};

const TableColumnConfig: FC<TableColumnConfigProps> = ({
  column,
  instructions,
  updateInstructions,
  isPivotTable,
  dataPanelData,
  dashboardDatasets,
  originalSchema,
  globalStyleConfig,
  newGridEnabled,
}) => {
  const columnConfig = instructions.schemaDisplayOptions?.[column.name];

  const schemaChangeIndex =
    instructions.changeSchemaList?.findIndex((schemaChange) => schemaChange.col === column.name) ??
    -1;
  const schemaChange =
    schemaChangeIndex >= 0 ? instructions.changeSchemaList?.[schemaChangeIndex] : undefined;

  const columnDisplayName = column.friendly_name || column.name;

  const updateDisplayOptions = (displayOptions: SchemaDisplayOption, updateColors?: boolean) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.schemaDisplayOptions) draft.schemaDisplayOptions = {};
      draft.schemaDisplayOptions[column.name] = {
        ...draft.schemaDisplayOptions[column.name],
        ...displayOptions,
      };
    });
    updateInstructions(newInstructions, updateColors);
  };

  let columnToDisplayType = column.type;

  // @ts-ignore
  if (columnConfig && isJoinConfigReady(columnConfig) && columnConfig.joinDisplayColumn) {
    // @ts-ignore
    columnToDisplayType = columnConfig.joinDisplayColumn.column.type;
  }

  const updateChangeSchemaList = (updates: Partial<SchemaChange>) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.changeSchemaList) draft.changeSchemaList = [];

      if (schemaChangeIndex !== -1) {
        draft.changeSchemaList[schemaChangeIndex] = {
          ...draft.changeSchemaList[schemaChangeIndex],
          ...updates,
        };
      } else {
        draft.changeSchemaList.push({
          col: column.name,
          newColName: column.friendly_name,
          keepCol: true,
          hideCol: false,
          ...updates,
        });
      }
    });

    updateInstructions(newInstructions, false);
  };

  const renderDivider = () => (
    <div className={sprinkles({ backgroundColor: 'outline' })} style={{ height: 1 }} />
  );

  return (
    <CollapsibleGroup className={sprinkles({ marginY: 'sp1.5' })} title={columnDisplayName}>
      <div className={sprinkles({ paddingX: 'sp1.5', paddingTop: 'sp1.5' })}>
        <Input
          showInputButton
          defaultValue={columnDisplayName}
          label={{ text: `Column title (${column.name})`, variableInput: true }}
          onSubmit={(newColName) => {
            if (newColName.trim() === '') newColName = column.name;
            updateChangeSchemaList({ newColName });
          }}
        />
        <Switch
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Tooltip"
          onChange={() => updateChangeSchemaList({ showTooltip: !schemaChange?.showTooltip })}
          switchOn={schemaChange?.showTooltip}
        />
        {schemaChange?.showTooltip ? (
          <Input
            showInputButton
            className={sprinkles({ marginTop: 'sp.5' })}
            defaultValue={schemaChange?.tooltipText}
            onSubmit={(newText) => updateChangeSchemaList({ tooltipText: newText })}
          />
        ) : null}
        <EnrichColumnConfiguration
          column={column}
          dashboardDatasets={dashboardDatasets}
          instructions={instructions}
          updateInstructions={updateInstructions}
        />
      </div>

      {
        // @ts-ignore
        !columnConfig?.joinOn || isJoinConfigReady(columnConfig) ? (
          <>
            <div className={sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1.5' })}>
              {columnToDisplayType === BOOLEAN && (
                <BooleanFormatConfig
                  column={column}
                  displayOptions={columnConfig as BooleanDisplayOptions}
                  globalStyleConfig={globalStyleConfig}
                  isNewDataGrid={newGridEnabled}
                  updateBooleanOptions={updateDisplayOptions}
                />
              )}
              {columnToDisplayType === STRING && (
                <StringFormatConfig
                  column={column}
                  dataPanelData={dataPanelData}
                  displayOptions={columnConfig as StringDisplayOptions}
                  globalStyleConfig={globalStyleConfig}
                  originalSchema={originalSchema}
                  updateStringOptions={updateDisplayOptions}
                />
              )}
              {DATE_TYPES.has(columnToDisplayType) && (
                <DateFormatConfig
                  column={column}
                  displayOptions={columnConfig as DateDisplayOptions}
                  operationType={OPERATION_TYPES.VISUALIZE_TABLE}
                  updateDateOptions={updateDisplayOptions}
                />
              )}
              {NUMBER_TYPES.has(columnToDisplayType) && (
                <NumberFormatConfig
                  column={column}
                  displayOptions={columnConfig as NumberDisplayOptions}
                  operationType={OPERATION_TYPES.VISUALIZE_TABLE}
                  updateNumberOptions={updateDisplayOptions}
                />
              )}
            </div>
            {NUMBER_TYPES.has(columnToDisplayType) ? (
              <>
                {renderDivider()}
                <div className={sectionClass}>
                  <ProgressBarConfiguration
                    displayOptions={columnConfig as NumberDisplayOptions}
                    operationType={OPERATION_TYPES.VISUALIZE_TABLE}
                    originalSchema={originalSchema}
                    updateNumberOptions={updateDisplayOptions}
                  />
                </div>
                {isPivotTable ? undefined : (
                  <>
                    {renderDivider()}
                    <div className={sectionClass}>
                      <GradientConfiguration
                        displayOptions={columnConfig as NumberDisplayOptions}
                        updateNumberOptions={updateDisplayOptions}
                      />
                    </div>
                  </>
                )}
              </>
            ) : null}
          </>
        ) : null
      }
    </CollapsibleGroup>
  );
};

const sectionClass = sprinkles({ padding: 'sp1.5' });
