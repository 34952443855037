import { FC, useEffect, useState, useMemo } from 'react';

import { DatasetRow, DatasetSchema, DatabaseUnsupportedOperations } from '@explo/data';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { NonIdealState } from 'components/ChartLayout/NonIdealState';
import { DataGrid, Icon, sprinkles } from 'components/ds';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { ColumnColorTracker } from 'constants/types';

type Props = {
  currentQuery: string;
  dataset?: ReportBuilderDataset;
  error: string | undefined;
  isLoading: boolean;
  rows: DatasetRow[] | undefined;
  rowCount: number | undefined;
  schema: DatasetSchema | undefined;
  handlePageChange: (offset: number) => void;
  unsupportedOperations?: DatabaseUnsupportedOperations[];
  colorTracker?: ColumnColorTracker;
};

export const DatasetPreviewTable: FC<Props> = ({
  currentQuery,
  error,
  dataset,
  isLoading,
  rowCount,
  rows,
  schema,
  unsupportedOperations,
  handlePageChange,
  colorTracker,
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    // Reset page data when query changes
    setPageNumber(1);
  }, [currentQuery]);

  const paginatorProps: PaginatorProps = useMemo(() => {
    return {
      totalRowCount: rowCount,
      currentPage: pageNumber,
      isPagingDisabled: isPagingDisabled(unsupportedOperations),
      loading: isLoading,
      goToPage: ({ page }) => {
        handlePageChange(page);
        setPageNumber(page);
      },
    };
  }, [rowCount, pageNumber, handlePageChange, unsupportedOperations, isLoading]);

  if (error) {
    return (
      <NonIdealState
        className={sprinkles({ justifyContent: 'flex-start' })}
        description={error}
        icon={
          <div className={sprinkles({ flexItems: 'alignCenter' })}>
            <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="lg" />
          </div>
        }
        title="There was a problem with your query"
      />
    );
  }

  if (currentQuery.trim() === '') {
    return (
      <NonIdealState
        description="The resulting table will show up here once you write some SQL"
        icon="console"
        title="Write a query to get started"
      />
    );
  }

  if ((!rows || !schema) && !isLoading) {
    // they have not run the preview for the query
    return (
      <NonIdealState
        description="This will run your query but not save it to the dataset. To save, press the save button."
        icon="eye-open"
        title="Click the preview button"
      />
    );
  }

  return (
    <div className={sprinkles({ flexItems: 'column', flex: 1, overflow: 'hidden' })}>
      <DataGrid
        colorTracker={colorTracker}
        columnConfigs={dataset?.columnConfigs}
        loading={isLoading}
        paginatorProps={paginatorProps}
        rows={rows}
        schema={schema}
      />
    </div>
  );
};
