import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import {
  updateGeneralFormatOptions,
  updateVisualizeOperation,
} from 'actions/dataPanelConfigActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { SettingHeader } from 'components/SettingHeader';
import { Switch, Input, Label, TextArea } from 'components/ds';
import {
  V2GeneralInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  V2ScatterPlotInstructions,
  VisualizeTableInstructions,
  VisualizePivotTableInstructions,
  BaseTableInstructions,
  VisualizeCollapsibleListInstructions,
  V2BoxPlotInstructions,
  HeaderConfig,
  VisualizeGeospatialChartInstructions,
} from 'constants/types';
import { TEXT_SIZE_OFFSET_MAP, getCategoricalColors } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { isNumberTrendTextPanelVisualizationType } from 'pages/dashboardPage/charts/utils/trendUtils';
import { updateEditableSectionChartInfo } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  isEditableSection: boolean | undefined;
  globalStyleConfig: GlobalStyleConfig;
  options: VisualizeOperationGeneralFormatOptions;
  visualizationType: OPERATION_TYPES;
  instructions:
    | V2TwoDimensionChartInstructions
    | V2KPIChartInstructions
    | V2KPITrendInstructions
    | V2ScatterPlotInstructions
    | V2BoxPlotInstructions
    | VisualizeTableInstructions
    | VisualizePivotTableInstructions
    | VisualizeCollapsibleListInstructions
    | V2PivotTableInstructions
    | VisualizeGeospatialChartInstructions;
};

export const VisualizationHeaderConfig: FC<Props> = ({
  isEditableSection,
  options,
  visualizationType,
  instructions,
}) => {
  const dispatch = useDispatch();

  const updateHeaderConfig = (updates: Partial<HeaderConfig>) => {
    const newOptions = produce(options, (draft) => {
      draft.headerConfig = { ...draft.headerConfig, ...updates };
    });
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateGeneralInstructions = (updates: V2GeneralInstructions) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.generalFormat = { ...draft.generalFormat, ...updates };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const defaultFontSize = String(
    globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['kpiTitle'],
  );

  const renderTrendOptions = () => {
    const trendInstructions = instructions as V2KPITrendInstructions;
    if (!isNumberTrendTextPanelVisualizationType(visualizationType, trendInstructions)) {
      return null;
    }

    return (
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={trendInstructions.titleFormat?.fontSize?.toString() ?? defaultFontSize}
        label="Title Font Size"
        onSubmit={(newValue) => {
          const intValue = parseInt(newValue);
          const newInstructions = produce(trendInstructions, (draft) => {
            draft.titleFormat = {
              ...draft.titleFormat,
              fontSize: intValue > 0 ? intValue : undefined,
            };
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
      />
    );
  };

  const renderTableOptions = () => {
    if (
      visualizationType !== OPERATION_TYPES.VISUALIZE_TABLE &&
      visualizationType !== OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
    )
      return null;

    const baseTableInstructions = instructions as BaseTableInstructions;
    return (
      <>
        <Switch
          className={configInputClass}
          label="Filtering"
          onChange={() => {
            const newInstructions = produce(baseTableInstructions, (draft) => {
              draft.isFilteringDisabled = !draft.isFilteringDisabled;
            });
            dispatch(updateVisualizeOperation(newInstructions, visualizationType));
          }}
          switchOn={!baseTableInstructions.isFilteringDisabled}
        />
        {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ? (
          <Switch
            className={configInputClass}
            disabled={baseTableInstructions.shouldVisuallyGroupByFirstColumn}
            label={{
              text: 'Editing columns',
              infoText:
                "This will be disabled if 'Group rows visually by first column' is enabled.",
            }}
            onChange={() => {
              const newInstructions = produce(
                instructions as VisualizeTableInstructions,
                (draft) => {
                  draft.isSchemaCustomizationEnabled = !draft.isSchemaCustomizationEnabled;
                },
              );

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={(instructions as VisualizeTableInstructions).isSchemaCustomizationEnabled}
          />
        ) : null}
      </>
    );
  };

  const renderKpiOptions = () => {
    if (
      visualizationType !== OPERATION_TYPES.VISUALIZE_NUMBER_V2 &&
      visualizationType !== OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    )
      return null;
    const { primaryColor, overrides } = globalStyleConfig.text;
    const kpiInstructions = instructions as V2KPIChartInstructions;

    const titleColor = kpiInstructions.generalFormat?.titleColor;

    const setTitleColor = (titleColor: string | undefined) => {
      const newInstructions = produce(kpiInstructions, (draft) => {
        draft.generalFormat = { ...draft.generalFormat, titleColor };
      });
      dispatch(updateVisualizeOperation(newInstructions, visualizationType));
    };

    const trendInstructions = instructions as V2KPITrendInstructions;

    return (
      <>
        <div className={configInputClass}>
          <Label htmlFor="">Title Color</Label>
          <ColorPickerButton
            fillWidth
            color={titleColor || overrides.kpiTitle?.color || primaryColor}
            colorPalette={getCategoricalColors(globalStyleConfig)}
            onCancel={titleColor ? () => setTitleColor(undefined) : undefined}
            onColorChange={setTitleColor}
          />
        </div>
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={trendInstructions.titleFormat?.fontSize?.toString() ?? defaultFontSize}
          label="Title Font Size"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            const newInstructions = produce(trendInstructions, (draft) => {
              draft.titleFormat = {
                ...draft.titleFormat,
                fontSize: intValue > 0 ? intValue : undefined,
              };
            });
            dispatch(updateVisualizeOperation(newInstructions, visualizationType));
          }}
        />
      </>
    );
  };

  return (
    <div>
      <SettingHeader
        name="Header"
        switchProps={{
          isOn: !options.headerConfig?.isHeaderHidden,
          onChange: () =>
            updateHeaderConfig({ isHeaderHidden: !options.headerConfig?.isHeaderHidden }),
        }}
      />
      {!options.headerConfig?.isHeaderHidden ? (
        <div className={configRootClass}>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={options.headerConfig?.title}
            label={{ text: 'Title', variableInput: true }}
            onSubmit={(newValue) =>
              isEditableSection
                ? dispatch(updateEditableSectionChartInfo({ name: newValue }))
                : updateHeaderConfig({ title: newValue })
            }
          />

          {renderTrendOptions()}
          {renderTableOptions()}
          {renderKpiOptions()}
          <Switch
            className={configInputClass}
            label="Descriptive Tooltip"
            onChange={() =>
              updateGeneralInstructions({ showTooltip: !instructions.generalFormat?.showTooltip })
            }
            switchOn={instructions.generalFormat?.showTooltip}
          />
          {instructions.generalFormat?.showTooltip ? (
            <TextArea
              className={configInputClass}
              defaultValue={instructions.generalFormat?.tooltipText || ''}
              label={{ text: 'Tooltip Text', variableInputLabel: true }}
              onSubmit={(newValue) => updateGeneralInstructions({ tooltipText: newValue })}
              placeholder=""
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
