import { DateTime } from 'luxon';

import {
  BaseCol,
  ColumnConfigs,
  DatasetRow,
  DATE_TYPES,
  DATETIME_PIVOT_AGGS_SET,
  GroupByCol,
  SortOrder,
} from '@explo/data';

import { getAxisNumericalValue } from 'pages/dashboardPage/charts/utils';

import { TABLE_SUMMARY, renderValue } from './pivotUtils';

/**
 * File Description
 *
 * This file contains any function that helps with the data side (over the rendering side) or
 * transforming pivot table data from our endpoint so that it can be fed into react data grid
 * or produce side effect data structures to help with inserting summary data properly.
 *
 * The two main functions in this file are extractSummaryRows and  ensureProperPivotColumnSorting
 * while everything else is a helper for one of those two.
 *
 * The main idea is that when we receive raw pivot table data we need to
 * 1. Remove a subset of potential summary data that comes in and store it in a map, so that it
 * can later be reinserted in either the footer or specific cells related to sub group summaries.
 * 2. Discover all the possible pivot column values and process the data by inserting fake blank
 * data so that react data grid will sort the pivot values correctly in the case of sparse initial data
 */

/**
 * Identifies and separates summary rows from the main dataset rows.
 * Note: Currently, there might be issues distinguishing between null data and summary data, but an API fix is forthcoming.
 *
 * When a row is missing a column name that is part of the group-by columns, it indicates that the row is a summary row for that group.
 * The key in the `keyPathToSummary` mapping corresponds to the path in the pivot table where the summary value should be rendered.
 * The value is the summary row itself.
 *
 * Summary rows are excluded from the original dataset rows to prevent the pivot table from automatically adding a blank row.
 * Storing the summary row in the mapping allows us to override leaf cells with the correct summary information.
 *
 * @param datasetRows - Array of dataset rows to process.
 * @param nameSchemaMap - Mapping of column names to their schema definitions.
 * @param groupByColumns - Array of column names to group by.
 * @param columnConfigs - Configuration for columns.
 * @returns An object containing `keyPathToSummary` and filtered `rows`.
 */
export const extractSummaryRows = (
  datasetRows: DatasetRow[],
  nameSchemaMap: Record<string, BaseCol>,
  groupByColumns: string[],
  columnConfigs: ColumnConfigs,
): { keyPathToSummary: Record<string, DatasetRow>; rows: DatasetRow[] } => {
  const rows: DatasetRow[] = [];
  const keyPathToSummary: Record<string, DatasetRow> = {};
  datasetRows.forEach((row) => {
    const missingGroupBys = groupByColumns.find((col) => row[col] === undefined);
    if (missingGroupBys) {
      const summaryKey = generateSummaryKey(row, groupByColumns, nameSchemaMap, columnConfigs);
      keyPathToSummary[summaryKey] = row;
    } else {
      rows.push(row);
    }
  });

  return { keyPathToSummary, rows };
};

export const generateSummaryKey = (
  row: DatasetRow,
  groupByColumns: string[],
  nameSchemaMap: Record<string, BaseCol>,
  columnConfigs: ColumnConfigs,
) => {
  // Last group by doesn't have any children rows so it doesn't have any summary rows
  const summarizableColumns = groupByColumns.slice(0, -1);
  const summaryKeyList: string[] = [];
  summarizableColumns.forEach((col) => {
    const value = row[col];
    const schemaCol = nameSchemaMap[col];
    if (value == null || !schemaCol) return;

    const transformedValue = renderValue(value.toString(), schemaCol, columnConfigs[col]);
    summaryKeyList.push(transformedValue.toString());
  });

  // If summary key is empty it means there are no row keys and this row is the overall table summary
  // TODO: Will be fixed when Fido passes nulls properly
  return summaryKeyList.join('-') || TABLE_SUMMARY;
};

/**
 * Retrieves and sorts all unique values from a specified pivot column.
 *
 * This function iterates over all rows to collect all unique values from the pivot column.
 * It then sorts these values, handling different data types appropriately:
 * - Dates are sorted chronologically if the column type and bucket indicate a date type.
 * - Numbers and strings are sorted in their natural order.
 * - Null or invalid values are moved to the end of the sorted array.
 *
 */
export const getSortedPivotedColumnValues = (
  rows: DatasetRow[],
  pivotColumn: string,
  colGroupBy: GroupByCol,
  sortOrder: SortOrder,
) => {
  const pivotedColumnValues = new Set(rows.map((row) => row[pivotColumn]));
  const sortedPivotedColumnValues = Array.from(pivotedColumnValues).sort((a, b) => {
    // Sort null values to the end
    if (a == null) return 1;
    if (b == null) return -1;

    if (
      DATE_TYPES.has(colGroupBy.column.type) &&
      colGroupBy.bucket &&
      DATETIME_PIVOT_AGGS_SET.has(colGroupBy.bucket.id) &&
      typeof a === 'string' &&
      typeof b === 'string'
    ) {
      const dateA = DateTime.fromISO(a);
      const dateB = DateTime.fromISO(b);
      if (dateA.isValid && dateB.isValid) {
        return sortOrder === SortOrder.DESC
          ? dateB.toMillis() - dateA.toMillis()
          : dateA.toMillis() - dateB.toMillis();
      }
      if (!dateA.isValid) return 1;
      if (!dateB.isValid) return -1;
    }

    // Convert elements to comparable values
    const numA = getAxisNumericalValue(a);
    const numB = getAxisNumericalValue(b);

    if (!isNaN(numA) && !isNaN(numB)) {
      return sortOrder === SortOrder.DESC ? numB - numA : numA - numB;
    }

    if (typeof a === 'string' && typeof b === 'string') {
      return sortOrder === SortOrder.DESC ? b.localeCompare(a) : a.localeCompare(b);
    }

    // If either numbers end up NaN, move them to the end
    if (isNaN(numA)) return 1;
    if (isNaN(numB)) return -1;

    return 0;
  });

  return sortedPivotedColumnValues;
};

/**
 * Creates a new first table row with all sorted pivot columns.
 *
 * Definition: Table Row is an array of data rows that correspond to the first visual pivot table row.
 *
 * This function generates a map of pivot values to their corresponding data rows from the
 * original first table row. It then iterates over sortedPivotedColumnValues to construct a
 * new table row that includes all sorted pivot columns in their correct order. Columns
 * with missing data are marked by nulls (represented by missing aggregation keys).
 *
 * Example: Given sortedPivotedColumnValues ['January', 'February', 'October'] and the first table row
 * containing {id: 1, month: 'October', some agg}, the newTableFirstRow will include:
 * {id: 1, month: 'January'}, {id: 1, month: 'February'}, {id: 1, month: 'October', some agg}.
 *
 */
export const createNewTableFirstRow = (
  sortedPivotedColumnValues: (string | number)[],
  rows: DatasetRow[],
  groupByColumns: string[],
  pivotColumn: string,
  aggregationKeys: string[],
) => {
  // Only first table row so we know know which pivot values are already defined versus should be null
  const pivotValueToRow: Record<string | number, DatasetRow> = {};

  const firstRow = rows[0];
  // Loop over first table row, will break when row instructions no longer match first data row
  for (const currentRow of rows) {
    const isFirstTableRow = groupByColumns.every((col) => currentRow[col] === firstRow[col]);
    if (!isFirstTableRow) break;

    pivotValueToRow[currentRow[pivotColumn]] = currentRow;
  }

  const newTableFirstRow: DatasetRow[] = sortedPivotedColumnValues.map((pivotValue) => {
    const row = pivotValueToRow[pivotValue];
    if (row) return row;

    const rowTemplate = { ...firstRow, [pivotColumn]: pivotValue };
    aggregationKeys.forEach((key) => delete rowTemplate[key]);

    return rowTemplate;
  });

  return { numRowsToReplace: Object.keys(pivotValueToRow).length, newTableFirstRow };
};

/**
 * Ensures proper sorting of pivot table columns with sparse data.
 *
 * This function processes rows of data representing cells or groups of cells at the intersection
 * of pivot table rows and columns. When data is sparse, since only rows with populated cells
 * are received, this can lead to incorrectly sorted pivot columns. React Data Grid sorts columns
 * based on this sparse data, primarily sorted by rows.
 *
 * Example: consider a table where the configured rows are id and the pivot column is month. If our
 * sparse data is {id: 1, month: "October", some agg}, {id: 2, month: "January", some agg}, and so on.
 * Then React Data Grid will imply the pivot column sort to be October then January.
 *
 * To address this issue, we extract all potential pivot column values, sort them, and create a new
 * first table row that includes all columns, but the missing data rows will have no agg.
 *
 * Definition: Table Row is an array of data rows that correspond to the first visual pivot table row.
 *
 * Continued Example: replace {id: 1, month: "October", some agg} with
 * {id: 1, month: "January"},  {id: 1, month: "February"},... ,{id: 1, month: "October", some agg},...
 *
 * This new first table row helps React Data Grid maintain correct column sorting.
 */

export const ensureProperPivotColumnSorting = (
  rows: DatasetRow[],
  pivotColumn: string,
  colGroupBy: GroupByCol,
  nameSchemaMap: Record<string, BaseCol>,
  groupByColumns: string[],
  sortOrder: SortOrder,
) => {
  const sortedPivotedColumnValues = getSortedPivotedColumnValues(
    rows,
    pivotColumn,
    colGroupBy,
    sortOrder,
  );

  const aggregationKeys = Object.keys(nameSchemaMap).filter(
    (key) => !groupByColumns.includes(key) && key !== pivotColumn,
  );

  const { numRowsToReplace, newTableFirstRow } = createNewTableFirstRow(
    sortedPivotedColumnValues,
    rows,
    groupByColumns,
    pivotColumn,
    aggregationKeys,
  );

  return [...newTableFirstRow, ...rows.slice(numRowsToReplace)];
};
