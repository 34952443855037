import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { SchemaDataType } from '@explo/data';

import { Button, sprinkles } from 'components/ds';
import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { SchemaDataTypeIcon } from 'constants/dataConstants';
import { deleteDashboardParam } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getDashboardEditConfigWithDrilldowns } from 'reducers/selectors';
import { updateVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { sortBy } from 'utils/standard';

import { ManageCustomVariableModal } from './ManageCustomVariableModal';
import { ValueElement } from './ValueElement';

type Props = {
  variables: DashboardVariableMap;
  searchQuery: string;
  createNewParam: () => DashboardParam;
  readOnly?: boolean;
};

export const CustomVariablesSection: FC<Props> = ({
  variables,
  searchQuery,
  createNewParam,
  readOnly,
}) => {
  const dispatch = useDispatch();

  // Null means its creating a new param
  const [editingParam, setEditingParam] = useState<DashboardParam | null | undefined>();

  const { customParams } = useSelector(
    (state: ReduxState) => ({
      customParams: getDashboardEditConfigWithDrilldowns(state)?.params ?? {},
    }),
    shallowEqual,
  );

  const orderedParams = useMemo(
    () =>
      sortBy(
        Object.values(customParams).filter((param) =>
          param.name.toLowerCase().includes(searchQuery),
        ),
        (param) => param.name.toLowerCase(),
      ),
    [customParams, searchQuery],
  );

  const updateVariable = (varName: string, newValue: DashboardVariable) =>
    dispatch(updateVariableThunk({ varName, newValue }));

  const renderRightElement = ({ type, name }: DashboardParam, value: DashboardVariable) => {
    if (readOnly) return JSON.stringify(value);
    return <ValueElement name={name} type={type} updateVariable={updateVariable} value={value} />;
  };

  return (
    <ConfigSection
      defaultOpen
      icon="brackets"
      infoText="Variables set below will not persist on refresh or when embedded. Only default values set for these variables will persist."
      title="Embed variables"
      variant="header2">
      {!readOnly ? (
        <div className={sprinkles({ padding: 'sp2' })}>
          <Button fillWidth icon="plus" onClick={() => setEditingParam(null)}>
            Add an embed variable
          </Button>
        </div>
      ) : null}
      {orderedParams.map((param) => {
        return (
          <PanelListItem
            copiable={readOnly}
            key={param.id}
            leftIcon={SchemaDataTypeIcon[param.type as SchemaDataType]}
            name={param.name}
            onDelete={readOnly ? undefined : () => dispatch(deleteDashboardParam(param.id))}
            onEdit={readOnly ? undefined : () => setEditingParam(param)}
            rightElement={renderRightElement(param, variables[param.name])}
          />
        );
      })}
      {editingParam !== undefined ? (
        <ManageCustomVariableModal
          createNewParam={createNewParam}
          onClose={() => setEditingParam(undefined)}
          param={editingParam}
        />
      ) : null}
    </ConfigSection>
  );
};
