import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { createDashboardDataPanel, CreateDashboardDataPanelArgs } from 'actions/dashboardV2Actions';
import { KPI_VIZ_OPS } from 'constants/dataConstants';
import { setAddDataPanelState } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  getEditableDatasets,
  getGlobalDatasetReferences,
  getParentSchemaSourceTypes,
  getReferencedGlobalDatasets,
} from 'reducers/selectors';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { createDashboardItemId } from 'utils/dashboardUtils';
import { titleCase } from 'utils/graphUtils';

import { DataPanelModal } from './dataPanelModal';

type Props = {
  dashboardId: number;
};

export const AddDataPanelModal: FC<Props> = ({ dashboardId }) => {
  const dispatch = useDispatch();

  const { addDataPanelState, datasets, globalDatasetReferences, referencedGlobalDatasets } =
    useSelector(
      (state: ReduxState) => ({
        addDataPanelState: state.dashboardInteractions.addDataPanelState,
        datasets: getEditableDatasets(state),
        parentSchemaSourceTypes: getParentSchemaSourceTypes(state),
        globalDatasetReferences: getGlobalDatasetReferences(state),
        referencedGlobalDatasets: getReferencedGlobalDatasets(state),
      }),
      shallowEqual,
    );

  if (!addDataPanelState) return null;

  return (
    <DataPanelModal
      datasets={datasets}
      headerText="Select a table"
      onClose={() => dispatch(setAddDataPanelState(null))}
      onConfirm={(selectedDatasetId) => {
        const dataset = datasets?.[selectedDatasetId ?? ''];
        const selectedGlobalDatasetReference = globalDatasetReferences?.[selectedDatasetId ?? ''];
        if (!dataset && !selectedGlobalDatasetReference) return;

        const { opType, layout, containerId } = addDataPanelState;

        const id = createDashboardItemId(dashboardId);
        // we want the title to be undefined for KPIs so that we can make the title
        // dynamically default to the aggregation selected by the user
        const name = KPI_VIZ_OPS.has(opType)
          ? undefined
          : titleCase(
              dataset ? dataset.table_name : referencedGlobalDatasets[selectedDatasetId ?? ''].name,
            );

        const createDashboardDataPanelArgs: CreateDashboardDataPanelArgs = {
          id,
          name,
          newLayout: layout,
          vizType: opType,
          containerId,
          datasetId: dataset ? dataset.id : (selectedGlobalDatasetReference?.id ?? ''),
          globalDatasetReference: selectedGlobalDatasetReference,
        };
        dispatch(createDashboardDataPanel(createDashboardDataPanelArgs));
        trackEvent(EVENTS.ADDED_DATA_PANEL, {
          dashboard_template_id: dashboardId,
          selected_dataset_id: selectedDatasetId,
        });
      }}
      opType={addDataPanelState.opType}
      primaryButtonText="Create"
      referencedGlobalDatasets={referencedGlobalDatasets}
      title="Add a new data panel"
    />
  );
};
