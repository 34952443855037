import {
  ColorSpecification,
  FilterSpecification,
  DataDrivenPropertyValueSpecification,
} from '@maplibre/maplibre-gl-style-spec';
import Color from 'color';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
  Map,
  Source,
  Layer,
  FillLayer,
  NavigationControl,
  MapLayerMouseEvent,
  Popup,
  MapRef,
} from 'react-map-gl';
import ResizeObserver from 'react-resize-observer';

import { DatasetSchema, DatasetRow } from '@explo/data';

import { sprinkles } from 'components/ds';
import { ChartTooltip } from 'components/embed';
import { PointData } from 'components/embed/ChartTooltip';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { INITIAL_VIEW_STATE, MAP_STYLE_TO_MAPBOX_URL, DEFAULT_MAP_STYLE } from 'constants/maps';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { GlobalStylesContext } from 'globalStyles';

import * as styles from '../../MapboxChart/index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  getMapBoxBoundaryFormat,
  getMapBoxBoundaryType,
} from 'pages/dashboardPage/DashboardDatasetView/Maps/mapUtils';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

type RegionToAggMap = {
  [region: string]: { aggVal: number; color?: string };
};

type Props = {
  data: DatasetRow[];
  instructions: V2TwoDimensionChartInstructions;
  schema: DatasetSchema;
  mapRef: React.RefObject<MapRef>;
  debouncedMapResize: () => void;
};

export const ChoroplethMap: FC<Props> = ({
  data,
  instructions,
  schema,
  mapRef,
  debouncedMapResize,
}) => {
  const [key, setKey] = useState(0);
  const [tooltipInfo, setTooltipInfo] = useState<{
    name: string;
    value: number;
    color: string;
    lat: number;
    lng: number;
  } | null>(null);

  const { globalStyleConfig } = useContext(GlobalStylesContext);

  const customMapboxToken = useSelector(
    (state: ReduxState) => state.dashboardLayout.requestInfo.customMapBoxToken,
  );

  const {
    visualizations: { gradientPalette },
  } = globalStyleConfig;

  const {
    colorSteps = 8,
    tooltipFormat,
    viewState,
    minColor,
    maxColor,
  } = instructions.chartSpecificFormat?.choroplethMap || {};

  const regionColumn = instructions.categoryColumn?.column?.name || '';
  const aggColumnName = schema.length > 1 ? schema[1].name : ''; // new name generated based on type of aggregation
  const matchedBoundaryFormat = getMapBoxBoundaryFormat(
    instructions.chartSpecificFormat?.choroplethMap,
  );
  const { sourceLayer, tileset } = getMapBoxBoundaryType(
    instructions.chartSpecificFormat?.choroplethMap,
  );

  const hoveredRegion = tooltipInfo?.name ?? '';
  const filter: FilterSpecification = useMemo(
    () => ['in', matchedBoundaryFormat, hoveredRegion],
    [matchedBoundaryFormat, hoveredRegion],
  );

  const colorPalette: string[] = useMemo(() => {
    const minSelectedColor = minColor ?? gradientPalette.hue1;
    const maxSelectedColor = maxColor ?? gradientPalette.hue2;

    if (colorSteps <= 1) return [minSelectedColor];

    const palette = [];
    for (let i = 0; i < colorSteps; i++) {
      const ratio = i / (colorSteps - 1);
      const color = Color(minSelectedColor).mix(Color(maxSelectedColor), ratio);
      palette.push(color.string());
    }
    return palette;
  }, [colorSteps, gradientPalette, minColor, maxColor]);

  const regionToAggMap: RegionToAggMap = useMemo(() => {
    let aggMin: number | undefined = undefined;
    let aggMax: number | undefined = undefined;

    const newMap: RegionToAggMap = {};
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const aggVal = Number(row[aggColumnName]);
      if (!row[regionColumn]) continue;
      const regionName = `${row[regionColumn]}`;
      newMap[regionName] = { aggVal };

      if (aggMin === undefined || aggVal < aggMin) aggMin = aggVal;
      if (aggMax === undefined || aggVal > aggMax) aggMax = aggVal;
    }

    if (aggMin === undefined || aggMax === undefined) return {};

    for (const regionName in newMap) {
      const density = newMap[regionName].aggVal;
      const ratio = (density - aggMin) / (aggMax - aggMin);
      const colorIndex = isNaN(ratio) ? 0 : Math.floor(ratio * (colorSteps - 1));
      const color = colorPalette[colorIndex];
      newMap[regionName].color = color;
    }

    return newMap;
  }, [aggColumnName, colorPalette, colorSteps, data, regionColumn]);

  const matchExpression = useMemo(() => {
    const matchExpression = ['match', ['get', matchedBoundaryFormat]];

    for (const regionName in regionToAggMap) {
      matchExpression.push(regionName, regionToAggMap[regionName].color as string);
    }

    // Last value is the default, used where there is no data
    matchExpression.push('transparent');
    return matchExpression as DataDrivenPropertyValueSpecification<ColorSpecification>;
  }, [matchedBoundaryFormat, regionToAggMap]);

  const dataLayer: FillLayer = useMemo(() => {
    setKey((prevKey) => prevKey + 1);

    return {
      id: 'aggregation',
      type: 'fill',
      source: 'choropleth',
      'source-layer': sourceLayer,
      paint: {
        'fill-color': matchExpression,
        'fill-opacity': 0.8,
      },
    } as FillLayer;
  }, [sourceLayer, matchExpression]);

  const highlightLayer: FillLayer = useMemo(() => {
    return {
      id: 'aggregation-highlighted',
      type: 'fill',
      source: 'choropleth',
      'source-layer': sourceLayer,
      paint: {
        'fill-color': matchExpression,
        'fill-opacity': 1,
      },
    } as FillLayer;
  }, [sourceLayer, matchExpression]);

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!event.features?.[0]?.properties) return setTooltipInfo(null);

      const region = event.features[0].properties[matchedBoundaryFormat];

      if (!region || !regionToAggMap[region]) return setTooltipInfo(null);

      setTooltipInfo({
        name: region,
        value: regionToAggMap[region]?.aggVal ?? 0,
        color: regionToAggMap[region]?.color ?? '',
        lat: event.lngLat.lat,
        lng: event.lngLat.lng,
      });
    },
    [matchedBoundaryFormat, regionToAggMap],
  );

  const tooltipData: PointData | undefined = useMemo(() => {
    if (!tooltipInfo) return;

    return {
      color: tooltipInfo.color,
      name: tooltipInfo.name,
      value: tooltipInfo.value || 0,
      format: {
        decimalPlaces: tooltipFormat?.decimalPlaces ?? 2,
        significantDigits: tooltipFormat?.significantDigits ?? 3,
        formatId: tooltipFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
        hasCommas: true,
        timeFormatId: tooltipFormat?.timeFormat?.id,
        customTimeFormat: tooltipFormat?.timeCustomerFormat,
        customDurationFormat: tooltipFormat?.customDurationFormat,
        units: tooltipFormat?.unit,
      },
    };
  }, [tooltipInfo, tooltipFormat]);

  const configStyle = instructions.chartSpecificFormat?.choroplethMap?.style;

  return (
    <div className={sprinkles({ height: 'fill', width: 'fill' })}>
      <Map
        dragRotate
        attributionControl={false}
        cursor={tooltipInfo?.value ? 'pointer' : 'grab'}
        initialViewState={viewState ?? INITIAL_VIEW_STATE}
        interactiveLayerIds={['aggregation']}
        key={key}
        mapLib={import(/* webpackChunkName: "mapbox-gl" */ 'mapbox-gl')}
        mapStyle={
          configStyle === undefined
            ? DEFAULT_MAP_STYLE
            : (MAP_STYLE_TO_MAPBOX_URL[configStyle] ?? configStyle)
        }
        mapboxAccessToken={customMapboxToken ?? process.env.REACT_APP_MAPBOX_TOKEN}
        onMouseMove={handleMouseMove}
        ref={mapRef}>
        <Source id="choropleth" type="vector" url={tileset}>
          <Layer {...dataLayer} />
          <Layer {...highlightLayer} filter={filter} />
          <NavigationControl showCompass={false} />
          {tooltipInfo && tooltipData ? (
            <Popup
              anchor="bottom"
              className={styles.tooltip}
              closeButton={false}
              latitude={tooltipInfo.lat}
              longitude={tooltipInfo.lng}
              offset={8}>
              <ChartTooltip
                globalStyleConfig={globalStyleConfig}
                header={tooltipInfo.name}
                points={[tooltipData]}
              />
            </Popup>
          ) : null}
        </Source>
      </Map>
      <ResizeObserver onResize={debouncedMapResize} />
    </div>
  );
};
