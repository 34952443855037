import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Select, sprinkles } from 'components/ds';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import { AggedChartColumnInfo, V2TwoDimensionChartInstructions } from 'constants/types';
import { getColDisplayText } from 'utils/dataPanelColUtils';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const ComboChartColumnTypeConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  let aggCols: AggedChartColumnInfo[] | undefined = instructions.aggColumns;
  if (instructions.colorColumnOptions?.length) aggCols = aggCols?.slice(0, 1);

  const onComboUpdateChartType = (index: number, chartType: COMBO_CHART_DATA_FORMATS) => {
    const newInstructions = produce(instructions, (draft) => {
      const changedCol = draft.aggColumns?.[index];
      if (changedCol) changedCol.column.dataFormat = chartType;
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sprinkles({ padding: 'sp1.5', flexItems: 'column', gap: 'sp1' })}>
      {aggCols?.map((col, index) => {
        const label = getColDisplayText(col);
        return (
          <Select
            key={label}
            label={label}
            onChange={(newType) =>
              onComboUpdateChartType(index, newType as COMBO_CHART_DATA_FORMATS)
            }
            selectedValue={col.column.dataFormat || COMBO_CHART_DATA_FORMATS.LINE}
            values={chartOptions}
          />
        );
      })}
    </div>
  );
};

const chartOptions: { value: string; label: string }[] = [
  { value: COMBO_CHART_DATA_FORMATS.BAR, label: 'Bar' },
  { value: COMBO_CHART_DATA_FORMATS.LINE, label: 'Line' },
  { value: COMBO_CHART_DATA_FORMATS.DOT, label: 'Dot' },
  { value: COMBO_CHART_DATA_FORMATS.AREA, label: 'Area' },
];
