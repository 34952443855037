import { Dispatch } from 'react';

import { renameDashboard } from 'actions/dashboardActions';
import { renameReportBuilder } from 'actions/reportBuilderActions';
import { renameFolder } from 'reducers/thunks/resourceThunks';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { ResourceType } from 'types/exploResource';

export const getRenameActionForResource = (
  resourceId: number,
  resourceType: ResourceType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  onRenameSuccess: () => void,
): ((newName: string) => void) => {
  switch (resourceType) {
    case ResourceType.FOLDER:
      return (newName: string) => {
        dispatch(
          renameFolder({
            id: resourceId,
            name: newName,
            onSuccess: onRenameSuccess,
          }),
        );
      };
    case ResourceType.DASHBOARD:
      return (newName: string) => {
        dispatch(
          renameDashboard({ id: resourceId, postData: { name: newName } }, () => {
            onRenameSuccess();
            trackEvent(EVENTS.RENAMED_DASHBOARD_TEMPLATE_NAME, {
              dashboard_template_id: resourceId,
              dashboard_template_name: newName,
            });
          }),
        );
      };
    case ResourceType.REPORT:
      return (newName: string) => {
        dispatch(
          renameReportBuilder({ id: resourceId, postData: { name: newName } }, onRenameSuccess),
        );
      };
    default:
      throw new Error('Unknown resource type');
  }
};
