import cx from 'classnames';
import { DateTime } from 'luxon';
import { FC, Fragment, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AGGREGATIONS_TYPES,
  AggregationType,
  BaseCol,
  ChartAggregation,
  DatasetSchema,
  OldConfigurableColInfo,
  PivotAgg,
} from '@explo/data';

import { IconButton, Menu, MenuItem, MenuLabel, MenuSub, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { getParentSchemaSourceTypes, getSelectedDataPanel } from 'reducers/selectors';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { isAggOptionDisabled } from 'utils/aggUtils';
import { getColBucketName, getColDisplayText, getGroupOptions } from 'utils/dataPanelColUtils';

import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import { DroppedFormula } from './DroppedFormula';
import * as styles from './styles.css';

type Props = {
  column: OldConfigurableColInfo;
  canSelectSmartGrouping?: boolean;
  draggingClass?: string;
  onColAdded: (newCol: BaseCol, oldColName?: string, oldColAggType?: AggregationType) => void;
  onColOptionChanged?: (option: {
    id: string;
    name: string;
    formula?: string;
    variableId?: string;
  }) => void;
  onRemoveCol: () => void;
  disableEdits?: boolean;
  dashboardElements?: DashboardElement[];
  dashboardParams?: Record<string, DashboardParam>;
  includeFirst?: boolean;
  isCollapsibleListAgg?: boolean;
  schema: DatasetSchema;
};

export const DroppedAggregationColumn: FC<Props> = ({
  column,
  canSelectSmartGrouping,
  draggingClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  dashboardElements,
  dashboardParams,
  includeFirst,
  isCollapsibleListAgg,
  schema,
}) => {
  const [isBucketDropdownOpen, setIsBucketDropdownOpen] = useState(false);

  const groupOptions = useMemo(
    () => getGroupOptions(column, dashboardElements, includeFirst, isCollapsibleListAgg),
    [column, dashboardElements, includeFirst, isCollapsibleListAgg],
  );

  const { selectedDataPanel, parentSchemaSourceTypes, datasets } = useSelector(
    (state: ReduxState) => ({
      selectedDataPanel: getSelectedDataPanel(state),
      parentSchemaSourceTypes: getParentSchemaSourceTypes(state),
      datasets: state.dashboardEditConfig.config?.datasets,
    }),
    shallowEqual,
  );

  const variableOptions = useMemo(
    () => dashboardParams && Object.values(dashboardParams).filter((p) => p.type === 'STRING'),
    [dashboardParams],
  );

  const selectedDataset = Object.values(datasets ?? {}).find(
    (dataset) => dataset.id === selectedDataPanel?.table_id,
  );

  const dateTime = DateTime.local();

  if (groupOptions.length === 0 || !onColOptionChanged) {
    return (
      <div
        className={cx(
          sprinkles({
            display: 'flex',
            backgroundColor: 'lightBlue',
            borderRadius: 3,
          }),
          draggingClass,
        )}>
        <div className={sprinkles({ width: 'fill', overflow: 'hidden', flex: 1 })}>
          <ColumnOptionsPopover
            rightDivider
            displayName={getColDisplayText(column)}
            onColChange={(newCol) => onColAdded(newCol, column.column.name, column.agg)}
            schema={schema}
          />
        </div>
        <IconButton className={styles.iconBtn} name="cross" onClick={onRemoveCol} />
      </div>
    );
  }

  if (column.agg?.id === ChartAggregation.FORMULA) {
    return (
      <DroppedFormula
        disableEdits={!!disableEdits}
        draggingClass={draggingClass}
        formula={column.agg.formula}
        onColOptionChanged={onColOptionChanged}
        onRemoveCol={onRemoveCol}
      />
    );
  }

  const variableId = column?.bucket?.variableId;
  const aggName = column?.agg ? AGGREGATIONS_TYPES[column.agg.id].name : undefined;

  return (
    <div className={cx(baseContainerClassName, draggingClass)}>
      <div className={columnContainerClassName}>
        <ColumnOptionsPopover
          rightDivider
          displayName={column.column.name}
          onColChange={(newCol) => onColAdded(newCol, column.column.name, column.agg)}
          schema={schema}
        />
      </div>
      <div className={menuContainerClassName}>
        <Menu
          isOpen={isBucketDropdownOpen}
          onOpenChange={setIsBucketDropdownOpen}
          trigger={
            <div className={cx(styles.popoverTargetContainer, sprinkles({ cursor: 'pointer' }))}>
              <div className={styles.colName}>
                {variableId ? (
                  <span className={sprinkles({ fontFamily: 'source', marginRight: 'sp.5' })}>
                    var
                  </span>
                ) : null}
                {variableId ||
                  aggName?.toUpperCase() ||
                  getColBucketName(column?.bucket?.id)?.toUpperCase()}
              </div>
              <IconButton className={styles.iconBtnWithDivider} name="caret-down" />
            </div>
          }>
          {groupOptions.map((group, i) => (
            <Fragment key={i}>
              {group.title ? <MenuLabel>{group.title}</MenuLabel> : null}
              {group.options.map((option, j) => {
                if (
                  isAggOptionDisabled({
                    sourceType: parentSchemaSourceTypes[selectedDataset?.parent_schema_id ?? -1],
                    aggId: option.id,
                  })
                ) {
                  return null;
                }

                return (
                  <MenuItem
                    disabled={
                      (option.id === PivotAgg.DATE_SMART && !canSelectSmartGrouping) || disableEdits
                    }
                    key={j}
                    onSelect={() => {
                      setIsBucketDropdownOpen(false);
                      if (!disableEdits) onColOptionChanged({ id: option.id, name: option.name });
                    }}>
                    {option.name}
                    {option.getExample ? (
                      <span className={sprinkles({ color: 'contentTertiary' })}>
                        {option.getExample(dateTime)}
                      </span>
                    ) : null}
                  </MenuItem>
                );
              })}
            </Fragment>
          ))}
          {variableOptions?.length ? (
            <>
              <MenuLabel>Variables</MenuLabel>
              <MenuSub trigger="Embed Variables">
                {variableOptions.map((option) => (
                  <MenuItem
                    disabled={disableEdits}
                    key={option.id}
                    onSelect={() => {
                      setIsBucketDropdownOpen(false);
                      if (!disableEdits)
                        onColOptionChanged({
                          id: option.id,
                          name: option.name,
                          variableId: option.name,
                        });
                    }}>
                    {option.name}
                    <span className={sprinkles({ color: 'contentTertiary' })}>
                      {option.defaultValue}
                    </span>
                  </MenuItem>
                ))}
              </MenuSub>
            </>
          ) : null}
        </Menu>
      </div>
      <IconButton
        className={styles.iconBtn}
        disabled={disableEdits}
        name="cross"
        onClick={() => !disableEdits && onRemoveCol()}
      />
    </div>
  );
};

const baseContainerClassName = sprinkles({
  display: 'flex',
  backgroundColor: 'lightBlue',
  borderRadius: 3,
});

const columnContainerClassName = sprinkles({
  flex: 3,
  width: 'fill',
  overflow: 'hidden',
});

const menuContainerClassName = sprinkles({
  flex: 2,
  width: 'fill',
  overflow: 'hidden',
  color: 'contentPrimary',
});
