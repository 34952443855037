import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { BaseCol, OPERATION_TYPES } from '@explo/data';

import {
  BarChartView,
  BarVisualizationDirection,
  BarVisualizationGrouping,
} from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import {
  cloneRowsIfDate,
  customerReportToBarChartInstructions,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import BarChart from 'pages/dashboardPage/charts/barChart';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { getCurrentTheme } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { getCurrentColorTracker } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';

type Props = {
  reportData: ReportData;
  schema: BaseCol[];
  columnConfigs?: ReportBuilderColConfigs;
  view: BarChartView;
  onSelect?: (chartInfo: ChartMenuInfo | null) => void;
};

export const ReportBarChart: FC<Props> = ({
  reportData: { isLoading, rows },
  schema,
  columnConfigs,
  onSelect,
  view,
}) => {
  const { variables, globalStyleConfig, colorCategoryTracker } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
      colorCategoryTracker: getCurrentColorTracker(state.reportEditing),
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () =>
      customerReportToBarChartInstructions(
        view.aggregations,
        view.groupBys,
        columnConfigs,
        view.sortOptions,
      ),
    [view.aggregations, view.groupBys, columnConfigs, view.sortOptions],
  );

  // BarChart mutates previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const previewData = useMemo(() => cloneRowsIfDate(view.groupBys, rows), [view.groupBys, rows]);

  const missingText = useMemo(
    () => [
      ...(!view.aggregations?.length ? ['Y-Axis Values'] : []),
      ...(!view.groupBys?.length ? ['X-Axis Grouping'] : []),
    ],
    [view.aggregations?.length, view.groupBys?.length],
  );

  if (missingText.length) {
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2}>
        Missing {missingText.join(' and ')}
      </InvalidData>
    );
  }

  const { grouping, direction } = view.barChart ?? {};

  return (
    <BarChart
      backgroundColor="#fff"
      colorTracker={colorCategoryTracker}
      dataPanelProvidedId=""
      dataPanelTemplateId=""
      datasetData={{}}
      datasetNamesToId={{}}
      drilldownEntryPoints={{}}
      generalOptions={{ enableRawDataDrilldown: !!onSelect }}
      globalStyleConfig={globalStyleConfig}
      grouped={!grouping || grouping === BarVisualizationGrouping.Grouped}
      horizontal={direction === BarVisualizationDirection.Horizontal}
      instructions={instructions}
      // There's a bug in Highcharts where after switching from another chart to Bar, then changing the direction, the chart doesn't re-render
      // Setting the key to the direction forces React to completely re-render the chart when direction changes
      key={direction}
      loading={isLoading}
      // operationType is not correct because we don't account for direction or grouping,
      // but it only matters for grouped stacked charts which Report Builder doesn't use
      operationType={OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2}
      previewData={previewData}
      schema={schema}
      setChartMenu={onSelect || noOp}
      variables={variables}
    />
  );
};

const noOp = () => null;
