import Highcharts from 'highcharts';
import { memo, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';

import { DatasetSchema, OPERATION_TYPES, getTimezoneAwareUnix } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { ChartTooltip } from 'components/embed';
import {
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { setChartMenu } from 'reducers/dashboardLayoutReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanelData, DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { SankeyChartUtils, replaceEmptyWithPlaceholder } from 'utils/sankeyChartUtils';
import { cloneDeep } from 'utils/standard';
import { TwoDimensionalDataPanelUtils } from 'utils/twoDimensionalDataPanelUtils';
import { replaceVariablesInString } from 'utils/variableUtils';

import { ColumnColorTracker } from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { getColorFromPaletteTracker } from 'utils/colorCategorySyncUtils';
import { DrilldownChart } from './shared/drilldownChart';
import {
  FormatValueOptions,
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  shouldProcessColAsDate,
} from './utils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

type Props = {
  backgroundColor: string;
  panelData: DataPanelData | undefined;
  instructions: V2TwoDimensionChartInstructions | undefined;
  dataPanelId: string;
  variables: DashboardVariableMap;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  colorTracker?: ColumnColorTracker;
};

const OP_TYPE = OPERATION_TYPES.VISUALIZE_SANKEY_CHART;

type SeriesOptions = Highcharts.SeriesSankeyOptions;

const LABEL_LEFT_MARGIN = 20;

export const SankeyChart = memo(function SankeyChart({
  generalOptions,
  instructions,
  variables,
  panelData,
  dataPanelId,
  backgroundColor,
  globalStyleConfig,
  datasetNamesToId,
  datasetData,
  drilldownEntryPoints,
  colorTracker,
}: Props) {
  const dispatch = useDispatch();

  const loading = !panelData || panelData.loading;

  const requiredVarNotsSet = useMemo(
    () => !areRequiredVariablesSetTwoDimViz(variables, instructions),
    [variables, instructions],
  );

  const instructionsNeedConfiguration = useMemo(
    () => !isTwoDimVizInstructionsReadyToDisplay(instructions, OP_TYPE),
    [instructions],
  );

  const categoricalColors = useMemo(
    () => getCategoricalColors(globalStyleConfig),
    [globalStyleConfig],
  );

  const { series, aggColDisplay } = useMemo(
    () =>
      transformData(
        panelData?.rows,
        panelData?.schema,
        instructions,
        categoricalColors,
        colorTracker,
      ),
    [instructions, panelData?.rows, panelData?.schema, colorTracker, categoricalColors],
  );

  const processedAggColDisplay = useMemo(
    () => replaceVariablesInString(aggColDisplay, variables, datasetNamesToId, datasetData),
    [aggColDisplay, variables, datasetNamesToId, datasetData],
  );

  if (loading || instructionsNeedConfiguration || requiredVarNotsSet) {
    return (
      <NeedsConfigurationPanel
        fullHeight
        instructionsNeedConfiguration={instructionsNeedConfiguration}
        loading={loading}
        requiredVarsNotSet={requiredVarNotsSet}
      />
    );
  }

  const sankeyFormat = instructions?.chartSpecificFormat?.sankeyChart ?? {};
  const format = getFormatFromInstructions(instructions);

  const hasClickEvents = TwoDimensionalDataPanelUtils.doesChartHaveClickEvents(
    instructions,
    generalOptions,
    drilldownEntryPoints,
    OP_TYPE,
  );

  const chartOptions: Highcharts.Options = {
    chart: { type: 'sankey', backgroundColor },
    series,
    title: sharedTitleConfig,

    plotOptions: {
      sankey: {
        dataLabels: {
          allowOverlap: false,
          align: 'right',
          x: LABEL_LEFT_MARGIN,
          enabled: true,
          style: {
            textOutline: 'none',
            ...getLabelStyle(globalStyleConfig, 'primary'),
          },
          formatter: function () {
            if (!sankeyFormat.showValues) return undefined;
            const percentage =
              sankeyFormat.showPercentages && sankeyFormat.showPctOnChart
                ? getPercentageForPoint(this.point)
                : '';

            return `${this.point.options.custom?.formattedWeight}${percentage}`;
          },
          nodeFormatter: function () {
            if (!sankeyFormat.showPercentages || !sankeyFormat.showPctOnChart) return this.key;
            return `${this.key}${getPercentageForNode(this.point)}`;
          },
        },
      },
      series: {
        animation: false,
        cursor: hasClickEvents ? 'pointer' : undefined,
        point: {
          events: {
            click: function (e) {
              if (!hasClickEvents) return;
              if (e.chartX === undefined || e.chartY === undefined || e.chartY === null) return;
              let category, subCategory;
              if (e.point.options.from && e.point.options.to) {
                category = e.point.options.custom?.fromRaw;
                subCategory = e.point.options.custom?.toRaw;
              } else {
                // More typescript annoyance
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const point: any = e.point;
                if (!point) return;

                if (point.linksFrom && point.linksFrom.length > 0) {
                  category = point.linksFrom[0].custom?.fromRaw;
                }
                if (point.linksTo && point.linksTo.length > 0) {
                  subCategory = point.linksTo[0].custom?.toRaw;
                }
                // Can't use or filters so this will not currently work
                if (category && subCategory) return;
              }
              if (!category && !subCategory) return;

              dispatch(
                setChartMenu({
                  chartId: dataPanelId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  ...SankeyChartUtils.getChartMenuParams(category, subCategory),
                }),
              );
            },
          },
        },
      },
    },
    tooltip: {
      ...sharedTooltipConfigs,
      // Not sure why this doesn't exist in the types but it works
      // @ts-ignore
      nodeFormatter: function () {
        const percentage =
          sankeyFormat.showPercentages && sankeyFormat.showPctOnTooltips
            ? getPercentageForNode(this)
            : '';

        return ReactDOMServer.renderToStaticMarkup(
          <ChartTooltip
            globalStyleConfig={globalStyleConfig}
            header={`${getValFromPoint(this, 'name')}${percentage}`}
            points={[
              {
                color: getValFromPoint(this, 'color'),
                name: processedAggColDisplay,
                value: getNumFromPoint(this, 'sum'),
                format,
              },
            ]}
          />,
        );
      },
      pointFormatter: function () {
        const percentage =
          sankeyFormat.showPercentages && sankeyFormat.showPctOnTooltips
            ? getPercentageForPoint(this)
            : '';

        return ReactDOMServer.renderToStaticMarkup(
          <ChartTooltip
            globalStyleConfig={globalStyleConfig}
            header={`${this.name}${percentage}`}
            points={[
              {
                color: String(this.color),
                name: processedAggColDisplay,
                formattedValue: this.options.custom?.formattedWeight,
              },
            ]}
          />,
        );
      },
    },
  };

  return (
    <DrilldownChart
      chartOptions={chartOptions}
      customMenuOptions={
        generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
      }
      dataPanelId={dataPanelId}
      drilldownEntryPoints={drilldownEntryPoints}
      instructions={instructions}
      underlyingDataEnabled={generalOptions?.enableRawDataDrilldown}
    />
  );
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValFromPoint = (point: any, key: string): string => {
  if (key in point) return String(point[key]);
  return '';
};

// Not sure why types aren't correct but have to do this
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNumFromPoint = (point: any, key: string): number => {
  if (!(key in point)) return 0;
  return getAxisNumericalValue(point[key]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPercentageForNode = (point: any): string => {
  const level = point?.level;
  const nodes = point?.series?.nodes;
  if (!nodes || level === undefined) return '';

  let totalSum = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes.forEach((node: any) => {
    if (node.level === level) totalSum += node.sum;
  });
  if (totalSum === 0) return '';
  return ` (${((point.sum / totalSum) * 100).toFixed(2)}%)`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPercentageForPoint = (point: any): string => {
  const level = point?.fromNode?.level;
  const nodes = point?.series?.data;
  if (!nodes || level === undefined) return '';

  let totalWeight = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes.forEach((node: any) => {
    if (node.fromNode.level === level) totalWeight += node.weight;
  });
  if (totalWeight === 0) return '';
  return ` (${((point.weight / totalWeight) * 100).toFixed(2)}%)`;
};

type TransformedData = {
  series: SeriesOptions[];
  aggColDisplay: string;
};

const emptyData: TransformedData = {
  series: [],
  aggColDisplay: '',
};

const transformData = (
  rows: Record<string, string | number>[] | undefined,
  schema: DatasetSchema | undefined,
  instructions: V2TwoDimensionChartInstructions | undefined,
  categoricalColors: string[],
  colorTracker?: ColumnColorTracker,
): TransformedData => {
  const { colorColumnOptions, categoryColumn, aggColumns } = instructions ?? {};
  if (
    !rows?.length ||
    !schema?.length ||
    !aggColumns?.length ||
    !categoryColumn ||
    !colorColumnOptions?.length
  ) {
    return emptyData;
  }

  const colorCol = colorColumnOptions[0];
  const categoryColIsDate = shouldProcessColAsDate(categoryColumn);
  const colorColIsDate = shouldProcessColAsDate(colorCol);

  const { colorColName, aggColName, xAxisColName } = getColorColNames(schema, OP_TYPE);

  let processedRows = rows;
  if (categoryColIsDate || colorColIsDate) {
    processedRows = cloneDeep(rows);
    processedRows.forEach((row) => {
      if (categoryColIsDate && typeof row[xAxisColName] !== 'number')
        row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
      if (colorColIsDate && typeof row[colorColName] !== 'number')
        row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
    });
  }

  const data: Highcharts.SeriesSankeyPointOptionsObject[] = [];
  const format = getFormatFromInstructions(instructions);

  const nodesColorMap: Record<string, string> = {};

  const getPaletteColor = (
    columnName: string | undefined,
    valueName: string,
    fallbackColor: string,
  ) => {
    if (colorTracker && columnName) {
      return (
        getColorFromPaletteTracker({
          columnName,
          valueName,
          colorTracker,
        }) ?? fallbackColor
      );
    }
    return fallbackColor;
  };

  processedRows.forEach((row, index) => {
    const weight = getAxisNumericalValue(row[aggColName]);
    const fromRaw = row[xAxisColName];
    const toRaw = row[colorColName];
    if (!weight) return;

    const from = replaceEmptyWithPlaceholder(
      formatLabel(
        fromRaw,
        categoryColumn.column.type,
        categoryColumn.bucket?.id,
        categoryColumn.bucketSize,
      ),
    );

    const to = replaceEmptyWithPlaceholder(
      formatLabel(toRaw, colorCol?.column.type, colorCol?.bucket?.id),
    );

    const custom = {
      formattedWeight: formatValue({ ...format, value: weight }),
      fromRaw: replaceEmptyWithPlaceholder(fromRaw),
      toRaw: replaceEmptyWithPlaceholder(toRaw),
    };
    // Use the color tracker to set the colors
    const fallbackColor = categoricalColors[index % categoricalColors.length];
    nodesColorMap[from] = getPaletteColor(categoryColumn.column.name, from, fallbackColor);
    nodesColorMap[to] = getPaletteColor(colorCol.column.name, to, fallbackColor);

    data.push({ from, to, name: `${from} -> ${to}`, weight, custom });
  });

  const aggCol = aggColumns[0];
  const aggColDisplay = aggCol.column.friendly_name || getColDisplayText(aggCol) || aggColName;

  const nodes = Object.entries(nodesColorMap).map(([id, color]) => ({ id, color }));

  return { series: [{ type: 'sankey', data, name: '', nodes }], aggColDisplay };
};

const getFormatFromInstructions = (
  instructions: V2TwoDimensionChartInstructions | undefined,
): Omit<FormatValueOptions, 'value'> => {
  const sankey = instructions?.chartSpecificFormat?.sankeyChart ?? {};
  return {
    formatId: sankey.numberFormat,
    decimalPlaces: sankey.decimalPlaces ?? 2,
    multiplier: sankey.multiplier,
    units: sankey.units,
  };
};
