import cx from 'classnames';
import { FC } from 'react';

import { OPERATION_TYPES } from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { NoDataPanel } from 'components/ChartLayout/NoDataPanel';
import { DemoWatermark } from 'components/DemoWatermark';
import { sprinkles } from 'components/ds';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanelData } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { getChartTooltipText } from 'utils/graphUtils';
import { isEmpty } from 'utils/standard';

import { NumberTrend } from '../charts/numberTrend';
import { SingleNumberChart } from '../charts/singleNumberChart';

import { HEADER_HEIGHT } from './DashboardDatasetView';

type Props = {
  dataPanel: DataPanel;
  dataPanelData: DataPanelData | undefined;
  displayDemoWatermark: boolean;
  globalStyleConfig: GlobalStyleConfig;
  loading: boolean;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  isScreenshotDownload?: boolean;
  processString: (s: string) => string;
};

export const NoDataChartView: FC<Props> = ({
  dataPanel,
  dataPanelData,
  loading,
  variables,
  globalStyleConfig,
  displayDemoWatermark,
  datasetNamesToId,
  datasetData,
  isScreenshotDownload,
  processString,
}) => {
  const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

  const schema = dataPanelData?.schema ?? [];

  const renderDemoWatermark = () => {
    return displayDemoWatermark ? <DemoWatermark /> : null;
  };

  if (
    operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    return (
      <div className={sprinkles({ height: 'fill', padding: 'sp2' })}>
        {renderDemoWatermark()}
        <SingleNumberChart
          dataPanelTemplateId={dataPanel.id}
          generalOptions={generalFormatOptions}
          globalStyleConfig={globalStyleConfig}
          infoTooltipText={getChartTooltipText(
            operation_type,
            instructions,
            variables,
            datasetNamesToId,
            datasetData,
          )}
          instructions={instructions.V2_KPI}
          loading={loading}
          operationType={operation_type}
          previewData={[]}
          processString={processString}
          schema={schema}
        />
      </div>
    );
  } else if (
    operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
    operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL
  ) {
    const secondaryData = dataPanelData?.secondaryData ?? [];
    return (
      <>
        {renderDemoWatermark()}
        <NumberTrend
          aggValuesLoading={!dataPanelData || !!dataPanelData.outstandingSecondaryDataRequests}
          aggregatedValues={
            !isEmpty(secondaryData)
              ? {
                  comparisonRange: secondaryData[0]
                    ? ((secondaryData[0][Object.keys(secondaryData[0])[0] as string] as number) ??
                      0)
                    : 0,
                  periodRange: secondaryData[1]
                    ? ((secondaryData[1][Object.keys(secondaryData[1])[0] as string] as number) ??
                      0)
                    : 0,
                }
              : undefined
          }
          dataPanelTemplateId={dataPanel.id}
          generalOptions={generalFormatOptions}
          globalStyleConfig={globalStyleConfig}
          infoTooltipText={getChartTooltipText(
            operation_type,
            instructions,
            variables,
            datasetNamesToId,
            datasetData,
          )}
          instructions={instructions.V2_KPI_TREND}
          loading={loading}
          operationType={operation_type}
          previewData={[]}
          processString={processString}
          schema={schema}
          variables={variables}
        />
      </>
    );
  }

  return (
    <div
      className={cx(
        sprinkles({ padding: 'sp1' }),
        embedSprinkles({ backgroundColor: 'containerFill' }),
      )}
      //20px to account for header padding
      // isScreenshotDownload check goes around edge case of pdf export of a chart with no data not taking up fill space
      style={{
        height: isScreenshotDownload
          ? `calc(100vh - ${HEADER_HEIGHT}px - 20px)`
          : `calc(100% - ${HEADER_HEIGHT}px - 20px)`,
      }}>
      <NoDataPanel
        fullHeight
        loading={loading}
        noDataState={generalFormatOptions?.noDataState}
        operationType={operation_type}
        processString={processString}
      />
    </div>
  );
};
