import { Dashboard } from 'actions/dashboardActions';
import { Dataset } from 'actions/datasetActions';
import { ReportBuilder } from 'actions/reportBuilderActions';

import { DashboardVersionConfig } from './dashboardVersionConfig';
import { DataPanelTemplate } from './dataPanelTemplate';

export type Resource = Dashboard | ReportBuilder;

export type RequestDataset = Pick<Dataset, 'query' | 'parent_schema_id' | 'namespace_id'>;

export type DataPanel = DataPanelTemplate;

export interface VersionInfo {
  is_draft: boolean;
  change_comments: string | null;
  version_number: number;
  edit_version_number: number;
}

export interface LatestVersionInfo {
  is_draft: boolean;
  version_number: number;
  modified: string;
  configuration: DashboardVersionConfig;
}

export enum ResourcePageType {
  REPORT_BUILDER = 'REPORT_BUILDER',
  EXPLORE = 'EXPLORE',
}

// TODO(zifanxiang): Find a way to keep this in sync with ResourceEntryType on the server or declare
// this in a language agnostic way.
export enum ResourceType {
  FOLDER = 'folder',
  DASHBOARD = 'dashboard',
  REPORT = 'report',
  DATASET = 'dataset',
}

// Should be a subset of ResourceType
export type ProductType = ResourceType.DASHBOARD | ResourceType.REPORT;

export type ResourceSearchResult = {
  id: number;
  name: string;
  type: ResourceType;
  breadcrumbs: Breadcrumb[];
};

type FolderContents = {
  folders: Folder[];
  breadcrumbs: Breadcrumb[];
  type: ProductType;
  folder_count: number;
  entry_count: number;
};

export type DashboardFolderContents = FolderContents & {
  resources: Dashboard[];
};
export type ReportFolderContents = FolderContents & {
  resources: ReportBuilder[];
};

export type FolderHierarchy = {
  id: number;
  name: string;
  sub_folders: FolderHierarchy[];
};

export type Folder = {
  id: number;
  name: string;
  parent_id: number;
  type: string;
  num_resources: number;
};

export type Breadcrumb = { name: string; id: number };
