import { Branch, Folder } from '@explo-tech/fido-api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listBranchContentThunk, listBranchesThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import * as RD from 'remotedata';
import { ROOT_FOLDER_PATH } from './constants';
import { ItemType } from 'reducers/dataLibraryReducer';

export const useLoadRootFolderContents = (
  mainBranch: RD.ResponseData<Branch>,
  rootFolder: RD.ResponseData<Folder>,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (RD.isIdle(mainBranch)) {
      dispatch(listBranchesThunk());
    }
  }, [dispatch, mainBranch]);

  useEffect(() => {
    if (!RD.isSuccess(mainBranch)) {
      return;
    }

    if (!RD.isIdle(rootFolder)) {
      return;
    }

    dispatch(
      listBranchContentThunk({
        id: mainBranch.data.id ?? '',
        path: ROOT_FOLDER_PATH,
        resourceType: ItemType.FOLDER,
      }),
    );
  });
};
