import { TypeColumn } from '@trust-kaz/reactdatagrid-enterprise/types';
import cx from 'classnames';
import React, { FC, Suspense, useEffect, useState } from 'react';

import { Spinner, sprinkles } from 'components/ds';
import { ColumnColorTracker } from 'constants/types';

import { useColumns } from './columnUtils';
import * as styles from './index.css';
import { DataGridPaginator, PaginatorProps } from './paginator';
import '@trust-kaz/reactdatagrid-enterprise/index.css';
import { ColumnConfigs, DatasetSchema, DatasetRow, BaseCol } from '@explo/data';

const ReactDataGrid = React.lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@trust-kaz/reactdatagrid-enterprise'),
);

type Props = {
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Allows an override of default columns that are generated from schema, rows, and columnConfigs
   */
  columns?: TypeColumn[];
  /**
   * Column configs for formatting
   */
  columnConfigs?: ColumnConfigs;
  /**
   * Show the loading state for the DataGrid
   */
  loading?: boolean;
  /**
   * Schema for column metadata (e.g. name, type)
   */
  schema?: DatasetSchema;
  /**
   * Height that all rows should be
   */
  rowHeight?: number;
  /**
   * Rows of data to display
   */
  rows?: DatasetRow[];
  /**
   * Props to show/configure DataGridPaginator
   */
  paginatorProps?: PaginatorProps;
  /**
   * Allows column selection features
   */
  columnSelectionEnabled?: boolean;
  /**
   * ID/name of column that should be selected
   */
  selectedColumn?: string;
  /**
   * Callback when column order changes
   */
  onColumnOrderChange?: (newOrder: string[]) => void;
  /**
   * Callback that fires when a column is selected
   */
  onColumnSelect?: (column?: BaseCol) => void;
  /**
   * Color category tracker for column selection
   */
  colorTracker?: ColumnColorTracker;
  /**
   * Config to determine whether to hide invalid dates behind empty string
   */
  ignoreInvalidDates?: boolean;
};

export const DataGrid: FC<Props> = ({
  className,
  columns,
  columnConfigs,
  columnSelectionEnabled,
  loading,
  schema = [],
  rowHeight,
  rows = [],
  onColumnOrderChange,
  onColumnSelect,
  paginatorProps,
  selectedColumn,
  colorTracker,
  ignoreInvalidDates,
}) => {
  const [selectedColumnId, setSelectedColumnId] = useState<string | number | undefined>(
    selectedColumn,
  );

  const handleColumnSelect = (id: string | number) => {
    setSelectedColumnId(id);
    onColumnSelect?.(schema.find((col) => col.name === id));
  };

  useEffect(() => {
    setSelectedColumnId(columnSelectionEnabled ? selectedColumn : undefined);
  }, [columnSelectionEnabled, selectedColumn]);

  const gridColumns = useColumns({
    columns,
    columnConfigs,
    rows,
    schema,
    selectedColumnId,
    onColumnSelect: columnSelectionEnabled ? handleColumnSelect : undefined,
    colorTracker,
    ignoreInvalidDates,
  });

  const renderLoadMask = ({ visible }: { visible: boolean }) => {
    return visible ? <Spinner fillContainer /> : null;
  };

  return (
    <div
      className={cx(styles.container, sprinkles({ parentContainer: 'fill', flexItems: 'column' }), {
        [styles.columnOverrideStyle]: columnSelectionEnabled,
      })}>
      <Suspense fallback={<Spinner fillContainer />}>
        <div className={sprinkles({ flex: 1 })}>
          <ReactDataGrid
            className={cx(sprinkles({ height: 'fill' }), className)}
            columnHoverClassName={styles.selectedCell}
            columnOrder={schema?.map((col) => col.name)}
            columns={gridColumns}
            dataSource={rows}
            defaultCellSelection={columnSelectionEnabled ? {} : undefined}
            enableColumnHover={columnSelectionEnabled}
            idProperty="id"
            licenseKey={process.env.REACT_APP_DATAGRID_KEY}
            loading={rows.length === 0 && loading}
            multiSelect={false}
            onCellSelectionChange={(config) => {
              if (!columnSelectionEnabled) return;
              const keys = Object.keys(config);
              const columnId = keys.length && keys[0].split(',')[1];
              handleColumnSelect(columnId);
            }}
            onColumnOrderChange={onColumnOrderChange}
            renderLoadMask={renderLoadMask}
            reorderColumns={onColumnOrderChange !== undefined}
            rowClassName={!columnSelectionEnabled ? styles.row : undefined}
            rowHeight={rowHeight}
            showColumnMenuTool={false}
            showHoverRows={!columnSelectionEnabled}
            sortable={false}
          />
        </div>
        {paginatorProps ? (
          <DataGridPaginator
            {...paginatorProps}
            currentRowCount={rows.length}
            loading={loading ?? false}
          />
        ) : null}
      </Suspense>
    </div>
  );
};
