import { ComputedView } from '@explo-tech/fido-api';
import { Button, Icon, Modal, sprinkles, Tabs, Tooltip } from 'components/ds';
import { MapVariablesContent } from 'pages/dashboardPage/dashboardDatasetEditor/modals/components/MapVariablesContent';
import {
  convertViewToDataset,
  getEmbeddoSchemaIdFromView,
} from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useDispatch, useSelector } from 'react-redux';
import { updateReferencedGlobalDatasetVersion } from 'reducers/dashboardEditConfigReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getRootEditConfigWithDrilldowns } from 'reducers/selectors';
import { fetchGlobalDatasetPreviewThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { Dispatch } from 'redux';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { GlobalDatasetVariableNameMap } from 'types/dashboardVersionConfig';
import { ComputedViewWithIds } from 'utils/fido/fidoRequestUtils';
import { cloneAndRemapVariableNames } from 'utils/variableUtils';
import { TabType } from './globalDatasetConfigurationTabType';
import { GlobalDatasetDataPreviewSection } from './GlobalDatasetDataPreviewSection';
import { previewContainerClass } from './GlobalDatasetVersionComparisonModal.css';
import { FetchOrigin } from 'reducers/thunks/dashboardDataThunks/types';

interface Props {
  currentGlobalDataset: ComputedView;
  newestGlobalDataset: ComputedView;

  onClose: () => void;
}

export const GlobalDatasetVersionComparisonModal: FC<Props> = ({
  currentGlobalDataset,
  newestGlobalDataset,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(TabType.PREVIEW);

  const { parentSchemas, globalDatasetData, allVariableMappings, variablesMap } = useSelector(
    (state: ReduxState) => {
      return {
        parentSchemas: getParentSchemasList(state),
        globalDatasetData: state.dataLibrary.datasetData,
        allVariableMappings: getRootEditConfigWithDrilldowns(state)?.variable_mappings ?? {},
        variablesMap: state.dashboardData.variables ?? {},
      };
    },
  );

  const [currentGlobalDatasetVariableNameMap, setCurrentGlobalDatasetVariableNameMap] =
    useState<GlobalDatasetVariableNameMap>(
      allVariableMappings[currentGlobalDataset.id ?? ''] ?? {},
    );
  const [newestGlobalDatasetVariableNameMap, setNewestGlobalDatasetVariableNameMap] = useState<
    Record<string, string>
  >(() => {
    const initialNewestGlobalDatasetVariableNameMap: GlobalDatasetVariableNameMap = {};
    for (const parameter of newestGlobalDataset.parameters) {
      if (currentGlobalDatasetVariableNameMap[parameter.name]) {
        initialNewestGlobalDatasetVariableNameMap[parameter.name] =
          currentGlobalDatasetVariableNameMap[parameter.name];
      }
    }
    return initialNewestGlobalDatasetVariableNameMap;
  });

  const currentGlobalDatasetWithIds: ComputedViewWithIds = useMemo(() => {
    return {
      ...currentGlobalDataset,
      id: currentGlobalDataset.id ?? '',
      namespaceId: currentGlobalDataset.namespaceId ?? '',
    };
  }, [currentGlobalDataset]);

  const currentGlobalDatasetParentSchemaId = useMemo(() => {
    return getEmbeddoSchemaIdFromView(parentSchemas, currentGlobalDatasetWithIds);
  }, [parentSchemas, currentGlobalDatasetWithIds]);

  const newestGlobalDatasetWithIds: ComputedViewWithIds = useMemo(() => {
    return {
      ...newestGlobalDataset,
      id: newestGlobalDataset.id ?? '',
      namespaceId: newestGlobalDataset.namespaceId ?? '',
    };
  }, [newestGlobalDataset]);

  const newestGlobalDatasetParentSchemaId = useMemo(() => {
    return getEmbeddoSchemaIdFromView(parentSchemas, newestGlobalDatasetWithIds);
  }, [parentSchemas, newestGlobalDatasetWithIds]);

  const currentDatasetHasUnmappedVariables = useMemo(() => {
    return currentGlobalDataset.parameters.some((parameter) => {
      return !currentGlobalDatasetVariableNameMap[parameter.name];
    });
  }, [currentGlobalDataset.parameters, currentGlobalDatasetVariableNameMap]);

  const remappedVariableMap = useMemo(() => {
    return cloneAndRemapVariableNames(currentGlobalDatasetVariableNameMap, variablesMap);
  }, [currentGlobalDatasetVariableNameMap, variablesMap]);

  const onPreviewCurrentDataset = useCallback(
    (query: string, pageNumber?: number) =>
      maybeDispatchDatasetPreview(
        dispatch,
        currentDatasetHasUnmappedVariables,
        selectedTab,
        query,
        pageNumber,
        currentGlobalDatasetWithIds,
        currentGlobalDatasetParentSchemaId,
        remappedVariableMap,
      ),
    [
      currentGlobalDatasetParentSchemaId,
      currentGlobalDatasetWithIds,
      dispatch,
      remappedVariableMap,
      selectedTab,
      currentDatasetHasUnmappedVariables,
    ],
  );

  const currentGlobalDatasetData =
    globalDatasetData[currentGlobalDataset.id ?? '']?.[currentGlobalDataset.versionId ?? ''];
  useEffect(() => {
    onPreviewCurrentDataset(currentGlobalDataset.query ?? '');
  }, [onPreviewCurrentDataset, currentGlobalDataset]);

  const remappedNewestVariableMap = useMemo(() => {
    return cloneAndRemapVariableNames(newestGlobalDatasetVariableNameMap, variablesMap);
  }, [newestGlobalDatasetVariableNameMap, variablesMap]);

  const newestDatasetHasUnmappedVariables = useMemo(() => {
    return newestGlobalDataset.parameters.some((parameter) => {
      return !newestGlobalDatasetVariableNameMap[parameter.name];
    });
  }, [newestGlobalDataset.parameters, newestGlobalDatasetVariableNameMap]);

  const onPreviewNewestDataset = useCallback(
    (query: string, pageNumber?: number) =>
      maybeDispatchDatasetPreview(
        dispatch,
        newestDatasetHasUnmappedVariables,
        selectedTab,
        query,
        pageNumber,
        newestGlobalDatasetWithIds,
        newestGlobalDatasetParentSchemaId,
        remappedNewestVariableMap,
      ),
    [
      newestGlobalDatasetParentSchemaId,
      newestGlobalDatasetWithIds,
      dispatch,
      remappedNewestVariableMap,
      newestDatasetHasUnmappedVariables,
      selectedTab,
    ],
  );

  const newestGlobalDatasetData =
    globalDatasetData[newestGlobalDataset.id ?? '']?.[newestGlobalDataset.versionId ?? ''];
  useEffect(() => {
    // TODO(zifanxiang): Prevent fetches for the same variable configuration.
    onPreviewNewestDataset(newestGlobalDataset.query ?? '');
  }, [onPreviewNewestDataset, newestGlobalDataset]);

  const convertedCurrentGlobalDataset = useMemo(
    () => convertViewToDataset(currentGlobalDatasetWithIds, parentSchemas),
    [currentGlobalDatasetWithIds, parentSchemas],
  );

  const convertedNewestGlobalDataset = useMemo(
    () => convertViewToDataset(newestGlobalDatasetWithIds, parentSchemas),
    [newestGlobalDatasetWithIds, parentSchemas],
  );

  return (
    <Modal isOpen onClose={onClose} size="xlarge" title="Update version">
      <div className={sprinkles({ flexItems: 'column' })}>
        <Tabs
          onTabSelect={(tabId) => setSelectedTab(tabId as TabType)}
          selectedTabId={selectedTab}
          tabs={Object.values(TabType)}
        />
        {/**
         * TODO(zifanxiang): Think of a good way to share this component structure with the
         * ViewGlobalDatasetModal.
         */}
        {selectedTab === TabType.PREVIEW ? (
          <div className={sprinkles({ marginTop: 'sp2' })}>
            <ReactDiffViewer
              splitView
              compareMethod={DiffMethod.LINES}
              newValue={newestGlobalDataset.query ?? ''}
              oldValue={currentGlobalDataset.query ?? ''}
            />
            <div
              className={sprinkles({
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginTop: 'sp2',
              })}>
              <div className={previewContainerClass}>
                <GlobalDatasetDataPreviewSection
                  className={sprinkles({ width: 'fill' })}
                  dataset={convertedCurrentGlobalDataset}
                  datasetData={currentGlobalDatasetData}
                  hasUnmappedVariables={currentDatasetHasUnmappedVariables}
                  onMapVariablesButtonClicked={() => {
                    setSelectedTab(TabType.VARIABLE_MAPPING);
                  }}
                  onPreviewDataset={onPreviewCurrentDataset}
                />
              </div>
              <div className={previewContainerClass}>
                <GlobalDatasetDataPreviewSection
                  className={sprinkles({ width: 'fill' })}
                  dataset={convertedNewestGlobalDataset}
                  datasetData={newestGlobalDatasetData}
                  hasUnmappedVariables={newestDatasetHasUnmappedVariables}
                  onMapVariablesButtonClicked={() => {
                    setSelectedTab(TabType.VARIABLE_MAPPING);
                  }}
                  onPreviewDataset={onPreviewNewestDataset}
                />
              </div>
            </div>
          </div>
        ) : null}
        {selectedTab === TabType.VARIABLE_MAPPING ? (
          <div className={sprinkles({ display: 'flex' })}>
            <div className={sprinkles({ width: 'half' })}>
              <MapVariablesContent
                globalDatasetVariableMappings={currentGlobalDatasetVariableNameMap}
                onClose={() => {
                  // Do nothing this is intentional
                }}
                onNewMappingSelected={(parameterName, variableName) => {
                  const newGlobalToDashboardVariableMappings = {
                    ...currentGlobalDatasetVariableNameMap,
                    [parameterName]: variableName,
                  };
                  setCurrentGlobalDatasetVariableNameMap(newGlobalToDashboardVariableMappings);
                }}
                selectedComputedView={currentGlobalDataset}
              />
            </div>
            <div className={sprinkles({ width: 'half' })}>
              <MapVariablesContent
                globalDatasetVariableMappings={newestGlobalDatasetVariableNameMap}
                onClose={() => {
                  // Do nothing this is intentional
                }}
                onNewMappingSelected={(parameterName, variableName) => {
                  const newGlobalToDashboardVariableMappings = {
                    ...newestGlobalDatasetVariableNameMap,
                    [parameterName]: variableName,
                  };
                  setNewestGlobalDatasetVariableNameMap(newGlobalToDashboardVariableMappings);
                }}
                selectedComputedView={newestGlobalDataset}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          justifyContent: 'flex-end',
          marginY: 'sp4',
        })}>
        <Button className={sprinkles({ marginX: 'sp1' })} onClick={onClose} variant="secondary">
          Keep current version
        </Button>
        <Button
          className={sprinkles({ marginRight: 'sp5' })}
          onClick={() => {
            onClose();
            dispatch(
              updateReferencedGlobalDatasetVersion({
                newReference: {
                  id: newestGlobalDataset.id ?? '',
                  versionId: newestGlobalDataset.versionId ?? '',
                  namespaceId: newestGlobalDataset.namespaceId ?? '',
                },
                newVariableMappings: newestGlobalDatasetVariableNameMap,
              }),
            );
          }}>
          <>
            <span>Update to newest version</span>
            {newestDatasetHasUnmappedVariables ? (
              <Tooltip text="There are unmapped dataset variables">
                <Icon
                  className={sprinkles({ color: 'yellow', marginX: 'sp1' })}
                  name="infoCircle"
                />
              </Tooltip>
            ) : null}
          </>
        </Button>
      </div>
    </Modal>
  );
};

const maybeDispatchDatasetPreview = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  datasetHasUnmappedVariables: boolean,
  selectedTab: TabType,
  query: string,
  pageNumber: number | undefined,
  selectedView: ComputedViewWithIds,
  parentSchemaId: number,
  variablesMap: DashboardVariableMap,
) => {
  if (datasetHasUnmappedVariables || selectedTab !== TabType.PREVIEW) {
    return;
  }

  dispatch(
    fetchGlobalDatasetPreviewThunk(
      {
        query,
        parentSchemaId,
        selectedView,
        variables: variablesMap,
      },
      FetchOrigin.DASHBOARD,
      pageNumber,
    ),
  );
};
