import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  CHART_VISUALIZATION_OPERATIONS_SET,
  DatasetSchema,
  FilterOperator,
  FilterValueSourceType,
  OPERATION_TYPES,
} from '@explo/data';

import { EmptyV2PivotInstructions } from 'actions/V2PivotTableActions';
import { Dataset } from 'actions/datasetActions';
import { SettingHeader } from 'components/SettingHeader';
import { maybeChangeNumberTrendVisualizationType } from 'pages/dashboardPage/charts/utils/trendUtils';
import { ReduxState } from 'reducers/rootReducer';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getPossibleLinksForDataPanel } from 'utils/filterLinking';

import { DroppableFilterColumnSection } from './FilterConfigSection/DroppableFilterColumnSection';
import { ElementFilterSection } from './FilterConfigSection/ElementFilterSection';
import { VisualizationConfigTableColumns } from './VisualizationConfigTableColumns';
import { VisualizationTypeSelector } from './VisualizationTypeSelector';
import { BoxPlotConfig } from './vizConfigs/BoxPlotConfig';
import { CalendarHeatmapConfig } from './vizConfigs/CalendarHeatmapConfig';
import CollapsibleListConfig from './vizConfigs/CollapsibleListConfig';
import { GeospatialVizConfig } from './vizConfigs/GeospatialVizConfig';
import { KPITrendConfig } from './vizConfigs/KPITrendConfig';
import KPIVizConfig from './vizConfigs/KPIVizConfig';
import { PivotTableConfig } from './vizConfigs/PivotTableConfig';
import ScatterPlotConfig from './vizConfigs/ScatterPlotConfig';
import TableConfig from './vizConfigs/TableConfig';
import TwoDimensionVizConfig from './vizConfigs/TwoDimensionVizConfig';
import { V2PivotTableConfig } from './vizConfigs/V2PivotTableConfig';
import { ComputedView } from '@explo-tech/fido-api';
import { convertGlobalDatasetsAndCombineWithLocal } from 'utils/datasetUtils';

type Props = {
  schema: DatasetSchema;
  datasets: Record<string, Dataset>;
  dataPanel: DataPanelTemplate;
  referencedGlobalDatasets: Record<string, ComputedView>;
};

export const DataConfigTab: FC<Props> = ({
  schema,
  dataPanel,
  datasets,
  referencedGlobalDatasets,
}) => {
  const { elementsById, params, loading } = useSelector(
    (state: ReduxState) => ({
      elementsById: state.dashboardEditConfig.config?.elements,
      params: state.dashboardEditConfig.config?.params,
      loading: !!state.dashboardData.dataPanelData[dataPanel.id]?.loading,
    }),
    shallowEqual,
  );

  const allDatasets = useMemo(
    () => convertGlobalDatasetsAndCombineWithLocal(datasets, referencedGlobalDatasets),
    [datasets, referencedGlobalDatasets],
  );

  const elements = useMemo(() => Object.values(elementsById ?? {}), [elementsById]);

  const filterInstructions = dataPanel.filter_op.instructions;
  const visualizationType = dataPanel.visualize_op.operation_type;
  const vizInstructions = dataPanel.visualize_op.instructions;

  const linksForDataPanel = getPossibleLinksForDataPanel(dataPanel, allDatasets, elements);

  const hasLinkedDateRangePickerFilter = !!linksForDataPanel.find(
    (link) => link.operator === FilterOperator.DATE_IS_BETWEEN && link.applied,
  );

  const hasDateRangePickerFilter =
    hasLinkedDateRangePickerFilter ||
    !!filterInstructions.filterClauses.find((filter) => {
      return (
        filter.filterValueVariableId !== undefined &&
        filter.filterValueSource === FilterValueSourceType.VARIABLE &&
        filter.filterOperation?.id === FilterOperator.DATE_IS_BETWEEN &&
        filter.filterColumn?.name ===
          vizInstructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.name
      );
    });

  const sharedTwoDimConfigProps = {
    chartType: visualizationType,
    instructions: vizInstructions.V2_TWO_DIMENSION_CHART || {},
    loading,
    dashboardElements: elements,
    dashboardParams: params ?? {},
    dataPanelProvidedId: dataPanel.provided_id,
    canSelectSmartGrouping: hasDateRangePickerFilter,
    schema,
  };

  const renderVizConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols={visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2}
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
            supportColor={visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2}
          />
        );
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols
            supportColor
            supportSecondaryGroup
            colorName="Stacks"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols
            supportColor
            aggName="X-Axis"
            categoryName="Y-Axis"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols
            supportColor
            supportSecondaryGroup
            aggName="X-Axis"
            categoryName="Y-Axis"
            colorName="Clusters"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
            secondaryGroupName="Stacks"
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            aggName="Values"
            categoryName="Categories"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_CHOROPLETH_MAP:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            aggName="Aggregation Column"
            categoryName="Boundary Column"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_DENSITY_MAP:
      case OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP:
        return (
          <GeospatialVizConfig
            instructions={vizInstructions.VISUALIZE_GEOSPATIAL_CHART || {}}
            operationType={visualizationType}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            aggName="Amount"
            categoryName="Stages"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            renderColorBeforeAgg
            aggName="Aggregation"
            categoryName="X-Axis"
            colorName="Y-Axis"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP:
        return (
          <CalendarHeatmapConfig
            instructions={vizInstructions.V2_TWO_DIMENSION_CHART}
            loading={loading}
            schema={schema}
          />
        );

      case OPERATION_TYPES.VISUALIZE_SANKEY_CHART:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            renderColorBeforeAgg
            aggName="Aggregation"
            categoryName="From"
            colorName="To"
            maxAggs={1}
          />
        );

      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <ScatterPlotConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_SCATTER_PLOT || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            supportColor
            aggName="Aggregation"
            categoryName="Categories"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
        return (
          <KPIVizConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_KPI || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL:
        return (
          <KPITrendConfig
            chartType={visualizationType}
            dashboardElements={elements}
            instructions={vizInstructions.V2_KPI_TREND || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <BoxPlotConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_BOX_PLOT || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return (
          <TableConfig
            chartType={OPERATION_TYPES.VISUALIZE_TABLE}
            instructions={vizInstructions.VISUALIZE_TABLE || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
        return (
          <PivotTableConfig
            chartType={OPERATION_TYPES.VISUALIZE_PIVOT_TABLE}
            instructions={vizInstructions.VISUALIZE_PIVOT_TABLE || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2:
        return (
          <V2PivotTableConfig
            instructions={vizInstructions.VISUALIZE_PIVOT_TABLE_V2 || EmptyV2PivotInstructions}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
        return (
          <CollapsibleListConfig
            chartType={OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST}
            instructions={vizInstructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
            loading={loading}
            schema={schema}
          />
        );

      default:
        return <div></div>;
    }
  };

  return (
    <>
      {CHART_VISUALIZATION_OPERATIONS_SET.has(visualizationType) ? (
        <>
          <SettingHeader name="Chart Type" />
          <VisualizationTypeSelector
            dataPanelId={dataPanel.id}
            selectedChartType={maybeChangeNumberTrendVisualizationType(
              visualizationType,
              dataPanel.visualize_op.instructions.V2_KPI_TREND,
            )}
          />
        </>
      ) : null}
      {renderVizConfig()}
      <SettingHeader name="Filter" />
      <DroppableFilterColumnSection
        clauses={filterInstructions.filterClauses}
        dashboardElements={elements}
        dashboardParams={params ?? {}}
        dataPanel={dataPanel}
        datasets={allDatasets}
        schema={schema}
      />
      <ElementFilterSection
        allDatasets={allDatasets}
        dashboardElements={elements}
        dataPanel={dataPanel}
      />
      <SettingHeader name="Fields" />
      <VisualizationConfigTableColumns loading={loading} schema={schema} />
    </>
  );
};
