import { Layout } from '@explo-tech/react-grid-layout';

import { EnvironmentTag } from 'actions/environmentTagActions';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { DashboardElement } from 'types/dashboardTypes';
import {
  DataPanel,
  LatestVersionInfo,
  ProductType,
  ResourcePageType,
  ResourceType,
} from 'types/exploResource';

export const getDataPanelDatasetId = (dataPanel: DataPanel): string => String(dataPanel.table_id);

export function isDashboardElement(
  element: DashboardElement | DataPanel,
): element is DashboardElement {
  return 'element_type' in element;
}

export function getPreviewInfoFromLatestVersion({ configuration }: LatestVersionInfo): {
  layout: Layout[];
  dataPanels: DataPanel[];
  elements: DashboardElement[];
  editableSectionTitle?: string;
} {
  return {
    layout: configuration.dashboard_layout,
    dataPanels: Object.values(configuration.data_panels),
    elements: Object.values(configuration.elements),
    editableSectionTitle: configuration.editable_section?.enabled
      ? configuration.editable_section.settings.title
      : undefined,
  };
}

export function getDataPanelTitle({ visualize_op }: DataPanel): string | null {
  const title = visualize_op.generalFormatOptions?.headerConfig?.title ?? '';
  return title.trim() !== '' && !visualize_op.generalFormatOptions?.headerConfig?.isHeaderHidden
    ? title
    : null;
}

export function getPermissionEntity(pageType: ResourcePageType): PERMISSIONED_ENTITIES {
  switch (pageType) {
    case ResourcePageType.REPORT_BUILDER:
      return PERMISSIONED_ENTITIES.REPORT_BUILDER;
    case ResourcePageType.EXPLORE:
      return PERMISSIONED_ENTITIES.DASHBOARD;
  }
}

export function isVersionTagged(
  pageType: ResourcePageType,
  resourceId: number,
  versionId: number,
  tag: EnvironmentTag,
): boolean {
  switch (pageType) {
    case ResourcePageType.EXPLORE:
      return tag.dashboard_versions_by_dashboard?.[resourceId] === versionId;
    case ResourcePageType.REPORT_BUILDER:
      return tag.report_builder_mapping?.[resourceId] === versionId;
  }
}

export const getResourceText = (
  pageType: ResourcePageType,
  params?: { plural?: boolean; capitalized?: boolean },
) => {
  let str = '';

  switch (pageType) {
    case ResourcePageType.REPORT_BUILDER:
      str = 'report builder';
      break;
    case ResourcePageType.EXPLORE:
      str = 'dashboard';
      break;
  }

  if (params?.plural) str = str + 's';

  if (params?.capitalized)
    str = str
      .split(' ')
      .map((subStr) => subStr.charAt(0).toUpperCase() + subStr.slice(1))
      .join(' ');

  return str;
};

export const formatNumFolders = (numFolders: number) =>
  `${numFolders} folder${numFolders == 1 ? '' : 's'}`;

export const formatNumResources = (isExploreProduct: boolean, numResources: number) =>
  `${numResources} ${getResourceText(
    isExploreProduct ? ResourcePageType.EXPLORE : ResourcePageType.REPORT_BUILDER,
    { plural: numResources !== 1 },
  )}`;

export const getResourceNameErrorMessage = (
  input: string | undefined,
  uniqueNames: Set<string | unknown>,
  currentName?: string,
): string | undefined => {
  const newName = input?.trim();
  if (!newName || !uniqueNames.has(newName)) return;
  if (!currentName || newName !== currentName) return 'Name must be unique within folder';
};

export const getFolderNameErrorMessage = (
  input: string | undefined,
  uniqueNames: Set<string>,
  currentName?: string,
  hasUserInputtedValue?: boolean,
) => {
  const resourceErrorMessage = getResourceNameErrorMessage(input, uniqueNames, currentName);
  if (resourceErrorMessage) return resourceErrorMessage;

  if ((!input || input.trim() === '') && hasUserInputtedValue) return 'Folder name cannot be empty';

  return '';
};

export const getCloneResourceSuccessUrl = (isExploreProduct: boolean, newResourceId: number) =>
  `/${isExploreProduct ? 'dashboard' : 'report-builder'}/${newResourceId}${
    isExploreProduct ? '' : '/datasets'
  }`;

export const getProductTypeFromResourcePageType = (
  resourcePageType: ResourcePageType,
): ProductType => {
  switch (resourcePageType) {
    case ResourcePageType.REPORT_BUILDER:
      return ResourceType.REPORT;
    case ResourcePageType.EXPLORE:
      return ResourceType.DASHBOARD;
    default:
      throw new Error('Invalid resource page type');
  }
};
