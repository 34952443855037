import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Dashboard } from 'actions/dashboardActions';
import { sprinkles } from 'components/ds';
import { DashboardSelectionControl } from 'pages/dashboardPage/LayersPanel/DashboardSelectionControl';
import { DashboardSelectionControlDatum } from 'pages/dashboardPage/LayersPanel/types';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DashboardHierarchy, DashboardVersionHierarchy } from 'types/dashboardTypes';
import { getDashboardDepth } from 'utils/dashboardHierarchyUtils';

import { AddNewLayerPopover } from './AddNewLayerPopover';

export const LayersPanel: FC = () => {
  const { dashboard, dashboardHierarchy, dashboardVersionHierarchy, currentUser } = useSelector(
    (state: ReduxState) => {
      return {
        dashboard: RD.getOrDefault(state.dashboard.currentDashboard, undefined),
        dashboardHierarchy: RD.getOrDefault(state.dashboard.dashboardHierarchy, undefined),
        dashboardVersionHierarchy: RD.getOrDefault(
          state.dashboardEditConfig.versionHierarchy,
          undefined,
        ),
        currentUser: state.currentUser,
      };
    },
    shallowEqual,
  );

  const itemControlDatas = useMemo(() => {
    if (!dashboardHierarchy || !dashboardVersionHierarchy) {
      return [];
    }
    return createDashboardControlData(dashboardHierarchy, dashboardVersionHierarchy);
  }, [dashboardHierarchy, dashboardVersionHierarchy]);

  const selectedDashboardDepth = useMemo(() => {
    if (dashboard && dashboardHierarchy) {
      return getDashboardDepth(dashboardHierarchy.dashboards, dashboard.id);
    }
    return 0;
  }, [dashboard, dashboardHierarchy]);

  const currentDashboardConfig = useMemo(() => {
    if (!dashboard || !dashboardVersionHierarchy) {
      return null;
    }
    return dashboardVersionHierarchy.dashboardVersions[dashboard.id].configuration;
  }, [dashboard, dashboardVersionHierarchy]);

  const dashboardIdToSourceDataPanelIdMap = useMemo(() => {
    const dashboardIdToSourceDataPanelIdMap: Record<number, string> = {};
    if (!currentDashboardConfig) {
      return dashboardIdToSourceDataPanelIdMap;
    }

    Object.values(currentDashboardConfig.data_panels).forEach((dataPanel) => {
      const drilldownEntryPoints = dataPanel.drilldownEntryPoints;
      Object.values(drilldownEntryPoints).forEach((drilldownEntryPoint) => {
        dashboardIdToSourceDataPanelIdMap[drilldownEntryPoint.destinationDashboardId] =
          dataPanel.id;
      });
    });

    return dashboardIdToSourceDataPanelIdMap;
  }, [currentDashboardConfig]);

  if (!dashboard || !dashboardHierarchy || !currentDashboardConfig) {
    // Consider rendering a loading spinner here but we shouldn't ever be in this state. The
    // dashboard page blocks any other rendering until the dashboard is finished fetching.
    return null;
  }

  return (
    <div className={sprinkles({ paddingX: 'sp2' })}>
      <div className={PANEL_HEADER_CLASS}>
        <div className={sprinkles({ fontWeight: 600 })}>Views</div>
        <AddNewLayerPopover
          currentUserId={currentUser.id}
          dashboardHierarchy={dashboardHierarchy}
          dataPanelsById={currentDashboardConfig.data_panels}
          parentDashboard={dashboard}
          parentDashboardDepth={selectedDashboardDepth}
        />
      </div>
      <div className={sprinkles({ paddingTop: 'sp1' })}>
        {itemControlDatas.map((itemData) => (
          <DashboardSelectionControl
            currentDashboardId={dashboard.id}
            dashboardIdToSourceDataPanelIdMap={dashboardIdToSourceDataPanelIdMap}
            itemData={itemData}
            key={`layers-panel-control-${itemData.name}`}
          />
        ))}
      </div>
    </div>
  );
};

const PANEL_HEADER_CLASS = sprinkles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 'sp2',
});

const createDashboardControlData = (
  dashboardHierarchy: DashboardHierarchy,
  dashboardVersionHierarchy: DashboardVersionHierarchy,
): DashboardSelectionControlDatum[] => {
  const dashboardMetadataItems: DashboardSelectionControlDatum[] = [];
  createDashboardControlDataHelper(
    dashboardHierarchy.dashboards[dashboardHierarchy.rootDashboardId],
    0,
    dashboardMetadataItems,
    dashboardHierarchy,
    dashboardVersionHierarchy,
  );
  return dashboardMetadataItems;
};

const createDashboardControlDataHelper = (
  currentDashboard: Dashboard,
  hierarchyLevel: number,
  itemDatas: DashboardSelectionControlDatum[],
  dashboardHierarchy: DashboardHierarchy,
  dashboardVersionHierarchy: DashboardVersionHierarchy,
) => {
  if (!dashboardVersionHierarchy.dashboardVersions[currentDashboard.id]) {
    // Do not include dashboards that are not present in the current version. If a dashboard is not
    // present in the current version, none of its children should be either so we can return early.
    return;
  }
  itemDatas.push({ name: currentDashboard.name, hierarchyLevel, dashboardId: currentDashboard.id });
  const childDashboardIds = currentDashboard.childDashboardIds;
  childDashboardIds.forEach((childDashboardId) => {
    createDashboardControlDataHelper(
      dashboardHierarchy.dashboards[childDashboardId],
      hierarchyLevel + 1,
      itemDatas,
      dashboardHierarchy,
      dashboardVersionHierarchy,
    );
  });
};
