import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { BaseCol, DatasetSchema, OPERATION_TYPES, SortOrder } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SortableList, SortableListItem } from 'components/SortableList/SortableList';
import { sprinkles, Switch } from 'components/ds';
import { VisualizeCollapsibleListInstructions, DefaultSortColumn } from 'constants/types';
import { sortSchemaByOrderedColumnNames } from 'utils/tableSchemaUtils';

import { ColumnSortingConfig } from '../../ColumnSortingConfig';
import { CategorySortingConfig } from '../CategorySortingConfig';
import { CollapsibleListItemConfig } from '../CollapsibleListItemConfig';

type Props = {
  instructions: VisualizeCollapsibleListInstructions;
  schema: DatasetSchema;
};

export const CollapsibleListColumnsConfig: FC<Props> = ({ instructions, schema }) => {
  const dispatch = useDispatch();

  const numCategories = instructions?.rowColumns?.length || 0;
  const categoryCols = schema.slice(0, numCategories);
  const aggCols = schema.slice(numCategories);

  const sortOptions = aggCols.map((col) => ({
    value: col.name,
    label: col.friendly_name || col.name,
  }));

  const orderedSchema = sortSchemaByOrderedColumnNames(aggCols, instructions?.orderedColumnNames);

  const updateInstructions = (instructions: VisualizeCollapsibleListInstructions) => {
    dispatch(updateVisualizeOperation(instructions, OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST));
  };

  const updateDefaultSort = (column: BaseCol, sortColumn: DefaultSortColumn) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.sortColumns = {
        ...draft.sortColumns,
        [column.name]: {
          ...draft.sortColumns?.[column.name],
          ...sortColumn,
        },
      };
    });

    updateInstructions(newInstructions);
  };

  return (
    <>
      <Switch
        className={sprinkles({ paddingX: 'sp1.5', marginTop: 'sp1.5', marginBottom: 'sp1' })}
        label="Sorting"
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.isSortingDisabled = !draft.isSortingDisabled;
          });

          updateInstructions(newInstructions);
        }}
        switchOn={!instructions.isSortingDisabled}
      />
      {!instructions.isSortingDisabled ? (
        <>
          <CategorySortingConfig
            sortedStages={instructions.sortedStages || []}
            updateInstructions={(updatedInstructions) => {
              const newInstructions = produce(instructions || {}, (draft) => {
                return { ...draft, ...updatedInstructions };
              });

              dispatch(
                updateVisualizeOperation(
                  newInstructions,
                  OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST,
                ),
              );
            }}
          />
          <div className={sprinkles({ paddingX: 'sp1.5' })}>
            {categoryCols.map((col) => (
              <ColumnSortingConfig
                key={col.name}
                label={col.name}
                order={instructions.sortColumns?.[col.name]?.order ?? SortOrder.ASC}
                selectedCol={instructions.sortColumns?.[col.name]?.column}
                sortOptions={sortOptions}
                updateDefaultSort={(sortColumn) => updateDefaultSort(col, sortColumn)}
              />
            ))}
          </div>
        </>
      ) : null}
      <SortableList
        getIdFromElem={(col) => col.name}
        onListUpdated={(newList) =>
          updateInstructions({
            ...instructions,
            orderedColumnNames: newList.map((col) => col.name),
          })
        }
        sortableItems={orderedSchema}>
        {orderedSchema.map((col) => (
          <SortableListItem key={col.name} sortId={col.name}>
            <CollapsibleListItemConfig
              column={col}
              instructions={instructions}
              key={col.name}
              schema={schema}
              updateInstructions={updateInstructions}
            />
          </SortableListItem>
        ))}
      </SortableList>
    </>
  );
};
