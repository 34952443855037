import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { ROUTE_PROVIDERS } from 'constants/routes';
import { ReduxState } from 'reducers/rootReducer';

import { History } from 'history';
import { ItemType, setCurrentItemInfo } from 'reducers/dataLibraryReducer';

export const navigateToPathThunk =
  (
    resourceId: string,
    resourceType: ItemType,
    path: string,
    history: History,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(setCurrentItemInfo({ path, type: resourceType }));
    const url =
      resourceType === ItemType.FOLDER
        ? ROUTE_PROVIDERS.DATA_LIBRARY_FOLDER(resourceId)
        : ROUTE_PROVIDERS.DATA_LIBRARY_DATASET(resourceId);
    history.push(url);
  };
