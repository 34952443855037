import { ComputedView, Resource } from '@explo-tech/fido-api';
import { COMPUTED_VIEW_TYPE } from './constants';

export const doResourcesHaveContentDifference = (
  firstResource: Resource,
  secondResource: Resource,
): boolean => {
  if (
    firstResource['@type'] !== COMPUTED_VIEW_TYPE ||
    secondResource['@type'] !== COMPUTED_VIEW_TYPE
  ) {
    return false;
  }

  const firstComputedView = firstResource as ComputedView;
  const secondComputedView = secondResource as ComputedView;

  return firstComputedView.query !== secondComputedView.query;
};
