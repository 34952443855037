import { CustomerReportFilter, CustomerReportGroupBy, SortInfo } from '@explo/data';

import {
  CustomerReportAgg,
  CustomerReportTotals,
  CustomerReportVisualization,
} from 'actions/customerReportActions';
import { ExportType } from 'actions/exportActions';
import { DashboardVariableMap } from 'types/dashboardTypes';

export type ExportViewOverrides = {
  id: string;
  visualization?: CustomerReportVisualization;
  group_bys?: CustomerReportGroupBy[];
  col_group_bys?: CustomerReportGroupBy[];
  aggs?: CustomerReportAgg[];
  totals?: CustomerReportTotals;
  hidden_columns?: string[];
  columns?: string[];
  filters?: CustomerReportFilter[];
  sort?: SortInfo[];
};

export type ExportCustomerReportBody = {
  folder_name: string;
  file_name: string;
  embed_id: string | undefined;
  version_number: number;
  dataset_id: string;
  export_type: ExportType;
  variables: DashboardVariableMap;
  view_override: ExportViewOverrides;
  timezone?: string;
};

export type ScheduledExportTestParams = {
  report_builder_email_cadence_id: string;
  is_test_email: true;
  dataset_id?: string;
  view_overrides: ExportViewOverrides[];
};

export type DraftScheduledExportTestBody = ExportBody & {
  jwt: string | undefined;
  customer_token: string | undefined;
  report_builder_id: number;
  dataset_id?: string;
  environment_tag_id: number | null;
  built_in_id?: string;
  customer_report_id?: number;
  view_overrides: ExportViewOverrides[];
};

export type ExportBody = {
  recipients: string[];
  timezone: string;
  subject: string;
  message: string;
  export_type: ExportType;
};

export type EmailBody = ExportBody & {
  minute: number;
  hour: number;
  week_of_month: number | null;
  day_of_week: number | null;
  locale: string;
};

export type EmailCadence = {
  id: string;
} & EmailBody;

export const DRAFT_EMAIL_ID = 'DRAFT';
