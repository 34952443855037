import { Branch } from '@explo-tech/fido-api';
import cx from 'classnames';
import { Button, Icon, Menu, MenuActionItem, MenuSeparator, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ItemType, switchCurrentBranch } from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';
import { listBranchContentThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { isError, isLoading, isSuccess, ResponseData, Success } from 'remotedata';
import { branchMenuItemClassName } from './BranchMenu.css';
import { ROOT_FOLDER_PATH } from './constants';
import { isBranchMain } from './dataLibraryUtil';
import { navigateToPathThunk } from './navigationUtils';
import { getRootFolderForBranch } from './selectors';

interface Props {
  currentBranch: Branch;
  branches: Map<string, Branch>;
  newCreatedBranchResponse: ResponseData<Branch>;

  onCreateNewBranchClicked: () => void;
}

export const BranchMenu: FC<Props> = ({
  currentBranch,
  branches,
  newCreatedBranchResponse,
  onCreateNewBranchClicked,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rootFolderData, baseFoldersForAllBranches } = useSelector((state: ReduxState) => {
    return {
      rootFolderData: getRootFolderForBranch(state, Success(currentBranch)),
      baseFoldersForAllBranches: state.dataLibrary.folders,
    };
  });

  const buttonIconConfig: {
    name: IconName;
    class: string;
  } = useMemo(() => {
    if (isSuccess(newCreatedBranchResponse)) {
      return {
        name: 'check',
        class: sprinkles({ color: 'success' }),
      };
    } else if (isLoading(newCreatedBranchResponse)) {
      return {
        name: 'spinner',
        class: sprinkles({ color: 'active' }),
      };
    } else if (isError(newCreatedBranchResponse)) {
      return {
        name: 'circle-exclamation-reg',
        class: sprinkles({ color: 'error' }),
      };
    }

    return {
      name: 'code-branch',
      class: '',
    };
  }, [newCreatedBranchResponse]);

  const sortedBranches = useMemo(() => {
    return Array.from(branches.values()).sort((firstBranch, secondBranch) => {
      if (isBranchMain(firstBranch)) {
        return -1;
      } else if (isBranchMain(secondBranch)) {
        return 1;
      }

      return firstBranch.name.localeCompare(secondBranch.name);
    });
  }, [branches]);

  if (!isSuccess(rootFolderData)) {
    return null;
  }

  return (
    <Menu
      trigger={
        <Button name="code-branch" variant="secondary">
          <Icon
            className={cx(buttonIconConfig.class, sprinkles({ marginRight: 'sp1' }))}
            name={buttonIconConfig.name}
          />
          <span className={sprinkles({ truncateText: 'ellipsis' })}>{currentBranch.name}</span>
        </Button>
      }>
      {sortedBranches.map((branch) => {
        return (
          <div className={branchMenuItemClassName} key={branch.id}>
            <MenuActionItem
              iconName="code-branch"
              onSelect={() => {
                dispatch(switchCurrentBranch(branch));
                const rootFolder = rootFolderData.data;
                dispatch(
                  navigateToPathThunk(
                    rootFolder.id ?? '',
                    ItemType.FOLDER,
                    ROOT_FOLDER_PATH,
                    history,
                  ),
                );

                const baseFoldersForNewBranch = baseFoldersForAllBranches.get(branch.id ?? '');
                if (!baseFoldersForNewBranch?.has(ROOT_FOLDER_PATH)) {
                  dispatch(
                    listBranchContentThunk({
                      id: branch.id ?? '',
                      path: ROOT_FOLDER_PATH,
                      resourceType: ItemType.FOLDER,
                    }),
                  );
                }
              }}
              text={branch.name}
              textClassName={isBranchMain(branch) ? sprinkles({ fontWeight: 700 }) : ''}
            />
            {currentBranch.id === branch.id ? (
              <Icon className={sprinkles({ color: 'active', marginRight: 'sp1' })} name="check" />
            ) : null}
          </div>
        );
      })}
      <MenuSeparator />
      <MenuActionItem
        disabled={!isBranchMain(currentBranch) || isLoading(newCreatedBranchResponse)}
        iconName="plus"
        key="create-new-branch"
        onSelect={onCreateNewBranchClicked}
        text="Create new branch"
        tooltipProps={{
          text: !isBranchMain ? 'Cannot create new branch from non-main branch' : undefined,
        }}
      />
    </Menu>
  );
};
