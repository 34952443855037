import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { FILTER_OPS_MULTISELECT, FILTER_OPS_NUMBER, FILTER_OPS_STRING } from '@explo/data';

import { DASHBOARD_ELEMENT_TYPE_TO_NAME, SELECT_ELEMENTS } from 'constants/dashboardConstants';
import { deleteVariablesThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DASHBOARD_ELEMENT_TYPES, SELECT_FILTER_TYPE } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';

import * as styles from '../styles.css';
import { isFilterUsedInDataPanel } from '../utils';

type Props = {
  dataPanels?: Record<string, DataPanel>;
  elementName: string;
  elementType: SELECT_FILTER_TYPE;

  updateType: (newType: SELECT_FILTER_TYPE) => void;
};

export const SelectFilterTypeConfig: FC<Props> = ({
  dataPanels,
  elementName,
  elementType,
  updateType,
}) => {
  const dispatch = useDispatch();

  const isFilterUsed = useMemo(() => {
    return isFilterUsedInDataPanel(dataPanels, elementName, (operationId) => {
      if (elementType === DASHBOARD_ELEMENT_TYPES.MULTISELECT) {
        if (!FILTER_OPS_MULTISELECT.has(operationId)) return false;
      } else if (!FILTER_OPS_NUMBER.has(operationId) && !FILTER_OPS_STRING.has(operationId)) {
        return false;
      }
      return true;
    });
  }, [elementName, elementType, dataPanels]);

  return (
    <div className={styles.optionRow}>
      {SELECT_ELEMENTS.map((selectType) => {
        const isSelected = elementType === selectType;

        // Can't switch from or to multi if filter is being used in a data panel
        const isDisabled =
          !isSelected &&
          isFilterUsed &&
          (elementType === DASHBOARD_ELEMENT_TYPES.MULTISELECT ||
            selectType === DASHBOARD_ELEMENT_TYPES.MULTISELECT);

        return (
          <div
            className={cx(styles.option, {
              [styles.selectedOption]: isSelected,
              [styles.disabledOption]: isDisabled,
            })}
            key={`option-${selectType}`}
            onClick={() => {
              if (elementType === selectType || isDisabled) return;
              dispatch(deleteVariablesThunk([elementName]));
              updateType(selectType);
            }}>
            {DASHBOARD_ELEMENT_TYPE_TO_NAME[selectType]}
          </div>
        );
      })}
    </div>
  );
};
