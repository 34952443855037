import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { saveCustomMapBoxToken } from 'actions/teamActions';
import { Input, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';

export const SettingsMapBoxSection: FC = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: ReduxState) => state.teamData.data?.custom_map_box_token);

  return (
    <div>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <div className={sprinkles({ heading: 'h2' })}>Mapbox</div>
      </div>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp3' })}>
        <div className={sprinkles({ color: 'contentTertiary' })}>
          This allows you to embed your own Mapbox in your chloropleth maps.
        </div>
        <Input
          showInputButton
          className={sprinkles({ marginY: 'sp3' })}
          defaultValue={token ?? undefined}
          label="Mapbox Access Token"
          onSubmit={(newString) =>
            dispatch(saveCustomMapBoxToken({ postData: { token: newString } }))
          }
          placeholder={'Your Access Token'}
        />
      </div>
    </div>
  );
};
