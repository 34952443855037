import { PointOptionsObject } from 'highcharts';

import { SortAxis, SortOption } from '@explo/data';

import { XAxisFormat } from 'constants/types';

import { SeriesOptions } from '../constants/types';

export const MAX_CATEGORIES = 999;

export const getTotalMaxCategories = (userSetMaxCategories: number | undefined) => {
  return Math.min(userSetMaxCategories ?? Number.MAX_VALUE, MAX_CATEGORIES);
};

export const filterBarChartDataToCategories = (
  data: SeriesOptions[],
  categories: string[],
  xAxisFormat: XAxisFormat | undefined,
  isDate?: boolean,
) => {
  return filterDataToCategoriesHelper(
    data,
    categories,
    xAxisFormat,
    (pointObj) => pointObj.name as string,
    isDate,
    true,
  );
};

export const filterLineChartDataToCategories = (
  data: SeriesOptions[],
  categories: string[],
  xAxisFormat: XAxisFormat | undefined,
  isDate?: boolean,
) => {
  return filterDataToCategoriesHelper(
    data,
    categories,
    xAxisFormat,
    (pointObj) => (isDate ? pointObj?.x : pointObj?.name)?.toString(),
    isDate,
    false, // TODO(SHIBA-6010): Support other category for line charts
  );
};

const filterDataToCategoriesHelper = (
  data: SeriesOptions[],
  categories: string[],
  xAxisFormat: XAxisFormat | undefined,
  getCategoryFieldFn: (pointObject: PointOptionsObject) => string | undefined,
  isDate?: boolean,
  isOtherCategoryEnabled?: boolean,
): { data: SeriesOptions[]; categories: string[] } => {
  if (
    !categories ||
    // we will not have truncated the categories if there is no user-specified max category and there are already fewer than MAX_CATEGORIES, so no need to filter
    (xAxisFormat?.maxCategories === undefined && categories.length < MAX_CATEGORIES)
  )
    return { data, categories };
  const showOther = !!xAxisFormat?.showOther && isOtherCategoryEnabled;

  let addOtherCategory = false;
  data.forEach((series) => {
    let other = 0;
    series.data = series.data.filter((data) => {
      const pointObject = data as PointOptionsObject;
      const categoryValue = getCategoryFieldFn(pointObject);
      const isIncluded = categoryValue && categories.includes(categoryValue);
      if (!isIncluded && showOther) other += pointObject.y || 0;
      return isIncluded;
    });
    // TODO(SHIBA-5973): Support other category for date type x-axis
    if (!isDate && other > 0) {
      addOtherCategory = true;
      series.data.push({ name: 'Other', y: other, selected: false });
    }
  });

  if (!addOtherCategory) return { data, categories };

  const { includeOtherInSorting, sortAxis, sortOption } = xAxisFormat ?? {};
  const allCategories = [...categories, 'Other'];

  const shouldSortOtherCategory =
    includeOtherInSorting &&
    (sortOption === SortOption.ASC || sortOption === SortOption.DESC) &&
    (sortAxis === SortAxis.CAT_AXIS || sortAxis === SortAxis.AGG_AXIS);

  if (!shouldSortOtherCategory) {
    return { data, categories: allCategories };
  }

  if (sortAxis === SortAxis.CAT_AXIS) {
    const sortedCategories = sortCategoryAxis(allCategories, sortOption);
    const updatedData = updateSeriesDataWithSortedCategories(data, sortedCategories);
    return { data: updatedData, categories: sortedCategories };
  }

  // case SortAxis.CAT_AXIS
  const categoryTotals = calculateCategoryTotals(allCategories, data);
  const sortedCategories = sortAggregationAxis(allCategories, categoryTotals, sortOption);
  const updatedData = updateSeriesDataWithSortedCategories(data, sortedCategories);
  return { data: updatedData, categories: sortedCategories };
};

export const truncateCategoriesToMaxCategories = (
  categories: string[],
  xAxisFormat: XAxisFormat | undefined,
  isSortingEnabled?: boolean,
  shouldReverseSort?: boolean,
) => {
  if (!categories) return [];
  const maxCategories = getTotalMaxCategories(xAxisFormat?.maxCategories);

  if (!maxCategories || maxCategories >= categories.length) return categories;

  // only truncate from the back if we're sorting in reverse
  if (isSortingEnabled && shouldReverseSort) {
    return categories.slice(categories.length - maxCategories);
  }
  return categories.slice(0, maxCategories);
};

const updateSeriesDataWithSortedCategories = (
  data: SeriesOptions[],
  sortedCategories: string[],
): SeriesOptions[] => {
  data.forEach((series) => {
    series.data = series.data.map((point) => ({
      ...(point as PointOptionsObject),
      x: sortedCategories?.indexOf((point as PointOptionsObject).name as string),
    }));
  });
  return data;
};

const sortCategoryAxis = (allCategories: string[], sortOption: SortOption): string[] => {
  return allCategories.sort((a, b) =>
    sortOption === SortOption.ASC ? a.localeCompare(b) : b.localeCompare(a),
  );
};

const calculateCategoryTotals = (
  allCategories: string[],
  data: SeriesOptions[],
): Record<string, number> => {
  const categoryTotals: Record<string, number> = {};
  allCategories.forEach((category) => {
    categoryTotals[category] = data.reduce((sum, series) => {
      const point = series.data.find((d) => (d as PointOptionsObject).name === category);
      return sum + ((point as PointOptionsObject)?.y || 0);
    }, 0);
  });
  return categoryTotals;
};

const sortAggregationAxis = (
  allCategories: string[],
  categoryTotals: Record<string, number>,
  sortOption: SortOption,
): string[] => {
  const categoryOrder = [...allCategories].sort((a, b) => {
    const diff = categoryTotals[a] - categoryTotals[b];
    return diff === 0 ? a.localeCompare(b) : diff;
  });

  return sortOption === SortOption.ASC ? categoryOrder : categoryOrder.reverse();
};
