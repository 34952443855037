import { FC, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
  CustomerReportFilter,
  FilterValueType,
  FilterValueNumberRangeType,
  ChartAggregation,
  UNSIGNED_INTEGER,
  INTEGER_DATA_TYPE,
} from '@explo/data';

import { sprinkles } from 'components/ds';
import { Slider } from 'components/ds/Slider';
import { EmbedSpinner } from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { getAggData, getAggDataLoading } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { FilterableColumn } from 'utils/customerReportUtils';

import * as styles from './styles.css';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  onChange: (value: FilterValueType) => void;
};

export const NumberRangeFilter: FC<Props> = ({ clause, column, onChange }) => {
  const [value, setValue] = useState(clause?.filterValue as FilterValueNumberRangeType | undefined);
  const [minString, setMinString] = useState(String(value?.min ?? ''));
  const [maxString, setMaxString] = useState(String(value?.max ?? ''));
  const { isLoading, minState, maxState } = useSelector(
    (state: ReportBuilderReduxState) => ({
      isLoading: getAggDataLoading(state.reportEditing),
      minState: getAggData(state.reportEditing, column.name, ChartAggregation.MIN),
      maxState: getAggData(state.reportEditing, column.name, ChartAggregation.MAX),
    }),
    shallowEqual,
  );
  const max = typeof maxState == 'number' ? maxState : undefined;
  const min = typeof minState == 'number' ? minState : undefined;
  const isUnsignedIntCol = column.type === UNSIGNED_INTEGER;
  const isIntCol = column.type === INTEGER_DATA_TYPE || isUnsignedIntCol;

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  useEffect(() => {
    if (minString === '') return setValue((prev) => ({ ...prev, min: undefined }));
    const parsedValue = isIntCol ? parseInt(minString) : parseFloat(minString);

    // number type=input mostly shouldn't allow numbers, but we have this check just in case
    if (isNaN(parsedValue)) return;

    // Clamp to min and max bounds if they exist. value.min shouldn't be greater than the value.max
    setValue((prev) => ({
      ...prev,
      min: Math.min(Math.max(parsedValue, min ?? parsedValue), prev?.max ?? max ?? parsedValue),
    }));
  }, [isIntCol, max, min, minString]);

  useEffect(() => {
    if (maxString === '') return setValue((prev) => ({ ...prev, max: undefined }));
    const parsedValue = isIntCol ? parseInt(maxString) : parseFloat(maxString);

    // number type=input mostly shouldn't allow numbers, but we have this check just in case
    if (isNaN(parsedValue)) return;

    // Clamp to min and max bounds if they exist. value.max shouldn't be less than the value.min
    setValue((prev) => ({
      ...prev,
      max: Math.min(Math.max(parsedValue, prev?.min ?? min ?? parsedValue), max ?? parsedValue),
    }));
  }, [isIntCol, max, maxString, min]);

  if (isLoading) return <EmbedSpinner fillContainer size={'lg'} />;

  const minMaxStep = max != null && min != null ? (max - min) / NUM_STEPS : DEFAULT_STEP;
  // For ints, round to whole number. Don't round floats
  const step = isIntCol ? Math.max(Math.round(minMaxStep), 1) : minMaxStep;

  return (
    <>
      {/* Aggregated values don't have a min/max so we shouldn't show the slider */}
      {min != null && max != null ? (
        <div className={sprinkles({ paddingX: 'sp2', marginTop: 'sp2' })}>
          <Slider
            max={max}
            min={min}
            numThumbs={2}
            onValueChange={(value) => {
              setMinString(String(value[0]));
              setMaxString(String(value[1]));
            }}
            step={step}
            value={value ? [value.min ?? min, value.max ?? max] : []}
          />
        </div>
      ) : null}
      <div className={inputContainerStyle}>
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
          <input
            className={styles.valueInput}
            onChange={(e) => setMinString(e.target.value)}
            placeholder={`Minimum ${min == null ? '' : `(${min})`}`}
            step={1}
            type="number"
            value={minString}
          />
          <EmbedText body="b3" color="contentSecondary">
            Minimum
          </EmbedText>
        </div>
        <div className={maxInputStyle}>
          <input
            className={styles.valueInput}
            onChange={(e) => setMaxString(e.target.value)}
            placeholder={`Maximum ${max == null ? '' : `(${max})`}`}
            step={1}
            type="number"
            value={maxString}
          />
          <EmbedText body="b3" color="contentSecondary">
            Maximum
          </EmbedText>
        </div>
      </div>
    </>
  );
};

const DEFAULT_STEP = 1;
const NUM_STEPS = 100;

const inputContainerStyle = sprinkles({
  flex: 1,
  paddingX: 'sp2',
  marginTop: 'sp2',
  gap: 'sp2',
  flexItems: 'alignCenterBetween',
});

const maxInputStyle = sprinkles({
  flexItems: 'column',
  justifyContent: 'flex-end',
  gap: 'sp1',
  textAlign: 'right',
});
