import { DateTime } from 'luxon';
import { FC } from 'react';

import { BOOLEAN, DATE, DATETIME, dateTimeFromISOString, STRING, TIMESTAMP } from '@explo/data';

import { DatePickerInput } from 'components/DatePickerInput';
import { Input, Select } from 'components/ds';
import * as styles from './styles.css';

import { DashboardVariable } from 'types/dashboardTypes';

type Props = {
  type: string;
  name: string;
  value: DashboardVariable;
  updateVariable: (varName: string, newValue: DashboardVariable) => void;
};

export const ValueElement: FC<Props> = ({ name, type, value, updateVariable }) => {
  if (type === TIMESTAMP || type === DATE || type === DATETIME)
    return (
      <DatePickerInput
        showCancelBtn
        className={styles.rightElementStyle}
        onNewValueSelect={(newValue) =>
          updateVariable(
            name,
            newValue
              ? typeof newValue === 'string'
                ? dateTimeFromISOString(newValue)
                : (newValue as DateTime)
              : undefined,
          )
        }
        selectedValue={
          typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
        }
        showTimeSelect={false}
      />
    );
  else if (type === BOOLEAN) {
    return (
      <Select
        className={styles.rightElementStyle}
        onCancel={() => updateVariable(name, undefined)}
        onChange={(newValue) => updateVariable(name, newValue)}
        selectedValue={value as string}
        values={[{ value: 'true' }, { value: 'false' }]}
      />
    );
  } else {
    const isString = type === STRING;
    return (
      <Input
        className={styles.rightElementStyle}
        defaultValue={value as string}
        onSubmit={(newVal) => {
          if (!newVal.trim()) return updateVariable(name, undefined);
          let newValue: number | string | undefined = newVal;
          if (!isString) {
            const numValue = Number(newVal);
            newValue = isNaN(numValue) ? undefined : numValue;
          }
          updateVariable(name, newValue);
        }}
        type={isString ? 'text' : 'number'}
      />
    );
  }
};
