import { getTimeZones } from '@vvo/tzdb';
import { groupBy } from 'lodash';

import { ReportBuilderEmailContentArgs } from '@explo/rover-typescript-client';
import { ExploreEmailCadence } from 'actions/teamActions';
import { EmailFrequency } from 'constants/emailFrequency';
import { EmailCadenceTime, SelectedDropdownInputItem } from 'constants/types';

export const getFrequency = (emailCadence?: ExploreEmailCadence) => {
  if (!emailCadence) return;

  if (emailCadence.day_of_week !== undefined && emailCadence.day_of_week !== null) {
    if (emailCadence.week_of_month !== undefined && emailCadence.week_of_month !== null)
      return EmailFrequency.MONTHLY;
    return EmailFrequency.WEEKLY;
  }
  return EmailFrequency.DAILY;
};

export const getHour = (emailCadence?: ExploreEmailCadence) => {
  if (emailCadence?.hour === undefined) return;

  return emailCadence.hour % 12 === 0 ? 12 : emailCadence.hour % 12;
};

export const getMilitaryTimeHour = (time?: EmailCadenceTime) => {
  if (!time || !time.hour) return;

  return time.isPm ? (time.hour % 12) + 12 : time.hour % 12;
};

export const getTimezoneOptions = (): SelectedDropdownInputItem[] => {
  const raw_timezones = getTimeZones({ includeUtc: true });
  const timezones_by_alternative_name = groupBy(
    raw_timezones,
    (timezone) => timezone.alternativeName,
  );
  return raw_timezones
    .sort((a, b) => a.rawOffsetInMinutes - b.rawOffsetInMinutes)
    .map((timezone) => {
      // timezone.name is always something like Country/More Specific/(optional) More Specific.
      // We just want the last, most specific part
      const region = timezone.name.split('/').pop()?.replace('_', ' ');
      // if there are multiple timezones with this alternative name (like Eastern Time),
      // then we want to also show the more specific region for this option
      const name =
        `(GMT${timezone.rawFormat.split(' ')[0]}) ${timezone.alternativeName}` +
        (timezones_by_alternative_name[timezone.alternativeName].length > 1 ? ` - ${region}` : '');
      return {
        name: name,
        id: timezone.name,
      };
    });
};

export const getReportBuilderTestEmailContent: (
  message: string | undefined,
) => Pick<ReportBuilderEmailContentArgs, 'htmlContent' | 'plainTextContent'> = (message) => ({
  // TODO we need to sanitize this email message likely
  htmlContent: `<div>${
    message ? `${message}<br><br>` : ''
  }This is a test email you requested to send</div>`,
  plainTextContent: 'This is a test email you requested to send',
});
