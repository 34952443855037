import { sprinkles } from '@explo/design-system';
import { FC } from 'react';

import { Dataset, DatasetData } from 'actions/datasetActions';
import cx from 'classnames';
import { Button } from 'components/ds';
import { DatasetPreview } from 'pages/dashboardPage/dashboardDatasetEditor/DatasetPreview';

interface Props {
  dataset: Dataset;
  datasetData: DatasetData;
  hasUnmappedVariables: boolean;
  className?: string;

  onMapVariablesButtonClicked: () => void;
  onPreviewDataset: (query: string, pageNumber?: number) => void;
}

export const GlobalDatasetDataPreviewSection: FC<Props> = ({
  dataset,
  datasetData,
  hasUnmappedVariables,
  className,
  onMapVariablesButtonClicked,
  onPreviewDataset,
}) => {
  return (
    <div className={cx(borderClassName, className)}>
      <div className={headerClassName}>
        <div className={sprinkles({ margin: 'sp2' })}>Preview</div>
      </div>
      <div className={sprinkles({ marginY: 'sp2' })}>
        {hasUnmappedVariables ? (
          <div className={sprinkles({ flexItems: 'center' })}>
            <div
              className={sprinkles({
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: 'sp3',
              })}>
              <div className={sprinkles({ marginY: 'sp1.5' })}>
                There are unmapped variables in the query.
              </div>
              <Button onClick={onMapVariablesButtonClicked}>Map Variables</Button>
            </div>
          </div>
        ) : (
          <DatasetPreview
            datasetConfig={dataset}
            datasetData={datasetData}
            datasetQuery={dataset.query ?? ''}
            datasetSchema={dataset.schema ?? null}
            fetchDataset={onPreviewDataset}
            getTablePreview={() => {
              // No table preview needed.
            }}
          />
        )}
      </div>
    </div>
  );
};

const headerClassName = sprinkles({
  heading: 'h3',
  color: 'contentSecondary',
  borderBottom: 1,
  borderColor: 'outline',
});

const borderClassName = sprinkles({
  border: 1,
  borderColor: 'outline',
  borderRadius: 8,
});
