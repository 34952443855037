import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SharedAreaChartConfigs } from 'components/ChartConfigs/AreaChartConfigs';
import { sprinkles } from 'components/ds';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export const AreaChartConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const updateInstructions = (newInstructions: V2TwoDimensionChartInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sprinkles({ marginTop: 'spn.5' })}>
      <SharedAreaChartConfigs
        configInputClass={configInputClass}
        instructions={instructions}
        updateInstructions={updateInstructions}
      />
    </div>
  );
};
